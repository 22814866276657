import React, { createContext, useState, useContext, useEffect } from 'react';
import {config} from './Config';

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    const primaryColor = isDarkMode ? config.colors.primary : config.colors.secondary;
    const secondaryColor = isDarkMode ? config.colors.secondary :  config.colors.primary;
    const primaryBgColor = isDarkMode ? config.backgroundColors.primary : config.backgroundColors.secondary;
    const secondaryBgColor = isDarkMode ? config.backgroundColors.secondary : config.backgroundColors.primary;

    document.documentElement.style.setProperty('--primarycolor', primaryColor);
    document.documentElement.style.setProperty('--secondarycolor', secondaryColor);
    document.documentElement.style.setProperty('--primarybgcolor', primaryBgColor);
    document.documentElement.style.setProperty('--secondarybgcolor', secondaryBgColor);
  }, [isDarkMode]);

  const toggleTheme = () => setIsDarkMode(false);
  useEffect(()=>{
    // console.log("++",isDarkMode)
  },[isDarkMode])

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
