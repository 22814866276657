import axios from "axios";
import { API_BASE_URL } from "../Config";

// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://192.168.1.24:8008/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

export const getAngelTokenApi = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/demataccount/getAngelTokens`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getAllStrategyApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getAllSavedStrategies`,
      data
    );
    if (response.data.success) {
      // console.log("getAllSavedStrategies", response.data.data);
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getBackTestApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getBackTestResult`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error", error);
  }
};

export const getStrategyChartType = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/strategies/getChartType`);
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getStrategyHoldingType = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/strategies/getHoldingType`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const getSingleStrategyDetailsApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getSingleStrategyDetails`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getfunctionTypeWisefunction = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getFunctions`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const getAllRunningDeployedStrategiesAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getAllRunningDeployedStrategies`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paperTradingPlaceOrderAPI = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/paperTradingPlaceOrder`,
      data
    );
    if (response.data.success) {
      return response.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const deployStrategyApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/deployStrategy`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const deleteStrategyApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/deleteStrategy`,
      data
    );
    if (response.data.success) {
      // console.log("deleted?", data);
      return response.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const existStrategyNameApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/checkNameExist`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const LivePlaceOrderAPI = async (data) => {
  console.log("Params to live trade", data);
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/5PaisaPlaceOrder`,
      data
    );
    // console.log("Ress live", response);
    if (response.data.success) {
      return response.data.response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getPaperTradingTransactionsapi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getPaperTradingTransactions`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};

export const getBackTestTransactionsApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getBackTestTransactions`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getDiscoveredBackTestTransactionsApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getDiscoveredBackTestStockTransactions`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getBackTestResultApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getBackTestResult`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const editStrategyNameApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/editStrategyName`,
      data
    );
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getCandleDataApi = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/strategies/getCandleData`,
      data
    );
    if (responce.data.success) {
      return responce.data?.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error :", error);
  }
};

export const checkPreviouslyDeployed = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/strategies/checkedPreviouslyDeployed`,
      data
    );
    if (responce.data.success) {
      // console.log("csk>", responce.data?.data);
      return responce.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error :", error);
  }
};

export const stopDeployedStrategyAPI = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/strategies/stopDeployedStrategy`,
      data
    );
    if (responce.data.success) {
      // console.log("csk>", responce.data?.data);
      return responce.data?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("error :", error);
  }
};

export const getAllDiscoveredStrategyApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getAllSavedDiscoveredStrategies`,
      data
    );
    if (response.data.success) {
      // console.log("getAllSavedDiscoveredStrategies", response.data.data);
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getDiscoveredBackTestResult = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getDiscoveredBackTestResult`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
export const getSingleSavedDiscoveredStrategyDetails = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getSingleSavedDiscoveredStrategyDetails`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getStrategyCategoriesApi = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/strategies/getStrategyCategories`
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getDiscoverStrategyByIdApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/strategies/getAllDiscoveredStrategiesByCategoryId`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
