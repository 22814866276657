// src/config/indicatorConfigs.js

const indicatorConfigs = {
    Close: {
        title: "Close",
        fields: [
            { label: "Offset", name: "closeOffset", type: "number" },
        ],
    },
    "Median Price": {
        title: "Median Price",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    SMA: {
        title: "Simple Moving Average",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    SMA2: {
        title: "Second SMA",
        fields: [
            { label: "Period", name: "periods2", type: "number" },
            { label: "Offset", name: "offset2", type: "number" },
            { label: "Field", name: "field2", type: "select", options: ["open", "high", "low", "close", "volume"] },
        ],
    },
    "Open price": {
        title: "Open price",
        fields: [
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Low price": {
        title: "Low price",
        fields: [
            { label: "Offset", name: "lowOffset", type: "number" },
        ],
    },
    "High price": {
        title: "High price",
        fields: [
            { label: "Offset", name: "highOffset", type: "number" },
        ],
    },
    "RSI (Relative Strength Index)": {
        title: "RSI (Relative Strength Index)",
        fields: [
            { label: "Period", name: "rsiPeriod", type: "number" },
            { label: "Offset", name: "rsiOffset", type: "number" },
        ],
    },
    Ulcer: {
        title: "Ulcer Index",
        fields: [
            { label: "Period", name: "ulcerPeriod", type: "number" },
            { label: "Field", name: "ulcerField", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Offset", name: "ulcerOffset", type: "number" },
        ],
    },
    "DEMA (Double Exponential Moving Average)": {
        title: "DEMA (Double Exponential Moving Average)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "TEMA (Triple Exponential Moving Average)": {
        title: "TEMA (Triple Exponential Moving Average)",
        fields: [
            { label: "Field", name: "temaField", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Period", name: "temaPeriod", type: "number" },
            { label: "Offset", name: "temaOffset", type: "number" },
        ],
    },

    "TriMA (Triangular Moving Average)": {
        title: "TriMA (Triangular Moving Average)",
        fields: [
            { label: "Field", name: "tmaField", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Period", name: "tmaPeriod", type: "number" },
            { label: "Offset", name: "tmaOffset", type: "number" },
        ],
    },
    "VWMA (Volume Weighted Moving Average)": {
        title: "VWMA (Volume Weighted Moving Average)",
        fields: [
            { label: "Field", name: "vwmaField", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "vwmaPeriod", type: "number" },
            { label: "Offset", name: "vwmaOffset", type: "number" },
        ],
    },
    "SMA (Simple Moving Average)": {
        title: "SMA (Simple Moving Average)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "EMA (Exponential Moving Average)": {
        title: "EMA (Exponential Moving Average)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "WMA (Weighted Moving Average)": {
        title: "WMA (Weighted Moving Average)",
        fields: [
            { label: "Field", name: "wmaField", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "wmaPeriod", type: "number" },
            { label: "Offset", name: "wmaOffset", type: "number" },
        ],
    },
    Number: {
        title: "Number",
        fields: [
            { label: "Period", name: "period", type: "number" },
        ],
    },
    "Money Flow Index": {
        title: "Money Flow Index",
        fields: [
            { label: "Period", name: "mfiPeriod", type: "number" },
            { label: "Offset", name: "mfiOffset", type: "number" },
        ],
    },
    "Williams %R": {
        title: "Williams %R",
        fields: [
            { label: "Period", name: "wrPeriod", type: "number" },
            { label: "Offset", name: "wrOffset", type: "number" },
        ],
    },
    "Moving Average Deviation (MA Dev)": {
        title: "Moving Average Deviation (MA Dev)",
        fields: [
            { label: "Field", name: "madField", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Period", name: "madPeriod", type: "number" },
            { label: "Type", name: "madType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "madOffset", type: "number" },
            { label: "Pop", name: "madPop", type: "select", options: ["point", "percent"] },
        ],
    },

    "MACD (Moving Average Convergence/Divergence)": {
        title: "MACD (Moving Average Convergence/Divergence)",
        fields: [
            { label: "Fast Period", name: "macd_fastMaPeriod", type: "number" },
            { label: "Slow Period", name: "macd_slowMaPeriod", type: "number" },
            { label: "Signal Period", name: "macd_signalPeriod", type: "number" },
            { label: "Offset", name: "macdOffset", type: "number" },
        ],
    },
    "OBV (On Balance Volume)": {
        title: "OBV (On Balance Volume)",
        fields: [
            { label: "Offset", name: "obvOffset", type: "number" },
        ],
    },
    "Aroon-Down": {
        title: "Aroon-Down",
        fields: [
            { label: "Period", name: "aroonDownPeriod", type: "number" },
            { label: "Offset", name: "aroonDownOffset", type: "number" },

        ],
    },
    "Aroon-Up": {
        title: "Aroon-Up",
        fields: [
            { label: "Period", name: "aroonUpPeriod", type: "number" },
            { label: "Offset", name: "aroonUpOffset", type: "number" },
        ],
    },
    "Awesome Oscillator": {
        title: "Awesome Oscillator",
        fields: [
            { label: "Offset", name: "aOscilatorOffset", type: "number" },
        ],
    },
    "VWAP (Volume Weighted Average Price)": {
        title: "VWAP (Volume Weighted Average Price)",
        fields: [
            { label: "Offset", name: "vwapOffset", type: "number" },
        ],
    },
    "Parabolic SAR": {
        title: "Parabolic SAR",
        fields: [
            { label: "Mini Af", name: "psarMinimumAF", type: "number" },
            { label: "Max Af", name: "psarMaximumAF", type: "number" },
            { label: "Offset", name: "psarOffset", type: "number" },
        ],
    },
    "Vortex Indicator": {
        title: "Vortex Indicator",
        fields: [
            { label: "Period", name: "vortexIPeriod", type: "number" },
            { label: "Signal", name: "vortexISignal", type: "select", options: ["+ve", "-ve"] },
            { label: "Offset", name: "vortexIOffset", type: "number" },

        ],
    },
    "Volume of a candle": {
        title: "Volume of a candle",
        fields: [
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Donchian Channel": {
        title: "Donchian Channel",
        fields: [
            { label: "Channel", name: "donchianChannel", type: "select", options: ["upper", "lower", "median"] },
            { label: "High Period", name: "donchianHighPeriod", type: "number" },
            { label: "Low Period", name: "donchianLowPeriod", type: "number" },
            { label: "Offset", name: "donchianOffset", type: "number" },
        ],
    },
    "Stochastic": {
        title: "Stochastic",
        fields: [
            { label: "Period", name: "stochasticPeriod", type: "number" },
            { label: "Type", name: "stochasticType", type: "select", options: ["%k", "%d"] },
            { label: "Smoothen", name: "stochasticSmooth", type: "select", options: ["yes", "no"] },
            { label: "Offset", name: "stochasticOffset", type: "number" },
        ],
    },
    "Chaikin Money Flow": {
        title: "Chaikin Money Flow",
        fields: [
            { label: "Period", name: "chaikinPeriod", type: "number" },
            { label: "Offset", name: "chaikinOffset", type: "number" },

        ],
    },
    "MACD-signal": {
        title: "MACD-signal",
        fields: [
            { label: "Fast Ma Period", name: "macdsFastMaPeriod", type: "number" },
            { label: "Slow Ma Period", name: "macdsSlowMaPeriod", type: "number" },
            { label: "Signal Period", name: "macdsSignalPeriod", type: "number" },
            { label: "Offset", name: "macdsOffset", type: "number" },
        ],
    },
    "Commodity Channel Index": {
        title: "Commodity Channel Index",
        fields: [
            { label: "Period", name: "cciPeriod", type: "number" },
            { label: "Offset", name: "cciOffset", type: "number" },
        ],
    },
    "Pivot Points": {
        title: "Pivot Points",
        fields: [
            { label: "Signal", name: "signal", type: "select", options: ["r3", "r2", "r1", "pp", "s1", "s2", "s3"] },
            { label: "Type", name: "type", type: "select", options: ["standard", "fibonacci"] },
            { label: "Continous", name: "continous", type: "select", options: ["no", "yes"] }
        ],
    },
    "Center Of Gravity": {
        title: "Center Of Gravity",
        fields: [
            { label: "Field", name: "rocField", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "rocPeriod", type: "number" },
            { label: "Offset", name: "rocOffset", type: "number" },

        ],
    },
    "Price Rate of Change": {
        title: "Price Rate of Change",
        fields: [
            { label: "Field", name: "rocField", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "rocPeriod", type: "number" },
            { label: "Offset", name: "rocOffset", type: "number" },

        ],
    },
    "RSI Moving Average": {
        title: "RSI Moving Average",
        fields: [
            { label: "Period", name: "rsimaPeriod", type: "number" },
            { label: "Type", name: "rsimaType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "rsimaMaPeriod", type: "number" },
            { label: "Offset", name: "rsimaOffset", type: "number" },
        ],
    },
    "TR (True Range)": {
        title: "TR (True Range)",
        fields: [
            { label: "Offset", name: "trOffset", type: "number" },
        ],
    },
    "ATR (Average True Range)": {
        title: "ATR (Average True Range)",
        fields: [
            { label: "Period", name: "atrPeriod", type: "number" },
            { label: "Offset", name: "atrOffset", type: "number" },
        ],
    },
    "NATR (Normalized Average True Range)": {
        title: "NATR (Normalized Average True Range)",
        fields: [
            { label: "Period", name: "natrPeriod", type: "number" },
            { label: "Offset", name: "natrOffset", type: "number" },

        ],
    },
    "Supertrend": {
        title: "Supertrend",
        fields: [
            { label: "Period", name: "superTPeriod", type: "number" },
            { label: "Multiply", name: "superTMultiply", type: "number" },
            { label: "Offset", name: "superTOffset", type: "number" },
        ],
    },
    "Bollinger %B": {
        title: "Bollinger %B",
        fields: [
            { label: "Field", name: "bolingerBField", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "bolingerBPeriod", type: "number" },
            { label: "Deviation", name: "bolingerBDev", type: "number" },
            { label: "Type", name: "bolingerBType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "bolingerBOffset", type: "number" },

        ],
    },
    "MFI Moving Average (Money Flow Index Moving Average)": {
        title: "MFI Moving Average (Money Flow Index Moving Average)",
        fields: [
            { label: "Period", name: "mfiMAPeriod", type: "number" },
            { label: "Type", name: "mfiMAType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "mfiMAMAPeriod", type: "number" },
            { label: "Offset", name: "mfiMAOffset", type: "number" },
        ],
    },
    "Ichimoku": {
        title: "Ichimoku",
        fields: [
            { label: "Conversion", name: "ichiConversion", type: "number" },
            { label: "Base", name: "ichiBase", type: "number" },
            { label: "leading Span B", name: "leadingSpanB", type: "number" },
            { label: "Lagging Span", name: "laggingSpan", type: "number" },
            { label: "Line", name: "ichiLine", type: "select", options: ["conversion", "base", "leadingSpanA", "leadingSpanB"] },
            { label: "Cloud Shift", name: "cloudShift", type: "select", options: ["yes", "no"] },
            { label: "Offset", name: "ichiOffset", type: "number" },
        ],
    },
    "Know Sure Thing": {
        title: "Know Sure Thing",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Lightest Roc", name: "LTRoc", type: "number" },
            { label: "Lightest Sma", name: "LTSma", type: "number" },
            { label: "Light Roc", name: "LRoc", type: "number" },
            { label: "Light Sma", name: "LSma", type: "number" },
            { label: "Heavy Roc", name: "HRoc", type: "number" },
            { label: "Heavy Sma", name: "HSma", type: "number" },
            { label: "Heaviest Roc", name: "HeRoc", type: "number" },
            { label: "Heaviest Sma", name: "HeSma", type: "number" },
            { label: "Signal Period", name: "SPeriod", type: "number" },
            { label: "Type", name: "Type", type: "select", options: ["kst", "signal"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Elder Force Index": {
        title: "Elder Force Index",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "MOM (Momentum Indicator)": {
        title: "MOM (Momentum Indicator)",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "Standard Deviation": {
        title: "Standard Deviation",
        fields: [
            { label: "Period", name: "stdPeriod", type: "number" },
            { label: "Field", name: "stdField", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Stddev", name: "stdDev", type: "number" },
            { label: "Ma Type", name: "maType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "Ultimate Oscillator": {
        title: "Ultimate Oscillator",
        fields: [
            { label: "Length1", name: "length1", type: "number" },
            { label: "Length2", name: "length2", type: "number" },
            { label: "Length3", name: "length3", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Nth Candle": {
        title: "Nth Candle",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close", "Volume"] },
            { label: "Range", name: "range", type: "select", options: ["1min", "3min", "5min", "10min", "15min", "30min", "hour", "1d"] },
            { label: "Num", name: "num", type: "number" },
        ],
    },
    "MACD-histogram": {
        title: "MACD-histogram",
        fields: [
            { label: "Fast Ma Period", name: "macdsFastMaPeriod", type: "number" },
            { label: "Slow Ma Period", name: "macdsSlowMaPeriod", type: "number" },
            { label: "Signal Period", name: "macdsSignalPeriod", type: "number" },
            { label: "Offset", name: "macdsOffset", type: "number" },

        ],
    },
    "Median Price": {
        title: "Median Price",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Chande Momentum Oscillator": {
        title: "Chande Momentum Oscillator",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "ATR Trailing Stoploss": {
        title: "ATR Trailing Stoploss",
        fields: [
            { label: "Multiply", name: "multiplier", type: "number" },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    }, "Choppiness Index": {
        title: "Choppiness Index",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Camarilla Pivot Points": {
        title: "Camarilla Pivot Points",
        fields: [
            { label: "Signal", name: "signal", type: "select", options: ["pp", "r1", "r2", "r3", "r4", "s1", "s2", "s3", "s4"] },
        ],
    },
    "Central Pivot Range": {
        title: "Central Pivot Range",
        fields: [
            { label: "Signal", name: "signal", type: "select", options: ["pp", "bc", "tc"] },
        ],
    },
    "Elder Ray Index": {
        title: "Elder Ray Index",
        fields: [
            { label: "Power", name: "power", type: "select", options: ["bull", "bear"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "UBB (Upper Bollinger Band)": {
        title: "UBB (Upper Bollinger Band)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Standard Deviations", name: "dev", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "MBB (Middle Bollinger Band)": {
        title: "MBB (Middle Bollinger Band)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Standard Deviations", name: "dev", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "LBB (Lower Bollinger Band)": {
        title: "LBB (Lower Bollinger Band)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Standard Deviations", name: "dev", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "Bollinger Bandwidth": {
        title: "Bollinger Bandwidth",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Standard Deviations", name: "dev", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },


        ],
    }, "Phase Power Moving Average": {
        title: "Phase Power Moving Average",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close", "volume"] },
            { label: "Length", name: "powerLength", type: "number" },
            { label: "Phase", name: "phase", type: "number" },
            { label: "Power", name: "power", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "-DI (Minus Directional Index)": {
        title: "-DI (Minus Directional Index)",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "TSI (True Strength Indicator)": {
        title: "TSI (True Strength Indicator)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Type", name: "type", type: "select", options: ["tsi", "signal"] },
            { label: "Long", name: "long", type: "number" },
            { label: "Short", name: "short", type: "number" },
            { label: "Signal", name: "signal", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "ADX (Average Directional Index)": {
        title: "ADX (Average Directional Index)",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    }, "Time Series Forecast": {
        title: "Time Series Forecast",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "TII (Trend Intensity Index)": {
        title: "TII (Trend Intensity Index)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Type", name: "type", type: "select", options: ["TII", "Signal"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Signal", name: "signal", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Linear Regression Forecast": {
        title: "Linear Regression Forecast",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "Open Interest Moving Average": {
        title: "Open Interest Moving Average",
        fields: [
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "double", "Hull"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "NVI (Negative Volume Index)": {
        title: "NVI (Negative Volume Index)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Line", name: "line", type: "select", options: ["index", "ma"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Option Greeks": {
        title: "Option Greeks",
        fields: [
            { label: "Type", name: "type", type: "select", options: ["delta", "gamma", "theta", "vega", "rho", "iv"] },
            { label: "Dividend", name: "dividend", type: "number" },
            { label: "Interest", name: "interest", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ],
    },
    "Detrended Price Oscillator": {
        title: "Detrended Price Oscillator",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Displacement", name: "displacement", type: "select", options: ["yes", "no"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Klinger Volume Oscillator": {
        title: "Klinger Volume Oscillator",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Short", name: "short", type: "number" },
            { label: "Long", name: "long", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["klinger", "signal", "histogram"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "VWAP Standard Deviation": {
        title: "VWAP Standard Deviation",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Dev", name: "dev", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "True Range Moving Average": {
        title: "True Range Moving Average",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["truerange", "atr", "natr"] },
            { label: "Ma Type", name: "maType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "McGinley Dynamic Indicator": {
        title: "McGinley Dynamic Indicator",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]

    },
    "Vortex Moving Average": {
        title: "Vortex Moving Average",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Signal", name: "signal", type: "select", options: ["+ve", "-ve"] },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Trade Volume Index": {
        title: "Trade Volume Index",
        fields: [
            { label: "Value", name: "value", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Swing Index": {
        title: "Swing Index",
        fields: [
            { label: "Limit Move", name: "limit", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "DI (Plus Directional Index)": {
        title: "DI (Plus Directional Index)",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Keltner Channel": {
        title: "Keltner Channel",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Band", name: "band", type: "select", options: ["top", "median", "bottom"] },
            { label: "Ma Type", name: "maType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Shift", name: "shift", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "VWAP Moving Average": {
        title: "VWAP Moving Average",
        fields: [
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Median Price Moving Average": {
        title: "Median Price Moving Average",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Moving Average": {
        title: "Moving Average",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Moving Average Volume": {
        title: "Moving Average Volume",
        fields: [
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]

    },
    "Bollinger %B Moving Average": {
        title: "Bollinger %B Moving Average",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Bb Period", name: "bPeriod", type: "number" },
            { label: "Deviation", name: "deviation", type: "number" },
            { label: "Bb Type", name: "bType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Type", name: "maType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ]
    },
    "ADX Moving Average (Average Directional Index Moving Average)": {
        title: "ADX Moving Average (Average Directional Index Moving Average)",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["adx", "plusedi", "minusdi"] },
            { label: "Ma Type", name: "maType", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ]
    },
    "Day High Low": {
        title: "Day High Low",
        fields: [
            { label: "Option", name: "option", type: "select", options: ["high", "low"] },

        ]
    },
    "Chande Kroll Stop": {
        title: "Chande Kroll Stop",
        fields: [
            { label: "P", name: "p", type: "number", },
            { label: "X", name: "x", type: "number", },
            { label: "Q", name: "q", type: "number", },
            { label: "Mode", name: "mode", type: "select", options: ["stoplong", "stopshort"] },
            { label: "Offset", name: "offset", type: "number", },


        ]
    },
    "Ehler Fisher": {
        title: "Ehler Fisher",
        fields: [
            { label: "Period", name: "period", type: "number", },
            { label: "Line", name: "line", type: "select", options: ["ef", "trigger"] },
            { label: "Offset", name: "offset", type: "number", },
        ]
    },
    "Price Rate Of Change Moving Average (Proc Moving Average)": {
        title: "Price Rate Of Change Moving Average (Proc Moving Average)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number", },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number", },
            { label: "Offset", name: "offset", type: "number", },

        ]
    },
    "MACD OI (Moving Average Convergence/Divergence Open Interest)": {
        title: "MACD OI (Moving Average Convergence/Divergence Open Interest)",
        fields: [
            { label: "Fast Period", name: "fastMaPeriod", type: "number" },
            { label: "Slow Period", name: "slowMaPeriod", type: "number" },
            { label: "Signal Period", name: "signalPeriod", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["macd", "signal", "histogram"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Previous N": {
        title: "Previous N",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Offset", name: "offset", type: "number" },
            { label: "candle", name: "candle", type: "select", options: ["min", "3min", "5min", "10min", "15min", "30min", "hour", "1d"] }

        ]
    },
    "Intraday Momentum Index Moving Average (IMI Moving Average)": {
        title: "Intraday Momentum Index Moving Average (IMI Moving Average)",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" }

        ]
    },
    "Opening Range": {
        title: "Opening Range",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Range", "name": "range", options: ["min", "3min", "5min", "10min", "15min", "30min", "hour", "1d"] }
        ]
    },
    "Choppiness Index Moving Average" : {
        title: "Choppiness Index Moving Average" ,
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" }
        ]
    },
    "CCI Moving Average (Commodity Channel Index Moving Average)" : {
        title: "CCI Moving Average (Commodity Channel Index Moving Average)" ,
        fields : [
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Ma Period", name: "maPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" }

        ]
    },
    "Schaff Trend Cycle": {
        title: "Schaff Trend Cycle",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Short Cycle", name: "short", type: "number" },
            { label: "Long Cycle", name: "long", type: "number" },
            { label: "Ma Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Aroon Oscillator" : {
        title: "Aroon Oscillator" ,
        fields : [
            {label : "Period", name : "period", type:"number"} , 
            {label: "Offset", name : "offset", type : "number"} ,
        ]
    },
    "Stochastic RSI": {
        title: "Stochastic RSI",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["%k", "%d"] },
            { label: "Smoothen", name: "smooth", type: "select", options: ["yes", "no"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "PVI (Positive Volume Index)": {
        title: "PVI (Positive Volume Index)",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Line", name: "line", type: "select", options: ["index", "ma"] },
            { label: "Offset", name: "offset", type: "number" },
        ],
    },
    "Twiggs Money Flow" : {
        title: "Twiggs Money Flow" ,
        fields : [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "ATR Bands": {
        title: "ATR Bands",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Shift", name: "shift", type: "number" },
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Band", name: "band", type: "select", options: ["top", "bottom"] },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Stochastic Momentum Index" : {
        title: "Stochastic Momentum Index" ,
        fields : [
            { label: "Type", name: "type", type: "select", options: ["%k", "%d"] },
            { label: "%K Period", name: "kPeriod", type: "number" },
            { label: "Smoothing Period", name: "smoothPeriod", type: "number"},
            { label: "Double Smoothing Period", name: "doublePeriod", type: "number"},
            { label: "%D Period", name: "dPeriod", type: "number" },
            { label: "Offset", name: "offset", type: "number" },

        ]
    },
    "Open interest" : {
        title: "Open interest" ,
        fields : [
            {label : "Offset", name : "offset", type : "number"}
        ]
    },
    "Candle Time" : {
        title: "Candle Time" ,
        fields : [
            {label : "Type", name:"type" , type : "select", options : ["minuteofcandle", "hourofcandle", "dayofweek"]} ,
        ]
    },
    "Coppock curve": {
        title: "Coppock curve",
        fields: [
            { label: "Field", name: "field", type: "select", options: ["open", "high", "low", "close"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Sroc", name: "sroc", type: "number" },
            { label: "Lroc", name: "lroc", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Alligator" : {
        title: "Alligator" ,
        fields : [
            { label: "Signal", name: "signal", type: "select", options: ["jaw", "teeth", "lips"] },
            { label: "Jaw Period", name: "jawPeriod", type: "number" },
            { label: "Jaw Offset", name: "jawOffset", type: "number" },
            { label: "Teeth Period", name: "teethPeriod", type: "number" },
            { label: "Teeth Offset", name: "teethOffset", type: "number" },
            { label: "Lips Period", name: "lipsPeriod", type: "number" },
            { label: "Lips Offset", name: "lipsOffset", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Volume Oscillator" : {
        title: "Volume Oscillator" ,
        fields : [
            { label: "Short", name: "short", type: "number" },
            { label: "Long", name: "long", type: "number" },
            { label: "Pop", name: "pop", type: "select", options: ["pct", "point"] },
            { label: "Offset", name: "offset", type: "number" },

        ]
    },
    "OBV Moving Average (On Balance Volume Moving Average)": {
        title: "OBV Moving Average (On Balance Volume Moving Average)",
        fields: [
            { label: "Type", name: "type", type: "select", options: ["SMA", "EMA", "WMA", "DEMA", "TEMA", "TriMA", "Hull"] },
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },
    "Intraday Momentum Index": {
        title: "Intraday Momentum Index",
        fields: [
            { label: "Period", name: "period", type: "number" },
            { label: "Offset", name: "offset", type: "number" },
        ]
    },

};



export { indicatorConfigs };
