import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import ScannerCategoriesSideBar from "./ScannerCategorySideBar";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  getAllDiscoveredScannerApi,
  getDiscoverScannersByIdApi,
  getScannerCategoriesApi,
} from "../../api/scannerapi";
import SkeletonLoader from "../componentCommon/SkeletonLoader";

const PopularScanner = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get("filter");
  const navigate = useNavigate();
  const [selmenu, setSelMenu] = useState();
  const [showFullText, setShowFullText] = useState(false);
  const [scanners, setScanners] = useState();
  const [category, setCategory] = useState();
  const [isPopularData, setIsPopularData] = useState(true) ;
  const [scannerCategory,setScannerCategories]=useState()
  const cardData = [
    {
      id: 1,
      img: "image",
      title: "Chart patterns",
      filter: "chart_pattern",
      backgroudColor: "#c5e4f6",
    },
    {
      id: 2,
      img: "image",
      title: "Webinar Scanners",
      filter: "webinar_scanner",
      backgroudColor: "#cdcfe6",
    },
    {
      id: 3,
      img: "image",
      title: "Bullish",
      filter: "bullish",

      backgroudColor: "#c5e4f6",
    },
    {
      id: 4,
      img: "image",
      title: "Intraday Bullish",
      filter: "intraday_bullish",
      backgroudColor: "#cdcfe6",
    },
    {
      id: 5,
      img: "image",
      title: "Intraday Bearish",
      filter: "intraday_bearish",
      backgroudColor: "#c5e4f6",
    },
    {
      id: 6,
      img: "image",
      title: "Range Breakout",
      filter: "range_breakout",
      backgroudColor: "#cdcfe6",
    },
    {
      id: 7,
      img: "image",
      title: "Top Gainers",
      filter: "top_gainers",
      backgroudColor: "#c5e4f6",
    },
    {
      id: 8,
      img: "image",
      title: "Crossover",
      filter: "crossover",
      backgroudColor: "#cdcfe6",
    },
    {
      id: 9,
      img: "image",
      title: "Top Losers",
      filter: "top_losers",
      backgroudColor: "#c5e4f6",
    },
    {
      id: 10,
      img: "image",
      title: "Bearish",
      filter: "bearish",
      backgroudColor: "#cdcfe6",
    },
    {
      id: 11,
      img: "image",
      title: "Popular Scanner",
      filter: "popular_scanner",
    },
  ];

  useEffect(() => {
    getScannerCategories();
  }, [filter]);

  const getScannerCategories = async () => {
    const result = await getScannerCategoriesApi();
    setScannerCategories(result);
    let category = result?.filter((item) => item?.id == filter);
    setCategory(category[0]?.name);
  };

  useEffect(() => {
    if (filter === null) getDiscoveredScanners();
    else getScannersById(filter);
  }, [filter]);

  const getDiscoveredScanners = async () => {
    const result = await getAllDiscoveredScannerApi();
    const updatedResult = result?.map((info) => {
      const card = cardData?.find(
        (card) => card.id === info?.scanner_details.category_id
      );
      return card
        ? {
            ...info,
            img: card.img,
            title: card.title,
            backgroundColor: card.backgroudColor,
            filter: card.filter,
          }
        : info;
    });
    setScanners(updatedResult);
    setSelMenu(updatedResult);
    setIsPopularData(false);
  };

  const getScannersById = async (id) => {
    const result = await getDiscoverScannersByIdApi({
      category_id: id,
    });
    const updatedResult = result?.map((info) => {
      const card = cardData?.find(
        (card) => card.id == info?.scanner_details.category_id
      );
      return card
        ? {
            ...info,
            img: card.img,
            title: card.title,
            backgroundColor: card.backgroudColor,
            filter: card.filter,
          }
        : info;
    });


    setScanners(updatedResult);
    setSelMenu(updatedResult);
    setIsPopularData(false);
  };

  useEffect(() => {
    if (filter !== null) {
      const data = scanners?.filter(
        (item) => item?.scanner_details?.category_id === filter
      );
      // setSelMenu(data);
    }
  }, [filter]);

  const text =
    "The strategy uses one indicator to check for positive trend in the stock and the other indicates begining of a positive move. Trending markets are ideal for this to work well. Comparing MACD with MACD signal depicts positive momentum and RSI crossover confirms initiation of a new bullish move.";

  const wordsToShowInitially = 20;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const displayText =
    showFullText || text.split(" ").length <= wordsToShowInitially
      ? text
      : text.split(" ").slice(0, wordsToShowInitially).join(" ");

  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer" id="popular-scanner-containt">
          <Row>
            <Col lg={9} md={9} sm={12} xs={12}>
              {filter !== null &&
                selmenu &&
                selmenu[0]?.filter !== "popular_scanner" && (
                  <Row className="py-3">
                    <Col>
                      <Card style={{ backgroundColor: "#c5e4f6" }}>
                        <Card.Body>
                          <Row className="p-3">
                            <Col
                              lg={2}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <i className="fa fa-plus"></i>
                            </Col>
                            <Col lg={9} className="fs-4 fw-bold">
                              {category}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                )}
              <Row className="title-section">
                <Col lg sm md xs>
                  <i
                    className="fa fa-chevron-left chev fs-5 pointer-cursor"
                    onClick={() => {
                      navigate("/scanners");
                    }}
                  ></i>
                  <span>
                    {""}
                    {selmenu === ""
                      ? "Discover Scanners"
                      : selmenu?.title
                      ? selmenu?.title
                      : "Discover"}{" "}
                  </span>
                  <Button
                    className="btn-color pull-right"
                    onClick={() => navigate("/scanners")}
                  >
                    <i className="fa fa-plus"></i>&nbsp;&nbsp; Create New
                    Scanner
                  </Button>
                </Col>
              </Row>
              <div className="scrollable-container">
                {isPopularData
                  ? Array.from({ length: 3 }).map((_, i) => {
                      return (
                        <SkeletonLoader
                          width="100%"
                          height="150px"
                          borderRadius="8px"
                        />
                      );
                    })
                  : scanners?.map((item) => (
                      <Row className="discover-card popular-scanner-card ms-3 mt-3 pe-4 pointer-cursor" style={{width : "95%"}}>
                        <Card>
                          <Card.Body className="p-0">
                            <Row>
                              <Col
                                lg={2}
                                md={2}
                                sm={12}
                                className="d-flex flex-column align-items-center discover-stocks-tab p-0"
                              >
                                <span
                                  style={{ height: "25%" }}
                                  className="p-2 stock-name"
                                >
                                  {item?.scanner_details?.scan_on}
                                </span>
                                <span
                                  className="d-flex justify-content-center  align-items-center stock-number"
                                  style={{ height: "55%" }}
                                >
                                  {item?.scanner_details?.stock_count}
                                </span>
                                <span
                                  className="d-flex  justify-content-center align-items-center stock-title"
                                  style={{ height: "20%" }}
                                >
                                  Matches
                                </span>
                              </Col>
                              <Col
                                lg={10}
                                md={10}
                                sm={12}
                                xs={12}
                                className="py-4"
                              >
                                <Container className="fw-bold d-flex justify-content-between">
                                  <div>
                                    <span>
                                      {item?.scanner_name?.length > 30
                                        ? `${item?.scanner_name?.slice(
                                            0,
                                            30
                                          )}...`
                                        : item?.scanner_name}
                                    </span>
                                    &nbsp;&nbsp;
                                    <i className="fa fa-star"></i>
                                  </div>
                                  <div>
                                    <span className="scan-date">
                                      24 Aug 2024
                                    </span>
                                    &nbsp;
                                    <span className="scan-status">Daily</span>
                                  </div>
                                </Container>
                                <Container className="sub-text">
                                  {displayText}
                                  {text.split(" ")?.length >
                                    wordsToShowInitially && (
                                    <span>
                                      {!showFullText ? "..." : " "}
                                      <Link onClick={toggleText}>
                                        {showFullText
                                          ? "Read Less"
                                          : "Read More"}
                                      </Link>
                                    </span>
                                  )}
                                </Container>
                                <Row className="mt-4">
                                  <Col
                                    lg={3}
                                    className=""
                                    onClick={() =>
                                      navigate(
                                        "/discover_scanner/result/" +
                                          item?.scanner_id,
                                        {
                                          state: {
                                            isDiscover: true,
                                          },
                                        }
                                      )
                                    }
                                  >
                                    <Button className="scan-show-result-btn">
                                      <i className="fa fa-file-text-o"></i>
                                      &nbsp; Show Result
                                    </Button>
                                  </Col>
                                  <Col lg={6} sm md={6} xs className="d-flex ">
                                    <Button
                                      variant="outline-primary"
                                      className="rounded-circle p-0 border-0"
                                    >
                                      <img
                                        src="/icons/Ellipse9.png"
                                        alt="img"
                                      />
                                      {/* <i className="fa fa-plus"></i> */}
                                    </Button>
                                    &nbsp;
                                    <Button
                                      variant="outline-primary"
                                      className="rounded-circle p-0 border-0"
                                    >
                                      {/* <i className="fa fa-plus"></i> */}
                                      <img
                                        src="/icons/Ellipse 12.png"
                                        alt="img"
                                      />
                                    </Button>
                                    &nbsp;
                                    <Button
                                      variant="outline-primary"
                                      className="rounded-circle p-0 border-0"
                                    >
                                      {/* <i className="fa fa-plus"></i> */}
                                      <img
                                        src="/icons/Ellipse 10.png"
                                        alt="img"
                                      />
                                    </Button>
                                    &nbsp;
                                  </Col>
                                  {/* <Col lg={3} className="">
                              <Button className="pull-right scan-take-live-btn">
                                <i className="fa fa-wifi"></i>&nbsp; Take Live
                              </Button>
                            </Col> */}
                                </Row>
                              </Col>
                              {/* <Col lg={3} md={3} sm={12} xs={12}>
                                  Chart
                                  <div className="end-0 top-50 translate-middle-y next-icon">
                                      <i className="fa fa-chevron-right"></i>
                                  </div>
                              </Col> */}
                            </Row>
                          </Card.Body>
                        </Card>
                      </Row>
                    ))}
                {scanners?.length === 0 && (
                  <div style={{ marginTop: "10%", textAlign: "center" }}>
                    <span className="p-0">No Scanners Available</span>
                  </div>
                )}
              </div>
            </Col>
            <Col lg={3} md={3} sm={12} xs={12} id="popular-scanner-side-bar">
              <ScannerCategoriesSideBar scannerCategory={scannerCategory} />
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default PopularScanner;
