import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import Chart from "react-apexcharts";
import { Card, Col, Form, Row, Table } from "react-bootstrap";
import { getBackTestResultApi } from "../../api/strategyapi";
import { useParams } from "react-router-dom";
import moment from "moment";
import { getUser } from "../../Session";
import ApexCharts from "apexcharts";



const BackTestResultStrategy = () => {

    const SCROLL_SENSITIVITY = 0.0005;
    const MAX_ZOOM = 10;
    const MIN_ZOOM = 0.3;

    const { id } = useParams();
    const [shearData , setShearData] = useState([]);
    const [selectedRow , setSelectedRow] = useState(null);
    const [selectedShare , setSelectedShare] = useState(null);
    const elementRef = useRef(null);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [zoom, setZoom] = useState(0.4);
    const [draggind, setDragging] = useState(false);

    const [cumulativeData ,setCumulativeData] = useState(null);
    const [transactionDetails , setTransactionDetails] = useState(null);

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    const showScroll = () => {
        const scrollValue = elementRef.current.scrollLeft;
        // if(scrollValue > 300 ){
        //   const candledata = [{
        //     name: 'candle',
        //     data: selectedShare.data.slice(50,100)
        //   }
        //   ];
        //   console.log("candledata",candledata);
        //   setCandleSeries(candledata);
        // }else if(scrollValue === 0 || scrollValue < 300){
        //   const candledata = [{
        //     name: 'candle',
        //     data: selectedShare.data.slice(0,50)
        //   }
        //   ];
        //   console.log("candledata",candledata);
        //   setCandleSeries(candledata);
        // }
    };
    const [series, setSeries] = useState([
        {
          // name: "Desktops",
          data: [50, 300, 55, 500, 100],
        },
      ]);

      const [showMM ,setShowMM] = useState(false);
      const [xaxisDates ,setXaxisDates] = useState([{
        mindate : '',
        maxDate : ''
      }])
    
    
      const [selection , setSelection] = useState("one_year");

      const data = [
        [
            1708506900000,
            765.1,
            765.1,
            760.65,
            760.65
        ],
        [
            1708506960000,
            761.95,
            761.95,
            758.7,
            760.3
        ],
        [
            1708507020000,
            761,
            761,
            759,
            759
        ],
        [
            1708507080000,
            759,
            760,
            758.25,
            758.25
        ],
        [
            1708507140000,
            758.25,
            759.5,
            758,
            759.5
        ],
        [
            1708507200000,
            758.5,
            759.45,
            756.6,
            756.6
        ],
        [
            1708507260000,
            756.6,
            757.15,
            756.6,
            756.6
        ],
        [
            1708507320000,
            756.05,
            756.05,
            753.45,
            753.45
        ],
        [
            1708507380000,
            753.5,
            756.85,
            753.5,
            756.85
        ],
        [
            1708507440000,
            757,
            758,
            756.9,
            757.85
        ],
        [
            1708507500000,
            758.1,
            758.65,
            757.3,
            757.3
        ],
        [
            1708507560000,
            757.85,
            758.55,
            757.85,
            758.5
        ],
        [
            1708507620000,
            757.85,
            758.15,
            757.55,
            758.05
        ],
        [
            1708507680000,
            758.3,
            758.3,
            758.2,
            758.2
        ],
        [
            1708507740000,
            758,
            758.65,
            758,
            758.5
        ],
        [
            1708507800000,
            758.25,
            758.25,
            756.75,
            756.75
        ],
        [
            1708507860000,
            757.2,
            758.15,
            757,
            757.6
        ],
        [
            1708507920000,
            757.7,
            757.7,
            757.7,
            757.7
        ],
        [
            1708507980000,
            758.1,
            758.1,
            757.9,
            757.9
        ],
        [
            1708508040000,
            757.55,
            757.95,
            757.3,
            757.3
        ],
        [
            1708508100000,
            757.3,
            758,
            757.3,
            757.55
        ],
        [
            1708508160000,
            757.95,
            758.05,
            757.8,
            758.05
        ],
        [
            1708508220000,
            758.25,
            758.4,
            758.25,
            758.3
        ],
        [
            1708508280000,
            758.55,
            759.05,
            758.25,
            759.05
        ],
        [
            1708508340000,
            758.65,
            759.05,
            758.6,
            759
        ],
        [
            1708508400000,
            758.8,
            758.8,
            758.35,
            758.4
        ],
        [
            1708508460000,
            759.15,
            759.45,
            758.45,
            758.85
        ],
        [
            1708508520000,
            758.6,
            758.6,
            757.95,
            757.95
        ],
        [
            1708508580000,
            757.8,
            757.8,
            757.05,
            757.05
        ],
        [
            1708508640000,
            757.3,
            758,
            757.3,
            758
        ],
        [
            1708508700000,
            757.9,
            758.05,
            757.9,
            758.05
        ],
        [
            1708508760000,
            758.05,
            758.1,
            756.4,
            756.4
        ],
        [
            1708508820000,
            756.95,
            758.1,
            756.85,
            758.1
        ],
        [
            1708508880000,
            758,
            758.05,
            757.1,
            758
        ],
        [
            1708508940000,
            757.6,
            757.6,
            757.45,
            757.5
        ],
        [
            1708509000000,
            757.55,
            757.55,
            757.3,
            757.3
        ],
        [
            1708509060000,
            757.6,
            757.75,
            757.2,
            757.75
        ],
        [
            1708509120000,
            757.6,
            757.85,
            757.6,
            757.85
        ],
        [
            1708509180000,
            757.9,
            757.9,
            757.9,
            757.9
        ],
        [
            1708509240000,
            757.6,
            757.6,
            756.3,
            756.3
        ],
        [
            1708509300000,
            756.85,
            756.85,
            756.6,
            756.6
        ],
        [
            1708509360000,
            756.9,
            756.9,
            754.35,
            755
        ],
        [
            1708509420000,
            755,
            755.1,
            754.8,
            755.1
        ],
        [
            1708509480000,
            755.65,
            755.75,
            755.45,
            755.75
        ],
        [
            1708509540000,
            755.75,
            755.75,
            755.05,
            755.05
        ],
        [
            1708509600000,
            755.65,
            755.7,
            755.05,
            755.05
        ],
        [
            1708509660000,
            755.7,
            755.7,
            754.8,
            754.8
        ],
        [
            1708509720000,
            753.75,
            754.6,
            753.75,
            754.6
        ],
        [
            1708509780000,
            754.65,
            754.8,
            754.5,
            754.5
        ],
        [
            1708509840000,
            754.7,
            754.95,
            754,
            754
        ],
        [
            1708509900000,
            754.25,
            754.6,
            753.85,
            754.4
        ],
        [
            1708509960000,
            754.45,
            754.55,
            754.3,
            754.3
        ],
        [
            1708510020000,
            754.35,
            754.35,
            754.3,
            754.3
        ],
        [
            1708510080000,
            754.3,
            754.35,
            754.3,
            754.35
        ],
        [
            1708510140000,
            754.3,
            754.65,
            754.25,
            754.65
        ],
        [
            1708510200000,
            754.8,
            755.05,
            754.8,
            754.95
        ],
        [
            1708510260000,
            754.8,
            754.95,
            754.8,
            754.9
        ],
        [
            1708510320000,
            754.8,
            755.25,
            754.8,
            755.2
        ],
        [
            1708510380000,
            755.1,
            755.1,
            754.35,
            754.35
        ],
        [
            1708510440000,
            754.35,
            754.85,
            754.35,
            754.85
        ],
        [
            1708510500000,
            754.8,
            755.25,
            754.8,
            755.2
        ],
        [
            1708510560000,
            755.5,
            755.75,
            755.4,
            755.75
        ],
        [
            1708510620000,
            755.75,
            755.75,
            754.6,
            755.35
        ],
        [
            1708510680000,
            755,
            755.6,
            755,
            755
        ],
        [
            1708510740000,
            755.25,
            755.25,
            755,
            755.2
        ],
        [
            1708510800000,
            755,
            755,
            755,
            755
        ],
        [
            1708510860000,
            755,
            755,
            754.4,
            754.4
        ],
        [
            1708510920000,
            754.4,
            754.4,
            753.2,
            754
        ],
        [
            1708510980000,
            754,
            754.2,
            753.85,
            754.15
        ],
        [
            1708511040000,
            754.2,
            754.8,
            754.15,
            754.8
        ],
        [
            1708511100000,
            754.85,
            755.15,
            754.85,
            755.05
        ],
        [
            1708511160000,
            755.05,
            755.7,
            754.8,
            754.8
        ],
        [
            1708511220000,
            755.1,
            755.2,
            754.1,
            754.1
        ],
        [
            1708511280000,
            754.15,
            754.15,
            753.55,
            753.65
        ],
        [
            1708511340000,
            753.8,
            753.85,
            753.05,
            753.05
        ],
        [
            1708511400000,
            753.05,
            754.55,
            753.05,
            754.55
        ],
        [
            1708511460000,
            754.55,
            755.75,
            754.55,
            755.75
        ],
        [
            1708511520000,
            755.95,
            756.7,
            755.3,
            755.3
        ],
        [
            1708511580000,
            755.15,
            756.05,
            755.1,
            756.05
        ],
        [
            1708511640000,
            756.1,
            756.1,
            755.55,
            755.65
        ],
        [
            1708511700000,
            756,
            756,
            755,
            755.3
        ],
        [
            1708511760000,
            755.65,
            755.65,
            755.05,
            755.3
        ],
        [
            1708511820000,
            755.3,
            755.3,
            755,
            755.15
        ],
        [
            1708511880000,
            755.25,
            755.3,
            755,
            755.3
        ],
        [
            1708511940000,
            755.3,
            755.5,
            755,
            755.45
        ],
        [
            1708512000000,
            755.5,
            755.5,
            752.5,
            754
        ],
        [
            1708512060000,
            753.95,
            755.45,
            753.95,
            755.2
        ],
        [
            1708512120000,
            755.2,
            755.5,
            754.35,
            754.95
        ],
        [
            1708512180000,
            754.95,
            755.1,
            754.8,
            755
        ],
        [
            1708512240000,
            755,
            755,
            754.1,
            754.35
        ],
        [
            1708512300000,
            754.45,
            754.45,
            753.1,
            754.1
        ],
        [
            1708512360000,
            753.85,
            754.5,
            753.85,
            754.5
        ],
        [
            1708512420000,
            754.45,
            754.55,
            754,
            754
        ],
        [
            1708512480000,
            754,
            754,
            753.25,
            753.55
        ],
        [
            1708512540000,
            753.7,
            754.6,
            753.5,
            754.45
        ],
        [
            1708512600000,
            754.45,
            754.45,
            753.25,
            753.95
        ],
        [
            1708512660000,
            754,
            754.05,
            752.8,
            752.8
        ],
        [
            1708512720000,
            752.7,
            752.7,
            751.5,
            751.6
        ],
        [
            1708512780000,
            751.6,
            752.1,
            751.6,
            752.05
        ],
        [
            1708512840000,
            752.05,
            752.6,
            752.05,
            752.05
        ],
        [
            1708512900000,
            752.25,
            752.25,
            751.55,
            751.55
        ],
        [
            1708512960000,
            751.7,
            752.45,
            751.7,
            752.3
        ],
        [
            1708513020000,
            752.2,
            753.5,
            752.15,
            753.5
        ],
        [
            1708513080000,
            753.5,
            753.75,
            752.6,
            752.6
        ],
        [
            1708513140000,
            752.55,
            753.25,
            752.55,
            752.95
        ],
        [
            1708513200000,
            753.4,
            753.4,
            752.6,
            753.3
        ],
        [
            1708513260000,
            753.5,
            753.5,
            753,
            753
        ],
        [
            1708513320000,
            753,
            753.5,
            752.75,
            753.4
        ],
        [
            1708513380000,
            753.45,
            753.45,
            753,
            753.15
        ],
        [
            1708513440000,
            753,
            753.35,
            751.95,
            753.35
        ],
        [
            1708513500000,
            753.1,
            753.45,
            753.1,
            753.4
        ],
        [
            1708513560000,
            753.55,
            753.8,
            753.15,
            753.8
        ],
        [
            1708513620000,
            753.6,
            754,
            753.5,
            753.75
        ],
        [
            1708513680000,
            754.1,
            754.4,
            753.7,
            754.05
        ],
        [
            1708513740000,
            753.5,
            754.4,
            753.5,
            754.4
        ],
        [
            1708513800000,
            754.05,
            754.5,
            754.05,
            754.5
        ],
        [
            1708513860000,
            754.1,
            754.5,
            753.85,
            754.45
        ],
        [
            1708513920000,
            754.3,
            754.4,
            753.55,
            754.35
        ],
        [
            1708513980000,
            754.1,
            754.5,
            754.1,
            754.25
        ],
        [
            1708514040000,
            754.5,
            754.5,
            754.2,
            754.2
        ],
        [
            1708514100000,
            754.35,
            754.5,
            753.8,
            753.8
        ],
        [
            1708514160000,
            753.7,
            755.1,
            753.7,
            755.1
        ],
        [
            1708514220000,
            755.3,
            755.3,
            754.6,
            755
        ],
        [
            1708514280000,
            755.05,
            755.15,
            754.4,
            754.9
        ],
        [
            1708514340000,
            754.75,
            754.75,
            753.6,
            753.95
        ],
        [
            1708514400000,
            753.95,
            754.3,
            753.15,
            753.35
        ],
        [
            1708514460000,
            754,
            754,
            752.9,
            753.15
        ],
        [
            1708514520000,
            753.1,
            753.2,
            751.7,
            752.2
        ],
        [
            1708514580000,
            752.3,
            753,
            751.05,
            753
        ],
        [
            1708514640000,
            753.2,
            753.4,
            752.5,
            752.9
        ],
        [
            1708514700000,
            752.85,
            753.55,
            752.3,
            753.55
        ],
        [
            1708514760000,
            753.55,
            753.55,
            752.8,
            752.85
        ],
        [
            1708514820000,
            753.35,
            753.35,
            752.6,
            753
        ],
        [
            1708514880000,
            753.3,
            753.4,
            753.05,
            753.4
        ],
        [
            1708514940000,
            753.4,
            753.5,
            753.15,
            753.5
        ],
        [
            1708515000000,
            753.5,
            753.5,
            753.35,
            753.5
        ],
        [
            1708515060000,
            753.5,
            753.5,
            752.7,
            753.2
        ],
        [
            1708515120000,
            753.2,
            753.5,
            752.95,
            753.5
        ],
        [
            1708515180000,
            753.5,
            753.8,
            753.5,
            753.5
        ],
        [
            1708515240000,
            753.45,
            753.8,
            753.45,
            753.5
        ],
        [
            1708515300000,
            753.6,
            753.65,
            752.5,
            752.5
        ],
        [
            1708515360000,
            753,
            753.4,
            752.75,
            753
        ],
        [
            1708515420000,
            753.1,
            753.65,
            752.65,
            753.4
        ],
        [
            1708515480000,
            753.35,
            753.65,
            753.2,
            753.55
        ],
        [
            1708515540000,
            753.6,
            754,
            753.6,
            753.85
        ],
        [
            1708515600000,
            754,
            754,
            753.6,
            753.85
        ],
        [
            1708515660000,
            753.5,
            753.7,
            753.25,
            753.25
        ],
        [
            1708515720000,
            753.6,
            753.9,
            753.25,
            753.85
        ],
        [
            1708515780000,
            753.7,
            754,
            753.5,
            753.75
        ],
        [
            1708515840000,
            753.75,
            753.85,
            753.25,
            753.5
        ],
        [
            1708515900000,
            753.75,
            753.75,
            753.25,
            753.65
        ],
        [
            1708515960000,
            753.25,
            753.8,
            753.25,
            753.25
        ],
        [
            1708516020000,
            753.7,
            753.8,
            753.05,
            753.25
        ],
        [
            1708516080000,
            753.2,
            753.65,
            753.2,
            753.65
        ],
        [
            1708516140000,
            753.5,
            753.75,
            753.1,
            753.1
        ],
        [
            1708516200000,
            753.65,
            753.65,
            752.9,
            753.3
        ],
        [
            1708516260000,
            753.2,
            753.45,
            752.85,
            752.85
        ],
        [
            1708516320000,
            753.3,
            753.5,
            752.75,
            753.5
        ],
        [
            1708516380000,
            753.65,
            753.95,
            753.1,
            753.8
        ],
        [
            1708516440000,
            753.6,
            753.85,
            753.05,
            753.05
        ],
        [
            1708516500000,
            753.2,
            753.35,
            752.65,
            753.35
        ],
        [
            1708516560000,
            753.45,
            753.9,
            753.3,
            753.55
        ],
        [
            1708516620000,
            753.65,
            753.8,
            753.35,
            753.6
        ],
        [
            1708516680000,
            753.6,
            753.6,
            752.9,
            753.25
        ],
        [
            1708516740000,
            753.4,
            753.6,
            752.95,
            753.1
        ],
        [
            1708516800000,
            753.25,
            753.55,
            753.2,
            753.4
        ],
        [
            1708516860000,
            753.45,
            753.55,
            753.4,
            753.55
        ],
        [
            1708516920000,
            753.25,
            754,
            753.25,
            754
        ],
        [
            1708516980000,
            754,
            754,
            753.95,
            753.95
        ],
        [
            1708517040000,
            754,
            754,
            754,
            754
        ],
        [
            1708517100000,
            754,
            754,
            754,
            754
        ],
        [
            1708517160000,
            754.7,
            754.9,
            754.15,
            754.15
        ],
        [
            1708517220000,
            754.5,
            754.7,
            754.3,
            754.3
        ],
        [
            1708517280000,
            754.4,
            754.4,
            754,
            754.2
        ],
        [
            1708517340000,
            754.05,
            754.05,
            753.05,
            753.45
        ],
        [
            1708517400000,
            753.6,
            754.45,
            753.6,
            754.15
        ],
        [
            1708517460000,
            754.45,
            754.45,
            753.95,
            754
        ],
        [
            1708517520000,
            754,
            754.55,
            754,
            754.45
        ],
        [
            1708517580000,
            754.65,
            754.65,
            754.4,
            754.45
        ],
        [
            1708517640000,
            754.5,
            754.9,
            754.5,
            754.9
        ],
        [
            1708517700000,
            754.85,
            755.5,
            754.85,
            755.5
        ],
        [
            1708517760000,
            755.55,
            755.55,
            755,
            755
        ],
        [
            1708517820000,
            755.2,
            755.4,
            755.2,
            755.2
        ],
        [
            1708517880000,
            755.2,
            755.2,
            754.25,
            754.25
        ],
        [
            1708517940000,
            754.25,
            755.1,
            754.25,
            754.7
        ],
        [
            1708518000000,
            754.65,
            755,
            754.4,
            755
        ],
        [
            1708518060000,
            755.2,
            755.2,
            754.8,
            755
        ],
        [
            1708518120000,
            754.7,
            755.25,
            754.7,
            755.25
        ],
        [
            1708518180000,
            755,
            755,
            754.2,
            754.75
        ],
        [
            1708518240000,
            754.8,
            755,
            754.8,
            754.9
        ],
        [
            1708518300000,
            754.9,
            754.9,
            754.85,
            754.85
        ],
        [
            1708518360000,
            754.85,
            754.9,
            754.75,
            754.75
        ],
        [
            1708518420000,
            754.75,
            755.6,
            754.6,
            755.6
        ],
        [
            1708518480000,
            755.6,
            755.7,
            755.3,
            755.7
        ],
        [
            1708518540000,
            755.7,
            755.7,
            754.95,
            755.7
        ],
        [
            1708518600000,
            755.75,
            756,
            755.4,
            755.4
        ],
        [
            1708518660000,
            755.9,
            756,
            755.6,
            756
        ],
        [
            1708518720000,
            755.85,
            755.85,
            755.65,
            755.65
        ],
        [
            1708518780000,
            755.05,
            755.75,
            755.05,
            755.6
        ],
        [
            1708518840000,
            755.05,
            755.6,
            755.05,
            755.6
        ],
        [
            1708518900000,
            755.6,
            755.6,
            755.35,
            755.35
        ],
        [
            1708518960000,
            755.3,
            755.3,
            754.6,
            754.6
        ],
        [
            1708519020000,
            755.2,
            755.85,
            755.2,
            755.65
        ],
        [
            1708519080000,
            755.5,
            755.5,
            755,
            755.4
        ],
        [
            1708519140000,
            755.4,
            755.4,
            755.4,
            755.4
        ],
        [
            1708519200000,
            755.4,
            755.4,
            754.8,
            754.8
        ],
        [
            1708519260000,
            755.15,
            755.2,
            754.65,
            754.65
        ],
        [
            1708519320000,
            755,
            755.2,
            754.75,
            755.2
        ],
        [
            1708519380000,
            755.2,
            755.2,
            755,
            755
        ],
        [
            1708519440000,
            755,
            755,
            754.65,
            754.65
        ],
        [
            1708519500000,
            754.9,
            754.95,
            754.85,
            754.85
        ],
        [
            1708519560000,
            754.95,
            755.4,
            754.95,
            755.3
        ],
        [
            1708519620000,
            755.3,
            755.3,
            755,
            755.15
        ],
        [
            1708519680000,
            755.45,
            755.5,
            755.3,
            755.5
        ],
        [
            1708519740000,
            755.5,
            755.5,
            755.3,
            755.3
        ],
        [
            1708519800000,
            755.5,
            755.5,
            755.5,
            755.5
        ],
        [
            1708519860000,
            755.6,
            756.05,
            755.6,
            756.05
        ],
        [
            1708519920000,
            756.05,
            756.2,
            756,
            756
        ],
        [
            1708519980000,
            756,
            756.3,
            755.35,
            756
        ],
        [
            1708520040000,
            755.85,
            756.75,
            755.85,
            756.75
        ],
        [
            1708520100000,
            756.75,
            756.75,
            756.05,
            756.65
        ],
        [
            1708520160000,
            756.65,
            756.95,
            756.65,
            756.9
        ],
        [
            1708520220000,
            756.8,
            756.85,
            756.25,
            756.25
        ],
        [
            1708520280000,
            756.25,
            756.25,
            755.95,
            755.95
        ],
        [
            1708520340000,
            755.8,
            755.8,
            755.2,
            755.35
        ],
        [
            1708520400000,
            755.1,
            755.45,
            754.75,
            755.45
        ],
        [
            1708520460000,
            755.7,
            756.15,
            755.7,
            756.15
        ],
        [
            1708520520000,
            756.05,
            756.25,
            755.85,
            755.85
        ],
        [
            1708520580000,
            756.25,
            756.35,
            756.05,
            756.05
        ],
        [
            1708520640000,
            756.05,
            756.15,
            756.05,
            756.15
        ],
        [
            1708520700000,
            755.55,
            755.55,
            755.3,
            755.3
        ],
        [
            1708520760000,
            755.35,
            755.35,
            755.35,
            755.35
        ],
        [
            1708520880000,
            754.3,
            756.25,
            754.1,
            756.2
        ],
        [
            1708520940000,
            756.15,
            756.35,
            756.15,
            756.15
        ],
        [
            1708521000000,
            755.95,
            756.2,
            755.6,
            756.1
        ],
        [
            1708521060000,
            755.9,
            755.9,
            755.9,
            755.9
        ],
        [
            1708521120000,
            755.85,
            755.85,
            755.85,
            755.85
        ],
        [
            1708521180000,
            755.95,
            756.1,
            755.85,
            755.85
        ],
        [
            1708521240000,
            756,
            756.05,
            755.45,
            755.9
        ],
        [
            1708521300000,
            755.75,
            756,
            755.7,
            756
        ],
        [
            1708521360000,
            755.75,
            755.75,
            755.75,
            755.75
        ],
        [
            1708521420000,
            755.75,
            756.75,
            755.75,
            756.7
        ],
        [
            1708521480000,
            756.35,
            756.8,
            756.35,
            756.8
        ],
        [
            1708521540000,
            756.7,
            756.7,
            756.65,
            756.65
        ],
        [
            1708521600000,
            756.7,
            756.7,
            756.25,
            756.45
        ],
        [
            1708521660000,
            756.05,
            756.2,
            755.9,
            756.2
        ],
        [
            1708521720000,
            755.6,
            756.35,
            755.6,
            756.3
        ],
        [
            1708521780000,
            756.2,
            756.4,
            755.75,
            755.75
        ],
        [
            1708521840000,
            756.05,
            756.05,
            755.5,
            755.5
        ],
        [
            1708521900000,
            755.95,
            755.95,
            755.95,
            755.95
        ],
        [
            1708521960000,
            755.35,
            755.65,
            755.35,
            755.65
        ],
        [
            1708522020000,
            755.4,
            756,
            755.35,
            755.35
        ],
        [
            1708522080000,
            755.8,
            755.8,
            755.25,
            755.25
        ],
        [
            1708522140000,
            755.85,
            755.85,
            755.25,
            755.75
        ],
        [
            1708522200000,
            755.8,
            755.8,
            755,
            755.6
        ],
        [
            1708522260000,
            755.5,
            755.5,
            755.5,
            755.5
        ],
        [
            1708522320000,
            755.05,
            755.55,
            755,
            755
        ],
        [
            1708522380000,
            755,
            755.2,
            755,
            755
        ],
        [
            1708522440000,
            755,
            755.35,
            754.65,
            755.35
        ],
        [
            1708522500000,
            755.4,
            756.35,
            755.25,
            756.3
        ],
        [
            1708522560000,
            756.3,
            756.45,
            755.9,
            756.45
        ],
        [
            1708522620000,
            755.85,
            755.85,
            755,
            755
        ],
        [
            1708522680000,
            755.6,
            755.6,
            755.1,
            755.1
        ],
        [
            1708522740000,
            755.65,
            755.65,
            755.65,
            755.65
        ],
        [
            1708522800000,
            755.65,
            755.75,
            755.25,
            755.25
        ],
        [
            1708522860000,
            755.7,
            755.75,
            755.65,
            755.65
        ],
        [
            1708522920000,
            755.45,
            755.5,
            755,
            755.15
        ],
        [
            1708522980000,
            755.4,
            755.5,
            755,
            755.25
        ],
        [
            1708523040000,
            755,
            755,
            754.75,
            755
        ],
        [
            1708523100000,
            754.85,
            755.15,
            754.15,
            754.4
        ],
        [
            1708523160000,
            754.6,
            754.6,
            753.9,
            754.55
        ],
        [
            1708523220000,
            754.6,
            754.75,
            753.5,
            753.5
        ],
        [
            1708523280000,
            753.7,
            753.7,
            753,
            753
        ],
        [
            1708523340000,
            753.5,
            753.8,
            753.5,
            753.7
        ],
        [
            1708523400000,
            754.1,
            754.55,
            754.1,
            754.1
        ],
        [
            1708523460000,
            754.05,
            754.9,
            754.05,
            754.9
        ],
        [
            1708523520000,
            754.8,
            755,
            754.6,
            754.95
        ],
        [
            1708523580000,
            755.25,
            755.35,
            755.05,
            755.25
        ],
        [
            1708523640000,
            755.4,
            755.4,
            755,
            755.2
        ],
        [
            1708523700000,
            754.85,
            754.85,
            754.05,
            754.75
        ],
        [
            1708523760000,
            754.9,
            755,
            754,
            754
        ],
        [
            1708523820000,
            754.55,
            754.55,
            753.85,
            754.35
        ],
        [
            1708523880000,
            754.55,
            754.9,
            754,
            754.9
        ],
        [
            1708523940000,
            754.7,
            754.7,
            753.25,
            753.25
        ],
        [
            1708524000000,
            754,
            754.45,
            754,
            754.45
        ],
        [
            1708524060000,
            754.25,
            754.25,
            753.5,
            754
        ],
        [
            1708524120000,
            754,
            754,
            753,
            753.35
        ],
        [
            1708524180000,
            752.95,
            753.7,
            752.95,
            753.7
        ],
        [
            1708524240000,
            753.7,
            754,
            753.65,
            754
        ],
        [
            1708524300000,
            754,
            754,
            753.95,
            753.95
        ],
        [
            1708524360000,
            754,
            754.3,
            753.65,
            754.25
        ],
        [
            1708524420000,
            754.15,
            754.35,
            753.7,
            754.35
        ],
        [
            1708524480000,
            754.35,
            754.35,
            753.4,
            753.4
        ],
        [
            1708524540000,
            753.85,
            753.85,
            752.8,
            753.1
        ],
        [
            1708524600000,
            753.1,
            753.95,
            753,
            753
        ],
        [
            1708524660000,
            753.45,
            753.8,
            752.65,
            753.05
        ],
        [
            1708524720000,
            753.45,
            753.6,
            753.15,
            753.45
        ],
        [
            1708524780000,
            753.1,
            753.15,
            752.45,
            753.15
        ],
        [
            1708524840000,
            753.05,
            753.1,
            752.55,
            753.05
        ],
        [
            1708524900000,
            753.1,
            754.3,
            753.1,
            753.45
        ],
        [
            1708524960000,
            753.95,
            754,
            753.15,
            753.55
        ],
        [
            1708525020000,
            753.85,
            754.6,
            753.85,
            754.6
        ],
        [
            1708525080000,
            754.6,
            754.6,
            753.75,
            753.75
        ],
        [
            1708525140000,
            754.5,
            754.5,
            753.7,
            754.45
        ],
        [
            1708525200000,
            754.45,
            754.7,
            754.4,
            754.4
        ],
        [
            1708525260000,
            754.7,
            754.95,
            754.45,
            754.45
        ],
        [
            1708525320000,
            753.75,
            754.35,
            753.75,
            754.1
        ],
        [
            1708525380000,
            754.1,
            754.1,
            752.55,
            752.55
        ],
        [
            1708525440000,
            753.15,
            753.85,
            752.65,
            753.3
        ],
        [
            1708525500000,
            753.75,
            753.75,
            752.75,
            752.75
        ],
        [
            1708525560000,
            753.5,
            753.7,
            753.1,
            753.4
        ],
        [
            1708525620000,
            753.25,
            753.65,
            753.1,
            753.5
        ],
        [
            1708525680000,
            753.7,
            753.9,
            753.65,
            753.8
        ],
        [
            1708525740000,
            753.65,
            753.7,
            753.05,
            753.05
        ],
        [
            1708525800000,
            753.35,
            753.65,
            752.95,
            752.95
        ],
        [
            1708525860000,
            753.65,
            753.9,
            753.05,
            753.9
        ],
        [
            1708525920000,
            753.9,
            754,
            753.35,
            753.35
        ],
        [
            1708525980000,
            754,
            754,
            753.3,
            753.75
        ],
        [
            1708526040000,
            753.75,
            753.85,
            753.35,
            753.5
        ],
        [
            1708526100000,
            753.9,
            754,
            753.35,
            753.55
        ],
        [
            1708526160000,
            753.7,
            753.7,
            752.9,
            753.1
        ],
        [
            1708526220000,
            753.25,
            753.5,
            752.8,
            753.5
        ],
        [
            1708526280000,
            753.6,
            753.9,
            753.3,
            753.5
        ],
        [
            1708526340000,
            753.7,
            753.7,
            752.35,
            752.35
        ],
        [
            1708526400000,
            752.35,
            752.35,
            751.2,
            751.5
        ],
        [
            1708526460000,
            751.45,
            752.4,
            751.45,
            752.4
        ],
        [
            1708526520000,
            752.45,
            752.55,
            751.3,
            751.3
        ],
        [
            1708526580000,
            751.75,
            752.35,
            751.3,
            752.2
        ],
        [
            1708526640000,
            752.35,
            752.35,
            751.45,
            751.45
        ],
        [
            1708526700000,
            751.95,
            751.95,
            751.2,
            751.2
        ],
        [
            1708526760000,
            751.45,
            751.45,
            749.35,
            749.4
        ],
        [
            1708526820000,
            749.7,
            749.7,
            748.5,
            748.5
        ],
        [
            1708526880000,
            749.1,
            749.35,
            748.9,
            749.15
        ],
        [
            1708526940000,
            749.2,
            749.2,
            748.35,
            749.05
        ],
        [
            1708527000000,
            749.15,
            749.15,
            748.45,
            748.45
        ],
        [
            1708527060000,
            748.95,
            749.65,
            748.95,
            749.3
        ],
        [
            1708527120000,
            749.75,
            750.35,
            749.5,
            750.1
        ],
        [
            1708527180000,
            750.45,
            750.45,
            748.2,
            748.2
        ],
        [
            1708527240000,
            748.75,
            749,
            746.3,
            749
        ],
        [
            1708527300000,
            748.45,
            749.05,
            748.35,
            748.55
        ],
        [
            1708527360000,
            748.6,
            748.65,
            747.45,
            747.95
        ],
        [
            1708527420000,
            747.8,
            748.45,
            747.05,
            747.3
        ],
        [
            1708527480000,
            747.65,
            747.8,
            746.95,
            747.8
        ],
        [
            1708527540000,
            747.8,
            747.8,
            746.95,
            747.3
        ],
        [
            1708527600000,
            747.8,
            749.15,
            747.8,
            748.9
        ],
        [
            1708527660000,
            748.7,
            748.9,
            747.7,
            748.05
        ],
        [
            1708527720000,
            748.4,
            749.1,
            747.75,
            747.75
        ],
        [
            1708527780000,
            748.3,
            748.3,
            747.4,
            748.05
        ],
        [
            1708527840000,
            748.05,
            748.25,
            747.4,
            748.2
        ],
        [
            1708527900000,
            748.05,
            748.7,
            748.05,
            748.7
        ],
        [
            1708527960000,
            748.75,
            749.45,
            748.75,
            749.45
        ],
        [
            1708528020000,
            749.05,
            749.1,
            748.4,
            748.7
        ],
        [
            1708528080000,
            748.95,
            749.1,
            748.4,
            748.85
        ],
        [
            1708528140000,
            749.05,
            749.95,
            749.05,
            749.85
        ],
        [
            1708528200000,
            749.85,
            750.35,
            749.85,
            750.1
        ],
        [
            1708528260000,
            750.25,
            750.25,
            749.45,
            749.45
        ],
        [
            1708528320000,
            749.45,
            749.45,
            748.35,
            748.8
        ],
        [
            1708528380000,
            748.75,
            749.2,
            748.45,
            749.2
        ],
        [
            1708528440000,
            749.3,
            749.3,
            747.8,
            748.55
        ],
        [
            1708528500000,
            748.75,
            748.8,
            747.9,
            748.75
        ],
        [
            1708528560000,
            748.75,
            749.15,
            748.75,
            749.05
        ],
        [
            1708528620000,
            749.15,
            749.15,
            747.8,
            748.15
        ],
        [
            1708528680000,
            748.1,
            748.15,
            747.55,
            747.95
        ],
        [
            1708528740000,
            747.95,
            747.95,
            747.2,
            747.35
        ],
        [
            1708528800000,
            747.45,
            747.8,
            747.05,
            747.8
        ],
        [
            1708528860000,
            747.9,
            748,
            747.9,
            748
        ],
        [
            1708528920000,
            748,
            748,
            747.95,
            748
        ],
        [
            1708528980000,
            747.95,
            748,
            747.95,
            748
        ],
        [
            1708529040000,
            747.95,
            748.05,
            747.95,
            748.05
        ],
        [
            1708529100000,
            747.95,
            748,
            747.25,
            747.55
        ],
        [
            1708529160000,
            747.5,
            747.5,
            746.35,
            747
        ],
        [
            1708529220000,
            746.15,
            746.9,
            746.1,
            746.9
        ],
        [
            1708529280000,
            746.75,
            747.8,
            746.7,
            747.8
        ],
        [
            1708529340000,
            747.3,
            749.5,
            747.3,
            749.5
        ],
        [
            1708529400000,
            749.5,
            749.5,
            749.5,
            749.5
        ],
        [
            1708593300000,
            749,
            749.95,
            747.2,
            747.75
        ],
        [
            1708593360000,
            747.6,
            750.05,
            747.6,
            749.2
        ],
        [
            1708593420000,
            750.35,
            750.9,
            750.35,
            750.75
        ],
        [
            1708593480000,
            751.5,
            751.5,
            749.75,
            749.75
        ],
        [
            1708593540000,
            749.95,
            750.25,
            749.55,
            750.25
        ],
        [
            1708593600000,
            750.4,
            750.5,
            750.25,
            750.3
        ],
        [
            1708593660000,
            750,
            750.6,
            750,
            750.5
        ],
        [
            1708593720000,
            750.65,
            750.7,
            750.3,
            750.7
        ],
        [
            1708593780000,
            750.6,
            750.65,
            749.45,
            749.45
        ],
        [
            1708593840000,
            749.5,
            750.7,
            749.5,
            750.25
        ],
        [
            1708593900000,
            750.6,
            750.6,
            750,
            750.05
        ],
        [
            1708593960000,
            749.75,
            750.1,
            749.75,
            749.8
        ],
        [
            1708594020000,
            749.4,
            749.95,
            749.4,
            749.6
        ],
        [
            1708594080000,
            750.1,
            750.1,
            749.2,
            749.2
        ],
        [
            1708594140000,
            749.2,
            749.2,
            748.1,
            748.1
        ],
        [
            1708594200000,
            748.25,
            748.25,
            746.2,
            746.2
        ],
        [
            1708594260000,
            746.75,
            746.75,
            746.15,
            746.45
        ],
        [
            1708594320000,
            746.4,
            746.4,
            745.65,
            746.1
        ],
        [
            1708594380000,
            746.05,
            746.05,
            743.45,
            743.45
        ],
        [
            1708594440000,
            744,
            745.15,
            744,
            744.3
        ],
        [
            1708594500000,
            744.5,
            745.45,
            744.3,
            744.3
        ],
        [
            1708594560000,
            744.2,
            745.3,
            744.2,
            745.3
        ],
        [
            1708594620000,
            745.35,
            745.45,
            745.05,
            745.3
        ],
        [
            1708594680000,
            745.85,
            746.2,
            745.5,
            745.5
        ],
        [
            1708594740000,
            745.5,
            746.55,
            744.85,
            744.85
        ],
        [
            1708594800000,
            745.2,
            745.25,
            745.15,
            745.15
        ],
        [
            1708594860000,
            745.15,
            747,
            745.15,
            747
        ],
        [
            1708594920000,
            747.25,
            749,
            747.25,
            747.3
        ],
        [
            1708594980000,
            747.6,
            748.55,
            747.6,
            748.5
        ],
        [
            1708595040000,
            748.4,
            748.4,
            747.35,
            748.05
        ],
        [
            1708595100000,
            747.8,
            749.1,
            747.65,
            749.1
        ],
        [
            1708595160000,
            749,
            749.6,
            749,
            749.3
        ],
        [
            1708595220000,
            749.6,
            749.95,
            749.55,
            749.95
        ],
        [
            1708595280000,
            749.95,
            749.95,
            749.05,
            749.25
        ],
        [
            1708595340000,
            749.3,
            749.3,
            748.2,
            748.2
        ],
        [
            1708595400000,
            748.05,
            749.25,
            748.05,
            749.25
        ],
        [
            1708595460000,
            749.05,
            749.05,
            748,
            748.25
        ],
        [
            1708595520000,
            748.15,
            748.15,
            747.95,
            747.95
        ],
        [
            1708595580000,
            748.25,
            748.3,
            747.65,
            747.65
        ],
        [
            1708595640000,
            747.55,
            747.55,
            746.7,
            747.25
        ],
        [
            1708595700000,
            747.25,
            747.25,
            746.55,
            747.2
        ],
        [
            1708595760000,
            747.1,
            747.25,
            746.75,
            747.1
        ],
        [
            1708595820000,
            746.5,
            747.05,
            745.6,
            746.55
        ],
        [
            1708595880000,
            746.55,
            747.15,
            746.1,
            747.05
        ],
        [
            1708595940000,
            746.3,
            746.9,
            746.1,
            746.1
        ],
        [
            1708596000000,
            746.05,
            746.05,
            742.8,
            742.8
        ],
        [
            1708596060000,
            743,
            744,
            742.05,
            743.25
        ],
        [
            1708596120000,
            743.1,
            744.15,
            742.2,
            744.05
        ],
        [
            1708596180000,
            744.15,
            745.2,
            741.7,
            743.2
        ],
        [
            1708596240000,
            743.2,
            744.75,
            742.85,
            743.7
        ],
        [
            1708596300000,
            743.7,
            743.75,
            740.7,
            740.8
        ],
        [
            1708596360000,
            741.2,
            741.9,
            740.7,
            741.5
        ],
        [
            1708596420000,
            741.55,
            742.15,
            741.45,
            741.95
        ],
        [
            1708596480000,
            742,
            742,
            741.25,
            741.5
        ],
        [
            1708596540000,
            741.45,
            741.6,
            740.75,
            740.75
        ],
        [
            1708596600000,
            741.6,
            742,
            741.4,
            742
        ],
        [
            1708596660000,
            742.05,
            742.4,
            741.2,
            741.2
        ],
        [
            1708596720000,
            741.8,
            742,
            741.8,
            741.95
        ],
        [
            1708596780000,
            742.35,
            742.35,
            742.2,
            742.3
        ],
        [
            1708596840000,
            742.25,
            742.6,
            741.75,
            742.6
        ],
        [
            1708596900000,
            742.25,
            742.5,
            741.55,
            742.25
        ],
        [
            1708596960000,
            742.25,
            742.6,
            742.25,
            742.35
        ],
        [
            1708597020000,
            743,
            743,
            741.75,
            742.2
        ],
        [
            1708597080000,
            742.2,
            742.55,
            742,
            742.45
        ],
        [
            1708597140000,
            742.65,
            743.5,
            742.65,
            742.9
        ],
        [
            1708597200000,
            742.9,
            745.15,
            742.9,
            744.95
        ],
        [
            1708597260000,
            745.15,
            745.75,
            745.1,
            745.45
        ],
        [
            1708597320000,
            745.45,
            746.3,
            745,
            745.1
        ],
        [
            1708597380000,
            744.95,
            745.05,
            744.25,
            744.75
        ],
        [
            1708597440000,
            744.7,
            745.05,
            744.15,
            744.95
        ],
        [
            1708597500000,
            744.75,
            745.2,
            743.2,
            744.55
        ],
        [
            1708597560000,
            744.55,
            744.7,
            744.1,
            744.35
        ],
        [
            1708597620000,
            744.35,
            745.65,
            744.3,
            745.6
        ],
        [
            1708597680000,
            745.75,
            745.8,
            745.1,
            745.45
        ],
        [
            1708597740000,
            745.45,
            745.45,
            745.2,
            745.45
        ],
        [
            1708597800000,
            745.45,
            745.5,
            744.7,
            745.5
        ],
        [
            1708597860000,
            745.5,
            745.9,
            745.5,
            745.8
        ],
        [
            1708597920000,
            745.8,
            745.8,
            744.95,
            745.3
        ],
        [
            1708597980000,
            745.3,
            745.3,
            745,
            745
        ],
        [
            1708598040000,
            745,
            745,
            744.55,
            744.55
        ],
        [
            1708598100000,
            744.55,
            745.05,
            744.35,
            744.6
        ],
        [
            1708598160000,
            745.25,
            745.25,
            744.5,
            745
        ],
        [
            1708598220000,
            745,
            745,
            744.8,
            745
        ],
        [
            1708598280000,
            745,
            745,
            744.2,
            744.7
        ],
        [
            1708598340000,
            744.7,
            744.7,
            743.85,
            743.85
        ],
        [
            1708598400000,
            744.3,
            744.9,
            743.6,
            744.45
        ],
        [
            1708598460000,
            744.55,
            744.9,
            743.8,
            744.7
        ],
        [
            1708598520000,
            744.7,
            744.7,
            744.65,
            744.7
        ],
        [
            1708598580000,
            744.7,
            744.7,
            743.35,
            744.65
        ],
        [
            1708598640000,
            744.7,
            744.7,
            743.75,
            743.75
        ],
        [
            1708598700000,
            743.3,
            743.75,
            743.1,
            743.1
        ],
        [
            1708598760000,
            743.3,
            743.9,
            743.05,
            743.25
        ],
        [
            1708598820000,
            743.2,
            743.2,
            742.5,
            742.55
        ],
        [
            1708598880000,
            742.95,
            743.3,
            742.75,
            742.75
        ],
        [
            1708598940000,
            742.55,
            742.75,
            742.2,
            742.6
        ],
        [
            1708599000000,
            742.65,
            742.65,
            742.55,
            742.6
        ],
        [
            1708599060000,
            742.6,
            742.6,
            741.8,
            741.8
        ],
        [
            1708599120000,
            742,
            742,
            741.35,
            741.4
        ],
        [
            1708599180000,
            741.9,
            741.9,
            741.3,
            741.3
        ],
        [
            1708599240000,
            741.35,
            741.35,
            739.55,
            740
        ],
        [
            1708599300000,
            739.65,
            739.9,
            739.05,
            739.7
        ],
        [
            1708599360000,
            739.6,
            739.65,
            739,
            739.2
        ],
        [
            1708599420000,
            739.9,
            739.9,
            738.7,
            738.7
        ],
        [
            1708599480000,
            738.7,
            739,
            738.7,
            738.7
        ],
        [
            1708599540000,
            738.9,
            739.9,
            738.7,
            739.8
        ],
        [
            1708599600000,
            740,
            740,
            739.75,
            739.75
        ],
        [
            1708599660000,
            739.9,
            739.9,
            739.2,
            739.7
        ],
        [
            1708599720000,
            739.7,
            739.75,
            739.4,
            739.6
        ],
        [
            1708599780000,
            739.6,
            739.7,
            739.55,
            739.7
        ],
        [
            1708599840000,
            739.7,
            740,
            739.6,
            740
        ],
        [
            1708599900000,
            740.25,
            740.4,
            740.05,
            740.05
        ],
        [
            1708599960000,
            739.95,
            739.95,
            739.15,
            739.15
        ],
        [
            1708600020000,
            739.05,
            740.45,
            739.05,
            740.05
        ],
        [
            1708600080000,
            740.3,
            740.3,
            739.8,
            740.2
        ],
        [
            1708600140000,
            740.2,
            740.5,
            740.2,
            740.35
        ],
        [
            1708600200000,
            740.45,
            741.15,
            740.35,
            741
        ],
        [
            1708600260000,
            740.9,
            740.9,
            740.65,
            740.85
        ],
        [
            1708600320000,
            740.85,
            740.85,
            739.55,
            740.15
        ],
        [
            1708600380000,
            740.5,
            741.25,
            740.45,
            740.8
        ],
        [
            1708600440000,
            740.8,
            740.8,
            740.15,
            740.55
        ],
        [
            1708600500000,
            740.55,
            741.25,
            740.55,
            741.2
        ],
        [
            1708600560000,
            741.2,
            741.5,
            741.15,
            741.5
        ],
        [
            1708600620000,
            742.25,
            742.3,
            741.35,
            741.35
        ],
        [
            1708600680000,
            741.7,
            741.7,
            740.6,
            741.05
        ],
        [
            1708600740000,
            741.05,
            741.05,
            739.9,
            740.55
        ],
        [
            1708600800000,
            740.45,
            740.45,
            739.2,
            739.8
        ],
        [
            1708600860000,
            739.8,
            739.8,
            739.2,
            739.3
        ],
        [
            1708600920000,
            739.3,
            739.65,
            739.3,
            739.6
        ],
        [
            1708600980000,
            739.6,
            740,
            739.5,
            740
        ],
        [
            1708601040000,
            740,
            740.85,
            739.9,
            740.85
        ],
        [
            1708601100000,
            740.9,
            740.9,
            739.8,
            739.8
        ],
        [
            1708601160000,
            740.25,
            740.25,
            739.6,
            740.2
        ],
        [
            1708601220000,
            740.2,
            740.2,
            739.3,
            739.95
        ],
        [
            1708601280000,
            739.95,
            739.95,
            739.35,
            739.9
        ],
        [
            1708601340000,
            739.9,
            739.9,
            739.4,
            739.8
        ],
        [
            1708601400000,
            739.85,
            739.85,
            739.35,
            739.8
        ],
        [
            1708601460000,
            739.8,
            739.8,
            739.25,
            739.7
        ],
        [
            1708601520000,
            739.7,
            739.7,
            739.2,
            739.55
        ],
        [
            1708601580000,
            739.55,
            739.65,
            739.55,
            739.65
        ],
        [
            1708601640000,
            739.35,
            740.65,
            739.3,
            740.65
        ],
        [
            1708601700000,
            740.65,
            740.7,
            739.55,
            740.1
        ],
        [
            1708601760000,
            740.1,
            741,
            739.45,
            741
        ],
        [
            1708601820000,
            741.2,
            741.7,
            741.2,
            741.7
        ],
        [
            1708601880000,
            741.7,
            741.7,
            740.6,
            740.6
        ],
        [
            1708601940000,
            741,
            741,
            740.3,
            740.65
        ],
        [
            1708602000000,
            740.65,
            740.9,
            740.65,
            740.8
        ],
        [
            1708602060000,
            740.8,
            740.8,
            740.05,
            740.55
        ],
        [
            1708602120000,
            740.55,
            740.55,
            739.8,
            740.3
        ],
        [
            1708602180000,
            740.35,
            740.35,
            739.75,
            740.25
        ],
        [
            1708602240000,
            740.25,
            740.65,
            740.25,
            740.6
        ],
        [
            1708602300000,
            741.45,
            741.45,
            740.65,
            740.65
        ],
        [
            1708602360000,
            741.15,
            741.7,
            741.1,
            741.7
        ],
        [
            1708602420000,
            741.65,
            741.65,
            740.8,
            741
        ],
        [
            1708602480000,
            741,
            741,
            740.25,
            740.75
        ],
        [
            1708602540000,
            740.75,
            741.4,
            740.75,
            741.2
        ],
        [
            1708602600000,
            741.15,
            741.15,
            740.2,
            740.75
        ],
        [
            1708602660000,
            740.75,
            741.1,
            740.7,
            741
        ],
        [
            1708602720000,
            741,
            741.75,
            741,
            741.75
        ],
        [
            1708602780000,
            741.85,
            741.9,
            741.5,
            741.75
        ],
        [
            1708602840000,
            741.7,
            741.7,
            741.05,
            741.55
        ],
        [
            1708602900000,
            741.55,
            743,
            741.5,
            742.75
        ],
        [
            1708602960000,
            743,
            743.45,
            742.95,
            743.45
        ],
        [
            1708603020000,
            743.45,
            743.45,
            743.3,
            743.4
        ],
        [
            1708603080000,
            743.4,
            743.4,
            743,
            743.05
        ],
        [
            1708603140000,
            743.05,
            743.3,
            743,
            743.3
        ],
        [
            1708603200000,
            743.5,
            743.6,
            743,
            743.1
        ],
        [
            1708603260000,
            743,
            743.8,
            743,
            743.8
        ],
        [
            1708603320000,
            743.8,
            743.8,
            743.35,
            743.6
        ],
        [
            1708603380000,
            743.6,
            743.6,
            743.1,
            743.55
        ],
        [
            1708603440000,
            743.55,
            743.55,
            742.95,
            743.45
        ],
        [
            1708603500000,
            743.45,
            743.45,
            742.9,
            743.35
        ],
        [
            1708603560000,
            743.05,
            743.2,
            743,
            743.05
        ],
        [
            1708603620000,
            743.05,
            743.4,
            743,
            743.35
        ],
        [
            1708603680000,
            743.4,
            743.4,
            743.05,
            743.35
        ],
        [
            1708603740000,
            743.35,
            743.35,
            743.05,
            743.05
        ],
        [
            1708603800000,
            743.05,
            743.3,
            743.05,
            743.25
        ],
        [
            1708603860000,
            743.25,
            743.25,
            742.8,
            743.25
        ],
        [
            1708603920000,
            743.25,
            743.25,
            742.55,
            743.05
        ],
        [
            1708603980000,
            743.2,
            743.2,
            742.45,
            743
        ],
        [
            1708604040000,
            743,
            743,
            742.55,
            742.65
        ],
        [
            1708604100000,
            742.6,
            742.7,
            742.3,
            742.45
        ],
        [
            1708604160000,
            742.65,
            743.2,
            742.15,
            743.2
        ],
        [
            1708604220000,
            743.2,
            743.7,
            743.1,
            743.45
        ],
        [
            1708604280000,
            743.4,
            743.4,
            743,
            743.35
        ],
        [
            1708604340000,
            743.35,
            743.35,
            742.65,
            743.1
        ],
        [
            1708604400000,
            743.15,
            743.15,
            742.6,
            743.15
        ],
        [
            1708604460000,
            743.15,
            743.15,
            742.6,
            743.1
        ],
        [
            1708604520000,
            743.1,
            743.1,
            743,
            743.05
        ],
        [
            1708604580000,
            743.05,
            743.05,
            742.05,
            742.1
        ],
        [
            1708604640000,
            742.05,
            742.6,
            742,
            742.6
        ],
        [
            1708604700000,
            742.65,
            743.7,
            742.2,
            743.7
        ],
        [
            1708604760000,
            743.7,
            743.95,
            743.15,
            743.45
        ],
        [
            1708604820000,
            743.45,
            743.45,
            742.65,
            743.15
        ],
        [
            1708604880000,
            743.15,
            743.15,
            742.45,
            743.1
        ],
        [
            1708604940000,
            743.1,
            743.1,
            742.65,
            743.1
        ],
        [
            1708605000000,
            743.1,
            743.1,
            742.4,
            742.85
        ],
        [
            1708605060000,
            742.85,
            743.35,
            742.6,
            743.35
        ],
        [
            1708605120000,
            743.35,
            743.9,
            742.85,
            743.4
        ],
        [
            1708605180000,
            743.5,
            743.5,
            742.85,
            742.9
        ],
        [
            1708605240000,
            742.9,
            743.75,
            742.9,
            743.45
        ],
        [
            1708605300000,
            743.45,
            743.45,
            743,
            743.45
        ],
        [
            1708605360000,
            743.45,
            743.45,
            742.8,
            743.45
        ],
        [
            1708605420000,
            743.45,
            743.45,
            743,
            743.4
        ],
        [
            1708605480000,
            743.4,
            743.4,
            743.25,
            743.3
        ],
        [
            1708605540000,
            743.3,
            743.3,
            743,
            743.25
        ],
        [
            1708605600000,
            743.25,
            743.25,
            743,
            743.2
        ],
        [
            1708605660000,
            743.2,
            743.2,
            743.1,
            743.15
        ],
        [
            1708605720000,
            743.15,
            743.15,
            742.7,
            743.1
        ],
        [
            1708605780000,
            743.1,
            743.55,
            743.1,
            743.5
        ],
        [
            1708605840000,
            743.5,
            743.5,
            743.05,
            743.4
        ],
        [
            1708605900000,
            743.4,
            743.4,
            743,
            743.4
        ],
        [
            1708605960000,
            743.4,
            743.4,
            743,
            743.35
        ],
        [
            1708606020000,
            743.35,
            743.35,
            742.85,
            743.35
        ],
        [
            1708606080000,
            743.35,
            743.35,
            742.85,
            743.35
        ],
        [
            1708606140000,
            743.35,
            743.9,
            743.05,
            743.45
        ],
        [
            1708606200000,
            743.45,
            743.45,
            743,
            743.05
        ],
        [
            1708606260000,
            743.05,
            743.65,
            743.05,
            743.5
        ],
        [
            1708606320000,
            743.5,
            743.5,
            743.1,
            743.4
        ],
        [
            1708606380000,
            743.45,
            743.45,
            742.35,
            742.45
        ],
        [
            1708606440000,
            742.45,
            742.8,
            742.45,
            742.75
        ],
        [
            1708606500000,
            742.5,
            742.5,
            741.35,
            741.9
        ],
        [
            1708606560000,
            741.9,
            742,
            741.35,
            741.6
        ],
        [
            1708606620000,
            741.6,
            741.6,
            740.85,
            741.5
        ],
        [
            1708606680000,
            741.5,
            741.5,
            741,
            741
        ],
        [
            1708606740000,
            741,
            741.6,
            741,
            741.5
        ],
        [
            1708606800000,
            741.35,
            741.35,
            740.7,
            741.25
        ],
        [
            1708606860000,
            741.25,
            741.25,
            740.7,
            741.25
        ],
        [
            1708606920000,
            741.25,
            741.6,
            741.2,
            741.2
        ],
        [
            1708606980000,
            741.35,
            742,
            741.3,
            741.3
        ],
        [
            1708607040000,
            741.75,
            742,
            741.15,
            742
        ],
        [
            1708607100000,
            742,
            742,
            741.7,
            741.8
        ],
        [
            1708607160000,
            741.75,
            742.2,
            740.65,
            742.2
        ],
        [
            1708607220000,
            742.2,
            742.2,
            742,
            742.2
        ],
        [
            1708607280000,
            742.2,
            742.2,
            741.45,
            742.2
        ],
        [
            1708607340000,
            742.2,
            742.2,
            741.65,
            742.15
        ],
        [
            1708607400000,
            742.15,
            742.15,
            742,
            742.1
        ],
        [
            1708607460000,
            742.1,
            742.1,
            742,
            742.1
        ],
        [
            1708607520000,
            742.1,
            742.1,
            742,
            742.1
        ],
        [
            1708607580000,
            742.1,
            742.8,
            742.1,
            742.75
        ],
        [
            1708607640000,
            742.75,
            742.75,
            742.2,
            742.65
        ],
        [
            1708607700000,
            742.65,
            742.7,
            742.1,
            742.6
        ],
        [
            1708607760000,
            742.6,
            742.6,
            742.3,
            742.5
        ],
        [
            1708607820000,
            742.55,
            742.55,
            742,
            742.55
        ],
        [
            1708607880000,
            742.55,
            742.55,
            742.05,
            742.55
        ],
        [
            1708607940000,
            742.5,
            742.55,
            742,
            742.55
        ],
        [
            1708608000000,
            742.55,
            742.55,
            742,
            742.55
        ],
        [
            1708608060000,
            742.55,
            742.55,
            742.5,
            742.55
        ],
        [
            1708608120000,
            742.55,
            742.55,
            741.95,
            742.55
        ],
        [
            1708608180000,
            742.55,
            742.8,
            742.5,
            742.5
        ],
        [
            1708608240000,
            742.7,
            742.7,
            742.05,
            742.65
        ],
        [
            1708608300000,
            742.65,
            742.65,
            741.95,
            742.2
        ],
        [
            1708608360000,
            742.6,
            742.6,
            742.1,
            742.55
        ],
        [
            1708608420000,
            742.55,
            742.55,
            741.9,
            742.55
        ],
        [
            1708608480000,
            742.55,
            743,
            742.5,
            742.5
        ],
        [
            1708608540000,
            742.8,
            743.2,
            742.6,
            743
        ],
        [
            1708608600000,
            742.95,
            742.95,
            742.55,
            742.7
        ],
        [
            1708608660000,
            742.7,
            743.15,
            742.7,
            743.1
        ],
        [
            1708608720000,
            743.1,
            743.1,
            742.75,
            743.05
        ],
        [
            1708608780000,
            743.05,
            743.05,
            743,
            743.05
        ],
        [
            1708608840000,
            743.05,
            743.05,
            743,
            743.05
        ],
        [
            1708608900000,
            743.05,
            743.35,
            743,
            743
        ],
        [
            1708608960000,
            743.4,
            743.45,
            743,
            743.05
        ],
        [
            1708609020000,
            743.2,
            743.2,
            742.55,
            743.15
        ],
        [
            1708609080000,
            743.15,
            743.75,
            742.5,
            743.75
        ],
        [
            1708609140000,
            743.8,
            743.9,
            743.15,
            743.7
        ],
        [
            1708609200000,
            743.7,
            743.7,
            743,
            743.25
        ],
        [
            1708609260000,
            743.25,
            743.25,
            743,
            743.05
        ],
        [
            1708609320000,
            743.2,
            744,
            743.2,
            743.65
        ],
        [
            1708609380000,
            743.65,
            744.95,
            743.65,
            744.95
        ],
        [
            1708609440000,
            744.95,
            744.95,
            743.9,
            744.35
        ],
        [
            1708609500000,
            744.35,
            744.35,
            743.7,
            744.2
        ],
        [
            1708609560000,
            744.2,
            744.2,
            743.7,
            743.85
        ],
        [
            1708609620000,
            743.85,
            743.85,
            743.2,
            743.8
        ],
        [
            1708609680000,
            743.4,
            743.8,
            743.25,
            743.55
        ],
        [
            1708609740000,
            743.55,
            743.55,
            743.05,
            743.5
        ],
        [
            1708609800000,
            743.5,
            744.5,
            743.3,
            743.75
        ],
        [
            1708609860000,
            743.8,
            744.25,
            743.8,
            743.85
        ],
        [
            1708609920000,
            743.85,
            743.85,
            743,
            743.1
        ],
        [
            1708609980000,
            743.1,
            743.9,
            742.9,
            743.85
        ],
        [
            1708610040000,
            743.85,
            743.9,
            743.2,
            743.65
        ],
        [
            1708610100000,
            743.65,
            743.65,
            743,
            743.6
        ],
        [
            1708610160000,
            743.6,
            743.6,
            743.05,
            743.1
        ],
        [
            1708610220000,
            743.45,
            744.45,
            743.1,
            744.45
        ],
        [
            1708610280000,
            744.5,
            744.8,
            744.2,
            744.35
        ],
        [
            1708610340000,
            744.35,
            745.5,
            744.35,
            745.5
        ],
        [
            1708610400000,
            745.6,
            746.85,
            745.6,
            746.4
        ],
        [
            1708610460000,
            746.85,
            747,
            746.6,
            746.8
        ],
        [
            1708610520000,
            746.8,
            746.95,
            746.2,
            746.6
        ],
        [
            1708610580000,
            746.6,
            747.25,
            746.05,
            746.85
        ],
        [
            1708610640000,
            746.85,
            746.85,
            746.05,
            746.2
        ],
        [
            1708610700000,
            746.2,
            746.65,
            745.55,
            746.65
        ],
        [
            1708610760000,
            746.8,
            746.9,
            745.55,
            746
        ],
        [
            1708610820000,
            746,
            746.5,
            745.65,
            746
        ],
        [
            1708610880000,
            746,
            746,
            745.1,
            745.6
        ],
        [
            1708610940000,
            745.6,
            746.25,
            745.4,
            746
        ],
        [
            1708611000000,
            745.9,
            746.15,
            745.1,
            745.6
        ],
        [
            1708611060000,
            745.55,
            745.55,
            744.5,
            744.75
        ],
        [
            1708611120000,
            745.15,
            745.15,
            744.15,
            744.15
        ],
        [
            1708611180000,
            744.95,
            745.3,
            744.35,
            745.1
        ],
        [
            1708611240000,
            745.1,
            745.1,
            744.45,
            744.95
        ],
        [
            1708611300000,
            744.95,
            745.55,
            744.85,
            745.55
        ],
        [
            1708611360000,
            745.5,
            745.7,
            744.95,
            745.25
        ],
        [
            1708611420000,
            745.45,
            745.7,
            745.25,
            745.3
        ],
        [
            1708611480000,
            745.3,
            745.3,
            745.05,
            745.2
        ],
        [
            1708611540000,
            745.2,
            745.2,
            744.65,
            744.65
        ],
        [
            1708611600000,
            745.05,
            745.45,
            744.6,
            745.1
        ],
        [
            1708611660000,
            745.1,
            745.7,
            744.5,
            745
        ],
        [
            1708611720000,
            745,
            745,
            744.3,
            744.85
        ],
        [
            1708611780000,
            744.85,
            745.2,
            744.45,
            744.45
        ],
        [
            1708611840000,
            745.2,
            745.3,
            744.85,
            745.2
        ],
        [
            1708611900000,
            744.9,
            745.35,
            744.55,
            744.55
        ],
        [
            1708611960000,
            744.95,
            746.2,
            744.8,
            745.4
        ],
        [
            1708612020000,
            745.4,
            745.4,
            745,
            745
        ],
        [
            1708612080000,
            745.4,
            745.9,
            744.95,
            745.9
        ],
        [
            1708612140000,
            745.25,
            746.2,
            745.25,
            746.05
        ],
        [
            1708612200000,
            746.05,
            746.05,
            745,
            745.55
        ],
        [
            1708612260000,
            745.7,
            746.1,
            745.25,
            745.75
        ],
        [
            1708612320000,
            745.75,
            745.75,
            745.25,
            745.25
        ],
        [
            1708612380000,
            745.55,
            745.55,
            745,
            745
        ],
        [
            1708612440000,
            745.5,
            745.5,
            744.9,
            745.35
        ],
        [
            1708612500000,
            745.35,
            745.35,
            744.15,
            744.15
        ],
        [
            1708612560000,
            745.5,
            746.35,
            745.05,
            746.3
        ],
        [
            1708612620000,
            746.35,
            746.9,
            746.25,
            746.7
        ],
        [
            1708612680000,
            746.7,
            746.7,
            746.15,
            746.15
        ],
        [
            1708612740000,
            746.2,
            746.25,
            745.3,
            745.8
        ],
        [
            1708612800000,
            746.15,
            746.7,
            745.9,
            746.7
        ],
        [
            1708612860000,
            746.65,
            746.65,
            745.55,
            745.65
        ],
        [
            1708612920000,
            746.25,
            746.25,
            745.6,
            745.6
        ],
        [
            1708612980000,
            746.1,
            746.1,
            745.2,
            745.5
        ],
        [
            1708613040000,
            745.5,
            746.05,
            745.5,
            746
        ],
        [
            1708613100000,
            746.05,
            746.5,
            745.45,
            745.6
        ],
        [
            1708613160000,
            745.6,
            746.6,
            745.6,
            746.25
        ],
        [
            1708613220000,
            746.15,
            746.4,
            745.85,
            746.3
        ],
        [
            1708613280000,
            746.25,
            746.3,
            745.9,
            746.25
        ],
        [
            1708613340000,
            746.3,
            746.3,
            745.85,
            746.2
        ],
        [
            1708613400000,
            746.2,
            746.2,
            745.55,
            746.1
        ],
        [
            1708613460000,
            746.05,
            746.4,
            745.95,
            746.35
        ],
        [
            1708613520000,
            746.65,
            746.65,
            745.75,
            745.8
        ],
        [
            1708613580000,
            745.7,
            746.55,
            745.7,
            746
        ],
        [
            1708613640000,
            746.15,
            747.25,
            746.1,
            747.2
        ],
        [
            1708613700000,
            747.2,
            747.5,
            747,
            747
        ],
        [
            1708613760000,
            747.35,
            747.55,
            747.3,
            747.55
        ],
        [
            1708613820000,
            747.6,
            747.6,
            747,
            747.45
        ],
        [
            1708613880000,
            747.5,
            747.5,
            747.05,
            747.3
        ],
        [
            1708613940000,
            747.25,
            747.7,
            747.25,
            747.6
        ],
        [
            1708614000000,
            747.7,
            748.45,
            747.7,
            748.05
        ],
        [
            1708614060000,
            748.1,
            748.1,
            747.55,
            748.1
        ],
        [
            1708614120000,
            748.05,
            748.05,
            747.1,
            747.1
        ],
        [
            1708614180000,
            747.4,
            747.4,
            746.8,
            747.3
        ],
        [
            1708614240000,
            747.3,
            747.3,
            747.15,
            747.25
        ],
        [
            1708614300000,
            747.3,
            747.3,
            747.15,
            747.2
        ],
        [
            1708614360000,
            747.15,
            747.4,
            747.1,
            747.4
        ],
        [
            1708614420000,
            747.4,
            748.05,
            747.3,
            748.05
        ],
        [
            1708614480000,
            748.05,
            748.15,
            748,
            748.05
        ],
        [
            1708614540000,
            748,
            748.45,
            748,
            748.45
        ],
        [
            1708614600000,
            748.45,
            748.6,
            748.45,
            748.6
        ],
        [
            1708614660000,
            748.45,
            748.65,
            748.45,
            748.6
        ],
        [
            1708614720000,
            748.55,
            748.55,
            748.45,
            748.45
        ],
        [
            1708614780000,
            748.45,
            748.6,
            748.4,
            748.5
        ],
        [
            1708614840000,
            748,
            748.15,
            748,
            748.05
        ],
        [
            1708614900000,
            748.05,
            748.05,
            748,
            748.05
        ],
        [
            1708614960000,
            748.05,
            748.55,
            748,
            748.55
        ],
        [
            1708615020000,
            748.5,
            748.6,
            748.5,
            748.55
        ],
        [
            1708615080000,
            748.55,
            748.85,
            748.5,
            748.6
        ],
        [
            1708615140000,
            748.55,
            748.6,
            748.55,
            748.55
        ],
        [
            1708615200000,
            748.55,
            748.8,
            748.55,
            748.75
        ],
        [
            1708615260000,
            749.1,
            749.15,
            749.05,
            749.15
        ],
        [
            1708615320000,
            748.95,
            749.05,
            748.95,
            749.05
        ],
        [
            1708615380000,
            749,
            749.05,
            749,
            749.05
        ],
        [
            1708615440000,
            749,
            749.1,
            749,
            749.1
        ],
        [
            1708615500000,
            749.05,
            749.4,
            749.05,
            749.15
        ],
        [
            1708615560000,
            749.15,
            749.15,
            749,
            749.05
        ],
        [
            1708615620000,
            749.15,
            749.85,
            749.15,
            749.6
        ],
        [
            1708615680000,
            749,
            749.85,
            748.8,
            749.05
        ],
        [
            1708615740000,
            749,
            749.1,
            749,
            749.1
        ],
        [
            1708615800000,
            750,
            750,
            750,
            750
        ],
        [
            1708679700000,
            746.8,
            747.8,
            746.8,
            747.1
        ],
        [
            1708679760000,
            748.45,
            749.3,
            747.8,
            749.3
        ],
        [
            1708679820000,
            749.05,
            752.7,
            749.05,
            750.9
        ],
        [
            1708679880000,
            751.15,
            751.7,
            750.4,
            750.55
        ],
        [
            1708679940000,
            750.5,
            751.2,
            750.45,
            751.2
        ],
        [
            1708680000000,
            751.2,
            751.65,
            749.6,
            749.6
        ],
        [
            1708680060000,
            749.4,
            749.6,
            748.8,
            749.05
        ],
        [
            1708680120000,
            749.05,
            749.3,
            748.35,
            748.55
        ],
        [
            1708680180000,
            748.55,
            749.2,
            748.55,
            748.95
        ],
        [
            1708680240000,
            748.95,
            748.95,
            748,
            748.4
        ],
        [
            1708680300000,
            748.15,
            748.4,
            748.15,
            748.2
        ],
        [
            1708680360000,
            748.25,
            748.25,
            747.3,
            747.3
        ],
        [
            1708680420000,
            747.05,
            747.4,
            746.55,
            746.6
        ],
        [
            1708680480000,
            746.55,
            746.55,
            746.3,
            746.3
        ],
        [
            1708680540000,
            746.2,
            746.45,
            746.2,
            746.25
        ],
        [
            1708680600000,
            746.25,
            746.25,
            745.1,
            745.1
        ],
        [
            1708680660000,
            745.85,
            746.1,
            745.8,
            746.1
        ],
        [
            1708680720000,
            746.05,
            746.2,
            745.55,
            745.55
        ],
        [
            1708680780000,
            745.55,
            745.6,
            745.25,
            745.25
        ],
        [
            1708680840000,
            745.25,
            745.55,
            745.15,
            745.3
        ],
        [
            1708680900000,
            744.6,
            744.75,
            743.5,
            744.75
        ],
        [
            1708680960000,
            745.1,
            745.35,
            745.1,
            745.1
        ],
        [
            1708681020000,
            745.1,
            747.1,
            745.1,
            746.7
        ],
        [
            1708681080000,
            746.65,
            747.05,
            746.5,
            746.5
        ],
        [
            1708681140000,
            747.05,
            747.05,
            746.5,
            746.6
        ],
        [
            1708681200000,
            746.6,
            746.95,
            746.6,
            746.6
        ],
        [
            1708681260000,
            746.6,
            747.1,
            746.6,
            747.1
        ],
        [
            1708681320000,
            746.9,
            747.05,
            746.9,
            747.05
        ],
        [
            1708681380000,
            747.05,
            747.35,
            747.05,
            747.05
        ],
        [
            1708681440000,
            747.05,
            747.5,
            747.05,
            747.05
        ],
        [
            1708681500000,
            747.1,
            747.1,
            747,
            747
        ],
        [
            1708681560000,
            747,
            747,
            747,
            747
        ],
        [
            1708681620000,
            747,
            747.05,
            747,
            747
        ],
        [
            1708681680000,
            747,
            747,
            747,
            747
        ],
        [
            1708681740000,
            747,
            747.35,
            747,
            747.05
        ],
        [
            1708681800000,
            747.05,
            747.05,
            747.05,
            747.05
        ],
        [
            1708681860000,
            747.25,
            747.25,
            747.1,
            747.15
        ],
        [
            1708681920000,
            747.1,
            747.35,
            747.1,
            747.1
        ],
        [
            1708681980000,
            747.1,
            747.2,
            747.05,
            747.05
        ],
        [
            1708682040000,
            747.2,
            747.25,
            747.05,
            747.05
        ],
        [
            1708682100000,
            747.15,
            747.7,
            747.15,
            747.7
        ],
        [
            1708682160000,
            747.7,
            748.6,
            747.55,
            748.2
        ],
        [
            1708682220000,
            748.2,
            750.45,
            748.2,
            750
        ],
        [
            1708682280000,
            750.05,
            750.05,
            748.35,
            748.35
        ],
        [
            1708682340000,
            748.35,
            748.7,
            748.05,
            748.15
        ],
        [
            1708682400000,
            748.55,
            748.8,
            748.05,
            748.3
        ],
        [
            1708682460000,
            748.05,
            748.9,
            748,
            748.25
        ],
        [
            1708682520000,
            748.25,
            748.9,
            748,
            748.15
        ],
        [
            1708682580000,
            748.55,
            748.55,
            748.05,
            748.05
        ],
        [
            1708682640000,
            748.55,
            749.2,
            748.45,
            748.7
        ],
        [
            1708682700000,
            749.35,
            749.45,
            749.15,
            749.15
        ],
        [
            1708682760000,
            749.15,
            750.55,
            749.15,
            750.25
        ],
        [
            1708682820000,
            750.7,
            750.7,
            750.1,
            750.1
        ],
        [
            1708682880000,
            750.05,
            750.3,
            750.05,
            750.1
        ],
        [
            1708682940000,
            750.65,
            750.65,
            750.25,
            750.5
        ],
        [
            1708683000000,
            750.35,
            750.7,
            750.3,
            750.3
        ],
        [
            1708683060000,
            750.2,
            750.5,
            750.1,
            750.15
        ],
        [
            1708683120000,
            750.15,
            750.8,
            750.15,
            750.8
        ],
        [
            1708683180000,
            751,
            751,
            750.35,
            750.35
        ],
        [
            1708683240000,
            750.35,
            750.35,
            750.15,
            750.15
        ],
        [
            1708683300000,
            750.15,
            750.5,
            750.15,
            750.15
        ],
        [
            1708683360000,
            750.15,
            750.6,
            750,
            750
        ],
        [
            1708683420000,
            750,
            750.05,
            748.4,
            749.45
        ],
        [
            1708683480000,
            749.45,
            749.85,
            749.3,
            749.85
        ],
        [
            1708683540000,
            749.4,
            750,
            748.95,
            749
        ],
        [
            1708683600000,
            749,
            749.4,
            749,
            749.05
        ],
        [
            1708683660000,
            749.05,
            749.3,
            749.05,
            749.1
        ],
        [
            1708683720000,
            749.3,
            749.75,
            749.1,
            749.75
        ],
        [
            1708683780000,
            750,
            750,
            749.4,
            749.65
        ],
        [
            1708683840000,
            749.75,
            750.15,
            749.7,
            749.75
        ],
        [
            1708683900000,
            749.75,
            750.2,
            749.55,
            750
        ],
        [
            1708683960000,
            750,
            750.2,
            750,
            750
        ],
        [
            1708684020000,
            750,
            750.2,
            750,
            750
        ],
        [
            1708684080000,
            750,
            750,
            749.15,
            749.65
        ],
        [
            1708684140000,
            749.6,
            749.7,
            749.05,
            749.15
        ],
        [
            1708684200000,
            749.15,
            749.25,
            748.55,
            748.75
        ],
        [
            1708684260000,
            748.7,
            748.75,
            747.5,
            747.5
        ],
        [
            1708684320000,
            747.55,
            748.35,
            747.45,
            748.25
        ],
        [
            1708684380000,
            748.3,
            748.4,
            747.9,
            747.9
        ],
        [
            1708684440000,
            747.6,
            747.95,
            747.45,
            747.5
        ],
        [
            1708684500000,
            747.6,
            747.7,
            746.8,
            746.8
        ],
        [
            1708684560000,
            746.75,
            747.2,
            746.75,
            747.2
        ],
        [
            1708684620000,
            747.45,
            747.7,
            747.15,
            747.5
        ],
        [
            1708684680000,
            747.7,
            747.95,
            747.5,
            747.5
        ],
        [
            1708684740000,
            747.5,
            747.85,
            747.5,
            747.8
        ],
        [
            1708684800000,
            747.8,
            747.8,
            747.15,
            747.3
        ],
        [
            1708684860000,
            747.3,
            747.9,
            747,
            747.9
        ],
        [
            1708684920000,
            748,
            748,
            747.9,
            747.95
        ],
        [
            1708684980000,
            747.95,
            748,
            747.2,
            747.5
        ],
        [
            1708685040000,
            747.6,
            747.6,
            746.85,
            747.2
        ],
        [
            1708685100000,
            747.45,
            747.45,
            747,
            747.25
        ],
        [
            1708685160000,
            747.25,
            747.7,
            747.25,
            747.7
        ],
        [
            1708685220000,
            747.7,
            747.75,
            747.45,
            747.45
        ],
        [
            1708685280000,
            747.45,
            747.8,
            747.25,
            747.5
        ],
        [
            1708685340000,
            748,
            748,
            747.35,
            747.35
        ],
        [
            1708685400000,
            747.65,
            747.65,
            747.25,
            747.65
        ],
        [
            1708685460000,
            747.35,
            747.65,
            747.25,
            747.55
        ],
        [
            1708685520000,
            747.55,
            747.65,
            747.55,
            747.55
        ],
        [
            1708685580000,
            747.65,
            747.75,
            747.5,
            747.75
        ],
        [
            1708685640000,
            747.75,
            747.75,
            747.5,
            747.5
        ],
        [
            1708685700000,
            747.5,
            747.55,
            746.75,
            747.15
        ],
        [
            1708685760000,
            747.1,
            747.4,
            747.05,
            747.3
        ],
        [
            1708685820000,
            747.35,
            747.4,
            747.05,
            747.05
        ],
        [
            1708685880000,
            747.05,
            747.5,
            746.9,
            747.5
        ],
        [
            1708685940000,
            747.5,
            747.7,
            747.5,
            747.6
        ],
        [
            1708686000000,
            747.6,
            747.8,
            747.6,
            747.7
        ],
        [
            1708686060000,
            747.7,
            747.85,
            747.7,
            747.85
        ],
        [
            1708686120000,
            747.65,
            747.95,
            747.65,
            747.95
        ],
        [
            1708686180000,
            747.8,
            748,
            747.8,
            747.95
        ],
        [
            1708686240000,
            747.95,
            748,
            747.9,
            747.95
        ],
        [
            1708686300000,
            747.95,
            748,
            747.95,
            748
        ],
        [
            1708686360000,
            747.95,
            748,
            747.8,
            748
        ],
        [
            1708686420000,
            747.8,
            748,
            747.8,
            748
        ],
        [
            1708686480000,
            747.8,
            748,
            747.5,
            747.5
        ],
        [
            1708686540000,
            747.7,
            748,
            747.7,
            747.95
        ],
        [
            1708686600000,
            747.95,
            748,
            747.25,
            747.25
        ],
        [
            1708686660000,
            747.3,
            747.55,
            747.2,
            747.2
        ],
        [
            1708686720000,
            747.2,
            747.2,
            746.8,
            746.8
        ],
        [
            1708686780000,
            746.8,
            746.85,
            746.45,
            746.45
        ],
        [
            1708686840000,
            746.65,
            746.95,
            746.4,
            746.95
        ],
        [
            1708686900000,
            747.05,
            747.25,
            746.8,
            747.1
        ],
        [
            1708686960000,
            746.8,
            746.95,
            746.3,
            746.55
        ],
        [
            1708687020000,
            746.5,
            746.8,
            746.5,
            746.7
        ],
        [
            1708687080000,
            746.8,
            746.8,
            746.6,
            746.7
        ],
        [
            1708687140000,
            746.7,
            746.8,
            746.6,
            746.8
        ],
        [
            1708687200000,
            746.85,
            746.95,
            746.85,
            746.9
        ],
        [
            1708687260000,
            746.9,
            746.95,
            746.7,
            746.75
        ],
        [
            1708687320000,
            746.7,
            746.7,
            746.6,
            746.65
        ],
        [
            1708687380000,
            746.6,
            746.7,
            746.05,
            746.2
        ],
        [
            1708687440000,
            746.2,
            746.7,
            746,
            746.7
        ],
        [
            1708687500000,
            746.55,
            746.7,
            746.35,
            746.35
        ],
        [
            1708687560000,
            746.35,
            746.6,
            746.1,
            746.45
        ],
        [
            1708687620000,
            746.45,
            746.5,
            746,
            746
        ],
        [
            1708687680000,
            746,
            746.15,
            746,
            746.1
        ],
        [
            1708687740000,
            746.1,
            746.1,
            746,
            746
        ],
        [
            1708687800000,
            746,
            746.25,
            746,
            746.25
        ],
        [
            1708687860000,
            746.25,
            746.35,
            746.25,
            746.3
        ],
        [
            1708687920000,
            746.3,
            746.35,
            745.9,
            745.9
        ],
        [
            1708687980000,
            745.9,
            745.9,
            745.3,
            745.6
        ],
        [
            1708688040000,
            745.6,
            745.85,
            745.6,
            745.6
        ],
        [
            1708688100000,
            745.65,
            745.7,
            745.45,
            745.45
        ],
        [
            1708688160000,
            745.35,
            745.45,
            745,
            745.2
        ],
        [
            1708688220000,
            745.15,
            745.4,
            745.1,
            745.1
        ],
        [
            1708688280000,
            745.1,
            745.1,
            744.6,
            744.7
        ],
        [
            1708688340000,
            745,
            745.05,
            744.65,
            744.65
        ],
        [
            1708688400000,
            744.65,
            745,
            744.65,
            744.65
        ],
        [
            1708688460000,
            744.8,
            744.95,
            744.55,
            744.75
        ],
        [
            1708688520000,
            744.75,
            744.85,
            744.75,
            744.85
        ],
        [
            1708688580000,
            744.75,
            744.8,
            744.55,
            744.55
        ],
        [
            1708688640000,
            744.65,
            744.75,
            744.55,
            744.55
        ],
        [
            1708688700000,
            744.35,
            744.65,
            744.35,
            744.65
        ],
        [
            1708688760000,
            745,
            745,
            744.45,
            744.45
        ],
        [
            1708688820000,
            744.55,
            744.75,
            744.3,
            744.5
        ],
        [
            1708688880000,
            744.5,
            744.5,
            744,
            744.4
        ],
        [
            1708688940000,
            744,
            744.2,
            743.75,
            743.75
        ],
        [
            1708689000000,
            743.95,
            744.2,
            743.5,
            743.6
        ],
        [
            1708689060000,
            743.5,
            743.85,
            742.4,
            743.5
        ],
        [
            1708689120000,
            743.05,
            743.4,
            742.15,
            742.15
        ],
        [
            1708689180000,
            742.65,
            742.65,
            742.15,
            742.15
        ],
        [
            1708689240000,
            742.15,
            742.6,
            742.1,
            742.6
        ],
        [
            1708689300000,
            742.2,
            743.05,
            742.2,
            742.3
        ],
        [
            1708689360000,
            742.55,
            742.75,
            742.25,
            742.35
        ],
        [
            1708689420000,
            741.75,
            742.35,
            741.7,
            742.35
        ],
        [
            1708689480000,
            741.9,
            742.35,
            741.9,
            742.15
        ],
        [
            1708689540000,
            742.15,
            742.2,
            741.85,
            742
        ],
        [
            1708689600000,
            742.25,
            742.35,
            742,
            742.3
        ],
        [
            1708689660000,
            742.3,
            742.55,
            742.3,
            742.45
        ],
        [
            1708689720000,
            742.4,
            742.45,
            742,
            742.05
        ],
        [
            1708689780000,
            742,
            742.65,
            742,
            742.65
        ],
        [
            1708689840000,
            742.65,
            742.7,
            742.65,
            742.7
        ],
        [
            1708689900000,
            742.4,
            742.95,
            742.4,
            742.95
        ],
        [
            1708689960000,
            742.95,
            743.1,
            742.95,
            743.1
        ],
        [
            1708690020000,
            743.05,
            743.05,
            742.6,
            742.75
        ],
        [
            1708690080000,
            742.7,
            742.75,
            742.5,
            742.55
        ],
        [
            1708690140000,
            742.5,
            742.55,
            742.25,
            742.55
        ],
        [
            1708690200000,
            742.3,
            742.55,
            742.05,
            742.35
        ],
        [
            1708690260000,
            742.35,
            742.7,
            742.3,
            742.7
        ],
        [
            1708690320000,
            742.75,
            742.75,
            742.65,
            742.75
        ],
        [
            1708690380000,
            742.7,
            742.75,
            742.35,
            742.35
        ],
        [
            1708690440000,
            742.8,
            742.8,
            742.5,
            742.8
        ],
        [
            1708690500000,
            742.8,
            744,
            742.8,
            744
        ],
        [
            1708690560000,
            744.25,
            744.55,
            743.8,
            744.25
        ],
        [
            1708690620000,
            744.25,
            744.95,
            744.25,
            744.95
        ],
        [
            1708690680000,
            744.95,
            745,
            744.55,
            744.95
        ],
        [
            1708690740000,
            744.75,
            744.95,
            744.05,
            744.75
        ],
        [
            1708690800000,
            744.55,
            745,
            744.45,
            744.85
        ],
        [
            1708690860000,
            744.85,
            744.9,
            744.65,
            744.9
        ],
        [
            1708690920000,
            744.85,
            745,
            744.85,
            745
        ],
        [
            1708690980000,
            745,
            745,
            744.95,
            744.95
        ],
        [
            1708691040000,
            745,
            745,
            744.5,
            744.9
        ],
        [
            1708691100000,
            744.65,
            744.95,
            744.45,
            744.9
        ],
        [
            1708691160000,
            744.8,
            744.95,
            744.8,
            744.95
        ],
        [
            1708691220000,
            745,
            745,
            744.95,
            745
        ],
        [
            1708691280000,
            745,
            745,
            744.65,
            744.65
        ],
        [
            1708691340000,
            744.95,
            745,
            744.95,
            745
        ],
        [
            1708691400000,
            744.95,
            745,
            744.95,
            745
        ],
        [
            1708691460000,
            744.95,
            744.95,
            744.55,
            744.8
        ],
        [
            1708691520000,
            744.9,
            744.9,
            744.3,
            744.9
        ],
        [
            1708691580000,
            744.6,
            744.9,
            744.6,
            744.75
        ],
        [
            1708691640000,
            744.65,
            744.9,
            744.35,
            744.5
        ],
        [
            1708691700000,
            744.75,
            744.75,
            744.4,
            744.75
        ],
        [
            1708691760000,
            744.55,
            744.75,
            744.4,
            744.75
        ],
        [
            1708691820000,
            744.65,
            744.75,
            744.65,
            744.75
        ],
        [
            1708691880000,
            744.7,
            744.7,
            744.15,
            744.3
        ],
        [
            1708691940000,
            743.95,
            744.45,
            743.9,
            744.4
        ],
        [
            1708692000000,
            744.25,
            744.75,
            744.25,
            744.7
        ],
        [
            1708692060000,
            744.75,
            744.75,
            744.45,
            744.7
        ],
        [
            1708692120000,
            744.65,
            744.65,
            744.4,
            744.65
        ],
        [
            1708692180000,
            744.55,
            744.65,
            744.25,
            744.4
        ],
        [
            1708692240000,
            744.35,
            744.7,
            744.05,
            744.7
        ],
        [
            1708692300000,
            744.75,
            744.95,
            744.55,
            744.95
        ],
        [
            1708692360000,
            744.7,
            744.9,
            744.55,
            744.85
        ],
        [
            1708692420000,
            744.9,
            744.9,
            744.2,
            744.4
        ],
        [
            1708692480000,
            744.2,
            744.6,
            744.2,
            744.55
        ],
        [
            1708692540000,
            744.25,
            744.5,
            744.1,
            744.35
        ],
        [
            1708692600000,
            744.2,
            744.35,
            744.2,
            744.2
        ],
        [
            1708692660000,
            744.25,
            744.25,
            743.95,
            744.1
        ],
        [
            1708692720000,
            744.05,
            744.2,
            743.9,
            744.2
        ],
        [
            1708692780000,
            743.85,
            744.15,
            743.05,
            743.4
        ],
        [
            1708692840000,
            743.25,
            743.5,
            743.25,
            743.5
        ],
        [
            1708692900000,
            743.5,
            743.5,
            743.45,
            743.5
        ],
        [
            1708692960000,
            743.5,
            743.5,
            743.45,
            743.5
        ],
        [
            1708693020000,
            743.45,
            743.5,
            743.45,
            743.5
        ],
        [
            1708693080000,
            743.45,
            743.5,
            743.35,
            743.35
        ],
        [
            1708693140000,
            743.5,
            743.8,
            743.15,
            743.8
        ],
        [
            1708693200000,
            743.75,
            744.1,
            743.75,
            744.1
        ],
        [
            1708693260000,
            744.1,
            744.2,
            744,
            744.05
        ],
        [
            1708693320000,
            744,
            744.15,
            743.75,
            744.05
        ],
        [
            1708693380000,
            743.75,
            745.35,
            743.75,
            745.3
        ],
        [
            1708693440000,
            745.35,
            745.35,
            745.2,
            745.3
        ],
        [
            1708693500000,
            745.3,
            745.3,
            745,
            745
        ],
        [
            1708693560000,
            745,
            746.05,
            744.85,
            746.05
        ],
        [
            1708693620000,
            746.15,
            746.8,
            745.7,
            746.65
        ],
        [
            1708693680000,
            746.65,
            747.2,
            745.95,
            745.95
        ],
        [
            1708693740000,
            745.95,
            746.35,
            745.9,
            746
        ],
        [
            1708693800000,
            746,
            746.2,
            745.85,
            746.1
        ],
        [
            1708693860000,
            746,
            746.05,
            745.8,
            745.8
        ],
        [
            1708693920000,
            745.8,
            745.8,
            745.35,
            745.45
        ],
        [
            1708693980000,
            745.4,
            745.45,
            745,
            745.35
        ],
        [
            1708694040000,
            745.45,
            745.45,
            745.05,
            745.3
        ],
        [
            1708694100000,
            745.45,
            745.45,
            745,
            745
        ],
        [
            1708694160000,
            745.05,
            745.35,
            745.05,
            745.3
        ],
        [
            1708694220000,
            745.3,
            745.35,
            745.3,
            745.3
        ],
        [
            1708694280000,
            745.3,
            745.35,
            745,
            745.3
        ],
        [
            1708694340000,
            745.3,
            745.35,
            745,
            745.3
        ],
        [
            1708694400000,
            745.3,
            745.35,
            745,
            745.25
        ],
        [
            1708694460000,
            745.25,
            745.4,
            745,
            745.35
        ],
        [
            1708694520000,
            745.35,
            745.5,
            745.35,
            745.35
        ],
        [
            1708694580000,
            745.35,
            745.35,
            745.2,
            745.25
        ],
        [
            1708694640000,
            745.25,
            745.35,
            745.1,
            745.2
        ],
        [
            1708694700000,
            745.2,
            745.2,
            744,
            744.2
        ],
        [
            1708694760000,
            744.25,
            744.35,
            744.1,
            744.2
        ],
        [
            1708694820000,
            745,
            745.7,
            745,
            745.65
        ],
        [
            1708694880000,
            745.5,
            745.95,
            745.5,
            745.95
        ],
        [
            1708694940000,
            746,
            746.1,
            746,
            746.1
        ],
        [
            1708695000000,
            746.05,
            746.05,
            745.55,
            745.8
        ],
        [
            1708695060000,
            745.75,
            745.8,
            745.55,
            745.75
        ],
        [
            1708695120000,
            745.4,
            745.8,
            745.4,
            745.75
        ],
        [
            1708695180000,
            745.75,
            745.75,
            745.4,
            745.75
        ],
        [
            1708695240000,
            745.65,
            745.65,
            745.05,
            745.45
        ],
        [
            1708695300000,
            745.45,
            745.55,
            745.3,
            745.3
        ],
        [
            1708695360000,
            745.3,
            745.5,
            745.15,
            745.15
        ],
        [
            1708695420000,
            745.2,
            745.75,
            745,
            745.25
        ],
        [
            1708695480000,
            745.25,
            745.6,
            745.25,
            745.6
        ],
        [
            1708695540000,
            745.65,
            745.8,
            745.55,
            745.65
        ],
        [
            1708695600000,
            745.65,
            745.65,
            745.05,
            745.3
        ],
        [
            1708695660000,
            745.35,
            745.45,
            745.1,
            745.4
        ],
        [
            1708695720000,
            745.4,
            745.45,
            745.3,
            745.45
        ],
        [
            1708695780000,
            745.35,
            745.45,
            745.25,
            745.4
        ],
        [
            1708695840000,
            745.4,
            745.45,
            745.05,
            745.3
        ],
        [
            1708695900000,
            745.3,
            745.35,
            745.25,
            745.3
        ],
        [
            1708695960000,
            745.3,
            745.35,
            745.25,
            745.35
        ],
        [
            1708696020000,
            745.35,
            745.4,
            745.35,
            745.4
        ],
        [
            1708696080000,
            745.35,
            745.35,
            745.2,
            745.3
        ],
        [
            1708696140000,
            745.3,
            745.3,
            745.2,
            745.25
        ],
        [
            1708696200000,
            745.25,
            745.4,
            745,
            745.4
        ],
        [
            1708696260000,
            745.4,
            746.7,
            745.4,
            746.25
        ],
        [
            1708696320000,
            746.25,
            746.35,
            745.75,
            745.75
        ],
        [
            1708696380000,
            745.75,
            745.8,
            745.5,
            745.55
        ],
        [
            1708696440000,
            745.55,
            746.1,
            745.55,
            745.7
        ],
        [
            1708696500000,
            745.7,
            746,
            745.65,
            745.65
        ],
        [
            1708696560000,
            745.65,
            745.75,
            745.2,
            745.4
        ],
        [
            1708696620000,
            745.55,
            745.7,
            745.35,
            745.45
        ],
        [
            1708696680000,
            745.45,
            745.8,
            745.35,
            745.5
        ],
        [
            1708696740000,
            745.6,
            745.7,
            745.55,
            745.55
        ],
        [
            1708696800000,
            745.6,
            745.65,
            745.45,
            745.45
        ],
        [
            1708696860000,
            745.45,
            745.6,
            745.4,
            745.55
        ],
        [
            1708696920000,
            745.45,
            745.55,
            745.15,
            745.3
        ],
        [
            1708696980000,
            745.15,
            745.3,
            745.15,
            745.25
        ],
        [
            1708697040000,
            745.25,
            745.7,
            745.15,
            745.7
        ],
        [
            1708697100000,
            745.75,
            746.15,
            745.7,
            746.15
        ],
        [
            1708697160000,
            746.15,
            746.25,
            745.7,
            745.85
        ],
        [
            1708697220000,
            745.85,
            746.1,
            745.85,
            746
        ],
        [
            1708697280000,
            745.75,
            746.1,
            745.75,
            746.1
        ],
        [
            1708697340000,
            746.15,
            746.15,
            745.45,
            745.45
        ],
        [
            1708697400000,
            745.45,
            745.85,
            745.3,
            745.85
        ],
        [
            1708697460000,
            745.85,
            745.85,
            745.45,
            745.85
        ],
        [
            1708697520000,
            745.7,
            745.85,
            745.7,
            745.85
        ],
        [
            1708697580000,
            745.85,
            745.85,
            745.35,
            745.7
        ],
        [
            1708697640000,
            745.4,
            745.75,
            745.4,
            745.7
        ],
        [
            1708697700000,
            745.65,
            745.7,
            745.3,
            745.4
        ],
        [
            1708697760000,
            745.05,
            745.3,
            744.9,
            745.3
        ],
        [
            1708697820000,
            745.2,
            745.25,
            745.05,
            745.05
        ],
        [
            1708697880000,
            745.15,
            745.9,
            744.9,
            745.6
        ],
        [
            1708697940000,
            745.6,
            745.9,
            745.3,
            745.3
        ],
        [
            1708698000000,
            745.4,
            745.5,
            745.35,
            745.5
        ],
        [
            1708698060000,
            745.35,
            745.5,
            745.15,
            745.15
        ],
        [
            1708698120000,
            745.4,
            745.4,
            745.2,
            745.3
        ],
        [
            1708698180000,
            745.6,
            745.6,
            745.3,
            745.5
        ],
        [
            1708698240000,
            745.6,
            745.6,
            745.55,
            745.55
        ],
        [
            1708698300000,
            745.55,
            745.6,
            745.5,
            745.5
        ],
        [
            1708698360000,
            745.55,
            745.55,
            745.45,
            745.55
        ],
        [
            1708698420000,
            745.45,
            745.55,
            745.45,
            745.5
        ],
        [
            1708698480000,
            745.45,
            745.5,
            745.45,
            745.5
        ],
        [
            1708698540000,
            745.45,
            745.6,
            745.45,
            745.6
        ],
        [
            1708698600000,
            745.55,
            745.55,
            745.05,
            745.35
        ],
        [
            1708698660000,
            745.05,
            745.35,
            745.05,
            745.25
        ],
        [
            1708698720000,
            745.15,
            745.2,
            745,
            745.05
        ],
        [
            1708698780000,
            745.15,
            745.15,
            744.9,
            745.05
        ],
        [
            1708698840000,
            744.9,
            745.05,
            744.9,
            745
        ],
        [
            1708698900000,
            745.2,
            745.35,
            745.2,
            745.35
        ],
        [
            1708698960000,
            745.2,
            745.4,
            745.2,
            745.25
        ],
        [
            1708699020000,
            745.15,
            746.05,
            745.15,
            746.05
        ],
        [
            1708699080000,
            746.15,
            746.45,
            745.9,
            746.05
        ],
        [
            1708699140000,
            746.05,
            746.5,
            746.05,
            746.45
        ],
        [
            1708699200000,
            746.3,
            746.4,
            746.3,
            746.3
        ],
        [
            1708699260000,
            746.2,
            746.25,
            746.2,
            746.25
        ],
        [
            1708699320000,
            746.25,
            746.45,
            746.25,
            746.4
        ],
        [
            1708699380000,
            746.4,
            746.45,
            746,
            746
        ],
        [
            1708699440000,
            745.7,
            746.25,
            745.7,
            746.05
        ],
        [
            1708699500000,
            746.25,
            747.2,
            746.25,
            747.2
        ],
        [
            1708699560000,
            747.15,
            747.2,
            746.05,
            746.05
        ],
        [
            1708699620000,
            746.15,
            746.35,
            746,
            746
        ],
        [
            1708699680000,
            746.15,
            746.15,
            745.75,
            746.05
        ],
        [
            1708699740000,
            745.95,
            746.1,
            745.65,
            746.1
        ],
        [
            1708699800000,
            746,
            746.15,
            746,
            746.1
        ],
        [
            1708699860000,
            746,
            746.15,
            746,
            746.05
        ],
        [
            1708699920000,
            746,
            746.15,
            745.8,
            745.85
        ],
        [
            1708699980000,
            745.95,
            745.95,
            745.8,
            745.8
        ],
        [
            1708700040000,
            745.65,
            745.95,
            745.65,
            745.85
        ],
        [
            1708700100000,
            745.85,
            745.95,
            745.85,
            745.9
        ],
        [
            1708700160000,
            745.95,
            746.35,
            745.6,
            746.05
        ],
        [
            1708700220000,
            745.95,
            746.1,
            745.75,
            746.1
        ],
        [
            1708700280000,
            746.05,
            746.2,
            745.55,
            745.85
        ],
        [
            1708700340000,
            745.85,
            745.9,
            745.8,
            745.9
        ],
        [
            1708700400000,
            745.8,
            745.9,
            745.6,
            745.7
        ],
        [
            1708700460000,
            745.6,
            745.7,
            745.55,
            745.65
        ],
        [
            1708700520000,
            745.7,
            745.7,
            745.55,
            745.55
        ],
        [
            1708700580000,
            745.6,
            745.6,
            745.45,
            745.45
        ],
        [
            1708700640000,
            745.6,
            745.8,
            745.25,
            745.8
        ],
        [
            1708700700000,
            746.1,
            746.1,
            745.8,
            745.8
        ],
        [
            1708700760000,
            745.8,
            746,
            745.1,
            746
        ],
        [
            1708700820000,
            746,
            746,
            744.95,
            746
        ],
        [
            1708700880000,
            746.15,
            746.3,
            745.2,
            745.75
        ],
        [
            1708700940000,
            745.5,
            745.55,
            745,
            745.5
        ],
        [
            1708701000000,
            745.5,
            745.8,
            745.05,
            745.45
        ],
        [
            1708701060000,
            745.3,
            745.45,
            744.85,
            745
        ],
        [
            1708701120000,
            745.5,
            745.6,
            745,
            745.35
        ],
        [
            1708701180000,
            745.2,
            745.45,
            745,
            745.2
        ],
        [
            1708701240000,
            745.1,
            745.2,
            745,
            745
        ],
        [
            1708701300000,
            745.15,
            745.35,
            744.6,
            745.05
        ],
        [
            1708701360000,
            745.05,
            745.05,
            744.6,
            745.05
        ],
        [
            1708701420000,
            745,
            745.15,
            745,
            745.05
        ],
        [
            1708701480000,
            745.05,
            745.25,
            744.6,
            744.6
        ],
        [
            1708701540000,
            745.25,
            745.35,
            745.25,
            745.3
        ],
        [
            1708701600000,
            745.4,
            745.45,
            745.35,
            745.45
        ],
        [
            1708701660000,
            745.45,
            745.45,
            745.05,
            745.15
        ],
        [
            1708701720000,
            745.15,
            745.15,
            743.75,
            743.75
        ],
        [
            1708701780000,
            743.4,
            743.75,
            742.65,
            743.15
        ],
        [
            1708701840000,
            742.75,
            743.35,
            742.25,
            742.4
        ],
        [
            1708701900000,
            742.25,
            742.25,
            740.85,
            742.05
        ],
        [
            1708701960000,
            742.05,
            742.05,
            740.45,
            740.45
        ],
        [
            1708702020000,
            741,
            741.1,
            741,
            741.05
        ],
        [
            1708702080000,
            741.1,
            741.1,
            738.95,
            740.8
        ],
        [
            1708702140000,
            740.2,
            742,
            740.2,
            740.2
        ],
        [
            1708938900000,
            743.95,
            747.35,
            736.55,
            739.2
        ],
        [
            1708938960000,
            737.75,
            738.45,
            736.35,
            738.45
        ],
        [
            1708939020000,
            738.85,
            739.65,
            738.85,
            739.65
        ],
        [
            1708939080000,
            739.65,
            740.5,
            739.6,
            739.65
        ],
        [
            1708939140000,
            739.85,
            741.15,
            739.85,
            739.9
        ],
        [
            1708939200000,
            739.85,
            739.85,
            738.2,
            738.2
        ],
        [
            1708939260000,
            738.2,
            738.9,
            738.1,
            738.6
        ],
        [
            1708939320000,
            738.6,
            739.35,
            738.6,
            739.35
        ],
        [
            1708939380000,
            739.25,
            739.4,
            739,
            739
        ],
        [
            1708939440000,
            738.6,
            739.2,
            738.4,
            738.55
        ],
        [
            1708939500000,
            738.45,
            739.1,
            738.45,
            738.5
        ],
        [
            1708939560000,
            738.5,
            739,
            738.3,
            738.35
        ],
        [
            1708939620000,
            738.35,
            738.35,
            737.45,
            737.45
        ],
        [
            1708939680000,
            737.3,
            737.65,
            736.85,
            737
        ],
        [
            1708939740000,
            737.35,
            737.35,
            737,
            737.25
        ],
        [
            1708939800000,
            737.2,
            737.5,
            737.2,
            737.2
        ],
        [
            1708939860000,
            737.2,
            737.2,
            736.25,
            737.15
        ],
        [
            1708939920000,
            737.3,
            737.95,
            737.15,
            737.7
        ],
        [
            1708939980000,
            737.5,
            739.1,
            737.5,
            739.1
        ],
        [
            1708940040000,
            739.1,
            740.4,
            738.75,
            740.15
        ],
        [
            1708940100000,
            740,
            740.25,
            740,
            740.15
        ],
        [
            1708940160000,
            740.1,
            740.1,
            740,
            740.05
        ],
        [
            1708940220000,
            740.1,
            740.85,
            740,
            740.1
        ],
        [
            1708940280000,
            740.65,
            740.65,
            740,
            740
        ],
        [
            1708940340000,
            740,
            740.05,
            740,
            740.05
        ],
        [
            1708940400000,
            740,
            740.05,
            740,
            740
        ],
        [
            1708940460000,
            740.05,
            741.4,
            740,
            740.85
        ],
        [
            1708940520000,
            740.4,
            740.95,
            740.3,
            740.5
        ],
        [
            1708940580000,
            740.35,
            740.35,
            739.55,
            740.1
        ],
        [
            1708940640000,
            740.1,
            741.15,
            740.1,
            740.85
        ],
        [
            1708940700000,
            740.8,
            741.1,
            740.4,
            741.05
        ],
        [
            1708940760000,
            741.15,
            741.15,
            740.05,
            740.5
        ],
        [
            1708940820000,
            740.45,
            740.6,
            740,
            740.1
        ],
        [
            1708940880000,
            740.35,
            740.45,
            740.05,
            740.4
        ],
        [
            1708940940000,
            740.85,
            741.3,
            740.05,
            740.05
        ],
        [
            1708941000000,
            740,
            740.3,
            740,
            740.1
        ],
        [
            1708941060000,
            740,
            740.1,
            740,
            740
        ],
        [
            1708941120000,
            740,
            740.05,
            740,
            740.05
        ],
        [
            1708941180000,
            740,
            740.95,
            740,
            740.95
        ],
        [
            1708941600000,
            740.15,
            740.15,
            740.15,
            740.15
        ],
        [
            1708941780000,
            740.75,
            740.75,
            740.75,
            740.75
        ],
        [
            1708943580000,
            740.3,
            740.45,
            740,
            740
        ],
        [
            1708943640000,
            740.35,
            740.4,
            740,
            740
        ],
        [
            1708943700000,
            740,
            740.35,
            739.4,
            740
        ],
        [
            1708943760000,
            740.05,
            740.4,
            740,
            740.1
        ],
        [
            1708943820000,
            740.05,
            740.9,
            740.05,
            740.45
        ],
        [
            1708943880000,
            740.3,
            740.85,
            740,
            740
        ],
        [
            1708944060000,
            739.75,
            739.75,
            739.75,
            739.75
        ],
        [
            1708944480000,
            740,
            740.05,
            740,
            740.05
        ],
        [
            1708944540000,
            740.15,
            740.45,
            739.85,
            740
        ],
        [
            1708944600000,
            740,
            740.15,
            739.75,
            740.1
        ],
        [
            1708944660000,
            740.1,
            740.7,
            740.1,
            740.1
        ],
        [
            1708944720000,
            740,
            740.25,
            739.5,
            740
        ],
        [
            1708944780000,
            740,
            740.1,
            739.6,
            740
        ],
        [
            1708944840000,
            740,
            740.05,
            739.55,
            740
        ],
        [
            1708944900000,
            740,
            740.1,
            740,
            740
        ],
        [
            1708944960000,
            740,
            740.05,
            740,
            740
        ],
        [
            1708945020000,
            739.95,
            740,
            738,
            738.3
        ],
        [
            1708945080000,
            738.1,
            738.2,
            735.05,
            737
        ],
        [
            1708945140000,
            736.55,
            737.75,
            736.55,
            737.35
        ],
        [
            1708945200000,
            737.35,
            738.6,
            737.05,
            738.1
        ],
        [
            1708945260000,
            738.4,
            738.6,
            736.95,
            736.95
        ],
        [
            1708945320000,
            736.45,
            737.55,
            736.45,
            737.5
        ],
        [
            1708945380000,
            737.35,
            737.65,
            737,
            737.5
        ],
        [
            1708945440000,
            737.5,
            737.55,
            737.05,
            737.55
        ],
        [
            1708945500000,
            737.6,
            737.75,
            737.35,
            737.75
        ],
        [
            1708945560000,
            737.75,
            737.9,
            737.5,
            737.9
        ],
        [
            1708945620000,
            737.65,
            738.25,
            737.65,
            737.8
        ],
        [
            1708945680000,
            737.8,
            738.4,
            737.8,
            738.4
        ],
        [
            1708945740000,
            738.95,
            739.7,
            738.9,
            739.3
        ],
        [
            1708945800000,
            739.3,
            739.45,
            739,
            739
        ],
        [
            1708945860000,
            739,
            739,
            739,
            739
        ],
        [
            1708945920000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708945980000,
            739,
            739,
            739,
            739
        ],
        [
            1708946040000,
            739,
            739,
            738.75,
            739
        ],
        [
            1708946100000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708946160000,
            739,
            739.4,
            739,
            739.3
        ],
        [
            1708946220000,
            739.35,
            739.35,
            739,
            739.1
        ],
        [
            1708946280000,
            739.1,
            739.7,
            739,
            739.4
        ],
        [
            1708946340000,
            739.35,
            739.9,
            739.35,
            739.65
        ],
        [
            1708946400000,
            739.7,
            739.7,
            739,
            739
        ],
        [
            1708946460000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708946520000,
            739,
            739.6,
            739,
            739.35
        ],
        [
            1708946580000,
            739.45,
            739.45,
            739,
            739.35
        ],
        [
            1708946640000,
            739,
            739.05,
            739,
            739.05
        ],
        [
            1708946700000,
            739.05,
            739.4,
            739,
            739.1
        ],
        [
            1708946760000,
            739.2,
            739.55,
            739.05,
            739.1
        ],
        [
            1708946820000,
            739.2,
            739.45,
            739.2,
            739.25
        ],
        [
            1708946880000,
            739.25,
            739.25,
            739,
            739
        ],
        [
            1708946940000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708947000000,
            739,
            739.05,
            738.8,
            739.05
        ],
        [
            1708947060000,
            739.05,
            739.1,
            739,
            739
        ],
        [
            1708947120000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708947180000,
            739,
            739.1,
            739,
            739
        ],
        [
            1708947240000,
            739,
            739.05,
            738.8,
            739
        ],
        [
            1708947300000,
            739.05,
            739.05,
            739,
            739
        ],
        [
            1708947360000,
            739,
            739.1,
            738.55,
            739
        ],
        [
            1708947420000,
            739,
            739.15,
            739,
            739
        ],
        [
            1708947480000,
            739,
            739.35,
            739,
            739.3
        ],
        [
            1708947540000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708947600000,
            739,
            739.15,
            739,
            739
        ],
        [
            1708947660000,
            739,
            739.05,
            739,
            739.05
        ],
        [
            1708947720000,
            739,
            739.15,
            739,
            739
        ],
        [
            1708947780000,
            739,
            739.2,
            739,
            739.15
        ],
        [
            1708947840000,
            739.1,
            739.7,
            739.1,
            739.45
        ],
        [
            1708947900000,
            739.45,
            739.5,
            739.2,
            739.2
        ],
        [
            1708947960000,
            739.25,
            739.3,
            738.95,
            739.1
        ],
        [
            1708948020000,
            739.15,
            739.15,
            739,
            739
        ],
        [
            1708948080000,
            739,
            739.05,
            739,
            739.05
        ],
        [
            1708948140000,
            739.05,
            739.1,
            738.75,
            739.05
        ],
        [
            1708948200000,
            739.05,
            739.05,
            739,
            739
        ],
        [
            1708948260000,
            739.05,
            739.05,
            739,
            739
        ],
        [
            1708948320000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708948380000,
            739,
            739.05,
            739,
            739
        ],
        [
            1708948440000,
            739,
            739.05,
            738.75,
            739
        ],
        [
            1708948500000,
            739,
            739.05,
            738.95,
            739
        ],
        [
            1708948560000,
            739,
            739.2,
            739,
            739.1
        ],
        [
            1708948620000,
            739.05,
            739.4,
            739,
            739.4
        ],
        [
            1708948680000,
            739.65,
            739.7,
            739.3,
            739.6
        ],
        [
            1708948740000,
            739.45,
            739.45,
            738.85,
            738.85
        ],
        [
            1708948800000,
            738.75,
            738.95,
            738.7,
            738.95
        ],
        [
            1708948860000,
            738.9,
            738.9,
            738.15,
            738.15
        ],
        [
            1708948920000,
            738.15,
            738.3,
            738.05,
            738.1
        ],
        [
            1708948980000,
            738.1,
            738.1,
            737.8,
            737.85
        ],
        [
            1708949040000,
            737.75,
            737.9,
            737.75,
            737.9
        ],
        [
            1708949100000,
            737.95,
            738.25,
            737.95,
            738.25
        ],
        [
            1708949160000,
            738.15,
            738.75,
            738.05,
            738.4
        ],
        [
            1708949220000,
            738.45,
            738.5,
            738,
            738.05
        ],
        [
            1708949280000,
            738,
            738,
            737.7,
            737.7
        ],
        [
            1708949340000,
            737.7,
            737.75,
            737.45,
            737.55
        ],
        [
            1708949400000,
            737.5,
            737.95,
            737,
            737.05
        ],
        [
            1708949460000,
            737.3,
            737.4,
            737.15,
            737.3
        ],
        [
            1708949520000,
            737.3,
            737.4,
            736.85,
            737.3
        ],
        [
            1708949580000,
            737.3,
            737.55,
            737.25,
            737.5
        ],
        [
            1708949640000,
            737.5,
            737.65,
            737.4,
            737.55
        ],
        [
            1708949700000,
            737.5,
            737.55,
            737.4,
            737.4
        ],
        [
            1708949760000,
            737.3,
            737.5,
            737.3,
            737.5
        ],
        [
            1708949820000,
            737.45,
            737.45,
            736.9,
            736.9
        ],
        [
            1708949880000,
            736.9,
            737,
            736.5,
            736.5
        ],
        [
            1708949940000,
            736.5,
            736.5,
            735.5,
            735.8
        ],
        [
            1708950000000,
            735.75,
            735.85,
            735.2,
            735.6
        ],
        [
            1708950060000,
            735.65,
            735.8,
            735.45,
            735.6
        ],
        [
            1708950120000,
            735.7,
            735.8,
            735.4,
            735.7
        ],
        [
            1708950180000,
            735.7,
            735.8,
            735.35,
            735.8
        ],
        [
            1708950240000,
            735.8,
            735.8,
            735.1,
            735.2
        ],
        [
            1708950300000,
            735.1,
            735.95,
            735,
            735.8
        ],
        [
            1708950360000,
            735.8,
            735.9,
            735.4,
            735.4
        ],
        [
            1708950420000,
            735.35,
            735.35,
            735,
            735.25
        ],
        [
            1708950480000,
            735.25,
            735.9,
            735,
            735.05
        ],
        [
            1708950540000,
            735.05,
            735.25,
            735,
            735
        ],
        [
            1708950600000,
            735,
            735.1,
            734.95,
            735.05
        ],
        [
            1708950660000,
            735.05,
            735.1,
            734.85,
            735
        ],
        [
            1708950720000,
            735.05,
            735.4,
            734.75,
            734.75
        ],
        [
            1708950780000,
            735,
            736,
            735,
            735.9
        ],
        [
            1708950840000,
            735.9,
            735.9,
            735.2,
            735.2
        ],
        [
            1708950900000,
            735.3,
            735.45,
            735,
            735.2
        ],
        [
            1708950960000,
            735.15,
            735.45,
            735,
            735.3
        ],
        [
            1708951020000,
            735.3,
            735.3,
            735,
            735
        ],
        [
            1708951080000,
            735,
            735.1,
            735,
            735.1
        ],
        [
            1708951140000,
            735,
            735.1,
            734.35,
            735
        ],
        [
            1708951200000,
            735.05,
            735.1,
            734.7,
            735.1
        ],
        [
            1708951260000,
            735.1,
            735.4,
            735,
            735.4
        ],
        [
            1708951320000,
            735.45,
            736,
            735.45,
            735.55
        ],
        [
            1708951380000,
            735.8,
            735.8,
            735.15,
            735.25
        ],
        [
            1708951440000,
            735.15,
            735.8,
            735.15,
            735.8
        ],
        [
            1708951500000,
            735.8,
            735.85,
            735.55,
            735.7
        ],
        [
            1708951560000,
            735.7,
            735.7,
            735.25,
            735.25
        ],
        [
            1708951620000,
            735.4,
            735.7,
            735.35,
            735.6
        ],
        [
            1708951680000,
            735.35,
            735.6,
            735.35,
            735.5
        ],
        [
            1708951740000,
            735.45,
            735.45,
            735.35,
            735.35
        ],
        [
            1708951800000,
            735.4,
            735.45,
            735.2,
            735.3
        ],
        [
            1708951860000,
            735.2,
            735.5,
            735,
            735.3
        ],
        [
            1708951920000,
            735.3,
            736,
            735.3,
            735.95
        ],
        [
            1708951980000,
            735.95,
            736,
            735.6,
            735.6
        ],
        [
            1708952040000,
            735.55,
            735.65,
            735.35,
            735.65
        ],
        [
            1708952100000,
            735.65,
            736,
            735.65,
            735.9
        ],
        [
            1708952160000,
            735.9,
            735.9,
            735.25,
            735.35
        ],
        [
            1708952220000,
            735.25,
            735.7,
            735.25,
            735.45
        ],
        [
            1708952280000,
            735.6,
            736,
            735.25,
            735.25
        ],
        [
            1708952340000,
            735.4,
            735.4,
            735.1,
            735.3
        ],
        [
            1708952400000,
            735.1,
            735.5,
            734.95,
            735.15
        ],
        [
            1708952460000,
            735.05,
            735.1,
            735,
            735
        ],
        [
            1708952520000,
            735.05,
            735.35,
            735.05,
            735.15
        ],
        [
            1708952580000,
            735.05,
            735.2,
            735,
            735.1
        ],
        [
            1708952640000,
            735.05,
            735.2,
            735,
            735
        ],
        [
            1708952700000,
            735.1,
            735.2,
            734.85,
            735.1
        ],
        [
            1708952760000,
            734.9,
            735.2,
            734.8,
            734.8
        ],
        [
            1708952820000,
            735.1,
            735.35,
            734.85,
            735.25
        ],
        [
            1708952880000,
            735.05,
            735.35,
            734.9,
            735.2
        ],
        [
            1708952940000,
            735.2,
            735.35,
            734.95,
            735.25
        ],
        [
            1708953000000,
            735.05,
            735.45,
            735.05,
            735.35
        ],
        [
            1708953060000,
            735.4,
            735.5,
            735.2,
            735.4
        ],
        [
            1708953120000,
            735.35,
            735.35,
            735.05,
            735.05
        ],
        [
            1708953180000,
            735.15,
            735.15,
            735,
            735.05
        ],
        [
            1708953240000,
            735,
            735.2,
            735,
            735.2
        ],
        [
            1708953300000,
            735.15,
            735.45,
            735,
            735.25
        ],
        [
            1708953360000,
            735.35,
            735.5,
            735.15,
            735.4
        ],
        [
            1708953420000,
            735.4,
            735.7,
            735.35,
            735.7
        ],
        [
            1708953480000,
            735.5,
            735.6,
            735,
            735
        ],
        [
            1708953540000,
            735,
            735,
            734.25,
            734.75
        ],
        [
            1708953600000,
            734.7,
            734.7,
            734.3,
            734.35
        ],
        [
            1708953660000,
            734.45,
            734.5,
            734.1,
            734.1
        ],
        [
            1708953720000,
            734.3,
            734.3,
            733.4,
            733.6
        ],
        [
            1708953780000,
            733.7,
            733.85,
            733.1,
            733.15
        ],
        [
            1708953840000,
            733.05,
            733.3,
            733,
            733.2
        ],
        [
            1708953900000,
            733.05,
            733.3,
            732.55,
            732.8
        ],
        [
            1708953960000,
            732.7,
            732.95,
            732.3,
            732.65
        ],
        [
            1708954020000,
            732.65,
            732.65,
            732.25,
            732.6
        ],
        [
            1708954080000,
            732.6,
            732.85,
            732.4,
            732.4
        ],
        [
            1708954140000,
            732.65,
            732.65,
            732.4,
            732.55
        ],
        [
            1708954200000,
            732.55,
            732.65,
            732.3,
            732.35
        ],
        [
            1708954260000,
            732.45,
            732.9,
            732.15,
            732.4
        ],
        [
            1708954320000,
            732.7,
            733,
            732.4,
            732.75
        ],
        [
            1708954380000,
            732.5,
            733.15,
            732.5,
            733.1
        ],
        [
            1708954440000,
            733.1,
            733.3,
            732.75,
            732.8
        ],
        [
            1708954500000,
            733.15,
            733.6,
            732.85,
            733.35
        ],
        [
            1708954560000,
            733.3,
            733.55,
            733,
            733.35
        ],
        [
            1708954620000,
            733.35,
            733.5,
            733,
            733.1
        ],
        [
            1708954680000,
            733.3,
            733.4,
            733.1,
            733.4
        ],
        [
            1708954740000,
            733.4,
            733.95,
            732.9,
            733.4
        ],
        [
            1708954800000,
            733.35,
            733.85,
            733.15,
            733.75
        ],
        [
            1708954860000,
            733.7,
            734.7,
            733.2,
            733.7
        ],
        [
            1708954920000,
            733.65,
            733.65,
            733.3,
            733.5
        ],
        [
            1708954980000,
            733.45,
            733.95,
            733.05,
            733.8
        ],
        [
            1708955040000,
            733.75,
            734.05,
            733.4,
            733.85
        ],
        [
            1708955100000,
            733.85,
            733.85,
            733.5,
            733.6
        ],
        [
            1708955160000,
            733.6,
            733.7,
            733.45,
            733.55
        ],
        [
            1708955220000,
            733.6,
            734.45,
            733.4,
            734.35
        ],
        [
            1708955280000,
            733.7,
            734.25,
            733.65,
            733.9
        ],
        [
            1708955340000,
            733.9,
            733.9,
            733.4,
            733.6
        ],
        [
            1708955400000,
            733.65,
            733.65,
            733.4,
            733.55
        ],
        [
            1708955460000,
            733.55,
            733.65,
            733.05,
            733.3
        ],
        [
            1708955520000,
            733.25,
            734,
            732.9,
            733.85
        ],
        [
            1708955580000,
            733.8,
            733.8,
            733.4,
            733.45
        ],
        [
            1708955640000,
            733.35,
            733.95,
            733.3,
            733.65
        ],
        [
            1708955700000,
            733.6,
            733.6,
            732.4,
            733.2
        ],
        [
            1708955760000,
            733.15,
            733.15,
            732.65,
            732.95
        ],
        [
            1708955820000,
            732.8,
            733,
            732.6,
            732.8
        ],
        [
            1708955880000,
            732.7,
            732.85,
            732.4,
            732.65
        ],
        [
            1708955940000,
            732.85,
            732.85,
            732.35,
            732.8
        ],
        [
            1708956000000,
            732.85,
            733,
            732.65,
            732.9
        ],
        [
            1708956060000,
            732.85,
            732.95,
            732.55,
            732.65
        ],
        [
            1708956120000,
            732.85,
            733,
            732.6,
            732.9
        ],
        [
            1708956180000,
            732.85,
            733,
            732.7,
            732.9
        ],
        [
            1708956240000,
            732.85,
            733,
            732.55,
            732.9
        ],
        [
            1708956300000,
            732.85,
            732.95,
            732.4,
            732.8
        ],
        [
            1708956360000,
            732.85,
            733.1,
            732.5,
            732.85
        ],
        [
            1708956420000,
            732.8,
            732.8,
            732.4,
            732.4
        ],
        [
            1708956480000,
            732.6,
            732.95,
            732.4,
            732.85
        ],
        [
            1708956540000,
            732.8,
            733,
            732.6,
            732.9
        ],
        [
            1708956600000,
            732.85,
            732.95,
            732.55,
            732.55
        ],
        [
            1708956660000,
            732.85,
            732.85,
            732.25,
            732.35
        ],
        [
            1708956720000,
            732.45,
            732.5,
            732.3,
            732.4
        ],
        [
            1708956780000,
            732.35,
            733,
            732.35,
            732.75
        ],
        [
            1708956840000,
            732.8,
            733,
            732.75,
            732.9
        ],
        [
            1708956900000,
            733,
            733,
            732.6,
            732.9
        ],
        [
            1708956960000,
            732.85,
            733,
            732.65,
            732.75
        ],
        [
            1708957020000,
            732.7,
            733,
            732.7,
            732.7
        ],
        [
            1708957080000,
            732.65,
            733,
            732.65,
            732.85
        ],
        [
            1708957140000,
            732.8,
            732.8,
            732.5,
            732.6
        ],
        [
            1708957200000,
            732.55,
            733,
            732.5,
            733
        ],
        [
            1708957260000,
            733,
            733,
            732.65,
            733
        ],
        [
            1708957320000,
            732.65,
            733,
            732.65,
            732.7
        ],
        [
            1708957380000,
            732.95,
            733,
            732.75,
            732.9
        ],
        [
            1708957440000,
            732.85,
            733.4,
            732.7,
            733.4
        ],
        [
            1708957500000,
            733.05,
            733.7,
            733.05,
            733.3
        ],
        [
            1708957560000,
            733.6,
            733.75,
            733.05,
            733.75
        ],
        [
            1708957620000,
            733.25,
            734.75,
            733.25,
            734.4
        ],
        [
            1708957680000,
            734.35,
            734.35,
            733.35,
            733.7
        ],
        [
            1708957740000,
            733.65,
            733.95,
            733.2,
            733.85
        ],
        [
            1708957800000,
            733.8,
            734,
            733.4,
            733.9
        ],
        [
            1708957860000,
            733.85,
            734,
            733.4,
            733.8
        ],
        [
            1708957920000,
            734,
            734,
            733.1,
            733.4
        ],
        [
            1708957980000,
            733.35,
            733.35,
            732.8,
            732.8
        ],
        [
            1708958040000,
            733.05,
            733.15,
            732.65,
            732.65
        ],
        [
            1708958100000,
            733,
            733,
            732.15,
            732.35
        ],
        [
            1708958160000,
            732.3,
            733,
            732.2,
            732.55
        ],
        [
            1708958220000,
            732.6,
            733.3,
            732.55,
            732.55
        ],
        [
            1708958280000,
            732.8,
            732.9,
            732.5,
            732.5
        ],
        [
            1708958340000,
            732.5,
            732.9,
            732.5,
            732.5
        ],
        [
            1708958400000,
            732.8,
            732.9,
            732.3,
            732.55
        ],
        [
            1708958460000,
            732.8,
            732.9,
            732.5,
            732.8
        ],
        [
            1708958520000,
            732.6,
            732.65,
            732.35,
            732.5
        ],
        [
            1708958580000,
            732.5,
            732.5,
            732.2,
            732.35
        ],
        [
            1708958640000,
            732.35,
            732.55,
            732.3,
            732.55
        ],
        [
            1708958700000,
            732.8,
            733,
            732.55,
            732.55
        ],
        [
            1708958760000,
            733,
            733,
            732.6,
            732.6
        ],
        [
            1708958820000,
            732.75,
            732.75,
            732.55,
            732.65
        ],
        [
            1708958880000,
            732.3,
            732.5,
            732.3,
            732.35
        ],
        [
            1708958940000,
            732.35,
            732.75,
            732.35,
            732.35
        ],
        [
            1708959000000,
            732.55,
            732.55,
            732.05,
            732.05
        ],
        [
            1708959060000,
            732.05,
            732.25,
            732,
            732.2
        ],
        [
            1708959120000,
            732.2,
            732.25,
            732,
            732
        ],
        [
            1708959180000,
            732.15,
            732.35,
            731.95,
            732
        ],
        [
            1708959240000,
            732,
            732.15,
            731.6,
            731.6
        ],
        [
            1708959300000,
            731,
            731,
            730.85,
            730.95
        ],
        [
            1708959360000,
            731,
            731,
            730.2,
            730.2
        ],
        [
            1708959420000,
            730.4,
            730.8,
            730.4,
            730.55
        ],
        [
            1708959480000,
            730.55,
            730.85,
            730.55,
            730.65
        ],
        [
            1708959540000,
            730.95,
            730.95,
            730.3,
            730.3
        ],
        [
            1708959600000,
            730.55,
            730.6,
            730.35,
            730.4
        ],
        [
            1708959660000,
            730.55,
            730.55,
            730.1,
            730.1
        ],
        [
            1708959720000,
            730.05,
            730.15,
            729.3,
            729.3
        ],
        [
            1708959780000,
            729.1,
            729.65,
            729.05,
            729.3
        ],
        [
            1708959840000,
            729.5,
            730.65,
            729.5,
            730.5
        ],
        [
            1708959900000,
            730.65,
            730.65,
            730.1,
            730.15
        ],
        [
            1708959960000,
            730.4,
            731,
            730.25,
            730.4
        ],
        [
            1708960020000,
            730.65,
            731.05,
            730.1,
            731.05
        ],
        [
            1708960080000,
            731.05,
            731.05,
            730.55,
            730.95
        ],
        [
            1708960140000,
            730.7,
            731.05,
            730.55,
            731
        ],
        [
            1708960200000,
            730.95,
            731.05,
            730.8,
            731
        ],
        [
            1708960260000,
            730.95,
            730.95,
            730.6,
            730.85
        ],
        [
            1708960320000,
            730.9,
            730.95,
            730.8,
            730.8
        ],
        [
            1708960380000,
            730.85,
            731.45,
            730.8,
            731.1
        ],
        [
            1708960440000,
            731.35,
            731.4,
            731.1,
            731.1
        ],
        [
            1708960500000,
            730.95,
            731.15,
            730.95,
            731
        ],
        [
            1708960560000,
            731,
            731.1,
            731,
            731.05
        ],
        [
            1708960620000,
            731.05,
            731.15,
            730.9,
            731
        ],
        [
            1708960680000,
            731,
            731.05,
            731,
            731
        ],
        [
            1708960740000,
            731,
            731.15,
            731,
            731
        ],
        [
            1708960800000,
            731.05,
            731.5,
            731,
            731
        ],
        [
            1708960860000,
            731,
            731.2,
            731,
            731.1
        ],
        [
            1708960920000,
            731.1,
            731.45,
            731,
            731.1
        ],
        [
            1708960980000,
            731.1,
            731.1,
            730.8,
            731
        ],
        [
            1708961040000,
            731,
            731.05,
            731,
            731
        ],
        [
            1708961100000,
            730.95,
            731.55,
            730.9,
            730.9
        ],
        [
            1708961160000,
            730.9,
            730.9,
            730.55,
            730.65
        ],
        [
            1708961220000,
            730.65,
            730.65,
            730.3,
            730.45
        ],
        [
            1708961280000,
            729.95,
            730.4,
            728.85,
            730.25
        ],
        [
            1708961340000,
            730.25,
            730.35,
            728.7,
            729.45
        ],
        [
            1708961400000,
            729.45,
            729.45,
            729.45,
            729.45
        ],
        [
            1709025300000,
            734.35,
            734.35,
            726.5,
            726.5
        ],
        [
            1709025360000,
            727.45,
            729.7,
            727.35,
            729.65
        ],
        [
            1709025420000,
            729.85,
            729.85,
            729,
            729
        ],
        [
            1709025480000,
            728.4,
            728.4,
            722,
            724.8
        ],
        [
            1709025540000,
            724.05,
            724.4,
            721.8,
            723.6
        ],
        [
            1709025600000,
            724.85,
            726.45,
            724.85,
            725.85
        ],
        [
            1709025660000,
            726.3,
            727.9,
            726.3,
            727.6
        ],
        [
            1709025720000,
            727.6,
            727.6,
            725.85,
            727.15
        ],
        [
            1709025780000,
            727.15,
            728.25,
            727.15,
            728.15
        ],
        [
            1709025840000,
            728.05,
            728.5,
            727.9,
            728.45
        ],
        [
            1709025900000,
            728.45,
            728.5,
            728.05,
            728.05
        ],
        [
            1709025960000,
            728.3,
            730,
            728,
            729.5
        ],
        [
            1709026020000,
            729.95,
            730.4,
            729.15,
            729.3
        ],
        [
            1709026080000,
            729.5,
            729.5,
            729.05,
            729.05
        ],
        [
            1709026140000,
            729,
            729.95,
            729,
            729.9
        ],
        [
            1709026200000,
            730.15,
            730.3,
            729.5,
            729.9
        ],
        [
            1709026260000,
            729.9,
            730.15,
            729.45,
            730
        ],
        [
            1709026320000,
            730,
            730.4,
            730,
            730
        ],
        [
            1709026380000,
            730.75,
            731.3,
            730.25,
            730.65
        ],
        [
            1709026440000,
            730.75,
            731.3,
            730,
            730
        ],
        [
            1709026500000,
            730,
            731.5,
            730,
            731.35
        ],
        [
            1709026560000,
            731,
            731.3,
            730.3,
            730.3
        ],
        [
            1709026620000,
            730.05,
            731.15,
            730,
            731.1
        ],
        [
            1709026680000,
            731.45,
            731.75,
            731,
            731
        ],
        [
            1709026740000,
            731,
            731.65,
            731,
            731.65
        ],
        [
            1709026800000,
            731.85,
            731.95,
            731,
            731.3
        ],
        [
            1709026860000,
            731,
            731.95,
            731,
            731.45
        ],
        [
            1709026920000,
            731.3,
            731.95,
            731.3,
            731.95
        ],
        [
            1709026980000,
            731.4,
            732,
            731.4,
            731.45
        ],
        [
            1709027040000,
            731.45,
            732,
            731.4,
            731.95
        ],
        [
            1709027100000,
            731.4,
            733.2,
            731.4,
            732.15
        ],
        [
            1709027160000,
            732.15,
            732.45,
            731.6,
            731.65
        ],
        [
            1709027220000,
            731.65,
            731.9,
            731.6,
            731.6
        ],
        [
            1709027280000,
            731.65,
            732,
            731.65,
            731.65
        ],
        [
            1709027340000,
            732,
            732.45,
            731.65,
            732
        ],
        [
            1709027400000,
            732,
            732.7,
            731.65,
            731.65
        ],
        [
            1709027460000,
            731.7,
            732,
            731.1,
            731.6
        ],
        [
            1709027520000,
            731.15,
            731.95,
            731.15,
            731.4
        ],
        [
            1709027580000,
            731.5,
            731.9,
            731.45,
            731.5
        ],
        [
            1709027640000,
            731.5,
            731.9,
            731.5,
            731.55
        ],
        [
            1709027700000,
            731.55,
            732.15,
            731.55,
            731.8
        ],
        [
            1709027760000,
            732,
            732.15,
            731.55,
            732
        ],
        [
            1709027820000,
            732,
            733.3,
            732,
            732.6
        ],
        [
            1709027880000,
            732.85,
            733,
            731.05,
            731.05
        ],
        [
            1709027940000,
            732.3,
            732.45,
            731.7,
            732.4
        ],
        [
            1709028000000,
            731,
            731.45,
            731,
            731.05
        ],
        [
            1709028060000,
            731.05,
            731.4,
            731,
            731.35
        ],
        [
            1709028120000,
            731,
            731.95,
            731,
            731.95
        ],
        [
            1709028180000,
            731.6,
            731.95,
            731,
            731
        ],
        [
            1709028240000,
            731.4,
            731.4,
            731,
            731
        ],
        [
            1709028300000,
            731,
            731.45,
            731,
            731
        ],
        [
            1709028360000,
            731,
            731.45,
            731,
            731.05
        ],
        [
            1709028420000,
            731.4,
            731.4,
            731,
            731.05
        ],
        [
            1709028480000,
            731,
            731.35,
            731,
            731
        ],
        [
            1709028540000,
            731,
            731.15,
            731,
            731
        ],
        [
            1709028600000,
            731.2,
            731.2,
            731,
            731
        ],
        [
            1709028660000,
            731,
            731.4,
            731,
            731.35
        ],
        [
            1709028720000,
            731,
            731.35,
            731,
            731
        ],
        [
            1709028780000,
            731,
            731.3,
            730,
            731
        ],
        [
            1709028840000,
            730.45,
            730.95,
            730.45,
            730.55
        ],
        [
            1709028900000,
            730.95,
            731,
            730.35,
            731
        ],
        [
            1709028960000,
            730.85,
            731.65,
            730.85,
            731.3
        ],
        [
            1709029020000,
            731.7,
            731.95,
            731.55,
            731.55
        ],
        [
            1709029080000,
            732,
            732.95,
            732,
            732.95
        ],
        [
            1709029140000,
            732.6,
            733.4,
            732.6,
            732.75
        ],
        [
            1709029200000,
            732.75,
            733.25,
            732.7,
            732.85
        ],
        [
            1709029260000,
            732.75,
            733.35,
            732.75,
            732.9
        ],
        [
            1709029320000,
            733.1,
            733.55,
            733.1,
            733.55
        ],
        [
            1709029380000,
            733.85,
            733.85,
            733.35,
            733.45
        ],
        [
            1709029440000,
            732.75,
            732.75,
            732.65,
            732.7
        ],
        [
            1709029500000,
            732.7,
            733.45,
            732.7,
            733.05
        ],
        [
            1709029560000,
            733.05,
            733.8,
            732.85,
            732.95
        ],
        [
            1709029620000,
            732.85,
            732.85,
            732.85,
            732.85
        ],
        [
            1709029680000,
            732.85,
            733.55,
            732.85,
            733.55
        ],
        [
            1709029740000,
            733.1,
            733.85,
            733.1,
            733.2
        ],
        [
            1709029800000,
            733.25,
            733.6,
            733.2,
            733.2
        ],
        [
            1709029860000,
            733.4,
            733.6,
            731.45,
            731.45
        ],
        [
            1709029920000,
            732,
            733.7,
            732,
            733.05
        ],
        [
            1709029980000,
            733.05,
            733.2,
            733.05,
            733.05
        ],
        [
            1709030040000,
            733.05,
            733.45,
            732.65,
            732.8
        ],
        [
            1709030100000,
            732.8,
            732.8,
            732.55,
            732.65
        ],
        [
            1709030160000,
            732.8,
            733.1,
            732.65,
            732.65
        ],
        [
            1709030220000,
            732.65,
            732.65,
            732.6,
            732.6
        ],
        [
            1709030280000,
            732.6,
            732.65,
            732.6,
            732.65
        ],
        [
            1709030340000,
            732.65,
            732.7,
            732.65,
            732.7
        ],
        [
            1709030400000,
            732.65,
            732.65,
            731.8,
            731.8
        ],
        [
            1709030460000,
            732,
            732,
            731.5,
            731.65
        ],
        [
            1709030520000,
            731.5,
            733.4,
            731.5,
            732.9
        ],
        [
            1709030580000,
            733.5,
            733.5,
            733.1,
            733.1
        ],
        [
            1709030640000,
            733.8,
            733.8,
            733.2,
            733.2
        ],
        [
            1709030700000,
            733.25,
            733.6,
            733.2,
            733.4
        ],
        [
            1709030760000,
            733.85,
            733.85,
            733.4,
            733.55
        ],
        [
            1709030820000,
            733.55,
            733.95,
            732.8,
            732.85
        ],
        [
            1709030880000,
            732.85,
            733.45,
            732.85,
            733.45
        ],
        [
            1709030940000,
            733.1,
            733.5,
            732.05,
            733.5
        ],
        [
            1709031000000,
            733.4,
            733.5,
            732.9,
            732.9
        ],
        [
            1709031060000,
            733.3,
            733.3,
            732.8,
            732.95
        ],
        [
            1709031120000,
            733.45,
            733.95,
            733.3,
            733.95
        ],
        [
            1709031180000,
            733.35,
            733.95,
            733.35,
            733.95
        ],
        [
            1709031240000,
            734,
            734.25,
            733.6,
            733.8
        ],
        [
            1709031300000,
            734.25,
            734.3,
            734.25,
            734.3
        ],
        [
            1709031360000,
            734.3,
            735.9,
            734.25,
            734.8
        ],
        [
            1709031420000,
            734.8,
            734.8,
            734.45,
            734.75
        ],
        [
            1709031480000,
            734.75,
            735.7,
            734.75,
            734.8
        ],
        [
            1709031540000,
            734.8,
            735.1,
            734.75,
            735.1
        ],
        [
            1709031600000,
            735.15,
            735.45,
            734.95,
            734.95
        ],
        [
            1709031660000,
            735.15,
            735.55,
            734,
            734
        ],
        [
            1709031720000,
            734,
            734.4,
            733.85,
            734.05
        ],
        [
            1709031780000,
            734.1,
            734.45,
            734.1,
            734.1
        ],
        [
            1709031840000,
            734.85,
            736.45,
            734.6,
            736.45
        ],
        [
            1709031900000,
            736,
            736.9,
            734.45,
            735.35
        ],
        [
            1709031960000,
            735.95,
            735.95,
            734.5,
            734.75
        ],
        [
            1709032020000,
            735.2,
            735.4,
            735,
            735
        ],
        [
            1709032080000,
            735,
            736,
            735,
            736
        ],
        [
            1709032140000,
            735.05,
            735.2,
            735,
            735
        ],
        [
            1709032200000,
            735,
            735.05,
            734.3,
            734.3
        ],
        [
            1709032260000,
            734.1,
            734.25,
            734.1,
            734.1
        ],
        [
            1709032320000,
            734,
            734,
            733.8,
            734
        ],
        [
            1709032380000,
            734,
            734.15,
            734,
            734
        ],
        [
            1709032440000,
            734,
            734.15,
            734,
            734.05
        ],
        [
            1709032500000,
            734.05,
            734.05,
            734,
            734
        ],
        [
            1709032560000,
            734,
            734.4,
            734,
            734
        ],
        [
            1709032620000,
            734.45,
            735.85,
            734,
            735.85
        ],
        [
            1709032680000,
            735.2,
            735.2,
            734,
            734
        ],
        [
            1709032740000,
            733.9,
            734,
            732.7,
            732.85
        ],
        [
            1709032800000,
            733.9,
            733.9,
            732.55,
            732.55
        ],
        [
            1709032860000,
            732.3,
            732.75,
            732.15,
            732.75
        ],
        [
            1709032920000,
            732.15,
            732.35,
            732.15,
            732.15
        ],
        [
            1709032980000,
            732.5,
            732.5,
            732.45,
            732.45
        ],
        [
            1709033040000,
            732.45,
            733,
            732.45,
            732.9
        ],
        [
            1709033100000,
            732.9,
            734,
            732.9,
            733.9
        ],
        [
            1709033160000,
            733.8,
            734.65,
            733.8,
            734
        ],
        [
            1709033220000,
            734,
            734,
            732.2,
            732.2
        ],
        [
            1709033280000,
            732.05,
            732.55,
            732.05,
            732.1
        ],
        [
            1709033340000,
            732.1,
            732.45,
            732.1,
            732.45
        ],
        [
            1709033400000,
            732.5,
            732.55,
            732.25,
            732.25
        ],
        [
            1709033460000,
            732.05,
            732.05,
            731.05,
            731.05
        ],
        [
            1709033520000,
            731.05,
            731.55,
            731.05,
            731.2
        ],
        [
            1709033580000,
            731.2,
            731.65,
            731.15,
            731.25
        ],
        [
            1709033640000,
            731.2,
            731.2,
            731.15,
            731.15
        ],
        [
            1709033700000,
            731.5,
            731.5,
            731.05,
            731.5
        ],
        [
            1709033760000,
            731.5,
            731.5,
            730.8,
            731
        ],
        [
            1709033820000,
            731,
            731.5,
            730.5,
            731.2
        ],
        [
            1709033880000,
            731.2,
            732,
            731.2,
            732
        ],
        [
            1709033940000,
            732,
            732.45,
            732,
            732
        ],
        [
            1709034000000,
            732.05,
            732.2,
            732.05,
            732.05
        ],
        [
            1709034060000,
            732.2,
            732.25,
            732,
            732
        ],
        [
            1709034120000,
            732,
            732,
            731.2,
            731.35
        ],
        [
            1709034180000,
            731.35,
            731.85,
            731.35,
            731.5
        ],
        [
            1709034240000,
            731.95,
            732.45,
            731.6,
            731.8
        ],
        [
            1709034300000,
            731.8,
            731.8,
            731.05,
            731.05
        ],
        [
            1709034360000,
            731.05,
            731.55,
            730.95,
            731
        ],
        [
            1709034420000,
            731,
            731.5,
            731,
            731
        ],
        [
            1709034480000,
            731.2,
            731.2,
            731,
            731
        ],
        [
            1709034540000,
            731,
            731,
            731,
            731
        ],
        [
            1709034600000,
            731.5,
            731.5,
            731,
            731.1
        ],
        [
            1709034660000,
            731.05,
            731.45,
            731.05,
            731.45
        ],
        [
            1709034720000,
            731.45,
            732,
            731.05,
            731.95
        ],
        [
            1709034780000,
            731.7,
            732.3,
            731.7,
            732.15
        ],
        [
            1709034840000,
            731.7,
            732.3,
            731.7,
            732.3
        ],
        [
            1709034900000,
            731.9,
            732.45,
            731.8,
            731.8
        ],
        [
            1709034960000,
            732.25,
            732.25,
            731.7,
            731.85
        ],
        [
            1709035020000,
            731.7,
            731.85,
            731.7,
            731.85
        ],
        [
            1709035080000,
            731.85,
            732.1,
            731.7,
            732.05
        ],
        [
            1709035140000,
            732.1,
            732.15,
            731.6,
            732.05
        ],
        [
            1709035200000,
            732,
            732,
            731.55,
            732
        ],
        [
            1709035260000,
            731.6,
            732.3,
            731.6,
            732.3
        ],
        [
            1709035320000,
            731.7,
            732.3,
            731.7,
            732.25
        ],
        [
            1709035380000,
            732.25,
            732.25,
            731.75,
            732.25
        ],
        [
            1709035440000,
            731.75,
            732.25,
            731.75,
            732.15
        ],
        [
            1709035500000,
            731.7,
            732.15,
            731.6,
            731.6
        ],
        [
            1709035560000,
            732,
            732,
            731.6,
            732
        ],
        [
            1709035620000,
            731.4,
            731.95,
            731.4,
            731.95
        ],
        [
            1709035680000,
            731.45,
            731.95,
            731.4,
            731.4
        ],
        [
            1709035740000,
            731.9,
            732,
            731.4,
            732
        ],
        [
            1709035800000,
            732.05,
            732.05,
            731.4,
            731.85
        ],
        [
            1709035860000,
            731.9,
            731.9,
            731.3,
            731.7
        ],
        [
            1709035920000,
            731.7,
            731.7,
            731.35,
            731.7
        ],
        [
            1709035980000,
            731.7,
            731.7,
            731.35,
            731.7
        ],
        [
            1709036040000,
            731.7,
            732,
            731.35,
            732
        ],
        [
            1709036100000,
            732,
            733.35,
            732,
            733
        ],
        [
            1709036160000,
            733.25,
            733.25,
            733.05,
            733.25
        ],
        [
            1709036220000,
            733.25,
            733.25,
            733,
            733.1
        ],
        [
            1709036280000,
            733.25,
            733.25,
            733,
            733
        ],
        [
            1709036340000,
            733.25,
            734,
            733.1,
            733.1
        ],
        [
            1709036400000,
            733.35,
            733.4,
            733.1,
            733.2
        ],
        [
            1709036460000,
            733.1,
            733.65,
            733.1,
            733.65
        ],
        [
            1709036520000,
            733.65,
            734.05,
            733.4,
            734
        ],
        [
            1709036580000,
            734.05,
            734.05,
            733.8,
            734.05
        ],
        [
            1709036640000,
            734.05,
            734.4,
            733.85,
            734.4
        ],
        [
            1709036700000,
            734.4,
            734.5,
            734.05,
            734.5
        ],
        [
            1709036760000,
            734.5,
            734.95,
            734.35,
            734.95
        ],
        [
            1709036820000,
            734.9,
            734.9,
            734.3,
            734.3
        ],
        [
            1709036880000,
            734.2,
            734.35,
            733.8,
            734.1
        ],
        [
            1709036940000,
            733.85,
            734.1,
            733.7,
            734.1
        ],
        [
            1709037000000,
            734.1,
            734.1,
            733.6,
            734.1
        ],
        [
            1709037060000,
            734.1,
            734.1,
            733.65,
            734.1
        ],
        [
            1709037120000,
            734.1,
            734.1,
            733.6,
            733.85
        ],
        [
            1709037180000,
            733.7,
            734.1,
            733.55,
            733.6
        ],
        [
            1709037240000,
            734.1,
            734.1,
            733.55,
            734.05
        ],
        [
            1709037300000,
            734.05,
            734.05,
            733.55,
            734
        ],
        [
            1709037360000,
            734,
            734,
            733.55,
            733.55
        ],
        [
            1709037420000,
            734,
            734,
            733.6,
            733.6
        ],
        [
            1709037480000,
            734,
            734.35,
            733.55,
            734.25
        ],
        [
            1709037540000,
            734,
            734.25,
            733.7,
            733.7
        ],
        [
            1709037600000,
            734,
            734,
            733.5,
            733.5
        ],
        [
            1709037660000,
            734,
            735.7,
            733.7,
            734.05
        ],
        [
            1709037720000,
            734.05,
            735,
            733.6,
            734
        ],
        [
            1709037780000,
            733.75,
            735.5,
            733.75,
            735.25
        ],
        [
            1709037840000,
            735.5,
            735.5,
            735.15,
            735.5
        ],
        [
            1709037900000,
            735.5,
            736,
            735.3,
            735.9
        ],
        [
            1709037960000,
            735.55,
            735.55,
            735,
            735.05
        ],
        [
            1709038020000,
            735,
            735.05,
            735,
            735
        ],
        [
            1709038080000,
            735,
            735.05,
            734.15,
            734.65
        ],
        [
            1709038140000,
            734.4,
            734.4,
            734,
            734.15
        ],
        [
            1709038200000,
            734,
            734.4,
            734,
            734.4
        ],
        [
            1709038260000,
            734,
            734.4,
            733.65,
            733.65
        ],
        [
            1709038320000,
            734,
            734,
            733.6,
            734
        ],
        [
            1709038380000,
            733.6,
            734,
            733.3,
            733.35
        ],
        [
            1709038440000,
            732.85,
            733.35,
            732.75,
            733
        ],
        [
            1709038500000,
            733.1,
            733.1,
            732.65,
            732.65
        ],
        [
            1709038560000,
            733,
            733.1,
            732.5,
            732.75
        ],
        [
            1709038620000,
            732.45,
            732.75,
            732.25,
            732.25
        ],
        [
            1709038680000,
            732.75,
            733.9,
            732.25,
            733.8
        ],
        [
            1709038740000,
            733.8,
            733.8,
            733.2,
            733.2
        ],
        [
            1709038800000,
            733.7,
            733.7,
            732.9,
            733.1
        ],
        [
            1709038860000,
            733.45,
            733.45,
            733.1,
            733.45
        ],
        [
            1709038920000,
            733.45,
            733.45,
            733,
            733.45
        ],
        [
            1709038980000,
            733.85,
            733.85,
            733.1,
            733.85
        ],
        [
            1709039040000,
            733.3,
            733.95,
            733.3,
            733.5
        ],
        [
            1709039100000,
            733.95,
            733.95,
            732.2,
            732.2
        ],
        [
            1709039160000,
            732.25,
            733.95,
            732,
            733.5
        ],
        [
            1709039220000,
            733.25,
            733.5,
            732.65,
            733.3
        ],
        [
            1709039280000,
            733.35,
            733.4,
            731.4,
            732.05
        ],
        [
            1709039340000,
            732.05,
            732.1,
            731.4,
            732.05
        ],
        [
            1709039400000,
            731.5,
            732.35,
            731.5,
            732.35
        ],
        [
            1709039460000,
            732.1,
            732.4,
            732,
            732
        ],
        [
            1709039520000,
            732.4,
            732.4,
            732,
            732.35
        ],
        [
            1709039580000,
            732.1,
            732.1,
            731.6,
            731.6
        ],
        [
            1709039640000,
            732.1,
            732.1,
            731.6,
            731.7
        ],
        [
            1709039700000,
            732,
            732,
            731.3,
            731.6
        ],
        [
            1709039760000,
            731.6,
            731.75,
            731.25,
            731.3
        ],
        [
            1709039820000,
            731.3,
            731.75,
            731.3,
            731.75
        ],
        [
            1709039880000,
            731.3,
            731.7,
            731.1,
            731.15
        ],
        [
            1709039940000,
            731.15,
            731.65,
            731.15,
            731.2
        ],
        [
            1709040000000,
            731.65,
            731.7,
            731.15,
            731.2
        ],
        [
            1709040060000,
            731,
            731.1,
            731,
            731.05
        ],
        [
            1709040120000,
            731,
            731.4,
            731,
            731
        ],
        [
            1709040180000,
            731,
            731.2,
            731,
            731
        ],
        [
            1709040240000,
            731.2,
            731.2,
            731,
            731
        ],
        [
            1709040300000,
            731,
            731.3,
            731,
            731.2
        ],
        [
            1709040360000,
            731.15,
            731.2,
            731,
            731.05
        ],
        [
            1709040420000,
            731.05,
            731.2,
            731,
            731.05
        ],
        [
            1709040480000,
            731.05,
            731.35,
            731,
            731.3
        ],
        [
            1709040540000,
            731,
            731.35,
            731,
            731
        ],
        [
            1709040600000,
            731.1,
            731.2,
            731,
            731
        ],
        [
            1709040660000,
            731,
            731.2,
            730.1,
            730.1
        ],
        [
            1709040720000,
            730.1,
            730.95,
            729.9,
            730.25
        ],
        [
            1709040780000,
            730.7,
            730.7,
            730,
            730.3
        ],
        [
            1709040840000,
            730,
            730.3,
            730,
            730.3
        ],
        [
            1709040900000,
            730.25,
            730.3,
            730.2,
            730.3
        ],
        [
            1709040960000,
            730.25,
            730.3,
            730.2,
            730.2
        ],
        [
            1709041020000,
            730.3,
            730.3,
            730.2,
            730.25
        ],
        [
            1709041080000,
            730.2,
            730.3,
            730.2,
            730.3
        ],
        [
            1709041140000,
            730.3,
            730.45,
            729.95,
            730.3
        ],
        [
            1709041200000,
            730.25,
            730.25,
            730,
            730
        ],
        [
            1709041260000,
            730.05,
            730.2,
            730,
            730
        ],
        [
            1709041320000,
            730.05,
            730.2,
            729.95,
            730.15
        ],
        [
            1709041380000,
            730,
            730.2,
            730,
            730.15
        ],
        [
            1709041440000,
            730.15,
            730.15,
            728.4,
            728.45
        ],
        [
            1709041500000,
            728.5,
            728.9,
            728.4,
            728.85
        ],
        [
            1709041560000,
            728.85,
            728.9,
            728.6,
            728.9
        ],
        [
            1709041620000,
            728.9,
            729,
            728.35,
            728.95
        ],
        [
            1709041680000,
            728.8,
            728.8,
            727.15,
            727.6
        ],
        [
            1709041740000,
            727.6,
            728.25,
            727.55,
            728.15
        ],
        [
            1709041800000,
            728.2,
            728.3,
            727.7,
            728.3
        ],
        [
            1709041860000,
            728.3,
            729.1,
            728.3,
            729.1
        ],
        [
            1709041920000,
            729.1,
            729.45,
            728.65,
            729.45
        ],
        [
            1709041980000,
            729.65,
            729.65,
            728.1,
            728.1
        ],
        [
            1709042040000,
            728.6,
            729.2,
            728.45,
            728.9
        ],
        [
            1709042100000,
            728.9,
            728.9,
            728.05,
            728.5
        ],
        [
            1709042160000,
            728.5,
            728.5,
            728,
            728.45
        ],
        [
            1709042220000,
            728.1,
            728.5,
            728,
            728.35
        ],
        [
            1709042280000,
            728.5,
            728.8,
            728.25,
            728.45
        ],
        [
            1709042340000,
            728,
            728.9,
            727.85,
            728.85
        ],
        [
            1709042400000,
            728.85,
            728.85,
            727.7,
            728.45
        ],
        [
            1709042460000,
            728,
            728.75,
            728,
            728.7
        ],
        [
            1709042520000,
            728.65,
            728.65,
            727.55,
            728.05
        ],
        [
            1709042580000,
            728.05,
            728.05,
            726.75,
            726.75
        ],
        [
            1709042640000,
            727.25,
            728.45,
            726.95,
            728
        ],
        [
            1709042700000,
            728,
            728,
            727.35,
            727.7
        ],
        [
            1709042760000,
            727.7,
            727.7,
            726.9,
            727.45
        ],
        [
            1709042820000,
            727,
            727.4,
            726.85,
            727.3
        ],
        [
            1709042880000,
            727.3,
            727.5,
            726.95,
            726.95
        ],
        [
            1709042940000,
            726.45,
            726.95,
            726.45,
            726.95
        ],
        [
            1709043000000,
            726.95,
            727.35,
            726.45,
            727
        ],
        [
            1709043060000,
            727.55,
            727.55,
            727.05,
            727.55
        ],
        [
            1709043120000,
            727,
            727.1,
            726.65,
            727.1
        ],
        [
            1709043180000,
            727.1,
            727.4,
            726.7,
            727.35
        ],
        [
            1709043240000,
            727.35,
            727.35,
            726.3,
            726.3
        ],
        [
            1709043300000,
            726.15,
            726.75,
            725.85,
            726.7
        ],
        [
            1709043360000,
            726.7,
            727.05,
            726.05,
            726.55
        ],
        [
            1709043420000,
            726.05,
            727.55,
            726.05,
            727.55
        ],
        [
            1709043480000,
            727.55,
            727.7,
            726.35,
            727.05
        ],
        [
            1709043540000,
            727.05,
            727.05,
            726.2,
            726.75
        ],
        [
            1709043600000,
            726.4,
            727.5,
            726.15,
            727.5
        ],
        [
            1709043660000,
            727,
            727.65,
            726.55,
            727.05
        ],
        [
            1709043720000,
            726.55,
            727.05,
            726.5,
            726.85
        ],
        [
            1709043780000,
            726.85,
            726.85,
            726.25,
            726.85
        ],
        [
            1709043840000,
            726.85,
            727.6,
            726.55,
            727.1
        ],
        [
            1709043900000,
            727.6,
            727.6,
            727,
            727.1
        ],
        [
            1709043960000,
            726.45,
            727.1,
            726.45,
            727
        ],
        [
            1709044020000,
            726.65,
            727.2,
            726.55,
            727.1
        ],
        [
            1709044080000,
            727.1,
            727.95,
            726.8,
            726.8
        ],
        [
            1709044140000,
            726.8,
            727.1,
            725.5,
            726.3
        ],
        [
            1709044200000,
            726.3,
            727.65,
            725.45,
            726.8
        ],
        [
            1709044260000,
            726.8,
            727.8,
            726.45,
            727.2
        ],
        [
            1709044320000,
            727.2,
            727.6,
            727.2,
            727.2
        ],
        [
            1709044380000,
            727.2,
            728.65,
            727.05,
            728.1
        ],
        [
            1709044440000,
            728.1,
            728.8,
            727.7,
            728.8
        ],
        [
            1709044500000,
            728.8,
            730.7,
            728.5,
            730.15
        ],
        [
            1709044560000,
            730.8,
            730.8,
            728.7,
            728.7
        ],
        [
            1709044620000,
            728.75,
            728.8,
            725.55,
            725.9
        ],
        [
            1709044680000,
            725.8,
            727.5,
            725.55,
            727.5
        ],
        [
            1709044740000,
            727.15,
            727.5,
            727.1,
            727.5
        ],
        [
            1709044800000,
            727.5,
            728.15,
            727.15,
            728
        ],
        [
            1709044860000,
            728,
            728.25,
            727.65,
            728.25
        ],
        [
            1709044920000,
            727.95,
            728,
            727.55,
            727.85
        ],
        [
            1709044980000,
            727.35,
            727.9,
            726.75,
            726.95
        ],
        [
            1709045040000,
            726.9,
            726.9,
            726.1,
            726.4
        ],
        [
            1709045100000,
            726.15,
            726.55,
            726,
            726.35
        ],
        [
            1709045160000,
            726.4,
            727.1,
            726.35,
            726.75
        ],
        [
            1709045220000,
            726.95,
            727,
            726.4,
            726.55
        ],
        [
            1709045280000,
            726.65,
            726.75,
            726.05,
            726.3
        ],
        [
            1709045340000,
            726.3,
            726.35,
            725.85,
            726.3
        ],
        [
            1709045400000,
            725.85,
            726.25,
            725.7,
            726.15
        ],
        [
            1709045460000,
            725.85,
            726.3,
            725.6,
            726.3
        ],
        [
            1709045520000,
            725.85,
            726.25,
            725.75,
            726.1
        ],
        [
            1709045580000,
            725.7,
            726.15,
            725.7,
            726.05
        ],
        [
            1709045640000,
            726,
            726.1,
            725.45,
            725.95
        ],
        [
            1709045700000,
            725.55,
            725.9,
            725.45,
            725.85
        ],
        [
            1709045760000,
            725.5,
            725.95,
            725.45,
            725.5
        ],
        [
            1709045820000,
            725.95,
            726.1,
            725.3,
            726.1
        ],
        [
            1709045880000,
            726.1,
            726.85,
            725.85,
            726.45
        ],
        [
            1709045940000,
            726.1,
            726.45,
            725.35,
            725.85
        ],
        [
            1709046000000,
            725.85,
            726.85,
            725.3,
            725.5
        ],
        [
            1709046060000,
            725.85,
            725.85,
            725.4,
            725.8
        ],
        [
            1709046120000,
            725.8,
            726.05,
            725.6,
            726.05
        ],
        [
            1709046180000,
            725.95,
            726.2,
            725.65,
            726.2
        ],
        [
            1709046240000,
            726.7,
            727.4,
            726.3,
            727.4
        ],
        [
            1709046300000,
            727.7,
            727.95,
            727.35,
            727.6
        ],
        [
            1709046360000,
            727.35,
            727.85,
            727,
            727.5
        ],
        [
            1709046420000,
            727.1,
            728.2,
            727.1,
            727.65
        ],
        [
            1709046480000,
            727.5,
            727.6,
            727.1,
            727.6
        ],
        [
            1709046540000,
            727.65,
            728.4,
            727.15,
            727.65
        ],
        [
            1709046600000,
            727.65,
            727.8,
            727.4,
            727.45
        ],
        [
            1709046660000,
            727.8,
            728.1,
            727.7,
            728.1
        ],
        [
            1709046720000,
            728.1,
            728.15,
            727.5,
            727.6
        ],
        [
            1709046780000,
            727.5,
            728.4,
            727.45,
            728.4
        ],
        [
            1709046840000,
            728.1,
            728.45,
            727.05,
            727.35
        ],
        [
            1709046900000,
            727.35,
            727.75,
            726.45,
            727.25
        ],
        [
            1709046960000,
            727.25,
            727.35,
            727.1,
            727.35
        ],
        [
            1709047020000,
            727.35,
            727.35,
            727,
            727.15
        ],
        [
            1709047080000,
            726.8,
            727.05,
            726.45,
            726.9
        ],
        [
            1709047140000,
            726.85,
            727,
            726.8,
            726.95
        ],
        [
            1709047200000,
            726.95,
            726.95,
            726.8,
            726.8
        ],
        [
            1709047260000,
            726.75,
            726.8,
            726.7,
            726.8
        ],
        [
            1709047320000,
            726.8,
            727.35,
            726.6,
            727.35
        ],
        [
            1709047380000,
            726.95,
            727.8,
            726.9,
            727.8
        ],
        [
            1709047440000,
            727.8,
            729.85,
            727.6,
            728.15
        ],
        [
            1709047500000,
            728.15,
            728.6,
            728.15,
            728.2
        ],
        [
            1709047560000,
            728.55,
            728.55,
            727.8,
            727.95
        ],
        [
            1709047620000,
            727.95,
            729,
            727.8,
            729
        ],
        [
            1709047680000,
            728.7,
            728.7,
            726.5,
            726.5
        ],
        [
            1709047740000,
            726.1,
            727.35,
            726.1,
            726.1
        ],
        [
            1709111700000,
            730.85,
            731,
            724.5,
            724.5
        ],
        [
            1709111760000,
            727.2,
            727.75,
            725.45,
            725.45
        ],
        [
            1709111820000,
            725.45,
            725.7,
            725.2,
            725.25
        ],
        [
            1709111880000,
            725.35,
            727.55,
            725.35,
            727.55
        ],
        [
            1709111940000,
            727.55,
            727.7,
            726.95,
            727.2
        ],
        [
            1709112000000,
            726.55,
            727.6,
            726.3,
            727.6
        ],
        [
            1709112060000,
            727.55,
            728.65,
            727.55,
            728.65
        ],
        [
            1709112120000,
            729,
            729.2,
            728.45,
            729.05
        ],
        [
            1709112180000,
            729.5,
            729.5,
            728.8,
            728.8
        ],
        [
            1709112240000,
            729.3,
            729.3,
            728.5,
            728.6
        ],
        [
            1709112300000,
            729,
            729,
            727.9,
            727.9
        ],
        [
            1709112360000,
            727.9,
            728,
            727.3,
            727.3
        ],
        [
            1709112420000,
            727.1,
            727.55,
            727.1,
            727.55
        ],
        [
            1709112480000,
            727.15,
            727.15,
            725.85,
            725.85
        ],
        [
            1709112540000,
            725.85,
            725.85,
            724.2,
            724.5
        ],
        [
            1709112600000,
            724.2,
            724.45,
            724.2,
            724.3
        ],
        [
            1709112660000,
            724.3,
            724.3,
            724.3,
            724.3
        ],
        [
            1709112720000,
            724.4,
            726.05,
            724.4,
            724.45
        ],
        [
            1709112780000,
            724.4,
            724.9,
            724.4,
            724.5
        ],
        [
            1709112840000,
            724.55,
            724.9,
            724.55,
            724.65
        ],
        [
            1709112900000,
            726.1,
            726.7,
            725.05,
            725.1
        ],
        [
            1709112960000,
            725.45,
            725.45,
            725.05,
            725.45
        ],
        [
            1709113020000,
            725.45,
            726.15,
            725.05,
            725.95
        ],
        [
            1709113080000,
            726,
            726.2,
            725.9,
            726.2
        ],
        [
            1709113140000,
            725.9,
            726.1,
            725.25,
            725.25
        ],
        [
            1709113200000,
            725.5,
            726.4,
            725.5,
            726
        ],
        [
            1709113260000,
            725.6,
            726.55,
            725.55,
            725.85
        ],
        [
            1709113320000,
            725.8,
            726.2,
            725.8,
            726.05
        ],
        [
            1709113380000,
            726,
            726.95,
            726,
            726.85
        ],
        [
            1709113440000,
            726.85,
            726.85,
            726.15,
            726.15
        ],
        [
            1709113500000,
            726.15,
            726.15,
            725.85,
            725.85
        ],
        [
            1709113560000,
            725.65,
            725.65,
            725.55,
            725.55
        ],
        [
            1709113620000,
            725.6,
            725.6,
            725.25,
            725.3
        ],
        [
            1709113680000,
            725.3,
            725.65,
            725.2,
            725.3
        ],
        [
            1709113740000,
            725.6,
            725.95,
            725.3,
            725.3
        ],
        [
            1709113800000,
            725.3,
            725.3,
            725.2,
            725.2
        ],
        [
            1709113860000,
            725.55,
            726.85,
            725.3,
            726.15
        ],
        [
            1709113920000,
            726.5,
            726.5,
            726.2,
            726.2
        ],
        [
            1709113980000,
            726.3,
            726.5,
            725.55,
            725.8
        ],
        [
            1709114040000,
            725.55,
            725.55,
            725,
            725.05
        ],
        [
            1709114100000,
            725.3,
            725.55,
            725.1,
            725.1
        ],
        [
            1709114160000,
            725,
            725.3,
            724.5,
            724.5
        ],
        [
            1709114220000,
            724.5,
            724.5,
            724.2,
            724.2
        ],
        [
            1709114280000,
            724.1,
            725.05,
            724,
            725.05
        ],
        [
            1709114340000,
            725.05,
            725.05,
            724.6,
            725.05
        ],
        [
            1709114400000,
            725.35,
            725.35,
            724.3,
            724.9
        ],
        [
            1709114460000,
            724.95,
            725,
            724.4,
            724.9
        ],
        [
            1709114520000,
            725,
            725.35,
            724.9,
            724.9
        ],
        [
            1709114580000,
            724.85,
            725,
            724.85,
            725
        ],
        [
            1709114640000,
            725,
            725.35,
            724.85,
            725
        ],
        [
            1709114700000,
            725,
            725,
            724.85,
            724.85
        ],
        [
            1709114760000,
            725,
            725.45,
            724.75,
            725
        ],
        [
            1709114820000,
            725,
            725.05,
            724.8,
            725
        ],
        [
            1709114880000,
            725,
            725.2,
            724.8,
            725.2
        ],
        [
            1709114940000,
            725.05,
            725.2,
            725,
            725.2
        ],
        [
            1709115000000,
            725.3,
            725.5,
            724.65,
            724.65
        ],
        [
            1709115060000,
            724.65,
            724.95,
            724.2,
            724.5
        ],
        [
            1709115120000,
            724.5,
            724.7,
            724.5,
            724.5
        ],
        [
            1709115180000,
            724.5,
            724.7,
            724.5,
            724.5
        ],
        [
            1709115240000,
            724.5,
            724.9,
            724.5,
            724.5
        ],
        [
            1709115300000,
            724.5,
            724.8,
            724.4,
            724.8
        ],
        [
            1709115360000,
            724.8,
            724.8,
            724.65,
            724.65
        ],
        [
            1709115420000,
            724.2,
            724.2,
            724.15,
            724.15
        ],
        [
            1709115480000,
            724.15,
            724.2,
            724,
            724.2
        ],
        [
            1709115540000,
            724,
            724.6,
            724,
            724.2
        ],
        [
            1709115600000,
            724.2,
            724.35,
            724.2,
            724.35
        ],
        [
            1709115660000,
            724.8,
            724.8,
            724.35,
            724.7
        ],
        [
            1709115720000,
            724.8,
            725.5,
            724.75,
            725.5
        ],
        [
            1709115780000,
            724.75,
            724.75,
            724.4,
            724.4
        ],
        [
            1709115840000,
            724.4,
            724.5,
            723.05,
            723.55
        ],
        [
            1709115900000,
            723.05,
            723.2,
            723,
            723.2
        ],
        [
            1709115960000,
            723.2,
            723.2,
            722.8,
            722.9
        ],
        [
            1709116020000,
            723.35,
            723.35,
            722.9,
            723.1
        ],
        [
            1709116080000,
            723.5,
            723.5,
            723.2,
            723.25
        ],
        [
            1709116140000,
            723.3,
            723.3,
            723.2,
            723.3
        ],
        [
            1709116200000,
            723.2,
            723.2,
            721.3,
            721.5
        ],
        [
            1709116260000,
            722.25,
            722.75,
            721.6,
            722.2
        ],
        [
            1709116320000,
            722.05,
            722.6,
            722.05,
            722.15
        ],
        [
            1709116380000,
            722.5,
            722.6,
            721.55,
            721.55
        ],
        [
            1709116440000,
            721.7,
            722.45,
            721.2,
            722.3
        ],
        [
            1709116500000,
            722.35,
            722.6,
            722.3,
            722.6
        ],
        [
            1709116560000,
            722.6,
            723.5,
            722.05,
            723.5
        ],
        [
            1709116620000,
            722.95,
            723.1,
            721.55,
            721.55
        ],
        [
            1709116680000,
            721.55,
            722.55,
            721.55,
            722.55
        ],
        [
            1709116740000,
            722.55,
            722.55,
            722.1,
            722.15
        ],
        [
            1709116800000,
            722.3,
            722.3,
            721.9,
            721.9
        ],
        [
            1709116860000,
            721.85,
            721.95,
            721.35,
            721.35
        ],
        [
            1709116920000,
            721.25,
            721.75,
            721.15,
            721.4
        ],
        [
            1709116980000,
            721.35,
            722.4,
            721.35,
            722
        ],
        [
            1709117040000,
            722.15,
            722.75,
            722,
            722.75
        ],
        [
            1709117100000,
            722.45,
            722.45,
            722.35,
            722.45
        ],
        [
            1709117160000,
            722.45,
            722.95,
            722.35,
            722.35
        ],
        [
            1709117220000,
            722.35,
            722.5,
            722.05,
            722.5
        ],
        [
            1709117280000,
            722.45,
            722.85,
            722.45,
            722.5
        ],
        [
            1709117340000,
            722.9,
            723.45,
            722.9,
            723
        ],
        [
            1709117400000,
            723.1,
            724,
            723,
            724
        ],
        [
            1709117460000,
            724,
            725.1,
            722.7,
            724.1
        ],
        [
            1709117520000,
            724,
            724.55,
            723.4,
            724.2
        ],
        [
            1709117580000,
            724.5,
            724.6,
            724.05,
            724.5
        ],
        [
            1709117640000,
            724.5,
            724.8,
            724,
            724.5
        ],
        [
            1709117700000,
            724.55,
            724.75,
            723.85,
            724.75
        ],
        [
            1709117760000,
            724.7,
            724.7,
            722.7,
            722.7
        ],
        [
            1709117820000,
            722.35,
            722.7,
            720.05,
            720.05
        ],
        [
            1709117880000,
            720,
            720,
            718.75,
            718.85
        ],
        [
            1709117940000,
            718.9,
            719.75,
            718.4,
            719.45
        ],
        [
            1709118000000,
            719.45,
            720,
            719.45,
            719.55
        ],
        [
            1709118060000,
            720,
            720,
            719.55,
            719.6
        ],
        [
            1709118120000,
            719.65,
            720.65,
            719.6,
            720.2
        ],
        [
            1709118180000,
            720.2,
            720.2,
            719.45,
            719.45
        ],
        [
            1709118240000,
            719,
            719.6,
            719,
            719.5
        ],
        [
            1709118300000,
            719.5,
            719.5,
            718,
            718
        ],
        [
            1709118360000,
            718,
            719,
            718,
            719
        ],
        [
            1709118420000,
            719,
            719.75,
            719,
            719.75
        ],
        [
            1709118480000,
            719.75,
            719.75,
            718.9,
            719.1
        ],
        [
            1709118540000,
            719.1,
            719.45,
            718.1,
            718.1
        ],
        [
            1709118600000,
            718.8,
            718.9,
            718.1,
            718.9
        ],
        [
            1709118660000,
            719.05,
            719.05,
            718.35,
            719.05
        ],
        [
            1709118720000,
            719.05,
            719.5,
            719.05,
            719.5
        ],
        [
            1709118780000,
            719.9,
            720.9,
            719.9,
            720.25
        ],
        [
            1709118840000,
            720.2,
            720.45,
            719.7,
            720.1
        ],
        [
            1709118900000,
            720,
            720.65,
            719.7,
            720
        ],
        [
            1709118960000,
            719.9,
            720,
            719.55,
            719.55
        ],
        [
            1709119020000,
            719.95,
            720,
            719.65,
            720
        ],
        [
            1709119080000,
            720,
            720,
            719.15,
            719.15
        ],
        [
            1709119140000,
            719.45,
            719.75,
            719.15,
            719.15
        ],
        [
            1709119200000,
            718.6,
            719,
            718.55,
            719
        ],
        [
            1709119260000,
            719.15,
            719.15,
            718.6,
            719.15
        ],
        [
            1709119320000,
            719.3,
            719.55,
            719.15,
            719.55
        ],
        [
            1709119380000,
            719.65,
            719.65,
            719.55,
            719.65
        ],
        [
            1709119440000,
            719.6,
            720.5,
            719.6,
            720
        ],
        [
            1709119500000,
            720,
            720.2,
            719.6,
            720
        ],
        [
            1709119560000,
            720,
            720,
            720,
            720
        ],
        [
            1709119620000,
            720,
            720.15,
            720,
            720
        ],
        [
            1709119680000,
            720,
            720.15,
            720,
            720.15
        ],
        [
            1709119740000,
            720,
            720.2,
            720,
            720
        ],
        [
            1709119800000,
            720.6,
            720.6,
            720,
            720.1
        ],
        [
            1709119860000,
            720.4,
            720.4,
            720,
            720.05
        ],
        [
            1709119920000,
            720.05,
            720.1,
            720,
            720
        ],
        [
            1709119980000,
            720.05,
            720.05,
            720,
            720
        ],
        [
            1709120040000,
            720,
            720.05,
            720,
            720
        ],
        [
            1709120100000,
            720,
            720.05,
            719.9,
            720
        ],
        [
            1709120160000,
            720,
            720.15,
            720,
            720.15
        ],
        [
            1709120220000,
            720.1,
            720.1,
            720.05,
            720.05
        ],
        [
            1709120280000,
            720.05,
            720.1,
            720.05,
            720.1
        ],
        [
            1709120340000,
            720.05,
            720.35,
            719.9,
            720.05
        ],
        [
            1709120400000,
            720.05,
            720.65,
            720,
            720
        ],
        [
            1709120460000,
            720,
            720.2,
            720,
            720
        ],
        [
            1709120520000,
            720,
            720.05,
            719.55,
            720.05
        ],
        [
            1709120580000,
            720,
            720.1,
            720,
            720
        ],
        [
            1709120640000,
            719.9,
            719.95,
            717.35,
            717.45
        ],
        [
            1709120700000,
            717.4,
            717.6,
            717.05,
            717.6
        ],
        [
            1709120760000,
            717.2,
            717.85,
            717.2,
            717.85
        ],
        [
            1709120820000,
            717.95,
            718.35,
            717.75,
            718.35
        ],
        [
            1709120880000,
            718.35,
            718.6,
            718,
            718.6
        ],
        [
            1709120940000,
            718.7,
            718.85,
            718.3,
            718.85
        ],
        [
            1709121000000,
            718.8,
            718.85,
            718.25,
            718.65
        ],
        [
            1709121060000,
            719,
            719,
            718,
            718
        ],
        [
            1709121120000,
            717.75,
            718.3,
            717.75,
            718.3
        ],
        [
            1709121180000,
            718,
            718.15,
            717.55,
            717.8
        ],
        [
            1709121240000,
            717.9,
            718,
            717.8,
            717.95
        ],
        [
            1709121300000,
            718,
            718.4,
            717.9,
            717.9
        ],
        [
            1709121360000,
            718,
            718.4,
            717.8,
            718.15
        ],
        [
            1709121420000,
            718.25,
            718.25,
            717.5,
            718.05
        ],
        [
            1709121480000,
            718.05,
            718.1,
            717.6,
            717.9
        ],
        [
            1709121540000,
            718.1,
            718.1,
            717.6,
            718.1
        ],
        [
            1709121600000,
            718.1,
            718.25,
            717.5,
            717.95
        ],
        [
            1709121660000,
            717.5,
            717.95,
            717,
            717
        ],
        [
            1709121720000,
            717.3,
            717.3,
            716,
            716
        ],
        [
            1709121780000,
            716.1,
            716.7,
            715.75,
            716.1
        ],
        [
            1709121840000,
            716.4,
            716.65,
            715.8,
            716
        ],
        [
            1709121900000,
            715.8,
            716.15,
            715.75,
            715.9
        ],
        [
            1709121960000,
            715.5,
            715.7,
            715,
            715.5
        ],
        [
            1709122020000,
            715.4,
            715.9,
            715.3,
            715.85
        ],
        [
            1709122080000,
            715.85,
            716.2,
            715.6,
            716.15
        ],
        [
            1709122140000,
            715.6,
            716.55,
            715.6,
            716.5
        ],
        [
            1709122200000,
            716.5,
            716.55,
            715.9,
            716.55
        ],
        [
            1709122260000,
            716,
            716.95,
            716,
            716.95
        ],
        [
            1709122320000,
            716.65,
            717.25,
            716.65,
            717.15
        ],
        [
            1709122380000,
            717.15,
            717.45,
            717.05,
            717.45
        ],
        [
            1709122440000,
            717.45,
            718.25,
            717.45,
            718.1
        ],
        [
            1709122500000,
            717.3,
            717.5,
            716.5,
            717
        ],
        [
            1709122560000,
            717,
            717,
            716.25,
            716.65
        ],
        [
            1709122620000,
            716.7,
            717.4,
            716.65,
            717.1
        ],
        [
            1709122680000,
            716.65,
            717.1,
            716.6,
            717.1
        ],
        [
            1709122740000,
            717.25,
            717.65,
            717.25,
            717.3
        ],
        [
            1709122800000,
            717.35,
            717.95,
            717.3,
            717.95
        ],
        [
            1709122860000,
            717.35,
            717.8,
            717.25,
            717.65
        ],
        [
            1709122920000,
            717.65,
            717.65,
            717.25,
            717.65
        ],
        [
            1709122980000,
            717.7,
            718,
            717.7,
            718
        ],
        [
            1709123040000,
            718.05,
            718.3,
            717.8,
            717.8
        ],
        [
            1709123100000,
            717.9,
            717.9,
            716.75,
            716.75
        ],
        [
            1709123160000,
            716.85,
            716.95,
            716.45,
            716.9
        ],
        [
            1709123220000,
            716.6,
            717.25,
            716.45,
            716.7
        ],
        [
            1709123280000,
            717.15,
            717.15,
            716.55,
            717.05
        ],
        [
            1709123340000,
            716.65,
            717.15,
            716.65,
            717.1
        ],
        [
            1709123400000,
            717.05,
            717.05,
            716.5,
            716.95
        ],
        [
            1709123460000,
            716.95,
            717,
            716.45,
            716.45
        ],
        [
            1709123520000,
            716.9,
            717.25,
            716.9,
            717.25
        ],
        [
            1709123580000,
            716.9,
            717.25,
            716.85,
            716.85
        ],
        [
            1709123640000,
            717.05,
            717.05,
            715.25,
            715.8
        ],
        [
            1709123700000,
            715.55,
            715.8,
            715.15,
            715.65
        ],
        [
            1709123760000,
            715.25,
            715.95,
            715.25,
            715.95
        ],
        [
            1709123820000,
            715.65,
            715.95,
            715.55,
            715.6
        ],
        [
            1709123880000,
            715.05,
            715.75,
            715.05,
            715.2
        ],
        [
            1709123940000,
            715.6,
            715.6,
            715.2,
            715.3
        ],
        [
            1709124000000,
            715.6,
            715.6,
            715.3,
            715.6
        ],
        [
            1709124060000,
            715.5,
            715.6,
            715.1,
            715.1
        ],
        [
            1709124120000,
            715.4,
            715.5,
            715.1,
            715.5
        ],
        [
            1709124180000,
            715.5,
            715.6,
            715,
            715
        ],
        [
            1709124240000,
            715,
            715.3,
            715,
            715
        ],
        [
            1709124300000,
            715.3,
            715.3,
            714.1,
            714.3
        ],
        [
            1709124360000,
            714.6,
            715,
            714.6,
            714.95
        ],
        [
            1709124420000,
            714.6,
            715,
            714.05,
            714.05
        ],
        [
            1709124480000,
            714.45,
            714.55,
            714,
            714
        ],
        [
            1709124540000,
            714.45,
            714.45,
            713.1,
            713.4
        ],
        [
            1709124600000,
            713.85,
            713.9,
            712.85,
            713.8
        ],
        [
            1709124660000,
            713.45,
            713.8,
            713.4,
            713.4
        ],
        [
            1709124720000,
            713.65,
            713.65,
            713.35,
            713.6
        ],
        [
            1709124780000,
            713.4,
            713.5,
            712.9,
            712.9
        ],
        [
            1709124840000,
            713.4,
            713.4,
            712.45,
            713.35
        ],
        [
            1709124900000,
            713.25,
            713.25,
            712.9,
            712.9
        ],
        [
            1709124960000,
            713.1,
            713.1,
            712.6,
            712.6
        ],
        [
            1709125020000,
            712.8,
            712.8,
            711.7,
            712.15
        ],
        [
            1709125080000,
            712,
            712.55,
            711.8,
            712.15
        ],
        [
            1709125140000,
            712,
            712.15,
            710.65,
            711.25
        ],
        [
            1709125200000,
            711.3,
            711.7,
            711.05,
            711.4
        ],
        [
            1709125260000,
            711.35,
            711.8,
            711.2,
            711.4
        ],
        [
            1709125320000,
            711.75,
            711.9,
            711.6,
            711.6
        ],
        [
            1709125380000,
            711.85,
            711.85,
            711.25,
            711.25
        ],
        [
            1709125440000,
            711.75,
            711.75,
            710.6,
            711.6
        ],
        [
            1709125500000,
            711.6,
            712.7,
            711.6,
            712.7
        ],
        [
            1709125560000,
            712.7,
            712.85,
            710.6,
            711.05
        ],
        [
            1709125620000,
            710.9,
            711.25,
            709,
            709.3
        ],
        [
            1709125680000,
            709.15,
            711.4,
            709.15,
            710.8
        ],
        [
            1709125740000,
            710,
            710.85,
            710,
            710.45
        ],
        [
            1709125800000,
            710.45,
            710.45,
            709.35,
            709.9
        ],
        [
            1709125860000,
            709.8,
            710.05,
            709.8,
            709.9
        ],
        [
            1709125920000,
            709.85,
            709.9,
            709.1,
            709.8
        ],
        [
            1709125980000,
            709.8,
            710.4,
            709.3,
            709.4
        ],
        [
            1709126040000,
            709.4,
            709.95,
            709.3,
            709.7
        ],
        [
            1709126100000,
            709.7,
            710,
            709.5,
            709.9
        ],
        [
            1709126160000,
            709.9,
            710.25,
            709.5,
            709.55
        ],
        [
            1709126220000,
            710.15,
            710.9,
            710.15,
            710.7
        ],
        [
            1709126280000,
            710.65,
            710.65,
            709.9,
            710.6
        ],
        [
            1709126340000,
            710.6,
            710.6,
            710,
            710
        ],
        [
            1709126400000,
            710.15,
            710.95,
            710.1,
            710.8
        ],
        [
            1709126460000,
            710.8,
            710.8,
            710.5,
            710.6
        ],
        [
            1709126520000,
            710.6,
            710.6,
            710.5,
            710.6
        ],
        [
            1709126580000,
            710.9,
            711.7,
            710.9,
            711.4
        ],
        [
            1709126640000,
            711.5,
            711.65,
            711,
            711.35
        ],
        [
            1709126700000,
            710.9,
            711.65,
            710.9,
            711.65
        ],
        [
            1709126760000,
            711.65,
            711.8,
            711.4,
            711.5
        ],
        [
            1709126820000,
            711.5,
            711.5,
            709.7,
            710
        ],
        [
            1709126880000,
            710,
            710,
            709.95,
            709.95
        ],
        [
            1709126940000,
            709.95,
            710,
            709.95,
            710
        ],
        [
            1709127000000,
            709.7,
            710,
            709.7,
            709.95
        ],
        [
            1709127060000,
            709.9,
            709.95,
            709.9,
            709.95
        ],
        [
            1709127120000,
            709.95,
            710.05,
            709.8,
            709.8
        ],
        [
            1709127180000,
            711.2,
            711.2,
            710.8,
            711.2
        ],
        [
            1709127240000,
            711,
            711.2,
            710.55,
            710.55
        ],
        [
            1709127300000,
            710.4,
            710.75,
            710.35,
            710.75
        ],
        [
            1709127360000,
            710.1,
            710.5,
            710.1,
            710.45
        ],
        [
            1709127420000,
            710.45,
            710.45,
            710.45,
            710.45
        ],
        [
            1709127480000,
            710.4,
            710.55,
            710.35,
            710.35
        ],
        [
            1709127540000,
            710.35,
            711.15,
            710.35,
            711.15
        ],
        [
            1709127600000,
            710.65,
            711,
            710.55,
            710.55
        ],
        [
            1709127660000,
            710.05,
            710.6,
            709.55,
            709.55
        ],
        [
            1709127720000,
            709.95,
            709.95,
            708.95,
            709.75
        ],
        [
            1709127780000,
            709.75,
            709.9,
            709.3,
            709.7
        ],
        [
            1709127840000,
            709.7,
            709.75,
            709.3,
            709.7
        ],
        [
            1709127900000,
            709.45,
            709.75,
            709.35,
            709.75
        ],
        [
            1709127960000,
            710.05,
            710.55,
            710.05,
            710.5
        ],
        [
            1709128020000,
            709.75,
            709.75,
            708.8,
            709.15
        ],
        [
            1709128080000,
            709.1,
            709.75,
            708.8,
            709.55
        ],
        [
            1709128140000,
            709.55,
            709.8,
            709.3,
            709.65
        ],
        [
            1709128200000,
            709.65,
            710,
            709.5,
            710
        ],
        [
            1709128260000,
            709.95,
            710.3,
            709.95,
            710.3
        ],
        [
            1709128320000,
            710.3,
            711,
            710.3,
            710.55
        ],
        [
            1709128380000,
            710.5,
            711.75,
            710.5,
            710.9
        ],
        [
            1709128440000,
            710.9,
            710.9,
            710.3,
            710.7
        ],
        [
            1709128500000,
            710.3,
            710.3,
            710.3,
            710.3
        ],
        [
            1709128560000,
            710.3,
            711,
            710.3,
            710.85
        ],
        [
            1709128620000,
            710.45,
            710.45,
            710.4,
            710.4
        ],
        [
            1709128680000,
            710.4,
            710.7,
            710.3,
            710.3
        ],
        [
            1709128740000,
            710.1,
            710.2,
            709.8,
            710.05
        ],
        [
            1709128800000,
            710.05,
            710.05,
            709.65,
            710
        ],
        [
            1709128860000,
            710,
            710.05,
            710,
            710.05
        ],
        [
            1709128920000,
            710.05,
            710.05,
            710.05,
            710.05
        ],
        [
            1709128980000,
            710.05,
            710.75,
            710.05,
            710.75
        ],
        [
            1709129040000,
            710.8,
            711.2,
            710.75,
            711.05
        ],
        [
            1709129100000,
            711.05,
            711.05,
            710.55,
            710.85
        ],
        [
            1709129160000,
            710.85,
            711.3,
            710.85,
            710.95
        ],
        [
            1709129220000,
            710.95,
            711.05,
            710.95,
            711.05
        ],
        [
            1709129280000,
            711.05,
            711.65,
            711.05,
            711.15
        ],
        [
            1709129340000,
            711.15,
            711.65,
            711.15,
            711.15
        ],
        [
            1709129400000,
            711.15,
            711.7,
            711.05,
            711.3
        ],
        [
            1709129460000,
            711.3,
            711.45,
            710.8,
            711.15
        ],
        [
            1709129520000,
            711.05,
            711.5,
            711.05,
            711.05
        ],
        [
            1709129580000,
            711.05,
            711.9,
            711.05,
            711.9
        ],
        [
            1709129640000,
            711.5,
            711.8,
            711.5,
            711.8
        ],
        [
            1709129700000,
            711.6,
            711.95,
            711.6,
            711.9
        ],
        [
            1709129760000,
            711.6,
            711.6,
            711.05,
            711.05
        ],
        [
            1709129820000,
            711.05,
            711.45,
            711.05,
            711.45
        ],
        [
            1709129880000,
            711.15,
            711.15,
            710.4,
            710.4
        ],
        [
            1709129940000,
            710.45,
            710.9,
            710.35,
            710.35
        ],
        [
            1709130000000,
            710.35,
            710.85,
            710.3,
            710.65
        ],
        [
            1709130060000,
            710.65,
            710.65,
            710.3,
            710.3
        ],
        [
            1709130120000,
            710.3,
            710.6,
            710.3,
            710.35
        ],
        [
            1709130180000,
            710.35,
            710.35,
            710.35,
            710.35
        ],
        [
            1709130240000,
            710.35,
            710.6,
            710.2,
            710.2
        ],
        [
            1709130300000,
            710.2,
            710.5,
            710.05,
            710.05
        ],
        [
            1709130360000,
            710.4,
            710.65,
            710.1,
            710.6
        ],
        [
            1709130420000,
            710.7,
            711,
            710.6,
            710.6
        ],
        [
            1709130480000,
            710.6,
            711.1,
            710.6,
            710.6
        ],
        [
            1709130540000,
            710.6,
            711.1,
            710,
            710.45
        ],
        [
            1709130600000,
            710.55,
            710.8,
            710.15,
            710.65
        ],
        [
            1709130660000,
            710.65,
            710.7,
            710.65,
            710.7
        ],
        [
            1709130720000,
            710.7,
            710.95,
            710,
            710
        ],
        [
            1709130780000,
            709.95,
            709.95,
            708.15,
            709.35
        ],
        [
            1709130840000,
            709.2,
            709.2,
            708.1,
            708.55
        ],
        [
            1709130900000,
            708.65,
            709.95,
            708.65,
            709.55
        ],
        [
            1709130960000,
            709.65,
            710,
            709.05,
            709.1
        ],
        [
            1709131020000,
            709,
            709.75,
            708.8,
            709
        ],
        [
            1709131080000,
            708.8,
            709.4,
            708.75,
            709.2
        ],
        [
            1709131140000,
            708.6,
            709.8,
            708.5,
            709.75
        ],
        [
            1709131200000,
            709.7,
            711,
            709.7,
            709.7
        ],
        [
            1709131260000,
            709.7,
            709.95,
            709.15,
            709.65
        ],
        [
            1709131320000,
            709.65,
            710.75,
            708.5,
            709.1
        ],
        [
            1709131380000,
            709.6,
            709.85,
            709.05,
            709.1
        ],
        [
            1709131440000,
            709.1,
            709.45,
            709.05,
            709.3
        ],
        [
            1709131500000,
            709.15,
            709.45,
            708.85,
            708.85
        ],
        [
            1709131560000,
            709,
            710.05,
            708,
            709.15
        ],
        [
            1709131620000,
            709.45,
            710.25,
            708.35,
            710
        ],
        [
            1709131680000,
            709.65,
            710.45,
            709.3,
            710.15
        ],
        [
            1709131740000,
            710.15,
            710.2,
            709.25,
            709.25
        ],
        [
            1709131800000,
            709.3,
            709.9,
            709.1,
            709.9
        ],
        [
            1709131860000,
            709.45,
            709.85,
            708.85,
            709.15
        ],
        [
            1709131920000,
            709.2,
            709.95,
            709.2,
            709.95
        ],
        [
            1709131980000,
            709.95,
            710.2,
            708.95,
            708.95
        ],
        [
            1709132040000,
            708.4,
            709.2,
            708.4,
            709.2
        ],
        [
            1709132100000,
            709.45,
            709.45,
            707.45,
            708
        ],
        [
            1709132160000,
            708.45,
            709.1,
            708,
            709.05
        ],
        [
            1709132220000,
            708.8,
            709.1,
            708.25,
            708.35
        ],
        [
            1709132280000,
            708.55,
            708.95,
            708,
            708.95
        ],
        [
            1709132340000,
            709,
            709.3,
            708.5,
            708.95
        ],
        [
            1709132400000,
            709,
            709.2,
            708.5,
            709
        ],
        [
            1709132460000,
            709,
            709.05,
            708.2,
            708.75
        ],
        [
            1709132520000,
            708.8,
            709.1,
            708.55,
            708.7
        ],
        [
            1709132580000,
            708.95,
            709,
            708.45,
            708.45
        ],
        [
            1709132640000,
            708.75,
            709.3,
            708.5,
            708.75
        ],
        [
            1709132700000,
            708.8,
            709.8,
            708.7,
            709.8
        ],
        [
            1709132760000,
            709.95,
            710.1,
            709.85,
            710
        ],
        [
            1709132820000,
            710.15,
            710.8,
            710,
            710.25
        ],
        [
            1709132880000,
            710.35,
            710.6,
            709.6,
            710.6
        ],
        [
            1709132940000,
            710.25,
            710.7,
            710.25,
            710.5
        ],
        [
            1709133000000,
            710.4,
            710.45,
            710.1,
            710.25
        ],
        [
            1709133060000,
            710.05,
            710.3,
            709.15,
            709.85
        ],
        [
            1709133120000,
            709.55,
            709.8,
            709.55,
            709.65
        ],
        [
            1709133180000,
            709.65,
            709.7,
            709.5,
            709.5
        ],
        [
            1709133240000,
            709.65,
            709.7,
            709.6,
            709.7
        ],
        [
            1709133300000,
            708.8,
            709.8,
            708.8,
            709.6
        ],
        [
            1709133360000,
            709.75,
            709.75,
            709.2,
            709.6
        ],
        [
            1709133420000,
            709.7,
            709.7,
            708.8,
            708.8
        ],
        [
            1709133480000,
            709,
            709,
            707.7,
            707.95
        ],
        [
            1709133540000,
            707.95,
            707.95,
            706.6,
            706.9
        ],
        [
            1709133600000,
            706.65,
            707,
            706.3,
            706.3
        ],
        [
            1709133660000,
            706,
            706.3,
            705.05,
            706.25
        ],
        [
            1709133720000,
            705.75,
            705.95,
            705.4,
            705.5
        ],
        [
            1709133780000,
            705.65,
            705.65,
            704.8,
            704.8
        ],
        [
            1709133840000,
            705.15,
            705.35,
            703,
            703
        ],
        [
            1709133900000,
            703.1,
            703.1,
            702.45,
            702.9
        ],
        [
            1709133960000,
            702.4,
            703,
            702.4,
            703
        ],
        [
            1709134020000,
            703.05,
            703.05,
            702.2,
            702.2
        ],
        [
            1709134080000,
            702.85,
            703,
            702,
            703
        ],
        [
            1709134140000,
            703,
            705.95,
            703,
            705.95
        ],
        [
            1709198100000,
            704.9,
            706,
            702.5,
            702.5
        ],
        [
            1709198160000,
            702.1,
            705.75,
            702.1,
            704.1
        ],
        [
            1709198220000,
            703.4,
            703.4,
            701.7,
            701.7
        ],
        [
            1709198280000,
            701,
            701,
            698.25,
            698.95
        ],
        [
            1709198340000,
            698.95,
            699.45,
            698.3,
            699.15
        ],
        [
            1709198400000,
            699,
            699.3,
            697.55,
            697.55
        ],
        [
            1709198460000,
            697.2,
            697.9,
            697.05,
            697.7
        ],
        [
            1709198520000,
            697.7,
            698.1,
            697.45,
            697.45
        ],
        [
            1709198580000,
            699.3,
            700.95,
            699.3,
            700.95
        ],
        [
            1709198640000,
            700.95,
            703,
            700.95,
            702.45
        ],
        [
            1709198700000,
            702.5,
            702.95,
            702,
            702
        ],
        [
            1709198760000,
            702.3,
            703.4,
            701.85,
            703.4
        ],
        [
            1709198820000,
            702.75,
            702.85,
            702.55,
            702.55
        ],
        [
            1709198880000,
            702.75,
            703.2,
            702.55,
            703.2
        ],
        [
            1709198940000,
            702.7,
            703,
            702.6,
            702.65
        ],
        [
            1709199000000,
            702.55,
            702.55,
            702.05,
            702.55
        ],
        [
            1709199060000,
            702.45,
            702.45,
            701.65,
            701.65
        ],
        [
            1709199120000,
            701.15,
            702,
            701.15,
            701.85
        ],
        [
            1709199180000,
            701.75,
            702.4,
            701.15,
            701.65
        ],
        [
            1709199240000,
            701.35,
            703.05,
            701.15,
            702.45
        ],
        [
            1709199300000,
            702.1,
            702.25,
            701.65,
            701.7
        ],
        [
            1709199360000,
            701.7,
            703,
            701.05,
            703
        ],
        [
            1709199420000,
            702.85,
            702.85,
            701.55,
            701.6
        ],
        [
            1709199480000,
            701.25,
            701.25,
            700.9,
            701
        ],
        [
            1709199540000,
            701,
            701.55,
            701,
            701.3
        ],
        [
            1709199600000,
            701.2,
            702.35,
            701.15,
            702.35
        ],
        [
            1709199660000,
            702.05,
            702.1,
            701.1,
            701.1
        ],
        [
            1709199720000,
            700.75,
            700.85,
            700.4,
            700.4
        ],
        [
            1709199780000,
            700.3,
            702.15,
            700.3,
            701
        ],
        [
            1709199840000,
            701.25,
            701.25,
            700.55,
            700.6
        ],
        [
            1709199900000,
            700.65,
            702.15,
            700.65,
            701.7
        ],
        [
            1709199960000,
            701.55,
            702,
            701.55,
            701.75
        ],
        [
            1709200020000,
            701.85,
            702.6,
            701.8,
            702.2
        ],
        [
            1709200080000,
            702.15,
            702.15,
            701.4,
            701.55
        ],
        [
            1709200140000,
            701.6,
            701.95,
            701.6,
            701.95
        ],
        [
            1709200200000,
            702,
            702.6,
            702,
            702.25
        ],
        [
            1709200260000,
            702.25,
            702.45,
            701.65,
            701.75
        ],
        [
            1709200320000,
            701.8,
            701.8,
            701.15,
            701.15
        ],
        [
            1709200380000,
            701.15,
            701.85,
            701.05,
            701.55
        ],
        [
            1709200440000,
            701.55,
            701.55,
            701.15,
            701.5
        ],
        [
            1709200500000,
            701.2,
            702.55,
            701.15,
            702.45
        ],
        [
            1709200560000,
            702.5,
            702.5,
            702.05,
            702.5
        ],
        [
            1709200620000,
            702.35,
            702.35,
            702.05,
            702.05
        ],
        [
            1709200680000,
            702.3,
            702.8,
            701.9,
            702
        ],
        [
            1709200740000,
            702.2,
            702.45,
            702.05,
            702.05
        ],
        [
            1709200800000,
            702.35,
            703.35,
            702.15,
            703
        ],
        [
            1709200860000,
            702.7,
            703.05,
            702.45,
            702.45
        ],
        [
            1709200920000,
            702.4,
            702.6,
            702.1,
            702.6
        ],
        [
            1709200980000,
            702.6,
            702.6,
            702.6,
            702.6
        ],
        [
            1709201040000,
            702.6,
            702.8,
            702.6,
            702.6
        ],
        [
            1709201100000,
            702.5,
            702.5,
            701.8,
            701.95
        ],
        [
            1709201160000,
            701.65,
            701.65,
            700.9,
            701.1
        ],
        [
            1709201220000,
            700.7,
            700.7,
            699.05,
            699.1
        ],
        [
            1709201280000,
            699.35,
            699.35,
            698.1,
            698.25
        ],
        [
            1709201340000,
            698.15,
            698.9,
            698.15,
            698.4
        ],
        [
            1709201400000,
            698.05,
            698.35,
            697.5,
            698.2
        ],
        [
            1709201460000,
            698.25,
            698.4,
            697.35,
            697.55
        ],
        [
            1709201520000,
            697.5,
            698.05,
            697.15,
            697.65
        ],
        [
            1709201580000,
            697.65,
            697.65,
            696.3,
            696.3
        ],
        [
            1709201640000,
            696.3,
            696.3,
            696,
            696.05
        ],
        [
            1709201700000,
            696.05,
            697,
            696.05,
            696.3
        ],
        [
            1709201760000,
            696.25,
            696.45,
            695.55,
            696.4
        ],
        [
            1709201820000,
            696.4,
            697.35,
            696.4,
            697.35
        ],
        [
            1709201880000,
            697.3,
            697.5,
            696.9,
            696.9
        ],
        [
            1709201940000,
            696.75,
            696.75,
            696.4,
            696.5
        ],
        [
            1709202000000,
            696.95,
            696.95,
            696.35,
            696.35
        ],
        [
            1709202060000,
            696.5,
            697.55,
            696.5,
            697.35
        ],
        [
            1709202120000,
            697.55,
            697.9,
            697.5,
            697.5
        ],
        [
            1709202180000,
            697.5,
            699.15,
            697.5,
            699.15
        ],
        [
            1709202240000,
            698.75,
            699.1,
            698.45,
            698.45
        ],
        [
            1709202300000,
            698.45,
            698.6,
            698.25,
            698.55
        ],
        [
            1709202360000,
            698.5,
            698.6,
            698,
            698
        ],
        [
            1709202420000,
            697.95,
            698.45,
            697.75,
            697.95
        ],
        [
            1709202480000,
            698.05,
            698.5,
            697.65,
            698.5
        ],
        [
            1709202540000,
            698.65,
            699,
            698.65,
            698.7
        ],
        [
            1709202600000,
            698.8,
            699.1,
            698.6,
            698.7
        ],
        [
            1709202660000,
            698.7,
            699.55,
            698.7,
            699
        ],
        [
            1709202720000,
            699,
            700.4,
            699,
            699.85
        ],
        [
            1709202780000,
            699.9,
            699.9,
            699.1,
            699.1
        ],
        [
            1709202840000,
            699,
            699.35,
            698.9,
            698.9
        ],
        [
            1709202900000,
            698.9,
            699.1,
            698.6,
            698.6
        ],
        [
            1709202960000,
            698.55,
            698.55,
            698.2,
            698.35
        ],
        [
            1709203020000,
            698.35,
            698.7,
            698.3,
            698.6
        ],
        [
            1709203080000,
            698.6,
            698.9,
            698.4,
            698.4
        ],
        [
            1709203140000,
            698.25,
            698.25,
            697.35,
            697.35
        ],
        [
            1709203200000,
            697.4,
            698.35,
            697.4,
            698.25
        ],
        [
            1709203260000,
            698.1,
            698.4,
            697.9,
            698.35
        ],
        [
            1709203320000,
            698.3,
            698.35,
            697.75,
            698.35
        ],
        [
            1709203380000,
            698.05,
            698.7,
            698.05,
            698.7
        ],
        [
            1709203440000,
            698.75,
            699.3,
            698.55,
            698.6
        ],
        [
            1709203500000,
            698.7,
            699.4,
            698.5,
            698.5
        ],
        [
            1709203560000,
            698.7,
            698.7,
            698.25,
            698.25
        ],
        [
            1709203620000,
            698.2,
            698.55,
            698.1,
            698.3
        ],
        [
            1709203680000,
            698.25,
            699,
            698.25,
            699
        ],
        [
            1709203740000,
            699,
            700.3,
            698.6,
            699.9
        ],
        [
            1709203800000,
            699.9,
            700.65,
            699.9,
            700.65
        ],
        [
            1709203860000,
            700.65,
            700.75,
            700,
            700
        ],
        [
            1709203920000,
            700,
            700.4,
            699.95,
            699.95
        ],
        [
            1709203980000,
            699.95,
            700.5,
            698.95,
            698.95
        ],
        [
            1709204040000,
            698.8,
            698.8,
            698.15,
            698.55
        ],
        [
            1709204100000,
            698.25,
            699.5,
            698.15,
            699.35
        ],
        [
            1709204160000,
            699.25,
            699.5,
            699.05,
            699.4
        ],
        [
            1709204220000,
            699.4,
            700.2,
            699.25,
            699.95
        ],
        [
            1709204280000,
            700.05,
            700.35,
            699.8,
            700.05
        ],
        [
            1709204340000,
            700.05,
            700.35,
            699.9,
            700.35
        ],
        [
            1709204400000,
            700.35,
            700.45,
            700.2,
            700.45
        ],
        [
            1709204460000,
            700.6,
            700.9,
            700.5,
            700.9
        ],
        [
            1709204520000,
            700.6,
            700.9,
            700.6,
            700.85
        ],
        [
            1709204580000,
            701.2,
            701.35,
            700.75,
            701.05
        ],
        [
            1709204640000,
            701.05,
            701.05,
            700.1,
            700.1
        ],
        [
            1709204700000,
            700.1,
            700.45,
            700,
            700
        ],
        [
            1709204760000,
            699.95,
            699.95,
            699.55,
            699.85
        ],
        [
            1709204820000,
            699.7,
            700.15,
            699.65,
            700
        ],
        [
            1709204880000,
            700.35,
            700.65,
            700.1,
            700.2
        ],
        [
            1709204940000,
            700.2,
            700.5,
            700.2,
            700.25
        ],
        [
            1709205000000,
            700.3,
            700.6,
            700.05,
            700.1
        ],
        [
            1709205060000,
            700.1,
            700.2,
            700.1,
            700.2
        ],
        [
            1709205120000,
            700.1,
            702.2,
            700.1,
            702.2
        ],
        [
            1709205180000,
            702.2,
            702.2,
            702,
            702
        ],
        [
            1709205240000,
            702,
            702.4,
            702,
            702.05
        ],
        [
            1709205300000,
            702.35,
            703.25,
            702.05,
            702.8
        ],
        [
            1709205360000,
            702.6,
            702.75,
            702.4,
            702.4
        ],
        [
            1709205420000,
            702.4,
            703.3,
            702.4,
            703.15
        ],
        [
            1709205480000,
            703.1,
            704.25,
            703.1,
            703.85
        ],
        [
            1709205540000,
            703.6,
            703.6,
            702,
            702
        ],
        [
            1709205600000,
            702,
            702.05,
            700,
            700
        ],
        [
            1709205660000,
            700,
            701.25,
            699.15,
            701.25
        ],
        [
            1709205720000,
            700.7,
            701.2,
            700.25,
            700.3
        ],
        [
            1709205780000,
            700.55,
            700.55,
            699.05,
            699.6
        ],
        [
            1709205840000,
            699.55,
            699.8,
            699.15,
            699.75
        ],
        [
            1709205900000,
            699.75,
            699.95,
            699.3,
            699.9
        ],
        [
            1709205960000,
            699.5,
            699.75,
            699.15,
            699.15
        ],
        [
            1709206020000,
            699.65,
            700,
            699.4,
            699.95
        ],
        [
            1709206080000,
            699.65,
            700.1,
            699.65,
            700.1
        ],
        [
            1709206140000,
            700.1,
            700.65,
            699.55,
            699.55
        ],
        [
            1709206200000,
            699.5,
            700.65,
            699.5,
            700.25
        ],
        [
            1709206260000,
            700.05,
            700.1,
            699.9,
            700.1
        ],
        [
            1709206320000,
            700,
            700.3,
            699.65,
            700
        ],
        [
            1709206380000,
            699.6,
            700,
            699.3,
            699.9
        ],
        [
            1709206440000,
            699.85,
            700,
            699.6,
            699.9
        ],
        [
            1709206500000,
            699.6,
            700.05,
            699.6,
            700.05
        ],
        [
            1709206560000,
            700.1,
            700.2,
            699.55,
            699.55
        ],
        [
            1709206620000,
            699.55,
            700,
            699.55,
            699.95
        ],
        [
            1709206680000,
            699.9,
            700.35,
            699.55,
            700.35
        ],
        [
            1709206740000,
            701.2,
            701.2,
            699.95,
            699.95
        ],
        [
            1709206800000,
            699.9,
            700,
            699.9,
            700
        ],
        [
            1709206860000,
            699.95,
            700,
            699.7,
            700
        ],
        [
            1709206920000,
            699.9,
            700.75,
            699.35,
            700.75
        ],
        [
            1709206980000,
            700.75,
            700.75,
            700.05,
            700.05
        ],
        [
            1709207040000,
            700.05,
            700.5,
            699.75,
            700
        ],
        [
            1709207100000,
            699.75,
            700.05,
            699.6,
            700.05
        ],
        [
            1709207160000,
            700,
            700.65,
            699.8,
            700.6
        ],
        [
            1709207220000,
            700.25,
            700.9,
            700.2,
            700.55
        ],
        [
            1709207280000,
            700.5,
            700.75,
            700.35,
            700.45
        ],
        [
            1709207340000,
            700.4,
            701,
            700.4,
            701
        ],
        [
            1709207400000,
            700.55,
            700.95,
            700.5,
            700.75
        ],
        [
            1709207460000,
            700.85,
            700.85,
            700.5,
            700.85
        ],
        [
            1709207520000,
            700.5,
            701.3,
            700.5,
            700.85
        ],
        [
            1709207580000,
            700.55,
            700.55,
            700.45,
            700.45
        ],
        [
            1709207640000,
            700.5,
            700.55,
            700.5,
            700.5
        ],
        [
            1709207700000,
            700.5,
            700.75,
            700.35,
            700.7
        ],
        [
            1709207760000,
            700.4,
            700.7,
            700.35,
            700.7
        ],
        [
            1709207820000,
            700.35,
            701.3,
            700.35,
            700.95
        ],
        [
            1709207880000,
            700.95,
            701.3,
            700.85,
            700.85
        ],
        [
            1709207940000,
            700.85,
            701.35,
            700.85,
            701.3
        ],
        [
            1709208000000,
            701,
            702.3,
            701,
            701.45
        ],
        [
            1709208060000,
            701.4,
            701.5,
            700.9,
            701.15
        ],
        [
            1709208120000,
            700.9,
            700.9,
            700.35,
            700.6
        ],
        [
            1709208180000,
            700.15,
            700.4,
            700.05,
            700.05
        ],
        [
            1709208240000,
            700,
            700.55,
            700,
            700.55
        ],
        [
            1709208300000,
            700.4,
            700.55,
            700.4,
            700.4
        ],
        [
            1709208360000,
            700.4,
            700.65,
            700.4,
            700.4
        ],
        [
            1709208420000,
            700.4,
            700.65,
            700.4,
            700.65
        ],
        [
            1709208480000,
            700.4,
            700.65,
            700.4,
            700.65
        ],
        [
            1709208540000,
            700.5,
            700.5,
            700,
            700
        ],
        [
            1709208600000,
            700,
            701.55,
            700,
            701.05
        ],
        [
            1709208660000,
            700.9,
            702.5,
            700.85,
            701.75
        ],
        [
            1709208720000,
            701.75,
            702,
            701.6,
            702
        ],
        [
            1709208780000,
            701.65,
            702.25,
            701.65,
            702.1
        ],
        [
            1709208840000,
            701.7,
            702.1,
            701.55,
            702
        ],
        [
            1709208900000,
            701.5,
            702.85,
            701.15,
            702.85
        ],
        [
            1709208960000,
            702.1,
            702.35,
            701.75,
            702.15
        ],
        [
            1709209020000,
            702.1,
            702.45,
            701.9,
            702.45
        ],
        [
            1709209080000,
            702.1,
            702.4,
            701.75,
            701.75
        ],
        [
            1709209140000,
            702.05,
            702.4,
            701.05,
            701.2
        ],
        [
            1709209200000,
            701.2,
            702.15,
            701.15,
            701.15
        ],
        [
            1709209260000,
            701.05,
            701.4,
            701.05,
            701.1
        ],
        [
            1709209320000,
            701.05,
            701.05,
            700.15,
            700.95
        ],
        [
            1709209380000,
            700.95,
            700.95,
            700.4,
            700.4
        ],
        [
            1709209440000,
            700.1,
            701,
            700.1,
            700.65
        ],
        [
            1709209500000,
            700.55,
            700.95,
            700.15,
            700.3
        ],
        [
            1709209560000,
            700.3,
            701.1,
            700.15,
            701.1
        ],
        [
            1709209620000,
            700.7,
            701.35,
            700.55,
            701.35
        ],
        [
            1709209680000,
            700.95,
            701.3,
            700.45,
            700.45
        ],
        [
            1709209740000,
            700.5,
            701.3,
            700.45,
            700.45
        ],
        [
            1709209800000,
            700.4,
            701.35,
            700.3,
            701.25
        ],
        [
            1709209860000,
            700.95,
            701.25,
            700.85,
            701
        ],
        [
            1709209920000,
            701,
            701.2,
            700.4,
            700.55
        ],
        [
            1709209980000,
            700.55,
            701.15,
            700.55,
            701.1
        ],
        [
            1709210040000,
            700.8,
            701.15,
            700.8,
            700.85
        ],
        [
            1709210100000,
            700.8,
            701.15,
            700.75,
            700.85
        ],
        [
            1709210160000,
            701.1,
            701.15,
            700.8,
            701.15
        ],
        [
            1709210220000,
            701.45,
            701.65,
            701.1,
            701.4
        ],
        [
            1709210280000,
            701.35,
            701.5,
            700.5,
            700.5
        ],
        [
            1709210340000,
            700.5,
            701.4,
            700.5,
            700.8
        ],
        [
            1709210400000,
            700.75,
            701.45,
            700.75,
            701.45
        ],
        [
            1709210460000,
            701.05,
            701.4,
            700.9,
            701.05
        ],
        [
            1709210520000,
            700.95,
            701.05,
            700.6,
            700.95
        ],
        [
            1709210580000,
            700.85,
            701.2,
            700.8,
            701.2
        ],
        [
            1709210640000,
            701,
            701.1,
            700.65,
            700.95
        ],
        [
            1709210700000,
            700.7,
            701,
            700.7,
            700.75
        ],
        [
            1709210760000,
            700.65,
            700.75,
            700,
            700
        ],
        [
            1709210820000,
            700,
            700.2,
            699.8,
            699.95
        ],
        [
            1709210880000,
            699.45,
            700,
            699.3,
            700
        ],
        [
            1709210940000,
            699.7,
            700,
            699.1,
            699.65
        ],
        [
            1709211000000,
            700,
            700.5,
            699.2,
            699.95
        ],
        [
            1709211060000,
            699.6,
            700,
            699.35,
            699.5
        ],
        [
            1709211120000,
            699.5,
            700.2,
            699.5,
            700.2
        ],
        [
            1709211180000,
            700,
            700.3,
            700,
            700
        ],
        [
            1709211240000,
            700,
            700.7,
            700,
            700.4
        ],
        [
            1709211300000,
            700.1,
            700.25,
            700.05,
            700.2
        ],
        [
            1709211360000,
            700.2,
            700.2,
            699.3,
            699.9
        ],
        [
            1709211420000,
            699.45,
            699.8,
            699.2,
            699.35
        ],
        [
            1709211480000,
            699.35,
            700.45,
            699.15,
            699.6
        ],
        [
            1709211540000,
            699.55,
            699.85,
            699.2,
            699.45
        ],
        [
            1709211600000,
            699.4,
            699.65,
            699,
            699.2
        ],
        [
            1709211660000,
            699,
            699.8,
            699,
            699.8
        ],
        [
            1709211720000,
            699.7,
            700,
            699.7,
            700
        ],
        [
            1709211780000,
            699.6,
            700.05,
            699.2,
            699.45
        ],
        [
            1709211840000,
            699.65,
            699.65,
            699.3,
            699.3
        ],
        [
            1709211900000,
            699.25,
            699.65,
            698.4,
            698.4
        ],
        [
            1709211960000,
            698.35,
            699.05,
            698.35,
            699.05
        ],
        [
            1709212020000,
            699.05,
            699.05,
            698.7,
            698.8
        ],
        [
            1709212080000,
            698.85,
            699.4,
            698.85,
            698.85
        ],
        [
            1709212140000,
            698.8,
            699.15,
            698.65,
            698.65
        ],
        [
            1709212200000,
            698.45,
            698.8,
            698.35,
            698.8
        ],
        [
            1709212260000,
            698.8,
            699.55,
            698.45,
            699.05
        ],
        [
            1709212320000,
            699.05,
            699.65,
            699.05,
            699.6
        ],
        [
            1709212380000,
            699.4,
            699.7,
            699.4,
            699.6
        ],
        [
            1709212440000,
            699.35,
            699.35,
            698.9,
            698.9
        ],
        [
            1709212500000,
            698.85,
            699.4,
            698.85,
            699.3
        ],
        [
            1709212560000,
            699.05,
            700.9,
            699.05,
            700.8
        ],
        [
            1709212620000,
            700.45,
            700.9,
            700.45,
            700.5
        ],
        [
            1709212680000,
            700.4,
            700.8,
            700.4,
            700.6
        ],
        [
            1709212740000,
            700.4,
            700.4,
            700,
            700.2
        ],
        [
            1709212800000,
            700.05,
            700.7,
            700.05,
            700.55
        ],
        [
            1709212860000,
            700.2,
            700.55,
            700.2,
            700.55
        ],
        [
            1709212920000,
            700.25,
            700.85,
            700.25,
            700.6
        ],
        [
            1709212980000,
            700.4,
            700.8,
            700.35,
            700.4
        ],
        [
            1709213040000,
            700.35,
            700.75,
            700.35,
            700.6
        ],
        [
            1709213100000,
            700.5,
            700.55,
            700.35,
            700.55
        ],
        [
            1709213160000,
            700.8,
            700.8,
            700.45,
            700.45
        ],
        [
            1709213220000,
            700.4,
            700.85,
            700.4,
            700.4
        ],
        [
            1709213280000,
            700.35,
            700.85,
            700.35,
            700.7
        ],
        [
            1709213340000,
            700.4,
            700.8,
            700.4,
            700.7
        ],
        [
            1709213400000,
            700.4,
            700.6,
            700.05,
            700.05
        ],
        [
            1709213460000,
            700,
            700.95,
            700,
            700.95
        ],
        [
            1709213520000,
            700.85,
            700.95,
            700.35,
            700.95
        ],
        [
            1709213580000,
            700.7,
            701.2,
            700.55,
            701.2
        ],
        [
            1709213640000,
            701.15,
            701.5,
            700.75,
            701.1
        ],
        [
            1709213700000,
            701.5,
            701.5,
            701.05,
            701.05
        ],
        [
            1709213760000,
            700.95,
            701.5,
            700.85,
            701.5
        ],
        [
            1709213820000,
            701.1,
            701.5,
            701.05,
            701.5
        ],
        [
            1709213880000,
            701.45,
            701.5,
            701,
            701
        ],
        [
            1709213940000,
            701,
            701.3,
            700.9,
            701.25
        ],
        [
            1709214000000,
            700.9,
            701.35,
            700.75,
            700.75
        ],
        [
            1709214060000,
            700.7,
            701,
            700.7,
            700.95
        ],
        [
            1709214120000,
            700.85,
            701,
            700.8,
            701
        ],
        [
            1709214180000,
            700.9,
            701,
            700.8,
            700.95
        ],
        [
            1709214240000,
            700.9,
            701.35,
            700.8,
            701.35
        ],
        [
            1709214300000,
            701.4,
            701.5,
            701,
            701
        ],
        [
            1709214360000,
            701.35,
            701.5,
            700.8,
            701.5
        ],
        [
            1709214420000,
            701.3,
            701.75,
            701.15,
            701.75
        ],
        [
            1709214480000,
            701.5,
            701.85,
            700.75,
            701.3
        ],
        [
            1709214540000,
            700.95,
            701.5,
            700.8,
            701.5
        ],
        [
            1709214600000,
            701,
            701.5,
            700.9,
            701.5
        ],
        [
            1709214660000,
            701,
            701.4,
            700.7,
            701.05
        ],
        [
            1709214720000,
            700.7,
            701.4,
            700.7,
            700.8
        ],
        [
            1709214780000,
            700.8,
            701.45,
            700.8,
            701.45
        ],
        [
            1709214840000,
            700.95,
            701.45,
            700.9,
            701.1
        ],
        [
            1709214900000,
            701.05,
            701.4,
            701.05,
            701.05
        ],
        [
            1709214960000,
            701.5,
            701.5,
            700.75,
            700.8
        ],
        [
            1709215020000,
            700.8,
            701.15,
            700.8,
            700.85
        ],
        [
            1709215080000,
            700.85,
            701.15,
            700.85,
            700.85
        ],
        [
            1709215140000,
            700.9,
            701.25,
            700.9,
            701
        ],
        [
            1709215200000,
            701,
            701.45,
            700.8,
            700.8
        ],
        [
            1709215260000,
            700.75,
            701.05,
            700.55,
            701.05
        ],
        [
            1709215320000,
            700.65,
            701.7,
            700.65,
            701.05
        ],
        [
            1709215380000,
            701.1,
            701.75,
            701.1,
            701.25
        ],
        [
            1709215440000,
            701.2,
            701.5,
            701.1,
            701.5
        ],
        [
            1709215500000,
            701.3,
            701.5,
            701.3,
            701.3
        ],
        [
            1709215560000,
            701.3,
            701.65,
            701.15,
            701.65
        ],
        [
            1709215620000,
            701.3,
            701.5,
            701.2,
            701.2
        ],
        [
            1709215680000,
            701.2,
            701.5,
            701.2,
            701.2
        ],
        [
            1709215740000,
            701.15,
            701.15,
            699.95,
            699.95
        ],
        [
            1709215800000,
            699.9,
            700.25,
            698.75,
            698.75
        ],
        [
            1709215860000,
            698.75,
            699.2,
            698.35,
            699.2
        ],
        [
            1709215920000,
            699.1,
            700.6,
            699.1,
            699.85
        ],
        [
            1709215980000,
            699.85,
            700.6,
            699.8,
            700.6
        ],
        [
            1709216040000,
            700.35,
            701.55,
            700.35,
            701.55
        ],
        [
            1709216100000,
            701.4,
            701.4,
            700.8,
            701.05
        ],
        [
            1709216160000,
            700.9,
            701.5,
            700.9,
            701.5
        ],
        [
            1709216220000,
            701.4,
            701.4,
            700,
            700
        ],
        [
            1709216280000,
            700,
            700,
            699.75,
            700
        ],
        [
            1709216340000,
            699.95,
            700,
            699.9,
            700
        ],
        [
            1709216400000,
            699.9,
            700.05,
            699.65,
            700.05
        ],
        [
            1709216460000,
            699.6,
            700,
            699.6,
            700
        ],
        [
            1709216520000,
            699.8,
            700,
            699.7,
            699.85
        ],
        [
            1709216580000,
            699.85,
            700.05,
            699.85,
            700
        ],
        [
            1709216640000,
            699.75,
            700.1,
            699.75,
            700
        ],
        [
            1709216700000,
            699.95,
            700,
            699.85,
            700
        ],
        [
            1709216760000,
            699.8,
            699.95,
            699.6,
            699.85
        ],
        [
            1709216820000,
            699.85,
            700.55,
            699.55,
            700.45
        ],
        [
            1709216880000,
            700.3,
            702,
            700.3,
            702
        ],
        [
            1709216940000,
            701.95,
            702.4,
            701.05,
            701.5
        ],
        [
            1709217000000,
            701.05,
            701.55,
            701,
            701.55
        ],
        [
            1709217060000,
            700.9,
            701.5,
            700.7,
            700.7
        ],
        [
            1709217120000,
            700.65,
            701,
            700.55,
            700.6
        ],
        [
            1709217180000,
            700.55,
            701,
            700.55,
            701
        ],
        [
            1709217240000,
            700.95,
            701,
            700,
            700
        ],
        [
            1709217300000,
            699.75,
            700.75,
            699.7,
            700
        ],
        [
            1709217360000,
            699.95,
            700,
            699.65,
            700
        ],
        [
            1709217420000,
            700,
            700.95,
            700,
            700.3
        ],
        [
            1709217480000,
            700.25,
            701.5,
            700.25,
            701.05
        ],
        [
            1709217540000,
            701.05,
            701.55,
            700.75,
            701.1
        ],
        [
            1709217600000,
            701.05,
            701.75,
            701.05,
            701.35
        ],
        [
            1709217660000,
            701.55,
            703,
            700.9,
            701.45
        ],
        [
            1709217720000,
            701.5,
            702.55,
            701.05,
            701.65
        ],
        [
            1709217780000,
            702.1,
            703.7,
            701.7,
            703.7
        ],
        [
            1709217840000,
            703.4,
            704,
            703.4,
            704
        ],
        [
            1709217900000,
            703.8,
            704.7,
            703.8,
            704.7
        ],
        [
            1709217960000,
            704.55,
            704.95,
            704.4,
            704.95
        ],
        [
            1709218020000,
            704.35,
            704.85,
            704.15,
            704.15
        ],
        [
            1709218080000,
            704.15,
            704.65,
            704.15,
            704.3
        ],
        [
            1709218140000,
            704.3,
            704.7,
            704.3,
            704.3
        ],
        [
            1709218200000,
            704.3,
            704.7,
            704.25,
            704.35
        ],
        [
            1709218260000,
            704.7,
            706.9,
            704.7,
            705.9
        ],
        [
            1709218320000,
            705.65,
            706.5,
            705.6,
            706.1
        ],
        [
            1709218380000,
            706.9,
            707.2,
            706.15,
            707.2
        ],
        [
            1709218440000,
            707,
            707.4,
            706.6,
            706.95
        ],
        [
            1709218500000,
            707.3,
            708.35,
            707.1,
            708.35
        ],
        [
            1709218560000,
            708.05,
            708.05,
            706.65,
            706.65
        ],
        [
            1709218620000,
            707.4,
            707.4,
            706.3,
            707.15
        ],
        [
            1709218680000,
            707.05,
            707.3,
            706.65,
            707.3
        ],
        [
            1709218740000,
            707,
            707.95,
            707,
            707.95
        ],
        [
            1709218800000,
            706.85,
            707.4,
            705.6,
            705.6
        ],
        [
            1709218860000,
            705.7,
            706.3,
            705.5,
            706.3
        ],
        [
            1709218920000,
            706.15,
            706.15,
            705.4,
            705.55
        ],
        [
            1709218980000,
            705.6,
            705.85,
            705.6,
            705.65
        ],
        [
            1709219040000,
            705.2,
            706.2,
            705.2,
            705.8
        ],
        [
            1709219100000,
            705.75,
            707,
            705.2,
            707
        ],
        [
            1709219160000,
            709.1,
            709.1,
            706.75,
            707.9
        ],
        [
            1709219220000,
            708.35,
            708.35,
            706.3,
            706.65
        ],
        [
            1709219280000,
            706.25,
            706.65,
            705.9,
            705.9
        ],
        [
            1709219340000,
            706.15,
            707.2,
            706.05,
            706.45
        ],
        [
            1709219400000,
            706.85,
            706.85,
            705.5,
            705.65
        ],
        [
            1709219460000,
            705.65,
            705.7,
            705.25,
            705.6
        ],
        [
            1709219520000,
            705.8,
            706.45,
            705.8,
            705.95
        ],
        [
            1709219580000,
            705.9,
            706.2,
            705.25,
            706.2
        ],
        [
            1709219640000,
            705.85,
            707,
            705.85,
            706.6
        ],
        [
            1709219700000,
            707,
            707,
            706.7,
            706.7
        ],
        [
            1709219760000,
            706.7,
            706.85,
            706.7,
            706.8
        ],
        [
            1709219820000,
            706.8,
            707,
            706.8,
            706.8
        ],
        [
            1709219880000,
            706.5,
            706.5,
            705.7,
            706.1
        ],
        [
            1709219940000,
            706.65,
            706.85,
            706.2,
            706.4
        ],
        [
            1709220000000,
            706.5,
            706.9,
            706.15,
            706.4
        ],
        [
            1709220060000,
            706.4,
            706.95,
            705.35,
            706.8
        ],
        [
            1709220120000,
            705.9,
            706.6,
            705,
            706.2
        ],
        [
            1709220180000,
            706.1,
            706.1,
            704,
            704.4
        ],
        [
            1709220240000,
            704.4,
            705,
            704.25,
            704.85
        ],
        [
            1709220300000,
            704.85,
            707.5,
            704.85,
            707.5
        ],
        [
            1709220360000,
            707.3,
            707.8,
            706.8,
            706.8
        ],
        [
            1709220420000,
            707.25,
            707.25,
            706.65,
            707
        ],
        [
            1709220480000,
            707,
            707,
            705.95,
            705.95
        ],
        [
            1709220540000,
            705.85,
            706,
            705.85,
            705.85
        ],
        [
            1709220600000,
            706,
            706,
            706,
            706
        ],
        [
            1709284500000,
            711,
            711,
            706.6,
            706.9
        ],
        [
            1709284560000,
            706.9,
            706.9,
            703.8,
            704.45
        ],
        [
            1709284620000,
            705.05,
            705.65,
            703.5,
            704.9
        ],
        [
            1709284680000,
            705.65,
            707.1,
            705.65,
            707.1
        ],
        [
            1709284740000,
            707.15,
            707.15,
            706.3,
            707
        ],
        [
            1709284800000,
            707.7,
            707.7,
            705.85,
            705.85
        ],
        [
            1709284860000,
            705.35,
            705.7,
            704.7,
            705.7
        ],
        [
            1709284920000,
            706.5,
            706.5,
            705.85,
            705.85
        ],
        [
            1709284980000,
            705.85,
            705.85,
            705.1,
            705.1
        ],
        [
            1709285040000,
            704.8,
            705.65,
            704.8,
            705.05
        ],
        [
            1709285100000,
            705.75,
            705.75,
            704.3,
            704.3
        ],
        [
            1709285160000,
            704.3,
            705.05,
            704,
            705.05
        ],
        [
            1709285220000,
            705.45,
            706.05,
            704.7,
            704.75
        ],
        [
            1709285280000,
            704.75,
            704.95,
            703.3,
            703.65
        ],
        [
            1709285340000,
            703.3,
            703.75,
            703,
            703
        ],
        [
            1709285400000,
            703,
            703.4,
            702.9,
            703
        ],
        [
            1709285460000,
            702.85,
            702.85,
            702.2,
            702.55
        ],
        [
            1709285520000,
            702.8,
            703.05,
            702.55,
            702.95
        ],
        [
            1709285580000,
            702.95,
            702.95,
            701.75,
            702
        ],
        [
            1709285640000,
            702.8,
            703.1,
            702.25,
            703.1
        ],
        [
            1709285700000,
            703.1,
            703.45,
            703.1,
            703.45
        ],
        [
            1709285760000,
            703.25,
            704.45,
            703.15,
            703.8
        ],
        [
            1709285820000,
            703.7,
            705.25,
            703.7,
            704.05
        ],
        [
            1709285880000,
            704.3,
            705.3,
            704.3,
            705.2
        ],
        [
            1709285940000,
            704.9,
            705.85,
            704.9,
            705.4
        ],
        [
            1709286000000,
            705.7,
            706,
            705.4,
            705.65
        ],
        [
            1709286060000,
            706.1,
            706.75,
            706,
            706.1
        ],
        [
            1709286120000,
            706.3,
            706.35,
            705.1,
            705.1
        ],
        [
            1709286180000,
            705.1,
            705.85,
            705.1,
            705.75
        ],
        [
            1709286240000,
            705.75,
            706.05,
            705.75,
            705.75
        ],
        [
            1709286300000,
            705.9,
            706.35,
            705.75,
            706.35
        ],
        [
            1709286360000,
            706,
            706.3,
            706,
            706.2
        ],
        [
            1709286420000,
            706.3,
            706.9,
            706.2,
            706.9
        ],
        [
            1709286480000,
            706.65,
            707.1,
            706.65,
            707.1
        ],
        [
            1709286540000,
            707,
            707.55,
            707,
            707
        ],
        [
            1709286600000,
            707,
            707.2,
            706.7,
            707.2
        ],
        [
            1709286660000,
            707.45,
            708.25,
            707.45,
            708.25
        ],
        [
            1709286720000,
            707.95,
            708.1,
            707.85,
            708.1
        ],
        [
            1709286780000,
            708,
            708.1,
            708,
            708
        ],
        [
            1709286840000,
            708,
            708.1,
            707.5,
            708.1
        ],
        [
            1709286900000,
            707.65,
            707.9,
            707.3,
            707.55
        ],
        [
            1709286960000,
            708,
            708,
            707.45,
            707.95
        ],
        [
            1709287020000,
            708,
            708,
            707.5,
            707.5
        ],
        [
            1709287080000,
            707.5,
            707.9,
            707.35,
            707.8
        ],
        [
            1709287140000,
            707.55,
            707.7,
            707.05,
            707.1
        ],
        [
            1709287200000,
            707.25,
            707.7,
            707,
            707.7
        ],
        [
            1709287260000,
            707.65,
            708,
            707.3,
            708
        ],
        [
            1709287320000,
            708,
            708.2,
            707.8,
            708.1
        ],
        [
            1709287380000,
            708.05,
            708.6,
            707.75,
            708.05
        ],
        [
            1709287440000,
            708,
            708.2,
            707.55,
            708.05
        ],
        [
            1709287500000,
            707.65,
            708.45,
            707.6,
            708.45
        ],
        [
            1709287560000,
            708,
            708.85,
            708,
            708.4
        ],
        [
            1709287620000,
            708.4,
            708.75,
            708.4,
            708.75
        ],
        [
            1709287680000,
            708.7,
            708.85,
            708.2,
            708.4
        ],
        [
            1709287740000,
            708.1,
            708.35,
            708.1,
            708.1
        ],
        [
            1709287800000,
            708.1,
            708.35,
            708.1,
            708.2
        ],
        [
            1709287860000,
            708.2,
            708.35,
            708.05,
            708.2
        ],
        [
            1709287920000,
            708.3,
            708.3,
            708.05,
            708.1
        ],
        [
            1709287980000,
            708.05,
            708.35,
            708.05,
            708.3
        ],
        [
            1709288040000,
            708.25,
            709.05,
            708,
            709.05
        ],
        [
            1709288100000,
            708.95,
            708.95,
            708.25,
            708.3
        ],
        [
            1709288160000,
            708.25,
            708.45,
            708.05,
            708.4
        ],
        [
            1709288220000,
            708.2,
            708.2,
            707.55,
            707.6
        ],
        [
            1709288280000,
            707.55,
            708.4,
            707.5,
            708.4
        ],
        [
            1709288340000,
            708.1,
            708.65,
            707.9,
            708.35
        ],
        [
            1709288400000,
            708.45,
            708.65,
            707.7,
            707.85
        ],
        [
            1709288460000,
            707.8,
            708.4,
            707.6,
            707.9
        ],
        [
            1709288520000,
            707.8,
            708.45,
            707.8,
            708.4
        ],
        [
            1709288580000,
            708.25,
            708.5,
            707.5,
            707.9
        ],
        [
            1709288640000,
            707.85,
            708.35,
            707.5,
            707.95
        ],
        [
            1709288700000,
            707.5,
            707.8,
            707.5,
            707.7
        ],
        [
            1709288760000,
            707.9,
            707.9,
            707.5,
            707.5
        ],
        [
            1709288820000,
            707.55,
            708.2,
            707.5,
            707.7
        ],
        [
            1709288880000,
            707.7,
            707.85,
            707.65,
            707.75
        ],
        [
            1709288940000,
            707.7,
            708.1,
            707.6,
            708.1
        ],
        [
            1709289000000,
            708,
            708,
            707.6,
            707.6
        ],
        [
            1709289060000,
            707.55,
            707.85,
            707.55,
            707.6
        ],
        [
            1709289120000,
            707.5,
            709,
            707.5,
            708.5
        ],
        [
            1709289180000,
            708,
            708,
            707.3,
            707.35
        ],
        [
            1709289240000,
            707.3,
            707.35,
            707,
            707.1
        ],
        [
            1709289300000,
            707.05,
            707.95,
            707,
            707.4
        ],
        [
            1709289360000,
            707,
            708.25,
            707,
            707
        ],
        [
            1709289420000,
            707,
            707,
            705.8,
            706
        ],
        [
            1709289480000,
            706,
            706.75,
            706,
            706.75
        ],
        [
            1709289540000,
            706.7,
            706.7,
            706.25,
            706.25
        ],
        [
            1709289600000,
            706.05,
            706.05,
            706,
            706.05
        ],
        [
            1709289660000,
            706.15,
            706.15,
            706.05,
            706.05
        ],
        [
            1709289720000,
            706.05,
            706.5,
            705.75,
            705.75
        ],
        [
            1709289780000,
            706.35,
            706.5,
            705.85,
            706.45
        ],
        [
            1709289840000,
            706.5,
            706.5,
            706.2,
            706.2
        ],
        [
            1709289900000,
            706.2,
            706.5,
            706.2,
            706.5
        ],
        [
            1709289960000,
            706.2,
            706.25,
            706.2,
            706.2
        ],
        [
            1709290020000,
            706.2,
            706.45,
            706.2,
            706.45
        ],
        [
            1709290080000,
            706.35,
            706.35,
            705.9,
            705.9
        ],
        [
            1709290140000,
            705.9,
            706.4,
            705.9,
            706.15
        ],
        [
            1709290200000,
            706.5,
            706.5,
            705.95,
            705.95
        ],
        [
            1709290260000,
            705.9,
            706.1,
            705.9,
            705.9
        ],
        [
            1709290320000,
            705.9,
            706.15,
            705.65,
            705.65
        ],
        [
            1709290380000,
            705.65,
            705.7,
            705.3,
            705.3
        ],
        [
            1709290440000,
            705.3,
            705.8,
            705.3,
            705.55
        ],
        [
            1709290500000,
            705.55,
            705.55,
            705.1,
            705.1
        ],
        [
            1709290560000,
            705.1,
            705.1,
            705,
            705
        ],
        [
            1709290620000,
            705,
            705.05,
            705,
            705
        ],
        [
            1709290680000,
            705,
            705,
            704.05,
            704.05
        ],
        [
            1709290740000,
            703.5,
            703.65,
            703.45,
            703.65
        ],
        [
            1709290800000,
            703.9,
            703.9,
            703.55,
            703.55
        ],
        [
            1709290860000,
            703.5,
            703.5,
            703.5,
            703.5
        ],
        [
            1709290920000,
            703.5,
            703.65,
            703.5,
            703.65
        ],
        [
            1709290980000,
            703.35,
            703.6,
            703.35,
            703.35
        ],
        [
            1709291040000,
            703.35,
            703.65,
            703.35,
            703.65
        ],
        [
            1709291100000,
            703.65,
            703.65,
            703.35,
            703.65
        ],
        [
            1709291160000,
            703.8,
            704.2,
            703.6,
            704.2
        ],
        [
            1709291220000,
            703.7,
            703.75,
            703.7,
            703.7
        ],
        [
            1709291280000,
            703.7,
            703.8,
            703.7,
            703.7
        ],
        [
            1709291340000,
            703.7,
            704.05,
            703.7,
            704.05
        ],
        [
            1709291400000,
            703.7,
            703.75,
            703.7,
            703.7
        ],
        [
            1709291460000,
            703.7,
            704.15,
            703.7,
            703.7
        ],
        [
            1709291520000,
            703.7,
            703.95,
            703.7,
            703.85
        ],
        [
            1709291580000,
            703.7,
            704.25,
            703.7,
            703.7
        ],
        [
            1709291640000,
            703.7,
            704.7,
            703.7,
            704.7
        ],
        [
            1709291700000,
            704.85,
            704.85,
            704.15,
            704.8
        ],
        [
            1709291760000,
            704.7,
            704.95,
            704.2,
            704.35
        ],
        [
            1709291820000,
            704.9,
            704.95,
            704.35,
            704.7
        ],
        [
            1709291880000,
            704.7,
            705,
            704.35,
            705
        ],
        [
            1709291940000,
            705,
            705,
            704.45,
            704.95
        ],
        [
            1709292000000,
            704.95,
            705,
            704.45,
            705
        ],
        [
            1709292060000,
            704.5,
            705,
            704.5,
            705
        ],
        [
            1709292120000,
            705,
            705,
            704.5,
            704.5
        ],
        [
            1709292180000,
            704.95,
            705.2,
            704.45,
            704.95
        ],
        [
            1709292240000,
            704.45,
            704.7,
            704.2,
            704.7
        ],
        [
            1709292300000,
            704.7,
            704.8,
            704.2,
            704.75
        ],
        [
            1709292360000,
            704.8,
            704.95,
            704.8,
            704.95
        ],
        [
            1709292420000,
            704.9,
            705.15,
            704.5,
            704.9
        ],
        [
            1709292480000,
            704.9,
            704.95,
            704.5,
            704.95
        ],
        [
            1709292540000,
            704.9,
            704.9,
            704.5,
            704.9
        ],
        [
            1709292600000,
            704.55,
            704.55,
            702.85,
            702.9
        ],
        [
            1709292660000,
            703.25,
            703.25,
            703.25,
            703.25
        ],
        [
            1709292720000,
            703,
            703.5,
            702.85,
            703.25
        ],
        [
            1709292780000,
            703.25,
            703.8,
            703.25,
            703.25
        ],
        [
            1709292840000,
            703.25,
            703.55,
            703.25,
            703.25
        ],
        [
            1709292900000,
            703.25,
            703.25,
            703,
            703.05
        ],
        [
            1709292960000,
            703,
            703.45,
            703,
            703.15
        ],
        [
            1709293020000,
            703.15,
            703.15,
            702.7,
            702.7
        ],
        [
            1709293080000,
            702.7,
            703.25,
            702.7,
            703
        ],
        [
            1709293140000,
            702.7,
            703.25,
            702.7,
            703.25
        ],
        [
            1709293200000,
            703.2,
            703.2,
            702.55,
            702.6
        ],
        [
            1709293260000,
            702.6,
            702.9,
            702.6,
            702.85
        ],
        [
            1709293320000,
            703.05,
            703.05,
            702.7,
            702.7
        ],
        [
            1709293380000,
            702.9,
            703.25,
            702.7,
            703.1
        ],
        [
            1709293440000,
            702.75,
            702.75,
            702.7,
            702.7
        ],
        [
            1709293500000,
            702.9,
            702.9,
            702.7,
            702.9
        ],
        [
            1709293560000,
            702.7,
            702.7,
            702.45,
            702.5
        ],
        [
            1709293620000,
            702.55,
            702.55,
            702.3,
            702.35
        ],
        [
            1709293680000,
            702.35,
            702.35,
            702.3,
            702.3
        ],
        [
            1709293740000,
            702.3,
            702.55,
            702.3,
            702.55
        ],
        [
            1709293800000,
            702.35,
            702.55,
            702.35,
            702.4
        ],
        [
            1709293860000,
            702.45,
            702.55,
            702.45,
            702.45
        ],
        [
            1709293920000,
            702.45,
            702.5,
            702.4,
            702.4
        ],
        [
            1709293980000,
            702.4,
            702.45,
            702,
            702
        ],
        [
            1709294040000,
            702.05,
            702.7,
            702,
            702.7
        ],
        [
            1709294100000,
            702.15,
            702.15,
            702.1,
            702.1
        ],
        [
            1709294160000,
            702.1,
            702.35,
            702.05,
            702.25
        ],
        [
            1709294220000,
            702.25,
            703.1,
            702,
            702
        ],
        [
            1709294280000,
            702,
            702.05,
            702,
            702.05
        ],
        [
            1709294340000,
            702.25,
            702.4,
            702.25,
            702.4
        ],
        [
            1709294400000,
            702.35,
            702.35,
            702.25,
            702.25
        ],
        [
            1709294460000,
            702.25,
            702.9,
            702.25,
            702.35
        ],
        [
            1709294520000,
            702.25,
            702.25,
            702,
            702
        ],
        [
            1709294580000,
            702,
            702,
            702,
            702
        ],
        [
            1709294640000,
            702,
            702.05,
            702,
            702
        ],
        [
            1709294700000,
            702,
            702,
            702,
            702
        ],
        [
            1709294760000,
            702,
            702,
            701.7,
            701.7
        ],
        [
            1709294820000,
            701.5,
            701.95,
            701.5,
            701.65
        ],
        [
            1709294880000,
            701.6,
            701.65,
            701.6,
            701.6
        ],
        [
            1709294940000,
            701.65,
            701.75,
            701.65,
            701.75
        ],
        [
            1709295000000,
            701.75,
            702.5,
            701.75,
            702.05
        ],
        [
            1709295060000,
            702.05,
            702.15,
            701.7,
            702.15
        ],
        [
            1709295120000,
            702.35,
            702.5,
            702.3,
            702.3
        ],
        [
            1709295180000,
            702.25,
            702.25,
            701.35,
            701.35
        ],
        [
            1709295240000,
            701.25,
            701.7,
            701.25,
            701.7
        ],
        [
            1709295300000,
            701.55,
            701.95,
            701.55,
            701.95
        ],
        [
            1709295360000,
            701.65,
            702.5,
            701.65,
            702.5
        ],
        [
            1709295420000,
            702.5,
            702.9,
            702.05,
            702.85
        ],
        [
            1709295480000,
            702.85,
            703.2,
            702.85,
            703.15
        ],
        [
            1709295540000,
            703.25,
            703.65,
            703.15,
            703.15
        ],
        [
            1709295600000,
            703.15,
            703.15,
            702.8,
            702.8
        ],
        [
            1709295660000,
            702.8,
            703.5,
            702.8,
            702.9
        ],
        [
            1709295720000,
            702.9,
            703.15,
            702.9,
            703.15
        ],
        [
            1709295780000,
            703.05,
            703.4,
            703.05,
            703.4
        ],
        [
            1709295840000,
            703.35,
            703.35,
            703.1,
            703.1
        ],
        [
            1709295900000,
            703.1,
            703.1,
            703.1,
            703.1
        ],
        [
            1709295960000,
            703.15,
            703.4,
            703.15,
            703.35
        ],
        [
            1709296020000,
            703.35,
            703.8,
            703.35,
            703.35
        ],
        [
            1709296080000,
            703.35,
            703.35,
            702.45,
            702.85
        ],
        [
            1709296140000,
            702.85,
            702.85,
            702.3,
            702.45
        ],
        [
            1709296200000,
            702.5,
            702.5,
            702,
            702
        ],
        [
            1709296260000,
            702,
            702.35,
            702,
            702.3
        ],
        [
            1709296320000,
            702.5,
            702.75,
            702.2,
            702.2
        ],
        [
            1709296380000,
            702.2,
            702.45,
            702.1,
            702.4
        ],
        [
            1709296440000,
            702.1,
            702.5,
            702.1,
            702.45
        ],
        [
            1709296500000,
            702.25,
            702.3,
            702.25,
            702.3
        ],
        [
            1709296560000,
            702.25,
            703.3,
            702.25,
            702.95
        ],
        [
            1709296620000,
            702.85,
            703.05,
            702.35,
            703.05
        ],
        [
            1709296680000,
            702.5,
            703.65,
            702.5,
            703.55
        ],
        [
            1709296740000,
            703.55,
            703.55,
            703.05,
            703.05
        ],
        [
            1709296800000,
            703.05,
            703.1,
            702.7,
            702.7
        ],
        [
            1709296860000,
            702.8,
            702.95,
            702.6,
            702.8
        ],
        [
            1709296920000,
            702.95,
            703.2,
            702.6,
            702.6
        ],
        [
            1709296980000,
            702.65,
            703,
            702.65,
            703
        ],
        [
            1709297040000,
            703.2,
            703.45,
            702.9,
            703
        ],
        [
            1709297100000,
            703,
            703,
            703,
            703
        ],
        [
            1709297160000,
            703,
            703.05,
            703,
            703
        ],
        [
            1709297220000,
            703,
            703.15,
            703,
            703
        ],
        [
            1709297280000,
            703,
            703.35,
            703,
            703.15
        ],
        [
            1709297340000,
            703.15,
            703.55,
            703,
            703.45
        ],
        [
            1709297400000,
            703.1,
            703.85,
            703,
            703.85
        ],
        [
            1709297460000,
            703.8,
            703.8,
            703.3,
            703.5
        ],
        [
            1709297520000,
            703.3,
            703.8,
            703.3,
            703.45
        ],
        [
            1709297580000,
            703.45,
            703.65,
            703.45,
            703.55
        ],
        [
            1709297640000,
            703.3,
            703.7,
            703.15,
            703.25
        ],
        [
            1709297700000,
            703.25,
            703.5,
            703.25,
            703.3
        ],
        [
            1709297760000,
            703.35,
            703.5,
            703.3,
            703.3
        ],
        [
            1709297820000,
            703.3,
            703.55,
            703.3,
            703.55
        ],
        [
            1709297880000,
            703.35,
            704.4,
            703.35,
            704.4
        ],
        [
            1709297940000,
            704.7,
            704.75,
            704.25,
            704.3
        ],
        [
            1709298000000,
            704.3,
            704.7,
            704.3,
            704.55
        ],
        [
            1709298060000,
            704.55,
            704.7,
            704.55,
            704.55
        ],
        [
            1709298120000,
            704.65,
            705,
            704.65,
            705
        ],
        [
            1709298180000,
            704.9,
            705.05,
            704.9,
            705
        ],
        [
            1709298240000,
            705,
            705,
            703.75,
            703.9
        ],
        [
            1709298300000,
            703.9,
            704.7,
            703.8,
            704.15
        ],
        [
            1709298360000,
            704,
            704.45,
            703.55,
            703.65
        ],
        [
            1709298420000,
            703.65,
            703.85,
            703.65,
            703.85
        ],
        [
            1709298480000,
            703.6,
            703.6,
            703.3,
            703.3
        ],
        [
            1709298540000,
            703.3,
            703.35,
            703.3,
            703.3
        ],
        [
            1709298600000,
            703.3,
            703.3,
            702.45,
            702.45
        ],
        [
            1709298660000,
            702.55,
            702.55,
            702.2,
            702.35
        ],
        [
            1709298720000,
            702.25,
            703.5,
            702.25,
            703.5
        ],
        [
            1709298780000,
            702.9,
            703.3,
            702.9,
            703
        ],
        [
            1709298840000,
            702.9,
            703.3,
            702.9,
            702.9
        ],
        [
            1709298900000,
            702.95,
            703.15,
            702.9,
            703
        ],
        [
            1709298960000,
            703,
            703.25,
            703,
            703.25
        ],
        [
            1709299020000,
            703.45,
            703.45,
            703.25,
            703.3
        ],
        [
            1709299080000,
            703.35,
            703.5,
            702.9,
            702.9
        ],
        [
            1709299140000,
            702.9,
            703.25,
            702.9,
            702.95
        ],
        [
            1709299200000,
            702.95,
            703.15,
            702.9,
            702.9
        ],
        [
            1709299260000,
            702.85,
            703.9,
            702.75,
            703.8
        ],
        [
            1709299320000,
            703.35,
            703.6,
            703.25,
            703.5
        ],
        [
            1709299380000,
            703.05,
            704,
            703.05,
            703.55
        ],
        [
            1709299440000,
            703.85,
            704.15,
            703.6,
            704.15
        ],
        [
            1709299500000,
            704.3,
            704.5,
            703.85,
            704.1
        ],
        [
            1709299560000,
            704.1,
            704.6,
            704.1,
            704.6
        ],
        [
            1709299620000,
            704.15,
            704.15,
            703.7,
            704
        ],
        [
            1709299680000,
            704,
            704.15,
            703.65,
            704
        ],
        [
            1709299740000,
            704,
            704.05,
            704,
            704.05
        ],
        [
            1709299800000,
            704.05,
            704.1,
            704.05,
            704.05
        ],
        [
            1709299860000,
            704.05,
            704.2,
            704,
            704.2
        ],
        [
            1709299920000,
            704.7,
            704.7,
            704.35,
            704.35
        ],
        [
            1709299980000,
            704.35,
            704.7,
            704.35,
            704.65
        ],
        [
            1709300040000,
            704.65,
            704.9,
            704.65,
            704.9
        ],
        [
            1709300100000,
            704.65,
            704.65,
            704.65,
            704.65
        ],
        [
            1709300160000,
            704.65,
            704.7,
            704.3,
            704.4
        ],
        [
            1709300220000,
            704.3,
            704.3,
            703.9,
            704.05
        ],
        [
            1709300280000,
            704.25,
            704.25,
            703.9,
            703.9
        ],
        [
            1709300340000,
            703.9,
            703.95,
            703.2,
            703.55
        ],
        [
            1709300400000,
            703.55,
            703.75,
            703.15,
            703.2
        ],
        [
            1709300460000,
            703.2,
            703.95,
            703.2,
            703.4
        ],
        [
            1709300520000,
            703.6,
            703.65,
            703.4,
            703.55
        ],
        [
            1709300580000,
            703.7,
            704,
            703.55,
            703.6
        ],
        [
            1709300640000,
            703.7,
            704.35,
            703.55,
            704.35
        ],
        [
            1709300700000,
            704,
            704,
            703.6,
            703.7
        ],
        [
            1709300760000,
            703.8,
            703.8,
            703.55,
            703.65
        ],
        [
            1709300820000,
            703.55,
            703.75,
            703.3,
            703.7
        ],
        [
            1709300880000,
            703.7,
            703.7,
            702.75,
            702.75
        ],
        [
            1709300940000,
            703,
            703.65,
            702.75,
            702.85
        ],
        [
            1709301000000,
            703.05,
            703.1,
            702.75,
            702.75
        ],
        [
            1709301060000,
            702.7,
            703.05,
            702.7,
            702.7
        ],
        [
            1709301120000,
            702.85,
            702.85,
            702.45,
            702.45
        ],
        [
            1709301180000,
            702.3,
            702.35,
            702.15,
            702.15
        ],
        [
            1709301240000,
            702.15,
            702.65,
            702.15,
            702.65
        ],
        [
            1709301300000,
            702.6,
            702.6,
            702.2,
            702.5
        ],
        [
            1709301360000,
            702.3,
            703,
            702.3,
            702.8
        ],
        [
            1709301420000,
            702.8,
            703.25,
            702.8,
            702.85
        ],
        [
            1709301480000,
            702.9,
            703.4,
            702.75,
            703.4
        ],
        [
            1709301540000,
            703.25,
            703.4,
            702.7,
            702.9
        ],
        [
            1709301600000,
            702.9,
            703.6,
            702.9,
            703.2
        ],
        [
            1709301660000,
            702.8,
            703.1,
            702.8,
            702.9
        ],
        [
            1709301720000,
            702.9,
            703,
            702.8,
            702.9
        ],
        [
            1709301780000,
            702.9,
            702.95,
            702.8,
            702.8
        ],
        [
            1709301840000,
            702.65,
            702.65,
            702.35,
            702.4
        ],
        [
            1709301900000,
            702.4,
            702.8,
            702.4,
            702.75
        ],
        [
            1709301960000,
            702.85,
            703.05,
            702.8,
            703
        ],
        [
            1709302020000,
            703,
            703.15,
            703,
            703.15
        ],
        [
            1709302080000,
            703.2,
            703.4,
            703,
            703
        ],
        [
            1709302140000,
            703,
            703.5,
            703,
            703.25
        ],
        [
            1709302200000,
            703.25,
            703.35,
            703.1,
            703.2
        ],
        [
            1709302260000,
            703.2,
            704.15,
            703.2,
            704.15
        ],
        [
            1709302320000,
            704,
            704.2,
            704,
            704
        ],
        [
            1709302380000,
            704,
            704.4,
            704,
            704
        ],
        [
            1709302440000,
            704,
            704.55,
            704,
            704.55
        ],
        [
            1709302500000,
            704.5,
            704.5,
            704,
            704
        ],
        [
            1709302560000,
            704,
            704.45,
            704,
            704.45
        ],
        [
            1709302620000,
            704.55,
            705,
            704.5,
            704.95
        ],
        [
            1709302680000,
            705,
            706.3,
            704.95,
            705.9
        ],
        [
            1709302740000,
            705.3,
            706.7,
            705.2,
            705.5
        ],
        [
            1709302800000,
            705.2,
            705.4,
            704.85,
            705.3
        ],
        [
            1709302860000,
            705.25,
            705.45,
            705,
            705.1
        ],
        [
            1709302920000,
            705,
            705,
            704.25,
            705
        ],
        [
            1709302980000,
            704.9,
            705.1,
            704.2,
            704.25
        ],
        [
            1709303040000,
            704.25,
            704.3,
            703.5,
            704.3
        ],
        [
            1709303100000,
            704.25,
            704.55,
            703.95,
            703.95
        ],
        [
            1709303160000,
            704,
            704.2,
            703.75,
            703.75
        ],
        [
            1709303220000,
            703.3,
            703.75,
            703.3,
            703.5
        ],
        [
            1709303280000,
            703.35,
            704.05,
            702.95,
            704.05
        ],
        [
            1709303340000,
            703.95,
            703.95,
            703.65,
            703.95
        ],
        [
            1709303400000,
            703.9,
            703.95,
            703.5,
            703.9
        ],
        [
            1709303460000,
            703.85,
            704.65,
            703.85,
            704.35
        ],
        [
            1709303520000,
            704.5,
            704.5,
            703.9,
            703.95
        ],
        [
            1709303580000,
            704.2,
            704.2,
            703.95,
            704.1
        ],
        [
            1709303640000,
            704.2,
            704.2,
            703.65,
            703.65
        ],
        [
            1709303700000,
            703.65,
            703.9,
            703.5,
            703.5
        ],
        [
            1709303760000,
            703.55,
            704.65,
            703.55,
            704.35
        ],
        [
            1709303820000,
            704.45,
            704.45,
            703.95,
            704.35
        ],
        [
            1709303880000,
            704.35,
            704.5,
            704.1,
            704.35
        ],
        [
            1709303940000,
            704.2,
            704.35,
            703.75,
            704.1
        ],
        [
            1709304000000,
            704.05,
            704.2,
            703.5,
            703.8
        ],
        [
            1709304060000,
            703.75,
            703.75,
            703.2,
            703.55
        ],
        [
            1709304120000,
            703.5,
            703.65,
            703.4,
            703.55
        ],
        [
            1709304180000,
            703.75,
            703.95,
            703.6,
            703.85
        ],
        [
            1709304240000,
            703.8,
            703.9,
            703.35,
            703.5
        ],
        [
            1709304300000,
            703.35,
            703.55,
            703.15,
            703.15
        ],
        [
            1709304360000,
            703.45,
            703.95,
            703.35,
            703.8
        ],
        [
            1709304420000,
            703.95,
            704.15,
            702.45,
            703.3
        ],
        [
            1709304480000,
            703.25,
            703.7,
            703.15,
            703.6
        ],
        [
            1709304540000,
            703.2,
            703.55,
            702.55,
            702.8
        ],
        [
            1709304600000,
            702.8,
            702.8,
            702.2,
            702.3
        ],
        [
            1709304660000,
            702.3,
            702.8,
            702.25,
            702.8
        ],
        [
            1709304720000,
            702.75,
            703.3,
            702.75,
            703.25
        ],
        [
            1709304780000,
            703.1,
            703.25,
            702.5,
            702.5
        ],
        [
            1709304840000,
            702.75,
            702.75,
            702.2,
            702.2
        ],
        [
            1709304900000,
            702.35,
            703.55,
            702.25,
            703.4
        ],
        [
            1709304960000,
            703.4,
            703.95,
            702.8,
            703.9
        ],
        [
            1709305020000,
            703.85,
            704.5,
            703.4,
            703.7
        ],
        [
            1709305080000,
            703.7,
            704.5,
            703.7,
            704.25
        ],
        [
            1709305140000,
            703.95,
            704.45,
            703.95,
            704.45
        ],
        [
            1709305200000,
            704.4,
            706.2,
            704.4,
            705.4
        ],
        [
            1709305260000,
            705.35,
            706,
            705.3,
            705.4
        ],
        [
            1709305320000,
            705.35,
            705.85,
            704.8,
            705.55
        ],
        [
            1709305380000,
            705.45,
            705.5,
            705.25,
            705.5
        ],
        [
            1709305440000,
            705.5,
            705.5,
            704.3,
            704.3
        ],
        [
            1709305500000,
            704.1,
            704.95,
            704.1,
            704.9
        ],
        [
            1709305560000,
            704.9,
            705.5,
            704.9,
            705.4
        ],
        [
            1709305620000,
            705.5,
            705.5,
            705.3,
            705.3
        ],
        [
            1709305680000,
            705.3,
            705.5,
            705.15,
            705.15
        ],
        [
            1709305740000,
            705.1,
            705.8,
            705.1,
            705.8
        ],
        [
            1709305800000,
            705.55,
            705.85,
            704.45,
            704.9
        ],
        [
            1709305860000,
            705.3,
            706.1,
            705.3,
            705.45
        ],
        [
            1709305920000,
            705.5,
            705.75,
            705.4,
            705.45
        ],
        [
            1709305980000,
            705.4,
            705.95,
            705.1,
            705.65
        ],
        [
            1709306040000,
            705.65,
            705.65,
            704.8,
            704.95
        ],
        [
            1709306100000,
            704.95,
            705.4,
            704.75,
            705.4
        ],
        [
            1709306160000,
            705.35,
            705.9,
            705.2,
            705.7
        ],
        [
            1709306220000,
            705.85,
            705.85,
            704.6,
            705.15
        ],
        [
            1709306280000,
            705.1,
            705.35,
            704.05,
            704.75
        ],
        [
            1709306340000,
            704.7,
            705.55,
            704.25,
            705.5
        ],
        [
            1709306400000,
            704.55,
            706.1,
            704.55,
            705.6
        ],
        [
            1709306460000,
            705.95,
            705.95,
            705.7,
            705.85
        ],
        [
            1709306520000,
            705.9,
            706,
            705.7,
            705.95
        ],
        [
            1709306580000,
            706,
            706.2,
            705.8,
            705.85
        ],
        [
            1709306640000,
            706,
            706.35,
            705.95,
            706.3
        ],
        [
            1709306700000,
            706.5,
            706.7,
            706,
            706.7
        ],
        [
            1709306760000,
            706.6,
            706.7,
            706,
            706.7
        ],
        [
            1709306820000,
            706.7,
            707,
            705.9,
            706
        ],
        [
            1709306880000,
            705.95,
            706.75,
            705.95,
            706
        ],
        [
            1709306940000,
            705.7,
            706,
            704.95,
            705.95
        ],
        [
            1709307000000,
            704.15,
            704.15,
            704.15,
            704.15
        ],
        [
            1709370900000,
            706,
            709.75,
            706,
            708.35
        ],
        [
            1709370960000,
            706.05,
            708.95,
            706.05,
            706.05
        ],
        [
            1709371020000,
            708.5,
            708.9,
            708,
            708
        ],
        [
            1709371080000,
            708,
            708,
            708,
            708
        ],
        [
            1709371140000,
            708,
            708,
            708,
            708
        ],
        [
            1709371200000,
            708,
            708,
            707.05,
            707.95
        ],
        [
            1709371260000,
            708,
            708,
            706.55,
            706.55
        ],
        [
            1709371320000,
            707.45,
            707.85,
            706.85,
            706.85
        ],
        [
            1709371380000,
            708,
            708,
            707.05,
            707.05
        ],
        [
            1709371440000,
            707.05,
            707.05,
            706.65,
            707
        ],
        [
            1709371500000,
            707,
            707,
            706.45,
            706.45
        ],
        [
            1709371560000,
            706.15,
            706.85,
            706.15,
            706.85
        ],
        [
            1709371620000,
            706.85,
            706.85,
            706.85,
            706.85
        ],
        [
            1709371680000,
            706.85,
            706.85,
            706.45,
            706.45
        ],
        [
            1709371740000,
            706.45,
            706.85,
            706.45,
            706.85
        ],
        [
            1709371800000,
            706.85,
            706.85,
            706.55,
            706.85
        ],
        [
            1709371860000,
            706.85,
            706.85,
            706.85,
            706.85
        ],
        [
            1709371920000,
            706.55,
            706.55,
            706.55,
            706.55
        ],
        [
            1709371980000,
            707,
            707,
            707,
            707
        ],
        [
            1709372040000,
            706.55,
            707,
            706.55,
            707
        ],
        [
            1709372100000,
            706.2,
            707,
            706.05,
            706.05
        ],
        [
            1709372160000,
            706.05,
            706.05,
            706.05,
            706.05
        ],
        [
            1709372220000,
            706.95,
            707,
            706.2,
            706.2
        ],
        [
            1709372280000,
            706.95,
            706.95,
            706.2,
            706.2
        ],
        [
            1709372340000,
            706.9,
            706.9,
            702.05,
            706.2
        ],
        [
            1709372400000,
            706.2,
            706.2,
            706,
            706.1
        ],
        [
            1709372460000,
            706,
            706.7,
            705.1,
            706.7
        ],
        [
            1709372520000,
            706.7,
            706.7,
            706.7,
            706.7
        ],
        [
            1709372580000,
            706.9,
            706.9,
            705.25,
            705.25
        ],
        [
            1709372640000,
            706.5,
            706.5,
            706.5,
            706.5
        ],
        [
            1709372700000,
            706.9,
            707,
            706.9,
            707
        ],
        [
            1709372760000,
            706.6,
            707,
            706.6,
            707
        ],
        [
            1709372820000,
            707,
            707.7,
            707,
            707.7
        ],
        [
            1709372880000,
            707.9,
            707.9,
            707.7,
            707.9
        ],
        [
            1709372940000,
            709,
            709,
            708.95,
            708.95
        ],
        [
            1709373000000,
            708,
            708,
            707.95,
            708
        ],
        [
            1709373060000,
            708,
            708,
            708,
            708
        ],
        [
            1709373120000,
            708,
            708,
            708,
            708
        ],
        [
            1709373180000,
            707.75,
            707.75,
            707.45,
            707.45
        ],
        [
            1709373240000,
            707.2,
            707.75,
            704.6,
            704.6
        ],
        [
            1709373300000,
            705.85,
            707.55,
            705.85,
            706
        ],
        [
            1709373360000,
            706.75,
            706.9,
            706.7,
            706.9
        ],
        [
            1709373420000,
            706.9,
            707.95,
            706.9,
            707.95
        ],
        [
            1709373480000,
            707.95,
            707.95,
            707.95,
            707.95
        ],
        [
            1709373540000,
            707.95,
            707.95,
            707,
            707
        ],
        [
            1709378400000,
            705.55,
            705.55,
            705.55,
            705.55
        ],
        [
            1709378520000,
            705.65,
            705.65,
            705.65,
            705.65
        ],
        [
            1709379000000,
            704.05,
            704.05,
            701.1,
            701.1
        ],
        [
            1709379060000,
            708,
            708,
            707.1,
            707.1
        ],
        [
            1709379120000,
            706.95,
            706.95,
            706.95,
            706.95
        ],
        [
            1709379180000,
            707.9,
            707.9,
            706.9,
            706.95
        ],
        [
            1709379240000,
            707,
            707,
            706.95,
            706.95
        ],
        [
            1709379300000,
            706.95,
            706.95,
            705.1,
            705.1
        ],
        [
            1709379360000,
            705.1,
            706.65,
            705.1,
            706.65
        ],
        [
            1709379420000,
            705.4,
            705.4,
            705.4,
            705.4
        ],
        [
            1709379480000,
            705.15,
            705.4,
            703.15,
            703.15
        ],
        [
            1709379540000,
            705.4,
            706.6,
            705.4,
            706.6
        ],
        [
            1709379660000,
            706.95,
            706.95,
            705.35,
            705.35
        ],
        [
            1709379720000,
            707,
            707,
            707,
            707
        ],
        [
            1709379780000,
            707,
            707,
            706.95,
            706.95
        ],
        [
            1709379840000,
            706.85,
            706.85,
            705.3,
            705.3
        ],
        [
            1709379900000,
            706.85,
            712.8,
            706.85,
            708.15
        ],
        [
            1709379960000,
            708,
            709.15,
            708,
            709.15
        ],
        [
            1709380020000,
            712.75,
            712.75,
            708.75,
            708.75
        ],
        [
            1709380080000,
            707.6,
            707.6,
            707.05,
            707.05
        ],
        [
            1709380140000,
            708.6,
            708.6,
            708.6,
            708.6
        ],
        [
            1709380200000,
            707.45,
            707.45,
            707.45,
            707.45
        ],
        [
            1709380260000,
            707.25,
            707.45,
            707.25,
            707.45
        ],
        [
            1709380320000,
            707.45,
            708.5,
            707.05,
            707.05
        ],
        [
            1709380500000,
            708,
            708,
            707.4,
            707.4
        ],
        [
            1709380560000,
            708,
            708,
            708,
            708
        ],
        [
            1709380620000,
            708.5,
            708.5,
            708,
            708
        ],
        [
            1709380680000,
            707.6,
            707.9,
            707.4,
            707.9
        ],
        [
            1709380740000,
            707.5,
            707.5,
            707.4,
            707.4
        ],
        [
            1709380800000,
            727,
            727,
            707.5,
            709.7
        ],
        [
            1709380860000,
            709.7,
            710.45,
            709.05,
            710
        ],
        [
            1709380920000,
            709.55,
            709.8,
            707.75,
            708.7
        ],
        [
            1709380980000,
            709.75,
            709.75,
            709.75,
            709.75
        ],
        [
            1709381040000,
            709.85,
            709.85,
            709,
            709.8
        ],
        [
            1709381100000,
            709.65,
            709.65,
            708.85,
            709
        ],
        [
            1709381220000,
            709,
            709.45,
            709,
            709
        ],
        [
            1709381280000,
            708.8,
            708.8,
            708.8,
            708.8
        ],
        [
            1709381340000,
            707.45,
            708,
            707.45,
            707.8
        ],
        [
            1709381400000,
            708.7,
            708.7,
            708.7,
            708.7
        ],
        [
            1709381460000,
            708.7,
            708.7,
            708.7,
            708.7
        ],
        [
            1709381520000,
            708.7,
            708.7,
            708.65,
            708.65
        ],
        [
            1709381580000,
            708.65,
            708.65,
            708.65,
            708.65
        ],
        [
            1709381640000,
            707.6,
            708.65,
            707.6,
            708.65
        ],
        [
            1709381700000,
            708.65,
            708.65,
            707.95,
            707.95
        ],
        [
            1709381760000,
            707.5,
            707.9,
            707.45,
            707.45
        ],
        [
            1709381820000,
            707.5,
            707.5,
            707.45,
            707.45
        ],
        [
            1709381880000,
            707.7,
            707.7,
            707.7,
            707.7
        ],
        [
            1709382000000,
            707.7,
            710.15,
            707.7,
            710.15
        ],
        [
            1709382120000,
            707.75,
            709.5,
            707.75,
            709.5
        ],
        [
            1709382180000,
            709,
            709,
            708,
            708
        ],
        [
            1709382240000,
            707.1,
            707.25,
            707.05,
            707.05
        ],
        [
            1709382300000,
            707.05,
            707.05,
            707.05,
            707.05
        ],
        [
            1709382360000,
            709.75,
            709.75,
            709.75,
            709.75
        ],
        [
            1709382420000,
            709.75,
            709.75,
            709.75,
            709.75
        ],
        [
            1709382480000,
            709.6,
            709.6,
            708,
            708
        ],
        [
            1709382540000,
            709.65,
            709.7,
            709.65,
            709.7
        ],
        [
            1709543700000,
            710.45,
            713.75,
            708.8,
            708.8
        ],
        [
            1709543760000,
            708.95,
            710.9,
            708.95,
            710.9
        ],
        [
            1709543820000,
            710.4,
            710.6,
            710.05,
            710.35
        ],
        [
            1709543880000,
            710,
            710,
            708.4,
            708.4
        ],
        [
            1709543940000,
            708.4,
            708.4,
            706.8,
            706.8
        ],
        [
            1709544000000,
            706.75,
            707.5,
            706.6,
            706.8
        ],
        [
            1709544060000,
            707.35,
            707.35,
            706.55,
            706.55
        ],
        [
            1709544120000,
            706.5,
            706.95,
            706,
            706.6
        ],
        [
            1709544180000,
            706.95,
            708.3,
            706.95,
            708.3
        ],
        [
            1709544240000,
            708,
            708.6,
            707.9,
            708.4
        ],
        [
            1709544300000,
            708.2,
            708.6,
            707.55,
            707.55
        ],
        [
            1709544360000,
            707.55,
            707.6,
            707.5,
            707.5
        ],
        [
            1709544420000,
            707.1,
            707.1,
            706.95,
            706.95
        ],
        [
            1709544480000,
            706.6,
            706.95,
            706.3,
            706.3
        ],
        [
            1709544540000,
            706.5,
            706.6,
            706.25,
            706.35
        ],
        [
            1709544600000,
            705.4,
            705.85,
            705.05,
            705.65
        ],
        [
            1709544660000,
            705.8,
            706.25,
            705.75,
            706.05
        ],
        [
            1709544720000,
            706.3,
            706.3,
            705.75,
            706
        ],
        [
            1709544780000,
            705.85,
            705.9,
            705.8,
            705.85
        ],
        [
            1709544840000,
            705.8,
            706,
            705.7,
            705.7
        ],
        [
            1709544900000,
            705.7,
            706.05,
            705.35,
            706
        ],
        [
            1709544960000,
            706.25,
            706.65,
            705.5,
            706
        ],
        [
            1709545020000,
            706.6,
            706.65,
            706,
            706.05
        ],
        [
            1709545080000,
            706.05,
            706.35,
            705.9,
            705.9
        ],
        [
            1709545140000,
            705.4,
            705.7,
            705.1,
            705.35
        ],
        [
            1709545200000,
            705.6,
            705.65,
            704.2,
            704.6
        ],
        [
            1709545260000,
            704.7,
            706.2,
            704.6,
            706
        ],
        [
            1709545320000,
            706,
            706.45,
            705.65,
            706.45
        ],
        [
            1709545380000,
            706.05,
            707.1,
            706.05,
            707.05
        ],
        [
            1709545440000,
            707,
            707,
            706.1,
            706.55
        ],
        [
            1709545500000,
            706.55,
            706.7,
            706.1,
            706.1
        ],
        [
            1709545560000,
            706.5,
            706.5,
            706.1,
            706.1
        ],
        [
            1709545620000,
            706.7,
            707.1,
            706.2,
            706.2
        ],
        [
            1709545680000,
            706.1,
            706.5,
            706.05,
            706.5
        ],
        [
            1709545740000,
            706.2,
            706.55,
            706.1,
            706.15
        ],
        [
            1709545800000,
            706.15,
            707.05,
            706.15,
            707.05
        ],
        [
            1709545860000,
            707.05,
            708.6,
            707.05,
            707.65
        ],
        [
            1709545920000,
            707.6,
            708.85,
            707.15,
            708.75
        ],
        [
            1709545980000,
            708.75,
            709.45,
            708.75,
            709.25
        ],
        [
            1709546040000,
            709.35,
            709.45,
            709.2,
            709.2
        ],
        [
            1709546100000,
            709.5,
            710.5,
            709.5,
            710.5
        ],
        [
            1709546160000,
            710,
            710.3,
            709.6,
            709.6
        ],
        [
            1709546220000,
            710.15,
            710.15,
            709.2,
            709.75
        ],
        [
            1709546280000,
            709.25,
            709.8,
            709.25,
            709.7
        ],
        [
            1709546340000,
            709.7,
            710.2,
            709.4,
            709.75
        ],
        [
            1709546400000,
            709.35,
            709.75,
            707.55,
            708.05
        ],
        [
            1709546460000,
            708.05,
            708.55,
            707.55,
            708.35
        ],
        [
            1709546520000,
            708.2,
            708.65,
            708.05,
            708.6
        ],
        [
            1709546580000,
            708.4,
            709.2,
            708.4,
            708.7
        ],
        [
            1709546640000,
            708.75,
            709.05,
            708.6,
            708.8
        ],
        [
            1709546700000,
            708.8,
            709.25,
            708.8,
            709.05
        ],
        [
            1709546760000,
            709.1,
            709.4,
            708.55,
            708.6
        ],
        [
            1709546820000,
            708.6,
            708.65,
            708.1,
            708.65
        ],
        [
            1709546880000,
            708.45,
            709,
            708.45,
            708.6
        ],
        [
            1709546940000,
            708.6,
            709,
            708.15,
            708.2
        ],
        [
            1709547000000,
            708.8,
            708.8,
            708.1,
            708.35
        ],
        [
            1709547060000,
            708.15,
            708.55,
            708.1,
            708.1
        ],
        [
            1709547120000,
            708.1,
            708.55,
            707.75,
            708.05
        ],
        [
            1709547180000,
            708.05,
            708.1,
            707.75,
            707.75
        ],
        [
            1709547240000,
            707.75,
            708,
            707.5,
            707.5
        ],
        [
            1709547300000,
            707.5,
            707.95,
            707.15,
            707.15
        ],
        [
            1709547360000,
            707.1,
            707.35,
            704,
            704.9
        ],
        [
            1709547420000,
            704.5,
            705.15,
            704.15,
            704.5
        ],
        [
            1709547480000,
            704.15,
            704.65,
            703.85,
            703.85
        ],
        [
            1709547540000,
            703.8,
            703.85,
            702.15,
            703.6
        ],
        [
            1709547600000,
            703.15,
            704.65,
            703.15,
            704
        ],
        [
            1709547660000,
            704.35,
            704.6,
            703.3,
            703.35
        ],
        [
            1709547720000,
            703.7,
            704,
            703.35,
            703.4
        ],
        [
            1709547780000,
            703.45,
            703.65,
            702.35,
            702.65
        ],
        [
            1709547840000,
            702.65,
            703.6,
            702.65,
            702.7
        ],
        [
            1709547900000,
            703,
            703.4,
            702.65,
            703.15
        ],
        [
            1709547960000,
            702.65,
            703.15,
            702.65,
            702.65
        ],
        [
            1709548020000,
            702.7,
            703.15,
            702.7,
            703.05
        ],
        [
            1709548080000,
            703.4,
            703.9,
            703.1,
            703.2
        ],
        [
            1709548140000,
            703.2,
            704,
            703.15,
            703.7
        ],
        [
            1709548200000,
            703.6,
            703.95,
            703.4,
            703.4
        ],
        [
            1709548260000,
            703.4,
            706.6,
            703.4,
            706.15
        ],
        [
            1709548320000,
            706,
            707,
            705,
            705.1
        ],
        [
            1709548380000,
            705.05,
            705.55,
            704.2,
            704.2
        ],
        [
            1709548440000,
            704.2,
            705,
            704.2,
            704.5
        ],
        [
            1709548500000,
            704.5,
            704.95,
            704.5,
            704.55
        ],
        [
            1709548560000,
            704.95,
            704.95,
            704.55,
            704.7
        ],
        [
            1709548620000,
            704.9,
            704.9,
            704.5,
            704.5
        ],
        [
            1709548680000,
            704.9,
            705.25,
            704.55,
            704.55
        ],
        [
            1709548740000,
            704.95,
            705,
            704.5,
            704.5
        ],
        [
            1709548800000,
            704.95,
            705.5,
            704.55,
            705.05
        ],
        [
            1709548860000,
            705.45,
            705.45,
            704.55,
            704.7
        ],
        [
            1709548920000,
            704.95,
            704.95,
            704.7,
            704.7
        ],
        [
            1709548980000,
            704.7,
            704.95,
            704.5,
            704.5
        ],
        [
            1709549040000,
            704.7,
            704.85,
            704,
            704
        ],
        [
            1709549100000,
            704,
            704.55,
            703.55,
            703.55
        ],
        [
            1709549160000,
            703.45,
            703.8,
            703.2,
            703.2
        ],
        [
            1709549220000,
            703.6,
            704.55,
            703.25,
            703.95
        ],
        [
            1709549280000,
            704.2,
            704.5,
            703.65,
            704.5
        ],
        [
            1709549340000,
            704.4,
            704.4,
            703.35,
            703.35
        ],
        [
            1709549400000,
            703.9,
            704.05,
            703.35,
            703.5
        ],
        [
            1709549460000,
            704,
            704.05,
            703.4,
            703.4
        ],
        [
            1709549520000,
            703.9,
            703.9,
            703.15,
            703.15
        ],
        [
            1709549580000,
            703.5,
            703.85,
            703.5,
            703.5
        ],
        [
            1709549640000,
            703.85,
            703.9,
            703.45,
            703.45
        ],
        [
            1709549700000,
            703.25,
            703.75,
            702.1,
            702.15
        ],
        [
            1709549760000,
            702.25,
            702.55,
            702,
            702
        ],
        [
            1709549820000,
            702.4,
            703.15,
            702.05,
            702.3
        ],
        [
            1709549880000,
            702.1,
            703.2,
            702.05,
            702.3
        ],
        [
            1709549940000,
            702.25,
            702.7,
            702.1,
            702.5
        ],
        [
            1709550000000,
            702.5,
            702.65,
            702.1,
            702.65
        ],
        [
            1709550060000,
            702.85,
            702.95,
            702.3,
            702.8
        ],
        [
            1709550120000,
            702.8,
            702.8,
            702.3,
            702.8
        ],
        [
            1709550180000,
            702.8,
            702.8,
            702.25,
            702.75
        ],
        [
            1709550240000,
            702.75,
            702.75,
            702.1,
            702.7
        ],
        [
            1709550300000,
            702.7,
            702.7,
            702.15,
            702.3
        ],
        [
            1709550360000,
            702.65,
            702.65,
            702.3,
            702.3
        ],
        [
            1709550420000,
            702.3,
            703.3,
            702.3,
            703.3
        ],
        [
            1709550480000,
            703.3,
            703.4,
            703,
            703.4
        ],
        [
            1709550540000,
            703.4,
            703.8,
            703,
            703
        ],
        [
            1709550600000,
            703.4,
            703.45,
            702.9,
            703
        ],
        [
            1709550660000,
            703.35,
            703.35,
            702.9,
            702.9
        ],
        [
            1709550720000,
            702.8,
            703.6,
            702.8,
            703.35
        ],
        [
            1709550780000,
            703.55,
            703.65,
            703.25,
            703.25
        ],
        [
            1709550840000,
            703.25,
            703.45,
            703.2,
            703.45
        ],
        [
            1709550900000,
            703.5,
            704.5,
            703.05,
            703.75
        ],
        [
            1709550960000,
            703.7,
            704.05,
            703.1,
            703.55
        ],
        [
            1709551020000,
            703.6,
            703.65,
            703.1,
            703.65
        ],
        [
            1709551080000,
            703.65,
            703.75,
            703.4,
            703.4
        ],
        [
            1709551140000,
            703.6,
            703.9,
            703.35,
            703.5
        ],
        [
            1709551200000,
            703.9,
            703.9,
            703.4,
            703.45
        ],
        [
            1709551260000,
            703.9,
            703.9,
            703.4,
            703.85
        ],
        [
            1709551320000,
            703.4,
            703.85,
            703.25,
            703.25
        ],
        [
            1709551380000,
            703.75,
            703.75,
            703.35,
            703.35
        ],
        [
            1709551440000,
            703.35,
            703.55,
            703.2,
            703.2
        ],
        [
            1709551500000,
            703.6,
            703.85,
            703.1,
            703.1
        ],
        [
            1709551560000,
            703.45,
            703.55,
            703.3,
            703.3
        ],
        [
            1709551620000,
            703.55,
            703.55,
            703.2,
            703.4
        ],
        [
            1709551680000,
            703.25,
            703.45,
            703.05,
            703.1
        ],
        [
            1709551740000,
            703.1,
            703.5,
            703.1,
            703.1
        ],
        [
            1709551800000,
            703.5,
            703.5,
            703.1,
            703.25
        ],
        [
            1709551860000,
            703.45,
            703.45,
            703.15,
            703.2
        ],
        [
            1709551920000,
            703.5,
            703.5,
            702.35,
            702.35
        ],
        [
            1709551980000,
            702.4,
            703.05,
            702.3,
            703.05
        ],
        [
            1709552040000,
            703.4,
            703.45,
            702.9,
            703.35
        ],
        [
            1709552100000,
            702.75,
            703.3,
            702.75,
            703.2
        ],
        [
            1709552160000,
            702.85,
            703.6,
            702.8,
            703.55
        ],
        [
            1709552220000,
            703.25,
            703.7,
            703.05,
            703.5
        ],
        [
            1709552280000,
            703.1,
            703.7,
            703.1,
            703.7
        ],
        [
            1709552340000,
            703.5,
            703.7,
            703.5,
            703.6
        ],
        [
            1709552400000,
            703.6,
            704.15,
            703.6,
            703.9
        ],
        [
            1709552460000,
            704.15,
            704.2,
            703.3,
            704.05
        ],
        [
            1709552520000,
            704.75,
            705.4,
            704.35,
            705
        ],
        [
            1709552580000,
            705,
            705.05,
            704.8,
            705
        ],
        [
            1709552640000,
            705.05,
            705.4,
            705,
            705.2
        ],
        [
            1709552700000,
            705.4,
            705.4,
            705,
            705
        ],
        [
            1709552760000,
            705,
            705.2,
            705,
            705
        ],
        [
            1709552820000,
            705,
            705.2,
            705,
            705.2
        ],
        [
            1709552880000,
            705.1,
            705.3,
            705.05,
            705.05
        ],
        [
            1709552940000,
            705,
            705.4,
            705,
            705.05
        ],
        [
            1709553000000,
            704.4,
            705.05,
            704.4,
            705
        ],
        [
            1709553060000,
            705,
            705.05,
            703.5,
            703.5
        ],
        [
            1709553120000,
            703.35,
            703.95,
            702.85,
            702.95
        ],
        [
            1709553180000,
            703.2,
            703.7,
            702.95,
            703.25
        ],
        [
            1709553240000,
            703.45,
            703.45,
            703.1,
            703.45
        ],
        [
            1709553300000,
            703.45,
            703.45,
            702.75,
            702.8
        ],
        [
            1709553360000,
            703.15,
            703.2,
            702.6,
            702.65
        ],
        [
            1709553420000,
            702.6,
            703.15,
            702.3,
            702.3
        ],
        [
            1709553480000,
            702.65,
            702.65,
            701.25,
            701.25
        ],
        [
            1709553540000,
            701.65,
            701.65,
            701.25,
            701.5
        ],
        [
            1709553600000,
            701.5,
            701.75,
            701.2,
            701.25
        ],
        [
            1709553660000,
            701.25,
            701.8,
            701.25,
            701.3
        ],
        [
            1709553720000,
            701.7,
            701.7,
            701.3,
            701.4
        ],
        [
            1709553780000,
            701.35,
            702,
            701.35,
            701.55
        ],
        [
            1709553840000,
            701.55,
            702.4,
            701.5,
            701.65
        ],
        [
            1709553900000,
            701.6,
            702,
            701.45,
            701.95
        ],
        [
            1709553960000,
            701.95,
            702.15,
            701.45,
            701.45
        ],
        [
            1709554020000,
            701.45,
            702,
            701.1,
            701.1
        ],
        [
            1709554080000,
            701.1,
            701.7,
            701.1,
            701.7
        ],
        [
            1709554140000,
            701.25,
            701.75,
            701.2,
            701.25
        ],
        [
            1709554200000,
            701.2,
            701.4,
            701.1,
            701.1
        ],
        [
            1709554260000,
            701.1,
            701.7,
            701.1,
            701.15
        ],
        [
            1709554320000,
            701.1,
            701.45,
            701.05,
            701.05
        ],
        [
            1709554380000,
            701.4,
            701.5,
            701.05,
            701.1
        ],
        [
            1709554440000,
            701.1,
            701.55,
            701,
            701.5
        ],
        [
            1709554500000,
            701.2,
            701.6,
            701.2,
            701.2
        ],
        [
            1709554560000,
            701.6,
            701.6,
            701.05,
            701.2
        ],
        [
            1709554620000,
            701.2,
            701.5,
            701,
            701.05
        ],
        [
            1709554680000,
            701,
            701.35,
            701,
            701.3
        ],
        [
            1709554740000,
            701.1,
            701.3,
            701,
            701
        ],
        [
            1709554800000,
            701.1,
            701.2,
            701,
            701
        ],
        [
            1709554860000,
            700.95,
            701.2,
            700.9,
            700.9
        ],
        [
            1709554920000,
            701.1,
            701.3,
            701,
            701
        ],
        [
            1709554980000,
            701.3,
            701.45,
            701,
            701.45
        ],
        [
            1709555040000,
            701.45,
            701.45,
            701.05,
            701.4
        ],
        [
            1709555100000,
            701.4,
            701.4,
            701,
            701.1
        ],
        [
            1709555160000,
            701.45,
            701.5,
            700.4,
            700.4
        ],
        [
            1709555220000,
            700.65,
            701.1,
            700.65,
            701
        ],
        [
            1709555280000,
            700.85,
            701.05,
            700.85,
            701
        ],
        [
            1709555340000,
            701,
            701.65,
            701,
            701.25
        ],
        [
            1709555400000,
            701.25,
            701.7,
            701.25,
            701.3
        ],
        [
            1709555460000,
            701.35,
            701.65,
            701.15,
            701.35
        ],
        [
            1709555520000,
            701.15,
            701.45,
            701,
            701.45
        ],
        [
            1709555580000,
            701.1,
            701.5,
            700.9,
            701.1
        ],
        [
            1709555640000,
            701.1,
            701.55,
            701.1,
            701.4
        ],
        [
            1709555700000,
            701.15,
            701.5,
            701.1,
            701.5
        ],
        [
            1709555760000,
            701.35,
            701.55,
            701.1,
            701.1
        ],
        [
            1709555820000,
            701.55,
            701.6,
            701.1,
            701.2
        ],
        [
            1709555880000,
            701.05,
            701.6,
            701.05,
            701.2
        ],
        [
            1709555940000,
            701.2,
            701.55,
            701.2,
            701.2
        ],
        [
            1709556000000,
            701.2,
            701.65,
            701.2,
            701.25
        ],
        [
            1709556060000,
            701.05,
            701.15,
            701,
            701.05
        ],
        [
            1709556120000,
            701.15,
            701.55,
            701.05,
            701.5
        ],
        [
            1709556180000,
            701.5,
            701.5,
            701.05,
            701.4
        ],
        [
            1709556240000,
            701.4,
            701.4,
            701,
            701
        ],
        [
            1709556300000,
            701.05,
            701.4,
            701,
            701
        ],
        [
            1709556360000,
            701.05,
            701.2,
            701,
            701.2
        ],
        [
            1709556420000,
            701,
            701.2,
            700.65,
            700.65
        ],
        [
            1709556480000,
            700.65,
            700.7,
            700.65,
            700.65
        ],
        [
            1709556540000,
            700.65,
            700.85,
            700.25,
            700.25
        ],
        [
            1709556600000,
            700.3,
            700.6,
            700,
            700.3
        ],
        [
            1709556660000,
            700.3,
            700.3,
            699.95,
            699.95
        ],
        [
            1709556720000,
            700.05,
            700.05,
            699.4,
            699.4
        ],
        [
            1709556780000,
            699.5,
            699.95,
            699.4,
            699.65
        ],
        [
            1709556840000,
            699.65,
            699.8,
            699.5,
            699.8
        ],
        [
            1709556900000,
            699.8,
            699.95,
            699.5,
            699.7
        ],
        [
            1709556960000,
            699.7,
            699.8,
            699.45,
            699.75
        ],
        [
            1709557020000,
            699.45,
            699.75,
            699,
            699.2
        ],
        [
            1709557080000,
            699.6,
            699.6,
            699.05,
            699.1
        ],
        [
            1709557140000,
            699.1,
            699.45,
            699.1,
            699.4
        ],
        [
            1709557200000,
            699.25,
            699.45,
            699.2,
            699.45
        ],
        [
            1709557260000,
            699.45,
            699.7,
            699.4,
            699.5
        ],
        [
            1709557320000,
            699.7,
            699.8,
            699.35,
            699.75
        ],
        [
            1709557380000,
            699.75,
            699.8,
            699.3,
            699.3
        ],
        [
            1709557440000,
            699.7,
            699.9,
            699.3,
            699.5
        ],
        [
            1709557500000,
            699.9,
            699.9,
            699.45,
            699.85
        ],
        [
            1709557560000,
            699.85,
            699.9,
            699.45,
            699.9
        ],
        [
            1709557620000,
            699.9,
            699.95,
            699.5,
            699.95
        ],
        [
            1709557680000,
            699.9,
            699.9,
            699.3,
            699.7
        ],
        [
            1709557740000,
            699.75,
            700,
            699.5,
            699.5
        ],
        [
            1709557800000,
            699.85,
            699.9,
            699.45,
            699.9
        ],
        [
            1709557860000,
            699.9,
            699.9,
            699.15,
            699.7
        ],
        [
            1709557920000,
            699.6,
            699.7,
            699.15,
            699.2
        ],
        [
            1709557980000,
            699.65,
            699.75,
            699.2,
            699.2
        ],
        [
            1709558040000,
            699.65,
            699.65,
            699.1,
            699.1
        ],
        [
            1709558100000,
            699.15,
            699.6,
            699.15,
            699.6
        ],
        [
            1709558160000,
            699.6,
            699.6,
            699.3,
            699.6
        ],
        [
            1709558220000,
            699.6,
            699.6,
            699.1,
            699.1
        ],
        [
            1709558280000,
            699.5,
            699.6,
            699.1,
            699.6
        ],
        [
            1709558340000,
            699.6,
            699.6,
            699.15,
            699.15
        ],
        [
            1709558400000,
            699.6,
            699.65,
            699.1,
            699.65
        ],
        [
            1709558460000,
            699.6,
            699.6,
            699.1,
            699.15
        ],
        [
            1709558520000,
            699.5,
            699.65,
            699.15,
            699.2
        ],
        [
            1709558580000,
            699.65,
            699.65,
            699.1,
            699.1
        ],
        [
            1709558640000,
            699.6,
            699.65,
            699.1,
            699.6
        ],
        [
            1709558700000,
            699.15,
            699.6,
            699.1,
            699.15
        ],
        [
            1709558760000,
            699.6,
            699.6,
            698.8,
            698.8
        ],
        [
            1709558820000,
            698.55,
            698.85,
            698.1,
            698.1
        ],
        [
            1709558880000,
            698.1,
            698.6,
            698.05,
            698.05
        ],
        [
            1709558940000,
            698.05,
            698.55,
            698,
            698.45
        ],
        [
            1709559000000,
            698.45,
            698.5,
            698,
            698.5
        ],
        [
            1709559060000,
            698.65,
            698.65,
            698.15,
            698.6
        ],
        [
            1709559120000,
            698.2,
            698.6,
            698,
            698.45
        ],
        [
            1709559180000,
            698,
            698.45,
            698,
            698.4
        ],
        [
            1709559240000,
            698.4,
            698.6,
            698.4,
            698.6
        ],
        [
            1709559300000,
            698.3,
            698.35,
            698.1,
            698.1
        ],
        [
            1709559360000,
            698.55,
            698.55,
            698.1,
            698.55
        ],
        [
            1709559420000,
            698.15,
            698.65,
            698.1,
            698.15
        ],
        [
            1709559480000,
            698.1,
            698.5,
            698.1,
            698.1
        ],
        [
            1709559540000,
            698.5,
            698.55,
            698.1,
            698.15
        ],
        [
            1709559600000,
            698.5,
            698.5,
            698,
            698.15
        ],
        [
            1709559660000,
            698,
            698.4,
            698,
            698.05
        ],
        [
            1709559720000,
            698,
            698.35,
            698,
            698.3
        ],
        [
            1709559780000,
            698.35,
            698.35,
            698,
            698
        ],
        [
            1709559840000,
            698,
            698.3,
            698,
            698.05
        ],
        [
            1709559900000,
            698.3,
            698.45,
            698,
            698.3
        ],
        [
            1709559960000,
            698,
            698.35,
            698,
            698.35
        ],
        [
            1709560020000,
            698.05,
            698.35,
            698,
            698
        ],
        [
            1709560080000,
            698.3,
            698.3,
            698,
            698
        ],
        [
            1709560140000,
            698.05,
            698.5,
            698,
            698.5
        ],
        [
            1709560200000,
            698.55,
            698.65,
            698.1,
            698.15
        ],
        [
            1709560260000,
            698.15,
            698.65,
            698.1,
            698.1
        ],
        [
            1709560320000,
            698.5,
            698.55,
            698.1,
            698.1
        ],
        [
            1709560380000,
            698.45,
            698.5,
            698.05,
            698.35
        ],
        [
            1709560440000,
            698.45,
            698.45,
            698.05,
            698.1
        ],
        [
            1709560500000,
            698.1,
            698.6,
            698.1,
            698.25
        ],
        [
            1709560560000,
            698.6,
            698.6,
            698.05,
            698.3
        ],
        [
            1709560620000,
            698.05,
            698.5,
            698.05,
            698.5
        ],
        [
            1709560680000,
            698,
            698.95,
            698,
            698.7
        ],
        [
            1709560740000,
            698.3,
            698.75,
            698.3,
            698.5
        ],
        [
            1709560800000,
            698.6,
            699.05,
            698.1,
            699
        ],
        [
            1709560860000,
            699.35,
            699.4,
            698.1,
            698.75
        ],
        [
            1709560920000,
            698.35,
            698.35,
            697.05,
            697.85
        ],
        [
            1709560980000,
            697.6,
            697.85,
            697.4,
            697.4
        ],
        [
            1709561040000,
            697.4,
            697.65,
            697.05,
            697.4
        ],
        [
            1709561100000,
            697.1,
            697.5,
            697.1,
            697.35
        ],
        [
            1709561160000,
            697.85,
            697.9,
            697.1,
            697.45
        ],
        [
            1709561220000,
            697.5,
            697.7,
            697.15,
            697.7
        ],
        [
            1709561280000,
            697.25,
            697.85,
            697.25,
            697.5
        ],
        [
            1709561340000,
            697.5,
            697.85,
            697.3,
            697.8
        ],
        [
            1709561400000,
            697.55,
            697.85,
            697.55,
            697.85
        ],
        [
            1709561460000,
            697.65,
            698,
            697.55,
            698
        ],
        [
            1709561520000,
            698,
            698.15,
            697.5,
            698
        ],
        [
            1709561580000,
            698.15,
            698.35,
            697.8,
            698.3
        ],
        [
            1709561640000,
            698.2,
            698.35,
            698.05,
            698.35
        ],
        [
            1709561700000,
            698.35,
            698.35,
            698,
            698
        ],
        [
            1709561760000,
            698.25,
            698.3,
            698,
            698.25
        ],
        [
            1709561820000,
            698,
            698.35,
            698,
            698
        ],
        [
            1709561880000,
            698.25,
            698.4,
            698,
            698.4
        ],
        [
            1709561940000,
            698.35,
            698.35,
            697.65,
            697.65
        ],
        [
            1709562000000,
            697.6,
            698.1,
            697.6,
            698.05
        ],
        [
            1709562060000,
            697.65,
            698.25,
            697.6,
            698.25
        ],
        [
            1709562120000,
            698.25,
            698.35,
            698,
            698.35
        ],
        [
            1709562180000,
            697.9,
            698.35,
            697.8,
            698.25
        ],
        [
            1709562240000,
            697.8,
            698.2,
            697.8,
            698.15
        ],
        [
            1709562300000,
            697.9,
            698.2,
            697.8,
            697.8
        ],
        [
            1709562360000,
            698,
            698.2,
            698,
            698
        ],
        [
            1709562420000,
            698,
            698.25,
            698,
            698.25
        ],
        [
            1709562480000,
            698,
            698.35,
            697.85,
            698.25
        ],
        [
            1709562540000,
            697.8,
            698.25,
            697.8,
            697.95
        ],
        [
            1709562600000,
            698.2,
            698.5,
            697.9,
            698.5
        ],
        [
            1709562660000,
            697.95,
            698,
            696.75,
            697.25
        ],
        [
            1709562720000,
            697.25,
            698.15,
            696.95,
            698.15
        ],
        [
            1709562780000,
            698.1,
            698.1,
            697.5,
            697.95
        ],
        [
            1709562840000,
            698.05,
            698.05,
            697.35,
            697.65
        ],
        [
            1709562900000,
            697.55,
            697.65,
            697.3,
            697.65
        ],
        [
            1709562960000,
            697.3,
            698.3,
            696.6,
            697.75
        ],
        [
            1709563020000,
            697.4,
            697.75,
            697.25,
            697.65
        ],
        [
            1709563080000,
            697.35,
            697.5,
            696.85,
            697.2
        ],
        [
            1709563140000,
            697.2,
            697.6,
            696.9,
            697.6
        ],
        [
            1709563200000,
            697.2,
            697.65,
            697.2,
            697.6
        ],
        [
            1709563260000,
            697.35,
            697.65,
            697.1,
            697.6
        ],
        [
            1709563320000,
            697.65,
            697.85,
            697.5,
            697.5
        ],
        [
            1709563380000,
            697.8,
            697.8,
            697.35,
            697.75
        ],
        [
            1709563440000,
            697.5,
            697.7,
            697.25,
            697.45
        ],
        [
            1709563500000,
            697.7,
            697.8,
            697.35,
            697.65
        ],
        [
            1709563560000,
            697.7,
            697.8,
            697.15,
            697.6
        ],
        [
            1709563620000,
            697.55,
            697.65,
            697.1,
            697.5
        ],
        [
            1709563680000,
            697.15,
            697.55,
            697.15,
            697.2
        ],
        [
            1709563740000,
            697.2,
            697.8,
            696.95,
            697.8
        ],
        [
            1709563800000,
            697.8,
            698.05,
            697.45,
            697.75
        ],
        [
            1709563860000,
            697.45,
            697.7,
            696.55,
            697.15
        ],
        [
            1709563920000,
            697.1,
            697.45,
            696.8,
            697.25
        ],
        [
            1709563980000,
            697.25,
            697.35,
            697.05,
            697.3
        ],
        [
            1709564040000,
            697.3,
            697.35,
            696.55,
            697.35
        ],
        [
            1709564100000,
            697.25,
            697.25,
            696.9,
            697
        ],
        [
            1709564160000,
            697,
            697.15,
            696.75,
            696.95
        ],
        [
            1709564220000,
            696.95,
            697.8,
            696.5,
            697
        ],
        [
            1709564280000,
            696.6,
            697.65,
            696.6,
            697.65
        ],
        [
            1709564340000,
            697.25,
            697.7,
            696.85,
            697.35
        ],
        [
            1709564400000,
            697.35,
            697.4,
            697.05,
            697.3
        ],
        [
            1709564460000,
            697.3,
            697.35,
            697.25,
            697.25
        ],
        [
            1709564520000,
            697.2,
            697.55,
            697.1,
            697.35
        ],
        [
            1709564580000,
            697.35,
            697.35,
            697,
            697.05
        ],
        [
            1709564640000,
            697.3,
            697.35,
            697.05,
            697.2
        ],
        [
            1709564700000,
            697.1,
            697.2,
            697,
            697
        ],
        [
            1709564760000,
            697.1,
            697.15,
            697.05,
            697.15
        ],
        [
            1709564820000,
            697.15,
            697.15,
            696.95,
            697.05
        ],
        [
            1709564880000,
            696.9,
            696.9,
            696,
            696.9
        ],
        [
            1709564940000,
            696.95,
            697.1,
            696.85,
            697.1
        ],
        [
            1709565000000,
            697.35,
            697.45,
            697.05,
            697.35
        ],
        [
            1709565060000,
            697.25,
            697.35,
            696.95,
            696.95
        ],
        [
            1709565120000,
            696.95,
            697,
            696.55,
            696.8
        ],
        [
            1709565180000,
            696.7,
            696.95,
            696.6,
            696.6
        ],
        [
            1709565240000,
            696.6,
            696.6,
            696.05,
            696.3
        ],
        [
            1709565300000,
            696.4,
            696.45,
            696.3,
            696.3
        ],
        [
            1709565360000,
            696.45,
            697.25,
            696.45,
            696.85
        ],
        [
            1709565420000,
            696.85,
            697.05,
            696.45,
            697.05
        ],
        [
            1709565480000,
            697.25,
            697.25,
            696.6,
            696.9
        ],
        [
            1709565540000,
            696.9,
            697.1,
            696.85,
            697.1
        ],
        [
            1709565600000,
            697.1,
            697.35,
            696.8,
            696.8
        ],
        [
            1709565660000,
            697,
            697.15,
            696.9,
            697.15
        ],
        [
            1709565720000,
            697.15,
            697.25,
            696.9,
            697.1
        ],
        [
            1709565780000,
            697.05,
            697.2,
            697.05,
            697.2
        ],
        [
            1709565840000,
            697.2,
            697.7,
            697.2,
            697.6
        ],
        [
            1709565900000,
            697.55,
            697.85,
            697.2,
            697.55
        ],
        [
            1709565960000,
            697.95,
            698,
            697.55,
            697.6
        ],
        [
            1709566020000,
            697.95,
            698,
            697.15,
            697.15
        ],
        [
            1709566080000,
            697.9,
            698.65,
            697.25,
            697.55
        ],
        [
            1709566140000,
            698.65,
            698.65,
            697.95,
            697.95
        ],
        [
            1709566200000,
            698.6,
            698.6,
            698.6,
            698.6
        ],
        [
            1709630100000,
            700,
            700.5,
            693.15,
            697.4
        ],
        [
            1709630160000,
            696,
            696.3,
            696,
            696.3
        ],
        [
            1709630220000,
            697.3,
            697.4,
            696.5,
            697.4
        ],
        [
            1709630280000,
            697.3,
            697.3,
            696.55,
            696.55
        ],
        [
            1709630340000,
            697.25,
            698.2,
            697.2,
            697.85
        ],
        [
            1709630400000,
            697.6,
            698.6,
            697.35,
            698.6
        ],
        [
            1709630460000,
            698.75,
            698.75,
            697.2,
            698.2
        ],
        [
            1709630520000,
            698.25,
            698.25,
            698.1,
            698.15
        ],
        [
            1709630580000,
            698.25,
            698.25,
            697.75,
            698.25
        ],
        [
            1709630640000,
            697.8,
            698.8,
            697.8,
            698.8
        ],
        [
            1709630700000,
            698.75,
            698.75,
            697.75,
            697.75
        ],
        [
            1709630760000,
            697.6,
            697.6,
            697.05,
            697.05
        ],
        [
            1709630820000,
            697.05,
            697.65,
            697.05,
            697.05
        ],
        [
            1709630880000,
            697.05,
            697.5,
            697.05,
            697.5
        ],
        [
            1709630940000,
            697.05,
            697.05,
            696.3,
            696.3
        ],
        [
            1709631000000,
            696.5,
            697.5,
            696.5,
            697.25
        ],
        [
            1709631060000,
            697.9,
            698,
            697.35,
            697.35
        ],
        [
            1709631120000,
            698,
            698,
            696.05,
            696.05
        ],
        [
            1709631180000,
            695.8,
            696.3,
            695.8,
            696.3
        ],
        [
            1709631240000,
            696.25,
            696.25,
            696,
            696
        ],
        [
            1709631300000,
            695.85,
            696.3,
            695.85,
            696
        ],
        [
            1709631360000,
            696,
            696,
            696,
            696
        ],
        [
            1709631420000,
            696.3,
            696.55,
            695,
            695
        ],
        [
            1709631480000,
            695,
            695.25,
            694.45,
            694.45
        ],
        [
            1709631540000,
            694.6,
            694.9,
            694.6,
            694.65
        ],
        [
            1709631600000,
            694.4,
            694.95,
            694.4,
            694.95
        ],
        [
            1709631660000,
            695.25,
            695.35,
            694.4,
            694.55
        ],
        [
            1709631720000,
            695,
            695,
            694.6,
            694.6
        ],
        [
            1709631780000,
            694.6,
            694.6,
            694.55,
            694.55
        ],
        [
            1709631840000,
            694.55,
            694.7,
            693.7,
            693.7
        ],
        [
            1709631900000,
            693.7,
            694.65,
            693.7,
            694
        ],
        [
            1709631960000,
            693.9,
            694.1,
            693.8,
            694.1
        ],
        [
            1709632020000,
            693.6,
            694.05,
            693.6,
            693.9
        ],
        [
            1709632080000,
            693.9,
            694.7,
            693.9,
            694.7
        ],
        [
            1709632140000,
            694.2,
            694.75,
            694.2,
            694.65
        ],
        [
            1709632200000,
            694.15,
            694.5,
            694.15,
            694.5
        ],
        [
            1709632260000,
            694.5,
            694.5,
            694.5,
            694.5
        ],
        [
            1709632320000,
            694.5,
            694.6,
            694.5,
            694.6
        ],
        [
            1709632380000,
            695.15,
            696.55,
            695.15,
            696.55
        ],
        [
            1709632440000,
            696.7,
            696.7,
            695.95,
            696.05
        ],
        [
            1709632500000,
            696.05,
            696.15,
            695.95,
            695.95
        ],
        [
            1709632560000,
            696,
            696.45,
            696,
            696.05
        ],
        [
            1709632620000,
            696.1,
            696.1,
            696.05,
            696.05
        ],
        [
            1709632680000,
            696.05,
            696.05,
            695.4,
            695.4
        ],
        [
            1709632740000,
            695.45,
            695.6,
            694.2,
            694.75
        ],
        [
            1709632800000,
            694.75,
            694.75,
            694.75,
            694.75
        ],
        [
            1709632860000,
            694.75,
            694.75,
            694,
            694.45
        ],
        [
            1709632920000,
            694.1,
            694.75,
            693.55,
            693.8
        ],
        [
            1709632980000,
            693.8,
            693.8,
            693,
            693
        ],
        [
            1709633040000,
            693,
            693.4,
            693,
            693.4
        ],
        [
            1709633100000,
            693.85,
            693.85,
            693.4,
            693.45
        ],
        [
            1709633160000,
            693.15,
            693.6,
            693,
            693.6
        ],
        [
            1709633220000,
            693.65,
            693.65,
            693.1,
            693.1
        ],
        [
            1709633280000,
            693.45,
            693.45,
            692.1,
            692.75
        ],
        [
            1709633340000,
            692.6,
            693.1,
            692.6,
            693.1
        ],
        [
            1709633400000,
            693.25,
            694.25,
            693.25,
            693.85
        ],
        [
            1709633460000,
            694.1,
            694.15,
            693.3,
            693.3
        ],
        [
            1709633520000,
            693.5,
            693.65,
            693.25,
            693.6
        ],
        [
            1709633580000,
            693.6,
            693.8,
            693.3,
            693.3
        ],
        [
            1709633640000,
            693.6,
            693.6,
            693.35,
            693.6
        ],
        [
            1709633700000,
            693.2,
            693.55,
            693.2,
            693.55
        ],
        [
            1709633760000,
            693.55,
            693.55,
            692.25,
            692.25
        ],
        [
            1709633820000,
            692.25,
            692.6,
            692.1,
            692.15
        ],
        [
            1709633880000,
            692.25,
            692.6,
            692.15,
            692.2
        ],
        [
            1709633940000,
            692.5,
            693.7,
            692.5,
            693.7
        ],
        [
            1709634000000,
            693.2,
            693.5,
            692.3,
            692.55
        ],
        [
            1709634060000,
            692.75,
            693.1,
            691.65,
            692.4
        ],
        [
            1709634120000,
            692.2,
            692.2,
            691.1,
            691.7
        ],
        [
            1709634180000,
            691.8,
            692.2,
            691.35,
            692
        ],
        [
            1709634300000,
            691.75,
            692.35,
            691.55,
            691.8
        ],
        [
            1709634360000,
            691.8,
            692.2,
            691.75,
            692.2
        ],
        [
            1709634420000,
            692.2,
            692.2,
            691.65,
            691.75
        ],
        [
            1709634480000,
            692.15,
            693.15,
            692.15,
            693.15
        ],
        [
            1709634540000,
            692.65,
            693.15,
            692.65,
            693
        ],
        [
            1709634600000,
            692.95,
            693,
            692,
            692.2
        ],
        [
            1709634660000,
            692.15,
            692.3,
            691.75,
            692.05
        ],
        [
            1709634720000,
            692.35,
            692.35,
            691.6,
            691.95
        ],
        [
            1709634780000,
            691.65,
            691.95,
            690.1,
            690.25
        ],
        [
            1709634840000,
            690.25,
            690.65,
            689,
            690.5
        ],
        [
            1709634900000,
            690.5,
            690.5,
            690.05,
            690.15
        ],
        [
            1709634960000,
            690.1,
            690.6,
            690.05,
            690.5
        ],
        [
            1709635020000,
            690.5,
            691.25,
            690.5,
            691.1
        ],
        [
            1709635080000,
            691.1,
            691.65,
            690.15,
            690.15
        ],
        [
            1709635140000,
            690.45,
            691.55,
            690.35,
            691.55
        ],
        [
            1709635200000,
            691.6,
            691.9,
            691.45,
            691.75
        ],
        [
            1709635260000,
            691.75,
            691.75,
            690.35,
            691.4
        ],
        [
            1709635320000,
            691.4,
            691.45,
            691.4,
            691.45
        ],
        [
            1709635380000,
            691.45,
            691.45,
            691,
            691
        ],
        [
            1709635440000,
            691.15,
            691.15,
            690.8,
            691.05
        ],
        [
            1709635500000,
            691.1,
            691.25,
            690.65,
            691.25
        ],
        [
            1709635560000,
            691.15,
            691.15,
            690.1,
            690.35
        ],
        [
            1709635620000,
            690.1,
            690.55,
            689.7,
            689.85
        ],
        [
            1709635680000,
            689.7,
            690.2,
            689.5,
            690
        ],
        [
            1709635740000,
            689.85,
            690.2,
            689.85,
            690.2
        ],
        [
            1709635800000,
            690.2,
            690.45,
            690.2,
            690.45
        ],
        [
            1709635860000,
            690.4,
            690.4,
            689.9,
            689.9
        ],
        [
            1709635920000,
            689.9,
            689.9,
            689.5,
            689.9
        ],
        [
            1709635980000,
            689.9,
            689.9,
            688.35,
            688.35
        ],
        [
            1709636040000,
            688.85,
            689.6,
            688.25,
            689.6
        ],
        [
            1709636100000,
            689.6,
            689.9,
            689.45,
            689.9
        ],
        [
            1709636160000,
            689.6,
            690.1,
            689.6,
            690
        ],
        [
            1709636220000,
            690,
            690.45,
            690,
            690.1
        ],
        [
            1709636280000,
            690.35,
            690.6,
            690.15,
            690.4
        ],
        [
            1709636340000,
            690.4,
            690.7,
            689.85,
            689.85
        ],
        [
            1709636400000,
            689.8,
            690.05,
            689.45,
            689.65
        ],
        [
            1709636460000,
            689.65,
            689.65,
            689,
            689.45
        ],
        [
            1709636520000,
            689.45,
            690,
            689.2,
            689.65
        ],
        [
            1709636580000,
            689.3,
            689.65,
            689.2,
            689.2
        ],
        [
            1709636640000,
            689.5,
            689.5,
            689.05,
            689.4
        ],
        [
            1709636700000,
            689.35,
            689.35,
            688.45,
            689.1
        ],
        [
            1709636760000,
            688.15,
            688.55,
            687.55,
            688.05
        ],
        [
            1709636820000,
            688.25,
            688.45,
            688,
            688
        ],
        [
            1709636880000,
            688,
            688.4,
            687.75,
            688.05
        ],
        [
            1709636940000,
            687.45,
            687.7,
            686.55,
            686.55
        ],
        [
            1709637000000,
            686.25,
            687.9,
            686.25,
            687.9
        ],
        [
            1709637060000,
            688,
            688,
            687.45,
            687.6
        ],
        [
            1709637120000,
            687.65,
            688.75,
            687.55,
            688
        ],
        [
            1709637180000,
            688,
            688.4,
            687.8,
            688.05
        ],
        [
            1709637240000,
            688.25,
            688.55,
            688.1,
            688.55
        ],
        [
            1709637300000,
            688.6,
            688.7,
            688.4,
            688.7
        ],
        [
            1709637360000,
            688.7,
            688.75,
            688.45,
            688.75
        ],
        [
            1709637420000,
            688.8,
            689,
            688.6,
            689
        ],
        [
            1709637480000,
            689,
            689,
            688.75,
            689
        ],
        [
            1709637540000,
            689.05,
            689.85,
            689.05,
            689.85
        ],
        [
            1709637600000,
            689.55,
            689.85,
            689.05,
            689.2
        ],
        [
            1709637660000,
            689.25,
            689.4,
            689,
            689.4
        ],
        [
            1709637720000,
            689.4,
            689.7,
            689.4,
            689.6
        ],
        [
            1709637780000,
            689.6,
            689.6,
            688.5,
            688.5
        ],
        [
            1709637840000,
            688.9,
            689.4,
            688.4,
            688.9
        ],
        [
            1709637900000,
            688.9,
            688.9,
            688.55,
            688.75
        ],
        [
            1709637960000,
            688.8,
            689.25,
            688.75,
            689.25
        ],
        [
            1709638020000,
            689.25,
            689.5,
            689.05,
            689.4
        ],
        [
            1709638080000,
            689.4,
            690,
            689.4,
            689.65
        ],
        [
            1709638140000,
            689.65,
            690.7,
            689.65,
            690.7
        ],
        [
            1709638200000,
            690.5,
            690.7,
            690,
            690.4
        ],
        [
            1709638260000,
            690.2,
            690.55,
            690.05,
            690.45
        ],
        [
            1709638320000,
            690.45,
            690.6,
            690.05,
            690.6
        ],
        [
            1709638380000,
            690.1,
            690.45,
            690,
            690.1
        ],
        [
            1709638440000,
            690.45,
            690.65,
            690.05,
            690.6
        ],
        [
            1709638500000,
            690.4,
            690.75,
            690.3,
            690.4
        ],
        [
            1709638560000,
            690.35,
            690.8,
            690.35,
            690.75
        ],
        [
            1709638620000,
            690.9,
            691.95,
            690.45,
            690.45
        ],
        [
            1709638680000,
            690.65,
            690.8,
            689.4,
            689.4
        ],
        [
            1709638740000,
            689.3,
            689.75,
            689.1,
            689.6
        ],
        [
            1709638800000,
            689.4,
            690.05,
            689.35,
            690.05
        ],
        [
            1709638860000,
            689.55,
            690,
            689.15,
            689.2
        ],
        [
            1709638920000,
            689.75,
            690.1,
            689.6,
            690.1
        ],
        [
            1709638980000,
            690.2,
            690.5,
            689.95,
            690.5
        ],
        [
            1709639040000,
            690.35,
            690.6,
            690.2,
            690.2
        ],
        [
            1709639100000,
            690.2,
            690.6,
            689.4,
            689.4
        ],
        [
            1709639160000,
            689.4,
            689.8,
            689.4,
            689.5
        ],
        [
            1709639220000,
            689.4,
            689.9,
            689.4,
            689.9
        ],
        [
            1709639280000,
            689.85,
            690.45,
            689.85,
            689.85
        ],
        [
            1709639340000,
            689.9,
            690.35,
            689.85,
            689.95
        ],
        [
            1709639400000,
            690.05,
            690.05,
            689.4,
            689.7
        ],
        [
            1709639460000,
            689.4,
            689.7,
            689.4,
            689.7
        ],
        [
            1709639520000,
            689.7,
            690,
            689.7,
            690
        ],
        [
            1709639580000,
            690,
            690.05,
            688.7,
            688.7
        ],
        [
            1709639640000,
            688.9,
            689,
            688.7,
            689
        ],
        [
            1709639700000,
            689.3,
            689.3,
            688.65,
            688.65
        ],
        [
            1709639760000,
            688.5,
            688.9,
            688.5,
            688.5
        ],
        [
            1709639820000,
            688.5,
            689,
            688.5,
            688.65
        ],
        [
            1709639880000,
            688.5,
            688.6,
            688.25,
            688.25
        ],
        [
            1709639940000,
            688.2,
            688.7,
            688.15,
            688.3
        ],
        [
            1709640000000,
            688.55,
            688.55,
            686.8,
            686.9
        ],
        [
            1709640060000,
            686.9,
            688.25,
            686.9,
            687.2
        ],
        [
            1709640120000,
            687,
            687.5,
            686.9,
            687.25
        ],
        [
            1709640180000,
            687.25,
            687.6,
            687.25,
            687.3
        ],
        [
            1709640240000,
            687.3,
            687.75,
            687.3,
            687.35
        ],
        [
            1709640300000,
            687.4,
            687.95,
            687.4,
            687.7
        ],
        [
            1709640360000,
            687.4,
            687.9,
            687.4,
            687.6
        ],
        [
            1709640420000,
            687.6,
            688.05,
            687.6,
            687.6
        ],
        [
            1709640480000,
            688,
            688.15,
            687.6,
            687.7
        ],
        [
            1709640540000,
            687.7,
            687.95,
            687.65,
            687.95
        ],
        [
            1709640600000,
            687.9,
            688.2,
            687.9,
            688.2
        ],
        [
            1709640660000,
            688,
            688,
            687.45,
            687.65
        ],
        [
            1709640720000,
            687.9,
            687.9,
            687,
            687.35
        ],
        [
            1709640780000,
            687.05,
            687.6,
            686.8,
            686.8
        ],
        [
            1709640840000,
            686.8,
            687.15,
            686.15,
            687.15
        ],
        [
            1709640900000,
            686.8,
            687.3,
            686.15,
            687.1
        ],
        [
            1709640960000,
            686.6,
            687.45,
            686.3,
            686.65
        ],
        [
            1709641020000,
            686.7,
            687.3,
            686.7,
            687.3
        ],
        [
            1709641080000,
            687.1,
            687.4,
            686.95,
            686.95
        ],
        [
            1709641140000,
            686.95,
            687.3,
            686.95,
            687.3
        ],
        [
            1709641200000,
            687.3,
            688.25,
            687.2,
            688.25
        ],
        [
            1709641260000,
            687.9,
            688.15,
            687.55,
            687.55
        ],
        [
            1709641320000,
            687.05,
            687.6,
            686.5,
            687.35
        ],
        [
            1709641380000,
            687.3,
            687.4,
            687,
            687
        ],
        [
            1709641440000,
            687,
            687.35,
            687,
            687.35
        ],
        [
            1709641500000,
            687.05,
            687.4,
            687.05,
            687.4
        ],
        [
            1709641560000,
            687.35,
            687.45,
            687,
            687.05
        ],
        [
            1709641620000,
            687.35,
            687.4,
            687.05,
            687.05
        ],
        [
            1709641680000,
            687.05,
            687.05,
            686.25,
            686.25
        ],
        [
            1709641740000,
            686.4,
            686.9,
            686.4,
            686.85
        ],
        [
            1709641800000,
            687,
            687.05,
            686.65,
            686.65
        ],
        [
            1709641860000,
            686.8,
            686.95,
            686.6,
            686.6
        ],
        [
            1709641920000,
            686.8,
            687,
            686.8,
            687
        ],
        [
            1709641980000,
            686.9,
            686.95,
            686.6,
            686.6
        ],
        [
            1709642040000,
            686.6,
            686.95,
            686.55,
            686.95
        ],
        [
            1709642100000,
            686.95,
            687.1,
            686.8,
            687
        ],
        [
            1709642160000,
            687.2,
            687.55,
            687.2,
            687.5
        ],
        [
            1709642220000,
            687.45,
            687.55,
            686.85,
            687.15
        ],
        [
            1709642280000,
            687.15,
            687.3,
            686.85,
            687.3
        ],
        [
            1709642340000,
            687.3,
            687.55,
            686.85,
            686.85
        ],
        [
            1709642400000,
            687.1,
            687.35,
            686.8,
            687.3
        ],
        [
            1709642460000,
            687.2,
            687.2,
            686.5,
            686.5
        ],
        [
            1709642520000,
            686.85,
            686.95,
            686.85,
            686.95
        ],
        [
            1709642580000,
            686.95,
            687.05,
            686.85,
            687.05
        ],
        [
            1709642640000,
            687.05,
            687.05,
            686.55,
            686.95
        ],
        [
            1709642700000,
            686.9,
            687.05,
            686.35,
            686.35
        ],
        [
            1709642760000,
            686.3,
            686.9,
            686.3,
            686.9
        ],
        [
            1709642820000,
            686.6,
            686.9,
            686.3,
            686.7
        ],
        [
            1709642880000,
            686.7,
            686.7,
            686.2,
            686.7
        ],
        [
            1709642940000,
            686.2,
            686.2,
            686.1,
            686.2
        ],
        [
            1709643000000,
            686.2,
            686.2,
            685.2,
            685.75
        ],
        [
            1709643060000,
            685.75,
            685.8,
            685.4,
            685.8
        ],
        [
            1709643120000,
            685.8,
            686.15,
            685.8,
            685.95
        ],
        [
            1709643180000,
            686.4,
            686.75,
            685.9,
            686.4
        ],
        [
            1709643240000,
            686.1,
            686.6,
            685.85,
            686.6
        ],
        [
            1709643300000,
            686.6,
            686.6,
            686.05,
            686.5
        ],
        [
            1709643360000,
            687.05,
            687.55,
            687.05,
            687.1
        ],
        [
            1709643420000,
            687.15,
            687.25,
            686.55,
            686.55
        ],
        [
            1709643480000,
            686.9,
            687,
            686.8,
            686.9
        ],
        [
            1709643540000,
            686.9,
            687.15,
            686.8,
            687.15
        ],
        [
            1709643600000,
            687.15,
            687.3,
            687,
            687.3
        ],
        [
            1709643660000,
            687.3,
            687.8,
            687.15,
            687.4
        ],
        [
            1709643720000,
            687.8,
            687.9,
            687.35,
            687.35
        ],
        [
            1709643780000,
            687.75,
            687.85,
            687.35,
            687.85
        ],
        [
            1709643840000,
            687.5,
            688.05,
            687.5,
            688.05
        ],
        [
            1709643900000,
            687.9,
            688.3,
            687.9,
            688.25
        ],
        [
            1709643960000,
            688.3,
            688.45,
            688.05,
            688.05
        ],
        [
            1709644020000,
            688.05,
            688.35,
            688.05,
            688.05
        ],
        [
            1709644080000,
            688.05,
            688.55,
            688.05,
            688.55
        ],
        [
            1709644140000,
            688.15,
            688.4,
            688.1,
            688.3
        ],
        [
            1709644200000,
            688.35,
            688.35,
            687.5,
            687.5
        ],
        [
            1709644260000,
            687.55,
            687.75,
            687.4,
            687.75
        ],
        [
            1709644320000,
            687.55,
            687.75,
            687.25,
            687.4
        ],
        [
            1709644380000,
            687.35,
            687.75,
            687.3,
            687.75
        ],
        [
            1709644440000,
            687.75,
            687.75,
            687.35,
            687.7
        ],
        [
            1709644500000,
            687.7,
            687.8,
            687.5,
            687.8
        ],
        [
            1709644560000,
            687.8,
            687.9,
            687.8,
            687.8
        ],
        [
            1709644620000,
            687.8,
            687.95,
            687.8,
            687.95
        ],
        [
            1709644680000,
            687.95,
            687.95,
            686.9,
            687.25
        ],
        [
            1709644740000,
            686.75,
            687.15,
            686.65,
            687.15
        ],
        [
            1709644800000,
            686.8,
            687.1,
            686.65,
            687
        ],
        [
            1709644860000,
            687,
            687.2,
            686.75,
            687.2
        ],
        [
            1709644920000,
            687.1,
            687.25,
            687.1,
            687.25
        ],
        [
            1709644980000,
            687.3,
            687.4,
            687,
            687.15
        ],
        [
            1709645040000,
            687.4,
            687.7,
            687.25,
            687.7
        ],
        [
            1709645100000,
            687.35,
            687.55,
            687.1,
            687.55
        ],
        [
            1709645160000,
            687.5,
            687.55,
            687.05,
            687.4
        ],
        [
            1709645220000,
            687.3,
            687.3,
            686.85,
            686.85
        ],
        [
            1709645280000,
            686.8,
            686.9,
            686.5,
            686.8
        ],
        [
            1709645340000,
            686.8,
            686.95,
            686.5,
            686.55
        ],
        [
            1709645400000,
            686.6,
            687.15,
            686.6,
            686.9
        ],
        [
            1709645460000,
            687.2,
            687.35,
            686.95,
            687.3
        ],
        [
            1709645520000,
            687.45,
            687.45,
            687.05,
            687.2
        ],
        [
            1709645580000,
            687,
            687.25,
            686.6,
            687.25
        ],
        [
            1709645640000,
            687.05,
            687.05,
            686.8,
            686.85
        ],
        [
            1709645700000,
            686.85,
            687.2,
            686.8,
            687.2
        ],
        [
            1709645760000,
            687.55,
            687.55,
            687,
            687
        ],
        [
            1709645820000,
            687,
            687.3,
            687,
            687
        ],
        [
            1709645880000,
            687,
            687.35,
            686.85,
            687.25
        ],
        [
            1709645940000,
            687.2,
            687.35,
            686.95,
            687.35
        ],
        [
            1709646000000,
            687.3,
            687.35,
            686.85,
            686.85
        ],
        [
            1709646060000,
            686.85,
            687.2,
            686.85,
            686.9
        ],
        [
            1709646120000,
            686.2,
            686.45,
            686,
            686.2
        ],
        [
            1709646180000,
            686.05,
            686.3,
            686,
            686
        ],
        [
            1709646240000,
            686.3,
            686.3,
            686,
            686.2
        ],
        [
            1709646300000,
            686,
            686.2,
            686,
            686.1
        ],
        [
            1709646360000,
            686.25,
            687.35,
            686.25,
            687.05
        ],
        [
            1709646420000,
            687.15,
            687.15,
            686.5,
            686.5
        ],
        [
            1709646480000,
            686.45,
            686.9,
            686.45,
            686.55
        ],
        [
            1709646540000,
            686.55,
            686.95,
            686.5,
            686.55
        ],
        [
            1709646600000,
            686.8,
            686.9,
            686.5,
            686.9
        ],
        [
            1709646660000,
            686.9,
            687,
            686.5,
            687
        ],
        [
            1709646720000,
            686.55,
            686.8,
            686.55,
            686.8
        ],
        [
            1709646780000,
            686.8,
            687.1,
            686.8,
            687.1
        ],
        [
            1709646840000,
            687.15,
            687.2,
            686.75,
            686.75
        ],
        [
            1709646900000,
            687.25,
            687.3,
            686.8,
            687.15
        ],
        [
            1709646960000,
            687.15,
            687.65,
            687.15,
            687.65
        ],
        [
            1709647020000,
            687.65,
            689.1,
            687.65,
            688.2
        ],
        [
            1709647080000,
            688.2,
            689.3,
            688.2,
            689.3
        ],
        [
            1709647140000,
            689.3,
            689.45,
            689.15,
            689.15
        ],
        [
            1709647200000,
            689.15,
            690,
            689.15,
            690
        ],
        [
            1709647260000,
            690,
            690.15,
            689.1,
            689.1
        ],
        [
            1709647320000,
            689.5,
            689.9,
            689.15,
            689.25
        ],
        [
            1709647380000,
            689.65,
            689.7,
            689.5,
            689.6
        ],
        [
            1709647440000,
            689.7,
            689.7,
            689.15,
            689.2
        ],
        [
            1709647500000,
            689.2,
            689.75,
            689.2,
            689.75
        ],
        [
            1709647560000,
            689.75,
            689.9,
            689.6,
            689.8
        ],
        [
            1709647620000,
            689.7,
            690.4,
            689.7,
            689.9
        ],
        [
            1709647680000,
            689.9,
            689.9,
            689.9,
            689.9
        ],
        [
            1709647740000,
            689.9,
            690.05,
            689.7,
            690.05
        ],
        [
            1709647800000,
            690.05,
            690.8,
            689.95,
            690.45
        ],
        [
            1709647860000,
            690.3,
            690.3,
            689,
            689
        ],
        [
            1709647920000,
            689.4,
            689.65,
            688.95,
            689.55
        ],
        [
            1709647980000,
            689.55,
            690.35,
            689.55,
            689.65
        ],
        [
            1709648040000,
            689.3,
            689.95,
            689.3,
            689.9
        ],
        [
            1709648100000,
            689.9,
            689.9,
            689.5,
            689.9
        ],
        [
            1709648160000,
            689.9,
            689.9,
            689.9,
            689.9
        ],
        [
            1709648220000,
            689.9,
            689.9,
            689.7,
            689.9
        ],
        [
            1709648280000,
            689.9,
            690,
            689.85,
            690
        ],
        [
            1709648340000,
            690,
            690,
            689.65,
            690
        ],
        [
            1709648400000,
            690,
            690.05,
            689.6,
            690.05
        ],
        [
            1709648460000,
            690,
            690,
            689.2,
            689.6
        ],
        [
            1709648520000,
            689.35,
            690.1,
            689.35,
            689.7
        ],
        [
            1709648580000,
            690.1,
            692.05,
            690.1,
            691.45
        ],
        [
            1709648640000,
            691.75,
            692.1,
            691.5,
            691.8
        ],
        [
            1709648700000,
            691.65,
            692.95,
            691.5,
            691.5
        ],
        [
            1709648760000,
            691.15,
            691.95,
            691.15,
            691.5
        ],
        [
            1709648820000,
            691.95,
            692.1,
            691.6,
            691.95
        ],
        [
            1709648880000,
            691.7,
            691.7,
            691.15,
            691.45
        ],
        [
            1709648940000,
            691.05,
            691.5,
            690.75,
            690.75
        ],
        [
            1709649000000,
            690.55,
            691.35,
            690.2,
            691.15
        ],
        [
            1709649060000,
            691.4,
            691.45,
            691.05,
            691.05
        ],
        [
            1709649120000,
            691.05,
            691.55,
            691.05,
            691.55
        ],
        [
            1709649180000,
            691.55,
            692.2,
            690.8,
            691.45
        ],
        [
            1709649240000,
            691.3,
            691.65,
            691.25,
            691.65
        ],
        [
            1709649300000,
            691.7,
            691.7,
            691.05,
            691.2
        ],
        [
            1709649360000,
            691.2,
            691.2,
            691,
            691.15
        ],
        [
            1709649420000,
            691.4,
            692.2,
            691.4,
            691.55
        ],
        [
            1709649480000,
            691.55,
            691.65,
            691.55,
            691.65
        ],
        [
            1709649540000,
            691.35,
            691.85,
            691.35,
            691.45
        ],
        [
            1709649600000,
            691.15,
            692,
            691.15,
            692
        ],
        [
            1709649660000,
            692,
            692,
            691.4,
            691.9
        ],
        [
            1709649720000,
            691.9,
            691.9,
            691.5,
            691.5
        ],
        [
            1709649780000,
            691.5,
            691.5,
            691,
            691
        ],
        [
            1709649840000,
            690.35,
            691.45,
            690.35,
            691.45
        ],
        [
            1709649900000,
            690.95,
            691.05,
            690.35,
            690.4
        ],
        [
            1709649960000,
            690.5,
            690.9,
            690.5,
            690.8
        ],
        [
            1709650020000,
            690.85,
            690.95,
            690.5,
            690.5
        ],
        [
            1709650080000,
            690.5,
            690.9,
            690.4,
            690.4
        ],
        [
            1709650140000,
            690.4,
            690.95,
            690.4,
            690.45
        ],
        [
            1709650200000,
            690.4,
            690.8,
            690.4,
            690.4
        ],
        [
            1709650260000,
            690.75,
            690.75,
            690.4,
            690.75
        ],
        [
            1709650320000,
            691.55,
            691.6,
            691.05,
            691.6
        ],
        [
            1709650380000,
            691,
            691,
            690.4,
            690.4
        ],
        [
            1709650440000,
            690.8,
            690.85,
            690.5,
            690.65
        ],
        [
            1709650500000,
            690.6,
            690.7,
            690.45,
            690.7
        ],
        [
            1709650560000,
            691,
            691.4,
            691,
            691.4
        ],
        [
            1709650620000,
            691.4,
            691.4,
            691,
            691
        ],
        [
            1709650680000,
            691,
            691.3,
            691,
            691.3
        ],
        [
            1709650740000,
            691.3,
            691.3,
            690.35,
            690.6
        ],
        [
            1709650800000,
            690.65,
            691.25,
            690.65,
            691.25
        ],
        [
            1709650860000,
            691.25,
            691.65,
            691.25,
            691.6
        ],
        [
            1709650920000,
            691.65,
            691.95,
            691.65,
            691.95
        ],
        [
            1709650980000,
            691.95,
            692,
            691.9,
            691.9
        ],
        [
            1709651040000,
            691.9,
            692.1,
            691.9,
            691.95
        ],
        [
            1709651100000,
            691.85,
            691.9,
            691.65,
            691.8
        ],
        [
            1709651160000,
            691.85,
            691.95,
            691.7,
            691.8
        ],
        [
            1709651220000,
            691.8,
            691.9,
            691.5,
            691.6
        ],
        [
            1709651280000,
            691.75,
            691.75,
            691.25,
            691.75
        ],
        [
            1709651340000,
            691.8,
            691.9,
            691.5,
            691.65
        ],
        [
            1709651400000,
            692.6,
            692.6,
            691.45,
            691.75
        ],
        [
            1709651460000,
            691.85,
            692.25,
            691.55,
            692.25
        ],
        [
            1709651520000,
            692.25,
            692.6,
            692.25,
            692.4
        ],
        [
            1709651580000,
            692.6,
            692.95,
            692.55,
            692.75
        ],
        [
            1709651640000,
            692.35,
            692.8,
            691.95,
            691.95
        ],
        [
            1709651700000,
            691.95,
            692.35,
            691.95,
            692.35
        ],
        [
            1709651760000,
            692.15,
            693.1,
            692.15,
            693.1
        ],
        [
            1709651820000,
            693.3,
            693.5,
            693.15,
            693.5
        ],
        [
            1709651880000,
            693.6,
            693.75,
            692.35,
            692.7
        ],
        [
            1709651940000,
            692.95,
            693.65,
            692.95,
            693.45
        ],
        [
            1709652000000,
            693.45,
            694.55,
            693.45,
            693.6
        ],
        [
            1709652060000,
            693.7,
            694.5,
            693.7,
            694.35
        ],
        [
            1709652120000,
            694.35,
            694.75,
            694.05,
            694.4
        ],
        [
            1709652180000,
            694.35,
            694.5,
            693.95,
            694.2
        ],
        [
            1709652240000,
            693.7,
            694.75,
            693.7,
            694.35
        ],
        [
            1709652300000,
            694.35,
            694.35,
            692.7,
            693.05
        ],
        [
            1709652360000,
            693.4,
            693.6,
            693.1,
            693.6
        ],
        [
            1709652420000,
            692.35,
            692.35,
            691.25,
            691.25
        ],
        [
            1709652480000,
            691.25,
            691.45,
            690.3,
            691
        ],
        [
            1709652540000,
            690.05,
            691.25,
            690.05,
            691
        ],
        [
            1709652600000,
            691.95,
            691.95,
            691.95,
            691.95
        ],
        [
            1709716500000,
            692.9,
            692.9,
            688.45,
            688.5
        ],
        [
            1709716560000,
            688.15,
            688.9,
            686.9,
            688
        ],
        [
            1709716620000,
            687.25,
            688.05,
            687.25,
            688.05
        ],
        [
            1709716680000,
            687.35,
            687.35,
            686.45,
            686.45
        ],
        [
            1709716740000,
            686.3,
            687.6,
            686.3,
            687.6
        ],
        [
            1709716800000,
            687,
            687.5,
            687,
            687.1
        ],
        [
            1709716860000,
            687.45,
            687.55,
            687,
            687.55
        ],
        [
            1709716920000,
            687.55,
            687.95,
            687,
            687.35
        ],
        [
            1709716980000,
            687.35,
            687.65,
            687,
            687.4
        ],
        [
            1709717040000,
            686.75,
            687.65,
            686.75,
            687.15
        ],
        [
            1709717100000,
            687.6,
            688,
            687.4,
            688
        ],
        [
            1709717160000,
            688.05,
            688.05,
            687.55,
            688
        ],
        [
            1709717220000,
            687.5,
            687.75,
            687.2,
            687.25
        ],
        [
            1709717280000,
            687.95,
            688.1,
            687.6,
            687.6
        ],
        [
            1709717340000,
            687.5,
            688,
            687.5,
            688
        ],
        [
            1709717400000,
            688,
            688.3,
            688,
            688
        ],
        [
            1709717460000,
            688,
            688.2,
            687.1,
            687.1
        ],
        [
            1709717520000,
            687.05,
            687.05,
            685.6,
            685.65
        ],
        [
            1709717580000,
            686.15,
            686.15,
            685,
            685.2
        ],
        [
            1709717640000,
            685.35,
            685.5,
            685.2,
            685.5
        ],
        [
            1709717700000,
            685.25,
            685.8,
            684.6,
            685.8
        ],
        [
            1709717760000,
            685.8,
            686.25,
            685.45,
            685.45
        ],
        [
            1709717820000,
            685.25,
            685.85,
            685.05,
            685.7
        ],
        [
            1709717880000,
            686.05,
            686.7,
            685.55,
            686
        ],
        [
            1709717940000,
            685.65,
            686,
            684.75,
            685
        ],
        [
            1709718000000,
            685.1,
            686,
            684.35,
            685.05
        ],
        [
            1709718060000,
            685,
            685.6,
            684,
            685.2
        ],
        [
            1709718120000,
            684.9,
            686.6,
            684.6,
            686.6
        ],
        [
            1709718180000,
            686.55,
            686.75,
            686.05,
            686.45
        ],
        [
            1709718240000,
            686.45,
            687.6,
            686.45,
            687.2
        ],
        [
            1709718300000,
            687.95,
            687.95,
            685.6,
            685.85
        ],
        [
            1709718360000,
            686.15,
            686.95,
            686.15,
            686.5
        ],
        [
            1709718420000,
            686.5,
            686.85,
            686.5,
            686.5
        ],
        [
            1709718480000,
            686.5,
            687,
            686,
            686.35
        ],
        [
            1709718540000,
            686,
            686.45,
            686,
            686.45
        ],
        [
            1709718600000,
            686.15,
            686.15,
            685.55,
            685.75
        ],
        [
            1709718660000,
            686,
            686.15,
            685.1,
            685.75
        ],
        [
            1709718720000,
            685.75,
            685.75,
            685.1,
            685.55
        ],
        [
            1709718780000,
            685.1,
            685.15,
            683.7,
            684.4
        ],
        [
            1709718840000,
            684.15,
            684.6,
            684.15,
            684.5
        ],
        [
            1709718900000,
            684.5,
            684.5,
            683.2,
            683.2
        ],
        [
            1709718960000,
            683.3,
            683.4,
            681.8,
            683.4
        ],
        [
            1709719020000,
            683,
            683.2,
            682.95,
            682.95
        ],
        [
            1709719080000,
            682.95,
            684.8,
            682.55,
            684.8
        ],
        [
            1709719140000,
            684.25,
            684.25,
            682.55,
            682.55
        ],
        [
            1709719200000,
            682.45,
            682.45,
            679.6,
            679.6
        ],
        [
            1709719260000,
            680.8,
            680.95,
            680,
            680.2
        ],
        [
            1709719320000,
            680.75,
            680.8,
            680.1,
            680.25
        ],
        [
            1709719380000,
            680.35,
            681.6,
            680.35,
            680.85
        ],
        [
            1709719440000,
            680.85,
            682,
            680.85,
            681.3
        ],
        [
            1709719500000,
            681.65,
            681.65,
            680.05,
            680.05
        ],
        [
            1709719560000,
            680.05,
            680.55,
            678.9,
            680.55
        ],
        [
            1709719620000,
            680.65,
            681.4,
            680.15,
            681.05
        ],
        [
            1709719680000,
            681.4,
            682,
            681.4,
            681.95
        ],
        [
            1709719740000,
            681.65,
            682.25,
            681.65,
            682.25
        ],
        [
            1709719800000,
            681.9,
            682.25,
            681.65,
            681.65
        ],
        [
            1709719860000,
            681.55,
            682,
            681.1,
            682
        ],
        [
            1709719920000,
            681.95,
            682,
            681.6,
            682
        ],
        [
            1709719980000,
            682.35,
            682.8,
            682.35,
            682.8
        ],
        [
            1709720040000,
            682.8,
            683.1,
            682.7,
            682.95
        ],
        [
            1709720100000,
            683.1,
            683.1,
            682.5,
            682.5
        ],
        [
            1709720160000,
            682.5,
            683.25,
            682.5,
            683.25
        ],
        [
            1709720220000,
            683.25,
            683.9,
            683.1,
            683.75
        ],
        [
            1709720280000,
            683.8,
            683.9,
            683.55,
            683.85
        ],
        [
            1709720340000,
            683.85,
            683.85,
            683.1,
            683.6
        ],
        [
            1709720400000,
            683.9,
            683.95,
            683.6,
            683.6
        ],
        [
            1709720460000,
            683.5,
            683.5,
            683.05,
            683.3
        ],
        [
            1709720520000,
            683.3,
            683.6,
            683.15,
            683.25
        ],
        [
            1709720580000,
            683.25,
            683.25,
            682.5,
            682.5
        ],
        [
            1709720640000,
            682.9,
            682.9,
            681.3,
            681.35
        ],
        [
            1709720700000,
            681,
            681,
            680.65,
            680.85
        ],
        [
            1709720760000,
            681,
            681,
            680.75,
            681
        ],
        [
            1709720820000,
            681.15,
            681.15,
            680.2,
            680.2
        ],
        [
            1709720880000,
            680.2,
            681,
            680.2,
            680.7
        ],
        [
            1709720940000,
            680.7,
            680.95,
            680.7,
            680.95
        ],
        [
            1709721000000,
            680.4,
            680.95,
            680.35,
            680.75
        ],
        [
            1709721060000,
            680.75,
            681.2,
            680.35,
            681.2
        ],
        [
            1709721120000,
            680.65,
            681.05,
            680.65,
            680.95
        ],
        [
            1709721180000,
            681,
            681.75,
            681,
            681.75
        ],
        [
            1709721240000,
            681.7,
            682.45,
            681.7,
            682.15
        ],
        [
            1709721300000,
            682.1,
            682.55,
            681.8,
            682.25
        ],
        [
            1709721360000,
            682.2,
            682.2,
            681.15,
            681.15
        ],
        [
            1709721420000,
            681.4,
            681.6,
            680.55,
            680.55
        ],
        [
            1709721480000,
            680.95,
            681.15,
            680.1,
            680.6
        ],
        [
            1709721540000,
            680.6,
            680.6,
            680.3,
            680.6
        ],
        [
            1709721600000,
            679.65,
            679.8,
            679.05,
            679.05
        ],
        [
            1709721660000,
            679.1,
            680.05,
            679.1,
            679.55
        ],
        [
            1709721720000,
            680,
            680.5,
            680,
            680.5
        ],
        [
            1709721780000,
            680.2,
            680.8,
            680.2,
            680.8
        ],
        [
            1709721840000,
            680.8,
            681.45,
            680.6,
            681.4
        ],
        [
            1709721900000,
            681,
            681.5,
            681,
            681.1
        ],
        [
            1709721960000,
            681.1,
            681.5,
            680.85,
            681.05
        ],
        [
            1709722020000,
            681.05,
            681.25,
            680.9,
            681.25
        ],
        [
            1709722080000,
            681.45,
            681.45,
            680.7,
            680.8
        ],
        [
            1709722140000,
            680.35,
            680.65,
            680.35,
            680.65
        ],
        [
            1709722200000,
            680.6,
            680.6,
            680.45,
            680.45
        ],
        [
            1709722260000,
            680.5,
            680.5,
            680.05,
            680.05
        ],
        [
            1709722320000,
            680.45,
            680.45,
            679.8,
            679.8
        ],
        [
            1709722380000,
            679.95,
            680.25,
            679.5,
            680.2
        ],
        [
            1709722440000,
            680.15,
            680.3,
            679.95,
            680
        ],
        [
            1709722500000,
            680.25,
            680.25,
            679.85,
            680.25
        ],
        [
            1709722560000,
            680.25,
            680.25,
            680.25,
            680.25
        ],
        [
            1709722620000,
            680.3,
            680.35,
            680.15,
            680.15
        ],
        [
            1709722680000,
            680,
            680.15,
            680,
            680
        ],
        [
            1709722740000,
            680.1,
            680.4,
            680.05,
            680.4
        ],
        [
            1709722800000,
            680.4,
            680.7,
            680.2,
            680.55
        ],
        [
            1709722860000,
            680.4,
            680.55,
            680.1,
            680.55
        ],
        [
            1709722920000,
            680.55,
            681.2,
            680.2,
            681.2
        ],
        [
            1709722980000,
            681.2,
            681.2,
            680.75,
            681.1
        ],
        [
            1709723040000,
            681.1,
            681.2,
            680.95,
            681
        ],
        [
            1709723100000,
            681.05,
            681.05,
            680.95,
            681.05
        ],
        [
            1709723160000,
            681,
            681.1,
            680.95,
            681.1
        ],
        [
            1709723220000,
            680.95,
            681.05,
            680.95,
            681
        ],
        [
            1709723280000,
            680.7,
            681.05,
            680.7,
            681.05
        ],
        [
            1709723340000,
            681.05,
            681.25,
            681.05,
            681.25
        ],
        [
            1709723400000,
            681.3,
            681.35,
            681.3,
            681.35
        ],
        [
            1709723460000,
            681.35,
            681.35,
            681.35,
            681.35
        ],
        [
            1709723520000,
            680.95,
            681.3,
            680.25,
            680.8
        ],
        [
            1709723580000,
            680.95,
            680.95,
            680.95,
            680.95
        ],
        [
            1709723640000,
            680.95,
            680.95,
            680.65,
            680.95
        ],
        [
            1709723700000,
            680.95,
            680.95,
            680.5,
            680.7
        ],
        [
            1709723760000,
            680.7,
            680.7,
            679.45,
            679.95
        ],
        [
            1709723820000,
            679.65,
            680,
            678.85,
            679.95
        ],
        [
            1709723880000,
            679.95,
            679.95,
            679.35,
            679.8
        ],
        [
            1709723940000,
            679.8,
            680.15,
            679.55,
            680.15
        ],
        [
            1709724000000,
            680.15,
            680.2,
            680,
            680.2
        ],
        [
            1709724060000,
            680.25,
            680.45,
            680.05,
            680.45
        ],
        [
            1709724120000,
            680.4,
            680.45,
            680,
            680.4
        ],
        [
            1709724180000,
            680.4,
            680.4,
            680,
            680.35
        ],
        [
            1709724240000,
            680.35,
            680.35,
            679.95,
            680
        ],
        [
            1709724300000,
            680.05,
            680.15,
            680.05,
            680.1
        ],
        [
            1709724360000,
            680.1,
            680.35,
            680,
            680.3
        ],
        [
            1709724420000,
            680.3,
            680.5,
            680.3,
            680.5
        ],
        [
            1709724480000,
            680.45,
            681.1,
            680.45,
            681.1
        ],
        [
            1709724540000,
            681.1,
            681.35,
            681.1,
            681.35
        ],
        [
            1709724600000,
            681.35,
            681.45,
            681.35,
            681.45
        ],
        [
            1709724660000,
            681.45,
            681.75,
            680.8,
            680.95
        ],
        [
            1709724720000,
            680.75,
            680.75,
            680.25,
            680.25
        ],
        [
            1709724780000,
            680.45,
            680.45,
            679.1,
            680.05
        ],
        [
            1709724840000,
            680.05,
            681.2,
            680.05,
            680.1
        ],
        [
            1709724900000,
            680.4,
            680.45,
            680,
            680
        ],
        [
            1709724960000,
            680.25,
            680.25,
            680,
            680
        ],
        [
            1709725020000,
            680,
            680.3,
            680,
            680.05
        ],
        [
            1709725080000,
            679.9,
            680.2,
            679.9,
            680
        ],
        [
            1709725140000,
            680,
            680.2,
            680,
            680.15
        ],
        [
            1709725200000,
            680,
            680.1,
            680,
            680
        ],
        [
            1709725260000,
            679.75,
            680.05,
            679.75,
            680.05
        ],
        [
            1709725320000,
            680.1,
            680.35,
            680.1,
            680.35
        ],
        [
            1709725380000,
            680.2,
            680.25,
            680.05,
            680.25
        ],
        [
            1709725440000,
            680,
            680.05,
            680,
            680.05
        ],
        [
            1709725500000,
            680.05,
            680.3,
            680,
            680.3
        ],
        [
            1709725560000,
            680.25,
            680.25,
            680.25,
            680.25
        ],
        [
            1709725620000,
            680,
            680.2,
            680,
            680.2
        ],
        [
            1709725680000,
            680,
            680.4,
            680,
            680.05
        ],
        [
            1709725740000,
            680.35,
            680.4,
            680.3,
            680.4
        ],
        [
            1709725800000,
            680.4,
            680.45,
            679.9,
            680.45
        ],
        [
            1709725860000,
            680.45,
            680.5,
            680.45,
            680.5
        ],
        [
            1709725920000,
            680.45,
            680.5,
            680.3,
            680.5
        ],
        [
            1709725980000,
            680.15,
            681.05,
            680.15,
            681.05
        ],
        [
            1709726040000,
            681.05,
            681.05,
            681,
            681.05
        ],
        [
            1709726100000,
            681.05,
            681.05,
            680.8,
            681
        ],
        [
            1709726160000,
            681.15,
            681.15,
            681,
            681
        ],
        [
            1709726220000,
            681,
            681.15,
            681,
            681.15
        ],
        [
            1709726280000,
            681.3,
            681.4,
            680.75,
            680.8
        ],
        [
            1709726340000,
            681.35,
            681.35,
            681.05,
            681.2
        ],
        [
            1709726400000,
            680.55,
            681.05,
            680.55,
            681.05
        ],
        [
            1709726460000,
            681.1,
            681.1,
            680,
            680
        ],
        [
            1709726520000,
            680,
            680.2,
            679.8,
            680
        ],
        [
            1709726580000,
            680,
            680.15,
            680,
            680
        ],
        [
            1709726640000,
            680,
            680,
            680,
            680
        ],
        [
            1709726700000,
            680,
            680,
            679.95,
            679.95
        ],
        [
            1709726760000,
            680,
            680,
            680,
            680
        ],
        [
            1709726820000,
            679.8,
            680.05,
            679.8,
            680
        ],
        [
            1709726880000,
            680,
            680,
            679.5,
            680
        ],
        [
            1709726940000,
            680,
            680,
            679.65,
            679.7
        ],
        [
            1709727000000,
            679.75,
            680.05,
            678.6,
            680
        ],
        [
            1709727060000,
            680,
            680.05,
            680,
            680
        ],
        [
            1709727120000,
            680,
            680,
            680,
            680
        ],
        [
            1709727180000,
            680,
            680.05,
            680,
            680
        ],
        [
            1709727240000,
            680,
            680.1,
            680,
            680
        ],
        [
            1709727300000,
            680,
            680.1,
            680,
            680
        ],
        [
            1709727360000,
            680,
            680.25,
            680,
            680.05
        ],
        [
            1709727420000,
            680,
            680.4,
            679,
            679
        ],
        [
            1709727480000,
            679,
            679.6,
            678.85,
            679
        ],
        [
            1709727540000,
            679,
            679.4,
            679,
            679.15
        ],
        [
            1709727600000,
            679.15,
            679.5,
            678.8,
            679.05
        ],
        [
            1709727660000,
            679.05,
            679.35,
            679,
            679
        ],
        [
            1709727720000,
            679,
            680.4,
            679,
            679.95
        ],
        [
            1709727780000,
            679.9,
            680.1,
            679.3,
            679.7
        ],
        [
            1709727840000,
            679.9,
            680.35,
            679.8,
            680
        ],
        [
            1709727900000,
            680,
            680.3,
            680,
            680
        ],
        [
            1709727960000,
            680,
            680.2,
            679.8,
            680
        ],
        [
            1709728020000,
            680,
            680.2,
            680,
            680
        ],
        [
            1709728080000,
            680.35,
            680.4,
            680,
            680.1
        ],
        [
            1709728140000,
            680.1,
            680.5,
            680,
            680.1
        ],
        [
            1709728200000,
            680.1,
            680.6,
            680.1,
            680.3
        ],
        [
            1709728260000,
            680.6,
            680.6,
            680.1,
            680.3
        ],
        [
            1709728320000,
            680.2,
            680.35,
            680.1,
            680.1
        ],
        [
            1709728380000,
            680.1,
            680.45,
            680.1,
            680.15
        ],
        [
            1709728440000,
            680.15,
            680.55,
            680.1,
            680.1
        ],
        [
            1709728500000,
            680.1,
            680.4,
            680.1,
            680.1
        ],
        [
            1709728560000,
            680.1,
            680.45,
            680.1,
            680.1
        ],
        [
            1709728620000,
            680.1,
            680.7,
            680.1,
            680.35
        ],
        [
            1709728680000,
            680.7,
            681.3,
            680.15,
            681.05
        ],
        [
            1709728740000,
            681.05,
            681.35,
            680.9,
            680.9
        ],
        [
            1709728800000,
            680.9,
            681.4,
            680.9,
            681.4
        ],
        [
            1709728860000,
            681.5,
            681.5,
            680.6,
            680.8
        ],
        [
            1709728920000,
            680.8,
            681.3,
            680.8,
            681
        ],
        [
            1709728980000,
            681,
            681.25,
            681,
            681.2
        ],
        [
            1709729040000,
            681,
            681.1,
            680.7,
            681.1
        ],
        [
            1709729100000,
            680.85,
            681.25,
            680.6,
            681.25
        ],
        [
            1709729160000,
            681.25,
            682.45,
            681.25,
            682.45
        ],
        [
            1709729220000,
            683.5,
            683.9,
            683.25,
            683.7
        ],
        [
            1709729280000,
            683.3,
            683.9,
            683.3,
            683.9
        ],
        [
            1709729340000,
            684.2,
            684.2,
            683.5,
            684
        ],
        [
            1709729400000,
            684,
            684.35,
            684,
            684.35
        ],
        [
            1709729460000,
            684.1,
            684.45,
            684.1,
            684.4
        ],
        [
            1709729520000,
            684.4,
            685,
            684,
            685
        ],
        [
            1709729580000,
            684.45,
            684.95,
            684.45,
            684.95
        ],
        [
            1709729640000,
            684.9,
            684.9,
            684.5,
            684.8
        ],
        [
            1709729700000,
            684.3,
            684.8,
            684.15,
            684.15
        ],
        [
            1709729760000,
            684.2,
            684.25,
            683.75,
            684.2
        ],
        [
            1709729820000,
            684.35,
            684.35,
            683.85,
            684.15
        ],
        [
            1709729880000,
            683.65,
            684,
            683.6,
            684
        ],
        [
            1709729940000,
            684,
            684,
            683.5,
            683.9
        ],
        [
            1709730000000,
            683.5,
            684.15,
            683.5,
            684
        ],
        [
            1709730060000,
            683.95,
            683.95,
            683.8,
            683.85
        ],
        [
            1709730120000,
            683.8,
            683.8,
            683.8,
            683.8
        ],
        [
            1709730180000,
            683.75,
            683.95,
            683.5,
            683.6
        ],
        [
            1709730240000,
            683.6,
            683.65,
            683.35,
            683.65
        ],
        [
            1709730300000,
            683.2,
            683.85,
            683.2,
            683.85
        ],
        [
            1709730360000,
            683.75,
            683.95,
            683.7,
            683.95
        ],
        [
            1709730420000,
            683.55,
            684.35,
            683.55,
            684.35
        ],
        [
            1709730480000,
            684.8,
            685,
            684.35,
            684.35
        ],
        [
            1709730540000,
            684.7,
            685,
            684.7,
            685
        ],
        [
            1709730600000,
            685,
            685,
            685,
            685
        ],
        [
            1709730660000,
            685,
            685,
            685,
            685
        ],
        [
            1709730720000,
            685,
            685,
            684.8,
            684.8
        ],
        [
            1709730780000,
            685,
            686.3,
            685,
            686
        ],
        [
            1709730840000,
            686,
            686.65,
            686,
            686.1
        ],
        [
            1709730900000,
            686.6,
            686.6,
            685.7,
            685.7
        ],
        [
            1709730960000,
            685.55,
            686.05,
            685.55,
            685.6
        ],
        [
            1709731020000,
            685.35,
            686.15,
            685.2,
            685.65
        ],
        [
            1709731080000,
            685.65,
            685.7,
            685.1,
            685.1
        ],
        [
            1709731140000,
            685.15,
            686.3,
            685.15,
            686.3
        ],
        [
            1709731200000,
            686.05,
            686.25,
            686,
            686
        ],
        [
            1709731260000,
            686.15,
            686.3,
            686,
            686
        ],
        [
            1709731320000,
            686.05,
            686.35,
            685.8,
            686
        ],
        [
            1709731380000,
            686.4,
            686.65,
            686,
            686.65
        ],
        [
            1709731440000,
            686,
            686.35,
            686,
            686
        ],
        [
            1709731500000,
            686.5,
            686.7,
            686.1,
            686.2
        ],
        [
            1709731560000,
            686.1,
            686.75,
            685.9,
            685.9
        ],
        [
            1709731620000,
            685.9,
            686.55,
            685.9,
            686.45
        ],
        [
            1709731680000,
            686,
            686.45,
            685.95,
            685.95
        ],
        [
            1709731740000,
            685.9,
            686.2,
            685.85,
            686.2
        ],
        [
            1709731800000,
            686.15,
            686.15,
            685.1,
            685.1
        ],
        [
            1709731860000,
            685.1,
            685.45,
            685,
            685.15
        ],
        [
            1709731920000,
            685,
            686.05,
            685,
            685.8
        ],
        [
            1709731980000,
            685.3,
            685.6,
            685.05,
            685.3
        ],
        [
            1709732040000,
            685.25,
            685.3,
            685,
            685
        ],
        [
            1709732100000,
            685,
            686.55,
            685,
            686.15
        ],
        [
            1709732160000,
            685.9,
            686.45,
            685.9,
            686.25
        ],
        [
            1709732220000,
            686.6,
            686.6,
            686.1,
            686.1
        ],
        [
            1709732280000,
            686.1,
            686.95,
            686,
            686.95
        ],
        [
            1709732340000,
            686.3,
            686.95,
            686,
            686.75
        ],
        [
            1709732400000,
            686.75,
            687.2,
            686.75,
            687.2
        ],
        [
            1709732460000,
            687.2,
            687.4,
            686.9,
            687.4
        ],
        [
            1709732520000,
            687.4,
            687.9,
            687.2,
            687.35
        ],
        [
            1709732580000,
            687.3,
            687.6,
            687.1,
            687.6
        ],
        [
            1709732640000,
            687.6,
            687.8,
            687.3,
            687.35
        ],
        [
            1709732700000,
            687.7,
            687.85,
            687.4,
            687.4
        ],
        [
            1709732760000,
            687.85,
            687.85,
            687.85,
            687.85
        ],
        [
            1709732820000,
            688,
            688.25,
            687.5,
            688.25
        ],
        [
            1709732880000,
            688.25,
            688.25,
            687.4,
            687.5
        ],
        [
            1709732940000,
            687.85,
            688.5,
            687.55,
            688.2
        ],
        [
            1709733000000,
            687.7,
            687.9,
            687.45,
            687.5
        ],
        [
            1709733060000,
            687.9,
            687.9,
            687.5,
            687.55
        ],
        [
            1709733120000,
            687.9,
            688,
            686.85,
            687.65
        ],
        [
            1709733180000,
            687.75,
            687.75,
            686.95,
            687.55
        ],
        [
            1709733240000,
            687.45,
            687.5,
            687.45,
            687.5
        ],
        [
            1709733300000,
            687.5,
            688.05,
            687.5,
            688.05
        ],
        [
            1709733360000,
            688.2,
            688.2,
            687.55,
            687.6
        ],
        [
            1709733420000,
            688.6,
            688.6,
            687.65,
            687.95
        ],
        [
            1709733480000,
            687.6,
            688.45,
            687.6,
            687.7
        ],
        [
            1709733540000,
            688.15,
            688.5,
            687.75,
            687.75
        ],
        [
            1709733600000,
            687.55,
            688.15,
            687.55,
            688
        ],
        [
            1709733660000,
            687.7,
            688.25,
            687.6,
            688.25
        ],
        [
            1709733720000,
            687.75,
            688.5,
            687.75,
            688.35
        ],
        [
            1709733780000,
            688.2,
            688.45,
            687.75,
            688.45
        ],
        [
            1709733840000,
            688.45,
            688.45,
            688.05,
            688.05
        ],
        [
            1709733900000,
            688.45,
            688.75,
            687.95,
            687.95
        ],
        [
            1709733960000,
            688.5,
            688.6,
            687.95,
            688.25
        ],
        [
            1709734020000,
            688.25,
            688.6,
            688.2,
            688.6
        ],
        [
            1709734080000,
            689.25,
            689.25,
            688.2,
            688.2
        ],
        [
            1709734140000,
            688.6,
            688.9,
            688.25,
            688.9
        ],
        [
            1709734200000,
            688.3,
            688.95,
            688.25,
            688.95
        ],
        [
            1709734260000,
            688.8,
            689.25,
            688.75,
            689.25
        ],
        [
            1709734320000,
            689.05,
            689.4,
            688.1,
            688.1
        ],
        [
            1709734380000,
            688.6,
            688.6,
            688.3,
            688.5
        ],
        [
            1709734440000,
            688.9,
            688.9,
            688.35,
            688.85
        ],
        [
            1709734500000,
            688.4,
            688.9,
            688.35,
            688.5
        ],
        [
            1709734560000,
            688.85,
            688.85,
            688.3,
            688.3
        ],
        [
            1709734620000,
            688.35,
            688.85,
            688.25,
            688.8
        ],
        [
            1709734680000,
            688.8,
            688.8,
            688.4,
            688.4
        ],
        [
            1709734740000,
            688.75,
            688.75,
            688.25,
            688.25
        ],
        [
            1709734800000,
            688.45,
            688.45,
            688.15,
            688.45
        ],
        [
            1709734860000,
            688.2,
            688.9,
            688,
            688.1
        ],
        [
            1709734920000,
            687.9,
            688.85,
            687.85,
            688.85
        ],
        [
            1709734980000,
            688.45,
            688.45,
            687.85,
            688.45
        ],
        [
            1709735040000,
            688.45,
            688.9,
            688.25,
            688.25
        ],
        [
            1709735100000,
            688.05,
            688.1,
            687.7,
            687.7
        ],
        [
            1709735160000,
            688.1,
            688.5,
            687.85,
            687.9
        ],
        [
            1709735220000,
            687.95,
            688.75,
            687.95,
            687.95
        ],
        [
            1709735280000,
            687.95,
            688.4,
            687.8,
            688.3
        ],
        [
            1709735340000,
            688.5,
            688.8,
            687.9,
            688.8
        ],
        [
            1709735400000,
            687.8,
            688,
            687.65,
            688
        ],
        [
            1709735460000,
            687.9,
            688,
            687,
            687.3
        ],
        [
            1709735520000,
            687.35,
            687.35,
            686,
            686
        ],
        [
            1709735580000,
            686.05,
            686.95,
            686,
            686.65
        ],
        [
            1709735640000,
            686.65,
            687.3,
            686.5,
            687.3
        ],
        [
            1709735700000,
            687.8,
            688.2,
            687.45,
            688.2
        ],
        [
            1709735760000,
            688.25,
            688.5,
            688.05,
            688.05
        ],
        [
            1709735820000,
            688.3,
            688.3,
            687.6,
            687.6
        ],
        [
            1709735880000,
            688.45,
            688.45,
            687.7,
            688.15
        ],
        [
            1709735940000,
            687.75,
            688.5,
            687.7,
            687.7
        ],
        [
            1709736000000,
            688.3,
            688.8,
            688.25,
            688.25
        ],
        [
            1709736060000,
            688.8,
            689.2,
            688.35,
            689.1
        ],
        [
            1709736120000,
            688.7,
            688.9,
            688.25,
            688.8
        ],
        [
            1709736180000,
            688.8,
            688.8,
            688.55,
            688.8
        ],
        [
            1709736240000,
            688.5,
            689.05,
            688.5,
            688.7
        ],
        [
            1709736300000,
            688.8,
            689.5,
            688.65,
            689
        ],
        [
            1709736360000,
            688.5,
            688.95,
            688.25,
            688.5
        ],
        [
            1709736420000,
            688.5,
            688.5,
            687.95,
            688.05
        ],
        [
            1709736480000,
            688,
            688.4,
            687.75,
            687.85
        ],
        [
            1709736540000,
            688.5,
            688.5,
            688.05,
            688.05
        ],
        [
            1709736600000,
            688.45,
            688.5,
            688.3,
            688.3
        ],
        [
            1709736660000,
            688.2,
            688.7,
            688.2,
            688.65
        ],
        [
            1709736720000,
            687.95,
            688.6,
            687.95,
            688.3
        ],
        [
            1709736780000,
            689,
            689,
            688.3,
            688.4
        ],
        [
            1709736840000,
            688.2,
            688.7,
            688.15,
            688.7
        ],
        [
            1709736900000,
            688.2,
            688.65,
            688.15,
            688.15
        ],
        [
            1709736960000,
            688.15,
            688.9,
            688.1,
            688.1
        ],
        [
            1709737020000,
            688.25,
            688.7,
            688,
            688.4
        ],
        [
            1709737080000,
            688.75,
            688.9,
            688.75,
            688.9
        ],
        [
            1709737140000,
            688.9,
            689,
            688.05,
            688.1
        ],
        [
            1709737200000,
            688.15,
            689,
            688.15,
            689
        ],
        [
            1709737260000,
            689,
            689.8,
            689,
            689.8
        ],
        [
            1709737320000,
            689.7,
            689.75,
            688.7,
            689
        ],
        [
            1709737380000,
            688.75,
            689.25,
            688.75,
            689.25
        ],
        [
            1709737440000,
            689.35,
            689.8,
            689.2,
            689.8
        ],
        [
            1709737500000,
            689.7,
            690,
            689.7,
            690
        ],
        [
            1709737560000,
            689.8,
            690.65,
            689.8,
            690.55
        ],
        [
            1709737620000,
            690.5,
            690.9,
            689.1,
            689.1
        ],
        [
            1709737680000,
            689.5,
            689.5,
            688.9,
            689.3
        ],
        [
            1709737740000,
            689.1,
            689.55,
            689.1,
            689.45
        ],
        [
            1709737800000,
            690,
            690.05,
            689.1,
            689.2
        ],
        [
            1709737860000,
            689,
            689,
            688.5,
            688.9
        ],
        [
            1709737920000,
            689.7,
            689.7,
            689.2,
            689.2
        ],
        [
            1709737980000,
            689.1,
            689.2,
            688.5,
            688.95
        ],
        [
            1709738040000,
            688.7,
            688.95,
            687.9,
            687.9
        ],
        [
            1709738100000,
            687.7,
            688.25,
            687.7,
            687.7
        ],
        [
            1709738160000,
            687.5,
            687.8,
            687.5,
            687.8
        ],
        [
            1709738220000,
            687.5,
            687.8,
            687.5,
            687.5
        ],
        [
            1709738280000,
            687.55,
            687.55,
            686.85,
            687.2
        ],
        [
            1709738340000,
            687.2,
            687.2,
            686.85,
            686.85
        ],
        [
            1709738400000,
            686.5,
            687.2,
            686.4,
            687.2
        ],
        [
            1709738460000,
            687.25,
            687.55,
            687.2,
            687.55
        ],
        [
            1709738520000,
            687.55,
            688,
            687.55,
            687.85
        ],
        [
            1709738580000,
            687.7,
            687.9,
            687.7,
            687.75
        ],
        [
            1709738640000,
            687.7,
            687.95,
            687.7,
            687.9
        ],
        [
            1709738700000,
            687.75,
            687.75,
            687.1,
            687.5
        ],
        [
            1709738760000,
            687.4,
            687.4,
            686.15,
            687
        ],
        [
            1709738820000,
            686.85,
            687.4,
            686.05,
            686.4
        ],
        [
            1709738880000,
            686.25,
            689.15,
            686.05,
            686.35
        ],
        [
            1709738940000,
            686.35,
            687,
            686,
            686.1
        ],
        [
            1709739000000,
            686.1,
            686.1,
            686.1,
            686.1
        ],
        [
            1709802900000,
            691.45,
            691.45,
            683.75,
            685.35
        ],
        [
            1709802960000,
            684.6,
            685.4,
            684.6,
            685.2
        ],
        [
            1709803020000,
            685.2,
            686.3,
            685.05,
            686.3
        ],
        [
            1709803080000,
            685.7,
            685.7,
            685.2,
            685.65
        ],
        [
            1709803140000,
            685.25,
            686.3,
            685.25,
            686.2
        ],
        [
            1709803200000,
            685.85,
            686.65,
            684.3,
            684.3
        ],
        [
            1709803260000,
            684.7,
            684.7,
            684.15,
            684.15
        ],
        [
            1709803320000,
            683.7,
            685.75,
            683.7,
            685.7
        ],
        [
            1709803380000,
            685.7,
            687.65,
            685.7,
            687.65
        ],
        [
            1709803440000,
            687,
            687.55,
            686.95,
            687.05
        ],
        [
            1709803500000,
            687,
            687.6,
            686.7,
            687.6
        ],
        [
            1709803560000,
            687.55,
            688.25,
            687.4,
            687.7
        ],
        [
            1709803620000,
            688.05,
            688.05,
            686.15,
            686.9
        ],
        [
            1709803680000,
            687.05,
            687.3,
            686.25,
            686.25
        ],
        [
            1709803740000,
            686.2,
            686.75,
            686.2,
            686.75
        ],
        [
            1709803800000,
            686.9,
            687.3,
            686.9,
            687
        ],
        [
            1709803860000,
            687,
            687.5,
            686.35,
            686.35
        ],
        [
            1709803920000,
            686.65,
            686.65,
            686,
            686.15
        ],
        [
            1709803980000,
            685.65,
            686.8,
            685.65,
            686.8
        ],
        [
            1709804040000,
            686.3,
            686.3,
            686.1,
            686.1
        ],
        [
            1709804100000,
            686.1,
            686.2,
            685.55,
            685.55
        ],
        [
            1709804160000,
            685.55,
            686.05,
            685.55,
            685.65
        ],
        [
            1709804220000,
            686.05,
            686.05,
            685,
            685
        ],
        [
            1709804280000,
            684.75,
            684.95,
            684.35,
            684.55
        ],
        [
            1709804340000,
            685.15,
            685.2,
            684.95,
            685.2
        ],
        [
            1709804400000,
            685.2,
            685.45,
            685,
            685
        ],
        [
            1709804460000,
            684.9,
            685,
            684.55,
            684.8
        ],
        [
            1709804520000,
            684.85,
            685.2,
            684.85,
            685.15
        ],
        [
            1709804580000,
            685.2,
            685.45,
            684.8,
            685.35
        ],
        [
            1709804640000,
            685,
            685.6,
            684.65,
            685
        ],
        [
            1709804700000,
            685,
            685,
            684.25,
            684.7
        ],
        [
            1709804760000,
            684.8,
            685,
            684.8,
            684.8
        ],
        [
            1709804820000,
            685,
            685,
            684.8,
            684.8
        ],
        [
            1709804880000,
            684.8,
            684.9,
            684.1,
            684.9
        ],
        [
            1709804940000,
            684.9,
            685.45,
            684.9,
            685
        ],
        [
            1709805000000,
            685.25,
            685.25,
            684.8,
            685.2
        ],
        [
            1709805060000,
            684.8,
            685.35,
            684.7,
            685
        ],
        [
            1709805120000,
            685.4,
            685.4,
            685.1,
            685.1
        ],
        [
            1709805180000,
            685.35,
            685.4,
            685.05,
            685.05
        ],
        [
            1709805240000,
            685.4,
            685.8,
            685.4,
            685.75
        ],
        [
            1709805300000,
            685.65,
            685.8,
            685.05,
            685.8
        ],
        [
            1709805360000,
            685.8,
            686.8,
            685.6,
            686.8
        ],
        [
            1709805420000,
            686.25,
            686.65,
            685.2,
            685.5
        ],
        [
            1709805480000,
            685.45,
            685.8,
            685.3,
            685.35
        ],
        [
            1709805540000,
            685.75,
            686.25,
            685.4,
            686.25
        ],
        [
            1709805600000,
            686.15,
            686.15,
            685.6,
            685.6
        ],
        [
            1709805660000,
            685.6,
            686.05,
            685.6,
            686.05
        ],
        [
            1709805720000,
            685.8,
            686.05,
            685.8,
            685.85
        ],
        [
            1709805780000,
            685.85,
            685.85,
            685.55,
            685.55
        ],
        [
            1709805840000,
            685.55,
            685.7,
            685.4,
            685.7
        ],
        [
            1709805900000,
            685.4,
            685.5,
            685.4,
            685.45
        ],
        [
            1709805960000,
            685.4,
            685.4,
            685.4,
            685.4
        ],
        [
            1709806020000,
            685.7,
            685.8,
            685.2,
            685.2
        ],
        [
            1709806080000,
            685.15,
            685.6,
            685.1,
            685.1
        ],
        [
            1709806140000,
            685.65,
            685.65,
            685.65,
            685.65
        ],
        [
            1709806200000,
            685.15,
            685.55,
            685.1,
            685.1
        ],
        [
            1709806260000,
            685.2,
            685.6,
            685.2,
            685.3
        ],
        [
            1709806320000,
            685.35,
            685.6,
            685.35,
            685.6
        ],
        [
            1709806380000,
            685.6,
            685.6,
            685.4,
            685.6
        ],
        [
            1709806440000,
            685.6,
            685.6,
            684.2,
            684.7
        ],
        [
            1709806500000,
            684.4,
            685.1,
            684.4,
            685
        ],
        [
            1709806560000,
            685,
            685.05,
            684.6,
            685.05
        ],
        [
            1709806620000,
            684.6,
            684.85,
            684.4,
            684.4
        ],
        [
            1709806680000,
            684.8,
            685.55,
            684.4,
            685.25
        ],
        [
            1709806740000,
            685.65,
            685.65,
            685,
            685.4
        ],
        [
            1709806800000,
            685,
            685.3,
            684.9,
            684.9
        ],
        [
            1709806860000,
            684.75,
            685.25,
            684.75,
            685.25
        ],
        [
            1709806920000,
            685,
            685,
            685,
            685
        ],
        [
            1709806980000,
            685.25,
            685.25,
            685,
            685
        ],
        [
            1709807040000,
            684.95,
            686.8,
            684.95,
            686.3
        ],
        [
            1709807100000,
            686.3,
            686.3,
            685.75,
            686.25
        ],
        [
            1709807160000,
            686,
            687,
            686,
            686.55
        ],
        [
            1709807220000,
            686.95,
            686.95,
            686,
            686.45
        ],
        [
            1709807280000,
            686.7,
            686.85,
            686.7,
            686.8
        ],
        [
            1709807340000,
            686.75,
            687.5,
            686.75,
            687.5
        ],
        [
            1709807400000,
            687.7,
            688,
            687.3,
            687.35
        ],
        [
            1709807460000,
            687.4,
            687.85,
            687.4,
            687.85
        ],
        [
            1709807520000,
            687.85,
            687.85,
            687.4,
            687.5
        ],
        [
            1709807580000,
            687.5,
            688.5,
            687.5,
            688.35
        ],
        [
            1709807640000,
            688.6,
            689.55,
            688.3,
            688.6
        ],
        [
            1709807700000,
            688.55,
            688.95,
            688.3,
            688.35
        ],
        [
            1709807760000,
            688.95,
            689,
            688.3,
            688.3
        ],
        [
            1709807820000,
            688.3,
            688.7,
            688.2,
            688.5
        ],
        [
            1709807880000,
            689,
            689,
            688.5,
            688.5
        ],
        [
            1709807940000,
            688.5,
            689,
            688.5,
            688.65
        ],
        [
            1709808000000,
            689,
            689.75,
            689,
            689.25
        ],
        [
            1709808060000,
            689.7,
            691,
            689.7,
            690.45
        ],
        [
            1709808120000,
            690.35,
            690.7,
            690.1,
            690.45
        ],
        [
            1709808180000,
            690.95,
            692.3,
            690.95,
            692.3
        ],
        [
            1709808240000,
            693.35,
            693.35,
            690,
            691
        ],
        [
            1709808300000,
            691,
            691,
            689.35,
            689.45
        ],
        [
            1709808360000,
            689.4,
            689.9,
            689.4,
            689.4
        ],
        [
            1709808480000,
            690,
            690.85,
            689.7,
            690.75
        ],
        [
            1709808540000,
            690.1,
            690.3,
            689.7,
            690.3
        ],
        [
            1709808600000,
            690.5,
            690.5,
            689.7,
            690
        ],
        [
            1709808660000,
            690.4,
            690.5,
            689.6,
            689.6
        ],
        [
            1709808720000,
            689.85,
            689.9,
            689.4,
            689.9
        ],
        [
            1709808780000,
            689.95,
            690.35,
            689.8,
            690.35
        ],
        [
            1709808840000,
            689.9,
            690.2,
            689.9,
            690.2
        ],
        [
            1709808900000,
            689.8,
            690.35,
            689.4,
            689.4
        ],
        [
            1709808960000,
            689.2,
            689.2,
            688.55,
            688.75
        ],
        [
            1709809020000,
            688.85,
            689.15,
            688.1,
            688.9
        ],
        [
            1709809080000,
            689.1,
            689.6,
            689.1,
            689.6
        ],
        [
            1709809140000,
            689.6,
            690.05,
            689.15,
            690.05
        ],
        [
            1709809200000,
            689.1,
            689.95,
            689,
            689.95
        ],
        [
            1709809260000,
            689.95,
            689.95,
            688.85,
            689
        ],
        [
            1709809320000,
            689,
            689.1,
            688.95,
            688.95
        ],
        [
            1709809380000,
            688.95,
            688.95,
            688.5,
            688.5
        ],
        [
            1709809440000,
            688.15,
            688.5,
            687.5,
            687.6
        ],
        [
            1709809500000,
            688,
            688,
            687.05,
            687.55
        ],
        [
            1709809560000,
            687.15,
            687.5,
            686.35,
            686.4
        ],
        [
            1709809620000,
            686.45,
            687,
            686.35,
            686.95
        ],
        [
            1709809680000,
            686.95,
            689.15,
            686.75,
            689.15
        ],
        [
            1709809740000,
            689.1,
            689.6,
            688.55,
            689.1
        ],
        [
            1709809800000,
            689.55,
            689.7,
            689.1,
            689.55
        ],
        [
            1709809860000,
            689.5,
            690.3,
            689.3,
            690.25
        ],
        [
            1709809920000,
            690.05,
            690.3,
            689.85,
            689.95
        ],
        [
            1709809980000,
            689.95,
            690.3,
            689.95,
            690.25
        ],
        [
            1709810040000,
            689.8,
            689.8,
            688.75,
            688.8
        ],
        [
            1709810100000,
            688.9,
            689.5,
            688.85,
            688.85
        ],
        [
            1709810160000,
            689.4,
            689.75,
            688.85,
            689.75
        ],
        [
            1709810220000,
            689.5,
            689.65,
            688.95,
            689.55
        ],
        [
            1709810280000,
            689.1,
            689.55,
            689,
            689.1
        ],
        [
            1709810340000,
            689.65,
            689.75,
            688.9,
            688.9
        ],
        [
            1709810400000,
            689.3,
            689.3,
            688.9,
            688.9
        ],
        [
            1709810460000,
            688.9,
            689.25,
            688.65,
            689.1
        ],
        [
            1709810520000,
            689,
            689.15,
            688.85,
            689
        ],
        [
            1709810580000,
            689.05,
            689.25,
            688.65,
            688.7
        ],
        [
            1709810640000,
            688.75,
            688.95,
            688.7,
            688.7
        ],
        [
            1709810700000,
            688.45,
            688.45,
            687.5,
            687.85
        ],
        [
            1709810760000,
            688.2,
            688.3,
            687.7,
            688.2
        ],
        [
            1709810820000,
            687.8,
            688.1,
            687.55,
            687.9
        ],
        [
            1709810880000,
            687.9,
            688.15,
            687.9,
            687.95
        ],
        [
            1709810940000,
            688.1,
            688.1,
            687.95,
            688.05
        ],
        [
            1709811000000,
            688,
            688.2,
            688,
            688.1
        ],
        [
            1709811060000,
            688.1,
            688.8,
            688,
            688.4
        ],
        [
            1709811120000,
            688.65,
            688.95,
            688.4,
            688.7
        ],
        [
            1709811180000,
            688.55,
            688.65,
            688.45,
            688.5
        ],
        [
            1709811240000,
            688.45,
            688.85,
            688.45,
            688.5
        ],
        [
            1709811300000,
            688.95,
            688.95,
            688.5,
            688.7
        ],
        [
            1709811360000,
            688.55,
            688.95,
            688.5,
            688.65
        ],
        [
            1709811420000,
            688.7,
            688.95,
            688.55,
            688.6
        ],
        [
            1709811480000,
            688.7,
            689.15,
            688.6,
            688.75
        ],
        [
            1709811540000,
            688.55,
            689.15,
            688.4,
            688.55
        ],
        [
            1709811600000,
            688.7,
            688.7,
            688.45,
            688.45
        ],
        [
            1709811660000,
            688.6,
            688.6,
            687.9,
            687.9
        ],
        [
            1709811720000,
            687.7,
            687.8,
            687.2,
            687.8
        ],
        [
            1709811780000,
            687.9,
            688.6,
            687.15,
            687.55
        ],
        [
            1709811840000,
            687.8,
            688.1,
            687.5,
            687.6
        ],
        [
            1709811900000,
            687.6,
            687.85,
            687.6,
            687.85
        ],
        [
            1709811960000,
            687.85,
            688.15,
            687.65,
            688
        ],
        [
            1709812020000,
            688.2,
            688.2,
            687.45,
            687.55
        ],
        [
            1709812080000,
            687.45,
            687.95,
            687.45,
            687.55
        ],
        [
            1709812140000,
            687.55,
            687.85,
            686.9,
            686.9
        ],
        [
            1709812200000,
            686.9,
            687.25,
            686.45,
            686.9
        ],
        [
            1709812260000,
            686.65,
            686.95,
            686.4,
            686.4
        ],
        [
            1709812320000,
            686.4,
            687.05,
            686.4,
            686.65
        ],
        [
            1709812380000,
            686.95,
            686.95,
            686.5,
            686.7
        ],
        [
            1709812440000,
            686.6,
            686.85,
            686.45,
            686.8
        ],
        [
            1709812500000,
            686.85,
            686.85,
            686.5,
            686.55
        ],
        [
            1709812560000,
            686.7,
            686.7,
            686.25,
            686.5
        ],
        [
            1709812620000,
            686.35,
            686.45,
            686.2,
            686.4
        ],
        [
            1709812680000,
            686.25,
            686.6,
            686.25,
            686.35
        ],
        [
            1709812740000,
            686.55,
            686.95,
            686.25,
            686.25
        ],
        [
            1709812800000,
            686.25,
            687,
            686.25,
            686.5
        ],
        [
            1709812860000,
            686.85,
            686.95,
            685.05,
            685.05
        ],
        [
            1709812920000,
            685.25,
            690.1,
            685.05,
            690.1
        ],
        [
            1709812980000,
            690,
            690.1,
            689.15,
            689.35
        ],
        [
            1709813040000,
            689.75,
            690.1,
            689.35,
            690
        ],
        [
            1709813100000,
            690.1,
            690.2,
            690,
            690.2
        ],
        [
            1709813160000,
            690.15,
            690.25,
            690,
            690.25
        ],
        [
            1709813220000,
            690.05,
            690.25,
            690,
            690.05
        ],
        [
            1709813280000,
            690.25,
            690.25,
            690,
            690.15
        ],
        [
            1709813340000,
            690,
            690.2,
            690,
            690
        ],
        [
            1709813400000,
            690,
            690.7,
            690,
            690.5
        ],
        [
            1709813460000,
            690.45,
            690.55,
            690.05,
            690.5
        ],
        [
            1709813520000,
            690.45,
            690.9,
            690.45,
            690.55
        ],
        [
            1709813580000,
            690.55,
            691.4,
            690.55,
            690.85
        ],
        [
            1709813640000,
            691.1,
            692.45,
            690.8,
            692.05
        ],
        [
            1709813700000,
            692.45,
            693.1,
            692.1,
            692.35
        ],
        [
            1709813760000,
            692.2,
            692.4,
            692,
            692.2
        ],
        [
            1709813820000,
            692.2,
            692.2,
            692,
            692
        ],
        [
            1709813880000,
            692,
            692.15,
            692,
            692
        ],
        [
            1709813940000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709814000000,
            692,
            692.2,
            692,
            692.15
        ],
        [
            1709814060000,
            692,
            692.2,
            692,
            692.15
        ],
        [
            1709814120000,
            692.15,
            692.15,
            692,
            692.05
        ],
        [
            1709814180000,
            692.05,
            692.35,
            692,
            692.15
        ],
        [
            1709814240000,
            692,
            692.2,
            692,
            692
        ],
        [
            1709814300000,
            692,
            692.05,
            692,
            692.05
        ],
        [
            1709814360000,
            692.05,
            692.2,
            692,
            692.15
        ],
        [
            1709814420000,
            692,
            692.45,
            692,
            692.45
        ],
        [
            1709814480000,
            692.05,
            692.4,
            692.05,
            692.1
        ],
        [
            1709814540000,
            692.3,
            692.4,
            692.05,
            692.25
        ],
        [
            1709814600000,
            692.05,
            692.05,
            692,
            692
        ],
        [
            1709814660000,
            692,
            692.15,
            692,
            692.15
        ],
        [
            1709814720000,
            692.05,
            692.05,
            691.85,
            692
        ],
        [
            1709814780000,
            692,
            692.05,
            691.95,
            692
        ],
        [
            1709814840000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709814900000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709814960000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709815020000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709815080000,
            692,
            692.05,
            692,
            692.05
        ],
        [
            1709815140000,
            692,
            692,
            692,
            692
        ],
        [
            1709815200000,
            692,
            692.05,
            692,
            692.05
        ],
        [
            1709815260000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709815320000,
            692,
            692.85,
            692,
            692.65
        ],
        [
            1709815380000,
            692.6,
            692.6,
            692.35,
            692.35
        ],
        [
            1709815440000,
            692.5,
            692.5,
            692,
            692.3
        ],
        [
            1709815500000,
            692.25,
            692.7,
            692.25,
            692.25
        ],
        [
            1709815560000,
            692.6,
            692.7,
            692.3,
            692.65
        ],
        [
            1709815620000,
            692.65,
            693.85,
            692.55,
            693.4
        ],
        [
            1709815680000,
            693.4,
            693.85,
            693.35,
            693.7
        ],
        [
            1709815740000,
            693.7,
            693.7,
            692.7,
            692.7
        ],
        [
            1709815800000,
            692.9,
            693.7,
            692.9,
            693.25
        ],
        [
            1709815860000,
            693.25,
            694,
            693.25,
            693.8
        ],
        [
            1709815920000,
            694.35,
            694.35,
            693.3,
            693.6
        ],
        [
            1709815980000,
            693.55,
            693.8,
            693.3,
            693.8
        ],
        [
            1709816040000,
            693.4,
            694.45,
            693.25,
            693.7
        ],
        [
            1709816100000,
            693.7,
            693.7,
            693.6,
            693.65
        ],
        [
            1709816160000,
            693.7,
            693.9,
            693.65,
            693.75
        ],
        [
            1709816220000,
            693.75,
            693.75,
            693.65,
            693.65
        ],
        [
            1709816280000,
            693.65,
            693.65,
            693.55,
            693.55
        ],
        [
            1709816340000,
            693.55,
            693.55,
            693.2,
            693.55
        ],
        [
            1709816400000,
            693.45,
            693.5,
            693.15,
            693.15
        ],
        [
            1709816460000,
            693.3,
            693.7,
            693.2,
            693.7
        ],
        [
            1709816520000,
            693.55,
            694.15,
            693.5,
            693.7
        ],
        [
            1709816580000,
            693.45,
            693.65,
            693.25,
            693.3
        ],
        [
            1709816640000,
            693.3,
            693.3,
            693.2,
            693.2
        ],
        [
            1709816700000,
            693.15,
            693.55,
            693,
            693
        ],
        [
            1709816760000,
            693,
            693.05,
            693,
            693.05
        ],
        [
            1709816820000,
            693,
            693.35,
            693,
            693.05
        ],
        [
            1709816880000,
            693.05,
            693.7,
            692.85,
            693
        ],
        [
            1709816940000,
            692.65,
            693.2,
            692.65,
            693.05
        ],
        [
            1709817000000,
            693.15,
            693.65,
            693.1,
            693.1
        ],
        [
            1709817060000,
            693.1,
            693.35,
            693,
            693.3
        ],
        [
            1709817120000,
            693.05,
            693.35,
            693,
            693.3
        ],
        [
            1709817180000,
            693.2,
            693.35,
            692.7,
            692.7
        ],
        [
            1709817240000,
            692.65,
            693.1,
            692.65,
            692.9
        ],
        [
            1709817300000,
            692.75,
            693.1,
            692.7,
            693
        ],
        [
            1709817360000,
            692.65,
            693.1,
            692.65,
            692.9
        ],
        [
            1709817420000,
            692.75,
            692.8,
            692,
            692.5
        ],
        [
            1709817480000,
            692.4,
            693.1,
            692.4,
            692.5
        ],
        [
            1709817540000,
            692.5,
            692.5,
            692.5,
            692.5
        ],
        [
            1709817600000,
            692.5,
            692.85,
            692.5,
            692.7
        ],
        [
            1709817660000,
            692.5,
            693,
            692.5,
            692.7
        ],
        [
            1709817720000,
            692.7,
            692.8,
            692.35,
            692.4
        ],
        [
            1709817780000,
            692.5,
            692.8,
            692.15,
            692.25
        ],
        [
            1709817840000,
            692.3,
            692.3,
            692.05,
            692.3
        ],
        [
            1709817900000,
            692.3,
            692.4,
            692,
            692.4
        ],
        [
            1709817960000,
            692.05,
            692.3,
            692,
            692
        ],
        [
            1709818020000,
            692.05,
            693.45,
            692,
            693.45
        ],
        [
            1709818080000,
            693.15,
            693.15,
            692.4,
            692.65
        ],
        [
            1709818140000,
            692.4,
            692.4,
            692.1,
            692.1
        ],
        [
            1709818200000,
            692.1,
            692.5,
            692.1,
            692.5
        ],
        [
            1709818260000,
            692.5,
            692.5,
            692.05,
            692.15
        ],
        [
            1709818320000,
            692.1,
            692.65,
            692.1,
            692.65
        ],
        [
            1709818380000,
            692.7,
            692.75,
            692.6,
            692.75
        ],
        [
            1709818440000,
            692.75,
            693.1,
            692.75,
            693.1
        ],
        [
            1709818500000,
            693.5,
            693.5,
            693.05,
            693.1
        ],
        [
            1709818560000,
            693.1,
            693.3,
            693.1,
            693.15
        ],
        [
            1709818620000,
            693.15,
            693.2,
            693.1,
            693.1
        ],
        [
            1709818680000,
            693.1,
            693.1,
            693.1,
            693.1
        ],
        [
            1709818740000,
            693.1,
            693.1,
            693,
            693
        ],
        [
            1709818800000,
            693,
            693.05,
            692.2,
            693
        ],
        [
            1709818860000,
            692.45,
            692.45,
            692.35,
            692.35
        ],
        [
            1709818920000,
            692.35,
            692.4,
            692.3,
            692.3
        ],
        [
            1709818980000,
            692.25,
            692.75,
            692.25,
            692.3
        ],
        [
            1709819040000,
            692.3,
            692.4,
            692.1,
            692.15
        ],
        [
            1709819100000,
            692.15,
            692.3,
            692.1,
            692.1
        ],
        [
            1709819160000,
            692.05,
            692.2,
            692.05,
            692.2
        ],
        [
            1709819220000,
            692.2,
            692.2,
            692.05,
            692.05
        ],
        [
            1709819280000,
            692.05,
            692.15,
            692,
            692.1
        ],
        [
            1709819340000,
            692.05,
            692.1,
            692,
            692
        ],
        [
            1709819400000,
            692,
            692.5,
            692,
            692.15
        ],
        [
            1709819460000,
            692.1,
            692.6,
            692.05,
            692.1
        ],
        [
            1709819520000,
            692.1,
            692.5,
            692,
            692.05
        ],
        [
            1709819580000,
            692,
            692.15,
            692,
            692.1
        ],
        [
            1709819640000,
            692,
            692.9,
            692,
            692.15
        ],
        [
            1709819700000,
            692.15,
            692.15,
            692,
            692
        ],
        [
            1709819760000,
            692,
            692.05,
            692,
            692.05
        ],
        [
            1709819820000,
            692,
            692.2,
            692,
            692.2
        ],
        [
            1709819880000,
            692.05,
            692.15,
            692,
            692
        ],
        [
            1709819940000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709820000000,
            692.05,
            692.1,
            692,
            692
        ],
        [
            1709820060000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709820120000,
            692,
            692.05,
            692,
            692
        ],
        [
            1709820180000,
            692,
            692,
            690,
            690
        ],
        [
            1709820240000,
            690,
            690.4,
            689.55,
            690
        ],
        [
            1709820300000,
            689.7,
            689.9,
            689.55,
            689.55
        ],
        [
            1709820360000,
            689.55,
            689.95,
            689.55,
            689.55
        ],
        [
            1709820420000,
            689.55,
            689.55,
            689.35,
            689.55
        ],
        [
            1709820480000,
            689.55,
            689.95,
            689.55,
            689.55
        ],
        [
            1709820540000,
            689.55,
            689.95,
            689.55,
            689.55
        ],
        [
            1709820600000,
            689.5,
            691.15,
            689.5,
            690.5
        ],
        [
            1709820660000,
            690.1,
            690.3,
            690.1,
            690.2
        ],
        [
            1709820720000,
            690.25,
            690.45,
            690.25,
            690.3
        ],
        [
            1709820780000,
            690.45,
            690.7,
            690.2,
            690.35
        ],
        [
            1709820840000,
            690.35,
            690.7,
            690.35,
            690.7
        ],
        [
            1709820900000,
            690.35,
            690.35,
            690.35,
            690.35
        ],
        [
            1709820960000,
            690.35,
            690.6,
            690.1,
            690.1
        ],
        [
            1709821020000,
            690.1,
            690.7,
            690.05,
            690.5
        ],
        [
            1709821080000,
            690.5,
            691.1,
            690.5,
            690.55
        ],
        [
            1709821140000,
            690.55,
            691,
            690.55,
            691
        ],
        [
            1709821200000,
            690.95,
            691.15,
            690.75,
            691.05
        ],
        [
            1709821260000,
            691.05,
            691.4,
            690.95,
            691
        ],
        [
            1709821320000,
            691,
            691.3,
            690.95,
            691
        ],
        [
            1709821380000,
            691.3,
            691.9,
            691.3,
            691.75
        ],
        [
            1709821440000,
            691.75,
            692.05,
            691.75,
            692.05
        ],
        [
            1709821500000,
            692.05,
            692.35,
            691.05,
            691.05
        ],
        [
            1709821560000,
            691.95,
            692.75,
            691.75,
            692.35
        ],
        [
            1709821620000,
            692.35,
            692.35,
            691.05,
            691.35
        ],
        [
            1709821680000,
            691.3,
            691.65,
            691.3,
            691.5
        ],
        [
            1709821740000,
            691.45,
            692.15,
            691.35,
            691.85
        ],
        [
            1709821800000,
            691.4,
            691.75,
            691.35,
            691.35
        ],
        [
            1709821860000,
            691.3,
            691.65,
            691.15,
            691.15
        ],
        [
            1709821920000,
            691.75,
            691.75,
            690.55,
            690.55
        ],
        [
            1709821980000,
            690.55,
            691,
            690.4,
            690.4
        ],
        [
            1709822040000,
            690.9,
            690.9,
            690.3,
            690.3
        ],
        [
            1709822100000,
            690.25,
            690.7,
            690.1,
            690.55
        ],
        [
            1709822160000,
            690.5,
            691,
            690.5,
            690.95
        ],
        [
            1709822220000,
            690.9,
            691.35,
            690.85,
            690.85
        ],
        [
            1709822280000,
            690.85,
            691.3,
            690.75,
            690.9
        ],
        [
            1709822340000,
            690.9,
            691.4,
            690.85,
            690.85
        ],
        [
            1709822400000,
            690.85,
            690.85,
            690.8,
            690.8
        ],
        [
            1709822460000,
            690.8,
            691.65,
            690.8,
            691.45
        ],
        [
            1709822520000,
            691,
            691.55,
            691,
            691.5
        ],
        [
            1709822580000,
            691.05,
            691.4,
            691,
            691.3
        ],
        [
            1709822640000,
            691,
            691.45,
            690.45,
            690.45
        ],
        [
            1709822700000,
            690.6,
            691.1,
            690.6,
            690.85
        ],
        [
            1709822760000,
            691.3,
            691.3,
            690.75,
            690.85
        ],
        [
            1709822820000,
            690.85,
            691.2,
            690.7,
            690.85
        ],
        [
            1709822880000,
            691.2,
            691.3,
            690.7,
            690.85
        ],
        [
            1709822940000,
            691.25,
            691.25,
            690.8,
            690.85
        ],
        [
            1709823000000,
            690.8,
            691.85,
            690.8,
            691.85
        ],
        [
            1709823060000,
            691.8,
            691.8,
            691.15,
            691.2
        ],
        [
            1709823120000,
            691.55,
            691.55,
            690.8,
            690.8
        ],
        [
            1709823180000,
            690.85,
            691.4,
            690.8,
            691.4
        ],
        [
            1709823240000,
            691.1,
            691.1,
            690.3,
            690.3
        ],
        [
            1709823300000,
            690.35,
            690.6,
            690.15,
            690.15
        ],
        [
            1709823360000,
            690.5,
            690.75,
            690.05,
            690.05
        ],
        [
            1709823420000,
            690.05,
            690.6,
            690,
            690
        ],
        [
            1709823480000,
            690,
            690.55,
            690,
            690.55
        ],
        [
            1709823540000,
            690.55,
            690.55,
            690,
            690.35
        ],
        [
            1709823600000,
            690.4,
            691.05,
            690.4,
            691.05
        ],
        [
            1709823660000,
            691.05,
            691.3,
            690.9,
            691
        ],
        [
            1709823720000,
            691,
            691.2,
            691,
            691
        ],
        [
            1709823780000,
            691,
            691.45,
            691,
            691.45
        ],
        [
            1709823840000,
            691.25,
            691.4,
            690.8,
            691
        ],
        [
            1709823900000,
            691,
            691,
            691,
            691
        ],
        [
            1709823960000,
            691,
            691.45,
            691,
            691.45
        ],
        [
            1709824020000,
            691.45,
            691.75,
            691.25,
            691.75
        ],
        [
            1709824080000,
            691.55,
            691.55,
            691,
            691
        ],
        [
            1709824140000,
            691,
            691.5,
            691,
            691.5
        ],
        [
            1709824200000,
            691.3,
            691.3,
            691.2,
            691.2
        ],
        [
            1709824260000,
            691,
            691.05,
            691,
            691.05
        ],
        [
            1709824320000,
            691,
            691.25,
            691,
            691
        ],
        [
            1709824380000,
            691.15,
            691.2,
            691,
            691
        ],
        [
            1709824440000,
            690.95,
            691.65,
            690.95,
            691.55
        ],
        [
            1709824500000,
            691,
            691.45,
            690.65,
            691.25
        ],
        [
            1709824560000,
            690.9,
            691.35,
            690.9,
            691.15
        ],
        [
            1709824620000,
            691.15,
            691.8,
            691.1,
            691.8
        ],
        [
            1709824680000,
            691.7,
            691.75,
            690.85,
            691.2
        ],
        [
            1709824740000,
            691.7,
            691.8,
            691.65,
            691.7
        ],
        [
            1709824800000,
            691.75,
            692.8,
            691.35,
            691.35
        ],
        [
            1709824860000,
            691.55,
            691.6,
            691.4,
            691.6
        ],
        [
            1709824920000,
            691.65,
            691.9,
            691.6,
            691.9
        ],
        [
            1709824980000,
            691.9,
            691.9,
            691.85,
            691.85
        ],
        [
            1709825040000,
            691.9,
            691.9,
            691,
            691.05
        ],
        [
            1709825100000,
            691.05,
            691.3,
            691,
            691.3
        ],
        [
            1709825160000,
            691.3,
            692.45,
            691.25,
            692.45
        ],
        [
            1709825220000,
            691.5,
            692.3,
            691.1,
            692.3
        ],
        [
            1709825280000,
            692.5,
            692.5,
            690.9,
            690.9
        ],
        [
            1709825340000,
            691,
            692.6,
            691,
            692.6
        ],
        [
            1709825400000,
            692.65,
            692.65,
            692.65,
            692.65
        ],
        [
            1710148500000,
            691.2,
            692.4,
            687.85,
            690.3
        ],
        [
            1710148560000,
            690.45,
            694.3,
            690.45,
            693.3
        ],
        [
            1710148620000,
            693,
            693.75,
            692.95,
            693.75
        ],
        [
            1710148680000,
            693.55,
            693.55,
            692.65,
            693.05
        ],
        [
            1710148740000,
            692.9,
            693.55,
            692.7,
            692.8
        ],
        [
            1710148800000,
            692.8,
            692.8,
            689.1,
            689.1
        ],
        [
            1710148860000,
            689.85,
            691,
            689.85,
            690.45
        ],
        [
            1710148920000,
            690.9,
            690.9,
            690.5,
            690.9
        ],
        [
            1710148980000,
            690.9,
            691.65,
            689.8,
            690.45
        ],
        [
            1710149040000,
            690.45,
            691.5,
            690.45,
            691.5
        ],
        [
            1710149100000,
            691.6,
            691.65,
            690.95,
            691.65
        ],
        [
            1710149160000,
            692.45,
            692.85,
            692.45,
            692.5
        ],
        [
            1710149220000,
            692.9,
            693.3,
            692.9,
            693.2
        ],
        [
            1710149280000,
            692.9,
            693.55,
            692.9,
            693.25
        ],
        [
            1710149340000,
            693.7,
            693.75,
            693.7,
            693.75
        ],
        [
            1710149400000,
            694.15,
            694.15,
            692.55,
            693.2
        ],
        [
            1710149460000,
            692.9,
            693.5,
            692.85,
            692.85
        ],
        [
            1710149520000,
            693.15,
            693.2,
            693.15,
            693.2
        ],
        [
            1710149580000,
            693,
            693.2,
            693,
            693
        ],
        [
            1710149640000,
            693,
            693.4,
            692.95,
            692.95
        ],
        [
            1710149700000,
            693.25,
            695.6,
            693.25,
            695.6
        ],
        [
            1710149760000,
            695.7,
            696.4,
            695.6,
            695.6
        ],
        [
            1710149820000,
            695.95,
            696,
            695.25,
            696
        ],
        [
            1710149880000,
            696,
            697.2,
            696,
            696.6
        ],
        [
            1710149940000,
            695.6,
            695.85,
            695.6,
            695.8
        ],
        [
            1710150000000,
            695.8,
            696.95,
            695.8,
            696.95
        ],
        [
            1710150060000,
            696.95,
            698.05,
            696.45,
            697
        ],
        [
            1710150120000,
            696.55,
            698,
            696.55,
            697.55
        ],
        [
            1710150180000,
            697.5,
            699.15,
            697.5,
            699.15
        ],
        [
            1710150240000,
            698.65,
            699.3,
            698,
            698.45
        ],
        [
            1710150300000,
            698.05,
            698.5,
            697.8,
            698
        ],
        [
            1710150360000,
            698,
            698.2,
            697.55,
            697.55
        ],
        [
            1710150420000,
            697.5,
            697.8,
            697.3,
            697.65
        ],
        [
            1710150480000,
            697.8,
            697.8,
            697.65,
            697.65
        ],
        [
            1710150540000,
            697.7,
            698.65,
            697.7,
            698.65
        ],
        [
            1710150600000,
            698.3,
            699,
            698.05,
            698.6
        ],
        [
            1710150660000,
            698.6,
            698.6,
            697.95,
            698.25
        ],
        [
            1710150720000,
            698.2,
            698.2,
            697.9,
            697.9
        ],
        [
            1710150780000,
            697.9,
            698.1,
            696.85,
            696.95
        ],
        [
            1710150840000,
            696.8,
            696.8,
            696.15,
            696.7
        ],
        [
            1710150900000,
            696.7,
            696.7,
            696,
            696
        ],
        [
            1710150960000,
            696.3,
            696.3,
            695.45,
            696.2
        ],
        [
            1710151020000,
            695.85,
            696.25,
            695.75,
            695.95
        ],
        [
            1710151080000,
            695.8,
            697.35,
            695.8,
            697.35
        ],
        [
            1710151140000,
            697.2,
            697.4,
            696.95,
            697.25
        ],
        [
            1710151200000,
            697.25,
            697.45,
            696.75,
            696.75
        ],
        [
            1710151260000,
            696.75,
            697.2,
            696.7,
            697.05
        ],
        [
            1710151320000,
            697.05,
            697.45,
            696.9,
            696.9
        ],
        [
            1710151380000,
            697.05,
            697.35,
            697,
            697.25
        ],
        [
            1710151440000,
            697.35,
            698.05,
            697.2,
            698.05
        ],
        [
            1710151500000,
            698.05,
            698.05,
            697.55,
            698.05
        ],
        [
            1710151560000,
            697.8,
            698.25,
            697.3,
            697.3
        ],
        [
            1710151620000,
            697.15,
            697.3,
            696.8,
            696.8
        ],
        [
            1710151680000,
            696.8,
            697.05,
            696.4,
            696.95
        ],
        [
            1710151740000,
            697.4,
            697.85,
            697.25,
            697.7
        ],
        [
            1710151800000,
            697.7,
            698,
            697.65,
            697.65
        ],
        [
            1710151860000,
            697.6,
            698,
            697.5,
            697.55
        ],
        [
            1710151920000,
            697.5,
            697.5,
            697.35,
            697.5
        ],
        [
            1710151980000,
            697.5,
            697.5,
            697,
            697
        ],
        [
            1710152040000,
            697,
            697.1,
            696.05,
            696.05
        ],
        [
            1710152100000,
            696.55,
            696.55,
            695.6,
            696.25
        ],
        [
            1710152160000,
            695.85,
            696.35,
            695.85,
            695.9
        ],
        [
            1710152220000,
            695.9,
            696.3,
            695.75,
            695.8
        ],
        [
            1710152280000,
            696.25,
            696.35,
            695.7,
            696.35
        ],
        [
            1710152340000,
            696.05,
            696.35,
            695.8,
            695.85
        ],
        [
            1710152400000,
            695.9,
            696.35,
            695.75,
            696.2
        ],
        [
            1710152460000,
            696,
            696,
            695.9,
            695.95
        ],
        [
            1710152520000,
            696.1,
            696.1,
            695,
            695.1
        ],
        [
            1710152580000,
            695.1,
            695.1,
            693.9,
            693.9
        ],
        [
            1710152640000,
            694,
            694.5,
            694,
            694.5
        ],
        [
            1710152700000,
            694.35,
            694.5,
            693.65,
            693.95
        ],
        [
            1710152760000,
            693.65,
            694,
            693.65,
            693.7
        ],
        [
            1710152820000,
            693.7,
            694,
            693.65,
            693.7
        ],
        [
            1710152880000,
            693.65,
            693.7,
            693.55,
            693.55
        ],
        [
            1710152940000,
            693.9,
            693.9,
            693.15,
            693.25
        ],
        [
            1710153000000,
            693.75,
            693.75,
            693,
            693.65
        ],
        [
            1710153060000,
            693.45,
            693.7,
            693.2,
            693.2
        ],
        [
            1710153120000,
            693.1,
            693.75,
            693.1,
            693.5
        ],
        [
            1710153180000,
            693.9,
            693.9,
            693.6,
            693.75
        ],
        [
            1710153240000,
            693.75,
            693.9,
            693.6,
            693.75
        ],
        [
            1710153300000,
            693.75,
            693.75,
            693.45,
            693.75
        ],
        [
            1710153360000,
            693.5,
            693.55,
            693.25,
            693.4
        ],
        [
            1710153420000,
            693.6,
            693.65,
            693.15,
            693.65
        ],
        [
            1710153480000,
            693.3,
            693.75,
            693.3,
            693.3
        ],
        [
            1710153540000,
            693.3,
            693.75,
            693.25,
            693.75
        ],
        [
            1710153600000,
            693.75,
            693.95,
            693.75,
            693.85
        ],
        [
            1710153660000,
            693.85,
            694,
            693.85,
            694
        ],
        [
            1710153720000,
            694,
            694.45,
            693.9,
            694.45
        ],
        [
            1710153780000,
            694.45,
            694.6,
            693.95,
            693.95
        ],
        [
            1710153840000,
            693.95,
            693.95,
            693.45,
            693.45
        ],
        [
            1710153900000,
            693.45,
            693.95,
            693.1,
            693.95
        ],
        [
            1710153960000,
            694.9,
            695,
            694.25,
            694.25
        ],
        [
            1710154020000,
            694.25,
            694.4,
            693.8,
            693.9
        ],
        [
            1710154080000,
            694.2,
            694.2,
            693.55,
            693.55
        ],
        [
            1710154140000,
            693.75,
            693.75,
            693.55,
            693.55
        ],
        [
            1710154200000,
            693.75,
            693.8,
            693.65,
            693.65
        ],
        [
            1710154260000,
            693.65,
            693.9,
            693.65,
            693.9
        ],
        [
            1710154320000,
            693.75,
            693.9,
            693.65,
            693.65
        ],
        [
            1710154380000,
            693.65,
            693.9,
            693.65,
            693.65
        ],
        [
            1710154440000,
            693.75,
            693.75,
            693.7,
            693.7
        ],
        [
            1710154500000,
            693.7,
            694.9,
            693.7,
            694.3
        ],
        [
            1710154560000,
            694.35,
            694.4,
            694.15,
            694.4
        ],
        [
            1710154620000,
            694.3,
            694.8,
            694.15,
            694.8
        ],
        [
            1710154680000,
            694.35,
            694.35,
            693.95,
            694.15
        ],
        [
            1710154740000,
            693.85,
            694.15,
            693.85,
            693.9
        ],
        [
            1710154800000,
            694.15,
            694.35,
            693.85,
            694.05
        ],
        [
            1710154860000,
            694.5,
            694.5,
            694.05,
            694.1
        ],
        [
            1710154920000,
            694.1,
            694.4,
            694.05,
            694.1
        ],
        [
            1710154980000,
            694.4,
            694.4,
            694.15,
            694.35
        ],
        [
            1710155040000,
            694.35,
            694.35,
            694.25,
            694.25
        ],
        [
            1710155100000,
            693.5,
            693.5,
            692.3,
            692.4
        ],
        [
            1710155220000,
            692.8,
            693,
            692.8,
            693
        ],
        [
            1710155280000,
            693.1,
            693.15,
            692.75,
            692.75
        ],
        [
            1710155340000,
            692.3,
            692.6,
            692.2,
            692.6
        ],
        [
            1710155400000,
            692.6,
            692.6,
            692.15,
            692.15
        ],
        [
            1710155460000,
            692.2,
            692.6,
            692.2,
            692.2
        ],
        [
            1710155520000,
            692.2,
            692.2,
            691.35,
            691.4
        ],
        [
            1710155580000,
            691.35,
            691.8,
            691.35,
            691.55
        ],
        [
            1710155640000,
            691.55,
            691.9,
            691.55,
            691.55
        ],
        [
            1710155700000,
            691.75,
            691.85,
            691.5,
            691.85
        ],
        [
            1710155760000,
            691.85,
            692.15,
            691.85,
            692.15
        ],
        [
            1710155820000,
            691.9,
            692.3,
            691.9,
            692.3
        ],
        [
            1710155880000,
            692.3,
            692.4,
            691.25,
            691.25
        ],
        [
            1710155940000,
            691.7,
            691.7,
            691.3,
            691.55
        ],
        [
            1710156000000,
            691.55,
            691.7,
            691.2,
            691.5
        ],
        [
            1710156060000,
            691.4,
            691.5,
            691.4,
            691.5
        ],
        [
            1710156120000,
            691.75,
            691.75,
            691.4,
            691.75
        ],
        [
            1710156180000,
            691.5,
            691.75,
            691.5,
            691.6
        ],
        [
            1710156240000,
            691.65,
            691.75,
            691.6,
            691.7
        ],
        [
            1710156300000,
            691.6,
            691.75,
            691.05,
            691.15
        ],
        [
            1710156360000,
            691.15,
            691.75,
            691,
            691.75
        ],
        [
            1710156420000,
            691.7,
            691.9,
            691.4,
            691.9
        ],
        [
            1710156480000,
            691.7,
            692.4,
            691.5,
            692.1
        ],
        [
            1710156540000,
            692.05,
            692.4,
            692.05,
            692.05
        ],
        [
            1710156600000,
            692.4,
            692.4,
            692.05,
            692.1
        ],
        [
            1710156660000,
            692.4,
            692.4,
            691.95,
            692.05
        ],
        [
            1710156720000,
            692.1,
            692.95,
            692.05,
            692.95
        ],
        [
            1710156780000,
            692.6,
            693.05,
            692.55,
            692.65
        ],
        [
            1710156840000,
            693.05,
            693.15,
            692.7,
            693.15
        ],
        [
            1710156900000,
            693.4,
            693.4,
            692.85,
            692.9
        ],
        [
            1710156960000,
            693.4,
            693.75,
            692.9,
            693.75
        ],
        [
            1710157020000,
            693.75,
            693.75,
            692.35,
            692.4
        ],
        [
            1710157080000,
            692.8,
            692.8,
            692.3,
            692.3
        ],
        [
            1710157140000,
            692.5,
            692.5,
            691.85,
            692.45
        ],
        [
            1710157200000,
            692.1,
            692.4,
            691.9,
            691.95
        ],
        [
            1710157260000,
            691.95,
            691.95,
            691.6,
            691.6
        ],
        [
            1710157320000,
            691.55,
            691.95,
            691.5,
            691.5
        ],
        [
            1710157380000,
            691.95,
            692.4,
            691.5,
            692.4
        ],
        [
            1710157440000,
            692.45,
            692.45,
            692.05,
            692.4
        ],
        [
            1710157500000,
            692.05,
            692.35,
            691.55,
            691.85
        ],
        [
            1710157560000,
            691.9,
            692.1,
            691.8,
            691.8
        ],
        [
            1710157620000,
            691.8,
            692,
            691.45,
            691.45
        ],
        [
            1710157680000,
            691.45,
            692,
            691.35,
            691.65
        ],
        [
            1710157740000,
            691.85,
            691.85,
            691.45,
            691.45
        ],
        [
            1710157800000,
            691.35,
            691.4,
            690.45,
            690.8
        ],
        [
            1710157860000,
            690.6,
            690.6,
            689.3,
            689.3
        ],
        [
            1710157920000,
            689.25,
            689.95,
            689.25,
            689.35
        ],
        [
            1710157980000,
            689.35,
            689.7,
            689.35,
            689.7
        ],
        [
            1710158040000,
            689.7,
            689.75,
            689.05,
            689.6
        ],
        [
            1710158100000,
            689.15,
            689.6,
            689.15,
            689.2
        ],
        [
            1710158160000,
            689.65,
            690.6,
            689.3,
            690.6
        ],
        [
            1710158220000,
            690.25,
            690.45,
            690.1,
            690.1
        ],
        [
            1710158280000,
            690.35,
            690.35,
            690,
            690
        ],
        [
            1710158340000,
            690.25,
            690.35,
            689.7,
            690
        ],
        [
            1710158400000,
            690.35,
            690.45,
            689.85,
            690.45
        ],
        [
            1710158460000,
            690.25,
            690.4,
            690.05,
            690.4
        ],
        [
            1710158520000,
            690.35,
            691.25,
            689.95,
            691.25
        ],
        [
            1710158580000,
            691.55,
            691.7,
            691.1,
            691.65
        ],
        [
            1710158640000,
            691.55,
            691.55,
            690.05,
            690.1
        ],
        [
            1710158700000,
            690.05,
            690.7,
            690,
            690.45
        ],
        [
            1710158760000,
            690.4,
            690.8,
            690.4,
            690.4
        ],
        [
            1710158820000,
            690.4,
            690.7,
            690.15,
            690.15
        ],
        [
            1710158880000,
            690.15,
            690.15,
            689.7,
            689.7
        ],
        [
            1710158940000,
            690,
            690,
            689.05,
            689.6
        ],
        [
            1710159000000,
            689.85,
            689.9,
            689.4,
            689.5
        ],
        [
            1710159060000,
            689.5,
            689.95,
            689.5,
            689.95
        ],
        [
            1710159120000,
            689.65,
            690.4,
            689.65,
            690.4
        ],
        [
            1710159180000,
            690.3,
            690.5,
            690.15,
            690.15
        ],
        [
            1710159240000,
            690.15,
            690.45,
            690.15,
            690.3
        ],
        [
            1710159300000,
            690.3,
            690.35,
            690.1,
            690.35
        ],
        [
            1710159360000,
            690.35,
            690.6,
            690.15,
            690.3
        ],
        [
            1710159420000,
            690.3,
            691,
            690.3,
            690.55
        ],
        [
            1710159480000,
            690.9,
            690.95,
            690.45,
            690.45
        ],
        [
            1710159540000,
            690.45,
            690.9,
            690.35,
            690.4
        ],
        [
            1710159600000,
            690.85,
            690.85,
            690.3,
            690.3
        ],
        [
            1710159660000,
            690.3,
            690.75,
            690.3,
            690.3
        ],
        [
            1710159720000,
            690.3,
            690.6,
            690,
            690
        ],
        [
            1710159780000,
            690,
            690,
            688.9,
            688.9
        ],
        [
            1710159840000,
            688.85,
            689.45,
            688.85,
            689.45
        ],
        [
            1710159900000,
            689.4,
            690,
            689.4,
            690
        ],
        [
            1710159960000,
            690,
            690,
            689.5,
            690
        ],
        [
            1710160020000,
            689.7,
            689.95,
            689.6,
            689.95
        ],
        [
            1710160080000,
            690,
            690,
            689.65,
            690
        ],
        [
            1710160140000,
            690,
            690,
            688.5,
            688.95
        ],
        [
            1710160200000,
            689.4,
            689.7,
            689.05,
            689.6
        ],
        [
            1710160260000,
            689.2,
            689.45,
            688.95,
            688.95
        ],
        [
            1710160320000,
            688.95,
            689.25,
            688.9,
            688.9
        ],
        [
            1710160380000,
            688.85,
            688.85,
            687.3,
            688.3
        ],
        [
            1710160440000,
            688.15,
            688.6,
            687.85,
            687.85
        ],
        [
            1710160500000,
            687.85,
            688.3,
            687.85,
            688.3
        ],
        [
            1710160560000,
            688.35,
            688.65,
            688,
            688.65
        ],
        [
            1710160620000,
            688.75,
            688.9,
            688.65,
            688.65
        ],
        [
            1710160680000,
            688.8,
            688.8,
            687.4,
            687.4
        ],
        [
            1710160740000,
            687.4,
            687.7,
            687.4,
            687.7
        ],
        [
            1710160800000,
            687.4,
            687.7,
            687.4,
            687.7
        ],
        [
            1710160860000,
            687.75,
            687.9,
            687.4,
            687.4
        ],
        [
            1710160920000,
            687.85,
            688,
            687.4,
            687.6
        ],
        [
            1710160980000,
            687.55,
            688,
            687.55,
            688
        ],
        [
            1710161040000,
            687.6,
            688,
            687.55,
            688
        ],
        [
            1710161100000,
            688.1,
            688.1,
            687.75,
            688
        ],
        [
            1710161160000,
            688,
            688.15,
            687.65,
            687.65
        ],
        [
            1710161220000,
            687.6,
            687.65,
            686.05,
            686.45
        ],
        [
            1710161280000,
            686.1,
            688.5,
            686.1,
            687.85
        ],
        [
            1710161340000,
            688,
            690.45,
            687.7,
            690.15
        ],
        [
            1710161400000,
            690.15,
            690.65,
            690.15,
            690.65
        ],
        [
            1710161460000,
            690.65,
            690.65,
            689.95,
            689.95
        ],
        [
            1710161520000,
            690,
            690,
            689.55,
            689.55
        ],
        [
            1710161580000,
            689.55,
            690,
            688.7,
            688.7
        ],
        [
            1710161640000,
            689.15,
            689.15,
            688.55,
            688.7
        ],
        [
            1710161700000,
            688.7,
            689.1,
            688.4,
            688.45
        ],
        [
            1710161760000,
            688.4,
            688.75,
            688.15,
            688.15
        ],
        [
            1710161820000,
            688.15,
            688.6,
            687.75,
            688.4
        ],
        [
            1710161880000,
            688.65,
            688.7,
            688.4,
            688.4
        ],
        [
            1710161940000,
            688.4,
            688.7,
            687.95,
            687.95
        ],
        [
            1710162000000,
            688.45,
            688.6,
            687.75,
            688.1
        ],
        [
            1710162060000,
            688.1,
            688.8,
            688.1,
            688.65
        ],
        [
            1710162120000,
            688.8,
            688.8,
            688.55,
            688.8
        ],
        [
            1710162180000,
            688.65,
            688.85,
            688.65,
            688.85
        ],
        [
            1710162240000,
            688.65,
            688.9,
            688.65,
            688.65
        ],
        [
            1710162300000,
            688.65,
            689.2,
            688.35,
            688.35
        ],
        [
            1710162360000,
            688.35,
            688.7,
            688.1,
            688.5
        ],
        [
            1710162420000,
            688.1,
            688.5,
            688.1,
            688.1
        ],
        [
            1710162480000,
            688.1,
            688.5,
            687.95,
            688.05
        ],
        [
            1710162540000,
            688.15,
            688.45,
            687.95,
            688.45
        ],
        [
            1710162600000,
            688.7,
            688.8,
            688.5,
            688.5
        ],
        [
            1710162660000,
            688.8,
            690,
            688.5,
            690
        ],
        [
            1710162720000,
            690,
            690.3,
            690,
            690
        ],
        [
            1710162780000,
            690,
            690.3,
            690,
            690
        ],
        [
            1710162840000,
            690.3,
            690.3,
            690,
            690
        ],
        [
            1710162900000,
            690.2,
            690.2,
            690,
            690.2
        ],
        [
            1710162960000,
            690,
            690.2,
            690,
            690
        ],
        [
            1710163020000,
            690,
            690.2,
            689.95,
            690
        ],
        [
            1710163080000,
            690,
            690.5,
            690,
            690
        ],
        [
            1710163140000,
            690.3,
            690.3,
            689.8,
            689.8
        ],
        [
            1710163200000,
            689.8,
            689.9,
            689.65,
            689.65
        ],
        [
            1710163260000,
            689.65,
            689.9,
            689.55,
            689.7
        ],
        [
            1710163320000,
            690,
            690.1,
            689.55,
            690.1
        ],
        [
            1710163380000,
            689.65,
            690,
            689.5,
            689.75
        ],
        [
            1710163440000,
            689.7,
            689.7,
            689.55,
            689.65
        ],
        [
            1710163500000,
            689.55,
            690.1,
            689.45,
            690.1
        ],
        [
            1710163560000,
            690.25,
            690.3,
            689.15,
            689.15
        ],
        [
            1710163620000,
            689.15,
            689.25,
            688.8,
            689.25
        ],
        [
            1710163680000,
            688.8,
            689.3,
            688.8,
            689
        ],
        [
            1710163740000,
            689,
            690.45,
            689,
            690.05
        ],
        [
            1710163800000,
            690.05,
            690.3,
            690.05,
            690.05
        ],
        [
            1710163860000,
            690.05,
            690.3,
            690,
            690
        ],
        [
            1710163920000,
            690,
            690.3,
            690,
            690.3
        ],
        [
            1710163980000,
            690,
            690.3,
            690,
            690
        ],
        [
            1710164040000,
            690.3,
            690.3,
            690,
            690.3
        ],
        [
            1710164100000,
            690.05,
            690.35,
            690,
            690.05
        ],
        [
            1710164160000,
            690.05,
            690.15,
            690.05,
            690.15
        ],
        [
            1710164220000,
            690.15,
            690.15,
            690.05,
            690.05
        ],
        [
            1710164280000,
            690.05,
            690.3,
            690,
            690.3
        ],
        [
            1710164340000,
            690.3,
            690.3,
            690,
            690
        ],
        [
            1710164400000,
            690,
            690.3,
            690,
            690
        ],
        [
            1710164460000,
            690,
            690.3,
            690,
            690
        ],
        [
            1710164520000,
            690,
            690.3,
            690,
            690
        ],
        [
            1710164580000,
            690,
            690.35,
            690,
            690.35
        ],
        [
            1710164640000,
            690,
            690.35,
            690,
            690
        ],
        [
            1710164700000,
            690,
            690.35,
            690,
            690
        ],
        [
            1710164760000,
            690,
            690.2,
            690,
            690.2
        ],
        [
            1710164820000,
            690,
            690.15,
            689.8,
            690.15
        ],
        [
            1710164880000,
            689.7,
            689.75,
            689.4,
            689.4
        ],
        [
            1710164940000,
            689.4,
            689.4,
            689.4,
            689.4
        ],
        [
            1710165000000,
            689.4,
            689.65,
            689.2,
            689.65
        ],
        [
            1710165060000,
            689.4,
            690.2,
            689.4,
            690
        ],
        [
            1710165120000,
            690,
            690.05,
            690,
            690
        ],
        [
            1710165180000,
            690,
            690.1,
            690,
            690
        ],
        [
            1710165240000,
            690.2,
            690.2,
            690,
            690
        ],
        [
            1710165300000,
            690,
            690.25,
            690,
            690.05
        ],
        [
            1710165360000,
            690.05,
            690.25,
            689.9,
            689.9
        ],
        [
            1710165420000,
            689.9,
            689.9,
            689.7,
            689.8
        ],
        [
            1710165480000,
            689.7,
            689.95,
            689.5,
            689.95
        ],
        [
            1710165540000,
            690,
            690.1,
            689.7,
            689.7
        ],
        [
            1710165600000,
            689.7,
            689.85,
            689.4,
            689.45
        ],
        [
            1710165660000,
            689.45,
            689.8,
            689.45,
            689.5
        ],
        [
            1710165720000,
            689.5,
            689.8,
            689.3,
            689.4
        ],
        [
            1710165780000,
            689.4,
            689.6,
            689.3,
            689.4
        ],
        [
            1710165840000,
            689.4,
            690,
            689.4,
            690
        ],
        [
            1710165900000,
            690,
            690.05,
            690,
            690
        ],
        [
            1710165960000,
            690,
            690.05,
            689.95,
            690
        ],
        [
            1710166020000,
            690.05,
            690.05,
            689.4,
            689.9
        ],
        [
            1710166080000,
            689.4,
            689.85,
            689.4,
            689.85
        ],
        [
            1710166140000,
            689.4,
            689.4,
            689,
            689
        ],
        [
            1710166200000,
            689.45,
            689.45,
            688.85,
            688.85
        ],
        [
            1710166260000,
            688.85,
            689,
            688.45,
            688.45
        ],
        [
            1710166320000,
            688.75,
            688.75,
            687.95,
            688
        ],
        [
            1710166380000,
            688,
            688,
            687.2,
            687.4
        ],
        [
            1710166440000,
            687.4,
            687.4,
            686.7,
            686.7
        ],
        [
            1710166500000,
            686.55,
            686.95,
            686.55,
            686.75
        ],
        [
            1710166560000,
            686.95,
            686.95,
            686.75,
            686.75
        ],
        [
            1710166620000,
            686.75,
            687.2,
            686.75,
            687
        ],
        [
            1710166680000,
            687.2,
            687.4,
            687,
            687.25
        ],
        [
            1710166740000,
            687.9,
            687.9,
            687.4,
            687.4
        ],
        [
            1710166800000,
            687.4,
            687.75,
            687.4,
            687.4
        ],
        [
            1710166860000,
            687.4,
            688.05,
            687.4,
            688
        ],
        [
            1710166920000,
            688.2,
            688.2,
            687.9,
            687.9
        ],
        [
            1710166980000,
            687.9,
            688.15,
            687.9,
            687.9
        ],
        [
            1710167040000,
            688.1,
            688.1,
            687.65,
            687.65
        ],
        [
            1710167100000,
            687.65,
            687.65,
            686.65,
            686.65
        ],
        [
            1710167160000,
            686.55,
            687.05,
            686.55,
            687
        ],
        [
            1710167220000,
            687.05,
            687.05,
            684.3,
            685
        ],
        [
            1710167280000,
            685,
            687.1,
            684.95,
            687.1
        ],
        [
            1710167340000,
            687.1,
            687.15,
            686.7,
            686.75
        ],
        [
            1710167400000,
            686.85,
            687.45,
            686.75,
            687.45
        ],
        [
            1710167460000,
            687.8,
            688.2,
            687.55,
            687.6
        ],
        [
            1710167520000,
            687.6,
            687.6,
            686.5,
            687.3
        ],
        [
            1710167580000,
            686.9,
            687.3,
            686.9,
            686.95
        ],
        [
            1710167640000,
            686.95,
            687.7,
            686.95,
            687.7
        ],
        [
            1710167700000,
            687.7,
            687.8,
            687.65,
            687.65
        ],
        [
            1710167760000,
            687.65,
            687.95,
            687.65,
            687.95
        ],
        [
            1710167820000,
            687.95,
            688,
            687.65,
            687.65
        ],
        [
            1710167880000,
            687.65,
            687.65,
            686.85,
            686.85
        ],
        [
            1710167940000,
            686.8,
            687.45,
            686.8,
            687.1
        ],
        [
            1710168000000,
            687.1,
            687.45,
            687.05,
            687.45
        ],
        [
            1710168060000,
            687.6,
            687.75,
            687.4,
            687.6
        ],
        [
            1710168120000,
            687.65,
            687.65,
            687.6,
            687.6
        ],
        [
            1710168180000,
            687.9,
            687.9,
            687.2,
            687.2
        ],
        [
            1710168240000,
            687.2,
            687.5,
            687,
            687
        ],
        [
            1710168300000,
            687.05,
            687.05,
            686.8,
            686.8
        ],
        [
            1710168360000,
            686.8,
            687.25,
            686.8,
            687.25
        ],
        [
            1710168420000,
            687.25,
            687.75,
            687.25,
            687.75
        ],
        [
            1710168480000,
            687.4,
            687.4,
            687.35,
            687.35
        ],
        [
            1710168540000,
            687.35,
            687.65,
            687.2,
            687.2
        ],
        [
            1710168600000,
            687.15,
            688.2,
            687.05,
            688.2
        ],
        [
            1710168660000,
            688,
            688.2,
            688,
            688
        ],
        [
            1710168720000,
            688,
            688.3,
            688,
            688.05
        ],
        [
            1710168780000,
            688.05,
            688.2,
            688,
            688.2
        ],
        [
            1710168840000,
            688.3,
            688.3,
            688,
            688
        ],
        [
            1710168900000,
            688,
            688.2,
            687.75,
            687.75
        ],
        [
            1710168960000,
            688,
            688,
            687.55,
            688
        ],
        [
            1710169020000,
            688,
            688.15,
            688,
            688
        ],
        [
            1710169080000,
            688,
            688.5,
            688,
            688.35
        ],
        [
            1710169140000,
            688,
            688.35,
            688,
            688.35
        ],
        [
            1710169200000,
            688.45,
            688.55,
            688,
            688
        ],
        [
            1710169260000,
            688,
            688,
            685.85,
            685.85
        ],
        [
            1710169320000,
            685.8,
            686.2,
            685.55,
            685.55
        ],
        [
            1710169380000,
            685.5,
            685.95,
            685.35,
            685.5
        ],
        [
            1710169440000,
            685.95,
            685.95,
            685.4,
            685.4
        ],
        [
            1710169500000,
            685.35,
            685.35,
            684.6,
            684.6
        ],
        [
            1710169560000,
            684.6,
            685.4,
            684.55,
            684.9
        ],
        [
            1710169620000,
            685.2,
            685.2,
            684.65,
            685
        ],
        [
            1710169680000,
            685,
            685,
            684.8,
            684.8
        ],
        [
            1710169740000,
            685,
            685,
            684.85,
            685
        ],
        [
            1710169800000,
            685,
            685,
            684.85,
            684.85
        ],
        [
            1710169860000,
            684.85,
            684.95,
            683.55,
            684
        ],
        [
            1710169920000,
            684.05,
            684.65,
            684.05,
            684.65
        ],
        [
            1710169980000,
            684.8,
            684.8,
            683.45,
            683.45
        ],
        [
            1710170040000,
            683.85,
            684.4,
            683.85,
            684.4
        ],
        [
            1710170100000,
            685,
            685.15,
            685,
            685.05
        ],
        [
            1710170160000,
            685.15,
            685.15,
            684.9,
            685
        ],
        [
            1710170220000,
            685,
            685,
            684.85,
            685
        ],
        [
            1710170280000,
            684.95,
            685,
            684.4,
            684.7
        ],
        [
            1710170340000,
            684.7,
            685,
            684.6,
            684.8
        ],
        [
            1710170400000,
            684.8,
            685,
            684.75,
            685
        ],
        [
            1710170460000,
            685,
            685,
            684.9,
            685
        ],
        [
            1710170520000,
            685,
            685,
            684.9,
            684.9
        ],
        [
            1710170580000,
            684.9,
            685,
            684.9,
            685
        ],
        [
            1710170640000,
            685,
            685,
            684.8,
            685
        ],
        [
            1710170700000,
            684.85,
            685,
            684.75,
            685
        ],
        [
            1710170760000,
            685,
            685,
            684.9,
            685
        ],
        [
            1710170820000,
            684.85,
            685.45,
            684.85,
            685
        ],
        [
            1710170880000,
            685.05,
            686,
            685.05,
            686
        ],
        [
            1710170940000,
            685.9,
            686.8,
            682.1,
            682.1
        ],
        [
            1710171000000,
            686.8,
            686.8,
            686.8,
            686.8
        ],
        [
            1710234900000,
            685.9,
            686.55,
            684.75,
            685.8
        ],
        [
            1710234960000,
            685.1,
            686.45,
            685.05,
            686.05
        ],
        [
            1710235020000,
            686.65,
            687.4,
            686.45,
            686.5
        ],
        [
            1710235080000,
            687.8,
            688.6,
            687.55,
            688.6
        ],
        [
            1710235140000,
            688.65,
            688.65,
            687.5,
            687.8
        ],
        [
            1710235200000,
            687.1,
            687.1,
            686.15,
            686.5
        ],
        [
            1710235260000,
            686.5,
            686.9,
            686.5,
            686.9
        ],
        [
            1710235320000,
            686.65,
            687.75,
            686.65,
            687.75
        ],
        [
            1710235380000,
            687.85,
            688.35,
            687.85,
            688.35
        ],
        [
            1710235440000,
            688.35,
            689.1,
            687.85,
            689.1
        ],
        [
            1710235500000,
            689.2,
            689.4,
            687.95,
            687.95
        ],
        [
            1710235560000,
            688.4,
            689.2,
            688.4,
            689.2
        ],
        [
            1710235620000,
            688.55,
            688.55,
            688.25,
            688.25
        ],
        [
            1710235680000,
            687.6,
            687.6,
            686.95,
            687
        ],
        [
            1710235740000,
            686.8,
            686.8,
            686.25,
            686.25
        ],
        [
            1710235800000,
            686,
            686.5,
            686,
            686.5
        ],
        [
            1710235860000,
            684.95,
            684.95,
            683.2,
            683.4
        ],
        [
            1710235920000,
            683.9,
            684.7,
            683.4,
            684.7
        ],
        [
            1710235980000,
            684.1,
            686.45,
            684.1,
            686.45
        ],
        [
            1710236040000,
            686.45,
            686.45,
            686,
            686.2
        ],
        [
            1710236100000,
            686.45,
            686.55,
            686.2,
            686.55
        ],
        [
            1710236160000,
            686.45,
            687.1,
            686.2,
            687.1
        ],
        [
            1710236220000,
            687.1,
            687.55,
            687.1,
            687.45
        ],
        [
            1710236280000,
            687.25,
            687.25,
            685.7,
            685.7
        ],
        [
            1710236340000,
            685.7,
            685.9,
            685.35,
            685.35
        ],
        [
            1710236400000,
            685.8,
            685.8,
            685.7,
            685.7
        ],
        [
            1710236460000,
            685.65,
            685.65,
            685.5,
            685.5
        ],
        [
            1710236520000,
            685.5,
            685.6,
            685.15,
            685.15
        ],
        [
            1710236580000,
            685,
            685,
            684.2,
            684.2
        ],
        [
            1710236640000,
            684.1,
            684.4,
            683.5,
            683.7
        ],
        [
            1710236700000,
            683.8,
            684.45,
            683.3,
            684.25
        ],
        [
            1710236760000,
            684.25,
            685.2,
            684.25,
            685.2
        ],
        [
            1710236820000,
            685.2,
            685.6,
            685.2,
            685.6
        ],
        [
            1710236880000,
            685.8,
            686,
            685.55,
            685.55
        ],
        [
            1710236940000,
            685.45,
            686.25,
            685.45,
            686.25
        ],
        [
            1710237000000,
            686.45,
            687.2,
            686.45,
            687.05
        ],
        [
            1710237060000,
            687.5,
            687.5,
            685.25,
            685.25
        ],
        [
            1710237120000,
            685.15,
            685.9,
            685.15,
            685.8
        ],
        [
            1710237180000,
            685.9,
            685.9,
            685.35,
            685.55
        ],
        [
            1710237240000,
            685.55,
            685.9,
            685.5,
            685.7
        ],
        [
            1710237300000,
            685.65,
            685.7,
            685.5,
            685.5
        ],
        [
            1710237360000,
            685.4,
            685.9,
            685.3,
            685.75
        ],
        [
            1710237420000,
            685.75,
            685.85,
            685.75,
            685.8
        ],
        [
            1710237480000,
            685.8,
            685.85,
            685.7,
            685.7
        ],
        [
            1710237540000,
            685.65,
            685.7,
            685.65,
            685.7
        ],
        [
            1710237600000,
            685.65,
            685.7,
            685.65,
            685.7
        ],
        [
            1710237660000,
            685.65,
            685.8,
            685.65,
            685.7
        ],
        [
            1710237720000,
            685.65,
            685.85,
            685.65,
            685.7
        ],
        [
            1710237780000,
            685.7,
            685.7,
            685.65,
            685.7
        ],
        [
            1710237840000,
            685.65,
            686.1,
            685.3,
            685.3
        ],
        [
            1710237900000,
            684.8,
            685,
            684.6,
            684.7
        ],
        [
            1710237960000,
            684.85,
            684.85,
            684.4,
            684.4
        ],
        [
            1710238020000,
            684.55,
            684.55,
            683.9,
            683.9
        ],
        [
            1710238080000,
            684.1,
            684.1,
            683.1,
            683.35
        ],
        [
            1710238140000,
            683.25,
            684.05,
            683.25,
            683.35
        ],
        [
            1710238200000,
            683.25,
            683.65,
            683.15,
            683.35
        ],
        [
            1710238260000,
            683.3,
            683.35,
            682.15,
            682.6
        ],
        [
            1710238320000,
            682.6,
            682.95,
            682.55,
            682.85
        ],
        [
            1710238380000,
            682.95,
            683.35,
            682.95,
            683.35
        ],
        [
            1710238440000,
            683.3,
            683.75,
            683.3,
            683.35
        ],
        [
            1710238500000,
            683.35,
            684,
            683,
            683.35
        ],
        [
            1710238560000,
            683.5,
            683.5,
            683.2,
            683.35
        ],
        [
            1710238620000,
            683.35,
            685.4,
            683.3,
            685.4
        ],
        [
            1710238680000,
            685.45,
            687.05,
            685.45,
            687.05
        ],
        [
            1710238740000,
            686.6,
            686.6,
            685.55,
            686
        ],
        [
            1710238800000,
            686.15,
            686.9,
            685.95,
            686.85
        ],
        [
            1710238860000,
            686.85,
            686.85,
            686.25,
            686.7
        ],
        [
            1710238920000,
            686.65,
            686.85,
            686.15,
            686.65
        ],
        [
            1710238980000,
            686.8,
            686.9,
            686.55,
            686.9
        ],
        [
            1710239040000,
            686.8,
            686.8,
            686.4,
            686.55
        ],
        [
            1710239100000,
            686.45,
            686.75,
            686.45,
            686.65
        ],
        [
            1710239160000,
            685.65,
            685.8,
            685.2,
            685.2
        ],
        [
            1710239220000,
            685.3,
            685.5,
            685.05,
            685.5
        ],
        [
            1710239280000,
            685.5,
            685.75,
            685.3,
            685.75
        ],
        [
            1710239340000,
            685.75,
            685.75,
            684.5,
            684.5
        ],
        [
            1710239400000,
            684.4,
            685.15,
            684,
            684.95
        ],
        [
            1710239460000,
            684.95,
            685,
            684.05,
            684.05
        ],
        [
            1710239520000,
            684.5,
            685.05,
            684.05,
            684.75
        ],
        [
            1710239580000,
            685,
            685.15,
            684.7,
            685
        ],
        [
            1710239640000,
            685,
            685.15,
            684.95,
            685.15
        ],
        [
            1710239700000,
            685,
            685.05,
            684.7,
            684.95
        ],
        [
            1710239760000,
            684.95,
            685.1,
            684.8,
            685.1
        ],
        [
            1710239820000,
            685,
            685.15,
            684.9,
            685.05
        ],
        [
            1710239880000,
            685,
            685.3,
            684.95,
            685.3
        ],
        [
            1710239940000,
            685,
            685.45,
            685,
            685.05
        ],
        [
            1710240000000,
            685,
            687.3,
            684.7,
            687.25
        ],
        [
            1710240060000,
            687.2,
            687.75,
            687.2,
            687.55
        ],
        [
            1710240120000,
            687.75,
            688.75,
            687.65,
            688.6
        ],
        [
            1710240180000,
            688.75,
            688.9,
            687.55,
            687.9
        ],
        [
            1710240240000,
            687.9,
            688.3,
            687.6,
            688.3
        ],
        [
            1710240300000,
            688.4,
            688.7,
            688.4,
            688.5
        ],
        [
            1710240360000,
            688.15,
            688.15,
            687.4,
            687.45
        ],
        [
            1710240420000,
            687.25,
            687.25,
            687,
            687
        ],
        [
            1710240480000,
            687,
            687.95,
            687,
            687.8
        ],
        [
            1710240540000,
            687.85,
            688.15,
            687.85,
            688.15
        ],
        [
            1710240600000,
            688.05,
            688.05,
            687.75,
            687.8
        ],
        [
            1710240660000,
            687.6,
            687.6,
            686.4,
            686.5
        ],
        [
            1710240720000,
            686.95,
            686.95,
            684.1,
            684.5
        ],
        [
            1710240780000,
            684.9,
            685.6,
            684.8,
            685.6
        ],
        [
            1710240840000,
            685.7,
            685.8,
            685.25,
            685.8
        ],
        [
            1710240900000,
            685.55,
            686,
            685.5,
            686
        ],
        [
            1710240960000,
            686,
            686.8,
            686,
            686.45
        ],
        [
            1710241020000,
            686.4,
            686.45,
            685.2,
            685.2
        ],
        [
            1710241080000,
            685,
            686.45,
            685,
            686.35
        ],
        [
            1710241140000,
            686.45,
            686.45,
            685.5,
            686
        ],
        [
            1710241200000,
            685.65,
            686.1,
            685.65,
            686.05
        ],
        [
            1710241260000,
            685.6,
            686.05,
            685.5,
            685.5
        ],
        [
            1710241320000,
            685.95,
            686.05,
            685.95,
            686.05
        ],
        [
            1710241380000,
            686.05,
            686.1,
            685.9,
            686.1
        ],
        [
            1710241440000,
            686.1,
            686.1,
            685.8,
            686.05
        ],
        [
            1710241500000,
            686,
            686,
            685.8,
            686
        ],
        [
            1710241560000,
            685.85,
            686,
            685.85,
            686
        ],
        [
            1710241620000,
            685.95,
            686,
            685.85,
            686
        ],
        [
            1710241680000,
            686.2,
            686.4,
            686.2,
            686.4
        ],
        [
            1710241740000,
            686.4,
            686.4,
            686.25,
            686.3
        ],
        [
            1710241800000,
            686.4,
            686.4,
            685.7,
            685.85
        ],
        [
            1710241860000,
            685.7,
            686,
            685.5,
            685.9
        ],
        [
            1710241920000,
            685.85,
            686.05,
            685.65,
            685.85
        ],
        [
            1710241980000,
            686.05,
            686.05,
            685.95,
            686.05
        ],
        [
            1710242040000,
            686.1,
            686.15,
            685.95,
            686.15
        ],
        [
            1710242100000,
            686,
            686,
            685.65,
            685.65
        ],
        [
            1710242160000,
            685.65,
            685.85,
            685.5,
            685.85
        ],
        [
            1710242220000,
            685.9,
            686,
            685.8,
            685.8
        ],
        [
            1710242280000,
            685.7,
            685.75,
            685.5,
            685.7
        ],
        [
            1710242340000,
            685.75,
            685.8,
            685.55,
            685.6
        ],
        [
            1710242400000,
            685.75,
            685.75,
            685.15,
            685.6
        ],
        [
            1710242460000,
            685.5,
            686,
            685.05,
            685.85
        ],
        [
            1710242520000,
            685.85,
            687.9,
            685.85,
            687.9
        ],
        [
            1710242580000,
            687.55,
            688.1,
            686.75,
            686.95
        ],
        [
            1710242640000,
            686.9,
            687,
            686.9,
            687
        ],
        [
            1710242700000,
            686.95,
            686.95,
            686.55,
            686.85
        ],
        [
            1710242760000,
            686.95,
            687,
            686.7,
            687
        ],
        [
            1710242820000,
            687,
            687,
            686.75,
            686.95
        ],
        [
            1710242880000,
            687,
            687,
            685.75,
            686
        ],
        [
            1710242940000,
            686,
            686,
            685.65,
            685.9
        ],
        [
            1710243000000,
            685.9,
            685.9,
            685.5,
            685.5
        ],
        [
            1710243060000,
            685.6,
            685.7,
            685.5,
            685.5
        ],
        [
            1710243120000,
            685.5,
            685.7,
            685.5,
            685.65
        ],
        [
            1710243180000,
            685.65,
            685.7,
            685.65,
            685.7
        ],
        [
            1710243240000,
            685.65,
            685.65,
            685,
            685.3
        ],
        [
            1710243300000,
            684.95,
            685.2,
            684.15,
            685.2
        ],
        [
            1710243360000,
            684.8,
            684.8,
            684.35,
            684.65
        ],
        [
            1710243420000,
            684.65,
            684.65,
            684.25,
            684.65
        ],
        [
            1710243480000,
            684.55,
            684.55,
            682.5,
            682.7
        ],
        [
            1710243540000,
            682.95,
            683.1,
            682.35,
            682.35
        ],
        [
            1710243600000,
            682.35,
            683,
            682.35,
            683
        ],
        [
            1710243660000,
            683,
            683.95,
            683,
            683.8
        ],
        [
            1710243720000,
            683.8,
            684.15,
            683.7,
            683.7
        ],
        [
            1710243780000,
            683.25,
            684.05,
            683.1,
            683.85
        ],
        [
            1710243840000,
            684.05,
            684.3,
            683.5,
            684.05
        ],
        [
            1710243900000,
            684.05,
            684.3,
            684,
            684.3
        ],
        [
            1710243960000,
            684.3,
            684.35,
            683.6,
            684.35
        ],
        [
            1710244020000,
            684.45,
            684.5,
            683.8,
            684.4
        ],
        [
            1710244080000,
            684.65,
            684.8,
            684.25,
            684.25
        ],
        [
            1710244140000,
            683.8,
            684.5,
            683.8,
            684.5
        ],
        [
            1710244200000,
            684.4,
            684.9,
            684.15,
            684.9
        ],
        [
            1710244260000,
            684.7,
            685.15,
            684.7,
            685
        ],
        [
            1710244320000,
            684.8,
            685,
            684,
            684.9
        ],
        [
            1710244380000,
            684.6,
            684.8,
            684.15,
            684.5
        ],
        [
            1710244440000,
            684.5,
            685,
            684.5,
            685
        ],
        [
            1710244500000,
            685,
            685,
            684.9,
            685
        ],
        [
            1710244560000,
            685,
            685,
            684.8,
            684.85
        ],
        [
            1710244620000,
            684.85,
            684.95,
            684.75,
            684.85
        ],
        [
            1710244680000,
            684.85,
            684.95,
            684.35,
            684.35
        ],
        [
            1710244740000,
            684.95,
            685,
            684.35,
            684.9
        ],
        [
            1710244800000,
            684.9,
            685,
            684.15,
            684.25
        ],
        [
            1710244860000,
            684.35,
            684.35,
            683.5,
            683.7
        ],
        [
            1710244920000,
            683.5,
            683.5,
            683.1,
            683.15
        ],
        [
            1710244980000,
            683.2,
            683.55,
            682.95,
            683.5
        ],
        [
            1710245040000,
            683.5,
            684,
            683.5,
            684
        ],
        [
            1710245100000,
            683.95,
            684.45,
            683.95,
            684.4
        ],
        [
            1710245160000,
            683.8,
            684.15,
            683.6,
            684.05
        ],
        [
            1710245220000,
            684.05,
            684.1,
            683.65,
            683.7
        ],
        [
            1710245280000,
            683.65,
            684.45,
            683.65,
            684.4
        ],
        [
            1710245340000,
            684.35,
            684.45,
            684.3,
            684.45
        ],
        [
            1710245400000,
            684.4,
            684.85,
            684.4,
            684.85
        ],
        [
            1710245460000,
            684.85,
            685.65,
            684.75,
            685.65
        ],
        [
            1710245520000,
            685.5,
            686.3,
            685.5,
            686.15
        ],
        [
            1710245580000,
            686.2,
            686.55,
            686,
            686.4
        ],
        [
            1710245640000,
            686.45,
            686.45,
            685,
            685.1
        ],
        [
            1710245700000,
            685.05,
            685.25,
            684.95,
            685
        ],
        [
            1710245760000,
            684.95,
            685.75,
            684.95,
            685.75
        ],
        [
            1710245820000,
            685.8,
            686.1,
            685.8,
            685.9
        ],
        [
            1710245880000,
            685.8,
            685.85,
            685.55,
            685.55
        ],
        [
            1710245940000,
            685.5,
            685.6,
            685.35,
            685.35
        ],
        [
            1710246000000,
            685.4,
            685.45,
            685,
            685.25
        ],
        [
            1710246060000,
            685.15,
            685.45,
            685,
            685.25
        ],
        [
            1710246120000,
            685.2,
            685.45,
            685.15,
            685.25
        ],
        [
            1710246180000,
            685.25,
            685.25,
            685.1,
            685.1
        ],
        [
            1710246240000,
            685.5,
            685.5,
            685.15,
            685.35
        ],
        [
            1710246300000,
            685.2,
            685.35,
            685.15,
            685.15
        ],
        [
            1710246360000,
            685.1,
            685.5,
            685.1,
            685.15
        ],
        [
            1710246420000,
            685.15,
            685.5,
            685.15,
            685.3
        ],
        [
            1710246480000,
            685.25,
            685.5,
            685.25,
            685.45
        ],
        [
            1710246540000,
            685.35,
            685.35,
            685,
            685.1
        ],
        [
            1710246600000,
            685,
            685.1,
            684.9,
            684.9
        ],
        [
            1710246660000,
            684.9,
            685.1,
            684.85,
            685.1
        ],
        [
            1710246720000,
            685.05,
            685.05,
            685,
            685.05
        ],
        [
            1710246780000,
            685.05,
            685.35,
            685,
            685.3
        ],
        [
            1710246840000,
            685.25,
            685.35,
            685.2,
            685.35
        ],
        [
            1710246900000,
            685.35,
            685.45,
            685.2,
            685.2
        ],
        [
            1710246960000,
            685.2,
            685.2,
            685,
            685.05
        ],
        [
            1710247020000,
            685,
            685.55,
            685,
            685.45
        ],
        [
            1710247080000,
            685,
            685.25,
            685,
            685.25
        ],
        [
            1710247140000,
            685.05,
            685.45,
            685,
            685.1
        ],
        [
            1710247200000,
            685.05,
            685.35,
            685.05,
            685.35
        ],
        [
            1710247260000,
            685.3,
            685.35,
            685,
            685.05
        ],
        [
            1710247320000,
            685.05,
            685.1,
            685,
            685.05
        ],
        [
            1710247380000,
            685,
            685.1,
            685,
            685
        ],
        [
            1710247440000,
            685,
            685.25,
            685,
            685.15
        ],
        [
            1710247500000,
            685.1,
            685.25,
            685,
            685.1
        ],
        [
            1710247560000,
            685.2,
            685.25,
            685.15,
            685.2
        ],
        [
            1710247620000,
            685.3,
            685.3,
            685.2,
            685.2
        ],
        [
            1710247680000,
            685,
            685.25,
            685,
            685.05
        ],
        [
            1710247740000,
            685.25,
            685.25,
            685,
            685.15
        ],
        [
            1710247800000,
            685.05,
            685.25,
            685,
            685.05
        ],
        [
            1710247860000,
            685.05,
            686.2,
            684.8,
            686.15
        ],
        [
            1710247920000,
            686.1,
            686.1,
            686,
            686.1
        ],
        [
            1710247980000,
            686.05,
            686.15,
            686,
            686.1
        ],
        [
            1710248040000,
            686.15,
            686.6,
            686.15,
            686.6
        ],
        [
            1710248100000,
            686.5,
            686.9,
            686.45,
            686.8
        ],
        [
            1710248160000,
            686.85,
            686.9,
            686.45,
            686.45
        ],
        [
            1710248220000,
            686.55,
            686.55,
            685.15,
            685.2
        ],
        [
            1710248280000,
            685.15,
            685.4,
            685.15,
            685.4
        ],
        [
            1710248340000,
            685.2,
            686.2,
            685.15,
            686.05
        ],
        [
            1710248400000,
            686.15,
            686.35,
            686,
            686.35
        ],
        [
            1710248460000,
            686.35,
            686.45,
            686.3,
            686.45
        ],
        [
            1710248520000,
            686.4,
            686.45,
            686.05,
            686.05
        ],
        [
            1710248580000,
            685.8,
            686,
            685.35,
            685.35
        ],
        [
            1710248640000,
            685.3,
            685.55,
            685.1,
            685.55
        ],
        [
            1710248700000,
            685.5,
            685.5,
            685,
            685.05
        ],
        [
            1710248760000,
            685,
            685.5,
            685,
            685.5
        ],
        [
            1710248820000,
            685.4,
            686.15,
            685.15,
            686.15
        ],
        [
            1710248880000,
            686.1,
            686.2,
            685.95,
            685.95
        ],
        [
            1710248940000,
            685.9,
            686.25,
            685.25,
            686.2
        ],
        [
            1710249000000,
            686.05,
            686.4,
            686,
            686.4
        ],
        [
            1710249060000,
            686.4,
            687,
            686.4,
            686.85
        ],
        [
            1710249120000,
            686.85,
            687.7,
            686.85,
            687.55
        ],
        [
            1710249180000,
            687.5,
            688,
            687.5,
            687.95
        ],
        [
            1710249240000,
            687.9,
            688,
            687.5,
            687.5
        ],
        [
            1710249300000,
            687.5,
            687.5,
            687.2,
            687.25
        ],
        [
            1710249360000,
            687.25,
            687.25,
            686.8,
            686.9
        ],
        [
            1710249420000,
            686.95,
            687.1,
            686.85,
            687.05
        ],
        [
            1710249480000,
            687.05,
            687.3,
            686.95,
            687.3
        ],
        [
            1710249540000,
            687.45,
            687.5,
            687.35,
            687.5
        ],
        [
            1710249600000,
            687.45,
            687.5,
            686.5,
            686.75
        ],
        [
            1710249660000,
            686.8,
            686.8,
            684.5,
            685
        ],
        [
            1710249720000,
            684.95,
            685.1,
            684.9,
            685
        ],
        [
            1710249780000,
            685,
            686.15,
            684.95,
            686
        ],
        [
            1710249840000,
            686.15,
            686.4,
            684.95,
            684.95
        ],
        [
            1710249900000,
            684.8,
            685.75,
            684.7,
            685.75
        ],
        [
            1710249960000,
            685.75,
            686.15,
            685.7,
            685.9
        ],
        [
            1710250020000,
            686.35,
            686.55,
            686.25,
            686.45
        ],
        [
            1710250080000,
            686.45,
            686.45,
            686.2,
            686.25
        ],
        [
            1710250140000,
            686.2,
            686.5,
            686.2,
            686.5
        ],
        [
            1710250200000,
            686.5,
            686.9,
            686.45,
            686.9
        ],
        [
            1710250260000,
            686.8,
            687.15,
            686.35,
            687.05
        ],
        [
            1710250320000,
            687.15,
            687.3,
            686.55,
            687.3
        ],
        [
            1710250380000,
            687.3,
            687.3,
            687.1,
            687.1
        ],
        [
            1710250440000,
            687.3,
            687.45,
            687.2,
            687.35
        ],
        [
            1710250500000,
            687.55,
            689.25,
            687.5,
            689.1
        ],
        [
            1710250560000,
            689.25,
            689.6,
            689.25,
            689.55
        ],
        [
            1710250620000,
            689.9,
            690.4,
            689.9,
            690.35
        ],
        [
            1710250680000,
            690.35,
            691.55,
            690.1,
            691.55
        ],
        [
            1710250740000,
            691.4,
            691.7,
            691.25,
            691.7
        ],
        [
            1710250800000,
            691.7,
            691.75,
            690.55,
            690.65
        ],
        [
            1710250860000,
            690.9,
            691.1,
            690.55,
            690.55
        ],
        [
            1710250920000,
            690.6,
            691.05,
            690.55,
            691.05
        ],
        [
            1710250980000,
            690.8,
            691,
            690.8,
            690.95
        ],
        [
            1710251040000,
            690.9,
            690.9,
            690.4,
            690.5
        ],
        [
            1710251100000,
            690.85,
            691.05,
            690.6,
            690.85
        ],
        [
            1710251160000,
            690.95,
            692,
            690.55,
            690.95
        ],
        [
            1710251220000,
            691.3,
            691.3,
            691,
            691.2
        ],
        [
            1710251280000,
            691.15,
            691.5,
            691.1,
            691.25
        ],
        [
            1710251340000,
            691.05,
            691.05,
            690.65,
            690.85
        ],
        [
            1710251400000,
            690.85,
            690.85,
            690.5,
            690.7
        ],
        [
            1710251460000,
            690.7,
            690.7,
            690.55,
            690.55
        ],
        [
            1710251520000,
            690.55,
            690.55,
            690.4,
            690.4
        ],
        [
            1710251580000,
            690.5,
            690.5,
            690.05,
            690.1
        ],
        [
            1710251640000,
            690.3,
            690.3,
            690,
            690
        ],
        [
            1710251700000,
            690.05,
            690.35,
            690.05,
            690.35
        ],
        [
            1710251760000,
            690.35,
            690.5,
            690.25,
            690.3
        ],
        [
            1710251820000,
            690.15,
            690.35,
            690,
            690.35
        ],
        [
            1710251880000,
            690.45,
            690.45,
            690.25,
            690.35
        ],
        [
            1710251940000,
            690.35,
            690.6,
            690,
            690.45
        ],
        [
            1710252000000,
            690.35,
            690.35,
            690.05,
            690.2
        ],
        [
            1710252060000,
            690.15,
            690.3,
            690.1,
            690.3
        ],
        [
            1710252120000,
            690.5,
            690.9,
            690.45,
            690.8
        ],
        [
            1710252180000,
            690.8,
            690.9,
            690.6,
            690.65
        ],
        [
            1710252240000,
            690.9,
            690.9,
            690.45,
            690.75
        ],
        [
            1710252300000,
            690.75,
            690.8,
            690,
            690.4
        ],
        [
            1710252360000,
            690.5,
            690.65,
            690.1,
            690.1
        ],
        [
            1710252420000,
            690,
            690.8,
            690,
            690.4
        ],
        [
            1710252480000,
            690.65,
            692.45,
            690.55,
            692
        ],
        [
            1710252540000,
            692.45,
            692.85,
            691.75,
            692.75
        ],
        [
            1710252600000,
            693,
            693.05,
            691.9,
            692.7
        ],
        [
            1710252660000,
            692.1,
            693.05,
            692.1,
            692.4
        ],
        [
            1710252720000,
            692.05,
            692.7,
            691.85,
            692.05
        ],
        [
            1710252780000,
            692.55,
            692.95,
            692,
            692.95
        ],
        [
            1710252840000,
            693.3,
            693.3,
            692,
            692.85
        ],
        [
            1710252900000,
            692.7,
            692.95,
            691.85,
            692.45
        ],
        [
            1710252960000,
            692.8,
            692.95,
            692.2,
            692.3
        ],
        [
            1710253020000,
            692.5,
            692.95,
            692.35,
            692.75
        ],
        [
            1710253080000,
            692.75,
            692.75,
            691.8,
            691.95
        ],
        [
            1710253140000,
            692,
            692.25,
            691.95,
            692.25
        ],
        [
            1710253200000,
            692,
            692.45,
            691.95,
            692.1
        ],
        [
            1710253260000,
            692.1,
            692.2,
            691.9,
            692.15
        ],
        [
            1710253320000,
            692.35,
            692.65,
            691.95,
            691.95
        ],
        [
            1710253380000,
            692.35,
            692.7,
            692.15,
            692.65
        ],
        [
            1710253440000,
            692.5,
            692.6,
            692.15,
            692.4
        ],
        [
            1710253500000,
            692.4,
            692.4,
            692,
            692
        ],
        [
            1710253560000,
            691.95,
            692.25,
            691.95,
            692.15
        ],
        [
            1710253620000,
            692.25,
            692.3,
            691.8,
            692
        ],
        [
            1710253680000,
            692,
            692.2,
            691.9,
            692.2
        ],
        [
            1710253740000,
            692.2,
            693.05,
            692.2,
            692.85
        ],
        [
            1710253800000,
            692.95,
            693.05,
            692.8,
            693.05
        ],
        [
            1710253860000,
            692.9,
            692.9,
            691.8,
            691.8
        ],
        [
            1710253920000,
            692.1,
            692.15,
            691.5,
            691.55
        ],
        [
            1710253980000,
            691.55,
            692.2,
            691.55,
            692.1
        ],
        [
            1710254040000,
            692.2,
            692.25,
            692.1,
            692.25
        ],
        [
            1710254100000,
            692.25,
            692.3,
            692.05,
            692.05
        ],
        [
            1710254160000,
            692.05,
            692.2,
            691.95,
            692
        ],
        [
            1710254220000,
            691.9,
            692.3,
            691.9,
            691.95
        ],
        [
            1710254280000,
            692,
            692.1,
            691.15,
            691.4
        ],
        [
            1710254340000,
            691.25,
            692,
            691.25,
            692
        ],
        [
            1710254400000,
            692,
            692.55,
            692,
            692.4
        ],
        [
            1710254460000,
            692.35,
            692.45,
            692.35,
            692.4
        ],
        [
            1710254520000,
            692.25,
            692.25,
            691.5,
            691.55
        ],
        [
            1710254580000,
            691.6,
            691.85,
            691.6,
            691.7
        ],
        [
            1710254640000,
            691.8,
            692.05,
            691.75,
            691.95
        ],
        [
            1710254700000,
            691.95,
            692.1,
            691.95,
            692.1
        ],
        [
            1710254760000,
            692.1,
            692.3,
            692,
            692.3
        ],
        [
            1710254820000,
            692.55,
            693.9,
            692.3,
            693.85
        ],
        [
            1710254880000,
            693.85,
            694.2,
            693.75,
            694.2
        ],
        [
            1710254940000,
            693.95,
            695,
            693.95,
            694.95
        ],
        [
            1710255000000,
            694.9,
            696.55,
            694.9,
            696.55
        ],
        [
            1710255060000,
            696.8,
            698.5,
            696.6,
            698
        ],
        [
            1710255120000,
            698.2,
            698.2,
            695.95,
            696.2
        ],
        [
            1710255180000,
            695.6,
            696.15,
            695.4,
            695.8
        ],
        [
            1710255240000,
            695.85,
            696,
            695.25,
            695.8
        ],
        [
            1710255300000,
            695.85,
            696,
            695.65,
            695.85
        ],
        [
            1710255360000,
            695.85,
            695.9,
            695.1,
            695.45
        ],
        [
            1710255420000,
            695.45,
            695.65,
            695,
            695.55
        ],
        [
            1710255480000,
            695.55,
            696,
            695.1,
            695.85
        ],
        [
            1710255540000,
            695.85,
            695.9,
            695.5,
            695.7
        ],
        [
            1710255600000,
            695.7,
            696,
            695.7,
            695.85
        ],
        [
            1710255660000,
            695.85,
            696.6,
            695.55,
            695.8
        ],
        [
            1710255720000,
            695.8,
            695.95,
            695.8,
            695.85
        ],
        [
            1710255780000,
            695.85,
            695.9,
            694.5,
            694.65
        ],
        [
            1710255840000,
            694.7,
            694.85,
            694.05,
            694.7
        ],
        [
            1710255900000,
            694.7,
            694.7,
            693.3,
            693.45
        ],
        [
            1710255960000,
            693.6,
            694.5,
            693.35,
            694.45
        ],
        [
            1710256020000,
            694,
            694.4,
            693.85,
            694.4
        ],
        [
            1710256080000,
            694.5,
            695,
            694,
            694.8
        ],
        [
            1710256140000,
            695,
            695,
            694.5,
            694.65
        ],
        [
            1710256200000,
            694.55,
            694.6,
            692.95,
            693
        ],
        [
            1710256260000,
            693,
            693,
            692.7,
            692.75
        ],
        [
            1710256320000,
            692.75,
            693.1,
            692.55,
            692.75
        ],
        [
            1710256380000,
            692.75,
            693.15,
            692.45,
            692.75
        ],
        [
            1710256440000,
            692.75,
            693.15,
            692.5,
            692.75
        ],
        [
            1710256500000,
            692.75,
            693.15,
            692.6,
            692.75
        ],
        [
            1710256560000,
            692.75,
            693.2,
            692.55,
            692.75
        ],
        [
            1710256620000,
            692.75,
            692.95,
            692.75,
            692.75
        ],
        [
            1710256680000,
            692.75,
            692.8,
            692,
            692.75
        ],
        [
            1710256740000,
            692.75,
            692.95,
            692.6,
            692.95
        ],
        [
            1710256800000,
            692.7,
            692.75,
            692.45,
            692.75
        ],
        [
            1710256860000,
            692.75,
            692.9,
            692.75,
            692.75
        ],
        [
            1710256920000,
            692.75,
            693.2,
            692.7,
            692.75
        ],
        [
            1710256980000,
            692.75,
            693.15,
            692.7,
            692.75
        ],
        [
            1710257040000,
            692.95,
            693.15,
            692.7,
            692.7
        ],
        [
            1710257100000,
            692.75,
            692.8,
            692.5,
            692.5
        ],
        [
            1710257160000,
            692.5,
            692.5,
            692,
            692.05
        ],
        [
            1710257220000,
            692.05,
            692.35,
            691.7,
            691.7
        ],
        [
            1710257280000,
            692,
            693.1,
            691.9,
            692.05
        ],
        [
            1710257340000,
            692.05,
            693.1,
            692.05,
            693.1
        ],
        [
            1710321300000,
            697.35,
            697.4,
            691.1,
            694
        ],
        [
            1710321360000,
            694.55,
            694.9,
            693.6,
            694.5
        ],
        [
            1710321420000,
            695.7,
            695.7,
            694.05,
            694.2
        ],
        [
            1710321480000,
            694,
            694,
            693,
            693.1
        ],
        [
            1710321540000,
            693.55,
            693.55,
            692.6,
            692.6
        ],
        [
            1710321600000,
            692.05,
            692.15,
            689.15,
            689.15
        ],
        [
            1710321660000,
            688.85,
            689.95,
            688.85,
            689.95
        ],
        [
            1710321720000,
            689.95,
            689.95,
            688.65,
            689
        ],
        [
            1710321780000,
            689.1,
            689.1,
            686.8,
            687.55
        ],
        [
            1710321840000,
            687.25,
            688.4,
            687.2,
            688.4
        ],
        [
            1710321900000,
            689.2,
            689.4,
            688.5,
            688.7
        ],
        [
            1710321960000,
            688.6,
            688.8,
            688.3,
            688.8
        ],
        [
            1710322020000,
            688.3,
            688.6,
            687,
            687.05
        ],
        [
            1710322080000,
            687.15,
            688.9,
            687,
            688.05
        ],
        [
            1710322140000,
            687.95,
            688.15,
            685.85,
            687
        ],
        [
            1710322200000,
            686.6,
            688.05,
            686.35,
            687.05
        ],
        [
            1710322260000,
            686.5,
            687.05,
            686.35,
            687.05
        ],
        [
            1710322320000,
            686.25,
            687.5,
            685.55,
            687.5
        ],
        [
            1710322380000,
            686.9,
            687.95,
            686.9,
            687.55
        ],
        [
            1710322440000,
            687.9,
            687.9,
            687.4,
            687.4
        ],
        [
            1710322500000,
            687.25,
            687.75,
            686.6,
            687.1
        ],
        [
            1710322560000,
            687.1,
            687.15,
            686.15,
            686.45
        ],
        [
            1710322620000,
            686.2,
            686.9,
            685.95,
            686.05
        ],
        [
            1710322680000,
            685.65,
            686.25,
            685.3,
            685.45
        ],
        [
            1710322740000,
            685.45,
            685.6,
            685.1,
            685.45
        ],
        [
            1710322800000,
            685.55,
            686.55,
            685.45,
            685.85
        ],
        [
            1710322860000,
            685.75,
            687.55,
            685.75,
            686.2
        ],
        [
            1710322920000,
            686.5,
            686.5,
            686.05,
            686.05
        ],
        [
            1710322980000,
            686.65,
            687.15,
            686.3,
            687.15
        ],
        [
            1710323040000,
            687.15,
            687.5,
            687,
            687.5
        ],
        [
            1710323100000,
            687.15,
            688.25,
            687.15,
            687.75
        ],
        [
            1710323160000,
            688.45,
            688.5,
            687.65,
            688.4
        ],
        [
            1710323220000,
            688,
            688.5,
            688,
            688.5
        ],
        [
            1710323280000,
            688.1,
            688.5,
            687.45,
            687.5
        ],
        [
            1710323340000,
            687,
            688.15,
            686.55,
            686.55
        ],
        [
            1710323400000,
            686.6,
            687.55,
            686.6,
            687.55
        ],
        [
            1710323460000,
            687.15,
            687.55,
            686.25,
            686.75
        ],
        [
            1710323520000,
            686.75,
            687.2,
            686.6,
            687.2
        ],
        [
            1710323580000,
            687,
            687,
            685.85,
            685.85
        ],
        [
            1710323640000,
            686.55,
            686.85,
            686.45,
            686.85
        ],
        [
            1710323700000,
            686.85,
            686.85,
            685.4,
            685.4
        ],
        [
            1710323760000,
            685.6,
            685.85,
            685.35,
            685.85
        ],
        [
            1710323820000,
            685,
            685.25,
            684.5,
            685.25
        ],
        [
            1710323880000,
            684.7,
            684.7,
            684.05,
            684.05
        ],
        [
            1710323940000,
            684,
            685.05,
            683.85,
            684.6
        ],
        [
            1710324000000,
            685.15,
            685.15,
            683.8,
            683.85
        ],
        [
            1710324060000,
            684.35,
            684.35,
            684,
            684
        ],
        [
            1710324120000,
            684.5,
            684.85,
            684.45,
            684.85
        ],
        [
            1710324180000,
            684.3,
            684.6,
            683.85,
            683.85
        ],
        [
            1710324240000,
            684.1,
            684.1,
            683.8,
            683.8
        ],
        [
            1710324300000,
            684,
            684,
            683.1,
            683.35
        ],
        [
            1710324360000,
            683.2,
            684,
            683.2,
            683.7
        ],
        [
            1710324420000,
            684.15,
            684.15,
            683.65,
            684
        ],
        [
            1710324480000,
            684,
            684,
            683.7,
            684
        ],
        [
            1710324540000,
            684,
            684,
            683.7,
            683.7
        ],
        [
            1710324600000,
            684,
            684,
            683.25,
            683.95
        ],
        [
            1710324660000,
            683.65,
            684,
            683.6,
            683.65
        ],
        [
            1710324720000,
            684,
            684,
            683.65,
            683.7
        ],
        [
            1710324780000,
            683.65,
            684,
            683.6,
            684
        ],
        [
            1710324840000,
            683.95,
            684,
            683.8,
            683.8
        ],
        [
            1710324900000,
            684,
            685.55,
            684,
            685
        ],
        [
            1710324960000,
            685,
            685,
            684.8,
            685
        ],
        [
            1710325020000,
            685,
            685,
            684.8,
            685
        ],
        [
            1710325080000,
            685,
            685,
            684.8,
            684.8
        ],
        [
            1710325140000,
            684.65,
            684.9,
            684.65,
            684.65
        ],
        [
            1710325200000,
            684.65,
            684.65,
            683.25,
            683.25
        ],
        [
            1710325260000,
            682.7,
            683,
            682.45,
            682.5
        ],
        [
            1710325320000,
            682.45,
            682.75,
            682.45,
            682.5
        ],
        [
            1710325380000,
            682.55,
            682.75,
            682.1,
            682.1
        ],
        [
            1710325440000,
            682.5,
            682.5,
            681.35,
            681.85
        ],
        [
            1710325500000,
            681.5,
            682.25,
            681.5,
            682
        ],
        [
            1710325560000,
            681.5,
            682.15,
            681.2,
            682.15
        ],
        [
            1710325620000,
            682.1,
            682.1,
            681.65,
            681.65
        ],
        [
            1710325680000,
            681.9,
            681.9,
            680.8,
            680.8
        ],
        [
            1710325740000,
            680.5,
            681.55,
            680.4,
            680.55
        ],
        [
            1710325800000,
            680.95,
            682.15,
            680.6,
            681.95
        ],
        [
            1710325860000,
            682.05,
            682.5,
            682.05,
            682.05
        ],
        [
            1710325920000,
            682.05,
            682.4,
            682.05,
            682.05
        ],
        [
            1710325980000,
            682.05,
            682.35,
            681.45,
            681.45
        ],
        [
            1710326040000,
            681.45,
            682.6,
            681.45,
            682.6
        ],
        [
            1710326100000,
            682.15,
            682.15,
            682.1,
            682.1
        ],
        [
            1710326160000,
            682.8,
            683.7,
            682.8,
            683.3
        ],
        [
            1710326220000,
            683.5,
            684.25,
            683.5,
            683.8
        ],
        [
            1710326280000,
            683.8,
            683.8,
            683.65,
            683.65
        ],
        [
            1710326340000,
            683.9,
            683.9,
            683.1,
            683.4
        ],
        [
            1710326400000,
            683.1,
            683.1,
            683.1,
            683.1
        ],
        [
            1710326460000,
            683.25,
            683.75,
            683.1,
            683.25
        ],
        [
            1710326520000,
            683.25,
            683.25,
            683.2,
            683.2
        ],
        [
            1710326580000,
            683.2,
            684,
            683.2,
            684
        ],
        [
            1710326640000,
            683.4,
            683.75,
            683.4,
            683.45
        ],
        [
            1710326700000,
            683.9,
            684,
            683.9,
            684
        ],
        [
            1710326760000,
            683.6,
            683.6,
            682.1,
            682.1
        ],
        [
            1710326820000,
            682.7,
            682.8,
            682.2,
            682.8
        ],
        [
            1710326880000,
            682.6,
            682.95,
            682.5,
            682.6
        ],
        [
            1710326940000,
            682.9,
            682.9,
            682.2,
            682.25
        ],
        [
            1710327000000,
            682.25,
            682.6,
            682.25,
            682.45
        ],
        [
            1710327060000,
            682.25,
            682.35,
            682,
            682
        ],
        [
            1710327120000,
            681.95,
            681.95,
            680.85,
            681.15
        ],
        [
            1710327180000,
            681.1,
            681.9,
            681.1,
            681.9
        ],
        [
            1710327240000,
            682,
            683.55,
            682,
            682.45
        ],
        [
            1710327300000,
            682.5,
            682.7,
            681.65,
            682.25
        ],
        [
            1710327360000,
            682.75,
            682.95,
            682.3,
            682.95
        ],
        [
            1710327420000,
            682.95,
            683.2,
            682.4,
            682.8
        ],
        [
            1710327480000,
            683.3,
            684.9,
            683,
            684.25
        ],
        [
            1710327540000,
            684.9,
            685,
            684.55,
            685
        ],
        [
            1710327600000,
            685,
            685,
            684.6,
            684.6
        ],
        [
            1710327660000,
            684.55,
            684.55,
            683.8,
            683.8
        ],
        [
            1710327720000,
            684.3,
            684.45,
            683.85,
            683.85
        ],
        [
            1710327780000,
            684,
            684,
            683,
            683
        ],
        [
            1710327840000,
            683.4,
            683.45,
            683,
            683
        ],
        [
            1710327900000,
            683,
            683,
            682.1,
            682.55
        ],
        [
            1710327960000,
            682.95,
            683.4,
            682.5,
            683.2
        ],
        [
            1710328020000,
            683,
            683.3,
            682.8,
            683.15
        ],
        [
            1710328080000,
            683.15,
            684,
            682.9,
            684
        ],
        [
            1710328140000,
            684,
            684,
            683.65,
            684
        ],
        [
            1710328200000,
            684,
            684,
            682.9,
            683.3
        ],
        [
            1710328260000,
            683.5,
            683.7,
            682.3,
            682.9
        ],
        [
            1710328320000,
            682.95,
            683.65,
            682.75,
            683.15
        ],
        [
            1710328380000,
            683.6,
            683.6,
            682.7,
            683.2
        ],
        [
            1710328440000,
            683.15,
            683.5,
            683.05,
            683.45
        ],
        [
            1710328500000,
            683.4,
            683.7,
            682.45,
            682.45
        ],
        [
            1710328560000,
            682.45,
            683.7,
            681.9,
            683.7
        ],
        [
            1710328620000,
            683.8,
            684,
            683.15,
            683.25
        ],
        [
            1710328680000,
            683.7,
            683.7,
            682.95,
            683.4
        ],
        [
            1710328740000,
            682.95,
            683,
            682.05,
            682.6
        ],
        [
            1710328800000,
            682.6,
            682.65,
            682.4,
            682.4
        ],
        [
            1710328860000,
            682.35,
            682.95,
            682.35,
            682.7
        ],
        [
            1710328920000,
            682.7,
            682.7,
            682.2,
            682.4
        ],
        [
            1710328980000,
            682.4,
            682.4,
            681.1,
            682.4
        ],
        [
            1710329040000,
            682.35,
            682.35,
            682,
            682.2
        ],
        [
            1710329100000,
            682.35,
            682.35,
            681.45,
            681.95
        ],
        [
            1710329160000,
            682,
            682.95,
            681.45,
            682.95
        ],
        [
            1710329220000,
            682.95,
            683.95,
            682.9,
            683.85
        ],
        [
            1710329280000,
            683.7,
            683.7,
            683,
            683
        ],
        [
            1710329340000,
            683.3,
            683.3,
            682.25,
            682.25
        ],
        [
            1710329400000,
            682.55,
            682.6,
            681.95,
            681.95
        ],
        [
            1710329460000,
            682.25,
            682.25,
            681.15,
            681.9
        ],
        [
            1710329520000,
            681.5,
            682.4,
            681.4,
            682.4
        ],
        [
            1710329580000,
            682.8,
            682.8,
            682.15,
            682.2
        ],
        [
            1710329640000,
            682.2,
            682.2,
            681.5,
            681.95
        ],
        [
            1710329700000,
            682.05,
            682.45,
            681.9,
            682.2
        ],
        [
            1710329760000,
            682.4,
            682.9,
            681.7,
            682.45
        ],
        [
            1710329820000,
            681.8,
            683.35,
            681.8,
            683.05
        ],
        [
            1710329880000,
            683.35,
            684.25,
            683.3,
            683.85
        ],
        [
            1710329940000,
            684,
            684.25,
            683.8,
            683.8
        ],
        [
            1710330000000,
            683.65,
            683.8,
            682.8,
            683.55
        ],
        [
            1710330060000,
            683.5,
            683.95,
            683.3,
            683.45
        ],
        [
            1710330120000,
            683.8,
            684,
            683.45,
            683.8
        ],
        [
            1710330180000,
            683.95,
            683.95,
            683.05,
            683.7
        ],
        [
            1710330240000,
            683.75,
            684,
            683.2,
            684
        ],
        [
            1710330300000,
            684,
            684,
            683.75,
            683.9
        ],
        [
            1710330360000,
            683.9,
            685,
            683.35,
            685
        ],
        [
            1710330420000,
            684.15,
            684.45,
            683.85,
            683.85
        ],
        [
            1710330480000,
            684,
            684,
            683.2,
            683.75
        ],
        [
            1710330540000,
            683.85,
            683.9,
            682.65,
            683.9
        ],
        [
            1710330600000,
            684,
            684.05,
            683.1,
            683.1
        ],
        [
            1710330660000,
            683.45,
            683.5,
            681.9,
            682.6
        ],
        [
            1710330720000,
            682.4,
            682.6,
            681.9,
            682.15
        ],
        [
            1710330780000,
            682.2,
            682.55,
            681.2,
            682.55
        ],
        [
            1710330840000,
            682.55,
            682.6,
            681.45,
            682.15
        ],
        [
            1710330900000,
            682.2,
            682.35,
            681.25,
            681.7
        ],
        [
            1710330960000,
            681.7,
            682.3,
            681.3,
            681.3
        ],
        [
            1710331020000,
            681.7,
            681.85,
            681.25,
            681.5
        ],
        [
            1710331080000,
            681.65,
            681.65,
            680.7,
            680.95
        ],
        [
            1710331140000,
            681,
            682.45,
            680.1,
            682.45
        ],
        [
            1710331200000,
            682.6,
            684,
            681.8,
            681.8
        ],
        [
            1710331260000,
            681.8,
            682.3,
            681.15,
            681.7
        ],
        [
            1710331320000,
            682.15,
            682.15,
            680.8,
            681.25
        ],
        [
            1710331380000,
            681.65,
            681.8,
            681.2,
            681.4
        ],
        [
            1710331440000,
            681.75,
            682.05,
            680.4,
            680.4
        ],
        [
            1710331500000,
            680.85,
            681.6,
            680.7,
            681.25
        ],
        [
            1710331560000,
            681.6,
            681.6,
            680.8,
            680.85
        ],
        [
            1710331620000,
            681.45,
            682.85,
            681.45,
            682.25
        ],
        [
            1710331680000,
            682.45,
            682.55,
            681.25,
            681.25
        ],
        [
            1710331740000,
            681.5,
            682.15,
            681.3,
            681.95
        ],
        [
            1710331800000,
            682.35,
            682.5,
            681.85,
            681.9
        ],
        [
            1710331860000,
            682.1,
            682.1,
            681.1,
            681.65
        ],
        [
            1710331920000,
            681.5,
            681.5,
            681,
            681
        ],
        [
            1710331980000,
            681.75,
            681.95,
            681.1,
            681.1
        ],
        [
            1710332040000,
            681.65,
            681.65,
            681,
            681.4
        ],
        [
            1710332100000,
            680.75,
            681.5,
            680.75,
            681.25
        ],
        [
            1710332160000,
            681.8,
            681.95,
            681,
            681.6
        ],
        [
            1710332220000,
            681.55,
            681.55,
            681,
            681.4
        ],
        [
            1710332280000,
            681.4,
            681.4,
            680.2,
            680.2
        ],
        [
            1710332340000,
            680.3,
            680.45,
            678.8,
            679.85
        ],
        [
            1710332400000,
            680.2,
            680.6,
            679.15,
            679.55
        ],
        [
            1710332460000,
            679.5,
            679.5,
            676.85,
            678
        ],
        [
            1710332520000,
            678.4,
            678.4,
            676.7,
            676.8
        ],
        [
            1710332580000,
            677.1,
            678.75,
            676,
            678.75
        ],
        [
            1710332640000,
            678.85,
            678.9,
            676.85,
            677.25
        ],
        [
            1710332700000,
            677.25,
            677.25,
            675.85,
            676.9
        ],
        [
            1710332760000,
            677,
            678.5,
            676.5,
            678.45
        ],
        [
            1710332820000,
            678.3,
            679.1,
            678,
            678.75
        ],
        [
            1710332880000,
            679,
            679.3,
            678.55,
            679.3
        ],
        [
            1710332940000,
            679.3,
            679.5,
            679.05,
            679.5
        ],
        [
            1710333000000,
            679.25,
            679.25,
            677.85,
            678.6
        ],
        [
            1710333060000,
            678.35,
            678.35,
            676.25,
            677.1
        ],
        [
            1710333120000,
            676.95,
            677.05,
            676.15,
            676.75
        ],
        [
            1710333180000,
            676.55,
            677.6,
            676.4,
            677.6
        ],
        [
            1710333240000,
            677.35,
            678.55,
            677.35,
            678.55
        ],
        [
            1710333300000,
            678.25,
            678.4,
            677.2,
            677.6
        ],
        [
            1710333360000,
            677.65,
            677.65,
            676.85,
            677.3
        ],
        [
            1710333420000,
            677.3,
            677.3,
            676.95,
            677.3
        ],
        [
            1710333480000,
            677.6,
            678.35,
            677.35,
            677.55
        ],
        [
            1710333540000,
            677.8,
            677.8,
            677.35,
            677.65
        ],
        [
            1710333600000,
            677.65,
            679.45,
            677.4,
            679.45
        ],
        [
            1710333660000,
            679.45,
            680,
            679.3,
            679.9
        ],
        [
            1710333720000,
            679.55,
            680,
            679.5,
            679.85
        ],
        [
            1710333780000,
            679.55,
            679.8,
            678.75,
            679.5
        ],
        [
            1710333840000,
            679.25,
            679.65,
            678.8,
            678.8
        ],
        [
            1710333900000,
            678.8,
            679.2,
            678.55,
            679.2
        ],
        [
            1710333960000,
            679.35,
            679.35,
            678.85,
            678.95
        ],
        [
            1710334020000,
            679,
            679.65,
            678.9,
            679.05
        ],
        [
            1710334080000,
            678.9,
            679.2,
            678.9,
            678.9
        ],
        [
            1710334140000,
            679,
            679.8,
            678.9,
            679.8
        ],
        [
            1710334200000,
            679.95,
            679.95,
            678.65,
            678.65
        ],
        [
            1710334260000,
            679.2,
            679.2,
            678.55,
            678.55
        ],
        [
            1710334320000,
            678.35,
            678.35,
            676.75,
            676.75
        ],
        [
            1710334380000,
            677,
            677.05,
            676.5,
            676.5
        ],
        [
            1710334440000,
            676.5,
            676.5,
            675.85,
            675.85
        ],
        [
            1710334500000,
            675.85,
            677.9,
            675.85,
            677.9
        ],
        [
            1710334560000,
            678.6,
            679.2,
            677.2,
            677.2
        ],
        [
            1710334620000,
            677.2,
            678,
            677.2,
            677.6
        ],
        [
            1710334680000,
            677.7,
            678,
            677.55,
            677.8
        ],
        [
            1710334740000,
            677.8,
            678,
            677.8,
            678
        ],
        [
            1710334800000,
            677.95,
            678,
            677.95,
            677.95
        ],
        [
            1710334860000,
            677.75,
            677.75,
            676.8,
            676.8
        ],
        [
            1710334920000,
            676.75,
            677.15,
            676.5,
            676.65
        ],
        [
            1710334980000,
            676.9,
            677.3,
            676.8,
            676.95
        ],
        [
            1710335040000,
            677,
            677.7,
            676.3,
            676.3
        ],
        [
            1710335100000,
            676.35,
            676.35,
            675.25,
            675.4
        ],
        [
            1710335160000,
            675.75,
            676.55,
            675.75,
            676.05
        ],
        [
            1710335220000,
            676.05,
            676.3,
            675.55,
            675.55
        ],
        [
            1710335280000,
            676.05,
            676.05,
            675.55,
            675.55
        ],
        [
            1710335340000,
            675.55,
            675.9,
            673.8,
            673.95
        ],
        [
            1710335400000,
            673.8,
            674.3,
            673.8,
            674.3
        ],
        [
            1710335460000,
            674.05,
            675.65,
            674.05,
            674.9
        ],
        [
            1710335520000,
            674.9,
            675.65,
            674.35,
            674.6
        ],
        [
            1710335580000,
            674.6,
            675,
            673.85,
            674.1
        ],
        [
            1710335640000,
            674.1,
            674.7,
            674.1,
            674.5
        ],
        [
            1710335700000,
            674.5,
            674.8,
            674.3,
            674.4
        ],
        [
            1710335760000,
            674.45,
            674.9,
            674.45,
            674.45
        ],
        [
            1710335820000,
            674.45,
            674.7,
            673.85,
            674
        ],
        [
            1710335880000,
            673.85,
            674.2,
            673.85,
            673.85
        ],
        [
            1710335940000,
            673.85,
            674.1,
            673.6,
            674.05
        ],
        [
            1710336000000,
            674.05,
            674.05,
            673.45,
            673.65
        ],
        [
            1710336060000,
            673.65,
            673.65,
            673.6,
            673.6
        ],
        [
            1710336120000,
            673.6,
            673.9,
            672.9,
            672.9
        ],
        [
            1710336180000,
            674.5,
            675,
            674.25,
            674.7
        ],
        [
            1710336240000,
            674.65,
            674.65,
            674.3,
            674.65
        ],
        [
            1710336300000,
            674.65,
            674.9,
            674.3,
            674.3
        ],
        [
            1710336360000,
            674.3,
            674.65,
            674.3,
            674.3
        ],
        [
            1710336420000,
            674.3,
            674.6,
            674.3,
            674.4
        ],
        [
            1710336480000,
            674.4,
            674.65,
            674.35,
            674.6
        ],
        [
            1710336540000,
            674.6,
            675,
            674.6,
            674.7
        ],
        [
            1710336600000,
            674.7,
            675,
            674.7,
            675
        ],
        [
            1710336660000,
            674.7,
            675,
            674.55,
            674.55
        ],
        [
            1710336720000,
            674.55,
            674.55,
            674.5,
            674.55
        ],
        [
            1710336780000,
            674.6,
            674.95,
            674.3,
            674.3
        ],
        [
            1710336840000,
            674.3,
            674.5,
            673.95,
            674
        ],
        [
            1710336900000,
            674.05,
            674.05,
            673.15,
            673.15
        ],
        [
            1710336960000,
            673.05,
            673.45,
            673,
            673.45
        ],
        [
            1710337020000,
            673.4,
            673.85,
            673.3,
            673.4
        ],
        [
            1710337080000,
            673.4,
            673.7,
            673.4,
            673.45
        ],
        [
            1710337140000,
            673.45,
            673.55,
            673.05,
            673.25
        ],
        [
            1710337200000,
            673.25,
            673.25,
            672.05,
            672.05
        ],
        [
            1710337260000,
            672,
            672.05,
            671.85,
            672.05
        ],
        [
            1710337320000,
            672.05,
            672.25,
            672,
            672
        ],
        [
            1710337380000,
            672.4,
            672.4,
            672.1,
            672.15
        ],
        [
            1710337440000,
            672.15,
            672.2,
            671.3,
            672.2
        ],
        [
            1710337500000,
            672.2,
            673.2,
            672.2,
            672.8
        ],
        [
            1710337560000,
            672.75,
            673.2,
            672.75,
            673
        ],
        [
            1710337620000,
            673,
            673,
            672.3,
            672.3
        ],
        [
            1710337680000,
            672.1,
            672.1,
            671.65,
            672
        ],
        [
            1710337740000,
            671.95,
            671.95,
            671.8,
            671.9
        ],
        [
            1710337800000,
            671.85,
            671.85,
            670.7,
            670.9
        ],
        [
            1710337860000,
            671.25,
            671.75,
            670.95,
            671.55
        ],
        [
            1710337920000,
            671.55,
            672.65,
            671.55,
            672.45
        ],
        [
            1710337980000,
            672.45,
            673.05,
            672.45,
            672.65
        ],
        [
            1710338040000,
            672.5,
            672.5,
            671.5,
            671.5
        ],
        [
            1710338100000,
            671.55,
            672.1,
            671.5,
            672.05
        ],
        [
            1710338160000,
            671.95,
            672.4,
            671.9,
            672.4
        ],
        [
            1710338220000,
            672.4,
            672.95,
            672.05,
            672.05
        ],
        [
            1710338280000,
            672.1,
            672.1,
            672,
            672.05
        ],
        [
            1710338340000,
            673.15,
            673.2,
            673,
            673
        ],
        [
            1710338400000,
            673.15,
            673.6,
            672.5,
            672.55
        ],
        [
            1710338460000,
            672.45,
            672.7,
            672.05,
            672.05
        ],
        [
            1710338520000,
            672.05,
            672.3,
            672.05,
            672.3
        ],
        [
            1710338580000,
            672.3,
            672.3,
            672.15,
            672.15
        ],
        [
            1710338640000,
            672.15,
            672.4,
            672.15,
            672.3
        ],
        [
            1710338700000,
            672.35,
            672.6,
            672.2,
            672.2
        ],
        [
            1710338760000,
            672.2,
            672.5,
            672.05,
            672.05
        ],
        [
            1710338820000,
            672.05,
            672.05,
            672,
            672
        ],
        [
            1710338880000,
            672,
            672,
            671.65,
            671.7
        ],
        [
            1710338940000,
            671.85,
            672.45,
            671.85,
            671.85
        ],
        [
            1710339000000,
            671.9,
            672.2,
            671.75,
            671.75
        ],
        [
            1710339060000,
            671.65,
            672.2,
            671.55,
            672.2
        ],
        [
            1710339120000,
            672.2,
            672.2,
            671.75,
            671.75
        ],
        [
            1710339180000,
            671.75,
            672.1,
            671.75,
            671.9
        ],
        [
            1710339240000,
            671.9,
            671.9,
            671.8,
            671.85
        ],
        [
            1710339300000,
            671.8,
            671.85,
            671.45,
            671.45
        ],
        [
            1710339360000,
            671.5,
            671.65,
            671.15,
            671.15
        ],
        [
            1710339420000,
            670.6,
            671.15,
            670.5,
            671.15
        ],
        [
            1710339480000,
            671.15,
            671.15,
            670.7,
            670.95
        ],
        [
            1710339540000,
            670.95,
            671.15,
            669.95,
            671.15
        ],
        [
            1710339600000,
            671.35,
            671.5,
            671.1,
            671.1
        ],
        [
            1710339660000,
            671.05,
            671.05,
            670,
            670
        ],
        [
            1710339720000,
            669.45,
            669.45,
            668.4,
            669.3
        ],
        [
            1710339780000,
            669.35,
            669.7,
            669,
            669.15
        ],
        [
            1710339840000,
            669.1,
            669.15,
            668.25,
            668.25
        ],
        [
            1710339900000,
            668.15,
            669.3,
            668.1,
            669.3
        ],
        [
            1710339960000,
            670.1,
            670.1,
            669.75,
            670.05
        ],
        [
            1710340020000,
            670.05,
            670.1,
            669.45,
            669.45
        ],
        [
            1710340080000,
            669.5,
            670,
            669,
            669.5
        ],
        [
            1710340140000,
            669.5,
            670.05,
            669.5,
            669.75
        ],
        [
            1710340200000,
            669.75,
            669.85,
            669.55,
            669.55
        ],
        [
            1710340260000,
            669.55,
            670.55,
            669.55,
            670.55
        ],
        [
            1710340320000,
            670.55,
            670.55,
            669.3,
            669.3
        ],
        [
            1710340380000,
            669.95,
            670.15,
            668.45,
            668.45
        ],
        [
            1710340440000,
            668.4,
            668.95,
            668.4,
            668.55
        ],
        [
            1710340500000,
            668.55,
            670.2,
            668.55,
            670.2
        ],
        [
            1710340560000,
            669.65,
            670.35,
            669,
            669
        ],
        [
            1710340620000,
            669,
            669,
            668.2,
            668.2
        ],
        [
            1710340680000,
            668,
            669.15,
            667.3,
            668.8
        ],
        [
            1710340740000,
            669.45,
            669.45,
            668.55,
            669.2
        ],
        [
            1710340800000,
            669.7,
            669.7,
            668.3,
            668.85
        ],
        [
            1710340860000,
            668.45,
            668.75,
            666.8,
            666.8
        ],
        [
            1710340920000,
            666.75,
            668,
            666.6,
            667.6
        ],
        [
            1710340980000,
            667.6,
            667.85,
            667.55,
            667.6
        ],
        [
            1710341040000,
            667.6,
            667.7,
            667.2,
            667.7
        ],
        [
            1710341100000,
            667.75,
            667.75,
            666.45,
            667.25
        ],
        [
            1710341160000,
            667.65,
            667.65,
            666.25,
            666.35
        ],
        [
            1710341220000,
            666.35,
            668.05,
            666.35,
            667.65
        ],
        [
            1710341280000,
            667.6,
            667.85,
            666,
            666
        ],
        [
            1710341340000,
            666,
            666.7,
            665.25,
            666.15
        ],
        [
            1710341400000,
            667,
            667.55,
            666.3,
            667.45
        ],
        [
            1710341460000,
            667.75,
            668,
            667.5,
            667.95
        ],
        [
            1710341520000,
            668,
            668,
            667.1,
            667.15
        ],
        [
            1710341580000,
            667.55,
            667.55,
            666.25,
            667.05
        ],
        [
            1710341640000,
            667.35,
            667.55,
            666.85,
            667.25
        ],
        [
            1710341700000,
            667.25,
            667.5,
            665.5,
            665.5
        ],
        [
            1710341760000,
            665.5,
            667.25,
            665.5,
            666.15
        ],
        [
            1710341820000,
            666.6,
            666.6,
            665.25,
            665.45
        ],
        [
            1710341880000,
            665.9,
            666.15,
            665.45,
            666.15
        ],
        [
            1710341940000,
            665.8,
            666.7,
            665.8,
            666.6
        ],
        [
            1710342000000,
            666.65,
            669.2,
            666.65,
            669.15
        ],
        [
            1710342060000,
            669.45,
            672.45,
            669.45,
            672.45
        ],
        [
            1710342120000,
            672.2,
            673,
            671.85,
            671.85
        ],
        [
            1710342180000,
            671.5,
            672.4,
            671.5,
            672.4
        ],
        [
            1710342240000,
            672.9,
            673,
            672,
            672.7
        ],
        [
            1710342300000,
            672.5,
            673.15,
            672.45,
            673.15
        ],
        [
            1710342360000,
            671.7,
            671.7,
            670.2,
            670.2
        ],
        [
            1710342420000,
            669.95,
            673.3,
            669.95,
            671.1
        ],
        [
            1710342480000,
            671.55,
            672.5,
            671,
            671.45
        ],
        [
            1710342540000,
            671.85,
            671.95,
            671.5,
            671.65
        ],
        [
            1710342600000,
            671.65,
            672.25,
            671.65,
            671.8
        ],
        [
            1710342660000,
            671.75,
            671.75,
            669.45,
            669.45
        ],
        [
            1710342720000,
            669.8,
            670.85,
            669.65,
            670.15
        ],
        [
            1710342780000,
            670.35,
            671.95,
            670,
            671.95
        ],
        [
            1710342840000,
            671.95,
            672.15,
            671.7,
            671.8
        ],
        [
            1710342900000,
            671.85,
            671.9,
            670.6,
            670.85
        ],
        [
            1710342960000,
            670.7,
            670.7,
            669.5,
            669.9
        ],
        [
            1710343020000,
            669.8,
            670.15,
            669.75,
            669.75
        ],
        [
            1710343080000,
            668.6,
            668.85,
            667.3,
            668.1
        ],
        [
            1710343140000,
            667.75,
            668.5,
            667.75,
            668.25
        ],
        [
            1710343200000,
            668.45,
            668.8,
            667.7,
            667.9
        ],
        [
            1710343260000,
            667.65,
            668.55,
            667.65,
            668.55
        ],
        [
            1710343320000,
            668.5,
            670.15,
            668.5,
            670.15
        ],
        [
            1710343380000,
            670,
            670.05,
            669.95,
            670.05
        ],
        [
            1710343440000,
            670,
            670,
            668.75,
            668.75
        ],
        [
            1710343500000,
            668.75,
            669.2,
            668.7,
            669.2
        ],
        [
            1710343560000,
            669.7,
            670.2,
            669.7,
            670.15
        ],
        [
            1710343620000,
            670.15,
            670.2,
            670.15,
            670.15
        ],
        [
            1710343680000,
            670.15,
            671.95,
            669.2,
            669.2
        ],
        [
            1710343740000,
            669.25,
            669.25,
            665.75,
            667.5
        ],
        [
            1710343800000,
            669,
            669,
            669,
            669
        ],
        [
            1710407700000,
            670.9,
            670.9,
            660.6,
            660.6
        ],
        [
            1710407760000,
            660.35,
            670.05,
            660.35,
            670.05
        ],
        [
            1710407820000,
            672.25,
            674,
            670.7,
            673.8
        ],
        [
            1710407880000,
            672.65,
            673.05,
            671.3,
            671.75
        ],
        [
            1710407940000,
            671.7,
            672.85,
            670.55,
            672.85
        ],
        [
            1710408000000,
            673,
            675.85,
            673,
            674.45
        ],
        [
            1710408060000,
            675.15,
            675.25,
            674.4,
            675.15
        ],
        [
            1710408120000,
            674.55,
            674.55,
            669.05,
            669.05
        ],
        [
            1710408180000,
            669.1,
            669.35,
            667.35,
            667.35
        ],
        [
            1710408240000,
            669.65,
            671.05,
            669.65,
            671.05
        ],
        [
            1710408300000,
            671.35,
            671.55,
            670.5,
            670.8
        ],
        [
            1710408360000,
            670.5,
            672.35,
            670.5,
            671.9
        ],
        [
            1710408420000,
            671.9,
            672.45,
            671.35,
            671.35
        ],
        [
            1710408480000,
            671.25,
            671.25,
            670.4,
            670.4
        ],
        [
            1710408540000,
            670.5,
            673.55,
            670.5,
            673.55
        ],
        [
            1710408600000,
            673.8,
            673.8,
            672.6,
            672.6
        ],
        [
            1710408660000,
            672.5,
            672.65,
            672.05,
            672.55
        ],
        [
            1710408720000,
            671.95,
            672,
            671.4,
            671.4
        ],
        [
            1710408780000,
            671.4,
            672.05,
            671.4,
            672.05
        ],
        [
            1710408840000,
            672.8,
            674.4,
            672.8,
            674.4
        ],
        [
            1710408900000,
            674.45,
            674.55,
            674,
            674.05
        ],
        [
            1710408960000,
            674.5,
            674.5,
            673.4,
            673.45
        ],
        [
            1710409020000,
            674.25,
            675.05,
            674.25,
            675.05
        ],
        [
            1710409080000,
            675.05,
            677,
            675.05,
            676.4
        ],
        [
            1710409140000,
            675.8,
            676.25,
            674.4,
            674.4
        ],
        [
            1710409200000,
            674.35,
            674.35,
            673.85,
            673.85
        ],
        [
            1710409260000,
            673.8,
            673.8,
            673.15,
            673.15
        ],
        [
            1710409320000,
            673.55,
            673.8,
            673.15,
            673.25
        ],
        [
            1710409380000,
            673.45,
            673.5,
            673.2,
            673.35
        ],
        [
            1710409440000,
            673.35,
            673.35,
            672.05,
            673.05
        ],
        [
            1710409500000,
            673.4,
            673.85,
            673.1,
            673.8
        ],
        [
            1710409560000,
            673.7,
            673.7,
            673.5,
            673.5
        ],
        [
            1710409620000,
            673.35,
            673.35,
            672.5,
            673
        ],
        [
            1710409680000,
            673.05,
            673.75,
            673,
            673.75
        ],
        [
            1710409740000,
            673.85,
            673.9,
            673.5,
            673.5
        ],
        [
            1710409800000,
            673.5,
            674.1,
            673.5,
            673.95
        ],
        [
            1710409860000,
            673.5,
            673.5,
            673.15,
            673.15
        ],
        [
            1710409920000,
            673.55,
            673.95,
            673.3,
            673.45
        ],
        [
            1710409980000,
            674.85,
            674.85,
            674.15,
            674.7
        ],
        [
            1710410040000,
            674.45,
            674.95,
            674.45,
            674.95
        ],
        [
            1710410100000,
            674.95,
            675,
            674.7,
            674.7
        ],
        [
            1710410160000,
            674.7,
            674.7,
            673.35,
            673.35
        ],
        [
            1710410220000,
            673.5,
            674.65,
            673.5,
            674.65
        ],
        [
            1710410280000,
            674.2,
            674.9,
            674.2,
            674.45
        ],
        [
            1710410340000,
            674.4,
            674.4,
            674.05,
            674.05
        ],
        [
            1710410400000,
            674.2,
            675.5,
            673.1,
            673.1
        ],
        [
            1710410460000,
            673,
            673,
            672.15,
            672.8
        ],
        [
            1710410520000,
            672.85,
            672.95,
            672.85,
            672.85
        ],
        [
            1710410580000,
            672.65,
            673.1,
            672.5,
            673
        ],
        [
            1710410640000,
            673,
            673.5,
            672.55,
            673.5
        ],
        [
            1710410700000,
            674,
            674.15,
            672.6,
            673
        ],
        [
            1710410760000,
            673,
            673.3,
            672.65,
            673.2
        ],
        [
            1710410820000,
            673.25,
            673.25,
            673.2,
            673.25
        ],
        [
            1710410880000,
            673,
            673.25,
            672.85,
            673.25
        ],
        [
            1710410940000,
            673.25,
            673.35,
            672.1,
            672.1
        ],
        [
            1710411000000,
            672.05,
            672.5,
            671.6,
            672
        ],
        [
            1710411060000,
            672.1,
            672.1,
            671.35,
            671.5
        ],
        [
            1710411120000,
            671.6,
            671.95,
            671,
            671
        ],
        [
            1710411180000,
            670.65,
            670.75,
            670.05,
            670.75
        ],
        [
            1710411240000,
            670.55,
            671.35,
            670.55,
            671.35
        ],
        [
            1710411300000,
            671.15,
            671.95,
            671.1,
            671.95
        ],
        [
            1710411360000,
            672,
            672,
            670.65,
            670.65
        ],
        [
            1710411420000,
            670.6,
            671.3,
            670.6,
            671
        ],
        [
            1710411480000,
            670.85,
            670.85,
            669.9,
            670
        ],
        [
            1710411540000,
            670,
            670,
            669.9,
            670
        ],
        [
            1710411600000,
            670,
            670,
            669.75,
            669.85
        ],
        [
            1710411660000,
            670,
            670,
            669.3,
            669.5
        ],
        [
            1710411720000,
            669.5,
            669.5,
            668.85,
            669
        ],
        [
            1710411780000,
            669.5,
            670,
            669.5,
            669.65
        ],
        [
            1710411840000,
            669.6,
            670.1,
            669.3,
            669.45
        ],
        [
            1710411900000,
            669.6,
            670.05,
            669.6,
            670
        ],
        [
            1710411960000,
            670,
            670,
            669.95,
            669.95
        ],
        [
            1710412020000,
            669.9,
            669.95,
            668.65,
            668.65
        ],
        [
            1710412080000,
            668.95,
            669.65,
            668.95,
            669.35
        ],
        [
            1710412140000,
            669.5,
            670,
            669.5,
            669.9
        ],
        [
            1710412200000,
            669.9,
            670.05,
            669.75,
            670
        ],
        [
            1710412260000,
            670,
            670.95,
            670,
            670.5
        ],
        [
            1710412320000,
            670.5,
            670.6,
            670.25,
            670.25
        ],
        [
            1710412380000,
            670.3,
            670.75,
            670.25,
            670.45
        ],
        [
            1710412440000,
            670.45,
            670.5,
            670.2,
            670.5
        ],
        [
            1710412500000,
            670.3,
            670.8,
            670.3,
            670.5
        ],
        [
            1710412560000,
            670.5,
            673.95,
            670.3,
            673.55
        ],
        [
            1710412620000,
            673.55,
            673.7,
            673.1,
            673.5
        ],
        [
            1710412680000,
            673.3,
            673.3,
            673.05,
            673.3
        ],
        [
            1710412740000,
            673.15,
            673.3,
            673.15,
            673.2
        ],
        [
            1710412800000,
            673.3,
            673.3,
            673.1,
            673.3
        ],
        [
            1710412860000,
            673.3,
            673.3,
            673.2,
            673.25
        ],
        [
            1710412920000,
            673.25,
            673.3,
            673.1,
            673.3
        ],
        [
            1710412980000,
            673.3,
            673.7,
            672.6,
            672.6
        ],
        [
            1710413040000,
            672.6,
            672.6,
            672.45,
            672.45
        ],
        [
            1710413100000,
            672.4,
            672.65,
            671.9,
            671.9
        ],
        [
            1710413160000,
            671.75,
            672,
            671.25,
            671.25
        ],
        [
            1710413220000,
            671.15,
            672.35,
            671.15,
            671.45
        ],
        [
            1710413280000,
            671.55,
            671.95,
            671.3,
            671.5
        ],
        [
            1710413340000,
            671.5,
            671.55,
            671.5,
            671.5
        ],
        [
            1710413400000,
            671.55,
            671.55,
            671.5,
            671.55
        ],
        [
            1710413460000,
            671.55,
            672.8,
            671.5,
            671.55
        ],
        [
            1710413520000,
            671.55,
            671.55,
            671.5,
            671.5
        ],
        [
            1710413580000,
            671.55,
            671.55,
            671.5,
            671.5
        ],
        [
            1710413640000,
            671.5,
            671.55,
            671.5,
            671.5
        ],
        [
            1710413700000,
            671.55,
            671.55,
            671.5,
            671.55
        ],
        [
            1710413760000,
            671.55,
            671.6,
            671.5,
            671.5
        ],
        [
            1710413820000,
            671.55,
            671.6,
            671.5,
            671.55
        ],
        [
            1710413880000,
            671.55,
            673.45,
            671.5,
            673.25
        ],
        [
            1710413940000,
            673.65,
            673.7,
            673.35,
            673.5
        ],
        [
            1710414000000,
            673.5,
            674,
            673.5,
            674
        ],
        [
            1710414060000,
            675,
            677,
            675,
            677
        ],
        [
            1710414120000,
            676.7,
            678.35,
            676.4,
            678.35
        ],
        [
            1710414180000,
            678.25,
            678.6,
            677.05,
            677.05
        ],
        [
            1710414240000,
            677.05,
            677.7,
            677.05,
            677.55
        ],
        [
            1710414300000,
            677.55,
            678.35,
            677.55,
            678.35
        ],
        [
            1710414360000,
            679.5,
            679.5,
            678.45,
            678.6
        ],
        [
            1710414420000,
            679.15,
            679.15,
            678.6,
            678.6
        ],
        [
            1710414480000,
            678.5,
            678.95,
            678.35,
            678.35
        ],
        [
            1710414540000,
            678.35,
            678.95,
            678.3,
            678.3
        ],
        [
            1710414600000,
            678.3,
            678.3,
            677.1,
            677.4
        ],
        [
            1710414660000,
            677.3,
            677.7,
            676.45,
            676.45
        ],
        [
            1710414720000,
            676.5,
            676.5,
            675.75,
            675.95
        ],
        [
            1710414780000,
            675.95,
            676,
            675,
            675
        ],
        [
            1710414840000,
            675.35,
            675.35,
            675,
            675.05
        ],
        [
            1710414900000,
            675.3,
            675.3,
            675,
            675.15
        ],
        [
            1710414960000,
            675,
            675.35,
            675,
            675
        ],
        [
            1710415020000,
            675,
            675.05,
            675,
            675.05
        ],
        [
            1710415080000,
            675.05,
            675.25,
            675,
            675
        ],
        [
            1710415140000,
            675,
            675,
            675,
            675
        ],
        [
            1710415200000,
            675,
            675.2,
            675,
            675.2
        ],
        [
            1710415260000,
            675.05,
            675.25,
            675,
            675
        ],
        [
            1710415320000,
            675,
            675.3,
            675,
            675.25
        ],
        [
            1710415380000,
            675.4,
            675.65,
            675.25,
            675.25
        ],
        [
            1710415440000,
            675.25,
            675.5,
            675.25,
            675.5
        ],
        [
            1710415500000,
            675.65,
            675.95,
            675.65,
            675.7
        ],
        [
            1710415560000,
            675.85,
            675.95,
            675.7,
            675.7
        ],
        [
            1710415620000,
            675.7,
            675.7,
            675.7,
            675.7
        ],
        [
            1710415680000,
            675.7,
            676,
            675.7,
            675.75
        ],
        [
            1710415740000,
            675.75,
            676.2,
            675.75,
            676.05
        ],
        [
            1710415800000,
            676.15,
            676.15,
            676.05,
            676.05
        ],
        [
            1710415860000,
            676.05,
            676.3,
            675.5,
            675.5
        ],
        [
            1710415920000,
            675.3,
            675.35,
            675.3,
            675.35
        ],
        [
            1710415980000,
            675.3,
            675.5,
            675.15,
            675.15
        ],
        [
            1710416040000,
            675.45,
            675.85,
            675.45,
            675.65
        ],
        [
            1710416100000,
            675.4,
            675.65,
            675.4,
            675.65
        ],
        [
            1710416160000,
            675.4,
            675.4,
            675,
            675.2
        ],
        [
            1710416220000,
            675,
            675.2,
            675,
            675.2
        ],
        [
            1710416280000,
            675,
            676,
            675,
            676
        ],
        [
            1710416340000,
            676,
            676,
            675.25,
            675.5
        ],
        [
            1710416400000,
            675.35,
            675.8,
            675,
            675
        ],
        [
            1710416460000,
            675,
            675.85,
            675,
            675.85
        ],
        [
            1710416520000,
            675.9,
            676.05,
            675.5,
            676
        ],
        [
            1710416580000,
            675.85,
            676.45,
            675.6,
            676
        ],
        [
            1710416640000,
            676,
            676.1,
            675.8,
            676
        ],
        [
            1710416700000,
            675.85,
            676,
            675,
            675
        ],
        [
            1710416760000,
            675.05,
            676,
            675.05,
            675.75
        ],
        [
            1710416820000,
            676,
            676,
            675.55,
            675.65
        ],
        [
            1710416880000,
            675.65,
            676,
            675.55,
            675.95
        ],
        [
            1710416940000,
            675.6,
            676,
            675.6,
            675.85
        ],
        [
            1710417000000,
            676,
            676,
            675.55,
            675.55
        ],
        [
            1710417060000,
            675.55,
            676,
            675.55,
            676
        ],
        [
            1710417120000,
            675.85,
            676,
            675.65,
            675.65
        ],
        [
            1710417180000,
            676,
            676,
            675.55,
            675.95
        ],
        [
            1710417240000,
            675.45,
            676,
            675.45,
            676
        ],
        [
            1710417300000,
            676,
            676,
            675.55,
            676
        ],
        [
            1710417360000,
            675.55,
            676,
            675.55,
            676
        ],
        [
            1710417420000,
            675.8,
            676,
            675.8,
            676
        ],
        [
            1710417480000,
            675.95,
            676,
            675.85,
            676
        ],
        [
            1710417540000,
            676,
            676,
            675.85,
            676
        ],
        [
            1710417600000,
            676,
            676.1,
            675.6,
            676
        ],
        [
            1710417660000,
            676,
            676,
            675.5,
            676
        ],
        [
            1710417720000,
            675.7,
            676,
            675.7,
            675.9
        ],
        [
            1710417780000,
            675.9,
            676,
            675.9,
            676
        ],
        [
            1710417840000,
            675.7,
            676,
            675.6,
            675.8
        ],
        [
            1710417900000,
            676,
            676,
            675.65,
            676
        ],
        [
            1710417960000,
            675.6,
            676,
            675.6,
            676
        ],
        [
            1710418020000,
            676,
            676.8,
            675.65,
            676.55
        ],
        [
            1710418080000,
            676.4,
            677,
            675.95,
            676.55
        ],
        [
            1710418140000,
            676.55,
            676.95,
            676.35,
            676.35
        ],
        [
            1710418200000,
            676.3,
            676.9,
            676.3,
            676.55
        ],
        [
            1710418260000,
            676.5,
            677.05,
            676.25,
            676.4
        ],
        [
            1710418320000,
            676.35,
            676.7,
            675.2,
            675.4
        ],
        [
            1710418380000,
            675.7,
            675.75,
            675,
            675.05
        ],
        [
            1710418440000,
            675.5,
            676,
            675.35,
            675.4
        ],
        [
            1710418500000,
            675.4,
            676.35,
            675.35,
            676.35
        ],
        [
            1710418560000,
            676.35,
            676.35,
            675.8,
            676
        ],
        [
            1710418620000,
            676,
            676.75,
            676,
            676.75
        ],
        [
            1710418680000,
            676.75,
            676.75,
            675.3,
            676.25
        ],
        [
            1710418740000,
            676.25,
            676.75,
            676.25,
            676.35
        ],
        [
            1710418800000,
            676.7,
            676.7,
            675.9,
            676
        ],
        [
            1710418860000,
            676,
            676.45,
            676,
            676
        ],
        [
            1710418920000,
            677,
            677.15,
            675.6,
            675.6
        ],
        [
            1710418980000,
            675.6,
            677.2,
            675.6,
            677
        ],
        [
            1710419040000,
            676.85,
            677.65,
            676.75,
            677.65
        ],
        [
            1710419100000,
            677.8,
            677.8,
            677.15,
            677.15
        ],
        [
            1710419160000,
            677.5,
            677.65,
            677.1,
            677.35
        ],
        [
            1710419220000,
            677.35,
            677.65,
            677.35,
            677.4
        ],
        [
            1710419280000,
            677.35,
            677.85,
            677.05,
            677.1
        ],
        [
            1710419340000,
            677,
            677.8,
            677,
            677.35
        ],
        [
            1710419400000,
            677.25,
            677.65,
            677.05,
            677.05
        ],
        [
            1710419460000,
            677.1,
            677.6,
            677.05,
            677.1
        ],
        [
            1710419520000,
            677.1,
            677.75,
            677.05,
            677.25
        ],
        [
            1710419580000,
            677.6,
            677.6,
            677.05,
            677.25
        ],
        [
            1710419640000,
            677.25,
            677.7,
            677.2,
            677.7
        ],
        [
            1710419700000,
            677.7,
            677.7,
            676.85,
            676.85
        ],
        [
            1710419760000,
            676.85,
            677.6,
            676.4,
            676.4
        ],
        [
            1710419820000,
            676.45,
            677.2,
            676.45,
            676.6
        ],
        [
            1710419880000,
            677,
            677.2,
            675.85,
            676.5
        ],
        [
            1710419940000,
            676.35,
            677,
            676.2,
            677
        ],
        [
            1710420000000,
            677,
            677.4,
            677,
            677.2
        ],
        [
            1710420060000,
            677.25,
            677.25,
            676.75,
            676.75
        ],
        [
            1710420120000,
            677,
            677.05,
            676.4,
            676.4
        ],
        [
            1710420180000,
            676.45,
            676.75,
            676.2,
            676.4
        ],
        [
            1710420240000,
            676.4,
            676.4,
            675.9,
            675.9
        ],
        [
            1710420300000,
            675.55,
            675.8,
            675.05,
            675.15
        ],
        [
            1710420360000,
            675.2,
            675.6,
            675,
            675.05
        ],
        [
            1710420420000,
            675.1,
            675.95,
            675.05,
            675.05
        ],
        [
            1710420480000,
            675,
            675.7,
            675,
            675
        ],
        [
            1710420540000,
            675,
            675.45,
            675,
            675.3
        ],
        [
            1710420600000,
            675.3,
            675.45,
            675,
            675.15
        ],
        [
            1710420660000,
            675.15,
            675.8,
            675.15,
            675.5
        ],
        [
            1710420720000,
            675.7,
            675.7,
            675.5,
            675.55
        ],
        [
            1710420780000,
            675.85,
            676,
            675.55,
            675.85
        ],
        [
            1710420840000,
            675.55,
            676,
            675.55,
            675.95
        ],
        [
            1710420900000,
            675.55,
            676.3,
            675.55,
            676.3
        ],
        [
            1710420960000,
            676,
            676.8,
            676,
            676.4
        ],
        [
            1710421020000,
            676.3,
            676.6,
            676.25,
            676.25
        ],
        [
            1710421080000,
            676.25,
            676.5,
            676,
            676.5
        ],
        [
            1710421140000,
            676.05,
            676.8,
            676.05,
            676.4
        ],
        [
            1710421200000,
            676.4,
            677.2,
            676.4,
            676.7
        ],
        [
            1710421260000,
            676.65,
            677.5,
            676.65,
            677.2
        ],
        [
            1710421320000,
            677.35,
            677.7,
            677.35,
            677.45
        ],
        [
            1710421380000,
            677.4,
            677.8,
            677.05,
            677.8
        ],
        [
            1710421440000,
            677.15,
            677.8,
            677.15,
            677.45
        ],
        [
            1710421500000,
            677.45,
            677.95,
            677.05,
            677.05
        ],
        [
            1710421560000,
            677.3,
            677.75,
            677.05,
            677.2
        ],
        [
            1710421620000,
            677.2,
            677.8,
            677.2,
            677.45
        ],
        [
            1710421680000,
            677.75,
            677.75,
            677.25,
            677.6
        ],
        [
            1710421740000,
            677.6,
            677.7,
            677.2,
            677.2
        ],
        [
            1710421800000,
            677.5,
            677.5,
            676.9,
            677.35
        ],
        [
            1710421860000,
            677.35,
            677.4,
            676.9,
            676.9
        ],
        [
            1710421920000,
            676.9,
            677.25,
            675.7,
            676
        ],
        [
            1710421980000,
            675.9,
            676,
            675.05,
            675.95
        ],
        [
            1710422040000,
            676,
            676.65,
            676,
            676.2
        ],
        [
            1710422100000,
            676.2,
            676.85,
            675.8,
            676
        ],
        [
            1710422160000,
            675.95,
            676.8,
            675.95,
            676.05
        ],
        [
            1710422220000,
            676.8,
            676.9,
            676.15,
            676.3
        ],
        [
            1710422280000,
            676.25,
            676.75,
            676.05,
            676.35
        ],
        [
            1710422340000,
            676.1,
            676.55,
            676.1,
            676.1
        ],
        [
            1710422400000,
            676.55,
            676.65,
            676.3,
            676.65
        ],
        [
            1710422460000,
            676.15,
            676.35,
            676,
            676.15
        ],
        [
            1710422520000,
            675.9,
            676.2,
            675.9,
            675.9
        ],
        [
            1710422580000,
            675.85,
            676.05,
            675.6,
            675.65
        ],
        [
            1710422640000,
            676.05,
            676.05,
            675.65,
            676.05
        ],
        [
            1710422700000,
            675.75,
            676.15,
            675.75,
            676.05
        ],
        [
            1710422760000,
            676.05,
            676.15,
            675.95,
            676
        ],
        [
            1710422820000,
            675.65,
            676,
            675.55,
            675.55
        ],
        [
            1710422880000,
            675.5,
            676,
            675.3,
            675.35
        ],
        [
            1710422940000,
            675.55,
            676,
            675.4,
            675.5
        ],
        [
            1710423000000,
            676,
            676,
            675.55,
            676
        ],
        [
            1710423060000,
            675.75,
            676,
            675.65,
            675.95
        ],
        [
            1710423120000,
            676,
            676,
            675.35,
            676
        ],
        [
            1710423180000,
            676,
            676,
            675.8,
            676
        ],
        [
            1710423240000,
            676,
            676,
            675.8,
            675.85
        ],
        [
            1710423300000,
            675.85,
            676,
            675.8,
            676
        ],
        [
            1710423360000,
            675.95,
            675.95,
            675,
            675.8
        ],
        [
            1710423420000,
            675.5,
            675.6,
            675,
            675.3
        ],
        [
            1710423480000,
            675,
            675.45,
            675,
            675.05
        ],
        [
            1710423540000,
            675.45,
            675.55,
            675,
            675.55
        ],
        [
            1710423600000,
            675.55,
            675.65,
            675.45,
            675.45
        ],
        [
            1710423660000,
            675.65,
            675.7,
            675,
            675.25
        ],
        [
            1710423720000,
            675.65,
            675.7,
            675.35,
            675.35
        ],
        [
            1710423780000,
            675.3,
            675.55,
            675,
            675
        ],
        [
            1710423840000,
            675.2,
            675.65,
            675.05,
            675.15
        ],
        [
            1710423900000,
            675.15,
            675.35,
            675,
            675
        ],
        [
            1710423960000,
            675,
            675.3,
            675,
            675.25
        ],
        [
            1710424020000,
            675,
            675.4,
            675,
            675.3
        ],
        [
            1710424080000,
            675,
            675.6,
            675,
            675.55
        ],
        [
            1710424140000,
            675.55,
            675.95,
            675.55,
            675.95
        ],
        [
            1710424200000,
            675.95,
            675.95,
            675.55,
            675.85
        ],
        [
            1710424260000,
            675.55,
            675.7,
            675.3,
            675.6
        ],
        [
            1710424320000,
            675.6,
            675.8,
            675.55,
            675.55
        ],
        [
            1710424380000,
            675.5,
            675.9,
            675.4,
            675.8
        ],
        [
            1710424440000,
            675.4,
            675.65,
            675.25,
            675.25
        ],
        [
            1710424500000,
            675.25,
            675.55,
            675,
            675.45
        ],
        [
            1710424560000,
            675.2,
            675.55,
            675.1,
            675.1
        ],
        [
            1710424620000,
            675.1,
            675.4,
            675.1,
            675.35
        ],
        [
            1710424680000,
            675.35,
            675.65,
            675,
            675.2
        ],
        [
            1710424740000,
            675.5,
            675.65,
            675.25,
            675.35
        ],
        [
            1710424800000,
            675.6,
            675.9,
            675.35,
            675.9
        ],
        [
            1710424860000,
            675.5,
            676,
            675.25,
            676
        ],
        [
            1710424920000,
            675.65,
            676,
            675.65,
            675.95
        ],
        [
            1710424980000,
            675.75,
            676,
            675,
            675.65
        ],
        [
            1710425040000,
            675.3,
            676,
            675.3,
            675.95
        ],
        [
            1710425100000,
            675.95,
            676,
            675.4,
            675.5
        ],
        [
            1710425160000,
            675.4,
            675.7,
            675,
            675.45
        ],
        [
            1710425220000,
            675.45,
            675.75,
            675.1,
            675.1
        ],
        [
            1710425280000,
            675.05,
            675.4,
            675,
            675
        ],
        [
            1710425340000,
            675,
            675.9,
            675,
            675.9
        ],
        [
            1710425400000,
            675.45,
            675.8,
            675,
            675.65
        ],
        [
            1710425460000,
            675.35,
            675.9,
            675.35,
            675.85
        ],
        [
            1710425520000,
            675.95,
            675.95,
            675.25,
            675.25
        ],
        [
            1710425580000,
            675.25,
            675.85,
            675.15,
            675.75
        ],
        [
            1710425640000,
            675.35,
            676,
            675.35,
            676
        ],
        [
            1710425700000,
            675.55,
            675.95,
            675.4,
            675.5
        ],
        [
            1710425760000,
            675.5,
            675.9,
            675.45,
            675.85
        ],
        [
            1710425820000,
            675.45,
            675.8,
            675.35,
            675.75
        ],
        [
            1710425880000,
            675.85,
            675.85,
            675.45,
            675.85
        ],
        [
            1710425940000,
            675.5,
            675.85,
            675.25,
            675.85
        ],
        [
            1710426000000,
            675.7,
            675.8,
            675.35,
            675.8
        ],
        [
            1710426060000,
            675.4,
            675.9,
            675.25,
            675.9
        ],
        [
            1710426120000,
            675.65,
            676,
            675.65,
            675.95
        ],
        [
            1710426180000,
            675.95,
            675.95,
            675.6,
            675.95
        ],
        [
            1710426240000,
            675.6,
            676.35,
            675.6,
            676
        ],
        [
            1710426300000,
            675.9,
            676.15,
            675.8,
            676
        ],
        [
            1710426360000,
            676,
            676,
            675.6,
            675.6
        ],
        [
            1710426420000,
            675.6,
            676,
            675.6,
            676
        ],
        [
            1710426480000,
            676,
            676,
            675.7,
            675.95
        ],
        [
            1710426540000,
            675.65,
            676,
            675.6,
            675.75
        ],
        [
            1710426600000,
            676,
            676,
            675.6,
            676
        ],
        [
            1710426660000,
            675.8,
            676,
            675.6,
            675.75
        ],
        [
            1710426720000,
            676,
            676.1,
            675.7,
            676.1
        ],
        [
            1710426780000,
            675.7,
            676,
            675.7,
            676
        ],
        [
            1710426840000,
            676.4,
            676.4,
            675.75,
            675.75
        ],
        [
            1710426900000,
            676.05,
            676.05,
            675.7,
            675.7
        ],
        [
            1710426960000,
            675.7,
            676,
            675.7,
            676
        ],
        [
            1710427020000,
            675.7,
            676.05,
            675.7,
            675.95
        ],
        [
            1710427080000,
            676,
            676,
            675.4,
            675.4
        ],
        [
            1710427140000,
            675.65,
            676,
            675.4,
            675.9
        ],
        [
            1710427200000,
            675.9,
            676.25,
            675.55,
            675.95
        ],
        [
            1710427260000,
            675.55,
            676.05,
            675.4,
            676
        ],
        [
            1710427320000,
            676,
            676.05,
            675.6,
            676
        ],
        [
            1710427380000,
            675.55,
            676.05,
            675.3,
            676
        ],
        [
            1710427440000,
            675.95,
            676.05,
            675.55,
            675.95
        ],
        [
            1710427500000,
            676,
            676,
            675.55,
            676
        ],
        [
            1710427560000,
            675.65,
            676,
            675.6,
            676
        ],
        [
            1710427620000,
            675.7,
            676,
            675.6,
            675.75
        ],
        [
            1710427680000,
            675.75,
            676.15,
            675.6,
            676
        ],
        [
            1710427740000,
            675.8,
            676,
            675.8,
            676
        ],
        [
            1710427800000,
            676,
            676.3,
            675.5,
            676
        ],
        [
            1710427860000,
            676,
            676,
            675.5,
            676
        ],
        [
            1710427920000,
            675.85,
            676.1,
            675.7,
            676
        ],
        [
            1710427980000,
            675.7,
            676,
            675.65,
            676
        ],
        [
            1710428040000,
            676,
            676.2,
            675.5,
            676
        ],
        [
            1710428100000,
            675.75,
            676.4,
            675.5,
            676
        ],
        [
            1710428160000,
            675.7,
            676.05,
            675.65,
            676.05
        ],
        [
            1710428220000,
            675.95,
            676,
            675.6,
            675.7
        ],
        [
            1710428280000,
            675.55,
            676,
            675.55,
            676
        ],
        [
            1710428340000,
            676,
            676.25,
            675.65,
            675.8
        ],
        [
            1710428400000,
            676,
            676.25,
            675.95,
            676
        ],
        [
            1710428460000,
            676.1,
            676.35,
            675.65,
            676
        ],
        [
            1710428520000,
            676,
            676.3,
            675.9,
            676.2
        ],
        [
            1710428580000,
            675.9,
            676.2,
            675.9,
            676
        ],
        [
            1710428640000,
            676,
            676,
            675.85,
            676
        ],
        [
            1710428700000,
            675.9,
            676,
            675.9,
            675.95
        ],
        [
            1710428760000,
            675.9,
            676.3,
            675.85,
            676.3
        ],
        [
            1710428820000,
            675.95,
            676,
            675.25,
            675.3
        ],
        [
            1710428880000,
            675.6,
            675.8,
            675.35,
            675.45
        ],
        [
            1710428940000,
            675.9,
            675.9,
            675.5,
            675.7
        ],
        [
            1710429000000,
            675.95,
            676,
            675.7,
            675.95
        ],
        [
            1710429060000,
            675.95,
            675.95,
            675.8,
            675.9
        ],
        [
            1710429120000,
            676,
            676,
            675.95,
            676
        ],
        [
            1710429180000,
            675.95,
            676.3,
            675.95,
            675.95
        ],
        [
            1710429240000,
            676,
            678,
            675.95,
            678
        ],
        [
            1710429300000,
            678.2,
            679,
            678,
            679
        ],
        [
            1710429360000,
            678.75,
            679,
            678.65,
            679
        ],
        [
            1710429420000,
            678.75,
            678.95,
            678,
            678.95
        ],
        [
            1710429480000,
            678.7,
            679.2,
            678.6,
            679
        ],
        [
            1710429540000,
            678.75,
            679,
            678.25,
            678.25
        ],
        [
            1710429600000,
            678.45,
            678.45,
            677.4,
            677.45
        ],
        [
            1710429660000,
            677.7,
            677.7,
            677.4,
            677.4
        ],
        [
            1710429720000,
            677.4,
            677.5,
            677.4,
            677.45
        ],
        [
            1710429780000,
            677.45,
            677.45,
            677.4,
            677.45
        ],
        [
            1710429840000,
            677.4,
            677.45,
            677.4,
            677.45
        ],
        [
            1710429900000,
            677.4,
            677.45,
            677.3,
            677.45
        ],
        [
            1710429960000,
            677.45,
            677.45,
            677.3,
            677.3
        ],
        [
            1710430020000,
            677.35,
            677.4,
            677.05,
            677.25
        ],
        [
            1710430080000,
            677,
            677,
            676.2,
            676.2
        ],
        [
            1710430140000,
            675.8,
            677.1,
            675.2,
            676.5
        ],
        [
            1710430200000,
            675.25,
            675.25,
            675.25,
            675.25
        ],
        [
            1710494100000,
            675.8,
            677.7,
            673.45,
            676.45
        ],
        [
            1710494160000,
            676.4,
            678.45,
            675.95,
            678.45
        ],
        [
            1710494220000,
            679.15,
            680.65,
            678.5,
            680.65
        ],
        [
            1710494280000,
            681,
            683.1,
            681,
            682.95
        ],
        [
            1710494340000,
            683.85,
            687.85,
            683.35,
            686.7
        ],
        [
            1710494400000,
            686.75,
            687.75,
            686.05,
            687.2
        ],
        [
            1710494460000,
            687.25,
            687.9,
            686.25,
            687.6
        ],
        [
            1710494520000,
            686.2,
            686.2,
            681.95,
            682
        ],
        [
            1710494580000,
            682.05,
            683.9,
            681.9,
            683.9
        ],
        [
            1710494640000,
            683.95,
            685.25,
            683.6,
            683.6
        ],
        [
            1710494700000,
            683.4,
            686.5,
            683.4,
            686.5
        ],
        [
            1710494760000,
            686.35,
            686.85,
            685.4,
            685.4
        ],
        [
            1710494820000,
            685.7,
            685.85,
            684,
            684
        ],
        [
            1710494880000,
            684.3,
            684.95,
            683.05,
            684.35
        ],
        [
            1710494940000,
            684.4,
            684.7,
            683,
            683.5
        ],
        [
            1710495000000,
            683.45,
            683.45,
            682.4,
            682.8
        ],
        [
            1710495060000,
            682.85,
            683.65,
            682.45,
            683
        ],
        [
            1710495120000,
            683,
            684.4,
            683,
            684.4
        ],
        [
            1710495180000,
            684.9,
            684.95,
            683.7,
            684
        ],
        [
            1710495240000,
            684.2,
            684.9,
            684.2,
            684.4
        ],
        [
            1710495300000,
            683.55,
            685.1,
            683.35,
            685.1
        ],
        [
            1710495360000,
            684.5,
            684.5,
            682.4,
            682.5
        ],
        [
            1710495420000,
            682.7,
            682.7,
            681.05,
            681.05
        ],
        [
            1710495480000,
            681.45,
            683.2,
            680.65,
            683.2
        ],
        [
            1710495540000,
            683.2,
            684.6,
            682.8,
            683.65
        ],
        [
            1710495600000,
            683.65,
            683.7,
            682.5,
            683.7
        ],
        [
            1710495660000,
            683.2,
            686.6,
            682.3,
            685.2
        ],
        [
            1710495720000,
            685.1,
            685.15,
            683.35,
            684.45
        ],
        [
            1710495780000,
            684.5,
            684.5,
            682.9,
            683.3
        ],
        [
            1710495840000,
            683.3,
            684.65,
            683.3,
            684
        ],
        [
            1710495900000,
            684.15,
            685.55,
            684,
            685.1
        ],
        [
            1710495960000,
            685.45,
            685.45,
            685,
            685.4
        ],
        [
            1710496020000,
            685.5,
            686.05,
            685.5,
            685.9
        ],
        [
            1710496080000,
            685.95,
            686,
            685.5,
            685.9
        ],
        [
            1710496140000,
            685.8,
            685.8,
            684.55,
            684.55
        ],
        [
            1710496200000,
            684.4,
            684.7,
            683.85,
            684.5
        ],
        [
            1710496260000,
            684.5,
            684.5,
            684.3,
            684.35
        ],
        [
            1710496320000,
            684.3,
            685.2,
            684.25,
            685.2
        ],
        [
            1710496380000,
            685.2,
            685.2,
            684.85,
            684.85
        ],
        [
            1710496440000,
            684.55,
            684.55,
            683,
            683
        ],
        [
            1710496500000,
            683,
            684.9,
            683,
            683.8
        ],
        [
            1710496560000,
            683.85,
            684,
            683.05,
            683.4
        ],
        [
            1710496620000,
            683.85,
            684.25,
            683.65,
            683.75
        ],
        [
            1710496680000,
            683.55,
            684,
            683.55,
            683.75
        ],
        [
            1710496740000,
            683.75,
            683.75,
            683,
            683.6
        ],
        [
            1710496800000,
            683.6,
            684.85,
            683.4,
            684.35
        ],
        [
            1710496860000,
            684.3,
            685,
            684,
            684.75
        ],
        [
            1710496920000,
            684.6,
            684.95,
            684.6,
            684.7
        ],
        [
            1710496980000,
            684.7,
            684.7,
            684.5,
            684.65
        ],
        [
            1710497040000,
            684.75,
            686,
            684.75,
            686
        ],
        [
            1710497100000,
            686,
            686,
            684.6,
            684.9
        ],
        [
            1710497160000,
            684.65,
            684.85,
            683.65,
            683.65
        ],
        [
            1710497220000,
            683.75,
            684.1,
            682.6,
            682.6
        ],
        [
            1710497280000,
            682.45,
            682.5,
            681.15,
            681.55
        ],
        [
            1710497340000,
            681.5,
            682.45,
            680.8,
            680.8
        ],
        [
            1710497400000,
            680.85,
            680.85,
            679.2,
            680.35
        ],
        [
            1710497460000,
            680.5,
            680.8,
            679.9,
            680.1
        ],
        [
            1710497520000,
            680.45,
            680.8,
            680.1,
            680.25
        ],
        [
            1710497580000,
            680.1,
            680.35,
            679.85,
            680.2
        ],
        [
            1710497640000,
            680.2,
            680.25,
            680.05,
            680.1
        ],
        [
            1710497700000,
            680.15,
            680.5,
            679.9,
            680.15
        ],
        [
            1710497760000,
            680.1,
            680.2,
            679.6,
            679.8
        ],
        [
            1710497820000,
            679.8,
            680,
            679.3,
            679.75
        ],
        [
            1710497880000,
            679.45,
            679.6,
            678.6,
            678.9
        ],
        [
            1710497940000,
            678.8,
            679,
            678.4,
            678.55
        ],
        [
            1710498000000,
            678.55,
            678.55,
            677.25,
            677.45
        ],
        [
            1710498060000,
            677.35,
            677.7,
            677.35,
            677.45
        ],
        [
            1710498120000,
            677.45,
            677.6,
            677.4,
            677.4
        ],
        [
            1710498180000,
            677.45,
            677.65,
            677.35,
            677.4
        ],
        [
            1710498240000,
            677.45,
            677.6,
            677.4,
            677.4
        ],
        [
            1710498300000,
            677.4,
            677.7,
            677.35,
            677.35
        ],
        [
            1710498360000,
            677.45,
            677.7,
            677.3,
            677.7
        ],
        [
            1710498420000,
            677.4,
            677.6,
            677,
            677.15
        ],
        [
            1710498480000,
            677.2,
            677.45,
            677.2,
            677.4
        ],
        [
            1710498540000,
            677.4,
            677.5,
            676.75,
            676.8
        ],
        [
            1710498600000,
            676.8,
            676.95,
            676.75,
            676.8
        ],
        [
            1710498660000,
            676.85,
            677,
            676.75,
            676.75
        ],
        [
            1710498720000,
            676.95,
            676.95,
            676.75,
            676.8
        ],
        [
            1710498780000,
            676.8,
            676.85,
            676.6,
            676.65
        ],
        [
            1710498840000,
            676.8,
            676.8,
            676.7,
            676.7
        ],
        [
            1710498900000,
            676.7,
            676.7,
            676.45,
            676.6
        ],
        [
            1710498960000,
            676.45,
            676.5,
            676.05,
            676.45
        ],
        [
            1710499020000,
            676.45,
            676.5,
            676.2,
            676.3
        ],
        [
            1710499080000,
            676.5,
            676.8,
            676.3,
            676.55
        ],
        [
            1710499140000,
            676.8,
            676.8,
            676.5,
            676.55
        ],
        [
            1710499200000,
            676.5,
            676.7,
            676.35,
            676.4
        ],
        [
            1710499260000,
            676.55,
            676.75,
            676.45,
            676.55
        ],
        [
            1710499320000,
            676.55,
            676.6,
            676.5,
            676.6
        ],
        [
            1710499380000,
            676.55,
            676.6,
            676.55,
            676.55
        ],
        [
            1710499440000,
            676.5,
            676.8,
            676.5,
            676.55
        ],
        [
            1710499500000,
            676.5,
            676.75,
            676.5,
            676.55
        ],
        [
            1710499560000,
            676.5,
            676.7,
            676.5,
            676.55
        ],
        [
            1710499620000,
            676.55,
            676.9,
            676.5,
            676.55
        ],
        [
            1710499680000,
            676.55,
            676.6,
            676.5,
            676.55
        ],
        [
            1710499740000,
            676.55,
            676.75,
            676.5,
            676.55
        ],
        [
            1710499800000,
            676.55,
            676.65,
            676.55,
            676.6
        ],
        [
            1710499860000,
            676.55,
            676.65,
            676.5,
            676.5
        ],
        [
            1710499920000,
            676.5,
            676.85,
            676.5,
            676.85
        ],
        [
            1710499980000,
            676.8,
            678.55,
            676.5,
            678.55
        ],
        [
            1710500040000,
            678.7,
            678.95,
            677.65,
            677.8
        ],
        [
            1710500100000,
            677.8,
            677.95,
            677.7,
            677.8
        ],
        [
            1710500160000,
            677.75,
            677.95,
            677.5,
            677.6
        ],
        [
            1710500220000,
            677.75,
            677.85,
            677.7,
            677.8
        ],
        [
            1710500280000,
            677.85,
            677.95,
            677.75,
            677.85
        ],
        [
            1710500340000,
            677.65,
            677.8,
            677.6,
            677.8
        ],
        [
            1710500400000,
            677.75,
            677.95,
            677.65,
            677.65
        ],
        [
            1710500460000,
            677.75,
            677.8,
            677.45,
            677.8
        ],
        [
            1710500520000,
            677.8,
            678.1,
            677.75,
            677.75
        ],
        [
            1710500580000,
            677.8,
            677.9,
            677.65,
            677.8
        ],
        [
            1710500640000,
            677.75,
            677.9,
            677,
            677
        ],
        [
            1710500700000,
            677.5,
            677.5,
            677.2,
            677.3
        ],
        [
            1710500760000,
            677.4,
            677.7,
            677.35,
            677.4
        ],
        [
            1710500820000,
            677.5,
            677.55,
            677.35,
            677.35
        ],
        [
            1710500880000,
            677.3,
            677.35,
            677,
            677
        ],
        [
            1710500940000,
            677.15,
            677.15,
            676.3,
            676.35
        ],
        [
            1710501000000,
            676.65,
            677,
            676.55,
            676.6
        ],
        [
            1710501060000,
            677.05,
            677.35,
            676.8,
            677.35
        ],
        [
            1710501120000,
            677.35,
            677.4,
            677.05,
            677.3
        ],
        [
            1710501180000,
            677.35,
            677.45,
            676.9,
            677.35
        ],
        [
            1710501240000,
            677.25,
            677.4,
            676.9,
            677.2
        ],
        [
            1710501300000,
            677.35,
            677.4,
            677.25,
            677.35
        ],
        [
            1710501360000,
            677.35,
            677.8,
            677.2,
            677.45
        ],
        [
            1710501420000,
            677.7,
            677.95,
            677.7,
            677.8
        ],
        [
            1710501480000,
            677.8,
            678.15,
            677.7,
            677.75
        ],
        [
            1710501540000,
            677.75,
            677.85,
            677.75,
            677.8
        ],
        [
            1710501600000,
            677.75,
            677.85,
            677.75,
            677.8
        ],
        [
            1710501660000,
            677.8,
            677.8,
            677.75,
            677.8
        ],
        [
            1710501720000,
            677.75,
            677.85,
            677.75,
            677.75
        ],
        [
            1710501780000,
            677.75,
            677.9,
            677.25,
            677.55
        ],
        [
            1710501840000,
            677.55,
            677.95,
            677.55,
            677.8
        ],
        [
            1710501900000,
            677.65,
            677.7,
            677.2,
            677.7
        ],
        [
            1710501960000,
            677.4,
            677.4,
            676.9,
            677.05
        ],
        [
            1710502020000,
            676.95,
            676.95,
            676.75,
            676.85
        ],
        [
            1710502080000,
            676.2,
            676.6,
            675.4,
            675.85
        ],
        [
            1710502140000,
            675.55,
            676.5,
            675.1,
            675.15
        ],
        [
            1710502200000,
            675.9,
            676.5,
            675.1,
            676.25
        ],
        [
            1710502260000,
            676.3,
            676.7,
            675.4,
            675.45
        ],
        [
            1710502320000,
            675.8,
            676.35,
            675.1,
            675.15
        ],
        [
            1710502380000,
            675.4,
            675.5,
            675.1,
            675.1
        ],
        [
            1710502440000,
            675.15,
            675.15,
            674.85,
            674.85
        ],
        [
            1710502500000,
            675,
            675,
            674.65,
            674.7
        ],
        [
            1710502560000,
            674.95,
            675,
            674.65,
            674.9
        ],
        [
            1710502620000,
            675,
            675,
            674.9,
            675
        ],
        [
            1710502680000,
            674.95,
            675.25,
            674.95,
            675.15
        ],
        [
            1710502740000,
            675.15,
            675.3,
            675.1,
            675.1
        ],
        [
            1710502800000,
            675.15,
            675.2,
            675.1,
            675.15
        ],
        [
            1710502860000,
            675.15,
            675.15,
            673.85,
            674.15
        ],
        [
            1710502920000,
            674.15,
            674.6,
            674,
            674.05
        ],
        [
            1710502980000,
            674.05,
            674.05,
            673.85,
            673.9
        ],
        [
            1710503040000,
            673.9,
            674.3,
            673.9,
            674
        ],
        [
            1710503100000,
            673.95,
            674.05,
            673.95,
            674
        ],
        [
            1710503160000,
            673.95,
            674.05,
            673.95,
            674
        ],
        [
            1710503220000,
            674,
            676.2,
            674,
            676.2
        ],
        [
            1710503280000,
            676.2,
            677.2,
            676,
            676.35
        ],
        [
            1710503340000,
            676.5,
            676.65,
            676.35,
            676.5
        ],
        [
            1710503400000,
            676.5,
            676.5,
            674.6,
            675
        ],
        [
            1710503460000,
            675,
            675.35,
            674.6,
            675
        ],
        [
            1710503520000,
            675.35,
            675.4,
            675,
            675.4
        ],
        [
            1710503580000,
            675.3,
            675.65,
            675.05,
            675.3
        ],
        [
            1710503640000,
            675.2,
            675.4,
            675.05,
            675.15
        ],
        [
            1710503700000,
            675.25,
            675.25,
            675.15,
            675.2
        ],
        [
            1710503760000,
            675.15,
            675.5,
            674.9,
            675.3
        ],
        [
            1710503820000,
            675.3,
            675.45,
            674.1,
            674.6
        ],
        [
            1710503880000,
            674.6,
            675.1,
            674.55,
            675.1
        ],
        [
            1710503940000,
            675.1,
            675.5,
            674.85,
            675.5
        ],
        [
            1710504000000,
            675.45,
            675.7,
            674.7,
            675
        ],
        [
            1710504060000,
            675.05,
            676,
            675.05,
            675.7
        ],
        [
            1710504120000,
            676.05,
            676.6,
            675.7,
            676.4
        ],
        [
            1710504180000,
            676.4,
            676.4,
            675.7,
            675.9
        ],
        [
            1710504240000,
            676,
            676,
            675.75,
            676
        ],
        [
            1710504300000,
            675.85,
            676.5,
            675.85,
            676.5
        ],
        [
            1710504360000,
            676.5,
            676.55,
            676.4,
            676.45
        ],
        [
            1710504420000,
            676.45,
            676.6,
            676.1,
            676.5
        ],
        [
            1710504480000,
            676.5,
            676.5,
            676.4,
            676.45
        ],
        [
            1710504540000,
            676.5,
            676.5,
            676.3,
            676.45
        ],
        [
            1710504600000,
            676.5,
            676.6,
            675.85,
            676.5
        ],
        [
            1710504660000,
            676.25,
            676.75,
            676.25,
            676.5
        ],
        [
            1710504720000,
            676.5,
            676.8,
            675.9,
            676.35
        ],
        [
            1710504780000,
            676.5,
            676.5,
            676.05,
            676.35
        ],
        [
            1710504840000,
            676.35,
            676.7,
            676.1,
            676.5
        ],
        [
            1710504900000,
            676.5,
            676.85,
            676.45,
            676.5
        ],
        [
            1710504960000,
            676.45,
            676.7,
            676.45,
            676.45
        ],
        [
            1710505020000,
            676.5,
            676.5,
            676.45,
            676.45
        ],
        [
            1710505080000,
            676.45,
            676.5,
            676.2,
            676.2
        ],
        [
            1710505140000,
            676.2,
            676.5,
            676.2,
            676.3
        ],
        [
            1710505200000,
            675.4,
            675.4,
            674.8,
            675
        ],
        [
            1710505260000,
            675.1,
            675.15,
            674.6,
            674.7
        ],
        [
            1710505320000,
            675.15,
            675.15,
            674.7,
            675
        ],
        [
            1710505380000,
            675,
            675.2,
            674.8,
            675.2
        ],
        [
            1710505440000,
            675.15,
            675.15,
            674.4,
            674.75
        ],
        [
            1710505500000,
            675,
            675.05,
            674.8,
            674.95
        ],
        [
            1710505560000,
            675,
            675,
            673.3,
            674.2
        ],
        [
            1710505620000,
            674.4,
            674.7,
            673.6,
            673.65
        ],
        [
            1710505680000,
            673.65,
            674.05,
            673.6,
            673.65
        ],
        [
            1710505740000,
            673.6,
            673.65,
            673.6,
            673.65
        ],
        [
            1710505800000,
            673.6,
            673.75,
            673.05,
            673.2
        ],
        [
            1710505860000,
            673.2,
            673.9,
            673.2,
            673.6
        ],
        [
            1710505920000,
            673.6,
            673.6,
            673.3,
            673.5
        ],
        [
            1710505980000,
            673.3,
            673.45,
            673.1,
            673.2
        ],
        [
            1710506040000,
            673.2,
            673.55,
            673,
            673.05
        ],
        [
            1710506100000,
            673.05,
            673.25,
            673.05,
            673.15
        ],
        [
            1710506160000,
            673.15,
            673.2,
            673.15,
            673.15
        ],
        [
            1710506220000,
            673.15,
            673.2,
            673,
            673.15
        ],
        [
            1710506280000,
            673.15,
            673.3,
            673.05,
            673.15
        ],
        [
            1710506340000,
            673.15,
            673.2,
            673.1,
            673.15
        ],
        [
            1710506400000,
            673.15,
            673.2,
            673.15,
            673.2
        ],
        [
            1710506460000,
            673.15,
            673.2,
            673.15,
            673.15
        ],
        [
            1710506520000,
            673.2,
            673.2,
            673.15,
            673.15
        ],
        [
            1710506580000,
            673.15,
            673.15,
            672.8,
            673
        ],
        [
            1710506640000,
            673,
            673,
            672.05,
            672.65
        ],
        [
            1710506700000,
            672.55,
            673.1,
            672,
            672.75
        ],
        [
            1710506760000,
            672.75,
            672.75,
            671.9,
            672.45
        ],
        [
            1710506820000,
            672.35,
            673,
            672.35,
            672.7
        ],
        [
            1710506880000,
            672.7,
            673.2,
            672.4,
            672.65
        ],
        [
            1710506940000,
            673.1,
            673.1,
            672.7,
            673
        ],
        [
            1710507000000,
            673.1,
            673.1,
            672.7,
            672.75
        ],
        [
            1710507060000,
            672.5,
            673.1,
            672.5,
            672.75
        ],
        [
            1710507120000,
            672.75,
            673.1,
            672.75,
            673
        ],
        [
            1710507180000,
            672.95,
            673.25,
            672.95,
            672.95
        ],
        [
            1710507240000,
            672.6,
            673.45,
            672.6,
            673.15
        ],
        [
            1710507300000,
            673.2,
            673.4,
            673.15,
            673.15
        ],
        [
            1710507360000,
            673.15,
            673.2,
            673.15,
            673.2
        ],
        [
            1710507420000,
            673.15,
            673.25,
            673.15,
            673.15
        ],
        [
            1710507480000,
            673.15,
            673.5,
            673.15,
            673.15
        ],
        [
            1710507540000,
            673.15,
            673.4,
            673.15,
            673.2
        ],
        [
            1710507600000,
            673.2,
            675.6,
            673.15,
            675.6
        ],
        [
            1710507660000,
            675.65,
            676.9,
            675.6,
            675.85
        ],
        [
            1710507720000,
            675.85,
            676.2,
            675,
            675.5
        ],
        [
            1710507780000,
            675.6,
            675.95,
            675,
            675.15
        ],
        [
            1710507840000,
            675.2,
            675.5,
            674.9,
            675
        ],
        [
            1710507900000,
            675.35,
            675.5,
            674.9,
            675.2
        ],
        [
            1710507960000,
            675.45,
            675.45,
            674.3,
            674.8
        ],
        [
            1710508020000,
            674.9,
            675.7,
            674.9,
            675.45
        ],
        [
            1710508080000,
            675.45,
            675.5,
            674.7,
            675
        ],
        [
            1710508140000,
            674.7,
            675,
            674.7,
            674.95
        ],
        [
            1710508200000,
            674.95,
            676,
            674.95,
            675.5
        ],
        [
            1710508260000,
            675.55,
            676.05,
            675.3,
            675.4
        ],
        [
            1710508320000,
            675.4,
            675.5,
            675.4,
            675.45
        ],
        [
            1710508380000,
            675.45,
            675.5,
            675.45,
            675.45
        ],
        [
            1710508440000,
            675.45,
            675.55,
            675.4,
            675.5
        ],
        [
            1710508500000,
            675.45,
            675.5,
            675.4,
            675.45
        ],
        [
            1710508560000,
            675.45,
            675.55,
            675.45,
            675.45
        ],
        [
            1710508620000,
            675.5,
            675.6,
            674.7,
            674.7
        ],
        [
            1710508680000,
            674.7,
            675.2,
            674.7,
            675.15
        ],
        [
            1710508740000,
            674.8,
            675.2,
            674.8,
            674.85
        ],
        [
            1710508800000,
            674.9,
            675.3,
            674.9,
            675.05
        ],
        [
            1710508860000,
            675.3,
            675.45,
            675.1,
            675.35
        ],
        [
            1710508920000,
            675.35,
            675.85,
            675.25,
            675.5
        ],
        [
            1710508980000,
            675.45,
            675.55,
            675.45,
            675.45
        ],
        [
            1710509040000,
            675.45,
            675.55,
            675.05,
            675.25
        ],
        [
            1710509100000,
            675.5,
            675.5,
            675.2,
            675.45
        ],
        [
            1710509160000,
            675.45,
            675.45,
            674.75,
            675.2
        ],
        [
            1710509220000,
            675.45,
            675.5,
            675.25,
            675.35
        ],
        [
            1710509280000,
            675.45,
            675.5,
            675.45,
            675.5
        ],
        [
            1710509340000,
            675.45,
            675.5,
            674.85,
            675.35
        ],
        [
            1710509400000,
            675.3,
            675.55,
            675.05,
            675.35
        ],
        [
            1710509460000,
            675.35,
            675.5,
            675.25,
            675.3
        ],
        [
            1710509520000,
            675.5,
            675.5,
            675.25,
            675.5
        ],
        [
            1710509580000,
            675.45,
            675.55,
            675.45,
            675.45
        ],
        [
            1710509640000,
            675.45,
            675.5,
            675.45,
            675.5
        ],
        [
            1710509700000,
            675.45,
            675.55,
            675.45,
            675.5
        ],
        [
            1710509760000,
            675.5,
            677.85,
            675.45,
            677.85
        ],
        [
            1710509820000,
            677.85,
            677.85,
            677.5,
            677.7
        ],
        [
            1710509880000,
            677.8,
            678.8,
            677.8,
            678.75
        ],
        [
            1710509940000,
            678.8,
            680.95,
            678.75,
            680.05
        ],
        [
            1710510000000,
            680.35,
            680.35,
            679.1,
            679.5
        ],
        [
            1710510060000,
            679.55,
            679.55,
            678.8,
            678.8
        ],
        [
            1710510120000,
            678.8,
            679.5,
            678.75,
            679.45
        ],
        [
            1710510180000,
            679.5,
            679.85,
            678.7,
            679
        ],
        [
            1710510240000,
            679,
            679.1,
            678.65,
            678.65
        ],
        [
            1710510300000,
            678.5,
            679,
            678.5,
            678.75
        ],
        [
            1710510360000,
            679,
            679.1,
            678.75,
            679
        ],
        [
            1710510420000,
            678.9,
            679,
            678.05,
            678.05
        ],
        [
            1710510480000,
            677.9,
            678.55,
            677.9,
            678.55
        ],
        [
            1710510540000,
            678.55,
            679,
            678.25,
            678.85
        ],
        [
            1710510600000,
            679,
            679,
            678.6,
            679
        ],
        [
            1710510660000,
            678.75,
            679.05,
            678.75,
            679
        ],
        [
            1710510720000,
            678.95,
            679,
            678.95,
            679
        ],
        [
            1710510780000,
            678.95,
            679,
            678.95,
            678.95
        ],
        [
            1710510840000,
            678.95,
            679,
            678.75,
            678.95
        ],
        [
            1710510900000,
            679,
            679,
            678.95,
            679
        ],
        [
            1710510960000,
            678.95,
            679,
            677.7,
            678.7
        ],
        [
            1710511020000,
            678.65,
            679.15,
            677.95,
            678.65
        ],
        [
            1710511080000,
            678.65,
            679,
            678.15,
            678.15
        ],
        [
            1710511140000,
            678.2,
            678.6,
            678.2,
            678.6
        ],
        [
            1710511200000,
            678.3,
            679,
            678.3,
            679
        ],
        [
            1710511260000,
            679,
            679,
            678.95,
            679
        ],
        [
            1710511320000,
            678.95,
            679,
            678.1,
            678.15
        ],
        [
            1710511380000,
            678.2,
            678.55,
            677.8,
            677.95
        ],
        [
            1710511440000,
            678,
            678.1,
            677.95,
            677.95
        ],
        [
            1710511500000,
            677.95,
            678,
            677.85,
            677.95
        ],
        [
            1710511560000,
            677.95,
            678,
            677.85,
            678
        ],
        [
            1710511620000,
            677.9,
            678.05,
            677.55,
            678
        ],
        [
            1710511680000,
            677.9,
            678.05,
            677.9,
            677.95
        ],
        [
            1710511740000,
            677.95,
            678,
            677.95,
            678
        ],
        [
            1710511800000,
            678,
            678,
            677.5,
            677.85
        ],
        [
            1710511860000,
            677.85,
            678,
            677.6,
            677.95
        ],
        [
            1710511920000,
            678,
            678,
            677.7,
            677.7
        ],
        [
            1710511980000,
            677.9,
            678,
            677.3,
            677.95
        ],
        [
            1710512040000,
            677.6,
            678,
            677.6,
            677.9
        ],
        [
            1710512100000,
            678,
            678,
            677.7,
            677.85
        ],
        [
            1710512160000,
            678,
            678,
            677.25,
            677.9
        ],
        [
            1710512220000,
            677.95,
            678.2,
            677.4,
            677.95
        ],
        [
            1710512280000,
            677.95,
            678,
            677.7,
            677.8
        ],
        [
            1710512340000,
            677.8,
            677.85,
            677.05,
            677.45
        ],
        [
            1710512400000,
            677.45,
            677.55,
            676.4,
            677.25
        ],
        [
            1710512460000,
            677.05,
            677.85,
            676.55,
            677.6
        ],
        [
            1710512520000,
            677.65,
            677.9,
            677.05,
            677.5
        ],
        [
            1710512580000,
            677.5,
            677.75,
            677.05,
            677.75
        ],
        [
            1710512640000,
            677.85,
            678,
            677.2,
            677.5
        ],
        [
            1710512700000,
            677.7,
            677.95,
            677.05,
            677.7
        ],
        [
            1710512760000,
            677.7,
            678,
            677.7,
            677.75
        ],
        [
            1710512820000,
            677.55,
            677.6,
            676.95,
            677.05
        ],
        [
            1710512880000,
            677.35,
            677.35,
            675.6,
            676
        ],
        [
            1710512940000,
            675.6,
            676,
            675.15,
            675.55
        ],
        [
            1710513000000,
            675.6,
            675.75,
            674.05,
            674.05
        ],
        [
            1710513060000,
            674.1,
            674.4,
            673.6,
            674.4
        ],
        [
            1710513120000,
            674.05,
            675.05,
            673.95,
            675.05
        ],
        [
            1710513180000,
            675.1,
            675.5,
            674.5,
            674.8
        ],
        [
            1710513240000,
            674.85,
            675.8,
            674.85,
            675.8
        ],
        [
            1710513300000,
            675.65,
            676.2,
            675.65,
            676.2
        ],
        [
            1710513360000,
            676.25,
            676.25,
            674.3,
            674.3
        ],
        [
            1710513420000,
            674.5,
            675,
            674.5,
            675
        ],
        [
            1710513480000,
            674.8,
            675,
            674.8,
            675
        ],
        [
            1710513540000,
            675,
            675.05,
            674.8,
            675
        ],
        [
            1710513600000,
            674.8,
            675,
            674.8,
            674.85
        ],
        [
            1710513660000,
            674.85,
            675,
            674.8,
            674.8
        ],
        [
            1710513720000,
            674.85,
            675,
            674.8,
            674.8
        ],
        [
            1710513780000,
            675.05,
            675.05,
            674.6,
            674.6
        ],
        [
            1710513840000,
            674.95,
            675,
            674.85,
            675
        ],
        [
            1710513900000,
            674.95,
            675,
            674.95,
            675
        ],
        [
            1710513960000,
            674.95,
            675,
            674.8,
            674.85
        ],
        [
            1710514020000,
            675,
            675,
            674.8,
            674.8
        ],
        [
            1710514080000,
            674.8,
            675,
            674.8,
            675
        ],
        [
            1710514140000,
            674.95,
            675,
            674.95,
            675
        ],
        [
            1710514200000,
            674.95,
            675,
            674.15,
            674.9
        ],
        [
            1710514260000,
            674.95,
            674.95,
            674.6,
            674.95
        ],
        [
            1710514320000,
            674.8,
            675,
            674.5,
            674.5
        ],
        [
            1710514380000,
            674.45,
            674.95,
            674.45,
            674.85
        ],
        [
            1710514440000,
            674.9,
            675,
            674.7,
            674.7
        ],
        [
            1710514500000,
            674.3,
            674.6,
            674.05,
            674.05
        ],
        [
            1710514560000,
            674.1,
            674.5,
            674.05,
            674.5
        ],
        [
            1710514620000,
            674.05,
            674.05,
            673.95,
            673.95
        ],
        [
            1710514680000,
            674,
            674.05,
            673.55,
            673.95
        ],
        [
            1710514740000,
            674.05,
            674.05,
            673.8,
            674
        ],
        [
            1710514800000,
            674,
            674.55,
            673.5,
            673.75
        ],
        [
            1710514860000,
            673.7,
            674,
            673.55,
            674
        ],
        [
            1710514920000,
            674,
            674,
            673.85,
            673.85
        ],
        [
            1710514980000,
            673.85,
            673.95,
            673.6,
            673.8
        ],
        [
            1710515040000,
            673.95,
            674,
            673.5,
            674
        ],
        [
            1710515100000,
            673.85,
            674.05,
            673.8,
            673.95
        ],
        [
            1710515160000,
            674,
            674,
            673.95,
            674
        ],
        [
            1710515220000,
            673.65,
            673.95,
            673.65,
            673.9
        ],
        [
            1710515280000,
            673.7,
            673.7,
            673.25,
            673.3
        ],
        [
            1710515340000,
            673.5,
            674,
            673.5,
            674
        ],
        [
            1710515400000,
            673.9,
            674,
            673.5,
            673.5
        ],
        [
            1710515460000,
            673.7,
            674,
            673.2,
            673.2
        ],
        [
            1710515520000,
            673.5,
            673.8,
            673.5,
            673.65
        ],
        [
            1710515580000,
            673.65,
            673.65,
            673.2,
            673.3
        ],
        [
            1710515640000,
            673.25,
            673.95,
            673.25,
            673.95
        ],
        [
            1710515700000,
            673.7,
            674,
            673.7,
            674
        ],
        [
            1710515760000,
            674,
            674,
            673.2,
            673.35
        ],
        [
            1710515820000,
            673.55,
            673.9,
            673.55,
            673.8
        ],
        [
            1710515880000,
            673.7,
            673.85,
            673.6,
            673.75
        ],
        [
            1710515940000,
            673.8,
            673.9,
            673.35,
            673.5
        ],
        [
            1710516000000,
            673.4,
            673.9,
            673.4,
            673.7
        ],
        [
            1710516060000,
            673.8,
            673.9,
            673.7,
            673.8
        ],
        [
            1710516120000,
            673.75,
            673.95,
            673.75,
            673.95
        ],
        [
            1710516180000,
            673.9,
            673.9,
            673.65,
            673.7
        ],
        [
            1710516240000,
            673.6,
            673.65,
            673.1,
            673.4
        ],
        [
            1710516300000,
            673.55,
            673.8,
            673.3,
            673.7
        ],
        [
            1710516360000,
            673.55,
            673.95,
            673.55,
            673.65
        ],
        [
            1710516420000,
            673.75,
            674.5,
            673.1,
            674.5
        ],
        [
            1710516480000,
            674.1,
            674.1,
            673.1,
            673.95
        ],
        [
            1710516540000,
            673.5,
            674,
            673.5,
            674
        ],
        [
            1710516600000,
            674,
            674,
            674,
            674
        ],
        [
            1710753300000,
            677.75,
            677.8,
            672,
            672.55
        ],
        [
            1710753360000,
            672.9,
            675.2,
            672.3,
            675.2
        ],
        [
            1710753420000,
            675.15,
            675.8,
            675.05,
            675.7
        ],
        [
            1710753480000,
            675.05,
            675.9,
            674.95,
            674.95
        ],
        [
            1710753540000,
            675.6,
            675.65,
            675.55,
            675.55
        ],
        [
            1710753600000,
            674.1,
            674.7,
            674.05,
            674.05
        ],
        [
            1710753660000,
            673.5,
            673.5,
            672.7,
            672.7
        ],
        [
            1710753720000,
            672,
            672.15,
            670.05,
            671.6
        ],
        [
            1710753780000,
            671.4,
            671.6,
            670.9,
            671.25
        ],
        [
            1710753840000,
            671.4,
            673,
            671.4,
            673
        ],
        [
            1710753900000,
            672.7,
            675,
            672.7,
            674.05
        ],
        [
            1710753960000,
            674.5,
            675.35,
            674.05,
            675.35
        ],
        [
            1710754020000,
            675.25,
            675.25,
            673.6,
            673.9
        ],
        [
            1710754080000,
            674,
            674.7,
            674,
            674.05
        ],
        [
            1710754140000,
            674.4,
            674.4,
            674.1,
            674.1
        ],
        [
            1710754200000,
            674.35,
            674.35,
            673.7,
            673.9
        ],
        [
            1710754260000,
            674,
            675.9,
            673.9,
            675.9
        ],
        [
            1710754320000,
            675.65,
            676,
            675.55,
            675.9
        ],
        [
            1710754380000,
            675.55,
            675.55,
            674,
            674
        ],
        [
            1710754440000,
            674.25,
            674.3,
            673.8,
            674.15
        ],
        [
            1710754500000,
            673.8,
            673.8,
            672.95,
            672.95
        ],
        [
            1710754560000,
            673.25,
            673.7,
            673.25,
            673.4
        ],
        [
            1710754620000,
            673.7,
            673.7,
            673.4,
            673.4
        ],
        [
            1710754680000,
            673.65,
            673.8,
            673.65,
            673.65
        ],
        [
            1710754740000,
            673.8,
            673.8,
            672.5,
            672.75
        ],
        [
            1710754800000,
            672.75,
            673,
            672.5,
            673
        ],
        [
            1710754860000,
            673,
            673.15,
            673,
            673.15
        ],
        [
            1710754920000,
            673.1,
            673.4,
            673.1,
            673.35
        ],
        [
            1710754980000,
            673.4,
            673.4,
            672.85,
            672.9
        ],
        [
            1710755040000,
            673.2,
            673.25,
            672.8,
            672.95
        ],
        [
            1710755100000,
            672.95,
            673.05,
            672.95,
            673.05
        ],
        [
            1710755160000,
            673.05,
            673.75,
            673,
            673.75
        ],
        [
            1710755220000,
            673.7,
            673.95,
            673.65,
            673.65
        ],
        [
            1710755280000,
            673.05,
            673.05,
            672.4,
            672.4
        ],
        [
            1710755340000,
            672.15,
            673,
            672.15,
            672.55
        ],
        [
            1710755400000,
            672.55,
            673.2,
            672.55,
            672.95
        ],
        [
            1710755460000,
            672.75,
            672.75,
            671.85,
            672.65
        ],
        [
            1710755520000,
            672.5,
            672.5,
            672.35,
            672.5
        ],
        [
            1710755580000,
            672.35,
            672.8,
            672.35,
            672.75
        ],
        [
            1710755640000,
            672.75,
            672.75,
            672.35,
            672.35
        ],
        [
            1710755700000,
            672.2,
            672.75,
            672.2,
            672.45
        ],
        [
            1710755760000,
            672.5,
            672.7,
            672.25,
            672.65
        ],
        [
            1710755820000,
            672.65,
            672.65,
            672.65,
            672.65
        ],
        [
            1710755880000,
            672.8,
            672.8,
            671.45,
            671.95
        ],
        [
            1710755940000,
            672.25,
            672.75,
            672.25,
            672.7
        ],
        [
            1710756000000,
            672.3,
            672.65,
            672.15,
            672.15
        ],
        [
            1710756060000,
            672,
            672,
            672,
            672
        ],
        [
            1710756120000,
            672.45,
            672.6,
            672,
            672
        ],
        [
            1710756180000,
            671.7,
            672,
            671.45,
            671.65
        ],
        [
            1710756240000,
            672,
            672.2,
            672,
            672.2
        ],
        [
            1710756300000,
            672,
            672,
            671.65,
            671.9
        ],
        [
            1710756360000,
            671.95,
            672.25,
            671.95,
            672.2
        ],
        [
            1710756420000,
            672.4,
            672.6,
            672.4,
            672.6
        ],
        [
            1710756480000,
            671.45,
            672.55,
            671.45,
            672.55
        ],
        [
            1710756540000,
            672.25,
            672.6,
            671.7,
            671.7
        ],
        [
            1710756600000,
            671.3,
            671.6,
            671.3,
            671.6
        ],
        [
            1710756660000,
            671.35,
            672.7,
            671.35,
            672.7
        ],
        [
            1710756720000,
            672.5,
            673,
            672.3,
            673
        ],
        [
            1710756780000,
            673.2,
            673.2,
            673.15,
            673.15
        ],
        [
            1710756840000,
            673.4,
            673.95,
            673.4,
            673.85
        ],
        [
            1710756900000,
            674,
            674.85,
            673.9,
            674.85
        ],
        [
            1710756960000,
            674.45,
            674.8,
            674.45,
            674.8
        ],
        [
            1710757020000,
            674.8,
            674.8,
            674.5,
            674.8
        ],
        [
            1710757080000,
            674.95,
            675.4,
            674.65,
            674.95
        ],
        [
            1710757140000,
            675.1,
            675.1,
            674.2,
            674.65
        ],
        [
            1710757200000,
            675.1,
            675.1,
            674.1,
            674.1
        ],
        [
            1710757260000,
            674,
            674.85,
            673.9,
            674.85
        ],
        [
            1710757320000,
            674.75,
            674.75,
            673.9,
            674.75
        ],
        [
            1710757380000,
            674.5,
            674.5,
            673.9,
            674.45
        ],
        [
            1710757440000,
            674.15,
            674.15,
            673.85,
            674.05
        ],
        [
            1710757560000,
            674.4,
            674.4,
            674.4,
            674.4
        ],
        [
            1710757680000,
            674.4,
            674.45,
            674.05,
            674.45
        ],
        [
            1710757740000,
            674.5,
            674.5,
            674.05,
            674.05
        ],
        [
            1710757800000,
            674.1,
            674.2,
            674.1,
            674.2
        ],
        [
            1710757860000,
            674.5,
            674.5,
            674.5,
            674.5
        ],
        [
            1710757920000,
            674.5,
            674.5,
            674.5,
            674.5
        ],
        [
            1710757980000,
            674.5,
            674.5,
            674.4,
            674.4
        ],
        [
            1710758040000,
            674.45,
            674.5,
            674.45,
            674.5
        ],
        [
            1710758100000,
            674.5,
            674.6,
            674.5,
            674.6
        ],
        [
            1710758160000,
            674.95,
            674.95,
            674.05,
            674.05
        ],
        [
            1710758220000,
            674.1,
            674.1,
            674.1,
            674.1
        ],
        [
            1710758280000,
            674.35,
            674.35,
            674.35,
            674.35
        ],
        [
            1710758340000,
            674.65,
            674.65,
            674.65,
            674.65
        ],
        [
            1710758400000,
            673.8,
            674.6,
            673.8,
            674.55
        ],
        [
            1710758460000,
            674.6,
            674.6,
            674.05,
            674.5
        ],
        [
            1710758520000,
            674.1,
            674.45,
            674.05,
            674.45
        ],
        [
            1710758640000,
            674.45,
            674.45,
            674.05,
            674.05
        ],
        [
            1710758700000,
            674.05,
            674.05,
            674.05,
            674.05
        ],
        [
            1710758760000,
            674,
            674.65,
            674,
            674.65
        ],
        [
            1710758820000,
            674,
            674.35,
            674,
            674.35
        ],
        [
            1710758880000,
            674,
            674,
            674,
            674
        ],
        [
            1710758940000,
            674.3,
            674.3,
            674.05,
            674.05
        ],
        [
            1710759000000,
            674.05,
            674.05,
            674.05,
            674.05
        ],
        [
            1710759060000,
            674.4,
            674.4,
            674.4,
            674.4
        ],
        [
            1710759120000,
            674.4,
            674.4,
            674.4,
            674.4
        ],
        [
            1710759180000,
            674.4,
            674.5,
            674.4,
            674.5
        ],
        [
            1710759240000,
            674.8,
            674.95,
            674.3,
            674.4
        ],
        [
            1710759300000,
            674.65,
            674.7,
            674,
            674.5
        ],
        [
            1710759360000,
            674.65,
            674.65,
            673.95,
            674.45
        ],
        [
            1710759420000,
            674.45,
            674.45,
            674.35,
            674.4
        ],
        [
            1710759480000,
            674.4,
            674.9,
            674.4,
            674.9
        ],
        [
            1710759540000,
            674.9,
            675,
            674,
            674
        ],
        [
            1710759600000,
            674,
            674.55,
            674,
            674.05
        ],
        [
            1710759660000,
            674.05,
            674.5,
            674.05,
            674.5
        ],
        [
            1710759720000,
            674.8,
            674.8,
            674.8,
            674.8
        ],
        [
            1710759780000,
            674.8,
            675.85,
            674.75,
            675.85
        ],
        [
            1710759840000,
            675.8,
            675.85,
            675,
            675.4
        ],
        [
            1710759900000,
            675.4,
            675.45,
            675.4,
            675.45
        ],
        [
            1710759960000,
            674.9,
            675.4,
            674.9,
            675.4
        ],
        [
            1710760020000,
            675.4,
            675.4,
            675.05,
            675.05
        ],
        [
            1710760080000,
            675,
            675.35,
            675,
            675
        ],
        [
            1710760140000,
            675,
            675.4,
            674.8,
            675
        ],
        [
            1710760200000,
            675,
            676.65,
            674.95,
            676.65
        ],
        [
            1710760260000,
            676.7,
            677.45,
            676.7,
            677.15
        ],
        [
            1710760320000,
            676.8,
            676.8,
            675.75,
            676.1
        ],
        [
            1710760380000,
            676.1,
            676.45,
            675.6,
            676.45
        ],
        [
            1710760440000,
            676.45,
            676.45,
            676.25,
            676.25
        ],
        [
            1710760500000,
            676,
            676.1,
            676,
            676.1
        ],
        [
            1710760560000,
            676.05,
            676.5,
            676.05,
            676.35
        ],
        [
            1710760620000,
            676.35,
            676.35,
            676.35,
            676.35
        ],
        [
            1710760680000,
            676.35,
            676.35,
            676.35,
            676.35
        ],
        [
            1710760740000,
            676.75,
            676.75,
            676.4,
            676.4
        ],
        [
            1710760800000,
            676.4,
            677.8,
            676.4,
            677.8
        ],
        [
            1710760860000,
            677.4,
            677.75,
            677.4,
            677.75
        ],
        [
            1710760920000,
            677.45,
            677.45,
            677.45,
            677.45
        ],
        [
            1710760980000,
            677.45,
            677.75,
            677.45,
            677.75
        ],
        [
            1710761040000,
            677.25,
            677.25,
            676.45,
            676.45
        ],
        [
            1710761100000,
            676.2,
            677,
            676.2,
            676.6
        ],
        [
            1710761160000,
            676.6,
            676.75,
            676.6,
            676.75
        ],
        [
            1710761220000,
            676.85,
            677,
            676.85,
            677
        ],
        [
            1710761280000,
            676.9,
            676.9,
            676,
            676.05
        ],
        [
            1710761340000,
            676.15,
            676.6,
            676.15,
            676.6
        ],
        [
            1710761400000,
            676.65,
            676.65,
            676.25,
            676.3
        ],
        [
            1710761460000,
            676.65,
            677.2,
            676.65,
            677.2
        ],
        [
            1710761520000,
            677.2,
            678.05,
            676.95,
            677.55
        ],
        [
            1710761580000,
            677.4,
            678.2,
            677.15,
            678.2
        ],
        [
            1710761640000,
            678.2,
            678.8,
            677.55,
            678.8
        ],
        [
            1710761700000,
            678.85,
            678.9,
            678.35,
            678.5
        ],
        [
            1710761760000,
            678.4,
            678.5,
            678.4,
            678.4
        ],
        [
            1710761820000,
            678.5,
            678.95,
            678.4,
            678.65
        ],
        [
            1710761880000,
            679,
            679.3,
            678.35,
            678.6
        ],
        [
            1710761940000,
            678.5,
            678.6,
            677.9,
            678.6
        ],
        [
            1710762000000,
            678.35,
            678.8,
            678.1,
            678.1
        ],
        [
            1710762060000,
            678.95,
            680.7,
            678.55,
            680.2
        ],
        [
            1710762120000,
            679.75,
            680.2,
            679.75,
            679.75
        ],
        [
            1710762180000,
            679.55,
            679.55,
            678.8,
            679.2
        ],
        [
            1710762240000,
            679.25,
            679.7,
            679.25,
            679.25
        ],
        [
            1710762300000,
            679.25,
            679.3,
            679.25,
            679.3
        ],
        [
            1710762360000,
            679.3,
            679.3,
            679.3,
            679.3
        ],
        [
            1710762420000,
            679.7,
            679.7,
            679.3,
            679.3
        ],
        [
            1710762480000,
            679.3,
            679.3,
            678.9,
            678.9
        ],
        [
            1710762540000,
            678.9,
            679,
            678.9,
            679
        ],
        [
            1710762600000,
            679,
            679.05,
            679,
            679.05
        ],
        [
            1710762660000,
            679.05,
            679.7,
            679.05,
            679.7
        ],
        [
            1710762720000,
            679.7,
            680,
            679.7,
            680
        ],
        [
            1710762780000,
            680.15,
            680.85,
            679.7,
            680.85
        ],
        [
            1710762840000,
            681,
            681.45,
            680.65,
            681.45
        ],
        [
            1710762900000,
            681.45,
            681.45,
            679.85,
            679.9
        ],
        [
            1710762960000,
            679.9,
            680.65,
            679.8,
            680.65
        ],
        [
            1710763020000,
            680.3,
            680.35,
            680,
            680
        ],
        [
            1710763080000,
            679.2,
            679.4,
            679.2,
            679.25
        ],
        [
            1710763140000,
            680,
            680,
            679.25,
            679.25
        ],
        [
            1710763200000,
            679.85,
            679.9,
            679.5,
            679.9
        ],
        [
            1710763260000,
            679.5,
            680.35,
            679.5,
            680.35
        ],
        [
            1710763320000,
            680.6,
            681,
            680.55,
            681
        ],
        [
            1710763380000,
            681,
            681.2,
            680.85,
            680.85
        ],
        [
            1710763440000,
            681,
            681,
            680.85,
            681
        ],
        [
            1710763500000,
            681,
            681,
            680.35,
            680.35
        ],
        [
            1710763560000,
            680.35,
            680.35,
            680.2,
            680.2
        ],
        [
            1710763620000,
            680.45,
            680.45,
            679.35,
            680
        ],
        [
            1710763680000,
            679.7,
            680.95,
            679.7,
            680.95
        ],
        [
            1710763740000,
            680.9,
            680.9,
            679.45,
            679.45
        ],
        [
            1710763800000,
            679.75,
            680.45,
            679.5,
            680.45
        ],
        [
            1710763860000,
            680.05,
            680.65,
            680,
            680.65
        ],
        [
            1710763920000,
            680.2,
            680.55,
            680,
            680.5
        ],
        [
            1710763980000,
            680.65,
            680.9,
            680.3,
            680.9
        ],
        [
            1710764040000,
            680.6,
            680.95,
            680.45,
            680.85
        ],
        [
            1710764100000,
            680.9,
            680.9,
            680.65,
            680.65
        ],
        [
            1710764160000,
            680.65,
            681,
            680.65,
            681
        ],
        [
            1710764220000,
            681,
            681.2,
            680.7,
            681.05
        ],
        [
            1710764280000,
            680.8,
            681.1,
            680.4,
            680.55
        ],
        [
            1710764340000,
            680.55,
            680.75,
            680.3,
            680.75
        ],
        [
            1710764400000,
            680.15,
            680.6,
            680.15,
            680.6
        ],
        [
            1710764460000,
            680.65,
            680.9,
            680.4,
            680.6
        ],
        [
            1710764520000,
            680.6,
            680.6,
            680.6,
            680.6
        ],
        [
            1710764580000,
            680.6,
            680.9,
            680.25,
            680.8
        ],
        [
            1710764640000,
            681.15,
            681.6,
            681.1,
            681.55
        ],
        [
            1710764700000,
            681.25,
            681.25,
            681,
            681.1
        ],
        [
            1710764760000,
            681.05,
            681.05,
            680.9,
            680.9
        ],
        [
            1710764820000,
            681.2,
            681.6,
            681.15,
            681.6
        ],
        [
            1710764880000,
            681.4,
            681.85,
            681.05,
            681.85
        ],
        [
            1710764940000,
            681.85,
            682.3,
            681.85,
            682.25
        ],
        [
            1710765000000,
            682.3,
            682.45,
            682,
            682.45
        ],
        [
            1710765060000,
            681.5,
            681.9,
            681.15,
            681.45
        ],
        [
            1710765120000,
            681.45,
            681.5,
            681.45,
            681.45
        ],
        [
            1710765180000,
            681.9,
            681.9,
            680.65,
            680.65
        ],
        [
            1710765240000,
            680.8,
            681.4,
            680.8,
            681.3
        ],
        [
            1710765300000,
            681,
            681.3,
            681,
            681.3
        ],
        [
            1710765360000,
            681,
            681.35,
            681,
            681
        ],
        [
            1710765420000,
            681.05,
            681.25,
            681,
            681
        ],
        [
            1710765480000,
            681,
            681,
            681,
            681
        ],
        [
            1710765540000,
            680.85,
            681.25,
            680.8,
            680.8
        ],
        [
            1710765600000,
            681.05,
            681.35,
            680.7,
            680.8
        ],
        [
            1710765660000,
            681,
            681.5,
            681,
            681.5
        ],
        [
            1710765720000,
            681.05,
            681.4,
            681.05,
            681.05
        ],
        [
            1710765780000,
            681,
            681.35,
            680.55,
            681.35
        ],
        [
            1710765840000,
            681.45,
            681.45,
            680.05,
            680.15
        ],
        [
            1710765900000,
            680.35,
            680.75,
            680.2,
            680.5
        ],
        [
            1710765960000,
            681.2,
            681.2,
            679.8,
            680.6
        ],
        [
            1710766020000,
            680.85,
            680.85,
            680.3,
            680.3
        ],
        [
            1710766080000,
            679.95,
            680.05,
            679.95,
            680
        ],
        [
            1710766140000,
            680,
            680.5,
            680,
            680.5
        ],
        [
            1710766200000,
            680.5,
            680.5,
            680.15,
            680.15
        ],
        [
            1710766260000,
            680.5,
            680.7,
            680.15,
            680.7
        ],
        [
            1710766320000,
            680.45,
            680.45,
            680.45,
            680.45
        ],
        [
            1710766380000,
            679.95,
            680.2,
            679.7,
            680.2
        ],
        [
            1710766440000,
            679.75,
            679.75,
            679.75,
            679.75
        ],
        [
            1710766500000,
            679.75,
            680.05,
            679.7,
            680.05
        ],
        [
            1710766560000,
            680.05,
            680.15,
            680.05,
            680.1
        ],
        [
            1710766620000,
            680.1,
            680.8,
            680.1,
            680.8
        ],
        [
            1710766680000,
            680.25,
            680.25,
            680.25,
            680.25
        ],
        [
            1710766740000,
            680.6,
            680.6,
            680,
            680
        ],
        [
            1710766800000,
            680.35,
            680.35,
            679.85,
            679.9
        ],
        [
            1710766860000,
            679.9,
            680.25,
            679.85,
            680.25
        ],
        [
            1710766920000,
            680.25,
            680.25,
            679.8,
            679.8
        ],
        [
            1710766980000,
            679.8,
            679.8,
            679.55,
            679.55
        ],
        [
            1710767040000,
            679.6,
            679.6,
            679.55,
            679.55
        ],
        [
            1710767100000,
            679.55,
            679.85,
            679.05,
            679.25
        ],
        [
            1710767160000,
            679.25,
            679.3,
            678.7,
            678.75
        ],
        [
            1710767220000,
            678.8,
            678.8,
            678.5,
            678.5
        ],
        [
            1710767280000,
            678.55,
            678.75,
            678.55,
            678.75
        ],
        [
            1710767340000,
            679.5,
            679.5,
            679.1,
            679.1
        ],
        [
            1710767400000,
            679.1,
            679.5,
            679.1,
            679.1
        ],
        [
            1710767460000,
            679.1,
            679.1,
            678.85,
            678.85
        ],
        [
            1710767520000,
            678.85,
            678.85,
            678.8,
            678.8
        ],
        [
            1710767580000,
            678.75,
            679.55,
            678.75,
            679.55
        ],
        [
            1710767640000,
            679.55,
            679.8,
            679.5,
            679.8
        ],
        [
            1710767700000,
            679.5,
            679.85,
            679.45,
            679.85
        ],
        [
            1710767760000,
            679.8,
            680.7,
            679.45,
            680.7
        ],
        [
            1710767820000,
            680.4,
            680.85,
            680.3,
            680.4
        ],
        [
            1710767880000,
            680.7,
            680.7,
            680.3,
            680.3
        ],
        [
            1710767940000,
            680.3,
            680.85,
            680.25,
            680.7
        ],
        [
            1710768000000,
            680.65,
            680.85,
            680.35,
            680.4
        ],
        [
            1710768060000,
            680.4,
            680.75,
            680.3,
            680.3
        ],
        [
            1710768120000,
            680.25,
            680.5,
            680,
            680
        ],
        [
            1710768180000,
            680,
            680.35,
            680,
            680.25
        ],
        [
            1710768240000,
            680,
            680.4,
            680,
            680.4
        ],
        [
            1710768300000,
            680.6,
            680.6,
            680.4,
            680.4
        ],
        [
            1710768360000,
            680.4,
            680.55,
            680.4,
            680.4
        ],
        [
            1710768420000,
            680.4,
            680.4,
            680.4,
            680.4
        ],
        [
            1710768480000,
            680.4,
            680.45,
            680.4,
            680.45
        ],
        [
            1710768540000,
            680.45,
            680.45,
            680.4,
            680.4
        ],
        [
            1710768600000,
            680.4,
            680.4,
            680.4,
            680.4
        ],
        [
            1710768660000,
            680.4,
            680.4,
            680.3,
            680.3
        ],
        [
            1710768720000,
            680.3,
            680.3,
            680.05,
            680.05
        ],
        [
            1710768780000,
            680.05,
            680.25,
            679.9,
            679.9
        ],
        [
            1710768840000,
            679.8,
            680,
            679.8,
            680
        ],
        [
            1710768900000,
            680,
            680.15,
            680,
            680
        ],
        [
            1710768960000,
            680,
            680.25,
            679.9,
            680
        ],
        [
            1710769020000,
            680,
            680.3,
            680,
            680
        ],
        [
            1710769080000,
            680,
            680.2,
            680,
            680.15
        ],
        [
            1710769140000,
            680,
            680.2,
            680,
            680.2
        ],
        [
            1710769200000,
            680.2,
            680.6,
            680,
            680.6
        ],
        [
            1710769260000,
            680.2,
            680.95,
            680.2,
            680.9
        ],
        [
            1710769320000,
            680.5,
            680.9,
            680.2,
            680.3
        ],
        [
            1710769380000,
            680.7,
            680.85,
            680.25,
            680.75
        ],
        [
            1710769440000,
            680.45,
            680.75,
            680.2,
            680.25
        ],
        [
            1710769500000,
            680.65,
            680.65,
            680.2,
            680.2
        ],
        [
            1710769560000,
            680.2,
            680.6,
            680.2,
            680.2
        ],
        [
            1710769620000,
            680.2,
            680.4,
            680.2,
            680.4
        ],
        [
            1710769680000,
            680.2,
            680.35,
            679.9,
            679.9
        ],
        [
            1710769740000,
            680,
            680.3,
            679.9,
            680.3
        ],
        [
            1710769800000,
            680,
            680.4,
            680,
            680.4
        ],
        [
            1710769860000,
            680,
            680.4,
            680,
            680
        ],
        [
            1710769920000,
            680,
            680.05,
            679.85,
            680
        ],
        [
            1710769980000,
            680,
            680.05,
            680,
            680.05
        ],
        [
            1710770040000,
            680,
            680.4,
            680,
            680.1
        ],
        [
            1710770100000,
            680.1,
            680.5,
            680.05,
            680.05
        ],
        [
            1710770160000,
            680.05,
            680.05,
            680,
            680
        ],
        [
            1710770220000,
            680,
            680.1,
            680,
            680.05
        ],
        [
            1710770280000,
            680,
            680.05,
            680,
            680
        ],
        [
            1710770340000,
            680,
            680.05,
            679.8,
            679.8
        ],
        [
            1710770400000,
            680,
            680.05,
            679.7,
            679.75
        ],
        [
            1710770460000,
            679.7,
            680,
            679.7,
            680
        ],
        [
            1710770520000,
            679.75,
            680.4,
            679.75,
            680
        ],
        [
            1710770580000,
            680,
            680.25,
            679.95,
            680
        ],
        [
            1710770640000,
            680,
            680.25,
            679.9,
            679.95
        ],
        [
            1710770700000,
            680,
            680.15,
            679.75,
            679.95
        ],
        [
            1710770760000,
            680,
            680.9,
            679.85,
            680.9
        ],
        [
            1710770820000,
            680.5,
            680.85,
            680.45,
            680.85
        ],
        [
            1710770880000,
            680.5,
            681.2,
            680.5,
            681.2
        ],
        [
            1710770940000,
            681.1,
            681.45,
            680.95,
            680.95
        ],
        [
            1710771000000,
            681,
            681.45,
            680.75,
            681.4
        ],
        [
            1710771060000,
            681,
            681.4,
            681,
            681.4
        ],
        [
            1710771120000,
            681.4,
            681.4,
            681,
            681
        ],
        [
            1710771180000,
            681,
            681.35,
            681,
            681
        ],
        [
            1710771240000,
            681,
            681.35,
            680.75,
            681.05
        ],
        [
            1710771300000,
            680.8,
            681,
            680.8,
            681
        ],
        [
            1710771360000,
            681,
            681.05,
            681,
            681
        ],
        [
            1710771420000,
            681,
            681,
            681,
            681
        ],
        [
            1710771480000,
            681,
            681,
            681,
            681
        ],
        [
            1710771540000,
            681,
            681.4,
            681,
            681
        ],
        [
            1710771600000,
            681,
            681.4,
            681,
            681.1
        ],
        [
            1710771660000,
            681.1,
            681.4,
            681,
            681.4
        ],
        [
            1710771720000,
            681,
            681.95,
            681,
            681.55
        ],
        [
            1710771780000,
            681.55,
            682,
            681.55,
            682
        ],
        [
            1710771840000,
            682.05,
            682.4,
            681.9,
            682
        ],
        [
            1710771900000,
            682.4,
            682.4,
            682,
            682.05
        ],
        [
            1710771960000,
            682.05,
            683.35,
            682.05,
            682.85
        ],
        [
            1710772020000,
            682.85,
            683.45,
            681.85,
            682.05
        ],
        [
            1710772080000,
            682.05,
            682.5,
            682,
            682.5
        ],
        [
            1710772140000,
            682,
            684.35,
            682,
            684.35
        ],
        [
            1710772200000,
            684.35,
            684.5,
            683.05,
            683.45
        ],
        [
            1710772260000,
            683.75,
            684,
            682.65,
            682.65
        ],
        [
            1710772320000,
            682.55,
            682.8,
            682.55,
            682.75
        ],
        [
            1710772380000,
            682.75,
            683.4,
            682.7,
            682.75
        ],
        [
            1710772440000,
            682.7,
            684.1,
            682.7,
            683.3
        ],
        [
            1710772500000,
            683.3,
            683.55,
            682.7,
            682.75
        ],
        [
            1710772560000,
            682.75,
            683.2,
            682.1,
            682.55
        ],
        [
            1710772620000,
            682.5,
            683.1,
            682.5,
            682.75
        ],
        [
            1710772680000,
            682.75,
            682.95,
            682.75,
            682.75
        ],
        [
            1710772740000,
            682.75,
            682.75,
            682.75,
            682.75
        ],
        [
            1710772800000,
            682.75,
            682.8,
            682.45,
            682.45
        ],
        [
            1710772860000,
            682.55,
            683.55,
            682.05,
            683.3
        ],
        [
            1710772920000,
            683.15,
            683.8,
            682.5,
            683.75
        ],
        [
            1710772980000,
            683.75,
            683.75,
            683.05,
            683.05
        ],
        [
            1710773040000,
            683.05,
            683.05,
            683,
            683.05
        ],
        [
            1710773100000,
            683.05,
            683.1,
            683,
            683
        ],
        [
            1710773160000,
            683,
            683,
            682.15,
            682.15
        ],
        [
            1710773220000,
            682.15,
            682.25,
            682,
            682
        ],
        [
            1710773280000,
            682,
            682.2,
            682,
            682
        ],
        [
            1710773340000,
            682,
            682.5,
            682,
            682.5
        ],
        [
            1710773400000,
            682.85,
            682.85,
            682.3,
            682.35
        ],
        [
            1710773460000,
            682.35,
            682.5,
            682.3,
            682.35
        ],
        [
            1710773520000,
            682.35,
            682.4,
            682.35,
            682.4
        ],
        [
            1710773580000,
            682.5,
            682.5,
            682.4,
            682.5
        ],
        [
            1710773640000,
            682.5,
            682.5,
            682.4,
            682.4
        ],
        [
            1710773700000,
            682.4,
            682.4,
            682,
            682
        ],
        [
            1710773760000,
            682,
            682,
            682,
            682
        ],
        [
            1710773820000,
            682,
            682,
            682,
            682
        ],
        [
            1710773880000,
            682,
            682,
            682,
            682
        ],
        [
            1710773940000,
            682,
            682.15,
            682,
            682.05
        ],
        [
            1710774000000,
            682.05,
            682.05,
            682,
            682
        ],
        [
            1710774060000,
            682,
            682.3,
            682,
            682.3
        ],
        [
            1710774120000,
            682.3,
            682.3,
            682,
            682.15
        ],
        [
            1710774180000,
            682,
            682.3,
            682,
            682.3
        ],
        [
            1710774240000,
            682.05,
            682.1,
            682,
            682
        ],
        [
            1710774300000,
            682,
            682,
            681.75,
            682
        ],
        [
            1710774360000,
            682,
            682.45,
            681.6,
            682.4
        ],
        [
            1710774420000,
            682,
            682.4,
            681.95,
            682
        ],
        [
            1710774480000,
            682,
            682.45,
            682,
            682
        ],
        [
            1710774540000,
            682,
            682.25,
            682,
            682.25
        ],
        [
            1710774600000,
            682,
            682.35,
            682,
            682
        ],
        [
            1710774660000,
            682,
            682.2,
            682,
            682
        ],
        [
            1710774720000,
            682.2,
            682.2,
            682,
            682
        ],
        [
            1710774780000,
            682.1,
            682.45,
            682,
            682.45
        ],
        [
            1710774840000,
            682.05,
            682.55,
            682.05,
            682.1
        ],
        [
            1710774900000,
            681.95,
            682.2,
            681.95,
            682
        ],
        [
            1710774960000,
            682.4,
            682.4,
            682,
            682.4
        ],
        [
            1710775020000,
            682.05,
            682.4,
            682,
            682.3
        ],
        [
            1710775080000,
            682.25,
            682.45,
            681.9,
            682.1
        ],
        [
            1710775140000,
            682.05,
            682.7,
            682.05,
            682.5
        ],
        [
            1710775200000,
            682.15,
            682.35,
            682,
            682.3
        ],
        [
            1710775260000,
            682,
            682.45,
            682,
            682.15
        ],
        [
            1710775320000,
            682,
            682.2,
            681.75,
            682
        ],
        [
            1710775380000,
            682,
            682.35,
            682,
            682
        ],
        [
            1710775440000,
            682.15,
            682.2,
            682,
            682
        ],
        [
            1710775500000,
            682.05,
            682.2,
            682,
            682
        ],
        [
            1710775560000,
            682.15,
            682.75,
            682.15,
            682.65
        ],
        [
            1710775620000,
            682.1,
            682.65,
            682.1,
            682.45
        ],
        [
            1710775680000,
            682.45,
            682.5,
            682.1,
            682.4
        ],
        [
            1710775740000,
            682,
            682.5,
            682,
            682.5
        ],
        [
            1710839700000,
            681.95,
            682,
            676.65,
            677.5
        ],
        [
            1710839760000,
            676.35,
            676.95,
            676.2,
            676.25
        ],
        [
            1710839820000,
            676.65,
            677.5,
            676.65,
            677.05
        ],
        [
            1710839880000,
            678.25,
            678.25,
            677.3,
            677.75
        ],
        [
            1710839940000,
            678.8,
            678.8,
            677.85,
            678.4
        ],
        [
            1710840000000,
            678.15,
            678.15,
            677.4,
            677.95
        ],
        [
            1710840060000,
            677.85,
            678.35,
            677.05,
            677.55
        ],
        [
            1710840120000,
            677.8,
            678.1,
            677.5,
            678
        ],
        [
            1710840180000,
            678,
            678.1,
            677.6,
            677.7
        ],
        [
            1710840240000,
            677.75,
            677.8,
            676.4,
            676.4
        ],
        [
            1710840300000,
            676.25,
            676.25,
            675,
            675
        ],
        [
            1710840360000,
            675,
            675.35,
            675,
            675
        ],
        [
            1710840420000,
            674.5,
            674.9,
            674.5,
            674.9
        ],
        [
            1710840480000,
            674.9,
            675.9,
            674.9,
            675.55
        ],
        [
            1710840540000,
            675.55,
            675.75,
            674,
            674.5
        ],
        [
            1710840600000,
            674.1,
            674.95,
            673.85,
            674.95
        ],
        [
            1710840660000,
            674.95,
            674.95,
            674.6,
            674.95
        ],
        [
            1710840720000,
            674.9,
            675.1,
            674.9,
            675.1
        ],
        [
            1710840780000,
            675,
            675,
            674.5,
            674.9
        ],
        [
            1710840840000,
            674.6,
            674.85,
            673.65,
            673.7
        ],
        [
            1710840900000,
            674.05,
            674.05,
            673.4,
            673.5
        ],
        [
            1710840960000,
            673.3,
            673.3,
            672.35,
            672.35
        ],
        [
            1710841020000,
            672.7,
            672.95,
            672.2,
            672.7
        ],
        [
            1710841080000,
            672.5,
            672.7,
            672.35,
            672.35
        ],
        [
            1710841140000,
            672.25,
            672.55,
            671.7,
            671.7
        ],
        [
            1710841200000,
            671.7,
            672.25,
            671.6,
            672.25
        ],
        [
            1710841260000,
            672.25,
            672.25,
            672,
            672
        ],
        [
            1710841320000,
            672.05,
            672.05,
            670.7,
            670.8
        ],
        [
            1710841380000,
            671.3,
            672,
            671,
            671.3
        ],
        [
            1710841440000,
            671.35,
            671.65,
            671.25,
            671.3
        ],
        [
            1710841500000,
            671.3,
            671.35,
            671.3,
            671.35
        ],
        [
            1710841560000,
            671.35,
            671.55,
            671.35,
            671.45
        ],
        [
            1710841620000,
            671.35,
            671.4,
            671.3,
            671.3
        ],
        [
            1710841680000,
            671.35,
            671.4,
            671.3,
            671.4
        ],
        [
            1710841740000,
            671.3,
            671.35,
            671.3,
            671.3
        ],
        [
            1710841800000,
            671.35,
            672,
            671.15,
            671.75
        ],
        [
            1710841860000,
            671.9,
            671.9,
            670.05,
            670.05
        ],
        [
            1710841920000,
            670,
            670.3,
            669.55,
            670.2
        ],
        [
            1710841980000,
            669.9,
            670.2,
            669.9,
            669.9
        ],
        [
            1710842040000,
            669.95,
            670.35,
            669.95,
            670.25
        ],
        [
            1710842100000,
            670,
            670.4,
            670,
            670.25
        ],
        [
            1710842160000,
            670.05,
            670.2,
            669.75,
            670.1
        ],
        [
            1710842220000,
            669.9,
            669.9,
            669,
            669.6
        ],
        [
            1710842280000,
            669.6,
            669.6,
            667.65,
            668.25
        ],
        [
            1710842340000,
            668.2,
            668.55,
            666.55,
            666.95
        ],
        [
            1710842400000,
            667,
            667.7,
            666.75,
            667.2
        ],
        [
            1710842460000,
            667,
            667.7,
            667,
            667.05
        ],
        [
            1710842520000,
            667.1,
            667.5,
            666.7,
            666.95
        ],
        [
            1710842580000,
            666.6,
            666.95,
            666.5,
            666.75
        ],
        [
            1710842640000,
            667,
            667.45,
            666.5,
            666.6
        ],
        [
            1710842700000,
            666.3,
            666.85,
            666.05,
            666.25
        ],
        [
            1710842760000,
            666.65,
            666.95,
            666.6,
            666.9
        ],
        [
            1710842820000,
            666.35,
            666.85,
            665.5,
            665.75
        ],
        [
            1710842880000,
            666.1,
            666.95,
            665.75,
            666.7
        ],
        [
            1710842940000,
            666.55,
            669.45,
            666.55,
            668.95
        ],
        [
            1710843000000,
            668.95,
            669.35,
            668.95,
            669.25
        ],
        [
            1710843060000,
            669.4,
            669.4,
            667.8,
            668.1
        ],
        [
            1710843120000,
            668.35,
            668.4,
            667.8,
            668.2
        ],
        [
            1710843180000,
            668.2,
            668.25,
            667.8,
            667.8
        ],
        [
            1710843240000,
            667.8,
            668.85,
            667.8,
            668.65
        ],
        [
            1710843300000,
            668.8,
            669,
            668.4,
            668.4
        ],
        [
            1710843360000,
            668,
            668.5,
            668,
            668.4
        ],
        [
            1710843420000,
            668,
            668.35,
            668,
            668.35
        ],
        [
            1710843480000,
            668,
            668.35,
            667.75,
            668
        ],
        [
            1710843540000,
            667.65,
            668,
            667.55,
            667.9
        ],
        [
            1710843600000,
            667.9,
            667.9,
            667,
            667
        ],
        [
            1710843660000,
            666.9,
            667.05,
            666.4,
            666.4
        ],
        [
            1710843720000,
            666.3,
            666.7,
            665.75,
            665.75
        ],
        [
            1710843780000,
            665.45,
            665.75,
            665.35,
            665.45
        ],
        [
            1710843840000,
            665.4,
            665.95,
            665.4,
            665.5
        ],
        [
            1710843900000,
            665.5,
            666,
            665.5,
            665.6
        ],
        [
            1710843960000,
            665.6,
            665.9,
            664.8,
            665
        ],
        [
            1710844020000,
            665.55,
            665.8,
            664.6,
            665.8
        ],
        [
            1710844080000,
            665.8,
            666,
            665.6,
            665.95
        ],
        [
            1710844140000,
            665.95,
            666,
            665.1,
            665.1
        ],
        [
            1710844200000,
            665.1,
            665.45,
            664.8,
            664.9
        ],
        [
            1710844260000,
            664.75,
            665.65,
            664.75,
            665.3
        ],
        [
            1710844320000,
            665.3,
            666.05,
            665.3,
            665.6
        ],
        [
            1710844380000,
            665.35,
            665.8,
            665.35,
            665.4
        ],
        [
            1710844440000,
            665.7,
            665.7,
            665.4,
            665.4
        ],
        [
            1710844500000,
            665.4,
            666.35,
            665.4,
            666.3
        ],
        [
            1710844560000,
            666.3,
            666.55,
            665.4,
            665.4
        ],
        [
            1710844620000,
            665.55,
            665.65,
            665,
            665
        ],
        [
            1710844680000,
            665.15,
            665.35,
            664.5,
            664.95
        ],
        [
            1710844740000,
            664.9,
            665,
            664.6,
            664.95
        ],
        [
            1710844800000,
            664.55,
            664.8,
            664.05,
            664.55
        ],
        [
            1710844860000,
            664.2,
            664.4,
            664.2,
            664.2
        ],
        [
            1710844920000,
            664.2,
            664.4,
            664.05,
            664.4
        ],
        [
            1710844980000,
            664.2,
            664.4,
            664.05,
            664.15
        ],
        [
            1710845040000,
            664.05,
            664.1,
            663.05,
            663.3
        ],
        [
            1710845100000,
            663.35,
            664.45,
            663.35,
            663.65
        ],
        [
            1710845160000,
            664.1,
            664.1,
            663.55,
            663.85
        ],
        [
            1710845220000,
            663.55,
            663.8,
            663.5,
            663.5
        ],
        [
            1710845280000,
            663.8,
            663.8,
            663.5,
            663.8
        ],
        [
            1710845340000,
            663.8,
            664.3,
            663.55,
            664.3
        ],
        [
            1710845400000,
            664,
            664.3,
            663.1,
            663.5
        ],
        [
            1710845460000,
            663,
            663.45,
            663,
            663
        ],
        [
            1710845520000,
            663.05,
            663.55,
            662.95,
            663.45
        ],
        [
            1710845580000,
            663.75,
            664.05,
            663.35,
            664.05
        ],
        [
            1710845640000,
            663.85,
            664.1,
            663.8,
            664
        ],
        [
            1710845700000,
            664.1,
            664.2,
            664,
            664.2
        ],
        [
            1710845760000,
            664.35,
            664.45,
            663.55,
            663.6
        ],
        [
            1710845820000,
            663.6,
            664.1,
            663.6,
            663.9
        ],
        [
            1710845880000,
            663.6,
            664,
            663.5,
            663.5
        ],
        [
            1710845940000,
            663.5,
            663.85,
            663.1,
            663.1
        ],
        [
            1710846000000,
            663.35,
            664.65,
            663.05,
            664.3
        ],
        [
            1710846060000,
            664.6,
            664.8,
            663.75,
            663.75
        ],
        [
            1710846120000,
            663.85,
            664.2,
            663.8,
            663.8
        ],
        [
            1710846180000,
            663.65,
            664.2,
            663.65,
            664
        ],
        [
            1710846240000,
            664.2,
            664.35,
            663.95,
            663.95
        ],
        [
            1710846300000,
            664.15,
            664.45,
            664,
            664.05
        ],
        [
            1710846360000,
            663.95,
            664.4,
            663.9,
            664.35
        ],
        [
            1710846420000,
            664.05,
            664.1,
            663.55,
            664.1
        ],
        [
            1710846480000,
            663.9,
            664.1,
            663.8,
            663.8
        ],
        [
            1710846540000,
            663.8,
            664.2,
            663,
            664.2
        ],
        [
            1710846600000,
            664.05,
            664.55,
            664.05,
            664.55
        ],
        [
            1710846660000,
            664.45,
            664.7,
            664,
            664.7
        ],
        [
            1710846720000,
            664.2,
            664.7,
            662.6,
            662.6
        ],
        [
            1710846780000,
            662.6,
            662.8,
            662.05,
            662.8
        ],
        [
            1710846840000,
            662.8,
            664.35,
            662.35,
            664.25
        ],
        [
            1710846900000,
            664,
            665.3,
            664,
            665.3
        ],
        [
            1710846960000,
            665.35,
            666,
            665.35,
            666
        ],
        [
            1710847020000,
            666,
            666,
            665.5,
            666
        ],
        [
            1710847080000,
            666,
            667.7,
            665.75,
            667.65
        ],
        [
            1710847140000,
            667.7,
            667.85,
            667.2,
            667.45
        ],
        [
            1710847200000,
            667.45,
            667.8,
            667.4,
            667.4
        ],
        [
            1710847260000,
            666.5,
            667.15,
            666.3,
            666.3
        ],
        [
            1710847320000,
            666.3,
            666.85,
            666.1,
            666.25
        ],
        [
            1710847380000,
            666.3,
            666.7,
            666,
            666.05
        ],
        [
            1710847440000,
            666.1,
            666.6,
            665.95,
            665.95
        ],
        [
            1710847500000,
            665.85,
            666.45,
            665.7,
            666.2
        ],
        [
            1710847560000,
            666.45,
            666.6,
            666.45,
            666.45
        ],
        [
            1710847620000,
            666.6,
            666.75,
            666.05,
            666.05
        ],
        [
            1710847680000,
            665.85,
            666.45,
            665.85,
            666
        ],
        [
            1710847740000,
            666.4,
            666.45,
            666.1,
            666.2
        ],
        [
            1710847800000,
            666.1,
            666.75,
            666.05,
            666.5
        ],
        [
            1710847860000,
            666.4,
            666.4,
            665.05,
            665.25
        ],
        [
            1710847920000,
            665.85,
            665.85,
            665.25,
            665.25
        ],
        [
            1710847980000,
            665.7,
            665.85,
            665.5,
            665.5
        ],
        [
            1710848040000,
            665.85,
            665.85,
            665.05,
            665.05
        ],
        [
            1710848100000,
            665.3,
            665.5,
            664.3,
            665.35
        ],
        [
            1710848160000,
            665.1,
            665.85,
            665.1,
            665.35
        ],
        [
            1710848220000,
            665.4,
            665.7,
            665.3,
            665.3
        ],
        [
            1710848280000,
            665.35,
            665.9,
            665.35,
            665.45
        ],
        [
            1710848340000,
            665.45,
            665.45,
            664.35,
            664.85
        ],
        [
            1710848400000,
            664.8,
            665.1,
            664.65,
            664.9
        ],
        [
            1710848460000,
            664.85,
            665.05,
            664.5,
            664.9
        ],
        [
            1710848520000,
            664.9,
            665.2,
            664.8,
            664.8
        ],
        [
            1710848580000,
            664.4,
            664.7,
            663.85,
            664.25
        ],
        [
            1710848640000,
            664.25,
            664.45,
            663.9,
            663.95
        ],
        [
            1710848700000,
            663.9,
            664.3,
            663.3,
            663.3
        ],
        [
            1710848760000,
            663.3,
            663.9,
            663.3,
            663.45
        ],
        [
            1710848820000,
            663.9,
            664.3,
            663.75,
            664
        ],
        [
            1710848880000,
            664.3,
            664.3,
            663.85,
            663.9
        ],
        [
            1710848940000,
            663.8,
            663.95,
            663.35,
            663.95
        ],
        [
            1710849000000,
            663.95,
            664,
            663.95,
            664
        ],
        [
            1710849060000,
            664,
            664.15,
            663.9,
            663.95
        ],
        [
            1710849120000,
            663.9,
            663.95,
            663.6,
            663.6
        ],
        [
            1710849180000,
            663.95,
            663.95,
            663.3,
            663.3
        ],
        [
            1710849240000,
            663.25,
            663.7,
            663.1,
            663.55
        ],
        [
            1710849300000,
            663.75,
            663.85,
            663.2,
            663.75
        ],
        [
            1710849360000,
            663.8,
            663.95,
            663.75,
            663.8
        ],
        [
            1710849420000,
            663.2,
            663.35,
            662.9,
            663.05
        ],
        [
            1710849480000,
            663.05,
            663.35,
            662.75,
            663.25
        ],
        [
            1710849540000,
            662.7,
            663.2,
            662.6,
            662.65
        ],
        [
            1710849600000,
            663.1,
            663.1,
            662.3,
            662.4
        ],
        [
            1710849660000,
            662.7,
            662.75,
            662.3,
            662.7
        ],
        [
            1710849720000,
            662.75,
            663,
            662.35,
            662.7
        ],
        [
            1710849780000,
            662.7,
            662.95,
            662.55,
            662.95
        ],
        [
            1710849840000,
            662.8,
            663.05,
            662.8,
            663
        ],
        [
            1710849900000,
            662.9,
            663.15,
            662.8,
            662.8
        ],
        [
            1710849960000,
            663.2,
            663.5,
            663,
            663
        ],
        [
            1710850020000,
            663.2,
            663.5,
            663,
            663.5
        ],
        [
            1710850080000,
            663.45,
            664.3,
            663.45,
            664
        ],
        [
            1710850140000,
            664.3,
            664.3,
            663.5,
            663.5
        ],
        [
            1710850200000,
            663.45,
            665.45,
            663.05,
            664.65
        ],
        [
            1710850260000,
            665.25,
            665.4,
            664.1,
            664.95
        ],
        [
            1710850320000,
            665.05,
            665.6,
            665,
            665.1
        ],
        [
            1710850380000,
            665.25,
            665.5,
            665,
            665.05
        ],
        [
            1710850440000,
            665.5,
            665.6,
            665,
            665
        ],
        [
            1710850500000,
            665.15,
            665.65,
            664.35,
            664.35
        ],
        [
            1710850560000,
            664.25,
            665.15,
            664.25,
            665
        ],
        [
            1710850620000,
            665.2,
            665.25,
            664.9,
            664.95
        ],
        [
            1710850680000,
            665.1,
            665.7,
            665.1,
            665.35
        ],
        [
            1710850740000,
            665.6,
            666.15,
            665.6,
            665.85
        ],
        [
            1710850800000,
            665.9,
            666.4,
            665.7,
            665.7
        ],
        [
            1710850860000,
            665.75,
            666.2,
            665.65,
            665.8
        ],
        [
            1710850920000,
            665.7,
            666.4,
            665.7,
            666.25
        ],
        [
            1710850980000,
            666.35,
            666.9,
            665.85,
            666.25
        ],
        [
            1710851040000,
            666.25,
            666.5,
            666.05,
            666.1
        ],
        [
            1710851100000,
            666.15,
            666.55,
            666.1,
            666.2
        ],
        [
            1710851160000,
            666.25,
            666.8,
            666.25,
            666.8
        ],
        [
            1710851220000,
            666.35,
            666.8,
            666.35,
            666.8
        ],
        [
            1710851280000,
            666.8,
            666.8,
            666.4,
            666.5
        ],
        [
            1710851340000,
            666.75,
            666.8,
            666,
            666
        ],
        [
            1710851400000,
            666,
            666.5,
            666,
            666.15
        ],
        [
            1710851460000,
            666.15,
            666.7,
            666.1,
            666.7
        ],
        [
            1710851520000,
            666.6,
            667.05,
            666,
            667.05
        ],
        [
            1710851580000,
            667.05,
            667.85,
            667.05,
            667.05
        ],
        [
            1710851640000,
            667.2,
            667.4,
            666.7,
            667.1
        ],
        [
            1710851700000,
            667.4,
            667.6,
            667.1,
            667.3
        ],
        [
            1710851760000,
            667.3,
            667.65,
            667.2,
            667.2
        ],
        [
            1710851820000,
            667.2,
            667.7,
            665.9,
            666.7
        ],
        [
            1710851880000,
            666.65,
            667.1,
            666,
            666
        ],
        [
            1710851940000,
            665.85,
            666,
            665.5,
            665.95
        ],
        [
            1710852000000,
            666,
            666.05,
            665.05,
            665.5
        ],
        [
            1710852060000,
            665.3,
            665.65,
            665.25,
            665.3
        ],
        [
            1710852120000,
            665.3,
            666.4,
            665.3,
            666
        ],
        [
            1710852180000,
            666,
            668.5,
            666,
            668.1
        ],
        [
            1710852240000,
            668.45,
            668.45,
            666.5,
            667.25
        ],
        [
            1710852300000,
            667.65,
            668.45,
            667.35,
            668
        ],
        [
            1710852360000,
            668.35,
            668.8,
            668,
            668.2
        ],
        [
            1710852420000,
            668.45,
            669.2,
            668.2,
            669.2
        ],
        [
            1710852480000,
            669.4,
            670.45,
            669,
            670
        ],
        [
            1710852540000,
            670,
            670.05,
            669.5,
            670
        ],
        [
            1710852600000,
            670,
            670.35,
            669.85,
            669.85
        ],
        [
            1710852660000,
            669.95,
            670.25,
            669.3,
            669.75
        ],
        [
            1710852720000,
            670,
            670.25,
            670,
            670
        ],
        [
            1710852780000,
            670,
            670.15,
            670,
            670.1
        ],
        [
            1710852840000,
            670.1,
            671.75,
            670.1,
            671.2
        ],
        [
            1710852900000,
            671.15,
            671.5,
            671.1,
            671.1
        ],
        [
            1710852960000,
            671.5,
            671.5,
            671.05,
            671.45
        ],
        [
            1710853020000,
            671.05,
            671.45,
            671,
            671.4
        ],
        [
            1710853080000,
            671,
            671.75,
            671,
            671.75
        ],
        [
            1710853140000,
            671.45,
            671.75,
            671.15,
            671.2
        ],
        [
            1710853200000,
            671.5,
            672.65,
            671.5,
            672
        ],
        [
            1710853260000,
            672.35,
            672.35,
            671.15,
            671.4
        ],
        [
            1710853320000,
            671.4,
            671.95,
            671,
            671.7
        ],
        [
            1710853380000,
            671.15,
            671.9,
            670,
            670
        ],
        [
            1710853440000,
            670.35,
            671.95,
            670.35,
            671.5
        ],
        [
            1710853500000,
            671.55,
            672.5,
            671.55,
            672.5
        ],
        [
            1710853560000,
            672.1,
            672.95,
            671.55,
            672.95
        ],
        [
            1710853620000,
            672.5,
            672.9,
            671.7,
            672.5
        ],
        [
            1710853680000,
            672.5,
            673.5,
            671.65,
            673.25
        ],
        [
            1710853740000,
            673.35,
            674.15,
            672.95,
            674.15
        ],
        [
            1710853800000,
            673.85,
            674.65,
            673.3,
            673.55
        ],
        [
            1710853860000,
            673.6,
            674.45,
            673.4,
            673.8
        ],
        [
            1710853920000,
            673.8,
            674.3,
            673.4,
            674
        ],
        [
            1710853980000,
            674.45,
            674.5,
            673.45,
            674.35
        ],
        [
            1710854040000,
            673.85,
            674.35,
            673.45,
            674.35
        ],
        [
            1710854100000,
            674,
            674.45,
            673.4,
            674.25
        ],
        [
            1710854160000,
            674.1,
            674.7,
            674,
            674.7
        ],
        [
            1710854220000,
            674.8,
            674.9,
            674.25,
            674.5
        ],
        [
            1710854280000,
            674.5,
            674.9,
            673.3,
            673.8
        ],
        [
            1710854340000,
            674.2,
            674.5,
            673.5,
            673.7
        ],
        [
            1710854400000,
            673.9,
            674.8,
            673.5,
            674.8
        ],
        [
            1710854460000,
            674.8,
            674.85,
            673.7,
            674.35
        ],
        [
            1710854520000,
            674.4,
            675,
            674.4,
            674.55
        ],
        [
            1710854580000,
            674.6,
            674.95,
            673.4,
            674.45
        ],
        [
            1710854640000,
            673.7,
            674.75,
            673.35,
            673.85
        ],
        [
            1710854700000,
            673.7,
            674.55,
            673.45,
            674.15
        ],
        [
            1710854760000,
            674.2,
            674.5,
            673.35,
            674.2
        ],
        [
            1710854820000,
            674,
            674.5,
            673.35,
            674.5
        ],
        [
            1710854880000,
            674.05,
            674.5,
            673.5,
            673.55
        ],
        [
            1710854940000,
            673.85,
            674.2,
            673.3,
            673.65
        ],
        [
            1710855000000,
            673.4,
            674.3,
            673.4,
            673.85
        ],
        [
            1710855060000,
            673.9,
            674.3,
            673.9,
            673.95
        ],
        [
            1710855120000,
            673.9,
            674.2,
            673.9,
            674
        ],
        [
            1710855180000,
            674,
            674.3,
            673.3,
            673.85
        ],
        [
            1710855240000,
            673.9,
            674.5,
            673.9,
            674.5
        ],
        [
            1710855300000,
            674.5,
            674.5,
            673.5,
            674.25
        ],
        [
            1710855360000,
            673.95,
            674.4,
            673.85,
            673.95
        ],
        [
            1710855420000,
            673.8,
            674.5,
            673.45,
            674.05
        ],
        [
            1710855480000,
            674.5,
            674.5,
            673.55,
            674.45
        ],
        [
            1710855540000,
            674.2,
            674.5,
            673.3,
            674.4
        ],
        [
            1710855600000,
            674.45,
            674.45,
            673.45,
            673.95
        ],
        [
            1710855660000,
            674.4,
            674.5,
            673.9,
            674.2
        ],
        [
            1710855720000,
            674.5,
            674.55,
            673.65,
            674.55
        ],
        [
            1710855780000,
            673.7,
            674.7,
            673.65,
            674.7
        ],
        [
            1710855840000,
            674.7,
            674.9,
            673.75,
            674.4
        ],
        [
            1710855900000,
            674.5,
            674.8,
            673.85,
            674.4
        ],
        [
            1710855960000,
            674.8,
            674.95,
            674.4,
            674.6
        ],
        [
            1710856020000,
            675,
            675,
            673.9,
            674.95
        ],
        [
            1710856080000,
            674.35,
            674.95,
            673.45,
            674.65
        ],
        [
            1710856140000,
            674.95,
            674.95,
            673.55,
            674.5
        ],
        [
            1710856200000,
            674.25,
            674.5,
            673.5,
            674.1
        ],
        [
            1710856260000,
            674.25,
            674.35,
            673.5,
            674
        ],
        [
            1710856320000,
            674.4,
            674.5,
            674.1,
            674.5
        ],
        [
            1710856380000,
            674.45,
            674.9,
            673.85,
            674.9
        ],
        [
            1710856440000,
            674.8,
            675.25,
            674.8,
            675.05
        ],
        [
            1710856500000,
            675.35,
            675.35,
            674.1,
            674.15
        ],
        [
            1710856560000,
            674.45,
            675.2,
            674.15,
            675.05
        ],
        [
            1710856620000,
            675.15,
            675.65,
            675,
            675.25
        ],
        [
            1710856680000,
            675.4,
            675.4,
            675,
            675.05
        ],
        [
            1710856740000,
            675,
            675,
            674.8,
            675
        ],
        [
            1710856800000,
            675.05,
            675.2,
            674.3,
            674.3
        ],
        [
            1710856860000,
            674.15,
            674.15,
            673.5,
            673.75
        ],
        [
            1710856920000,
            673.55,
            674.15,
            673.55,
            674.15
        ],
        [
            1710856980000,
            674.45,
            674.8,
            673.4,
            673.95
        ],
        [
            1710857040000,
            673.95,
            673.95,
            673.6,
            673.8
        ],
        [
            1710857100000,
            673.9,
            673.95,
            673.5,
            673.95
        ],
        [
            1710857160000,
            673.9,
            673.95,
            673.6,
            673.7
        ],
        [
            1710857220000,
            673.9,
            673.9,
            673.05,
            673.05
        ],
        [
            1710857280000,
            672.8,
            673.85,
            672.65,
            673.05
        ],
        [
            1710857340000,
            672.55,
            673.95,
            672.25,
            673.95
        ],
        [
            1710857400000,
            673.9,
            673.9,
            673.1,
            673.4
        ],
        [
            1710857460000,
            673.75,
            673.75,
            672.2,
            672.7
        ],
        [
            1710857520000,
            672.75,
            672.9,
            671.2,
            672.4
        ],
        [
            1710857580000,
            672.1,
            672.4,
            671.05,
            672.2
        ],
        [
            1710857640000,
            671.8,
            673.95,
            671.8,
            673.95
        ],
        [
            1710857700000,
            673.95,
            674.05,
            673.4,
            673.65
        ],
        [
            1710857760000,
            673.65,
            674,
            672.25,
            673
        ],
        [
            1710857820000,
            673.4,
            673.4,
            671.7,
            672.85
        ],
        [
            1710857880000,
            672.85,
            673.45,
            672.5,
            673.2
        ],
        [
            1710857940000,
            673.5,
            674.25,
            673.2,
            674
        ],
        [
            1710858000000,
            673.8,
            674,
            673.35,
            673.95
        ],
        [
            1710858060000,
            673.55,
            673.95,
            673.05,
            673.7
        ],
        [
            1710858120000,
            673.3,
            673.7,
            673.25,
            673.35
        ],
        [
            1710858180000,
            673.35,
            673.6,
            673.05,
            673.25
        ],
        [
            1710858240000,
            673.55,
            673.65,
            672.55,
            672.9
        ],
        [
            1710858300000,
            673,
            673.65,
            672.55,
            673.05
        ],
        [
            1710858360000,
            673.45,
            673.5,
            673.05,
            673.3
        ],
        [
            1710858420000,
            673.5,
            673.5,
            673.1,
            673.45
        ],
        [
            1710858480000,
            673.45,
            673.45,
            672.1,
            672.1
        ],
        [
            1710858540000,
            671.9,
            673.4,
            671.9,
            673.2
        ],
        [
            1710858600000,
            672.9,
            673.5,
            672.9,
            673.5
        ],
        [
            1710858660000,
            673.5,
            673.65,
            672.3,
            673
        ],
        [
            1710858720000,
            672.75,
            673.45,
            672.7,
            673.45
        ],
        [
            1710858780000,
            673,
            673.45,
            673,
            673.1
        ],
        [
            1710858840000,
            673.1,
            673.45,
            672.85,
            673
        ],
        [
            1710858900000,
            673,
            673.45,
            673,
            673.4
        ],
        [
            1710858960000,
            673,
            673.5,
            673,
            673.15
        ],
        [
            1710859020000,
            673.05,
            673.4,
            672.9,
            673.4
        ],
        [
            1710859080000,
            673.05,
            673.4,
            672.6,
            673.2
        ],
        [
            1710859140000,
            672.8,
            673.4,
            672.8,
            673.15
        ],
        [
            1710859200000,
            673.4,
            673.4,
            672.85,
            672.85
        ],
        [
            1710859260000,
            673.4,
            673.4,
            672.85,
            672.9
        ],
        [
            1710859320000,
            672.9,
            673.4,
            672.85,
            673.35
        ],
        [
            1710859380000,
            673.4,
            673.4,
            672.9,
            673.35
        ],
        [
            1710859440000,
            673.4,
            675.05,
            672.95,
            675.05
        ],
        [
            1710859500000,
            675.4,
            675.45,
            674.5,
            674.5
        ],
        [
            1710859560000,
            674.05,
            674.6,
            674.05,
            674.5
        ],
        [
            1710859620000,
            674.6,
            674.65,
            674.05,
            674.5
        ],
        [
            1710859680000,
            674.1,
            674.9,
            673.8,
            674.9
        ],
        [
            1710859740000,
            674.9,
            674.9,
            673.2,
            674.1
        ],
        [
            1710859800000,
            673.85,
            674.9,
            673.85,
            674.9
        ],
        [
            1710859860000,
            674.65,
            675.85,
            674.65,
            675.45
        ],
        [
            1710859920000,
            675.6,
            675.65,
            675.1,
            675.1
        ],
        [
            1710859980000,
            675.2,
            675.55,
            675,
            675.5
        ],
        [
            1710860040000,
            675.05,
            675.4,
            675,
            675.4
        ],
        [
            1710860100000,
            675.5,
            675.5,
            675,
            675.45
        ],
        [
            1710860160000,
            675.05,
            675.05,
            674.15,
            674.95
        ],
        [
            1710860220000,
            675,
            675.35,
            674.05,
            674.75
        ],
        [
            1710860280000,
            674.95,
            675.7,
            673.75,
            675.25
        ],
        [
            1710860340000,
            675.3,
            675.35,
            675,
            675.05
        ],
        [
            1710860400000,
            675.05,
            675.05,
            674.9,
            675
        ],
        [
            1710860460000,
            674.75,
            675.05,
            674.7,
            675
        ],
        [
            1710860520000,
            675.3,
            676.2,
            675.2,
            676.2
        ],
        [
            1710860580000,
            676,
            676.25,
            675.35,
            675.35
        ],
        [
            1710860640000,
            675.4,
            675.55,
            675.25,
            675.25
        ],
        [
            1710860700000,
            675.25,
            675.85,
            675.1,
            675.3
        ],
        [
            1710860760000,
            674.55,
            675.35,
            674.55,
            675.35
        ],
        [
            1710860820000,
            675.05,
            675.35,
            674,
            675
        ],
        [
            1710860880000,
            675,
            675.3,
            674.8,
            675
        ],
        [
            1710860940000,
            675,
            675.05,
            674.8,
            675.05
        ],
        [
            1710861000000,
            675.85,
            675.85,
            674.3,
            674.3
        ],
        [
            1710861060000,
            674.45,
            675.2,
            674.4,
            675
        ],
        [
            1710861120000,
            674.75,
            675,
            674.05,
            675
        ],
        [
            1710861180000,
            675,
            675,
            674.05,
            674.35
        ],
        [
            1710861240000,
            674.95,
            674.95,
            673.9,
            674.9
        ],
        [
            1710861300000,
            674.35,
            675.05,
            674.35,
            675
        ],
        [
            1710861360000,
            674.4,
            674.55,
            673.7,
            674.2
        ],
        [
            1710861420000,
            674.65,
            674.7,
            673.65,
            674.4
        ],
        [
            1710861480000,
            674.55,
            674.95,
            674,
            674.75
        ],
        [
            1710861540000,
            674.05,
            674.05,
            672.65,
            673.35
        ],
        [
            1710861600000,
            673.6,
            673.95,
            673.25,
            673.25
        ],
        [
            1710861660000,
            673.3,
            674.6,
            673.3,
            674.6
        ],
        [
            1710861720000,
            674.05,
            674.35,
            673,
            674.35
        ],
        [
            1710861780000,
            674.35,
            674.55,
            673.75,
            674.15
        ],
        [
            1710861840000,
            673.85,
            673.85,
            672.05,
            672.1
        ],
        [
            1710861900000,
            672.4,
            674.2,
            672.4,
            673.95
        ],
        [
            1710861960000,
            673.7,
            673.85,
            672.65,
            673.2
        ],
        [
            1710862020000,
            673.2,
            674.75,
            672.9,
            674.75
        ],
        [
            1710862080000,
            673.5,
            674.6,
            673.1,
            673.15
        ],
        [
            1710862140000,
            673.1,
            673.75,
            673.1,
            673.7
        ],
        [
            1710862200000,
            674.6,
            674.6,
            674.6,
            674.6
        ],
        [
            1710926100000,
            674.4,
            674.4,
            672.25,
            672.25
        ],
        [
            1710926160000,
            671.9,
            672.95,
            671.9,
            672.95
        ],
        [
            1710926220000,
            674.15,
            674.15,
            673.65,
            673.9
        ],
        [
            1710926280000,
            673.9,
            673.9,
            673.5,
            673.5
        ],
        [
            1710926340000,
            673.05,
            673.7,
            672.45,
            673.7
        ],
        [
            1710926400000,
            673.3,
            673.7,
            673.1,
            673.15
        ],
        [
            1710926460000,
            672.85,
            672.85,
            670.1,
            670.15
        ],
        [
            1710926520000,
            670.3,
            670.65,
            669.15,
            669.15
        ],
        [
            1710926580000,
            669,
            669.8,
            668.85,
            669.4
        ],
        [
            1710926640000,
            669,
            669.15,
            668.4,
            669.15
        ],
        [
            1710926700000,
            669.65,
            670.65,
            669.5,
            670.65
        ],
        [
            1710926760000,
            670.65,
            673.5,
            670.35,
            673
        ],
        [
            1710926820000,
            673.1,
            673.15,
            671.5,
            672.8
        ],
        [
            1710926880000,
            672.6,
            673.45,
            671.4,
            672.7
        ],
        [
            1710926940000,
            673.05,
            673.1,
            672.35,
            672.35
        ],
        [
            1710927000000,
            672.4,
            672.4,
            670.95,
            671
        ],
        [
            1710927060000,
            670.8,
            670.8,
            670,
            670.1
        ],
        [
            1710927120000,
            670.2,
            670.2,
            668.9,
            669
        ],
        [
            1710927180000,
            669.45,
            669.65,
            669.3,
            669.5
        ],
        [
            1710927240000,
            669.35,
            669.8,
            668.9,
            669.4
        ],
        [
            1710927300000,
            669.7,
            669.7,
            668.3,
            668.3
        ],
        [
            1710927360000,
            668.55,
            668.55,
            667.55,
            668
        ],
        [
            1710927420000,
            668,
            669.65,
            668,
            669.65
        ],
        [
            1710927480000,
            669.8,
            670,
            669.6,
            669.95
        ],
        [
            1710927540000,
            669.95,
            670,
            669.95,
            670
        ],
        [
            1710927600000,
            669.75,
            669.75,
            669.3,
            669.75
        ],
        [
            1710927660000,
            669.75,
            669.75,
            669.45,
            669.45
        ],
        [
            1710927720000,
            669.3,
            669.3,
            668.05,
            668.1
        ],
        [
            1710927780000,
            668.1,
            668.1,
            667.5,
            667.55
        ],
        [
            1710927840000,
            667.55,
            667.75,
            667.1,
            667.75
        ],
        [
            1710927900000,
            667.85,
            667.85,
            667.25,
            667.75
        ],
        [
            1710927960000,
            667.5,
            667.8,
            667.5,
            667.8
        ],
        [
            1710928020000,
            667.55,
            667.8,
            667.3,
            667.4
        ],
        [
            1710928080000,
            667.6,
            668.4,
            667.6,
            668.4
        ],
        [
            1710928140000,
            668,
            669,
            668,
            669
        ],
        [
            1710928200000,
            668.95,
            670.7,
            668.95,
            670.1
        ],
        [
            1710928260000,
            669.8,
            669.8,
            669.55,
            669.8
        ],
        [
            1710928320000,
            669.5,
            669.5,
            669.5,
            669.5
        ],
        [
            1710928380000,
            669.15,
            669.2,
            668.95,
            669.2
        ],
        [
            1710928440000,
            669.15,
            669.15,
            667.9,
            667.9
        ],
        [
            1710928500000,
            668.55,
            669.15,
            668.55,
            669.15
        ],
        [
            1710928560000,
            668.9,
            669,
            668.35,
            668.8
        ],
        [
            1710928620000,
            668.7,
            668.7,
            668.1,
            668.1
        ],
        [
            1710928680000,
            668.1,
            668.4,
            668,
            668.4
        ],
        [
            1710928740000,
            667.95,
            668.05,
            666.55,
            666.55
        ],
        [
            1710928800000,
            666.35,
            668,
            666.05,
            667.4
        ],
        [
            1710928860000,
            667.3,
            667.3,
            665.8,
            666.25
        ],
        [
            1710928920000,
            666,
            666.4,
            665.2,
            665.2
        ],
        [
            1710928980000,
            665.15,
            665.8,
            665.15,
            665.7
        ],
        [
            1710929040000,
            665.85,
            665.85,
            665.05,
            665.5
        ],
        [
            1710929100000,
            665.5,
            665.6,
            665.15,
            665.6
        ],
        [
            1710929160000,
            665.5,
            665.85,
            665.5,
            665.85
        ],
        [
            1710929220000,
            665.4,
            665.85,
            665.4,
            665.85
        ],
        [
            1710929280000,
            665.45,
            666,
            665.45,
            665.75
        ],
        [
            1710929340000,
            665.85,
            665.85,
            665.7,
            665.7
        ],
        [
            1710929400000,
            665.35,
            666.2,
            665.35,
            666.15
        ],
        [
            1710929460000,
            666.2,
            666.2,
            665.8,
            666.15
        ],
        [
            1710929520000,
            665.7,
            665.7,
            665.2,
            665.2
        ],
        [
            1710929580000,
            665.25,
            666,
            665.25,
            665.55
        ],
        [
            1710929640000,
            665.05,
            665.85,
            665.05,
            665.85
        ],
        [
            1710929700000,
            665.8,
            665.95,
            665.55,
            665.95
        ],
        [
            1710929760000,
            665.95,
            666,
            665.95,
            666
        ],
        [
            1710929820000,
            666.1,
            667.55,
            666,
            667.55
        ],
        [
            1710929880000,
            667.25,
            668.35,
            667.25,
            668
        ],
        [
            1710929940000,
            668.6,
            668.95,
            668,
            668.7
        ],
        [
            1710930000000,
            668.7,
            669,
            668,
            668.8
        ],
        [
            1710930060000,
            668.65,
            668.65,
            668,
            668
        ],
        [
            1710930120000,
            668.05,
            668.65,
            668,
            668.65
        ],
        [
            1710930180000,
            668,
            668.5,
            667.95,
            668.5
        ],
        [
            1710930240000,
            668.5,
            668.5,
            668,
            668.3
        ],
        [
            1710930300000,
            668.05,
            668.95,
            668.05,
            668.95
        ],
        [
            1710930360000,
            668.95,
            669,
            668.65,
            669
        ],
        [
            1710930420000,
            669,
            669,
            668.5,
            668.9
        ],
        [
            1710930480000,
            668.75,
            669,
            668.7,
            669
        ],
        [
            1710930540000,
            668.65,
            669,
            668.35,
            668.35
        ],
        [
            1710930600000,
            668.4,
            668.4,
            667.85,
            668
        ],
        [
            1710930660000,
            668,
            668.45,
            668,
            668.25
        ],
        [
            1710930720000,
            667.8,
            668.4,
            667.8,
            668.15
        ],
        [
            1710930780000,
            668,
            668.45,
            668,
            668
        ],
        [
            1710930840000,
            668,
            668.05,
            668,
            668
        ],
        [
            1710930900000,
            668.15,
            668.15,
            668,
            668
        ],
        [
            1710930960000,
            668,
            668.25,
            668,
            668.05
        ],
        [
            1710931020000,
            668,
            668.25,
            668,
            668.15
        ],
        [
            1710931080000,
            668.05,
            668.05,
            668,
            668
        ],
        [
            1710931140000,
            668.15,
            668.15,
            668,
            668
        ],
        [
            1710931200000,
            668,
            668.15,
            668,
            668
        ],
        [
            1710931260000,
            668,
            668.55,
            668,
            668.05
        ],
        [
            1710931320000,
            668.05,
            669,
            668.05,
            668.4
        ],
        [
            1710931380000,
            668.4,
            668.4,
            668,
            668
        ],
        [
            1710931440000,
            668,
            668.4,
            668,
            668.1
        ],
        [
            1710931500000,
            668.1,
            668.6,
            668,
            668.3
        ],
        [
            1710931560000,
            668.1,
            668.6,
            667.95,
            668
        ],
        [
            1710931620000,
            667.9,
            668.2,
            667.9,
            668
        ],
        [
            1710931680000,
            668,
            668.4,
            667.8,
            668.05
        ],
        [
            1710931740000,
            668.15,
            668.25,
            667.85,
            668
        ],
        [
            1710931800000,
            668,
            668.45,
            667.95,
            668
        ],
        [
            1710931860000,
            668,
            668.4,
            667.95,
            668
        ],
        [
            1710931920000,
            667.95,
            668.4,
            667.95,
            668.4
        ],
        [
            1710931980000,
            668.4,
            668.5,
            668,
            668.1
        ],
        [
            1710932040000,
            668.1,
            668.1,
            668,
            668.05
        ],
        [
            1710932100000,
            668,
            668.85,
            668,
            668.85
        ],
        [
            1710932160000,
            668.55,
            669,
            668.55,
            668.9
        ],
        [
            1710932220000,
            668.55,
            672,
            668.55,
            671.35
        ],
        [
            1710932280000,
            671.3,
            671.6,
            670.45,
            670.45
        ],
        [
            1710932340000,
            670.45,
            671,
            670.45,
            670.9
        ],
        [
            1710932400000,
            670.55,
            670.9,
            670.1,
            670.1
        ],
        [
            1710932460000,
            669.8,
            670,
            668.6,
            668.75
        ],
        [
            1710932520000,
            669,
            669,
            668.45,
            668.6
        ],
        [
            1710932580000,
            668.6,
            669.25,
            668.55,
            669.25
        ],
        [
            1710932640000,
            669,
            669.35,
            668.4,
            669.35
        ],
        [
            1710932700000,
            669.45,
            669.45,
            668.05,
            668.3
        ],
        [
            1710932760000,
            668,
            668.55,
            667.95,
            668
        ],
        [
            1710932820000,
            668,
            668.2,
            667.85,
            668
        ],
        [
            1710932880000,
            668,
            668.05,
            667.8,
            668
        ],
        [
            1710932940000,
            668,
            668.1,
            668,
            668.1
        ],
        [
            1710933000000,
            668,
            668.7,
            667.8,
            668.55
        ],
        [
            1710933060000,
            668.55,
            668.55,
            668.45,
            668.5
        ],
        [
            1710933120000,
            668.5,
            668.8,
            668.45,
            668.45
        ],
        [
            1710933180000,
            668.45,
            668.45,
            668.1,
            668.1
        ],
        [
            1710933240000,
            668.1,
            668.1,
            668.05,
            668.05
        ],
        [
            1710933300000,
            668,
            668,
            668,
            668
        ],
        [
            1710933360000,
            668,
            670.5,
            668,
            670.45
        ],
        [
            1710933420000,
            670.2,
            670.3,
            670,
            670
        ],
        [
            1710933480000,
            670,
            670.2,
            670,
            670
        ],
        [
            1710933540000,
            670,
            670.2,
            670,
            670
        ],
        [
            1710933600000,
            670,
            670.1,
            670,
            670
        ],
        [
            1710933660000,
            670,
            670.4,
            670,
            670.3
        ],
        [
            1710933720000,
            670.3,
            670.5,
            670.2,
            670.5
        ],
        [
            1710933780000,
            671.25,
            671.35,
            670.3,
            670.6
        ],
        [
            1710933840000,
            670.6,
            670.8,
            670.1,
            670.1
        ],
        [
            1710933900000,
            670.05,
            670.15,
            670.05,
            670.05
        ],
        [
            1710933960000,
            670.05,
            670.3,
            670.05,
            670.15
        ],
        [
            1710934020000,
            670.15,
            670.95,
            670.05,
            670.45
        ],
        [
            1710934080000,
            670.45,
            670.55,
            670.15,
            670.55
        ],
        [
            1710934140000,
            670.3,
            670.55,
            670.2,
            670.4
        ],
        [
            1710934200000,
            670.4,
            670.85,
            670.2,
            670.25
        ],
        [
            1710934260000,
            670.35,
            670.35,
            670.25,
            670.25
        ],
        [
            1710934320000,
            670.25,
            670.9,
            670,
            670.55
        ],
        [
            1710934380000,
            670.35,
            671.05,
            670.3,
            671.05
        ],
        [
            1710934440000,
            671,
            671.05,
            670.65,
            670.75
        ],
        [
            1710934500000,
            670.6,
            670.7,
            670.35,
            670.6
        ],
        [
            1710934560000,
            670.5,
            670.95,
            670.35,
            670.5
        ],
        [
            1710934620000,
            670.55,
            671.25,
            670.55,
            671.1
        ],
        [
            1710934680000,
            670.85,
            671.05,
            670.85,
            671
        ],
        [
            1710934740000,
            671,
            671.6,
            670.9,
            671.45
        ],
        [
            1710934800000,
            671.25,
            671.95,
            671.25,
            671.4
        ],
        [
            1710934860000,
            671.45,
            673.5,
            671.45,
            673.5
        ],
        [
            1710934920000,
            674.45,
            675,
            674.25,
            674.4
        ],
        [
            1710934980000,
            674.1,
            674.1,
            673.6,
            673.7
        ],
        [
            1710935040000,
            673.4,
            673.65,
            673,
            673.25
        ],
        [
            1710935100000,
            673.15,
            673.2,
            672.15,
            672.15
        ],
        [
            1710935160000,
            672.2,
            673.1,
            672.2,
            672.25
        ],
        [
            1710935220000,
            672.45,
            672.8,
            672.2,
            672.8
        ],
        [
            1710935280000,
            672.4,
            672.5,
            671.55,
            671.6
        ],
        [
            1710935340000,
            671.5,
            671.95,
            671.3,
            671.4
        ],
        [
            1710935400000,
            671.3,
            671.95,
            670.9,
            671.95
        ],
        [
            1710935460000,
            671.75,
            672.3,
            671.75,
            672.3
        ],
        [
            1710935520000,
            672.3,
            672.8,
            672.3,
            672.8
        ],
        [
            1710935580000,
            672.8,
            672.95,
            672.5,
            672.75
        ],
        [
            1710935640000,
            672.75,
            673.1,
            672.55,
            672.6
        ],
        [
            1710935700000,
            672.6,
            674.35,
            672.6,
            673.65
        ],
        [
            1710935760000,
            673.55,
            673.55,
            673,
            673.05
        ],
        [
            1710935820000,
            673.1,
            673.8,
            673,
            673.5
        ],
        [
            1710935880000,
            673.5,
            673.95,
            673.3,
            673.3
        ],
        [
            1710935940000,
            673.15,
            673.5,
            673.15,
            673.2
        ],
        [
            1710936000000,
            673.2,
            673.45,
            673,
            673.15
        ],
        [
            1710936060000,
            673.15,
            673.65,
            673.15,
            673.25
        ],
        [
            1710936120000,
            673.25,
            673.6,
            673.2,
            673.2
        ],
        [
            1710936180000,
            673.6,
            673.6,
            673,
            673.45
        ],
        [
            1710936240000,
            673.15,
            673.6,
            673,
            673
        ],
        [
            1710936300000,
            673,
            673.4,
            673,
            673.1
        ],
        [
            1710936360000,
            673.5,
            673.5,
            673,
            673.15
        ],
        [
            1710936420000,
            673.45,
            673.45,
            673,
            673.25
        ],
        [
            1710936480000,
            673.05,
            673.45,
            673,
            673.15
        ],
        [
            1710936540000,
            673.25,
            673.4,
            673,
            673.4
        ],
        [
            1710936600000,
            673,
            673.45,
            673,
            673
        ],
        [
            1710936660000,
            673,
            673.45,
            673,
            673.45
        ],
        [
            1710936720000,
            673.05,
            673.45,
            673.05,
            673.1
        ],
        [
            1710936780000,
            673.1,
            673.4,
            673,
            673.4
        ],
        [
            1710936840000,
            673,
            673.45,
            673,
            673
        ],
        [
            1710936900000,
            673,
            673.45,
            672.8,
            673
        ],
        [
            1710936960000,
            673,
            673.65,
            673,
            673
        ],
        [
            1710937020000,
            673.65,
            676.35,
            673.65,
            676.35
        ],
        [
            1710937080000,
            676.45,
            676.65,
            675.9,
            676.25
        ],
        [
            1710937140000,
            676.25,
            676.5,
            675.3,
            675.8
        ],
        [
            1710937200000,
            675.4,
            675.75,
            674.2,
            674.55
        ],
        [
            1710937260000,
            674.85,
            674.85,
            674.1,
            674.5
        ],
        [
            1710937320000,
            674.85,
            675.2,
            674.4,
            675.2
        ],
        [
            1710937380000,
            675,
            675.55,
            675,
            675.25
        ],
        [
            1710937440000,
            675.3,
            675.3,
            674.5,
            674.85
        ],
        [
            1710937500000,
            675.15,
            675.45,
            674.75,
            674.75
        ],
        [
            1710937560000,
            675,
            675.75,
            674.95,
            675.7
        ],
        [
            1710937620000,
            675.25,
            675.65,
            675.05,
            675.05
        ],
        [
            1710937680000,
            675.2,
            675.9,
            675.2,
            675.5
        ],
        [
            1710937740000,
            675.5,
            675.7,
            675.5,
            675.5
        ],
        [
            1710937800000,
            675.5,
            675.55,
            675.3,
            675.45
        ],
        [
            1710937860000,
            675.4,
            676.05,
            675.4,
            676.05
        ],
        [
            1710937920000,
            675.65,
            676.3,
            675.65,
            676.2
        ],
        [
            1710937980000,
            676,
            676.3,
            676,
            676.3
        ],
        [
            1710938040000,
            676,
            676,
            675.3,
            675.75
        ],
        [
            1710938100000,
            675.9,
            675.9,
            675.6,
            675.65
        ],
        [
            1710938160000,
            675.65,
            675.9,
            675.5,
            675.85
        ],
        [
            1710938220000,
            675.5,
            675.9,
            675.4,
            675.4
        ],
        [
            1710938280000,
            675.5,
            675.9,
            675.5,
            675.65
        ],
        [
            1710938340000,
            675.65,
            675.9,
            675.5,
            675.5
        ],
        [
            1710938400000,
            675.5,
            675.9,
            675.5,
            675.6
        ],
        [
            1710938460000,
            675.95,
            676,
            675.4,
            675.6
        ],
        [
            1710938520000,
            675.6,
            675.75,
            675.5,
            675.55
        ],
        [
            1710938580000,
            675.6,
            676,
            675.5,
            675.9
        ],
        [
            1710938640000,
            675.5,
            675.95,
            675.5,
            675.8
        ],
        [
            1710938700000,
            675.8,
            676.15,
            675.15,
            675.95
        ],
        [
            1710938760000,
            675.5,
            675.95,
            675.35,
            675.6
        ],
        [
            1710938820000,
            675.6,
            676.1,
            675.4,
            675.65
        ],
        [
            1710938880000,
            676.1,
            676.15,
            675.6,
            676.1
        ],
        [
            1710938940000,
            675.65,
            676.15,
            675.6,
            675.6
        ],
        [
            1710939000000,
            675.6,
            676.35,
            675.6,
            676.05
        ],
        [
            1710939060000,
            676.45,
            676.45,
            675.85,
            676.3
        ],
        [
            1710939120000,
            675.8,
            676.15,
            675.65,
            675.7
        ],
        [
            1710939180000,
            676.15,
            676.35,
            675.75,
            676
        ],
        [
            1710939240000,
            676.3,
            676.35,
            676,
            676.05
        ],
        [
            1710939300000,
            676.05,
            676.45,
            676,
            676
        ],
        [
            1710939360000,
            676.05,
            676.5,
            676,
            676.05
        ],
        [
            1710939420000,
            676.05,
            676.25,
            676,
            676
        ],
        [
            1710939480000,
            675.2,
            675.7,
            675.2,
            675.2
        ],
        [
            1710939540000,
            675.1,
            675.6,
            675.1,
            675.15
        ],
        [
            1710939600000,
            675.15,
            675.4,
            675.05,
            675.05
        ],
        [
            1710939660000,
            675.05,
            675.5,
            674.1,
            675.5
        ],
        [
            1710939720000,
            675.1,
            675.75,
            675.1,
            675.55
        ],
        [
            1710939780000,
            675,
            675.65,
            674.8,
            675.05
        ],
        [
            1710939840000,
            675.05,
            675.55,
            674.95,
            674.95
        ],
        [
            1710939900000,
            674.95,
            675.5,
            674.9,
            675.2
        ],
        [
            1710939960000,
            675.25,
            675.65,
            675.25,
            675.4
        ],
        [
            1710940020000,
            675.4,
            675.4,
            675.2,
            675.2
        ],
        [
            1710940080000,
            675.2,
            675.65,
            675.15,
            675.2
        ],
        [
            1710940140000,
            675.2,
            675.65,
            675.15,
            675.2
        ],
        [
            1710940200000,
            675.05,
            675.6,
            674.95,
            675.6
        ],
        [
            1710940260000,
            675,
            675.5,
            675,
            675.1
        ],
        [
            1710940320000,
            675.1,
            675.95,
            675,
            675.95
        ],
        [
            1710940380000,
            675.3,
            675.85,
            675.2,
            675.3
        ],
        [
            1710940440000,
            675.35,
            675.9,
            675.3,
            675.35
        ],
        [
            1710940500000,
            675.8,
            675.85,
            675.1,
            675.35
        ],
        [
            1710940560000,
            675.5,
            675.7,
            675,
            675.15
        ],
        [
            1710940620000,
            675.15,
            675.9,
            674.85,
            675.65
        ],
        [
            1710940680000,
            676.25,
            676.9,
            676.25,
            676.35
        ],
        [
            1710940740000,
            676.35,
            676.35,
            675.8,
            675.8
        ],
        [
            1710940800000,
            676,
            676.1,
            675.55,
            675.65
        ],
        [
            1710940860000,
            675.65,
            676.15,
            675.65,
            675.8
        ],
        [
            1710940920000,
            675.8,
            676.2,
            675.75,
            675.75
        ],
        [
            1710940980000,
            676.2,
            676.2,
            675.75,
            675.75
        ],
        [
            1710941040000,
            675.75,
            676.2,
            675.75,
            675.85
        ],
        [
            1710941100000,
            676.25,
            676.45,
            675.8,
            676.15
        ],
        [
            1710941160000,
            676.5,
            677.4,
            676.25,
            677.25
        ],
        [
            1710941220000,
            677.45,
            677.95,
            676.9,
            676.9
        ],
        [
            1710941280000,
            676.9,
            677.15,
            676.1,
            676.55
        ],
        [
            1710941340000,
            676.3,
            676.35,
            676.1,
            676.1
        ],
        [
            1710941400000,
            676.1,
            676.4,
            675.6,
            675.6
        ],
        [
            1710941460000,
            675.45,
            675.55,
            675,
            675.25
        ],
        [
            1710941520000,
            675.25,
            675.7,
            675.2,
            675.35
        ],
        [
            1710941580000,
            675.3,
            675.45,
            674.9,
            675.1
        ],
        [
            1710941640000,
            675.2,
            675.4,
            675.05,
            675.05
        ],
        [
            1710941700000,
            675.05,
            675.35,
            674.85,
            675.35
        ],
        [
            1710941760000,
            675.35,
            675.55,
            675.2,
            675.3
        ],
        [
            1710941820000,
            675.3,
            675.7,
            675.25,
            675.5
        ],
        [
            1710941880000,
            675.5,
            675.55,
            675.3,
            675.5
        ],
        [
            1710941940000,
            675.45,
            675.5,
            675.1,
            675.4
        ],
        [
            1710942000000,
            675.4,
            675.9,
            675.35,
            675.6
        ],
        [
            1710942060000,
            675.65,
            676.4,
            675.6,
            676.05
        ],
        [
            1710942120000,
            676,
            676.4,
            675.85,
            676
        ],
        [
            1710942180000,
            676,
            676,
            675.9,
            675.9
        ],
        [
            1710942240000,
            675.9,
            676.05,
            675.45,
            675.65
        ],
        [
            1710942300000,
            675.9,
            675.9,
            675.55,
            675.8
        ],
        [
            1710942360000,
            675.8,
            676.05,
            675.55,
            675.65
        ],
        [
            1710942420000,
            675.6,
            676.25,
            675.6,
            675.85
        ],
        [
            1710942480000,
            675.7,
            676.15,
            675.65,
            675.7
        ],
        [
            1710942540000,
            675.7,
            676.05,
            675.5,
            675.5
        ],
        [
            1710942600000,
            675.5,
            675.5,
            675.05,
            675.35
        ],
        [
            1710942660000,
            675.25,
            675.75,
            675.25,
            675.75
        ],
        [
            1710942720000,
            675.65,
            676.1,
            675.6,
            676.1
        ],
        [
            1710942780000,
            675.65,
            675.9,
            675.5,
            675.65
        ],
        [
            1710942840000,
            675.95,
            676.15,
            675.6,
            675.65
        ],
        [
            1710942900000,
            675.7,
            676,
            675.7,
            675.8
        ],
        [
            1710942960000,
            675.8,
            675.8,
            675.75,
            675.8
        ],
        [
            1710943020000,
            675.85,
            676.25,
            675.75,
            675.75
        ],
        [
            1710943080000,
            675.75,
            675.75,
            675.5,
            675.55
        ],
        [
            1710943140000,
            675.6,
            675.8,
            675.35,
            675.35
        ],
        [
            1710943200000,
            675.8,
            675.8,
            675.45,
            675.6
        ],
        [
            1710943260000,
            675.6,
            675.65,
            675.55,
            675.6
        ],
        [
            1710943320000,
            675.35,
            675.45,
            675.35,
            675.4
        ],
        [
            1710943380000,
            675.4,
            675.75,
            675.3,
            675.3
        ],
        [
            1710943440000,
            675.2,
            675.4,
            675.2,
            675.4
        ],
        [
            1710943500000,
            675.4,
            675.75,
            675.2,
            675.3
        ],
        [
            1710943560000,
            675.3,
            675.75,
            675.3,
            675.4
        ],
        [
            1710943620000,
            675.4,
            675.9,
            675.4,
            675.5
        ],
        [
            1710943680000,
            675.5,
            675.95,
            675.5,
            675.65
        ],
        [
            1710943740000,
            675.6,
            676.5,
            675.55,
            676.25
        ],
        [
            1710943800000,
            676.25,
            676.85,
            676.2,
            676.35
        ],
        [
            1710943860000,
            676.35,
            676.85,
            675.1,
            675.1
        ],
        [
            1710943920000,
            675.25,
            676.2,
            675.25,
            676.2
        ],
        [
            1710943980000,
            675.95,
            676.35,
            675.95,
            676.1
        ],
        [
            1710944040000,
            676.55,
            676.85,
            676.1,
            676.5
        ],
        [
            1710944100000,
            676.5,
            676.8,
            676.25,
            676.8
        ],
        [
            1710944160000,
            676.4,
            677,
            676.4,
            676.55
        ],
        [
            1710944220000,
            676.5,
            676.9,
            675.95,
            676.65
        ],
        [
            1710944280000,
            676.3,
            676.8,
            676.25,
            676.3
        ],
        [
            1710944340000,
            676.3,
            676.8,
            676.3,
            676.75
        ],
        [
            1710944400000,
            676.35,
            676.35,
            675.8,
            675.8
        ],
        [
            1710944460000,
            676.05,
            676.1,
            675.95,
            675.95
        ],
        [
            1710944520000,
            675.95,
            676.5,
            675.95,
            676.1
        ],
        [
            1710944580000,
            676.05,
            676.2,
            675.7,
            675.85
        ],
        [
            1710944640000,
            675.9,
            676.35,
            675.9,
            676.1
        ],
        [
            1710944700000,
            676.45,
            676.45,
            675.8,
            675.8
        ],
        [
            1710944760000,
            675.85,
            676.25,
            675.7,
            675.7
        ],
        [
            1710944820000,
            675.5,
            675.5,
            675,
            675.4
        ],
        [
            1710944880000,
            675.4,
            675.7,
            675.05,
            675.05
        ],
        [
            1710944940000,
            675.05,
            675.1,
            674.75,
            674.75
        ],
        [
            1710945000000,
            674.8,
            675.35,
            674.75,
            675.1
        ],
        [
            1710945060000,
            675.2,
            675.6,
            674.8,
            675.3
        ],
        [
            1710945120000,
            675.3,
            675.6,
            674.65,
            674.65
        ],
        [
            1710945180000,
            675,
            675.05,
            674.3,
            674.35
        ],
        [
            1710945240000,
            674.35,
            674.35,
            673.75,
            673.8
        ],
        [
            1710945300000,
            673.8,
            675.7,
            673.8,
            675.6
        ],
        [
            1710945360000,
            675.6,
            675.7,
            674.5,
            674.5
        ],
        [
            1710945420000,
            674.65,
            675.85,
            674.2,
            674.55
        ],
        [
            1710945480000,
            673.85,
            674.7,
            673.8,
            674.7
        ],
        [
            1710945540000,
            674.75,
            675.1,
            673.65,
            673.75
        ],
        [
            1710945600000,
            674,
            674.85,
            673.85,
            674.85
        ],
        [
            1710945660000,
            675.45,
            675.45,
            675.05,
            675.15
        ],
        [
            1710945720000,
            675.05,
            675.4,
            674.95,
            674.95
        ],
        [
            1710945780000,
            674.85,
            675.75,
            674.85,
            675.75
        ],
        [
            1710945840000,
            676.1,
            676.1,
            675.65,
            675.85
        ],
        [
            1710945900000,
            676.4,
            676.4,
            675.75,
            675.75
        ],
        [
            1710945960000,
            675.75,
            675.9,
            675.6,
            675.6
        ],
        [
            1710946020000,
            675.95,
            675.95,
            675.45,
            675.45
        ],
        [
            1710946080000,
            675.5,
            675.75,
            675.5,
            675.6
        ],
        [
            1710946140000,
            675.7,
            675.95,
            675.6,
            675.6
        ],
        [
            1710946200000,
            675.6,
            675.9,
            675.2,
            675.4
        ],
        [
            1710946260000,
            675.4,
            675.95,
            675.4,
            675.55
        ],
        [
            1710946320000,
            675.8,
            675.9,
            675.25,
            675.25
        ],
        [
            1710946380000,
            675.3,
            675.8,
            675.3,
            675.3
        ],
        [
            1710946440000,
            675.1,
            676.45,
            675.05,
            676
        ],
        [
            1710946500000,
            676,
            676,
            674.6,
            674.6
        ],
        [
            1710946560000,
            674.65,
            675,
            674,
            674.95
        ],
        [
            1710946620000,
            675.4,
            675.7,
            675.05,
            675.25
        ],
        [
            1710946680000,
            675.25,
            675.35,
            674.9,
            674.9
        ],
        [
            1710946740000,
            674.95,
            675.55,
            674.85,
            675
        ],
        [
            1710946800000,
            675.3,
            675.35,
            674.75,
            674.75
        ],
        [
            1710946860000,
            674.4,
            674.7,
            674.2,
            674.2
        ],
        [
            1710946920000,
            674.2,
            674.9,
            674.2,
            674.7
        ],
        [
            1710946980000,
            674.4,
            674.5,
            674.35,
            674.45
        ],
        [
            1710947040000,
            674.5,
            675.7,
            674.5,
            675.3
        ],
        [
            1710947100000,
            675.25,
            676.15,
            675.25,
            676.15
        ],
        [
            1710947160000,
            676.15,
            676.15,
            675.75,
            676.05
        ],
        [
            1710947220000,
            676.05,
            676.2,
            675.7,
            675.85
        ],
        [
            1710947280000,
            675.7,
            676,
            675.55,
            675.95
        ],
        [
            1710947340000,
            675.75,
            676.25,
            675.75,
            676
        ],
        [
            1710947400000,
            676.85,
            676.95,
            676.7,
            676.7
        ],
        [
            1710947460000,
            676.5,
            676.5,
            676.15,
            676.25
        ],
        [
            1710947520000,
            676.25,
            676.4,
            675.55,
            675.6
        ],
        [
            1710947580000,
            675.8,
            676,
            675.65,
            675.8
        ],
        [
            1710947640000,
            675.8,
            676,
            675.8,
            675.8
        ],
        [
            1710947700000,
            675.8,
            676,
            675.8,
            675.8
        ],
        [
            1710947760000,
            675.8,
            675.8,
            675,
            675.05
        ],
        [
            1710947820000,
            675.2,
            676.05,
            675.2,
            675.75
        ],
        [
            1710947880000,
            675.8,
            676.1,
            675.8,
            675.85
        ],
        [
            1710947940000,
            676.05,
            676.05,
            675.8,
            675.85
        ],
        [
            1710948000000,
            676.3,
            676.4,
            675.9,
            676
        ],
        [
            1710948060000,
            675.95,
            675.95,
            675.65,
            675.65
        ],
        [
            1710948120000,
            675.8,
            675.9,
            675.7,
            675.8
        ],
        [
            1710948180000,
            675.7,
            675.8,
            675.7,
            675.8
        ],
        [
            1710948240000,
            676.1,
            676.2,
            675.85,
            675.95
        ],
        [
            1710948300000,
            676.05,
            676.3,
            676.05,
            676.3
        ],
        [
            1710948360000,
            676.3,
            677,
            676.3,
            676.65
        ],
        [
            1710948420000,
            676.7,
            678.6,
            676.7,
            678.6
        ],
        [
            1710948480000,
            678.65,
            678.95,
            678,
            678.4
        ],
        [
            1710948540000,
            678.35,
            678.4,
            677,
            677
        ],
        [
            1711012500000,
            679,
            687,
            678.7,
            683.8
        ],
        [
            1711012560000,
            684.05,
            684.2,
            682.95,
            682.95
        ],
        [
            1711012620000,
            683.75,
            683.95,
            681.85,
            682.05
        ],
        [
            1711012680000,
            682,
            682.15,
            680.95,
            682.15
        ],
        [
            1711012740000,
            683.3,
            683.65,
            681.3,
            681.3
        ],
        [
            1711012800000,
            680.8,
            680.9,
            678.3,
            678.3
        ],
        [
            1711012860000,
            678.35,
            679.35,
            677.35,
            679.35
        ],
        [
            1711012920000,
            678.8,
            681.7,
            678.75,
            680.9
        ],
        [
            1711012980000,
            680.4,
            681.55,
            680.3,
            681.3
        ],
        [
            1711013040000,
            681,
            681.3,
            680.5,
            680.75
        ],
        [
            1711013100000,
            681.35,
            681.6,
            680.55,
            681.45
        ],
        [
            1711013160000,
            681.7,
            683.45,
            681.7,
            683.4
        ],
        [
            1711013220000,
            683.4,
            683.5,
            683,
            683.5
        ],
        [
            1711013280000,
            683.75,
            685.4,
            683.6,
            685.15
        ],
        [
            1711013340000,
            685.4,
            685.4,
            684.65,
            684.65
        ],
        [
            1711013400000,
            684.65,
            684.85,
            684.1,
            684.5
        ],
        [
            1711013460000,
            684.45,
            685,
            684.05,
            685
        ],
        [
            1711013520000,
            684.8,
            685.15,
            684.65,
            685.15
        ],
        [
            1711013580000,
            685.15,
            685.15,
            684.1,
            684.2
        ],
        [
            1711013640000,
            684.3,
            684.7,
            683.6,
            683.6
        ],
        [
            1711013700000,
            683.6,
            683.95,
            683.6,
            683.9
        ],
        [
            1711013760000,
            683.85,
            685,
            683.75,
            684.75
        ],
        [
            1711013820000,
            684.25,
            686.4,
            684.2,
            685.7
        ],
        [
            1711013880000,
            685.55,
            686.15,
            685,
            685.35
        ],
        [
            1711013940000,
            685,
            685.95,
            685,
            685.2
        ],
        [
            1711014000000,
            685.75,
            685.85,
            685.5,
            685.65
        ],
        [
            1711014060000,
            685.8,
            685.8,
            685.35,
            685.35
        ],
        [
            1711014120000,
            685.65,
            685.65,
            685.1,
            685.1
        ],
        [
            1711014180000,
            685.45,
            685.45,
            684.6,
            685.25
        ],
        [
            1711014240000,
            685,
            687.75,
            685,
            686.35
        ],
        [
            1711014300000,
            686.5,
            686.8,
            685.6,
            685.6
        ],
        [
            1711014360000,
            685.6,
            686.95,
            685.55,
            686.3
        ],
        [
            1711014420000,
            686.85,
            687.05,
            686.4,
            686.4
        ],
        [
            1711014480000,
            686.45,
            687.95,
            686.45,
            687.05
        ],
        [
            1711014540000,
            687.2,
            687.65,
            686.8,
            687
        ],
        [
            1711014600000,
            687,
            687,
            685.95,
            686.3
        ],
        [
            1711014660000,
            686.3,
            686.65,
            686.15,
            686.6
        ],
        [
            1711014720000,
            686.4,
            687.25,
            686.4,
            686.85
        ],
        [
            1711014780000,
            686.85,
            688,
            686.85,
            687.95
        ],
        [
            1711014840000,
            687.9,
            688.7,
            687.3,
            687.9
        ],
        [
            1711014900000,
            687.8,
            687.95,
            687.5,
            687.5
        ],
        [
            1711014960000,
            687.7,
            687.95,
            687.55,
            687.95
        ],
        [
            1711015020000,
            687.95,
            689,
            687.95,
            688.6
        ],
        [
            1711015080000,
            688.7,
            689,
            688.65,
            688.95
        ],
        [
            1711015140000,
            689,
            689.3,
            688.9,
            688.9
        ],
        [
            1711015200000,
            688.8,
            689.3,
            688.8,
            689
        ],
        [
            1711015260000,
            689.3,
            689.3,
            688.8,
            689.3
        ],
        [
            1711015320000,
            689.45,
            690.4,
            689.4,
            690.35
        ],
        [
            1711015380000,
            690.3,
            691.6,
            690.1,
            690.7
        ],
        [
            1711015440000,
            690.65,
            690.9,
            690.1,
            690.75
        ],
        [
            1711015500000,
            691,
            691.4,
            690,
            690
        ],
        [
            1711015560000,
            690.3,
            690.6,
            690.1,
            690.55
        ],
        [
            1711015620000,
            690.7,
            691.45,
            690.55,
            691.25
        ],
        [
            1711015680000,
            691.05,
            691.6,
            690.9,
            691.25
        ],
        [
            1711015740000,
            690.9,
            690.9,
            690.15,
            690.8
        ],
        [
            1711015800000,
            690.75,
            691.65,
            690.75,
            691.65
        ],
        [
            1711015860000,
            691.6,
            691.85,
            691.35,
            691.85
        ],
        [
            1711015920000,
            691.9,
            691.95,
            691.05,
            691.05
        ],
        [
            1711015980000,
            690.45,
            691.6,
            690.15,
            691.35
        ],
        [
            1711016040000,
            691.3,
            691.4,
            690.85,
            691.05
        ],
        [
            1711016100000,
            690.95,
            691.4,
            690.55,
            691.35
        ],
        [
            1711016160000,
            691.25,
            691.25,
            689.5,
            690.25
        ],
        [
            1711016220000,
            689.8,
            690.3,
            689,
            689.4
        ],
        [
            1711016280000,
            688.9,
            689.95,
            688.35,
            689.85
        ],
        [
            1711016340000,
            689.75,
            690.5,
            689.25,
            690.5
        ],
        [
            1711016400000,
            690.1,
            690.65,
            689.8,
            690.05
        ],
        [
            1711016460000,
            690.1,
            690.5,
            689.7,
            690.5
        ],
        [
            1711016520000,
            690.45,
            690.45,
            690.35,
            690.35
        ],
        [
            1711016580000,
            690.35,
            691,
            690.25,
            691
        ],
        [
            1711016640000,
            691,
            691.15,
            690.8,
            690.8
        ],
        [
            1711016700000,
            690.8,
            691.05,
            689.95,
            690.95
        ],
        [
            1711016760000,
            690.95,
            690.95,
            690.5,
            690.9
        ],
        [
            1711016820000,
            690.7,
            692.85,
            690.2,
            692.5
        ],
        [
            1711016880000,
            692.95,
            692.95,
            691.05,
            691.75
        ],
        [
            1711016940000,
            691.7,
            692.3,
            691.2,
            692.2
        ],
        [
            1711017000000,
            691.9,
            692.4,
            690.35,
            691.85
        ],
        [
            1711017060000,
            691.55,
            692.2,
            691.55,
            691.9
        ],
        [
            1711017120000,
            692.25,
            692.65,
            692,
            692.5
        ],
        [
            1711017180000,
            692.5,
            692.5,
            691.8,
            692.05
        ],
        [
            1711017240000,
            691.8,
            692,
            691.1,
            691.2
        ],
        [
            1711017300000,
            690.85,
            691.8,
            690.85,
            691.65
        ],
        [
            1711017360000,
            691.15,
            691.65,
            690.05,
            690.7
        ],
        [
            1711017420000,
            690.7,
            691.55,
            690.35,
            690.35
        ],
        [
            1711017480000,
            690.6,
            690.6,
            690.1,
            690.4
        ],
        [
            1711017540000,
            690.15,
            691.1,
            690.15,
            691
        ],
        [
            1711017600000,
            690.8,
            690.95,
            689.75,
            690.85
        ],
        [
            1711017660000,
            690.4,
            691.25,
            690,
            690.1
        ],
        [
            1711017720000,
            689.65,
            691.2,
            689.35,
            690.45
        ],
        [
            1711017780000,
            690.45,
            691.05,
            690.35,
            691.05
        ],
        [
            1711017840000,
            691.05,
            691.5,
            690.5,
            690.6
        ],
        [
            1711017900000,
            690.6,
            691.3,
            690.45,
            691.15
        ],
        [
            1711017960000,
            691,
            691,
            690.5,
            691
        ],
        [
            1711018020000,
            690.85,
            691.8,
            690.75,
            691.8
        ],
        [
            1711018080000,
            691.4,
            691.85,
            690.55,
            690.85
        ],
        [
            1711018140000,
            690.85,
            691.55,
            690.85,
            691.2
        ],
        [
            1711018200000,
            691.2,
            691.35,
            691.2,
            691.25
        ],
        [
            1711018260000,
            691.25,
            691.35,
            691.05,
            691.1
        ],
        [
            1711018320000,
            691.1,
            691.4,
            690.65,
            691.25
        ],
        [
            1711018380000,
            691.25,
            691.45,
            690.75,
            690.75
        ],
        [
            1711018440000,
            690.95,
            691.45,
            690.8,
            690.8
        ],
        [
            1711018500000,
            690.8,
            691.25,
            690.8,
            691.25
        ],
        [
            1711018560000,
            691.25,
            691.25,
            690.5,
            691.2
        ],
        [
            1711018620000,
            691.2,
            691.25,
            690.9,
            690.9
        ],
        [
            1711018680000,
            690.9,
            691.45,
            690.75,
            690.85
        ],
        [
            1711018740000,
            690.95,
            691.45,
            690.95,
            691.15
        ],
        [
            1711018800000,
            691.3,
            691.35,
            691.2,
            691.3
        ],
        [
            1711018860000,
            691.6,
            692.55,
            691.6,
            692.5
        ],
        [
            1711018920000,
            692.4,
            692.65,
            692.15,
            692.45
        ],
        [
            1711018980000,
            692.35,
            692.55,
            691.5,
            691.95
        ],
        [
            1711019040000,
            692.05,
            692.4,
            692.05,
            692.35
        ],
        [
            1711019100000,
            692.35,
            692.65,
            691.95,
            692.6
        ],
        [
            1711019160000,
            692.6,
            692.65,
            691.5,
            691.5
        ],
        [
            1711019220000,
            691.5,
            691.5,
            691.45,
            691.45
        ],
        [
            1711019280000,
            691.5,
            691.85,
            691.45,
            691.45
        ],
        [
            1711019340000,
            691.5,
            691.7,
            691.45,
            691.45
        ],
        [
            1711019400000,
            691.7,
            691.7,
            691.4,
            691.4
        ],
        [
            1711019460000,
            690.95,
            691.45,
            690.95,
            691.45
        ],
        [
            1711019520000,
            691.35,
            691.35,
            690.9,
            691.05
        ],
        [
            1711019580000,
            691.3,
            691.3,
            690.95,
            691
        ],
        [
            1711019640000,
            691,
            691,
            690.9,
            690.9
        ],
        [
            1711019700000,
            690.85,
            691,
            690.85,
            691
        ],
        [
            1711019760000,
            690.9,
            690.95,
            690.5,
            690.5
        ],
        [
            1711019820000,
            690.65,
            690.95,
            690.5,
            690.95
        ],
        [
            1711019880000,
            690.95,
            690.95,
            690.5,
            690.8
        ],
        [
            1711019940000,
            690.95,
            690.95,
            690,
            690.25
        ],
        [
            1711020000000,
            690.25,
            691.05,
            690,
            690.65
        ],
        [
            1711020060000,
            690.85,
            691.35,
            690.75,
            691.05
        ],
        [
            1711020120000,
            691.3,
            691.45,
            689.95,
            691
        ],
        [
            1711020180000,
            691,
            691.2,
            690,
            690.25
        ],
        [
            1711020240000,
            690.65,
            690.7,
            689.95,
            690
        ],
        [
            1711020300000,
            690,
            690.4,
            690,
            690.15
        ],
        [
            1711020360000,
            690.15,
            690.7,
            690.15,
            690.3
        ],
        [
            1711020420000,
            690.55,
            690.75,
            690,
            690
        ],
        [
            1711020480000,
            690,
            690,
            689.85,
            689.85
        ],
        [
            1711020540000,
            689.9,
            690.15,
            689.9,
            689.95
        ],
        [
            1711020600000,
            690,
            690,
            689.95,
            690
        ],
        [
            1711020660000,
            690,
            690,
            689.9,
            689.95
        ],
        [
            1711020720000,
            689.95,
            690,
            689.95,
            690
        ],
        [
            1711020780000,
            690,
            690,
            688.1,
            688.7
        ],
        [
            1711020840000,
            688.7,
            688.75,
            687.3,
            687.3
        ],
        [
            1711020900000,
            687.55,
            687.8,
            687.5,
            687.55
        ],
        [
            1711020960000,
            687.4,
            687.8,
            686.6,
            686.85
        ],
        [
            1711021020000,
            686.55,
            687.2,
            686.55,
            687
        ],
        [
            1711021080000,
            687,
            687.2,
            686.15,
            686.15
        ],
        [
            1711021140000,
            685.95,
            687.45,
            685.95,
            686.1
        ],
        [
            1711021200000,
            685.95,
            687.4,
            685.9,
            686.95
        ],
        [
            1711021260000,
            687.25,
            687.25,
            686.4,
            686.55
        ],
        [
            1711021320000,
            686.55,
            687.35,
            686.4,
            687.35
        ],
        [
            1711021380000,
            686.9,
            687.45,
            686.45,
            687.25
        ],
        [
            1711021440000,
            687.25,
            687.55,
            686.95,
            687
        ],
        [
            1711021500000,
            687.4,
            687.6,
            687,
            687.1
        ],
        [
            1711021560000,
            687.6,
            687.65,
            686.7,
            687.05
        ],
        [
            1711021620000,
            687.1,
            687.55,
            687.05,
            687.3
        ],
        [
            1711021680000,
            687.3,
            687.4,
            686.85,
            686.9
        ],
        [
            1711021740000,
            687.1,
            687.4,
            687,
            687
        ],
        [
            1711021800000,
            687.45,
            688.65,
            687,
            688.4
        ],
        [
            1711021860000,
            688.75,
            688.85,
            687.5,
            688.3
        ],
        [
            1711021920000,
            688.65,
            688.65,
            687.65,
            687.85
        ],
        [
            1711021980000,
            688.25,
            688.4,
            687.8,
            687.8
        ],
        [
            1711022040000,
            688.25,
            688.35,
            687.5,
            687.75
        ],
        [
            1711022100000,
            687.9,
            688.35,
            687.6,
            687.95
        ],
        [
            1711022160000,
            688.3,
            688.4,
            687.95,
            687.95
        ],
        [
            1711022220000,
            688.05,
            688.15,
            687.75,
            687.8
        ],
        [
            1711022280000,
            687.8,
            688,
            687.3,
            687.5
        ],
        [
            1711022340000,
            687.5,
            687.5,
            687.45,
            687.45
        ],
        [
            1711022400000,
            687.5,
            687.5,
            687.2,
            687.5
        ],
        [
            1711022460000,
            687.5,
            687.5,
            687,
            687.5
        ],
        [
            1711022520000,
            687.15,
            687.5,
            686.9,
            686.9
        ],
        [
            1711022580000,
            686.8,
            687,
            686.5,
            687
        ],
        [
            1711022640000,
            687.2,
            687.2,
            686.5,
            687
        ],
        [
            1711022700000,
            686.5,
            686.95,
            686.5,
            686.95
        ],
        [
            1711022760000,
            686.75,
            686.85,
            686.2,
            686.2
        ],
        [
            1711022820000,
            686.7,
            686.75,
            686.55,
            686.7
        ],
        [
            1711022880000,
            686.55,
            686.65,
            685.9,
            685.9
        ],
        [
            1711022940000,
            686.25,
            686.25,
            685.5,
            686.05
        ],
        [
            1711023000000,
            685.3,
            686.1,
            685.3,
            686.1
        ],
        [
            1711023060000,
            686.1,
            686.1,
            685.85,
            685.85
        ],
        [
            1711023120000,
            685.85,
            686.3,
            685.6,
            686
        ],
        [
            1711023180000,
            686.3,
            686.3,
            685.3,
            685.65
        ],
        [
            1711023240000,
            685.75,
            686,
            685.2,
            685.2
        ],
        [
            1711023300000,
            685.15,
            685.8,
            684,
            685
        ],
        [
            1711023360000,
            685,
            685.6,
            684.45,
            684.65
        ],
        [
            1711023420000,
            684.95,
            685,
            684.8,
            684.9
        ],
        [
            1711023480000,
            684.8,
            685,
            684.8,
            685
        ],
        [
            1711023540000,
            685,
            685.4,
            684.95,
            685
        ],
        [
            1711023600000,
            684.95,
            685,
            684.7,
            684.95
        ],
        [
            1711023660000,
            685,
            685.2,
            684.95,
            685
        ],
        [
            1711023720000,
            685,
            685,
            685,
            685
        ],
        [
            1711023780000,
            685,
            685.25,
            684.95,
            685
        ],
        [
            1711023840000,
            685.25,
            685.25,
            685,
            685
        ],
        [
            1711023900000,
            685,
            685.25,
            684.95,
            685
        ],
        [
            1711023960000,
            684.8,
            685,
            684.8,
            685
        ],
        [
            1711024020000,
            685,
            685.2,
            684.55,
            685.2
        ],
        [
            1711024080000,
            684.8,
            685,
            684.8,
            685
        ],
        [
            1711024140000,
            685,
            685,
            685,
            685
        ],
        [
            1711024200000,
            685,
            685.15,
            685,
            685
        ],
        [
            1711024260000,
            685,
            685.2,
            684.95,
            685.2
        ],
        [
            1711024320000,
            684.95,
            685.55,
            684.95,
            685.55
        ],
        [
            1711024380000,
            685.55,
            686.6,
            685.55,
            686.25
        ],
        [
            1711024440000,
            687.05,
            687.2,
            686.65,
            686.75
        ],
        [
            1711024500000,
            686.95,
            687.2,
            686.6,
            686.6
        ],
        [
            1711024560000,
            686.65,
            687.2,
            686.6,
            686.7
        ],
        [
            1711024620000,
            687.05,
            687.05,
            685.65,
            685.9
        ],
        [
            1711024680000,
            686.3,
            687.3,
            685.9,
            686.65
        ],
        [
            1711024740000,
            686.65,
            687.35,
            686.65,
            687
        ],
        [
            1711024800000,
            687,
            687,
            686.8,
            687
        ],
        [
            1711024860000,
            687,
            687,
            686.95,
            687
        ],
        [
            1711024920000,
            687,
            687,
            686.8,
            687
        ],
        [
            1711024980000,
            687,
            687,
            686.95,
            687
        ],
        [
            1711025040000,
            687,
            687,
            686.8,
            686.9
        ],
        [
            1711025100000,
            687,
            687.35,
            686.9,
            686.95
        ],
        [
            1711025160000,
            687.3,
            687.4,
            686.95,
            687.4
        ],
        [
            1711025220000,
            687.45,
            687.45,
            686.55,
            686.55
        ],
        [
            1711025280000,
            686.85,
            687.05,
            686.55,
            687
        ],
        [
            1711025340000,
            687,
            687.35,
            686.6,
            686.6
        ],
        [
            1711025400000,
            686.4,
            687.1,
            686.4,
            686.75
        ],
        [
            1711025460000,
            686.75,
            686.95,
            686.45,
            686.65
        ],
        [
            1711025520000,
            686.85,
            687.25,
            686.7,
            686.8
        ],
        [
            1711025580000,
            686.8,
            687.5,
            686.8,
            687.45
        ],
        [
            1711025640000,
            687.1,
            687.6,
            687,
            687
        ],
        [
            1711025700000,
            686.9,
            687,
            686.8,
            687
        ],
        [
            1711025760000,
            686.8,
            687,
            686.8,
            687
        ],
        [
            1711025820000,
            686.95,
            687,
            686.9,
            686.95
        ],
        [
            1711025880000,
            686.95,
            687,
            686.8,
            686.85
        ],
        [
            1711025940000,
            686.85,
            686.85,
            686.55,
            686.85
        ],
        [
            1711026000000,
            686.8,
            686.85,
            686.8,
            686.85
        ],
        [
            1711026060000,
            686.8,
            687.5,
            686.8,
            687.05
        ],
        [
            1711026120000,
            687.25,
            687.6,
            687.05,
            687.5
        ],
        [
            1711026180000,
            687.35,
            688.05,
            687.15,
            687.6
        ],
        [
            1711026240000,
            687.6,
            688.05,
            687.5,
            687.8
        ],
        [
            1711026300000,
            687.7,
            688.2,
            687.25,
            687.95
        ],
        [
            1711026360000,
            687.45,
            688,
            687.05,
            687.05
        ],
        [
            1711026420000,
            687.05,
            687.2,
            686.1,
            686.45
        ],
        [
            1711026480000,
            686.35,
            686.75,
            686.35,
            686.55
        ],
        [
            1711026540000,
            686.6,
            687,
            686.6,
            686.95
        ],
        [
            1711026600000,
            686.95,
            687,
            685.85,
            686.25
        ],
        [
            1711026660000,
            686.6,
            686.85,
            686.1,
            686.3
        ],
        [
            1711026720000,
            686.35,
            687.05,
            686.35,
            686.8
        ],
        [
            1711026780000,
            686.8,
            687.4,
            686.8,
            686.95
        ],
        [
            1711026840000,
            686.95,
            687.45,
            686.95,
            687.15
        ],
        [
            1711026900000,
            687.15,
            687.25,
            686.8,
            687.05
        ],
        [
            1711026960000,
            686.75,
            687.4,
            686.75,
            686.75
        ],
        [
            1711027020000,
            686.95,
            687.2,
            686.75,
            686.75
        ],
        [
            1711027080000,
            686.75,
            687.2,
            686.75,
            686.95
        ],
        [
            1711027140000,
            687.2,
            687.45,
            686.8,
            687
        ],
        [
            1711027200000,
            687,
            687.45,
            686.95,
            687
        ],
        [
            1711027260000,
            687,
            687.45,
            687,
            687.15
        ],
        [
            1711027320000,
            687.15,
            687.45,
            687,
            687
        ],
        [
            1711027380000,
            686.95,
            687.45,
            686.95,
            686.95
        ],
        [
            1711027440000,
            686.95,
            687.45,
            686.95,
            687
        ],
        [
            1711027500000,
            686.8,
            686.95,
            686.35,
            686.7
        ],
        [
            1711027560000,
            686.6,
            686.85,
            686.6,
            686.7
        ],
        [
            1711027620000,
            686.4,
            686.7,
            686.4,
            686.7
        ],
        [
            1711027680000,
            686.7,
            686.7,
            685.9,
            686
        ],
        [
            1711027740000,
            685.95,
            686.15,
            685.95,
            686
        ],
        [
            1711027800000,
            685.95,
            686,
            685.75,
            685.8
        ],
        [
            1711027860000,
            685.75,
            685.8,
            685.75,
            685.8
        ],
        [
            1711027920000,
            685.75,
            686.05,
            685.75,
            685.75
        ],
        [
            1711027980000,
            685.65,
            685.9,
            685.55,
            685.65
        ],
        [
            1711028040000,
            685.65,
            685.7,
            685.05,
            685.7
        ],
        [
            1711028100000,
            685.65,
            685.9,
            685.4,
            685.4
        ],
        [
            1711028160000,
            685.4,
            685.55,
            684.8,
            685
        ],
        [
            1711028220000,
            685,
            685,
            684.85,
            685
        ],
        [
            1711028280000,
            685.2,
            685.2,
            684.95,
            685
        ],
        [
            1711028340000,
            684.95,
            685.2,
            684.95,
            685.2
        ],
        [
            1711028400000,
            685.25,
            685.4,
            684.95,
            685.35
        ],
        [
            1711028460000,
            685.1,
            685.45,
            685.1,
            685.1
        ],
        [
            1711028520000,
            685.1,
            685.5,
            685,
            685.4
        ],
        [
            1711028580000,
            685.2,
            685.35,
            685.05,
            685.05
        ],
        [
            1711028640000,
            685.05,
            685.35,
            684.5,
            685
        ],
        [
            1711028700000,
            684.8,
            685.15,
            684.8,
            685.05
        ],
        [
            1711028760000,
            684.95,
            685,
            684.8,
            684.9
        ],
        [
            1711028820000,
            684.9,
            684.95,
            684.8,
            684.9
        ],
        [
            1711028880000,
            684.8,
            684.9,
            684.6,
            684.85
        ],
        [
            1711028940000,
            684.7,
            684.9,
            684.7,
            684.9
        ],
        [
            1711029000000,
            684.85,
            684.9,
            684.85,
            684.9
        ],
        [
            1711029060000,
            684.9,
            684.9,
            684.6,
            684.7
        ],
        [
            1711029120000,
            684.65,
            684.9,
            684.65,
            684.9
        ],
        [
            1711029180000,
            684.65,
            684.75,
            684.65,
            684.7
        ],
        [
            1711029240000,
            684.5,
            684.65,
            684.3,
            684.5
        ],
        [
            1711029300000,
            684.45,
            684.5,
            684.2,
            684.2
        ],
        [
            1711029360000,
            684.5,
            684.7,
            684.25,
            684.5
        ],
        [
            1711029420000,
            684.5,
            684.8,
            684.45,
            684.5
        ],
        [
            1711029480000,
            684.5,
            684.5,
            684.45,
            684.5
        ],
        [
            1711029540000,
            684.45,
            684.5,
            684.45,
            684.5
        ],
        [
            1711029600000,
            684.5,
            684.5,
            684.45,
            684.5
        ],
        [
            1711029660000,
            684.5,
            684.8,
            684.5,
            684.5
        ],
        [
            1711029720000,
            684.5,
            684.8,
            684.5,
            684.5
        ],
        [
            1711029780000,
            684.5,
            684.75,
            684.45,
            684.45
        ],
        [
            1711029840000,
            684.5,
            684.6,
            684.5,
            684.5
        ],
        [
            1711029900000,
            684.5,
            684.5,
            684.45,
            684.45
        ],
        [
            1711029960000,
            684.8,
            684.8,
            684.5,
            684.6
        ],
        [
            1711030020000,
            684.5,
            684.5,
            684.45,
            684.5
        ],
        [
            1711030080000,
            684.5,
            684.55,
            684.3,
            684.5
        ],
        [
            1711030140000,
            684.45,
            684.7,
            684.45,
            684.5
        ],
        [
            1711030200000,
            684.6,
            684.6,
            684.45,
            684.5
        ],
        [
            1711030260000,
            684.75,
            684.9,
            684.2,
            684.65
        ],
        [
            1711030320000,
            684.9,
            685.4,
            684.75,
            685.15
        ],
        [
            1711030380000,
            685.15,
            685.5,
            685,
            685.05
        ],
        [
            1711030440000,
            685.35,
            685.35,
            684.8,
            684.8
        ],
        [
            1711030500000,
            684.8,
            685.1,
            684.8,
            684.8
        ],
        [
            1711030560000,
            684.9,
            684.9,
            684.5,
            684.5
        ],
        [
            1711030620000,
            684.5,
            684.5,
            684.3,
            684.5
        ],
        [
            1711030680000,
            684.5,
            684.5,
            684.45,
            684.5
        ],
        [
            1711030740000,
            684.5,
            684.5,
            684.5,
            684.5
        ],
        [
            1711030800000,
            684.45,
            684.5,
            684.45,
            684.5
        ],
        [
            1711030860000,
            684.5,
            684.6,
            684.5,
            684.5
        ],
        [
            1711030920000,
            684.5,
            685,
            684.5,
            685
        ],
        [
            1711030980000,
            685,
            685.2,
            684.95,
            685.05
        ],
        [
            1711031040000,
            685,
            685,
            685,
            685
        ],
        [
            1711031100000,
            685.2,
            685.2,
            684.95,
            685
        ],
        [
            1711031160000,
            685,
            685.2,
            685,
            685
        ],
        [
            1711031220000,
            685,
            685.2,
            685,
            685
        ],
        [
            1711031280000,
            685,
            685,
            684.85,
            684.9
        ],
        [
            1711031340000,
            684.9,
            684.9,
            684.85,
            684.9
        ],
        [
            1711031400000,
            684.9,
            684.9,
            684.9,
            684.9
        ],
        [
            1711031460000,
            685.7,
            687.55,
            685.7,
            687.5
        ],
        [
            1711031520000,
            687.5,
            687.5,
            686.45,
            686.95
        ],
        [
            1711031580000,
            687,
            687.1,
            686.8,
            687.1
        ],
        [
            1711031640000,
            687.1,
            687.5,
            686,
            686.45
        ],
        [
            1711031700000,
            686.45,
            687.35,
            686.45,
            687.35
        ],
        [
            1711031760000,
            687.35,
            687.7,
            687.35,
            687.4
        ],
        [
            1711031820000,
            687.45,
            687.45,
            686.8,
            686.8
        ],
        [
            1711031880000,
            687.1,
            687.1,
            686.75,
            687.1
        ],
        [
            1711031940000,
            687.1,
            687.2,
            686.5,
            686.5
        ],
        [
            1711032000000,
            686.5,
            686.5,
            686.25,
            686.25
        ],
        [
            1711032060000,
            686.5,
            686.7,
            686.5,
            686.5
        ],
        [
            1711032120000,
            686.5,
            686.7,
            686.45,
            686.45
        ],
        [
            1711032180000,
            686.5,
            686.7,
            686.5,
            686.5
        ],
        [
            1711032240000,
            686.5,
            686.8,
            686.4,
            686.65
        ],
        [
            1711032300000,
            686.75,
            686.75,
            686.05,
            686.5
        ],
        [
            1711032360000,
            686.5,
            686.65,
            686.45,
            686.5
        ],
        [
            1711032420000,
            686.7,
            686.7,
            686.4,
            686.4
        ],
        [
            1711032480000,
            686.4,
            686.65,
            686.4,
            686.4
        ],
        [
            1711032540000,
            686.4,
            686.6,
            686.3,
            686.4
        ],
        [
            1711032600000,
            686.4,
            686.7,
            686.3,
            686.55
        ],
        [
            1711032660000,
            686.55,
            686.6,
            686.4,
            686.4
        ],
        [
            1711032720000,
            686.8,
            686.8,
            685.9,
            686.3
        ],
        [
            1711032780000,
            686.2,
            686.4,
            685.85,
            686.15
        ],
        [
            1711032840000,
            686,
            686.2,
            685.85,
            686.15
        ],
        [
            1711032900000,
            686,
            686,
            685.9,
            686
        ],
        [
            1711032960000,
            685.95,
            686.15,
            685.1,
            685.7
        ],
        [
            1711033020000,
            685.75,
            685.95,
            685.4,
            685.6
        ],
        [
            1711033080000,
            685.6,
            685.6,
            685.4,
            685.6
        ],
        [
            1711033140000,
            685.45,
            685.6,
            685.45,
            685.55
        ],
        [
            1711033200000,
            685.5,
            685.6,
            685.45,
            685.5
        ],
        [
            1711033260000,
            685.5,
            685.5,
            685.1,
            685.45
        ],
        [
            1711033320000,
            684.95,
            685,
            684.55,
            684.7
        ],
        [
            1711033380000,
            684.7,
            685.6,
            684.7,
            685.6
        ],
        [
            1711033440000,
            685.2,
            685.6,
            685.15,
            685.6
        ],
        [
            1711033500000,
            685.6,
            685.6,
            684.8,
            685.25
        ],
        [
            1711033560000,
            685.25,
            685.65,
            684.9,
            684.9
        ],
        [
            1711033620000,
            685,
            685.2,
            684.5,
            685.2
        ],
        [
            1711033680000,
            685,
            685.5,
            685,
            685.5
        ],
        [
            1711033740000,
            685.2,
            686.1,
            684.95,
            685.45
        ],
        [
            1711033800000,
            685.65,
            685.65,
            685.3,
            685.45
        ],
        [
            1711033860000,
            685.45,
            686.05,
            685.4,
            686.05
        ],
        [
            1711033920000,
            685.95,
            686.35,
            685.9,
            685.9
        ],
        [
            1711033980000,
            685.9,
            686,
            685.75,
            685.9
        ],
        [
            1711034040000,
            685.75,
            686.1,
            685.75,
            685.9
        ],
        [
            1711034100000,
            685.75,
            685.9,
            685.5,
            685.7
        ],
        [
            1711034160000,
            685.5,
            685.5,
            684.65,
            684.95
        ],
        [
            1711034220000,
            685,
            685.2,
            684.85,
            684.95
        ],
        [
            1711034280000,
            685,
            685.2,
            684.55,
            684.55
        ],
        [
            1711034340000,
            684.4,
            684.55,
            684.4,
            684.55
        ],
        [
            1711034400000,
            684.55,
            685,
            684.55,
            684.95
        ],
        [
            1711034460000,
            684.95,
            685.7,
            684.95,
            685.7
        ],
        [
            1711034520000,
            685.7,
            685.7,
            685.3,
            685.6
        ],
        [
            1711034580000,
            685.6,
            685.6,
            685.35,
            685.35
        ],
        [
            1711034640000,
            685.35,
            686.1,
            685.25,
            685.75
        ],
        [
            1711034700000,
            686,
            687.95,
            685.5,
            687.95
        ],
        [
            1711034760000,
            687.95,
            687.95,
            686.9,
            687.55
        ],
        [
            1711034820000,
            687.1,
            687.6,
            686.1,
            686.55
        ],
        [
            1711034880000,
            686.55,
            686.6,
            686.2,
            686.5
        ],
        [
            1711034940000,
            686.5,
            689.9,
            686.3,
            689.9
        ],
        [
            1711035000000,
            690,
            690,
            690,
            690
        ]];


      const [candleSeries ,setCandleSeries ] = useState([{
        data : []
      }]);
     
      const getLimitData = () => {
        const dataLength = data.length;
        const stepSize = Math.floor(data.length / 70);
        const selectedRecords = [];

        for (let i = 0; i < data.length; i += stepSize) {
            selectedRecords.push(data[i]);
        }

        const lastIndex = data.length - 1;
        const startIndex = Math.max(0, lastIndex - 500); // Index of the first element in the last 70 data points
        const last70Data = data.slice(startIndex);

        const timestamp = 1708506900000;
        const date = new Date(timestamp);
        
        setCandleSeries([{data : last70Data}]);
      }
      useEffect(() => {},[candleSeries]);

      useEffect(() => { getLimitData()},[]);
     
      const [candleOption , setCandleOption] = useState({
        chart: {
            height: 350,
            type: 'candlestick',
          },
          title: {
            text: 'CandleStick Chart - Category X-axis',
            align: 'left'
          },
          
          annotations: {
            xaxis: [
              {
                x: 'Oct 06 14:00',
                borderColor: '#00E396',
                label: {
                  borderColor: '#00E396',
                  style: {
                    fontSize: '12px',
                    color: '#fff',
                    background: '#00E396'
                  },
                  orientation: 'horizontal',
                  offsetY: 7,
                  text: 'Annotation Test'
                }
              }
            ]
          },
          tooltip: {
            enabled: true,
          },
          xaxis: {
            type: "datetime"
          },
          yaxis: {
            tooltip: {
              enabled: true
            }
        }
    }
    );

    // useEffect(() => {updateData('one_year')},[]);

   const  updateData = (timeline) => {
     setSelection(timeline);
    
      switch (timeline) {
        case 'one_month':
          ApexCharts.exec(
            'area-datetime',
            'zoomX',
            new Date('28 Jan 2013').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'six_months':
          ApexCharts.exec(
            'area-datetime',
            'zoomX',
            new Date('27 Sep 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'one_year':
          ApexCharts.exec(
            'area-datetime',
            'zoomX',
            new Date('21 Feb 2024').getTime(),
            new Date('21 Mar 2024').getTime()
          )
          break
        case 'ytd':
          ApexCharts.exec(
            'area-datetime',
            'zoomX',
            new Date('01 Jan 2013').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        case 'all':
          ApexCharts.exec(
            'area-datetime',
            'zoomX',
            new Date('23 Jan 2012').getTime(),
            new Date('27 Feb 2013').getTime()
          )
          break
        default:
      }
    }



    useEffect(() => {},[candleSeries]);

    return(
        <>
         <section className="mobile-view"> 
             <div className="container web-cotainer"> 
                <Row className="title-section">
                    <Col>
                      <span>  Backtest Results</span>
                    </Col>
               </Row>
               <div>
                    <ReactApexChart  options={candleOption}  series={candleSeries}  type="candlestick" height={350}  />
               </div>
             </div>
        </section> 
        </>
    )
}

export default BackTestResultStrategy;