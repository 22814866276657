import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ZerodhaHMPDetails from "./ZerodhaHMPDetails";
import { getZerodhaLogin, getZerodhaSessionGenrated, zerodhaGetAccountApi } from "../../api/zerodhaapi";
import { getUser, saveActiveAccount, saveZerodhaToken,getActiveAccountdata } from "../../Session";
import { useNavigate } from "react-router-dom";
import {checkzerodhaacc} from "../../api/dashboardApi";
const ZerodhaLogin = ({handleClose}) =>{
    const [token , setToken ] = useState();
    const handleChangeConnect = async () => {
        const user = getUser();
        const response = await zerodhaGetAccountApi({ user_id : user.id });
        // const url = await getZerodhaLogin({user_id : user?.id});
        // sessionStorage.setItem("tokenReceived", "true");
        // window.location.href = url;
        // window.open(url); 
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("request_token");
        handleClose();
        // window.location.href = url ;
        // if(url) {
        //    window.open(url); 
       
        // };
        if(response === false)
       {
        console.log("Account is not activated, Please try again...")
       }
       else
       {
      
        handleClose();
      
        const activeacc=getActiveAccountdata();
        const checkforzerodha=await checkzerodhaacc(
        {
            api_key:response?.accountDetails[0]?.api_key,
            access_token:response?.accountDetails[0]?.access_token

        });
      
       if(checkforzerodha?.success===true)
        {
            saveZerodhaToken(response?.accountDetails[0]?.access_token);
            saveActiveAccount("zerodha",response?.accountDetails[0]);
        }
        else if(checkforzerodha?.success===false)
            {
                alert("Invalid token");
            }
        else{
        }
        window.location.reload();
       }
    }

    return(
        <>
            {
                // token ? <ZerodhaHMPDetails /> : 
            <span> Please 
                 <span variant="primary" className="text-primary fw-6 pointer-cursor" onClick={handleChangeConnect}> click here </span>
                to connect </span>
            }
           
        </>
    )

}

export default ZerodhaLogin;