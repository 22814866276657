import React from "react";
import { Link } from "react-router-dom";

const ZerodhaAddAccountMsg = ({setIsAccountAdd}) => {

    const handleChangeMsg = () => {
        setIsAccountAdd("signupform");
    }

  return (
    <>
      <div className="col-lg-9">
        <div className="info-div">
          <h2 className="mobile-title">Zerodha Registartion</h2>
          <p className="p-3">
            You have no account please<span className="fw-5 text-primary" onClick={handleChangeMsg}> click here  </span> to{" "}
             create account
          </p>
        </div>
      </div>
    </>
  );
};
export default ZerodhaAddAccountMsg;
