// Account.js
import React, { useEffect, useMemo, useState } from "react";
import ProfileCard from "./ProfileCard";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Profile from "./Profile";
import TradingAccount from "../components/TradingAccount";
import Billing from "../components/account/Billing";
import { Card } from "react-bootstrap";
import Preferences from "../components/account/Preferences";
import Help from "../components/account/Help";
import SubscribePlan from "../Pages/SubscribePlan";
import Feedback from "../components/account/Feedback";

const Account = () => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [activePage, setActivePage] = useState(tab);
  const navigate = useNavigate();
  const location = useLocation() ;
   useEffect(() => {
     const newTab = searchParams.get("tab");
     setActivePage(newTab);
   }, [location.search]);
  const handleClickNavigate = (page) => {
    navigate(page);
    setActivePage(page);
  };
  const memorizeComponent = useMemo(
    () => (
      <ProfileCard
        tab={tab}
        handleClickNavigate={handleClickNavigate}
        setActivePage={setActivePage}
        activePage={activePage}
      />
    ),
    [tab, activePage, location.pathname]
  );

  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer" id="profile-account">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">{memorizeComponent}</div>

            <div className="col-lg-8 col-md-8 col-12">
              <Card className="note">
                <Card.Body className="p-2">
                  <span>
                    &nbsp; <i className="fa fa-info"></i> &nbsp; contact
                    support:{" "}
                    <span className="text-primary text-decoration-none fw-bold">
                      support@ProductName.tech
                    </span>{" "}
                    for any queries, Contact no:{" "}
                    <span className="text-primary text-decoration-none fw-bold">
                      08069426942
                    </span>
                  </span>
                </Card.Body>
              </Card>

              {activePage === "profile" && <Profile />}
              {activePage === "trading" && <TradingAccount setActivePage ={setActivePage}/>}
              {activePage === "billing" && <Billing />}
              {activePage === "preferences" && <Preferences />}
              {activePage === "help" && <Help />}
              {activePage === "feedback" && <Feedback />}
              {activePage === "pricing" && <></>}
              {activePage === "help" && <Help />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Account;
