import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const DiscoverSideBar = ({strategycategories}) => {
  // console.log("first",strategycategories);
  const navigate = useNavigate();

  const navigaeToLocation = (filter_name) => {
    const url = `/discover?filter=${filter_name}`;
    navigate(url);
  };
const generateimage=(name)=>{
  if (name?.includes("Trend Following")) {
    return <img src="/icons/Group.png" alt="trend-following"/>;
  } else if (name?.includes("Price Action")) {
    return <img src="/icons/price-action.png" alt="Price-Action" />;
  } else if (name?.includes("Mean Reversion")) {
    return <img
    src="/icons/Mean-Reversion.png"
    alt="Price-Action"
    className="bg-color-grey"
  />;
  } else if(name?.includes("Momentum")) {
      return <img
      src="/icons/Mumentum.png"
      alt="Price-Action"
      className="bg-color-grey"
    />;
  } else if(name?.includes("Miscellaneous")) {
      return <img src="/icons/miscellaneous.png" alt="Momentum" />;
  } else if (name?.includes("Pivot Point")){
        return <img src="/icons/pivot-point.png" alt="Momentum" />;
  }else{
  }

  return `${name} scanners`;
}
const generatetext=(name)=>{
  if (name?.includes("Trend Following")) {
    return <span className="sub-title">Make the trend your friend</span>;
  } else if (name?.includes("Price Action")) {
    return <span className="sub-title">Price action is king</span>
  } else if (name?.includes("Mean Reversion")) {
    return <span className="sub-title">The price return back to the mean.</span>;
  } else if(name?.includes("Momentum")) {
      return <span className="sub-title">The rate at which price changes.</span>;
  }  else {
    return `${name} scanners`;
  }

 
}

  return (
    <>
      <Row className="mb-2">
        <Col xs>
          <span className="fw-bold">Discover</span>
          <Link
            to="/discover/explore"
            className="pull-right text-decoration-none"
          >
            {" "}
            View all
          </Link>
        </Col>
      </Row>

      {strategycategories?.map((scanner,index)=>{
                return(
                <>
            <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(index+1);
        }}
      >
        <Col lg={2} md={2}>
          {/* <img src="/icons/price-action.png" alt="Price-Action" /> */}
          {generateimage(scanner.name)}
        </Col>
        <Col lg={8} md={3} className="d-flex flex-column">
          <span className="title">{scanner.name}</span>
          <span className="sub-title">{generatetext(scanner.name)}.</span>
        </Col>
        <Col lg={2}>
        <div className="d-flex justify-content-center align-items-center"
                    style={{
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                        border: "1px solid #2c2c2c",
                        backgroundColor: "#f5f5f5", // Light background for contrast
                        color: "#2c2c2c", // Text color
                        fontWeight: "bold", // Make text bold
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add light shadow
                        cursor: "pointer", // Pointer for interactivity
                        transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effects
                      }}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "#e0e0e0") // Slight darkening on hover
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor = "#f5f5f5") // Back to normal on mouse out
                      }>
                    <span>{scanner?.strategy_count}</span>
                    </div>
        </Col>
      </Row>
      <hr />
                </>
                );
            })
            }
      {/* <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(1);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/Group.png" alt="trend-following" />
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Trend following</span>
          <span className="sub-title">Make the trend your friend</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(2);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/price-action.png" alt="Price-Action" />
        </Col>
        <Col lg={10} md={3} className="d-flex flex-column">
          <span className="title">Price Action</span>
          <span className="sub-title">Price action is king.</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(3);
        }}
      >
        <Col lg={2} md={2}>
          <img
            src="/icons/Mean-Reversion.png"
            alt="Price-Action"
            className="bg-color-grey"
          />
        </Col>
        <Col lg={10} md={3} className="d-flex flex-column">
          <span className="title">Mean Reversion</span>
          <span className="sub-title">The price return back to the mean.</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(4);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/Mumentum.png" alt="Momentum" />
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Momentum</span>
          <span className="sub-title">The rate at which price changes.</span>
        </Col>
      
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(5);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/miscellaneous.png" alt="Momentum" />
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Miscellaneous</span>
          <span className="sub-title">Miscellaneous</span>
        </Col>
      </Row>
      <hr />
      <Row
        className="mb-2 align-items-center discover-menu"
        onClick={() => {
          navigaeToLocation(6);
        }}
      >
        <Col lg={2} md={2}>
          <img src="/icons/pivot-point.png" alt="Momentum" />
        </Col>
        <Col lg={10} md={10} className="d-flex flex-column">
          <span className="title">Pivot Points</span>
          <span className="sub-title">Pivot Points</span>
        </Col>
      </Row>
      <hr/> */}
    </>
  );
};

export default DiscoverSideBar;
