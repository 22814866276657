import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { getUserPlan, getUser, getZerodhaToken } from "../Session";
import { getAllAccounts } from "../api/dashboardApi";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Select from "react-select";
import { saveActiveAccount } from "../Session";
import { paisaGetAccount } from "../api/profileapi";
import { get5paisaToken } from "../Session";
import Paisa5LoginModal from "../components/dashboardComponent/Paisa5LoginModal";
import { getActiveAccount } from "../Session";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../components/context/manageContent";
import { BASE_URL, IMAGE_URL } from "../Config";
const MobileUser = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [activeuser, setActiveUser] = useState(null);
  const [accdetails, setAccdetails] = useState({});
  const [paisa5data, setPaisa5Data] = useState();
  const [activeaccount, setActiveaccount] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeAccount, setActiveAccount] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [connectBtn, setConnectBtn] = useState(false);
  const [myacc, setMyacc] = useState(false);
  const contentData = useContext(DataContext);
  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };
  const user = getUser();
  useEffect(() => {
    gettingallacc(user.id);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const getPaisa5data = await paisaGetAccount({ user_id: user.id });
        setPaisa5Data(getPaisa5data);
      } catch (error) {
        console.error("Error fetching Paisa5 data:", error);
      }
    };

    fetchData();
  }, []);

  const handelClickCreateStrategy = (name) => {
    setShowCreatePopup(false);
    // navigate("/createstrateges");
  };
  const handleClose = () => {
    setShowCreatePopup(false);
  };
  const gettingallacc = async (user) => {
    const accdetails = await getAllAccounts({ user_id: user });
    setAccdetails(accdetails?.data);
  };

  const pathName = useLocation().pathname;

  useEffect(() => {
    const activeUserFromSession = getActiveAccount();
    setActiveUser(activeUserFromSession);
  }, [setActiveaccount, setActiveUser, setMyacc]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathName, activeuser]);

  const handleChange = (selectedOption) => {
    if (selectedOption?.value == "paisa") {
      if (activeaccount == true) {
        setActiveUser(selectedOption?.value);
      }
    }
  };

  const getdata = async (account) => {
    const user = await getUser();
    const data = await getAllAccounts({ user_id: user.id });
    let checkData = "";
    if (account === "paisa5") {
      checkData = data?.data?.FivePaisa[0];
    } else if (account === "zerodha") {
      checkData = data?.data?.Zerodha[0];
    }
    saveActiveAccount(account, checkData);
  };

  // useEffect(() => {
  //   getdata();
  // }, []);
  useEffect(() => {
    const gettingtoken5paisa = get5paisaToken();
    const gettingZerodhaToken = getZerodhaToken();
    if (gettingtoken5paisa) {
      setActiveaccount(true);
      setMyacc(true);
      getdata("paisa");

      // saveActiveAccount('paisa');
      setActiveUser("paisa");
    } else if (gettingZerodhaToken) {
      setActiveaccount(true);
      setMyacc(true);
      getdata("zerodha");
      setActiveUser("zerodha");
    }
  }, [setActiveaccount, setActiveUser, setMyacc]);

  const activeMenuContent = useMemo(() => {
    if (activeMenu === "5Paisa") {
      // return <Paisa5login handleChangeConnect={handleChangeConnect} connectBtn={connectBtn} paisa5data={paisa5data} />;
      return (
        <Paisa5LoginModal
          showCreatePopup={showCreatePopup}
          handelClickCreateStrategy={handelClickCreateStrategy}
          setShowCreatePopup={setShowCreatePopup}
          paisa5data={paisa5data}
          handleClose={handleClose}
          setActiveaccount={setActiveaccount}
          setActiveUser={setActiveUser}
          setMyacc={setMyacc}
          state={{ val: 2 }}
        />
      );
    } else {
      return null;
    }
  }, [
    activeMenu,
    connectBtn,
    paisa5data,
    showCreatePopup,
    setActiveUser,
    setMyacc,
    setActiveaccount,
  ]);
  const handleChangeMenu = (menu, value) => {

    // saveActiveAccount(menu);
    if (menu == "5Paisa") {
      setActiveMenu("5Paisa");

      if (activeaccount == true) {
        setActiveAccount(true);
        saveActiveAccount(value);
        setShowCreatePopup(false);
        alert("Already logged-in");
      } else {
        setShowCreatePopup(true);
      }
    }
  };

  // const customStyles = {
  const customStyles = {
    option: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
    }),
    container: (provided) => ({
      ...provided,
      width: "auto", // Set container width to auto
      maxWidth: "100%", // Set maximum width to 100% to allow the content to adjust
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0", // Remove padding to ensure the content fits within the container
    }),
    imageContainer: {
      maxWidth: "40px", // Set maximum width for the image container
    },
    textContainer: {
      flex: "1", // Allow text container to expand to fill available space
      overflow: "hidden", // Hide overflow if text exceeds container width
      textOverflow: "ellipsis", // Add ellipsis for text overflow
    },
  };

  // const formatOptionLabel = ({ label, src }) => (
  //   <div style={{ display: "flex", alignItems: "center" }}>
  //     <img src={src} alt="Icon" style={{ width: 24, height: 24, marginRight: 10 }} />
  //     <span>{label}</span>
  //   </div>
  // );n
  const options = [];
  if (accdetails?.FivePaisa?.length > 0) {
    options.push({
      label: "5Paisa",
      value: "paisa",
      s: "/5Paisa.png",
    });
  }
  if (accdetails?.Zerodha?.length > 0) {
    options.push({
      label: "Zerodha",
      value: "zerodha",
      s: "/icon-trading/Zerodha.png",
    });
  }

  const formatOptionLabel = ({ label, value, s }) => (
    <div
      onClick={() => handleChangeMenu(label, value)}
      className={`d-flex align-items-center justify-content-center  ${
        activeMenu === label.toLowerCase() && activeAccount ? "active" : ""
      }`}
    >
      <div className="image-container mx-2" style={customStyles.imageContainer}>
        <img src={s} alt={`${label} Icon`} className={`w-50 h-50`} />
      </div>
      <span style={{ margin: "0", padding: "0", lineHeight: "1" }}>
        {label}
      </span>
    </div>
  );

  const checkaccountdataexist = (link) => {
    const acc = getActiveAccount();
    // if (acc && acc != null) navigate(link);
    // else alert("Active your account first");
     navigate(link);
  };
  const strategyNumber = pathName.match(/^\/strategies\/(\d+)$/);
  return (
    <>
      <header className="mob-header fixed-top mobile-header-responsive">
        <div
          className="container d-flex align-items-center"
          style={{ height: "100%" }}
        >
          <h1 className="logo my-auto me-auto">
            <Link to="/" onClick={() => setMobileNavOpen(false)}>
              <img
                src={`${IMAGE_URL}${contentData?.top_navbar_image}`}
                style={{ height: "48px" }}
                // alt="logo"
                loading="lazy"
              ></img>
            </Link>
          </h1>
          <nav className="mob-navbar" style={{ margin: "auto" }}>
            <ul className="mob-nav-links">
              <div
                style={{
                  height: "70px",
                  width: "28vw",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <li>
                  <Link
                    to="/dashboard"
                    className={`my-text ${
                      pathName === "/dashboard" ? "active" : null
                    }`}
                    style={{ fontSize: "22px", color: "#0e6efd" }}
                  >
                    <i className="fa-solid fa-house" aria-hidden="true"></i>
                    {pathName === "/dashboard" ? (
                      <span className="menu-line"></span>
                    ) : null}
                  </Link>
                </li>

                <div
                  // to="/strategies"
                  className={`px-1 ${
                    pathName === "/strategies" ? "active" : null
                  }`}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    className={`headerButton ${
                      pathName === "/strategies" ||
                      pathName === "/discover" ||
                      pathName === "/strategies/viewall" ||
                      pathName.startsWith("/strategies/")
                        ? "active"
                        : null
                    }`}
                    onClick={() => checkaccountdataexist("/strategies")}
                  >
                    Strategies
                  </div>
                </div>

                <div
                  // to="/techincal"
                  className={`px-1 ${
                    pathName === "/techincal" ? "active" : null
                  }`}
                >
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => checkaccountdataexist("/techincal")}
                    className={`headerButton ${
                      pathName === "/techincal" ? "active" : null
                    }`}
                  >
                    Technicals
                  </div>
                </div>

                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => checkaccountdataexist("/scanners")}
                  className={`px-1 ${
                    pathName === "/scanners" ? "active" : null
                  }`}
                >
                  <div
                    className={`headerButton ${
                      pathName === "/scanners" ? "active" : null
                    }`}
                  >
                    Scanner
                  </div>
                </div>
              </div>
            </ul>
          </nav>
          <nav
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {accdetails?.FivePaisa?.length === 0 &&
            accdetails?.Zerodha?.length === 0 ? (
              <div></div>
            ) : (
              <span
                className="subscribe-name d-flex align-items-center"
                style={{
                  visibility:
                    location?.pathname === "/dashboard" ? "hidden" : "visible",
                }}
              >
                <Select
                  aria-label="Default select example"
                  value={
                    options.find(
                      (option) =>
                        option.value === activeuser && activeaccount === true
                    ) || null
                  }
                  onChange={handleChange}
                  options={options}
                  formatOptionLabel={formatOptionLabel}
                  styles={customStyles}
                  placeholder="No Account"
                />
              </span>
            )}
            <div
              style={{
                marginLeft: "30px",
                marginRight: "-20px",
                width: "9vw",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              {/* <span>
                <Link
                  to="/account?tab=profile"
                  className={`d-flex flex-column justify-content-center align-items-center ${
                    pathName == "/account" ? "active" : null
                  }`}
                  style={{ fontSize: "24px" }}
                >
                  <div className="d-flex flex-column">
                    <span>
                      <i className="fa fa-user-o" aria-hidden="true"></i>
                    </span>
                    {pathName === "/account" ? (
                      <span className="menu-line-profile"></span>
                    ) : null}
                  </div>
                </Link>
              </span> */}
              <Link to="/account?tab=profile">
                <img
                  src={
                    user?.profile_image
                      ? `${BASE_URL}` + user?.profile_image
                      : "/icon-images/profile.png"
                  }
                  className="img-fluid text-right"
                  alt="profile"
                  style={{ borderRadius: "50%", height: "45px", width: "45px" }}
                />
              </Link>

              <span>
                <Link
                  to="/notification"
                  className={` ${
                    pathName === "/notification" ? "active" : null
                  }`}
                  style={{ fontSize: "25px" }}
                >
                  <div className="d-flex flex-column">
                    <span>
                      <i className="fa fa-bell-o" aria-hidden="true"></i>
                    </span>
                    {pathName === "/notification" ? (
                      <span className="menu-line-notification"></span>
                    ) : null}
                  </div>
                </Link>
              </span>
            </div>

            {/* <img
              style={{ marginLeft: "20px" }}
              src="/icon-images/menu.png"
              className="img-fluid text-rigth"
              height={20}
              width={30}
              alt="profile"
            /> */}
          </nav>
        </div>
      </header>
      <div className="p-3 pr-4 dashboard-account-treading-tab">
        {activeMenuContent}
      </div>
    </>
  );
};

export default MobileUser;
