import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { getMarginApi } from "../api/paisa5api";
import { useLocation, useParams } from "react-router-dom";
import { getZerodhaMargin, getMarginandFO } from "../api/zerodhaapi";
import { get5paisaToken } from "../Session";
import { getActiveAccount, getActiveAccountdata } from "../Session";
const BalanceCard = () => {
  const user = get5paisaToken();
  const activeuser = getActiveAccount();
  const d = getActiveAccountdata();
  const [marginData, setMarginData] = useState();
  const [activeTab, setActiveTab] = useState("Equity");
  const [text, setText] = useState("Equity");
  const [foMargins, setFoMargins] = useState([]);
  const [equityMargins, setEquityMargins] = useState([]);
  const [activeArray, setActiveArray] = useState();
  const param = useParams();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const getBalance = async () => {
    // set active accounts here
    if (activeuser === "zerodha") {
      const zerodhaMargin = await getZerodhaMargin();
      setMarginData("-3999.6");
    }
    if (activeuser === "paisa") {
      const data = await getMarginApi();
      // setMarginData(data[0].NetAvailableMargin);
      // console.log("ZERODHAA",data)
      if (data) {
        setMarginData(data.marginDetails[0].NetAvailableMargin);
      } else {
      }
      // setMarginData(data.data?.marginDetails[0]?.NetAvailableMargin);
    }
  };
  // sessionStorage.setItem("5paisaToken
  useEffect(() => {
    if (user) {
      getBalance();
    }
  }, [param, marginData, activeuser]);

  useEffect(() => {
    if (d) {
      getMargin();
    }
  }, []);
  const getMargin = async () => {
    const result = await getMarginandFO({
      api_key: d.api_key,
      access_key: d.access_token,
    });
    if (result) {
      var [foarray, equityarray] = result;
      setFoMargins(foarray);
      setEquityMargins(equityarray);
    }
  };
  useEffect(() => {
    if (equityMargins) setActiveArray(equityMargins[0]);
  }, [foMargins, equityMargins]);
  // getActiveAccount

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setText(tab);
    if (tab === "Equity") {
      setActiveArray(equityMargins[0]);
    } else {
      setActiveArray(foMargins[0]);
    }
  };

  return (
    <Card>
      <Card.Body>
        <div className="row">
          <div className="col-lg-6 p-1">
            <Button
              style={{
                color: activeTab === "Equity" ? "white" : "black",
                backgroundColor: activeTab === "Equity" ? "blue" : "#B299A4",
              }}
              onClick={() => handleTabClick("Equity")}
              size="sm"
            >
              Equity
            </Button>
          </div>
          <div className="col-lg-6 p-1">
            <Button
              style={{
                color: activeTab === "F & O" ? "white" : "black",
                backgroundColor: activeTab === "F & O" ? "blue" : "#B299A4",
              }}
              onClick={() => handleTabClick("F & O")}
              size="sm"
            >
              F & O
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>{text}</p>
            <span className="avl-bal-icon">
              <i className="fa fa-rupee"></i>{" "}
            </span>
            <span className="avl-bal-amount">{activeArray?.available_bal}</span>
            <br />
            <span className="bal-text">Available Balance</span>
          </div>
        </div>
        <div className="row pt-2">
          <div className="col-lg-6">
            <span className="avl-bal-margin">
              <i className="fa fa-rupee"></i> {activeArray?.margin}
            </span>
            <br />
            <span className="bal-text">Margin</span>
          </div>
          <div className="col-lg-6">
            <span className="avl-bal-account">
              <i className="fa fa-rupee"></i>
              {activeArray?.account}
            </span>
            <br />
            <span className="bal-text">Account</span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BalanceCard;
