import React, { useMemo } from "react";
import { Col, Form, Row } from "react-bootstrap";

const Paisa5Holdings = () => {
  // console.log("Paisa5HMPDetails");

  const holdings = {
    holdings: "No record found.",
  };

  const memoizedFormGroup = useMemo(
    () => (
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextMargin">
        <Form.Label column sm="3">
          Holding :
        </Form.Label>
        <Col sm="1" className="pt-2">
          {/* <span className="fa fa-rupee"></span> */}
        </Col>
        <Col sm="8">
          <Form.Control plaintext readOnly defaultValue={holdings.holdings} />
        </Col>
      </Form.Group>
    ),
    [holdings.holdings]
  );

  return <Form className="m-5">{memoizedFormGroup}</Form>;
};

export default Paisa5Holdings;
