import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const StocksCarousel = ({ indices, handleItemClick, handleitemid }) => {
  const visibleCount = 5;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - visibleCount, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + visibleCount, indices?.length - visibleCount)
    );
  };

  const handleClick = (index, id, itemid, item) => {
    setSelectedIndex(index);
    handleItemClick(id, itemid, item);
  };
  const visibleItems = indices?.slice(
    currentIndex,
    currentIndex + visibleCount
  );

  useEffect(() => {
    if (indices && indices.length > 0) {
      handleClick(
        0,
        indices[0].indices_id ? indices[0].indices_id : indices[0].id,
        indices[0].id,
        indices[0]
      );
    }
  }, [indices]);

  return (
    <div className="row mt-5" id="technical-move-bar">
      <div className="col-lg-12">
        <div className="bank-cards-bar position-relative">
          <Button
            style={{ display: currentIndex === 0 ? "none" : "block" }}
            className="chevron-left-btn position-absolute chevronleft"
            onClick={handlePrev}
            // disabled={currentIndex === 0}
          >
            <i className="fa fa-chevron-left" style={{ color: "#33318b" }}></i>
          </Button>
          <div
            className="d-flex overflow-hidden"
            style={{ width: "100%", position: "relative" }}
          >
            {visibleItems?.map((item, index) => (
              <div
                className="cursor-pointer m-2 text-center"
                key={index}
                onClick={() => {
                  handleClick(
                    currentIndex + index,
                    item.indices_id ? item.indices_id : item.id,
                    item.id,
                    item
                  );
                }}
                style={{
                  width: "150px",
                  cursor: "pointer",
                  // padding: "15px",
                  border: "5px solid white",
                  borderRadius:
                    currentIndex + index === selectedIndex ? "5px" : "none",
                  backgroundColor:
                    currentIndex + index === selectedIndex
                      ? "#f7f7f6"
                      : "#f6f6f6",
                  height: "120px",
                  boxShadow:
                    currentIndex + index === selectedIndex
                      ? "0 0 16px 3px #B8B8B8"
                      : "none",
                  transition: "box-shadow 0.3s",
                  flexShrink: 0, // Ensures the items don't shrink below their specified width
                }}
              >
                <div
                  style={{
                    color: "#33318b",
                    fontWeight: "600",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {item.Indices}
                </div>
                <div className="text-center" style={{ fontSize: "11px" }}>
                  Stock Count: {item.Stock_Count}
                </div>
              </div>
            ))}
          </div>
          <Button
            style={{
              display:
                currentIndex >= indices?.length - visibleCount
                  ? "none"
                  : "block",
            }}
            className="chevron-right-btn"
            onClick={handleNext}
          >
            <i className="fa fa-chevron-right" style={{ color: "#33318b" }}></i>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StocksCarousel;
