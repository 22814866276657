import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Paisa5login from "./dashboardComponent/Paisa5login";
import { paisaGetAccount } from "../api/profileapi";
import { getUser, getZerodhaToken, saveZerodhaToken } from "../Session";
import { get5paisaToken } from "../Session";
import Paisa5LoginModal from "./dashboardComponent/Paisa5LoginModal";
import { saveActiveAccount, getActiveAccountdata } from "../Session";
import ZerodhaLoginModal from "./dashboardComponent/ZerodhaLoginModal";
import { getZerodhaSessionGenrated, zerodhaGetAccountApi } from "../api/zerodhaapi";
import { useSearchParams } from "react-router-dom";
import CustomAlert from "./componentCommon/CustomAlert";

// const user  = getUser();

const DashboardActiveAccount = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const zerodhaRequestToken = searchParams.get("request_token");
  const zerodhaAction = searchParams.get("action");
  const zerodhaType = searchParams.get("type");
  const zerodhaStatus = searchParams.get("status");
  const [connectBtn, setConnectBtn] = useState(false);
  const [activeMenu, setActiveMenu] = useState();
  const [paisa5data, setPaisa5Data] = useState();
  const [activeaccount, setActiveaccount] = useState(false);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [activeuser, setActiveUser] = useState(null);
  const [showActiveAccount, setShowActiveAccount] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  
  const handleClickStrategyModal = () => {
    setShowCreatePopup(true);
  };

  const handelClickCreateStrategy = (name) => {
    setShowCreatePopup(false);
    // navigate("/createstrateges");
  };

  const handleChangeConnect = () => {
    setConnectBtn(true);
  };
  const handleChangeMenu = (menu) => {
    setActiveMenu(menu);
    if (menu === "zerodha") {
      const zerodhaToken = getZerodhaToken();
      if (zerodhaToken === null) {
        setShowCreatePopup(true);
      } else {
        setShowActiveAccount("zerodha");
        saveActiveAccount(menu);

        setShowCreatePopup(false);
        // alert("Already Account is activated");
        setAlertMessage({
          message: "Already Account is activated",
          type: "alert-success",
        });
      }
    } else if (menu === "paisa") {
      const paisa5Token = get5paisaToken();
      if (paisa5Token === null) {
        setShowCreatePopup(true);
      } else {
        setShowActiveAccount("paisa");
        saveActiveAccount(menu);
        setShowCreatePopup(false);
        // alert("Already Account is activated");
        setAlertMessage({
          message: "Already Account is activated",
          type: "alert-success",
        });
      }
    }
  };

  useEffect(() => {
    if (alertMessage) {
      setTimeout(() => {
        setAlertMessage(null);
      }, 1500);
    }
  }, [alertMessage]);
  const zerodhaAccountSessionActive = async () => {
    const user = getUser();
    // console.log("USERGET", user)
    const getResZerodha = await zerodhaGetAccountApi({ user_id: user.id });
    const response = await getZerodhaSessionGenrated({
      user_id: user.id,
      token: zerodhaRequestToken,
      // api_key: getResZerodha?.accountDetails[0]?.api_key,
      // api_secret: getResZerodha?.accountDetails[0]?.api_secret,
    });
    saveZerodhaToken(zerodhaRequestToken);
    const zerodhaToken = await getZerodhaToken();
    if (response){
      window.location.reload();
    } 
      //  console.log("zerodhhhhaaa---", zerodhaToken, response);
    if (zerodhaToken === null) {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (
      zerodhaRequestToken !== "null" &&
      zerodhaAction === "login" &&
      zerodhaType === "login" &&
      zerodhaStatus === "success"
    ) {
      zerodhaAccountSessionActive();
      setActiveaccount(true);
    }
  }, []);

  const handleClose = () => {
    // console.log("checkMyData", showCreatePopup);
    setShowCreatePopup(false);
  };

  useEffect(() => {
    const user = getUser();
    const fetchData = async () => {
      try {
        const getPaisa5data = await paisaGetAccount({ user_id: user.id });
        setPaisa5Data(getPaisa5data);
      } catch (error) {
        console.error("Error fetching Paisa5 data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const gettingtoken5paisa = get5paisaToken();
    const gettingZerodhaAcc = getZerodhaToken();
    // console.log("gettingZerodhaAcc", gettingZerodhaAcc);
    if (gettingtoken5paisa) {
      setActiveaccount(true);
      // saveActiveAccount(menu);
      setActiveMenu("paisa");
      setShowActiveAccount("paisa");

      // setShowActiveAccount("paisa");
    } else if (gettingZerodhaAcc !== null) {
      setActiveaccount(true);
      // setActiveaccount("zerodha");
      setActiveMenu("zerodha");
      setShowActiveAccount("zerodha");
    }
  }, [setActiveaccount, setActiveUser]);

  const activeMenuContent = useMemo(() => {
    if (activeMenu === "paisa") {
      // return <Paisa5login handleChangeConnect={handleChangeConnect} connectBtn={connectBtn} paisa5data={paisa5data} />;
      return (
        <Paisa5LoginModal
          showCreatePopup={showCreatePopup}
          handelClickCreateStrategy={handelClickCreateStrategy}
          setShowCreatePopup={setShowCreatePopup}
          paisa5data={paisa5data}
          connectBtn={connectBtn}
          handleClose={handleClose}
          handleChangeConnect={handleChangeConnect}
          setActiveaccount={setActiveaccount}
          state={{ val: 1 }}
        />
      );
    } else if (activeMenu === "zerodha") {
      return (
        <ZerodhaLoginModal
          showCreatePopup={showCreatePopup}
          handelClickCreateStrategy={handelClickCreateStrategy}
          setShowCreatePopup={setShowCreatePopup}
          paisa5data={paisa5data}
          connectBtn={connectBtn}
          handleClose={handleClose}
          handleChangeConnect={handleChangeConnect}
          setActiveaccount={setActiveaccount}
          state={{ val: 1 }}
        />
      );
    } else {
      return null;
    }
  }, [activeMenu, connectBtn, paisa5data, showCreatePopup]);

  const { demataccounts: accdetails } = props;

  useEffect(() => {
    if (accdetails) {
      displayicons(accdetails);
    } else {
      console.log("Not getting accounts...");
    }
  }, [accdetails]);

  const displayicons = async (accdetails) => {
    if (!accdetails) return;

    // console.log("WWW", accdetails);
  };

  // console.log("active calling........")
  return (
    <>
      {alertMessage && (
        <CustomAlert
          message={alertMessage.message}
          type={alertMessage.type}
          setAlertMessage={setAlertMessage}
        />
      )}
      {/* <div className="row dashboard-account-treading"> */}
      <div id="dematAccountsContainer" className="h-100">
        {accdetails &&
        accdetails?.FivePaisa?.length === 0 &&
        accdetails?.Zerodha?.length === 0 ? (
          <>
            <div className="py-2">
              <h6 className="px-3 pt-1">No linked Demat Account.</h6>
              <p className="px-3">
                To add Demat Account, please{" "}
                <a href="/trading" className="fw-bolder">
                  click here
                </a>
              </p>
            </div>
          </>
        ) : (
          <div className="icons-container py-1 mx-3 d-flex">
            {accdetails?.FivePaisa?.length > 0 && (
              <>
                <div
                  className="image-container mx-2"
                  onClick={() => {
                    handleChangeMenu("paisa");
                  }}
                >
                  <img
                    src="./5Paisa.png"
                    alt="FivePaisa Icon"
                    className={`icon-class  {activeMenu === "paisa" && activeaccount==true ? 'active' : ''}`}
                  />
                  {activeaccount === true && showActiveAccount === "paisa" && (
                    <div className="overlay">
                      <span className="overlay-text">Active</span>
                    </div>
                  )}
                </div>
              </>
            )}

            {accdetails?.Zerodha?.length > 0 && (
              <div
                className="pointer-cursor"
                onClick={() => {
                  handleChangeMenu("zerodha");
                }}
              >
                <img
                  src="icon-trading/Zerodha.png"
                  alt="Zerodha Icon"
                  className="icon-class zerodha-icon mx-2"
                />
                {activeaccount === true && showActiveAccount === "zerodha" && (
                  <div className="overlay">
                    <span className="overlay-text">Active</span>
                  </div>
                )}
              </div>
            )}
            {/* <li onChange={handleChangeMenu}><img src="icon-trading/alice-blue.jpeg" alt="groww" className="img-fluid" /></li>
            <li onChange={handleChangeMenu}><img src="icon-trading/5paisa.jpeg" alt="groww" className="img-fluid" /></li>
            <li onChange={handleChangeMenu}><img src="icon-trading/alice-blue.jpeg" alt="groww" className="img-fluid" /></li>
            <li onChange={handleChangeMenu}><img src="icon-trading/groww.png" alt="groww" className="img-fluid" /></li>
            <li onChange={handleChangeMenu}><img src="icon-trading/groww.png" alt="groww" className="img-fluid" /></li> */}
            {/* {accdetails.Zerodha?.length > 0 && (
                    <img src="icon-trading/groww.png" alt="groww" 
                    className={`icon-class mx-2 {activeMenu === "groww" ? 'active' : ''}`}  
                    onClick={() =>{handleChangeMenu("groww")}} />
                )}
                  {accdetails.Zerodha?.length > 0 && (
                    <img src="icon-trading/Zerodha.png" alt="Zerodha Icon" className="icon-class zerodha-icon mx-2"  />
                )}
                  {accdetails.Zerodha?.length > 0 && (
                    <img src="icon-trading/Zerodha.png" alt="Zerodha Icon" className="icon-class zerodha-icon mx-2"  />
                )}
                  {accdetails.Zerodha?.length > 0 && (
                    <img src="icon-trading/Zerodha.png" alt="Zerodha Icon" className="icon-class zerodha-icon mx-2"  />
                )}
                  {accdetails.Zerodha?.length > 0 && (
                    <img src="icon-trading/Zerodha.png" alt="Zerodha Icon" className="icon-class zerodha-icon mx-2"  />
                )}
                  {accdetails.Zerodha?.length > 0 && (
                    <img src="icon-trading/Zerodha.png" alt="Zerodha Icon" className="icon-class zerodha-icon mx-2"  />
                )}
                  {accdetails.Zerodha?.length > 0 && (
                    <img src="icon-trading/Zerodha.png" alt="Zerodha Icon" className="icon-class zerodha-icon mx-2"  />
                )} */}
          </div>
        )}
      </div>
      {/* </div> */}
      <div className="p-3 pr-4 dashboard-account-treading-tab">
        {activeMenuContent}
      </div>
      {/* </div> */}
    </>
  );
};
export default DashboardActiveAccount;
