import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import moment from "moment";

const StrategyBox = ({ strategy }) => {

  const navigate = useNavigate();
  const isTabScreen = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const [series, setSeries] = useState([
    {
      name: "Desktops",
      data: [30, 40, 45, 50, 10, 20, 70, 91, 30, 40, 45, 50],
    },
  ]);

  const handleSingelStrategy = () => {
    const url = `/strategies/${strategy?.strategy_id}`;
    navigate(url);
  };

  return (
    <Card
      className={`mb-2 strategy-card ${
        !isTabScreen ? "" : "tab-screen"
      } pointer-cursor`}
      style={{
        backgroundColor: "rgba(128, 128, 128, 0.1)", 
        boxShadow: "0 4px 6px rgba(0, 0, 255, 0.1)", 
        border: "1px solid rgba(255, 255, 255, 0.3)",
        // background: "#f6f6f6",
        left: 20,
        height: "175px",
        overflow: "auto",
        
      }}
    >
      <Card.Body
        onClick={handleSingelStrategy}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Card.Subtitle style={{ maxHeight: "60px", overflow: "auto" }}>
          {strategy.strategy_name}
        </Card.Subtitle>
        <Card.Body
          className="discover-scanner-body fs-6 p-0"
          style={{ flexGrow: 1 }}
        >
          <Card.Text className="text-muted m-0">
            <span className="text-muted">
              {moment(strategy?.strategy_details?.created_at).format(
                "DD-MM-YYYY"
              )}
            </span>
            {/* <h6 className="fw-bold text-success">5.97%</h6> */}
            <br />
            <br />
          </Card.Text>
        </Card.Body>
        <div style={{ marginTop: "auto" }}>
          <h6 className="text-muted fw-bold">
            {strategy?.stock_count}{" "}
            {strategy?.stock_count > 1 ? "Stocks" : "Stock"}
          </h6>
        </div>
      </Card.Body>
    </Card>
  );
};

export default StrategyBox;
