import React from "react";
import { useMenuContext } from "./StrategyCreateComponent";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const StrategyCreateBarComponent = ({
  strategyName,
  setCreateStratgyPage,
  formData,
  stocks,
}) => {
  const { menu, setMenu } = useMenuContext();
  const navigate = useNavigate();
  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-between my-4">
          <div>
            <i
              className="fa fa-chevron-left chev"
              onClick={() => navigate(-1)}
            ></i>
            <h5 className="ps-2 fw-bolder d-inline">{strategyName}</h5>
          </div>
          <div>
            <div className="d-flex align-items-center"></div>
          </div>
        </div>
        <div className="container">
          <div className="hline" style={{ width: "100%", display: "flex" }}>
            <div
              style={{
                height: "100%",
                width: menu === "position" ? "17%" : 0,
                background: menu === "position" && "#2d2f94",
              }}
            >
              <div
                className={`circle one ${menu === "position" ? "active" : ""}`}
                onClick={() => setMenu("position")}
              >
                1
              </div>
              <div
                className={`position ${menu === "position" ? "active" : ""}`}
              >
                Positions
              </div>
            </div>

            <div
              style={{
                height: "100%",
                width: menu === "entry" ? "42%" : 0,
                background: menu === "entry" && "#2d2f94",
              }}
            >
              <div
                className={`circle two ${menu === "entry" ? "active" : ""}`}
                onClick={() => {
                  if (stocks?.length === 0 && formData.stocks?.length === 0) {
                    alert("Please select stock");
                  } else {
                    setMenu("entry");
                  }
                }}
              >
                2
              </div>
              <div className={`Entry ${menu === "entry" ? "active" : ""}`}>
                Entry
              </div>
            </div>

            <div
              style={{
                height: "100%",
                width: menu === "exist" ? "62%" : 0,
                background: menu === "exist" && "#2d2f94",
              }}
            >
              <div
                className={`circle three ${menu === "exist" ? "active" : ""}`}
              >
                3
              </div>
              <div className={`Exit ${menu === "exist" ? "active" : ""}`}>
                Exit
              </div>
            </div>
            <div
              style={{
                height: "100%",
                width: menu === "review" ? "100%" : 0,
                background: menu === "review" && "#2d2f94",
              }}
            >
              <div
                className={`circle four ${menu === "review" ? "active" : ""}`}
                // onClick={() => setMenu("review")}
              >
                4
              </div>
              <div className={`Review ${menu === "review" ? "active" : ""}`}>
                Review
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StrategyCreateBarComponent;
