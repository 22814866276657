import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { API_BASE_URL, IMAGE_URL } from "../../Config" ;

export const DataContext = createContext() ;

// export const userContentData = () => {
//     return useContext(DataContext) ;
// };

export const ContentDataProvider = ({children}) => {
const [contentData, setContentData] = useState(null) ;

useEffect(() => {

    axios.get(`${API_BASE_URL}/adminpanel/GetStaticContentData`)
    .then((res) => {
        if (res.data.success === true) {
            setContentData(res.data.data[0]);
            const favicon = document.querySelector('link[rel="icon"]');
            if (favicon) {
                favicon.setAttribute("href", `${IMAGE_URL}${res.data.data[0]?.featured_image}`);
            }
        } else {
            console.error('No data found or response is malformed.');
        }
    })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });

}, [])
    return <DataContext.Provider value={contentData}>{children}</DataContext.Provider>;

}
