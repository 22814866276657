import React, { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "../components/Card";
import DeployedBox from "./DeployedBox";
import CreateStrategyPopup from "../components/strategy/CreateStrategyPopup";
import { useNavigate } from "react-router";
import StrategyBox from "./StrategyBox";
import StrategyCreateComonent from "../components/strategy/StrategyCreateComponent";
import ToastMsg from "../components/ToastMsg";
import {
  existStrategyNameApi,
  getAllStrategyApi,
  getStrategyCategoriesApi,
} from "../api/strategyapi";
import { getUser, getActiveAccount } from "../Session";
import { Link, useSearchParams } from "react-router-dom";
import { getDiscoverScannersByIdApi } from "../api/scannerapi";
import SkeletonLoader from "../components/componentCommon/SkeletonLoader";

const strategyCard = [
  {
    id: 1,
    icon: "startegy-icon/scanner-1.png",
    title: "Trend Following",
    subtext: "Make the trend your friend",
    class: "first-card",
    filter: "trend",
  },
  {
    id: 2,
    icon: "startegy-icon/Scanner-2.png",
    title: "Price Action",
    subtext: "Make the trend your friend",
    class: "second-card",
    filter: "price_action",
  },
  {
    id: 3,
    icon: "startegy-icon/Scanner-3.png",
    title: "Mean Reversion",
    subtext: "Make the trend your friend",
    class: "first-card",
    filter: "meanreversion",
  },
  {
    id: 4,
    icon: "startegy-icon/Scanner-4.png",
    title: "Momentum",
    subtext: "Make the trend your friend",
    class: "second-card",
    filter: "momentum",
  },
  {
    id: 5,
    icon: "startegy-icon/Scanner-5.png",
    title: "Miscellaneous",
    subtext: "Make the trend your friend",
    class: "first-card",
    filter: "miscellaneous",
  },
  {
    id: 6,
    icon: "startegy-icon/Scanner-6.png",
    title: "Pivot Point",
    subtext: "Make the trend your friend",
    class: "second-card",
    filter: "pivot_point",
  },
];

const Strategy = () => {
  const navigate = useNavigate();

  const user = getUser();

  const [searchParams, setSearchParams] = useSearchParams();
  const active = searchParams.get("active");
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [createStratgyPage, setCreateStratgyPage] = useState(false);
  const [strategyName, setStrategyName] = useState("");
  const [toastShow, setToastShow] = useState(true);
  const [strategiesList, setStrategyList] = useState([]);
  const [strategiesNameError, setStrategiesNameError] = useState(false);
  const [scanners, setScanners] = useState();
  const [strategyCategories, setStrategyCategories] = useState();
  const [paperDeployed, setPaperDeployed] = useState();
  const [strategiesLoading, setStrategiesLoading] = useState(true);
  const [isDiscoverLoading, setIsDiscoverLoading] = useState(true);
  const [strategies,setStrategies]=useState([])
  useEffect(() => {
    getStrategyCategories();
  }, []);

  

  const getStrategyCategories = async () => {
    const result = await getStrategyCategoriesApi();
    setStrategies(result);
    // if (result) setStrategyCategories(result);
    const updatedResult = result?.map((info) => {
      const card = strategyCard.find((card) => card.id === info.id);
      return card
        ? {
            ...info,
            icon: card.icon,
            subtext: card.subtext,
            class: card.class,
            filter: card.filter,
          }
        : info;
    });

    setStrategyCategories(updatedResult);
    setIsDiscoverLoading(false);
  };

  const handleClickStrategyModal = () => {
    setShowCreatePopup(true);
  };

  const handelClickCreateStrategy = async (name) => {
    const user = getUser();
    const acc = getActiveAccount();
    if (acc != null) {
      const data = await existStrategyNameApi({
        search: name?.trim(),
        user_id: user.id,
      });
      if (data?.data?.length >= 1) {
        setStrategiesNameError(true);
      } else {
        setStrategiesNameError(false);
        setShowCreatePopup(false);
        setCreateStratgyPage(true);
        setStrategyName(name);
      }
    } else {
      alert(
        "To create strategy you need to activate broker account from dashboard"
      );
      navigate("/dashboard");
    }
  };

  const handleClickViewAllStrategy = () => {
    navigate("/strategies/viewall");
  };

  const handelStrategyCard = async () => {
    const user = await getUser();
    const result = await getAllStrategyApi({ user_id: user.id });
    setStrategyList(result);
    setStrategiesLoading(false);
  };

  useEffect(() => {
    handelStrategyCard();
    if (active === null) {
      setShowCreatePopup(false);
    } else {
      setShowCreatePopup(true);
    }
  }, []);

  const memoizedComponent = useMemo(() => {
    return createStratgyPage ? (
      <StrategyCreateComonent
        strategyName={strategyName}
        setCreateStratgyPage={setCreateStratgyPage}
      />
    ) : (
      <section className="mobile-view">
        <div className="container web-cotainer">
          <div className="row">
            <div className="col-lg-9 col-mg-3 col-sm-12 py-3">
              <h2 style={{ color: "#2c308e" }} className="text-center">
                <b>Strategies</b>
              </h2>
              <div className="row">
                <div className="d-flex justify-content-between">
                  <span className="fs-5 fw-6">My Strategies</span>
                  <span
                    className="text-primary pointer-cursor"
                    onClick={handleClickViewAllStrategy}
                  >
                    View all
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="text-muted text-center py-3">
                    <img
                      src="icon-images/strategyIcon.png"
                      alt="Strategies Images"
                      width={"auto"}
                      height={60}
                      className="img-fluid"
                    />
                  </div>
                  <Button
                    variant="primary"
                    className="btn-color w-lg-50 w-100"
                    onClick={handleClickStrategyModal}
                  >
                    Create New Strategy
                  </Button>
                </div>
                <div className="col-lg-8">
                  {/* <div
                    style={{
                      width: "1px",
                      height: "100%",
                      background: "lightgray",
                      // marginTop: "10px"
                    }}
                  ></div> */}
                  <div
                    style={{
                      display: "flex",
                      lineHeight: 2,
                      // height: "100%",
                      width: "100%",
                      overflowX: "auto",
                      // alignItems: "center",
                      justifyContent: "space-around",
                      // paddingLeft: "50%"
                    }}
                  >
                    <div className="container">
                      <div className="row" style={{ flexWrap: "nowrap" }}>
                        {strategiesLoading ? (
                          <SkeletonLoader
                            width="100%"
                            height="200px"
                            borderRadius="8px"
                          />
                        ) : (
                          strategiesList?.map((item) => (
                            <div className="col-4">
                              <StrategyBox strategy={item} />
                            </div>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-5">
                <div className="col-lg-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12 col-12">
                      <h6 className="fw-bold">Discover Strategies</h6>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-sm-6 col-4 ">
                        <h6 className="text-secondary text-start fw">
                          Explore by Category
                        </h6>
                      </div>
                      <div className="col-lg-6 col-sm-6 col-4 d-flex justify-content-end">
                        <Link
                          to="/discover"
                          className="second-text text-end fw text-decoration-none"
                        >
                          View all
                        </Link>
                        {/* <h6 className="second-text text-end fw">View all</h6> */}
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div className="row" id="strategy-cards-tread">
                  {isDiscoverLoading
                    ? Array.from({ length: 6 }).map((_, index) => (
                        <div
                          className="col-lg-4 col-md-4 col-sm-4 p-2 pointer-cursor"
                          key={index}
                        >
                          <SkeletonLoader
                            width="100%"
                            height="200px"
                            borderRadius="8px"
                          />
                        </div>
                      ))
                    : strategyCategories?.map((item) => {
                        return (
                          <Card
                            itemData={item}
                            key={item.id}
                            redirect="/discover?filter="
                          />
                        );
                      })}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-mg-3 col-sm-12">
              <DeployedBox paperDeployed={paperDeployed} />
            </div>
          </div>
        </div>
        <CreateStrategyPopup
          showCreatePopup={showCreatePopup}
          handelClickCreateStrategy={handelClickCreateStrategy}
          setShowCreatePopup={setShowCreatePopup}
          nameError={strategiesNameError}
          setError={setStrategiesNameError}
        />
        {/* <KnowSureThing /> */}
        {/* <ToastMsg toastShow={toastShow} setToastShow={setToastShow} /> */}
      </section>
    );
  }, [
    createStratgyPage,
    showCreatePopup,
    toastShow,
    strategiesList,
    strategiesNameError,
  ]); // Dependency array

  return <>{memoizedComponent}</>;
};

export default Strategy;
