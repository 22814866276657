import React from "react";

const Number_I = ({
  index,
  instanceData,
  instanceName,
  handleRemoveButtonClick,
  setShowNumber,
  formData,
  item,
  setShowCandleModal,
  getIndex,
  instance,
  exit,
  isShowingAddButton,
  handleEdit,
  handleAdd,
}) => {
  let data = item
    ? item
    : instanceData?.body
    ? instanceData?.body[index]
    : instanceData;
  return (
    <div className="d-flex">
      {isShowingAddButton ? (
        <button
        className="stratergyAddBtn"
          onClick={() => {
            handleAdd(instanceData, index);
          }}
        >
          Add
        </button>
      ) : (
        <>
          <button
            onClick={() => {
              formData.selectedOption1 = data?.name;
              formData.clickedFunction = instanceName;
              setShowNumber(true);
              getIndex(index, instance);
            }}
            className="buttonStyle"
          >
            {exit
              ? `${data?.name} (${`${data?.e_period}`})`
              : `${data?.name} (${`${data?.period}`})`}
            <i
              onClick={(e) => {
                e.stopPropagation();
                if(instance === "body1" || instance === "body2"){
                  handleRemoveButtonClick(instanceName, index, instance);
                }else {
                  handleEdit(instanceData, index);
                }
              }}
              className={`fa-solid fa-xmark iconContainer`}
              style={{
                color: "#7AB1DD",
              }}
            ></i>
            &ensp;
          </button>
        </>
      )}
    </div>
  );
};

export default Number_I;
