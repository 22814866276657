import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getUser } from "../../Session";
import { existScannerNameApi } from "../../api/scannerapi";

const ScannerModal = ({
  showCreatePopup,
  handelClickCreateScanner,
  setShowCreatePopup,
  nameError,
  setError,
}) => {

  const [showerror, setShowError] = useState();
  const [scannnerName, setScannnerName] = useState();
  const [isDisable, setIsDisable] = useState(false);

  const handleClose = () => {
    setShowCreatePopup(false);
  };
  const handelChange = (e) => {
    const value = e.target.value;
    setScannnerName(value);
    handleClickCreateScanner(value);
  };

  const handleClickCreateScanner = async (name) => {
    const user = getUser();
    const data = await existScannerNameApi({
      scanner_name: name,
      user_id: user.id,
    });
    if (data === 1) {
      setShowError(true);
    } else {
      setShowError(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (!showerror) handelClickCreateScanner(scannnerName);
      else handelClickCreateScanner();
    }
  };

  return (
    <>
      <Modal
        show={showCreatePopup}
        onHide={handleClose}
        animation={false}
        centered
      >
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center"
        >
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Create new Scanner</Form.Label>
              <Form.Control
                type="text"
                placeholder="Scanner Name"
                value={scannnerName}
                onChange={handelChange}
                autoComplete="off"
                onKeyDown={handleKeyPress}
              />
              {nameError ? (
                <span className="text-error">Name cannot be empty</span>
              ) : showerror ? (
                <span className="text-error">Name already exist</span>
              ) : null}
            </Form.Group>
            <Button
              className="btn-color"
              variant="primary"
              disabled={isDisable}
              onClick={() => {
                if (!showerror) handelClickCreateScanner(scannnerName);
                else handelClickCreateScanner();
              }}
              size="sm"
            >
              Create
            </Button>
            <br />
            <Button variant="default" onClick={handleClose} size="sm">
              Cancel
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScannerModal;
