import React from "react";

const EMA_I = ({
  index,
  instanceData,
  instanceName,
  handleRemoveButtonClick,
  setShowEMA,
  formData,
  item,
  setShowCandleModal,
  getIndex,
  instance,
  exit,
  isShowingAddButton,
  handleEdit,
  handleAdd,
}) => {
  let data = item
    ? item
    : instanceData?.body
    ? instanceData?.body[index]
    : instanceData;

  return (
    <div className="d-flex">
      {isShowingAddButton ? (
        <button
        className="stratergyAddBtn" 
          onClick={() => {
            handleAdd(instanceData, index);
          }}
        >
          Add
        </button>
      ) : (
        <>
          <div
            className="hover-underline"
            style={{ cursor: "pointer" }}
            onClick={() => {
              formData.selectedOption1 = data?.name;
              formData.clickedFunction = instanceName;
              setShowCandleModal(true);
              getIndex(index, instance);
            }}
          >
            {data?.candleIntervalId}
          </div>
          &ensp;
          <button
            className="buttonStyle"
            onClick={() => {
              formData.selectedOption1 = data?.name;
              formData.clickedFunction = instanceName
                ? instanceName
                : item.instance_name;
              setShowEMA(true);
              getIndex(index, instance);
            }}
          >
            {exit
              ? `${
                  item ? item.name : instanceData?.name
                } (${`${data.e_eField},${data.e_ePeriod},${data.e_eOffset}`})`
              : `${
                  item ? item.name : instanceData?.name
                } (${`${data.field},${data.period},${data.offset}`})`}
            <i
              onClick={(e) => {
                e.stopPropagation();
                if(instance === "body1" || instance === "body2"){
                  handleRemoveButtonClick(instanceName, index, instance);
                }else {
                  handleEdit(instanceData, index);
                }
              }}
              className={`fa-solid fa-xmark iconContainer`}
              style={{
                color: "#7AB1DD",
              }}
            ></i>
            &ensp;
          </button>
        </>
      )}
    </div>
  );
};

export default EMA_I;
