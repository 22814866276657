import React, { useEffect, useState, useRef } from "react";
import { Button, Card, Form, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
  deleteStrategyApi,
  deployStrategyApi,
  getAllStrategyApi,
} from "../../api/strategyapi";
import { getUser } from "../../Session";
import moment from "moment";
import DeleteStrategyModal from "./DeleteStrategyModel";
import CreateDeployPopup from "./CreateDeployPopup";
import StrategyDeploySuccessModal from "./StrategyDeploySuccessModal";
import Note from "../Note";
import { getSingleSavedStrategyData } from "../../api/api";
import SkeletonLoader from "../componentCommon/SkeletonLoader";
import Pagination from "../componentCommon/Pagination";
import { ResetList, SortReset } from "../componentCommon/SortReset";

const StrategyAll = () => {
  const navigate = useNavigate();
  const [strategyData, setStrategyData] = useState([]);
  const [optionMenu, setOptionMenu] = useState(
    Array(strategyData?.length).fill(false)
  );
  const [strategyId, setStrategyId] = useState();
  const [show, setShow] = useState(false);
  const [openDeployModal, setOpentDeployModal] = useState(false);
  const [strategyItemData, setStrategyItemData] = useState();
  const [termCondition, settermCondition] = useState(false);
  const [deployedSuccess, setDeployedSuccess] = useState(false);
  const [trading_type, set_trading_Type] = useState(1);
  const [search, searchtext] = useState("");
  const [strategyDatafilter, setStrategyDatafilter] = useState([]);
  const [hoverEllipse, setHoverEllipse] = useState(false);
  const dropdownRefs = useRef([]);
  const [stratergyLoading, setStratergyLoading] = useState(true);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(strategyData?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = strategyDatafilter.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  // search funcationality //
   const [searchName, setSearchName] = useState("") ;
  // sort functionality //
  const [isReset, setIsReset] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  // useEffect(() => {
  //   setStrategyDatafilter(strategyData);
  // }, [strategyData]);

  const handleConfirmDelete = (id) => {
    setShow(true);
    setStrategyId(id);
  };

  const handleConfirm = async () => {
    const data = await deleteStrategyApi({ strategy_id: strategyId });
    handleGetAllStrategy();
    handleClose();
    if (data) alert("Strategy Deleted!");
  };

  const handleMenuShow = () => {
    setOptionMenu(!optionMenu);
  };
  const toggleMenu = (index) => {
    const updatedMenus = [...optionMenu];
    updatedMenus[index] = !updatedMenus[index];
    setOptionMenu(updatedMenus);
  };

  const handleGetAllStrategy = async () => {
    const user = getUser();
    const strategyData = await getAllStrategyApi({ user_id: user.id });
    setStrategyData(strategyData);
    setStrategyDatafilter(strategyData);
    setStratergyLoading(false);
  };

  const handleClickModalOpen = (item) => {
    setOpentDeployModal(true);
    setStrategyItemData(item);
    setStrategyId(item.id);
  };

  const handleDeployed = async (isChecked) => {
    if (isChecked) {
      const result = await deployStrategyApi({
        strategy_id: strategyId,
        trading_type: trading_type,
      });
      setOpentDeployModal(false);
      setStrategyItemData();
      setStrategyId();
      settermCondition(false);
      setDeployedSuccess(true);
      handleGetAllStrategy();
    } else {
      settermCondition(true);
    }
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setOptionMenu(Array(strategyData?.length).fill(false));
    }
  };

  useEffect(() => {
    handleGetAllStrategy();
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlesearch = (e) => {
    const text = e.target.value;
    setSearchName(text)
    searchtext(text);
    if (text === "") {
      setStrategyDatafilter(strategyData);
      setCurrentPage(1);
    } else {
      const filteredData = strategyData?.filter((item) =>
        item?.strategy_name?.toLowerCase().includes(text.toLowerCase())
      );
      setStrategyDatafilter(filteredData);
      setCurrentPage(1);
    }
  };

  const getSingleStrategyData = async (id) => {
    const user = await getUser();
    const strategyData = await getSingleSavedStrategyData({
      user_id: user.id,
      strategy_id: id,
    });
    if (strategyData?.data[0]) {
      strategyData.data[0].entire_object.strategy_id =
        strategyData?.data[0]?.strategy_id;
      strategyData.data[0].entire_object.strategy_name =
        strategyData?.data[0]?.strategy_name;
    }
    navigate("/createstrategies", {
      state: {
        existingData: strategyData?.data[0]?.entire_object,
      },
    });
  };

  const handleClickReset = () => {
    setIsReset(!isReset);
    setSearchName("") ;
    handleGetAllStrategy();
    setCurrentPage(1);
  };
  return (
    <>
      <section className="mobile-view">
        <div className="container web-cotainer">
          <Note />
          <div className="row pt-3 title-section">
            <div className="d-flex justify-content-between">
              <span className="fs-3 fw-bold">
                <i
                  className="fa fa-chevron-left chev fs-5 pointer-cursor"
                  onClick={() => navigate("/strategies")}
                ></i>
                &nbsp; My Strategies
              </span>
              <span>
                <Button
                  onClick={() => {
                    navigate("/strategies?active=create_strategies");
                  }}
                  className="btn-color"
                >
                  Create New Strategy
                </Button>
              </span>
            </div>
          </div>
          <div className="row pt-3">
            <div className="d-flex justify-content-between align-items-center searchFilterDiv">
              <div>
                <div className="custom-wrapper">
                  <input
                    type="text"
                    placeholder="Search for strategy"
                    id="myInput"
                    className="form-control w-100"
                    value={searchName}
                    onChange={handlesearch}
                  />
                  <i class="fa fa-search input-icon ms-1"></i>
                </div>
              </div>

              <div className="d-flex">
                <div className="mt-3 me-2">
                  <SortReset
                    dataList={strategyDatafilter}
                    setDataList={setStrategyDatafilter}
                    isReset={isReset}
                    isScanner=""
                  />
                </div>
                <div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                  />
                </div>

                <div className="mt-2">
                  <ResetList handleClickReset={handleClickReset} />
                </div>
              </div>
            </div>
          </div>
          {stratergyLoading
            ? Array.from({ length: 3 }).map((_, i) => {
                return (
                  <SkeletonLoader
                    width="100%"
                    height="120px"
                    borderRadius="8px"
                  />
                );
              })
            : currentItems?.map((item, index) => (
                <div
                  className="pt-4 startegy-all-details"
                  id="startegy-all-details"
                  key={index}
                >
                  <Card>
                    <Card.Body>
                      <div
                        className="row pointer-cursor"
                        onClick={() => {
                          if (!hoverEllipse) {
                            navigate(`/strategies/${item?.strategy_id}`);
                          }
                        }}
                      >
                        <div className="col-lg-12 col-mg-12">
                          <div className="d-flex justify-content-between">
                            <div>
                              <span className="fw-bold">
                                {item?.strategy_name}
                              </span>
                              &nbsp;&nbsp;
                              <span style={{ fontSize: "12px" }}>
                                {moment(
                                  item?.strategy_details?.created_at
                                ).format("DD-MM-YYYY")}
                              </span>
                            </div>
                            <div
                              className="dropdown"
                              ref={(ref) => (dropdownRefs.current[index] = ref)}
                              onMouseEnter={() => setHoverEllipse(true)}
                              onMouseLeave={() => setHoverEllipse(false)}
                            >
                              <i
                                className="fa fa-ellipsis-v text-muted dropdown-button"
                                onClick={() => toggleMenu(index)}
                              ></i>
                              {optionMenu[index] && (
                                <ListGroup className="dropdown-menu">
                                  <ListGroup.Item
                                    onClick={() =>
                                      getSingleStrategyData(item?.strategy_id)
                                    }
                                  >
                                    <i className="fa-solid fa-pen"></i>
                                    &nbsp;&nbsp; Edit
                                  </ListGroup.Item>
                                  <ListGroup.Item>
                                    <i className="fa-solid fa-copy"></i>
                                    &nbsp;&nbsp; Copy
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    onClick={() =>
                                      handleConfirmDelete(item?.strategy_id)
                                    }
                                    className="text-danger"
                                  >
                                    <i className="fa-solid fa-trash"></i>
                                    &nbsp;&nbsp; Delete
                                  </ListGroup.Item>
                                </ListGroup>
                              )}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              navigate(`/strategies/${item?.strategy_id}`);
                            }}
                          >
                            <span className="">
                              {item?.strategy_details.position_id === 1
                                ? "BUY"
                                : "Sell"}{" "}
                              {item?.strategy_details.quantity > 0 &&
                                item?.strategy_details.quantity}{" "}
                              share when
                              {(() => {
                                if (item.strategy_details) {
                                  const data = JSON.parse(
                                    item.strategy_details?.entire_object
                                  );

                                  let functiontext = "";
                                  if (data?.original_entry_array) {
                                    // If original_entry_array is available, process it
                                    const originalEntryArray =
                                      data?.original_entry_array;
                                    const candleInterval =
                                      data?.candle_interval_id;

                                    const functionDetails = originalEntryArray
                                      .map((func) => {
                                        let detail = func?.name;
                                        if (
                                          func?.period !== undefined &&
                                          func?.period !== null
                                        ) {
                                          const period =
                                            func?.period !== null
                                              ? func?.period
                                              : "";
                                          const offset =
                                            func?.offset !== undefined
                                              ? func?.offset
                                              : "";
                                          detail += ` (${period}`;
                                          if (offset !== "") {
                                            detail += `,${offset}`;
                                          }
                                          detail += ")";
                                        }
                                        return detail;
                                      })
                                      .filter(
                                        (detail) =>
                                          detail !== "undefined" &&
                                          detail?.indexOf("undefined") === -1
                                      );

                                    if (functionDetails.length > 0) {
                                      // Adjusted to insert "higher than" only once
                                      const higherThanIndex =
                                        functionDetails?.findIndex(
                                          (detail) => detail === "higher than"
                                        );
                                      if (higherThanIndex !== -1) {
                                        const beforeHigherThan =
                                          functionDetails?.slice(
                                            0,
                                            higherThanIndex
                                          );
                                        const afterHigherThan =
                                          functionDetails?.slice(
                                            higherThanIndex + 1
                                          );
                                        functiontext = `${candleInterval}   ${beforeHigherThan?.join(
                                          " "
                                        )} higher than ${afterHigherThan?.join(
                                          " "
                                        )}`;
                                      } else {
                                        functiontext = `${candleInterval}   ${functionDetails.join(
                                          " "
                                        )}`;
                                      }
                                    }
                                  }
                                  data.functionText1 =
                                    data?.functionText1?.replace(/\s+$/, "");
                                  var functiontext1 = data?.functionText1
                                    ? data?.functionText1
                                    : functiontext;
                                }
                                // bg-danger
                                return (
                                  <span
                                    className="function-text ps-1"
                                    style={{ fontWeight: "500" }}
                                  >
                                    {functiontext1}
                                  </span>
                                );
                              })()}
                              &nbsp;at{" "}
                              {item.strategy_details?.candle_interval_id} candle
                              interval using candlestick chart. Enter trade
                              between {item.strategy_details?.start_time} to{" "}
                              {item.strategy_details?.end_time} and{" "}
                              {item.strategy_details?.position_id === 1
                                ? "Sell"
                                : "Buy"}{" "}
                              {item.strategy_details?.quantity} share at stop
                              loss % of {item.strategy_details?.stop_loss} or
                              target profit % of{" "}
                              {item.strategy_details?.target_profit}
                              &nbsp;at{" "}
                              {item.strategy_details?.candle_interval_id} candle
                              interval using candlestick chart.
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-4 col-mg-4">
                          <div className="strategy-graph-data">
                            {item.is_running === 0 &&
                            item.is_completed === 0 ? (
                              <span>Backtest is running</span>
                            ) : item.is_completed === 1 ? (
                              <span>Backtest is completed</span>
                            ) : (
                              item.is_running === 1 && (
                                <span>Backtest is stopped</span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
        </div>
        <DeleteStrategyModal
          show={show}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
        <CreateDeployPopup
          openDeployModal={openDeployModal}
          setOpentDeployModal={setOpentDeployModal}
          strategy={strategyItemData}
          termCondition={termCondition}
          settermCondition={settermCondition}
          handleDeployed={handleDeployed}
          trading_type={trading_type}
          set_trading_Type={set_trading_Type}
        />
        <StrategyDeploySuccessModal
          deployedSuccess={deployedSuccess}
          setDeployedSuccess={setDeployedSuccess}
        />
      </section>
    </>
  );
};

export default StrategyAll;
