import axios from "axios";
import { API_BASE_URL } from "../Config";

// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

export const zerodhaGetAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetAccount`,
      data
    );
    if (response.data.success) {
      return response.data.accountDetails;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Zerroda Error : ", error);
  }
};

export const zerodhaAddAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaAddAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const zerodhaEditAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaEditAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};

export const zerodhaDeleteAccount = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaDeleteAccount`,
      data
    );
    if (response.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaLogin = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaLogin`,
      data
    );
    if (response.data.success) {
      return response.data.loginURL;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaSessionGenrated = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGenerateSession`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaMargin = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetMargin`,
      data
    );
    if (response.data.success) {
      return response.data.marginDetails;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getMarginandFO = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/getMargins`,
      data
    );
    if (response.data.success) {
      // console.log("++===", response.data);
      const r = response?.data;
      const foavailable_balance = r.foMargins.available.live_balance;
      const foMargins = r.foMargins.net;
      const foAcc = r.foMargins.available.adhoc_margin;

      const equityavailable_balance = r.equityMargins.available.live_balance;
      const equityMargins = r.equityMargins.net;
      const equityAcc = r.equityMargins.available.adhoc_margin;

      const positions = r.positions.net;
      const holdings = r.holdings;
      return [
        [
          {
            available_bal: foavailable_balance,
            margin: foMargins,
            account: foAcc,
          },
        ],
        [
          {
            available_bal: equityavailable_balance,
            margin: equityMargins,
            account: equityAcc,
          },
        ],
        positions,
        holdings,
      ];
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};
export const getZerodhaHoldings = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetHoldings`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaPositions = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetPositions`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const getZerodhaOrders = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetOrders`,
      data
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error  : ", error);
  }
};

export const zerodhaGetAccountApi = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/ZerodhaGetAccount`,
      data
    );
    // console.log("WWEE", response.data);
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
