import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const CompartorModal = ({
  showSMA,
  setIsShow,
  type,
  handleModalData,
  mainData,
  clickedFunction,
}) => {
  let data = mainData?.indicators
    ? mainData?.indicators?.[clickedFunction]
    : undefined;
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    // Update formData when data changes
    setFormData(data);
  }, [data]);
  const handleClose = () => {
    setIsShow(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleDone = () => {
    handleModalData(formData);
    setIsShow(false);
  };

  return (
    <>
      <Modal show={showSMA} onHide={handleClose} animation={false} centered>
        <Modal.Body
          id="create-strategy-modal"
          className="d-flex align-items-center justify-content-center"
        >
          <Form>
            <h5>Comparators</h5>
            <br />
            <>
              {type === "Exit" ? (
                <Form.Select
                  aria-label="Default select example"
                  name="exit_condition"
                  // value={exit_condition}
                  onChange={handelChange}
                >
                  <option>select comparator</option>
                  <option value="higher than">higher than</option>
                  <option value="lower than">lower than</option>
                  <option value="higher than equal to">
                    higher than equal to
                  </option>
                  <option value="lower than equal to">
                    lower than equal to
                  </option>
                  <option value="crosses above">crosses above</option>
                  <option value="up by">up by</option>
                  <option value="down by">down by</option>
                  <option value="crossing below">crosses below</option>
                </Form.Select>
              ) : (
                <Form.Select
                  aria-label="Default select example"
                  name="name"
                  value={formData?.name}
                  onChange={handelChange}
                >
                  <option>select comparator</option>
                  <option value="higher than">higher than</option>
                  <option value="lower than">lower than</option>
                  <option value="higher than equal to">
                    higher than equal to
                  </option>
                  <option value="lower than equal to">
                    lower than equal to
                  </option>
                  <option value="crosses above">crosses above</option>
                  <option value="up by">up by</option>
                  <option value="down by">down by</option>
                  <option value="crossing below">crosses below</option>
                </Form.Select>
              )}
            </>

            <br />
            <Button variant="primary" onClick={handleDone}>
              Done
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CompartorModal;
