import React, { useState } from "react";
import { Form, Modal, Row ,Col ,Button } from "react-bootstrap";
import { deleteprofileimage } from "../../api/profileapi";
import AlertShow from "../../mcomponents/AlertShow";
const UploadPhotoModal = ({uploadModalShow ,setUploadModalShow ,setImages,setSelectedFile,id,setRemoveprofilepic,userData}) => {
  const [showAlert, setShowAlert] = useState(false);
 const [closeAlert, setCloseAlert] = useState(false);
 const [alertMsg, setAlertMsg] = useState("");
 const [severity, setSeverity] = useState("");
const [alertConfirm,setalertConfirm]=useState(false);
    const handleClose = () => {
        setUploadModalShow(false)
    }
    const handleAlertMsg = (error_type, msg) => {
      setShowAlert(true);
      setSeverity(error_type);
      setAlertMsg(msg);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    };
    const handleCloseAlert = () => {
      setShowAlert(false);
      // setIsConfirmation(false);
    };
    const removephoto=async()=>{
      // setAlertMsg("Do you want to change the profile pic?");
      // setSeverity("warning");
      // isConfirmation(true); // Set to true for confirmation dialog
      // setShowAlert(true);
    // if(isConfirmation===true)
    // {
    // var msg="Do you want to remove profile picture?";
    // const error_color = "";
    // handleAlertMsg(error_color, msg);

    handleClose();
      if(userData.profile_image==="/images/avatar-placeholder.png")
        {
            var msg="Already removed";
            const error_color = "warning";
            handleAlertMsg(error_color, msg);
            handleClose();
        }
        else
        {
            const result=await deleteprofileimage({id:id});
            // console.log("imgprof",result.profile_image);
             setRemoveprofilepic(result?.profile_image)
              handleClose();
        }
    // }
    // else
    // {
      // handleClose();
    // }
       
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0]; 
        if(event.target.files && file){
          // console.log("filesss",file);
          setSelectedFile(file);
          setImages(URL.createObjectURL(file));
        }
        setUploadModalShow(false);
      };
     
    return(
        <>
        <Modal show={uploadModalShow} onHide={handleClose} animation={false} centered  dialogClassName="upload-modal">
        <Modal.Header className="pt-4  text-center" style={{display : "block"}}>
          <Modal.Title className="fs-5 px-4">Change Profile photo</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0 text-center">
          <Form className="py-0 text-center">
            <Row  className="justify-content-md-center">
              <Col className="d-flex justify-content-center">
              <Form.Label className="text-center fs-6 text-primary" 
              htmlFor="filePicker" style={{cursor:"pointer"}}>
                  Choose profile picture
                </Form.Label>
                <Form.Control onChange={handleFileChange}  id="filePicker" style={{visibility:"hidden",display:"none"}} type={"file"}/>
                {/* <Form.Control type="file" accept=".txt" className="text-center text-primary fs-6" /> */}
              </Col>
            </Row>
            {userData.profile_image==="/images/avatar-placeholder.png" ? <div></div> :  <Row  className="justify-content-md-center">
              <Col className="d-flex justify-content-center">
                <Form.Control className="text-center text-danger fs-6" 
                onClick={removephoto} plaintext value="Remove Photo" style={{cursor:"pointer"}} />
              </Col>
            </Row>}
           
            <Row  className="justify-content-md-center">
              <Col className="d-flex justify-content-center">
                <Form.Control className="text-center fs-6" plaintext value="Cancel" onClick={handleClose} style={{cursor:"pointer"}}/>
              </Col>
            </Row>
          </Form>
          <br/>
        </Modal.Body>
      </Modal>
      <AlertShow
             alertShowHide={showAlert}
             alertClose={handleCloseAlert}
             setalertMsg={alertMsg}
            //  alertConfirm={isConfirmation ?  removePicture : null}
             severity={severity}
            />
        </>
    )
}

export default UploadPhotoModal;