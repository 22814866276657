import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./Routes";
import Layout from "./components/Layout";
import { ContentDataProvider } from "./components/context/manageContent";
// import { useTheme } from "./ThemeContext";
function App() {
  return (
    <ContentDataProvider>
      <AppRoutes />
    </ContentDataProvider>
  );
}

export default App;
