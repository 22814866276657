import axios from "axios";
import { API_BASE_URL } from "../Config";

// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = "http://192.168.0.128:8008/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "http://3.111.147.17:8008/api/v1"; // live URL
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";
// const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1";

export const freeSubscribeApi = async (user) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/sales/AddFreePack`,
      user
    );
    if (responce.data.success) {
      return responce.data.success;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};
// http://65.2.111.247:8008/api/v1/dashboard/demo_phoneno

export const Demophonenumber = async (id, phone) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/dashboard/demo_phoneno`,
      { user_id: id, phone: phone }
    );
    if (response.data.success) {
      return response.data.result;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const primeSubscribeApi = async () => {
  try {
    const responce = await axios.post(`${API_BASE_URL}/`);
  } catch (error) {
    console.log("Error :", error);
  }
};

export const getActivePlanApi = async (user) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/users/get-active-plan`,
      user
    );
    if (responce.data.success) {
      return responce.data.activePlan;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const requestPaymentApi = async (data) => {
  try {
    const responce = await axios.post(
      `${API_BASE_URL}/sales/request-payment`,
      data
    );
    if (responce.data.success) {
      return responce.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error :", error);
  }
};

export const paymenHandler = async (data) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/sales/confirm`, data);
    if (response.data.success) {
      return response.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error : ", error);
  }
};
export const getAllAccounts = async (user) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/GetAllAccounts`,
      user
    );
    if (response.data.success) {
      return response;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error in getting all demat acc", error);
  }
};
// https://dalrotibackend.shrikanttapkeer.com/api/v1/technical/get-nifty50TopGainers
export const getTopMovers = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/technical/get-nifty50TopGainers`,
      data
    );
    if (response.data.success) {
      return response.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error in getting all demat acc", error);
  }
};
export const GetBlogs = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/adminpanel/GetBlogLists`);
    if (response.data.success) {
      return response?.data.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};
export const checkzerodhaacc = async (data) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/demataccount/checkZerodhActive`,
      data
    );
    if (response.data) {
      return response?.data;
    } else {
      return false;
    }
  } catch (error) {
    console.log("Error", error);
  }
};
