import React, { useCallback, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  VerifyOtpAfterSignUp,
  checkExistEmail,
  checkExistPhoneNo,
  expireOtpApi,
  genrateOtp,
  sendOtp,
  signUpUser,
} from "../api/api";
import Otp from "../components/Otp";

import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { RecaptchaVerifier } from "firebase/auth";
import { auth } from "../firebase.config";
function Signup() {
  const [userData, setUserData] = useState({
    user_name: "",
    email: "",
    phone: "",
    birthday: "",
    birthmonth: "",
    birthyear: "",
    password: "",
  });
  const [userErrors, setUserErrors] = useState({
    firstNameError: "",
    lastNameError: "",
    phoneNoError: "",
    emailError: "",
    dobError: "",
  });
  const [emailErrorMsg, setEmailErrorMsg] = useState();
  const [phoneErrorMsg, setPhoneErrorMsg] = useState();
  const [otpPage, setOptPage] = useState(false);
  const [otp, setOpt] = useState();
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]);
  const [userOtp, setUserOtp] = useState();
  const [singUpBtn, setSignUpBtn] = useState(false);
  const [expireOtpMsg, setExpireOtpMsg] = useState();
  const [verifyotp, setVerifyotp] = useState(false);
  const [sendotpclick, setSendotpclick] = useState(false);
  const [confirmResult, setConfirmResult] = useState();
  const [timer, setTimer] = useState(60);
  const [resendOtp, setResendOtp] = useState(false);
  const {
    firstName,
    phoneNo,
    email,
    password,
    birthday,
    birthmonth,
    birthyear,
  } = userData;

  const {
    firstNameError,
    lastNameError,
    phoneNoError,
    emailError,
    dobError,
    bankNameError,
    accountHolderNameError,
    accountNoError,
    branchNameError,
    ifscCodeError,
  } = userErrors;

  const navigate = useNavigate();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        // console.log("prevTimer",prevTimer);
        if (prevTimer === 0) {
          clearInterval(intervalId);
          handleExipreOtp();
          // setBtnShow(false);
          // onOtpDigitsChange(otpDigits,0);
          return 0; // Clear the interval when the timer reaches 0
          // You can perform additional actions when the timer expires
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [timer]); // Empty dependency array ensures that the effect runs only once on mount

  const areAllIsErrorsTrue = Object.values(userErrors).every(
    (error) => error === false && typeof error === "boolean"
  );

  const handleCheckValidation = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value === "") {
      name === "email" &&
        setEmailErrorMsg("Please Enter Valid Email Address..");
      name === "phoneNo" &&
        setEmailErrorMsg("Please Enter Valid Mobile Number..");
      setUserErrors({ ...userErrors, [name + "Error"]: true });
      setUserData({ ...userData, [name]: "" });
    } else {
      setUserErrors({ ...userErrors, [name + "Error"]: false });
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleBlurCheckValidEmail = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setUserErrors({ ...userErrors, [name + "Error"]: false });
      setUserData({ ...userData, [name]: value });
      const isExistEmail = await checkExistEmail(value);
      if (isExistEmail) {
        setUserErrors({ ...userErrors, [name + "Error"]: true });
        setUserData({ ...userData, [name]: "" });
        setEmailErrorMsg("Email Address Already Exist..");
      }
    } else {
      setUserErrors({ ...userErrors, [name + "Error"]: true });
      setUserData({ ...userData, [name]: "" });
      setEmailErrorMsg("");
    }
  };

  const handelBlurcheckValidPhone = async (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (!/^\d{10}$/.test(value)) {
      setUserErrors({ ...userErrors, [name + "Error"]: true });
      setUserData({ ...userData, [name]: "" });
    } else {
      setUserErrors({ ...userErrors, [name + "Error"]: false });
      setUserData({ ...userData, [name]: value });
      const isExistPhoneNo = await checkExistPhoneNo(value);
      if (isExistPhoneNo) {
        setUserErrors({ ...userErrors, [name + "Error"]: true });
        setUserData({ ...userData, [name]: "" });
        setPhoneErrorMsg("Mobile Number Already Exist..");
      }
    }
  };
  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => {
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
        auth
      );
    }
  }

  function onSignup() {
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const options = {
      expiration: 180,
    };
    setOptPage(true);
    const countryCode = "+91";
    const formatPh1 = countryCode + phoneNo;
    signInWithPhoneNumber(auth, formatPh1, appVerifier, options)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setConfirmResult(confirmationResult);
        alert('OTP sent successfully!');
        setTimer(180);
        setOptPage(true);
        setSendotpclick(true);
        setExpireOtpMsg("");
      })
      .catch((error) => {
        console.log("PHONEERROR", error);
      });
    setSendotpclick(true);
  }

  const getresult = async () => {
    if (confirmResult && otpDigits.length == 6) {
      const abcRes = await abc(confirmResult, otpDigits);
      if (abcRes == true) {
        setVerifyotp(true);
      } else {
        setExpireOtpMsg("Please enter correct otp");
      }
    } else {
      console.log("Result of otp");
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formattedDob = birthyear + "-" + birthmonth + "-" + birthday;
      if (!firstName || !email || !phoneNo || !formattedDob) {
        alert("Please enter all fields");
        return;
      }
      const userData = {
        user_name: firstName,
        email: email,
        phone: phoneNo,
        birth_date: formattedDob,
        password: 1234,
      };
      const responce = await signUpUser(userData);

      if (responce) {
        alert("Account created successfully");
        navigate("/login");
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const handleOtpDigitsChange = (
    newOtpDigits,
    isVisible = null,
    isTimer = null
  ) => {
    const numberArray = newOtpDigits.map((str) =>
      str === "" ? null : parseInt(str, 10)
    );
    const concatenatedString = numberArray.join("");
    setOtpDigits(newOtpDigits);
    if (concatenatedString.length === 6 && isVisible === "" && isTimer !== 0) {
      setUserOtp(concatenatedString);
      setSignUpBtn(true);
      setExpireOtpMsg("");
    } else {
      setSignUpBtn(false);
      // setExpireOtpMsg("Your Otp is expired please click on resend button");
    }
    if (isTimer === 0 || isVisible !== "") {
      setExpireOtpMsg("Your Otp is expired please click on resend button");
    }
  };

  const abc = async (confirm123, code) => {
    try {
      const otpCode = code.join("");
      console.log("Inside confirm try", confirm123, otpCode);
      await confirm123.confirm(otpCode);
      return true;
    } catch (error) {
      if (error.code === "auth/invalid-verification-code") {
        console.log("Invalid verification code.");
      } else if (error.code === "auth/code-expired") {
        console.log("Verification code has expired.");
      } else {
        console.log(
          "Something went wrong while confirming OTP:",
          error.message
        );
      }
      return false;
    }
  };

  const handleClickResendOtp = async () => {
    try {
      const otp = await genrateOtp(phoneNo);
      setOpt(otp);
      setExpireOtpMsg("");
    } catch (error) {
      console.log("Error :", error);
    }
  };

  const handleExipreOtp = async () => {
    try {
      const expireOtpResponce = await expireOtpApi(phoneNo, userOtp);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const isMobileNumber = (value) => {
    return /^\d{10}$/.test(value);
  };

  return (
    <>
      {/* {!otpPage ? ( */}
      <section>
        <div className="container-fluid ">
          <div className="py-5">
            <div className="row py-5">
              <div className="col-12 col-lg-6 ps-5">
                <h1
                  className="pt-2"
                  style={{ color: "#2c3092", fontSize: "54px" }}
                >
                  Sign up to access <br></br>
                  <span className="fw-bolder">ProductName</span>
                </h1>
                <div className="ml-lg-3">
                  <img
                    src="join.png"
                    className="pt-4 ml-lg-5 join-img"
                    alt="joinimage"
                  ></img>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="ps-5">
                  <div className="d-flex align-items-center my-1">
                    <img
                      src="chevron.png"
                      className="chevron-img"
                      alt="chevron"
                    ></img>
                    <h4
                      className="fw-bolder ps-2 mb-0"
                      style={{ color: "#6c6db3" }}
                    >
                      Sign Up
                    </h4>
                  </div>
                  {/* <form> */}
                  <input
                    type="text"
                    onChange={handleCheckValidation}
                    name="firstName"
                    value={firstName}
                    placeholder="Username"
                    className="pt-0 sign-up-input"
                  ></input>
                  <span className="text-error">
                    {firstNameError && "Please Enter Your First Name.."}
                  </span>
                  {/* <input
                      type="text"
                      name="lastName"
                      placeholder="Last name"
                      onChange={handleCheckValidation}
                      value={lastName}
                      className="pt-0 sign-up-input"
                    ></input>
                    <span className="text-error">{lastNameError && ("Please Enter Your Last Name..")}</span> */}
                  <input
                    type="tel"
                    placeholder="Phone"
                    onChange={handleCheckValidation}
                    onBlur={handelBlurcheckValidPhone}
                    name="phoneNo"
                    maxLength={10}
                    value={phoneNo}
                    className="pt-0 sign-up-input"
                  ></input>
                  {verifyotp == false ? (
                    isMobileNumber(phoneNo) && (
                      <>
                        <div className="text-end w-75 cursor">
                          <span
                            className=""
                            //  onClick={sendotp}
                            onClick={onSignup}
                            onMouseOver={(e) => {
                              e.target.style.color = "#ee5e8f"; // Change the color on hover
                            }}
                            onMouseOut={(e) => {
                              e.target.style.color = "black"; // Reset the color when not hovering
                            }}
                          >
                            send OTP
                          </span>
                          {/* <button onClick={getresult}>confirm</button> */}
                          {sendotpclick === true && (
                            <Otp
                              onOtpDigitsChange={handleOtpDigitsChange}
                              otpDigits={otpDigits}
                              //  handleVerfiyOtp = {handleVerfiyOtp}
                              handleVerfiyOtp={getresult}
                              handleExipreOtp={handleExipreOtp}
                              singUpBtn={singUpBtn}
                              setBtnShow={setSignUpBtn}
                              expireOtpMsg={expireOtpMsg}
                              setExpireOtpMsg={expireOtpMsg}
                              onClickResendOtp={onSignup}
                            />
                          )}
                          <div id="recaptcha-container"></div>
                        </div>
                      </>
                    )
                  ) : (
                    <div className="w-75 text-end text-success">Verified</div>
                  )}

                  <span className="text-error">
                    {phoneNoError && phoneErrorMsg}
                  </span>
                  <input
                    type="email"
                    name="email"
                    onChange={handleCheckValidation}
                    onBlur={handleBlurCheckValidEmail}
                    value={email}
                    autoComplete="off"
                    placeholder="Email"
                    className="pt-0 sign-up-input"
                  ></input>
                  <span className="text-error">
                    {emailError && emailErrorMsg}
                  </span>
                  {/* <input 
                    type="password"  
                    name="password" 
                    autoComplete="new-password"
                    value={password} 
                    onChange={handleCheckValidation}
                    placeholder="Password" className="pt-0 sign-up-input"/>
                    */}
                  <h5 className="fw-bold text-dark ps-1 mb-0 mt-2">
                    Date Of Birth
                  </h5>
                  {/* <input
                      type="date"
                      name="dob"
                      onChange={handleCheckValidation}
                      value={dob}
                      placeholder="    "
                      className="pt-0 sign-up-input"
                    ></input> */}
                  <div className="d-flex w-75 w-md-50">
                    <select
                      name="birthday"
                      onChange={handleCheckValidation}
                      value={birthday}
                      className="pt-0 sign-up-input w-25"
                    >
                      <option value="">DD</option>
                      {Array.from({ length: 31 }, (_, i) => i + 1).map(
                        (day) => (
                          <option key={day} value={day}>
                            {day}
                          </option>
                        )
                      )}
                    </select>
                    <select
                      name="birthmonth"
                      onChange={handleCheckValidation}
                      value={birthmonth}
                      className="pt-0 sign-up-input mx-3 w-25"
                    >
                      <option value="">MM</option>
                      {Array.from({ length: 12 }, (_, i) => i + 1).map(
                        (month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        )
                      )}
                    </select>
                    <select
                      name="birthyear"
                      onChange={handleCheckValidation}
                      value={birthyear}
                      className="w-25 pt-0 sign-up-input year"
                    >
                      <option value="">YYYY</option>
                      {Array.from(
                        { length: 100 },
                        (_, i) => new Date().getFullYear() - i
                      ).map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* <span className="text-error">{dobError && ("Please Enter Your Date Of Birth..")}</span>
                    <input
                      type="text"
                      onChange={handleCheckValidation}
                      value={bankName}
                      name="bankName"
                      placeholder="Bank Name"
                      className="pt-0 sign-up-input"
                    ></input>
                    <span className="text-error">{bankNameError && ("Please Enter Bank Name..")}</span>
                    <input
                      type="text"
                      onChange={handleCheckValidation}
                      value={accountHolderName}
                      name="accountHolderName"
                      placeholder="Account Holder Name"
                      className="pt-0 sign-up-input"
                    ></input>
                    <span className="text-error">{accountHolderNameError && ("Please Enter Account Holder Name..")}</span>
                    <input
                      type="number"
                      onChange={handleCheckValidation}
                      value={accountNo}
                      name="accountNo"
                      placeholder="Account Number"
                      className="pt-0 sign-up-input"
                    ></input>
                    <span className="text-error">{accountNoError && ("Please Enter Your Account Name")}</span>
                    <input
                      type="text"
                      onChange={handleCheckValidation}
                      value={branchName}
                      name="branchName"
                      placeholder="Branch Name"
                      className="pt-0 sign-up-input"
                    ></input>
                    <span className="text-error">{branchNameError && ("Please Enter Branch Code")}</span>
                    <input
                      type="text"
                      onChange={handleCheckValidation}
                      name="ifscCode"
                      value={ifscCode}
                      placeholder="IFSC code"
                      className="pt-0 sign-up-input"
                    ></input>
                    <span className="text-error">{ifscCodeError && ("Please Enter IFSC Code")} </span> */}
                  <div className="mt-5">
                    {/* {areAllIsErrorsTrue && ( */}
                    <button
                      className="btn btn-primary btn-color sign-up-btn"
                      onClick={handleSubmit}
                    >
                      Sign Up
                    </button>
                    {/* )} */}
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ):  */}
      {/* ( */}

      {/* ) */}

      {/* } */}
    </>
  );
}

export default Signup;
