import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
const intervals = [
  { value: "1m", label: "1 Minute" },
  { value: "3m", label: "3 Minute" },
  { value: "5m", label: "5 Minute" },
  { value: "10m", label: "10 Minute" },
  { value: "15m", label: "15 Minute" },
  { value: "30m", label: "30 Minute" },
  { value: "60m", label: "60 Minute" },
  { value: "1d", label: "1 Day" },
];

const TakeLiveModal = ({
  copyScannerShow,
  handelClickCreateScanner,
  setCopyScannerShow,
  nameError,
  setError,
  scannerName,
  handleTakeLive,
  mainData,
  onFormChange,
  isLive,
  candle_interval_id,
}) => {
  const [scannnerName, setScannnerName] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [candleId, setCandleId] = useState();
  const [filterIntervalData, setFilterIntervalData] = useState([]) ;
  const handleClose = () => {
    setCopyScannerShow(false);
  };
  useEffect(() => {
    if (copyScannerShow) {
      setCandleId(0);
    }
  }, [copyScannerShow]);
  const handelChange = (e) => {
    const value = e.target.value;
    if (value == undefined || value == "") {
      alert("Please select Alert Frequency");
      return;
    }
    setScannnerName(value);
    setError(false);
    setCandleId(value);
    onFormChange(value);
  };

  useEffect(() => {
    setCandleId(mainData?.candleIntervalId);
  }, [mainData?.candleIntervalId]);
  
  useEffect(() => {
    const filteredIntervals = intervals.filter((interval) => {
      const intervalValue = parseInt(interval.value.replace(/[^0-9]/g, ""), 10);

      if(interval.value == "1d") {
        return true;
      } else if(candle_interval_id == "1d"){
        return interval.value === "1d";
      }
        else {
        return intervalValue >=  candle_interval_id ;
      }
    });
    setFilterIntervalData(filteredIntervals) ;
  }, [candle_interval_id])
  return (
    <>
      <Modal
        show={copyScannerShow}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="copy-scanner-modal"
      >
        <Modal.Body className="text-center">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label className="mb-4 fs-4">
                {isLive ? "Stop Live" : "Take Live"}
              </Form.Label>

              <Form.Group>
                <div
                  style={{
                    textAlign: "left",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Scanner Name
                </div>
                <div style={{ textAlign: "left", fontSize: "16px" }}>
                  {scannerName}
                </div>
              </Form.Group>

              {nameError ? (
                <span className="text-error">Name cannot be empty</span>
              ) : null}
            </Form.Group>
            {!isLive && (
              <Form.Group
                as={Row}
                className="mb-4"
                controlId="formPlaintextEmail"
              >
                <Form.Label
                  column
                  sm="5"
                  style={{
                    textAlign: "left",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                >
                  Alert Frequency
                </Form.Label>
                <Col sm="5">
                  <Form.Select
                    size="sm"
                    name={"candleIntervalId"}
                    value={candleId}
                    onChange={handelChange}
                  >
                    <option value="">Select</option>

                    {filterIntervalData?.map((interval) => (
                      <option key={interval.value} value={interval.value}>
                        {interval.label}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            )}
            <div className="d-flex justify-content-between">
              <Button
                className="btn-color mr-2"
                variant="primary"
                disabled={isDisable}
                onClick={() => handleTakeLive(scannerName)}
              >
                {isLive ? "Stop Live" : "Take Live"}
              </Button>
              <Button
                variant="outline-primary"
                className=""
                onClick={handleClose}
                id="cancel-outline-primary"
              >
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TakeLiveModal;
