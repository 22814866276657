const modalMap = {
    "Close": "Close",
    "Open price": "Open price",
    "High price": "High price",
    "Low price": "Low price",
    "RSI (Relative Strength Index)": "RSI (Relative Strength Index)",
    "Ulcer": "Ulcer",
    "DEMA (Double Exponential Moving Average)": "DEMA (Double Exponential Moving Average)",
    "TEMA (Triple Exponential Moving Average)": "TEMA (Triple Exponential Moving Average)",
    "TriMA (Triangular Moving Average)": "TriMA (Triangular Moving Average)",
    "VWMA (Volume Weighted Moving Average)": "VWMA (Volume Weighted Moving Average)",
    "Time Series Forecast": "Time Series Forecast",
    "Linear Regression Forecast": "Linear Regression Forecast",
    "SMA (Simple Moving Average)": "SMA (Simple Moving Average)",
    "EMA (Exponential Moving Average)": "EMA (Exponential Moving Average)",
    "WMA (Weighted Moving Average)": "WMA (Weighted Moving Average)",
    "Number": "Number",
    "Money Flow Index": "Money Flow Index",
    "Williams %R": "Williams %R",
    "Moving Average Deviation (MA Dev)": "Moving Average Deviation (MA Dev)",
    "MACD (Moving Average Convergence/Divergence)": "MACD (Moving Average Convergence/Divergence)",
    "OBV (On Balance Volume)": "OBV (On Balance Volume)",
    "Aroon-Down": "Aroon-Down",
    "Aroon-Up": "Aroon-Up",
    "Awesome Oscillator": "Awesome Oscillator",
    "VWAP (Volume Weighted Average Price)": "VWAP (Volume Weighted Average Price)",
    "Parabolic SAR": "Parabolic SAR",
    "Vortex Indicator": "Vortex Indicator",
    "Volume of a candle": "Volume of a candle",
    "Donchian Channel": "Donchian Channel",
    "Stochastic": "Stochastic",
    "Chaikin Money Flow": "Chaikin Money Flow",
    "MACD-histogram": "MACD-histogram",
    "MACD-signal": "MACD-signal",
    "Commodity Channel Index": "Commodity Channel Index",
    "Center Of Gravity": "Center Of Gravity",
    "Price Rate of Change": "Price Rate of Change",
    "RSI Moving Average": "RSI Moving Average",
    "TR (True Range)": "TR (True Range)",
    "ATR (Average True Range)": "ATR (Average True Range)",
    "NATR (Normalized Average True Range)": "NATR (Normalized Average True Range)",
    "Supertrend": "Supertrend",
    "Bollinger %B": "Bollinger %B",
    "MFI Moving Average (Money Flow Index Moving Average)": "MFI Moving Average (Money Flow Index Moving Average)",
    "Ichimoku": "Ichimoku",
    "Know Sure Thing": "Know Sure Thing",
    "Elder Force Index": "Elder Force Index",
    "MOM (Momentum Indicator)": "MOM (Momentum Indicator)",
    "Standard Deviation": "Standard Deviation",
    "Ultimate Oscillator": "Ultimate Oscillator",
    "Nth Candle": "Nth Candle",
    "Chande Momentum Oscillator": "Chande Momentum Oscillator",
    "Median Price": "Median Price",
    "ATR Trailing Stoploss": "ATR Trailing Stoploss",
    "Choppiness Index": "Choppiness Index",
    "Central Pivot Range": "Central Pivot Range",
    "Camarilla Pivot Points": "Camarilla Pivot Points",
    "Pivot Points": "Pivot Points",
    "Elder Ray Index": "Elder Ray Index",
    "UBB (Upper Bollinger Band)": "UBB (Upper Bollinger Band)",
    "MBB (Middle Bollinger Band)": "MBB (Middle Bollinger Band)",
    "LBB (Lower Bollinger Band)": "LBB (Lower Bollinger Band)",
    "Bollinger Bandwidth": "Bollinger Bandwidth",
    "Phase Power Moving Average": "Phase Power Moving Average",
    "-DI (Minus Directional Index)": "-DI (Minus Directional Index)",
    "ADX (Average Directional Index)": "ADX (Average Directional Index)",
    "TSI (True Strength Indicator)": "TSI (True Strength Indicator)",
    "TII (Trend Intensity Index)": "TII (Trend Intensity Index)",
    "Open Interest Moving Average": "Open Interest Moving Average",
    "NVI (Negative Volume Index)": "NVI (Negative Volume Index)",
    "Option Greeks": "Option Greeks",
    "Detrended Price Oscillator": "Detrended Price Oscillator",
    "Klinger Volume Oscillator": "Klinger Volume Oscillator",
    "VWAP Standard Deviation": "VWAP Standard Deviation" ,
    "McGinley Dynamic Indicator": "McGinley Dynamic Indicator" ,
    "True Range Moving Average": "True Range Moving Average" ,
    "Vortex Moving Average": "Vortex Moving Average" ,
    "Trade Volume Index": "Trade Volume Index" ,
    "Swing Index": "Swing Index" ,
    "DI (Plus Directional Index)": "DI (Plus Directional Index)" ,
    "Keltner Channel": "Keltner Channel" ,
    "VWAP Moving Average": "VWAP Moving Average" ,
    "Median Price Moving Average": "Median Price Moving Average",
    "Moving Average": "Moving Average" ,
    "Moving Average Volume": "Moving Average Volume",
    "Bollinger %B Moving Average": "Bollinger %B Moving Average" ,
    "ADX Moving Average (Average Directional Index Moving Average)": "ADX Moving Average (Average Directional Index Moving Average)" ,
    "Day High Low": 'Day High Low' ,
    "Chande Kroll Stop": "Chande Kroll Stop",
    "Ehler Fisher": "Ehler Fisher" ,
    "Price Rate Of Change Moving Average (Proc Moving Average)": "Price Rate Of Change Moving Average (Proc Moving Average)" ,
    "MACD OI (Moving Average Convergence/Divergence Open Interest)": "MACD OI (Moving Average Convergence/Divergence Open Interest)" ,
    "Previous N": 'Previous N' ,
    "Intraday Momentum Index Moving Average (IMI Moving Average)": "Intraday Momentum Index Moving Average (IMI Moving Average)" ,
    "Opening Range": "Opening Range" ,
    "Choppiness Index Moving Average": "Choppiness Index Moving Average" ,
    "CCI Moving Average (Commodity Channel Index Moving Average)": "CCI Moving Average (Commodity Channel Index Moving Average)" ,
    "Schaff Trend Cycle": "Schaff Trend Cycle" ,
    "Aroon Oscillator": "Aroon Oscillator" ,
    "Stochastic RSI": "Stochastic RSI" ,
    "PVI (Positive Volume Index)": "PVI (Positive Volume Index)" ,
    "Twiggs Money Flow": "Twiggs Money Flow" ,
    "ATR Bands": "ATR Bands" ,
    "Stochastic Momentum Index": "Stochastic Momentum Index" ,
    "Open interest": "Open interest" ,
    "Candle Time": "Candle Time" ,
    "Coppock curve": "Coppock curve" ,
    "Alligator": "Alligator" ,
    "Volume Oscillator": "Volume Oscillator" ,
    "OBV Moving Average (On Balance Volume Moving Average)": "OBV Moving Average (On Balance Volume Moving Average)" ,
    "Intraday Momentum Index": "Intraday Momentum Index" ,
};


const fieldMappings = (isExit, instanceData) => ({
    Close: isExit ? ["e_closeOffset"] : ["closeOffset"],
    "Open price": isExit ? ["e_offset"] : ["offset"],
    "High price": isExit ? ["e_highOffset"] : ["highOffset"],
    "Low price": isExit ? ["e_lowOffset"] : ["lowOffset"],
    "RSI (Relative Strength Index)": isExit
        ? ["e_rsiPeriod", "e_rsiOffset"]
        : ["rsiPeriod", "rsiOffset"],
    Ulcer: isExit
        ? ["e_ulcerField", "e_ulcerPeriod", "e_ulcerOffset"]
        : ["ulcerField", "ulcerPeriod", "ulcerOffset"],
    "DEMA (Double Exponential Moving Average)": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
    "TEMA (Triple Exponential Moving Average)": isExit
        ? ["e_temaField", "e_temaPeriod", "e_temaOffset"]
        : ["temaField", "temaPeriod", "temaOffset"],
    "TriMA (Triangular Moving Average)": isExit
        ? ["e_tmaField", "e_tmaPeriod", "e_tmaOffset"]
        : ["tmaField", "tmaPeriod", "tmaOffset"],
    "VWMA (Volume Weighted Moving Average)": isExit
        ? ["e_vwmaField", "e_vwmaPeriod", "e_vwmaOffset"]
        : ["vwmaField", "vwmaPeriod", "vwmaOffset"],
    "SMA (Simple Moving Average)": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
    "EMA (Exponential Moving Average)": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
    "WMA (Weighted Moving Average)": isExit
        ? ["e_wmaField", "e_wmaPeriod", "e_wmaOffset"]
        : ["wmaField", "wmaPeriod", "wmaOffset"],
    Number: isExit ? ["e_period"] : ["period"],
    "Money Flow Index": isExit
        ? ["e_mfiPeriod", "e_mfiOffset"]
        : ["mfiPeriod", "mfiOffset"],
    "Williams %R": isExit ? ["e_wrPeriod", "e_wrOffset"] : ["wrPeriod","wrOffset"],
    "Moving Average Deviation (MA Dev)": isExit
        ? [
            "e_madField",
            "e_madPeriod",
            "e_madType",
            "e_madOffset1",
            "e_madOffset2",
        ]
        : ["madField", "madPeriod", "madType", "madOffset", "madPop"],
    "MACD (Moving Average Convergence/Divergence)": isExit
        ? [
            "e_macd_fastMaPeriod",
            "e_macd_slowMaPeriod",
            "e_macd_signalPeriod",
            "e_macdOffset",
        ]
        : [
            "macd_fastMaPeriod",
            "macd_slowMaPeriod",
            "macd_signalPeriod",
            "macdOffset",
        ],
    "OBV (On Balance Volume)": isExit ? ["e_obvOffset"] : ["obvOffset"],
    "Aroon-Down": isExit
        ? ["e_aroonDownPeriod", "e_aroonDownOffset"]
        : ["aroonDownPeriod", "aroonDownOffset"],
    "Aroon-Up": isExit
        ? ["e_aroonUpPeriod", "e_aroonUpOffset"]
        : ["aroonUpPeriod", "aroonUpOffset"],
    "Awesome Oscillator": isExit
        ? ["e_aOscilatorOffset"]
        : ["aOscilatorOffset"],
    "VWAP (Volume Weighted Average Price)": isExit
        ? ["e_vwapOffset"]
        : ["vwapOffset"],
    "Parabolic SAR": isExit
        ? ["e_psarMinimumAF", "e_psarMaximumAF", "e_psarOffset"]
        : ["psarMinimumAF", "psarMaximumAF", "psarOffset"],
    "Vortex Indicator": isExit
        ? ["e_vortexIPeriod", "e_vortexISignal", "e_vortexIOffset"]
        : ["vortexIPeriod", "vortexISignal", "vortexIOffset"],
    "Volume of a candle": isExit ? ["e_offset"] : ["offset"],
    "Donchian Channel": isExit
        ? [
            "e_donchianChannel",
            "e_donchianHighPeriod",
            "e_donchianLowPeriod",
            "e_donchianOffset",
        ]
        : [
            "donchianChannel",
            "donchianHighPeriod",
            "donchianLowPeriod",
            "donchianOffset",
        ],
    Stochastic: isExit
        ? [
            "e_stochasticPeriod",
            "e_stochasticType",
            "e_stochasticSmooth",
            "e_stochasticOffset",
        ]
        : [
            "stochasticPeriod",
            "stochasticType",
            "stochasticSmooth",
            "stochasticOffset",
        ],
    "Chaikin Money Flow": isExit
        ? ["e_chaikinPeriod", "e_chaikinOffset"]
        : ["chaikinPeriod", "chaikinOffset"],
    "MACD-signal": isExit
        ? [
            "e_macdsFastMaPeriod",
            "e_macdsSlowMaPeriod",
            "e_macdsSignalPeriod",
            "e_macdsOffset",
        ]
        : [
            "macdsFastMaPeriod",
            "macdsSlowMaPeriod",
            "macdsSignalPeriod",
            "macdsOffset",
        ],
    "Commodity Channel Index": isExit
        ? ["e_cciPeriod", "e_cciOffset"]
        : ["cciPeriod", "cciOffset"],
    "Price Rate of Change": isExit
        ? ["e_rocField", "e_rocPeriod", "e_rocOffset"]
        : ["rocField", "rocPeriod", "rocOffset"],
    "&&": "and",
    "||": "or",
    "RSI Moving Average": isExit
        ? ["e_rsimaPeriod", "e_rsimaType", "e_rsimaMaPeriod", "e_rsimaOffset"]
        : ["rsimaPeriod", "rsimaType", "rsimaMaPeriod", "rsimaOffset"],
    "TR (True Range)": isExit ? ["e_trOffset"] : ["trOffset"],
    "ATR (Average True Range)": isExit
        ? ["e_atrPeriod", "e_atrOffset"]
        : ["atrPeriod", "atrOffset"],
    "NATR (Normalized Average True Range)": isExit
        ? ["e_natrPeriod", "e_natrOffset"]
        : ["natrPeriod", "natrOffset"],
    Supertrend: isExit
        ? ["e_superTPeriod", "e_superTMultiply", "e_superTOffset"]
        : ["superTPeriod", "superTMultiply", "superTOffset"],
    "Bollinger %B": isExit
        ? [
            "e_bolingerBField",
            "e_bolingerBPeriod",
            "e_bolingerBDev",
            "e_bolingerBType",
            "e_bolingerBOffset",
        ]
        : [
            "bolingerBField",
            "bolingerBPeriod",
            "bolingerBDev",
            "bolingerBType",
            "bolingerBOffset",
        ],
    "MFI Moving Average (Money Flow Index Moving Average)": isExit
        ? ["e_mfiMAPeriod", "e_mfiMAType", "e_mfiMAMAPeriod", "e_mfiMAOffset"]
        : ["mfiMAPeriod", "mfiMAType", "mfiMAMAPeriod", "mfiMAOffset"],
    Ichimoku: isExit
        ? [
            "e_ichiConversion",
            "e_ichiBase",
            "e_leadingSpanB",
            "e_laggingSpan",
            "e_ichiLine",
            "e_cloudShift",
            "e_ichiOffset",
        ]
        : [
            "ichiConversion",
            "ichiBase",
            "leadingSpanB",
            "laggingSpan",
            "ichiLine",
            "cloudShift",
            "ichiOffset",
        ],
    "Know Sure Thing": isExit
        ? [
            "e_field",
            "e_LTRoc",
            "e_LTSma",
            "e_LRoc",
            "e_LSma",
            "e_HRoc",
            "e_HSma",
            "e_HeRoc",
            "e_HeSma",
            "e_SPeriod",
            "e_Type",
            "e_offset",
        ]
        : [
            "field",
            "LTRoc",
            "LTSma",
            "LRoc",
            "LSma",
            "HRoc",
            "HSma",
            "HeRoc",
            "HeSma",
            "SPeriod",
            "Type",
            "offset",
        ],
    "MOM (Momentum Indicator)": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
    "Standard Deviation": isExit
        ? ["e_stdPeriod", "e_stdField", "e_stdDev", "e_maType", "e_offset"]
        : ["stdPeriod", "stdField", "stdDev", "maType", "offset"],
    "Ultimate Oscillator": isExit
        ? ["e_length1", "e_length2", "e_length3", "e_offset"]
        : ["length1", "length2", "length3", "offset"],
    "Nth Candle": isExit
        ? ["e_field", "e_range", "e_num"]
        : ["field", "range", "num"],
    "MACD-histogram": isExit
        ? [
            "e_macdsFastMaPeriod",
            "e_macdsSlowMaPeriod",
            "e_macdsSignalPeriod",
            "e_macdsOffset",
        ]
        : [
            "macdsFastMaPeriod",
            "macdsSlowMaPeriod",
            "macdsSignalPeriod",
            "macdsOffset",
        ],
    "Median Price": isExit ? ["e_period", "e_offset"] : ["period", "offset"],
    "Chande Momentum Oscillator": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
    "ATR Trailing Stoploss": isExit
        ? ["e_multiplier", "e_period", "e_offset"]
        : ["multiplier", "period", "offset"],
    "Choppiness Index": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
    "Central Pivot Range": isExit ? ["e_signal"] : ["signal"],
    "Camarilla Pivot Points": isExit ? ["e_signal"] : ["signal"],
    "Pivot Points": isExit
        ? ["e_signal", "e_type", "e_continous"]
        : ["signal", "type", "continous"],
    "Center Of Gravity": isExit
        ? ["e_rocField", "e_rocPeriod", "e_rocOffset"]
        : ["rocField", "rocPeriod", "rocOffset"],
    "Elder Ray Index": isExit
        ? ["e_power", "e_period", "e_offset"]
        : ["power", "period", "offset"],
    "Elder Force Index": isExit ? ["e_period", "e_offset"] : ["period", "offset"],
    "UBB (Upper Bollinger Band)": isExit
        ? ["e_field", "e_period", "e_dev", "e_type", "e_offset"]
        : ["field", "period", "dev", "type", "offset"],
    "MBB (Middle Bollinger Band)": isExit
        ? ["e_field", "e_period", "e_dev", "e_type", "e_offset"]
        : ["field", "period", "dev", "type", "offset"],
    "LBB (Lower Bollinger Band)": isExit
        ? ["e_field", "e_period", "e_dev", "e_type", "e_offset"]
        : ["field", "period", "dev", "type", "offset"],
    "Bollinger Bandwidth": isExit
        ? ["e_field", "e_period", "e_dev", "e_type", "e_offset"]
        : ["field", "period", "dev", "type", "offset"],
    "Phase Power Moving Average": isExit
        ? ["e_field", "e_powerLength", "e_phase", "e_power", "e_offset"]
        : ["field", "powerLength", "phase", "power", "offset"],
    "-DI (Minus Directional Index)": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
    "TSI (True Strength Indicator)": isExit
        ? ["e_field", "e_type", "e_long", "e_short", "e_signal", "e_offset"]
        : ["field", "type", "long", "short", "signal", "offset"],
    "ADX (Average Directional Index)": isExit
        ? ["e_period", "e_offset"]
        : ["period", "offset"],
    "Time Series Forecast": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
    "TII (Trend Intensity Index)": isExit
        ? ["e_field", "e_type", "e_period", "e_signal", "e_offset"]
        : ["field", "type", "period", "signal", "offset"],
    "Linear Regression Forecast": isExit
        ? ["e_field", "e_period", "e_offset"]
        : ["field", "period", "offset"],
    "Open Interest Moving Average": isExit
        ? ["e_type", "e_period", "e_offset"]
        : ["type", "period", "offset"],
    "NVI (Negative Volume Index)": isExit
        ? ["e_field", "e_period", "e_type", "e_line", "e_offset"]
        : ["field", "period", "type", "line", "offset"],
    "Option Greeks": isExit
        ? ["e_type", "e_dividend", "e_interest", "e_offset"]
        : ["type", "dividend", "interest", "offset"],
    "Detrended Price Oscillator": isExit
        ? ["e_field", "e_period", "e_type", "e_displacement", "e_offset"]
        : ["field", "period", "type", "displacement", "offset"],
    "Klinger Volume Oscillator": isExit
        ? ["e_period", "e_short", "e_long", "e_type", "e_offset"]
        : ["period", "short", "long", "type", "offset"],
    "VWAP Standard Deviation": isExit ? ["e_period", "e_dev", "e_type", "e_offset"] : ["period", "dev", "type", "offset"],
    "True Range Moving Average": isExit ? ["e_period", "e_type", "e_maType", "e_maPeriod", "e_offset"] : ["period", "type", "maType", "maPeriod", "offset"],
    "McGinley Dynamic Indicator" : isExit ? ["e_period", "e_offset"] : ["period", "offset"],
    "Vortex Moving Average" : isExit ? ["e_period", "e_signal", "e_type", "e_maPeriod", "e_offset"] : ["period", "signal", "type", "maPeriod", "offset"],
    "Trade Volume Index" : isExit ? ["e_value", "e_offset"] : ["value", "offset"] ,
    "Swing Index": isExit ? ["e_limit", "e_offset"] : ["limit", "offset"],
    "DI (Plus Directional Index)" : isExit ? ["e_period", "e_offset"] : ["period", "offset"] ,
    "Keltner Channel": isExit ? ["e_period", "e_band", "e_maType", "e_shift", "e_offset"] : ["period", "band", "maType", "shift", "offset"] ,
    "VWAP Moving Average" : isExit ? ["e_type", "e_period", "e_offset"] : ["type", "period", "offset"] ,
    "Median Price Moving Average": isExit ? ["e_period", "e_type", "e_maPeriod", "e_offset"] : ["period", "type", "maPeriod", "offset"],
    "Moving Average": isExit ? ["e_field", "e_period", "e_type", "e_offset"]
        : ["field", "period", "type", "offset"],
    "Moving Average Volume": isExit ? ["e_type", "e_period", "e_offset"] : ["type", "period", "offset"],
    "Bollinger %B Moving Average": isExit ? ["e_field", "e_bPeriod", "e_deviation", "e_bType", "e_maType", "e_maPeriod", "e_offset"] : ["field", "bPeriod", "deviation", "bType", "maType", "maPeriod", "offset"] ,
    "ADX Moving Average (Average Directional Index Moving Average)": isExit ? ["e_period", "e_type", "e_maType", "e_maPeriod", "e_offset"] : ["period", "type", "maType", "maPeriod", "offset"],
    "Day High Low" : isExit ? ["e_option"] : ["option"] ,
    "Chande Kroll Stop" : isExit ? ["e_p", "e_x", "e_q", "e_mode", "e_offset"] : ["p", "x", "q", "mode", "offset"],
    "Ehler Fisher" : isExit ? ["e_period", "e_line", "e_offset"] : ["period", "line", "offset"],
    "Price Rate Of Change Moving Average (Proc Moving Average)": isExit ? ["e_field", "e_period", "e_type", "e_maPeriod", "e_offset"] : ["field", "period", "type", "maPeriod", "offset"],
    "MACD OI (Moving Average Convergence/Divergence Open Interest)": isExit
        ? [
            "e_fastMaPeriod",
            "e_slowMaPeriod",
            "e_signalPeriod",
            "e_type",
            "e_offset",
        ]
        : [
            "fastMaPeriod",
            "slowMaPeriod",
            "signalPeriod",
            "type" ,
            "offset",
        ],
    "Previous N": isExit ? ["e_field", "e_offset", "e_candle"] : ["field", "offset", "candle"] ,
    "Intraday Momentum Index Moving Average (IMI Moving Average)": isExit ? ["e_period", "e_type", "e_maPeriod", "e_offset"] : ["period", "type", "maPeriod", "offset"],
    "Opening Range": isExit ? ["e_field", "e_range"] : ["field", "range"] ,
    "Choppiness Index Moving Average": isExit ? ["e_period", "e_type", "e_maPeriod", "e_offset"] : ["period", "type", "maPeriod", "offset"],
    "CCI Moving Average (Commodity Channel Index Moving Average)": isExit ? ["e_period", "e_type", "e_maPeriod", "e_offset"] : ["period", "type", "maPeriod", "offset"],
    "Schaff Trend Cycle": isExit
        ? ["e_period","e_field" , "e_short", "e_long", "e_type", "e_offset"]
        : ["period", "field" , "short", "long", "type", "offset"],
    "Aroon Oscillator" : isExit ? ["e_period" , "e_offset"] : ["period", "offset"],
    "Stochastic RSI": isExit ? ["e_period", "e_type", "e_smooth","e_offset"]: ["period","type", "smooth","offset"],
    "PVI (Positive Volume Index)": isExit ? ["e_field", "e_period", "e_type", "e_line", "e_offset"] : ["field", "period", "type", "line", "offset"],
    "Twiggs Money Flow" : isExit ? ["e_period", "e_offset"] : ["period", "offset"] ,
    "ATR Bands": isExit ? ["e_period", "e_shift", "e_field", "e_band", "e_offset"] : ["period", "shift", "field", "band", "offset"],
    "Stochastic Momentum Index": isExit ? ["e_type", "e_kPeriod", "e_smoothPeriod", "e_doublePeriod", "e_dPeriod", "e_offset"] : ["type", "kPeriod", "smoothPeriod", "doublePeriod", "dPeriod", "offset"],
    "Open interest" : isExit ? [ "e_offset"] : ["offset"] ,
    "Candle Time" : isExit ? ["e_type"] : ["type"] ,
    "Coppock curve": isExit ? ["e_field", "e_period", "e_sroc", "e_lroc", "e_offset"] : ["field", "period", "sroc", "lroc","offset"],
    "Alligator": isExit ? ["e_signal", "e_jawPeriod", "e_jawOffset", "e_teethPeriod", "e_teethOffset", "e_lipsPeriod", "e_lipsOffset", "e_offset"] : ["signal", "jawPeriod", "jawOffset", "teethPeriod", "teethOffset", "lipsPeriod", "lipsOffset", "offset" ] ,
    "Volume Oscillator": isExit ? ["e_short", "e_long", "e_pop", "e_offset"] : ["short", "long", "pop", "offset"] ,
    "OBV Moving Average (On Balance Volume Moving Average)": isExit ? ["e_type", "e_period", "e_offset"] : ["type", "period", "offset"],
    "Intraday Momentum Index": isExit ? ["e_period", "e_offset"] : ["period", "offset"],
    default: instanceData?.name,
});


export { modalMap, fieldMappings }