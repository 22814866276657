
const config={
    colors:{
        primary:"black",
        secondary:"white",
    },
    backgroundColors:{
        primary:"black",
        secondary:"white",
    }
} ;

const API_BASE_URL = "https://dalrotibackend.shrikanttapkeer.com/api/v1" ;
const BASE_URL = "https://dalrotibackend.shrikanttapkeer.com" ;
const IMAGE_URL = "https://dalrotibackend.shrikanttapkeer.com/images/" ;
const FETCH_STOCKS = "https://margincalculator.angelbroking.com/OpenAPI_File/files/OpenAPIScripMaster.json" ;


export { config, API_BASE_URL, BASE_URL, IMAGE_URL, FETCH_STOCKS };