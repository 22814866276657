import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const CandleInterval = ({
  show,
  setIsShow,
  handleModalData,
  mainData,
  clickedFunction,
  exit,
  index,
  instance,
}) => {
  let data;

  if (exit) {
    const exitIndicators = mainData?.exit_indicators?.[clickedFunction];

    if (exitIndicators?.body) {
      data = exitIndicators.body[index];
    } else if (instance && exitIndicators?.body1) {
      data = exitIndicators[instance]?.[0];
    } else if (exitIndicators?.body2) {
      data = exitIndicators[instance]?.[0];
    } else {
      data = exitIndicators;
    }
  } else {
    const indicators = mainData?.indicators?.[clickedFunction];

    if (indicators?.body) {
      data = indicators.body[index];
    } else if (instance && indicators?.body1) {
      data = indicators[instance]?.[0];
    } else if (indicators?.body2) {
      data = indicators[instance]?.[0];
    } else {
      data = indicators;
    }
  }

  const [formData, setFormData] = useState(data);
  const [isSelectInterval, setIsSelectInterval] = useState(false);
  useEffect(() => {
    // Update formData when data changes
    setFormData(data);
  }, [data]);
  useEffect(() => {
    // console.log(
    //   "candleModalData-",
    //   formData?.candleIntervalId
    // );
  }, [formData]);

  // console.log("CF-", mainData?.exit_indicators?.[clickedFunction]);
  const handleClose = () => {
    setIsShow(false);
  };

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleDone = () => {
    if (formData?.candleIntervalId) {
      // console.log("cccc--", formData?.candleIntervalId, formData);
      handleModalData(formData);
      setIsShow(false);
    } else {
      setIsSelectInterval(!isSelectInterval);
      return;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsSelectInterval(false);
    }, 2000);
   
  }, [isSelectInterval])
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        centered
        dialogClassName="formula-modal"
      >
        {isSelectInterval && (
          <div class="alert alert-danger" role="alert">
            Please select Candle Interval
          </div>
        )}
        <Modal.Header className="pt-4 border-0">
          <Modal.Title className="fs-5 fw-bold px-4">
            Candle Interval
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <Form>
            <Row>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextEmail"
              >
                <Col sm="4">
                  <Form.Select
                    size="sm"
                    name={"candleIntervalId"}
                    value={formData?.candleIntervalId || ""}
                    onChange={handelChange}
                  >
                    <option value="">Select</option>
                    {mainData?.candleIntervalId <= 1 && (
                      <option value="1minute">1 Minute</option>
                    )}
                    {mainData?.candleIntervalId <= 3 && (
                      <option value="3minute">3 Minute</option>
                    )}
                    {mainData?.candleIntervalId <= 5 &&
                      5 % mainData?.candleIntervalId === 0 && (
                        <option value="5minute">5 Minute</option>
                      )}
                    {mainData?.candleIntervalId <= 10 &&
                      10 % mainData?.candleIntervalId === 0 && (
                        <option value="10minute">10 Minute</option>
                      )}
                    {mainData?.candleIntervalId <= 15 &&
                      15 % mainData?.candleIntervalId === 0 && (
                        <option value="15minute">15 Minute</option>
                      )}
                    {mainData?.candleIntervalId <= 30 && (
                      <option value="30minute">30 Minute</option>
                    )}
                    {mainData?.candleIntervalId <= 60 && (
                      <option value="60minute">60 Minute</option>
                    )}

                    {mainData?.candleIntervalId !== "1day" &&
                      mainData?.candleIntervalId && (
                        <option value="2h">2 hour</option>
                      )}

                    {mainData?.candleIntervalId !== "1day" &&
                      mainData?.candleIntervalId && (
                        <option value="3h">3 hour</option>
                      )}

                    {mainData?.candleIntervalId !== "1day" &&
                      mainData?.candleIntervalId && (
                        <option value="4h">4 hour</option>
                      )}

                    {(mainData?.candleIntervalId === "1day" ||
                      mainData?.candleIntervalId) && (
                      <option value="1day">1 Day</option>
                    )}

                    {(mainData?.candleIntervalId === "1day" ||
                      mainData?.candleIntervalId) && (
                      <option value="week">Week</option>
                    )}

                    {(mainData?.candleIntervalId === "1day" ||
                      mainData?.candleIntervalId) && (
                      <option value="month">Month</option>
                    )}
                    {/* {(mainData?.candleIntervalId === "1day" ||
                      mainData?.candleIntervalId) && (
                      <option value="weekly">Weeekly</option>
                    )}

                    {(mainData?.candleIntervalId === "1day" ||
                      mainData?.candleIntervalId) && (
                      <option value="monthly">Monthly</option>
                    )} */}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className="pb-1   border-0">
          <Button variant="primary" size="sm" onClick={handleDone}>
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CandleInterval;
