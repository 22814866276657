import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import DeployedBox from "../../mcomponents/DeployedBox";
import { Menu, MenuItem } from "@mui/material";
import { getUser, getZerodhaToken } from "../../Session";
import {
  addNewScannerAPI,
  deleteScannerAPI,
  deployScanner,
  getLiveScanLogAPi,
  getLiveScannerCountAPI,
  getLiveScannerResultAPI,
  getScannerResultApi,
  stopLiveScanner,
} from "../../api/scannerapi";
import moment from "moment";
import TakeLiveModal from "./TakeLiveModal";
import LogBarChart from "./LogChart";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { getStockLTPDataAPI } from "../../api/api";
import DeleteStrategyModal from "../strategy/DeleteStrategyModel";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SkeletonLoader from "../componentCommon/SkeletonLoader";
import ScannerTable from "./subComponent/ScannerTable";
import {
  setupScannerInterval,
  shouldFetchScannerResult,
} from "../../Utils/setupScannerInterval";
import CustomAlert from "../componentCommon/CustomAlert";
export const SingleScanner = () => {
  let flag = 0;
  const navigate = useNavigate();
  const location = useLocation();
  const strategyid = location.pathname.split("/");
  const strategyId = location.state?.strategyId;
  const isCreated = location.state?.isCreated;
  const [isCreatedClick, setIsCreatedClick] = useState(isCreated);
  const [onClickDisable, setonClickDisable] = useState(false);
  const [copyScannerShow, setCopyScannerShow] = useState(false);
  const { scanid } = useParams();
  const [scannerData, setScannerData] = useState([]);
  const [data, setData] = useState([]);
  const [scannerNameError, setScannerNameError] = useState(false);
  const [scanCondition, setScanCondition] = useState(false);
  const [mainObj, setMainObj] = useState();
  const [candleId, setCandleId] = useState();
  const [isLive, setIsLive] = useState(false);
  const [logData, setLogData] = useState(false);
  const [graph, setGraph] = useState(false);
  const [isHide, setHide] = useState(false);
  const [clickLive, setCLickLive] = useState(false);
  const [show, setShow] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState();
  const [length, setLength] = useState(false);
  const [scannerName, setScannerName] = useState(false);
  const [cnt, setCnt] = useState(0);
  const [clickeddata, setClickeddata] = useState(null);
  const user = getUser();
  const token = getZerodhaToken();
  const intervalRef = useRef(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [otherpage, setOtherpage] = useState(null);
  const [intervalId, setIntervalId] = useState("init");
  const [scandate, setDate] = useState();
  const [isConditionLoading, setIsConditionLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState(null);
  const [selectBar, setSelectBar] = useState(false);
  const buttonRef = useRef(null);
  const [cache, setCache] = useState(null);
  const [localStoredId, setLocalStoredId] = useState(null);
  const [checkLiveCnt, setCheckLiveCnt] = useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  let getLocalId = localStorage.getItem(scanid);
  let local;

  useEffect(() => {
    if (getLocalId) {
      local = JSON.parse(getLocalId).strategyId;
      setLocalStoredId(local);
    }
  }, []);

  useEffect(() => {
    const checkFirst = localStorage.getItem("IsCreated");
    if (isCreatedClick && checkFirst == null) {
      setTimeout(() => {
        localStorage.setItem("IsCreated", true);
        setIsCreatedClick(false);
        localStorage.removeItem(`${scanid}`);
        localStorage.removeItem(`${scanid}`);
      }, 40000);
    }
    if (onClickDisable) {
      setTimeout(() => {
        setonClickDisable(false);
        setIsConditionLoading(false);
        // localStorage.setItem("toggleDisabled", true);
        localStorage.removeItem(`${scanid}`);
      }, 40000);
    }
  }, [isCreatedClick, onClickDisable]);

  // useEffect(() => {
  //   let toggleDisabled = localStorage.getItem("toggleDisabled");
  //   if (toggleDisabled) {
  //     setIsConditionLoading(false);
  //     localStorage.removeItem("toggleDisabled");
  //   }
  // }, []);
  const handleEditMenu = () => {
    if (isLive) {
      // alert("Please Stop the Live Scanner");
      setAlertMessage({
        message: "Please Stop the Live Scanner",
        type: "alert-warning",
      });
      return;
    }
    setIsCreatedClick(true);
    setonClickDisable(true);
    if (scannerData.length > 0) {
      let data = {};
      if (scannerData.length > 0 && scannerData[0]?.entire_object) {
        data = JSON.parse(scannerData[0]?.entire_object);
      }
      if (scannerData.length > 0 && scannerData[0]) {
        if (scannerData[0]?.scanner_id)
          data.scanner_id = scannerData[0]?.scanner_id;
      }
      localStorage.removeItem("IsCreated");
      localStorage.setItem("IsNavigate", true);
      navigate(
        `/scanners/page?scannid=${scannerData[0]?.scanner_id}&scannername=${scannerName}`,
        {
          state: data,
        }
      );
      handleClose();
    } else {
      let data = mainObj;
      if (allData && allData.length > 0)
        data.scanner_id = allData && allData[0]?.scanner_id;
      localStorage.removeItem("IsCreated");
      localStorage.setItem("IsNavigate", true);
      navigate(
        `/scanners/page?scannid=${allData[0]?.scanner_id}&scannername=${scannerName}`,
        {
          state: data,
        }
      );
      handleClose();
    }
  };

  const handleDelete = () => {
    setShow(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setShow(false);
  };
  useEffect(() => {
    const callApi = () => {
      if (
        (isCreatedClick && localStorage.getItem("IsCreated") == null) ||
        localStorage.getItem(`${scanid}`) == scanid
        // localStoredId == scanid
      ) {
        setTimeout(() => {
          fetchScannerResult("callApiT");
        }, 40000);
      } else {
        fetchScannerResult("callApi");
      }
      getLiveResult();
    };
    callApi();
  }, []);
  useEffect(() => {
    const checkButtonText = buttonRef.current?.innerText;
    if (checkButtonText !== "Stop Live") {
      if (!isLive) {
        const interval = setInterval(() => {
          if (shouldFetchScannerResult(mainObj, allDataLength))
            fetchScannerResult("location");
        }, 2000);
        return () => {
          clearInterval(interval);
        };
      }
    }
  }, [location.pathname, allDataLength, isLive]);

  const setValue = (prop) => {
    setSelectBar(true);
    setClickeddata(prop);
    setOtherpage(prop);
  };

  const fetchScannerResult = async (res) => {
    try {
      const currentTime = new Date().getHours();
      const isAfter330pm = currentTime >= 15.5;
      let result;
      if (isAfter330pm && cache) {
        setAllData(cache);
      } else {

        result = await getScannerResultApi({
          id: scanid,
          user_id: user.id,
          api_key: "3wxj01wpd2rnnn9c",
          access_token: token,
        });
        setAllData(result?.data);
        setCache(result?.data);
      }
      setAllDataLength(result?.data?.length);
      if (result?.data && result?.data.length > 0) {
        setScannerName(result?.data[0]?.scanner_name);
        setDate(
          result?.data[0]?.updated_at
            ? result?.data[0]?.updated_at
            : result?.data[0]?.created_at
        );
        const entireObject = result?.data[0]?.entire_object;
        if (entireObject) {
          try {
            const parsedObject = JSON.parse(entireObject);
            setMainObj(parsedObject);
            setScanCondition(
              parsedObject?.functionText1?.replace(/Add/g, "").trim(" ")
            );
          } catch (parseError) {
            console.error("Error parsing entire_object JSON:", parseError);
          }
        } else {
          console.warn("entire_object is undefined or empty");
        }
      } else {
        console.warn("No data found in the result");
      }

      const filteredData = result?.data?.filter(
        (item) => item.is_trigger === 1
      );
      setCnt(filteredData?.length);
      setLength(true);

      const tokens = filteredData?.map((item) => parseInt(item.script_code));
      if (flag === 0) {
        setData(filteredData);
        setIsConditionLoading(false);
        if (filteredData[0]?.candle_interval_id != "1d") {
          let intervalId = parseInt(
            filteredData?.[0]?.candle_interval_id.replace(/[^0-9]/g, ""),
            10
          );

          if (filteredData.length === 0) {
            const intervalMap = {
              "1 Minute": 1,
              "3 Minutes": 3,
              "5 Minutes": 5,
              "10 Minutes": 10,
              "15 Minutes": 15,
              "30 Minutes": 30,
              "60 Minutes": 60,
            };

            let interval = intervalRef.current.innerText;
            let candleInterval = intervalMap[interval] || "1d"; // Default to "1d" if interval not found

            setIntervalId(candleInterval);
          } else {

            setIntervalId(intervalId);
          }
        } else {
          setIntervalId(61);
        }
        flag = 1;
      }

      getStocksData(tokens, filteredData);
    } catch (error) {
      console.error("Error fetching scanner result:", error);
    }
  };

  const getStocksData = async (tokens, data) => {
    const apiKey = "3wxj01wpd2rnnn9c";
    const queryParams = tokens?.map((token) => `i=${token}`).join("&");
    if (tokens?.length > 0) {
      const response = await getStockLTPDataAPI({
        apiKey: apiKey,
        token: token,
        queryParams: queryParams,
      });
      if (response) {
        if (response.data) updateStocksDataNew(data, response?.data);
      }
    }
  };

  const updateStocksDataNew = (stocks, data) => {
    // Iterate through each item in the response data
    Object.keys(data).forEach((instrumentToken) => {
      const stockToUpdate = stocks.find(
        (stock) => stock.script_code == instrumentToken
      );

      if (stockToUpdate) {
        const newData = data[instrumentToken];
        stockToUpdate.ltp = newData.last_price;
        stockToUpdate.closePrice = newData.ohlc.close;
      }
    });

    if (stocks?.length > 0) {
      setScannerData(stocks);
      setIsConditionLoading(false);
    }
  };

  // GRAPH
  const fetchScanLiveLog = async () => {
    const result = await getLiveScanLogAPi({
      scanner_id: scanid,
      user_id: user?.id,
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
    });
    if (result?.success) {
      setLogData(result?.data);
      setGraph(result?.isLive);
    }
    if (clickeddata == null || otherpage === null) {
      let l = result?.data.length - 1;
      const click = result?.data[l]?.time_interval;
      setClickeddata(click);
    }
  };

  useEffect(() => {
    if (isLive) {
      const interval = setInterval(getLiveResult, 2000);
      return () => clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (strategyId) {
      const scanConditions = () =>
        shouldFetchScannerResult(mainObj, allDataLength);
      const interval = setupScannerInterval(
        scanConditions,
        clickLive,
        fetchScannerResult,
        getLiveResult
      );
      return () => clearInterval(interval);
    } else {
      const scanConditions = () =>
        shouldFetchScannerResult(mainObj, allDataLength);
      const interval = setupScannerInterval(
        scanConditions,
        clickLive,
        fetchScannerResult,
        getLiveResult
      );
      return () => clearInterval(interval);
    }
  }, [allDataLength, clickeddata, clickLive]);

  const scanNow = async () => {
    // if(isLive) {
    //   return;
    // }
    localStorage.setItem(`${scanid}`, scanid);
    // const expirationTime = Date.now() + 40000;
    // localStorage.setItem(
    //   scanid,
    //   JSON.stringify({ strategyId : scanid, expirationTime })
    // );
    setonClickDisable(true);
    setAllData([]);
    setScannerData([]);
    setCnt(0);
    setSelectBar(false);
    setIsLive(false);
    let data = { ...mainObj };
    data.access_token = token;
    data.scanner_id = scanid;
    const result = await addNewScannerAPI(data);
    if (result) {
      setAllDataLength(0);
      const interval = setInterval(() => {
        if (shouldFetchScannerResult(mainObj, allDataLength)) {
          fetchScannerResult("scanNow");
        }
      }, 1000);
      return () => clearInterval(interval);
    } else {
    }
  };
  const checkLiveCountApi = async () => {
    let getCountRes = await getLiveScannerCountAPI({
      user_id: user?.id,
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
    });
    return getCountRes;
  };
  const handleOpenModal = async () => {
    let response = await checkLiveCountApi();

    if (response?.success && response?.data > 5) {
      setCheckLiveCnt(true);
      return;
    } else {
      setCopyScannerShow(true);
      setSelectBar(false);
    }
  };
  const takeLive = async () => {
    if (isLive) {
      setHide(true);
      setCLickLive(true);
      setIntervalId(candleId);
      let timer;
      timer = setTimeout(() => {}, 40000);
      const result = await stopLiveScanner({
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
        scanner_id: scanid,
        candleIntervalId: candleId,
        user_id: user?.id,
      });

      if (result?.success) {
        fetchScannerResult("live");
        setCopyScannerShow(false);
      }
    } else {
      if (candleId == undefined || candleId == "") {
        // alert("Please select Alert Frequency");
        setAlertMessage({
          message: "Please select Alert Frequency",
          type: "alert-info",
        });
        return;
      }
      setHide(false);
      setIntervalId(candleId);
      const result = await deployScanner({
        api_key: "3wxj01wpd2rnnn9c",
        access_token: token,
        scanner_id: scanid,
        candleIntervalId: candleId,
      });
      if (result.success) {
        setIsDisabled(true);
        setTimeout(() => {
          setIsDisabled(false);
        }, 40000);
        setTimeout(() => {
          getLiveResult();
        }, 1000);

        setCopyScannerShow(false);
      }
    }
  };

  const getLiveResult = async () => {
    fetchScanLiveLog();
    const result = await getLiveScannerResultAPI({
      api_key: "3wxj01wpd2rnnn9c",
      access_token: token,
      scanner_id: scanid,
      user_id: user.id,
      time_interval: clickeddata,
    });
    if (result?.success) {
      // if (result?.data) {
      if (result?.isLive === true) {
        setCLickLive(true);
        setIsLive(true);
        setCnt(result?.data?.length);
        if (result?.data?.length === 0) setScannerData([]);
      } else {
        setIsLive(false);
      }
      const tokens = result?.data?.map((item) => parseInt(item.script_code));
      getStocksData(tokens, result?.data);
      // }
    }
  };
  const handleEdit = (data) => {
    setCandleId(data);
  };
  const noscanedit = () => {
    // alert(
    //   "scanning is still in progress, you can edit after scanning is completed"
    // );
    setAlertMessage({
      message:
        "scanning is still in progress, you can edit after scanning is completed",
      type: "alert-warning",
    });
  };
  function getTimeframe(intervalId) {
    const timeframeMap = {
      "1m": "1 Minute",
      "3m": "3 Minutes",
      "5m": "5 Minutes",
      "10m": "10 Minutes",
      "15m": "15 Minutes",
      "30m": "30 Minutes",
      "60m": "60 Minutes",
      "1d": "1 Day",
    };

    return timeframeMap[intervalId] || "";
  }
  const handleConfirm = async () => {
    handleClose();
    const data = await deleteScannerAPI({
      scanner_id: scanid,
      user_id: user.id,
    });
    if (data) {
      // alert("Scanner Deleted!");
      setAlertMessage({
        message: "Scanner Deleted!",
        type: "alert-success",
      });
      handleCloseModal();
      navigate("/scanners");
    }
    handleClose();
  };
  useEffect(() => {
    if (alertMessage) {
      setTimeout(() => {
        setAlertMessage(null);
      }, 1500);
    }
  }, [alertMessage]);
  useEffect(() => {
    if (checkLiveCnt) {
      setTimeout(() => {
        setCheckLiveCnt(false);
      }, 1500);
    }
  }, [checkLiveCnt]);
  return (
    <>
      {checkLiveCnt && (
        <CustomAlert
          message="User already have 5 take live "
          type="alert-info"
          setAlertMessage={setCheckLiveCnt}
        />
      )}

      {alertMessage && (
        <CustomAlert
          message={alertMessage.message}
          type={alertMessage.type}
          setAlertMessage={setAlertMessage}
        />
      )}
      <section className="mobile-view">
        <div className="container web-cotainer">
          <Row>
            <Col lg={9} md={9} sm={12}>
              <Row>
                <Container className="title-section p-0">
                  <Col className="d-flex justify-content-between align-items-center">
                    <div>
                      <i
                        className="fa fa-chevron-left pointer-cursor fa-2x "
                        onClick={() => navigate(-1)}
                      ></i>
                      <span>My Scanner</span>
                    </div>
                    <div>
                      <Button
                        className="primary na-cursor"
                        // onClick={() => {
                        //   setCopyScannerShow(true);
                        //   setSelectBar(false);
                        // }}
                        onClick={handleOpenModal}
                        ref={buttonRef}
                        disabled={
                          (isDisabled && isLive) ||
                          onClickDisable ||
                          (isCreatedClick &&
                            localStorage.getItem("IsCreated") == null) ||
                          localStorage.getItem(`${scanid}`) == scanid
                          // localStoredId == scanid
                        }
                      >
                        {isLive ? "Stop Live" : "Take Live"}
                      </Button>
                      &nbsp;&nbsp;
                      {!isLive && (
                        <Button
                          disabled={
                            onClickDisable ||
                            (isCreatedClick &&
                              localStorage.getItem("IsCreated") == null) ||
                            localStorage.getItem(`${scanid}`) == scanid
                            // localStoredId == scanid
                          }
                          onClick={() => {
                            scanNow();
                          }}
                        >
                          Scan Now
                        </Button>
                      )}
                    </div>
                  </Col>
                </Container>
              </Row>

              <Row className="mt-2 mb-2">
                <Col className="d-flex flex-row justify-content-between align-items-center">
                  <div>
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="200px"
                        height="50px"
                        borderRadius="8px"
                      />
                    ) : (
                      // <h1>Loading....</h1>
                      <>
                        <span className="fs-4 fw-bold">{scannerName}</span>
                        &nbsp;
                        <i className="fa fa-star"></i>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <span>
                          <Button
                            variant="outline-primary"
                            size="sm mb-2"
                            disabled={
                              (isCreatedClick &&
                                localStorage.getItem("IsCreated") == null) ||
                              onClickDisable ||
                              // localStorage.getItem(`${scanid}`) == scanid
                              localStoredId == scanid
                            }
                            onClick={() => {
                              handleEditMenu();
                            }}
                          >
                            Edit Scanner
                          </Button>
                        </span>
                      </>
                    )}
                  </div>

                  <div
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <i
                      className="fa fa-ellipsis-v text-muted dropdown-button"
                      aria-hidden="true"
                      onClick={handleClick}
                    ></i>
                    <Menu
                      id="menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          if (
                            shouldFetchScannerResult(mainObj, allDataLength)
                          ) {
                            noscanedit();
                          } else {
                            handleEditMenu();
                          }
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    </Menu>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg md sm>
                  <div className="d-flex align-items-center mb-2">
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : (
                      <div style={{ fontWeight: "500", width: "110px" }}>
                        Scan on&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; :
                      </div>
                    )}
                    &nbsp;&nbsp;
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="40%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : (
                      <div
                        style={{
                          fontWeight: "500",
                          color: "#293696",
                        }}
                      >
                        {data && data?.length > 0
                          ? data[0]?.scan_on
                          : allData && allData[0]?.scan_on}
                      </div>
                    )}
                  </div>
                  <span className="d-flex align-items-center mb-2">
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : (
                      <div style={{ fontWeight: "500", width: "110px" }}>
                        Timeframe&nbsp;&nbsp; :
                      </div>
                    )}
                    &nbsp;&nbsp;
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="80%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : (
                      <div
                        style={{ color: "#293696", fontWeight: "600" }}
                        ref={intervalRef}
                      >
                        {data && data[0]?.entire_object
                          ? getTimeframe(
                              JSON?.parse(data[0]?.entire_object)
                                ?.candle_interval_id
                            )
                          : allData &&
                            allData[0]?.entire_object &&
                            getTimeframe(
                              JSON?.parse(allData[0]?.entire_object)
                                ?.candle_interval_id
                            )}
                      </div>
                    )}
                  </span>

                  <span style={{ fontWeight: "500" }}>
                    <div className="d-flex align-items-center">
                      {isConditionLoading ? (
                        <SkeletonLoader
                          width="20%"
                          height="20px"
                          borderRadius="8px"
                        />
                      ) : (
                        <>
                          <div style={{ fontWeight: "500", width: "110px" }}>
                            Condition &nbsp;&nbsp;
                          </div>
                          <span>:</span>
                        </>
                      )}
                      &nbsp;&nbsp;
                      {isConditionLoading ? (
                        <SkeletonLoader
                          width="40%"
                          height="20px"
                          borderRadius="8px"
                        />
                      ) : (
                        <div style={{ fontWeight: "600", color: "#293696" }}>
                          {scanCondition}
                        </div>
                      )}
                    </div>
                  </span>
                  <span
                    className="d-flex align-items-center"
                    style={{ marginTop: "6px" }}
                  >
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : (
                      <div style={{ fontWeight: "500", width: "110px" }}>
                        Description &nbsp;:
                      </div>
                    )}
                    &nbsp;&nbsp;
                    {isConditionLoading ? (
                      <SkeletonLoader
                        width="20%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : (
                      <div>
                        {data && data[0] && <div>{data[0]?.description}</div>}
                      </div>
                    )}
                  </span>
                </Col>
              </Row>
              {/* )} */}
              {!isHide && graph && (
                <LogBarChart data={logData} val={setValue} graph={graph} />
              )}
              <>
                <Row className="mt-3 d-flex align-items-center justify-content-between">
                  <Col lg={6} md={6} sm={12}>
                    {(isCreatedClick &&
                      localStorage.getItem("IsCreated") == null) ||
                    localStorage.getItem(`${scanid}`) == scanid ? (
                      <SkeletonLoader
                        width="100%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : isConditionLoading ? (
                      <SkeletonLoader
                        width="100%"
                        height="20px"
                        borderRadius="8px"
                      />
                    ) : (
                      <div>
                        <span className="fs-5" style={{ fontWeight: "600" }}>
                          {cnt}
                        </span>
                        &nbsp;&nbsp;
                        <span>Scan Result</span>&nbsp;&nbsp;
                        <span className="text-muted">
                          <span style={{ fontWeight: "500" }}>
                            Last ran at &nbsp;
                          </span>
                          {selectBar ? (
                            moment
                              .utc(scannerData[0]?.date)
                              .format("hh:mm A DD-MM-YYYY")
                          ) : scannerData && scannerData[0] ? (
                            moment
                              .utc(scannerData[0]?.created_at)
                              .local()
                              .format("hh:mm A DD-MM-YYYY")
                          ) : (
                            <span>
                              {scandate &&
                                moment
                                  .utc(scandate)
                                  .local()
                                  .format("hh:mm A DD/MM/YYYY")}
                            </span>
                          )}
                          {/* {
                              moment
                              .utc(scannerData[0]?.date)
                              .format("hh:mm A DD-MM-YYYY")
                           
                          }
                          {
                          } */}
                        </span>
                      </div>
                    )}
                  </Col>
                </Row>
                {(isCreatedClick &&
                  localStorage.getItem("IsCreated") == null) ||
                localStorage.getItem(`${scanid}`) == scanid ? (
                  <SkeletonLoader
                    width="100%"
                    height="20px"
                    borderRadius="8px"
                  />
                ) : isConditionLoading ? (
                  <SkeletonLoader
                    width="100%"
                    height="20px"
                    borderRadius="8px"
                  />
                ) : (
                  <ScannerTable
                    isCreatedClick={isCreatedClick}
                    scanid={scanid}
                    isConditionLoading={isConditionLoading}
                    scannerData={scannerData}
                    fetchData={fetchScannerResult}
                    isLive={isLive}
                  />
                )}
              </>
            </Col>
            <Col lg={3} md={3} sm={12}>
              <DeployedBox />
            </Col>
          </Row>
        </div>
      </section>

      <TakeLiveModal
        copyScannerShow={copyScannerShow}
        setCopyScannerShow={setCopyScannerShow}
        setError={setScannerNameError}
        scannerName={scannerName}
        handleTakeLive={takeLive}
        mainData={mainObj}
        onFormChange={handleEdit}
        isLive={isLive}
        candle_interval_id={intervalId}
      />
      <DeleteStrategyModal
        show={show}
        handleClose={handleCloseModal}
        handleConfirm={handleConfirm}
        mode="Scanner"
      />
    </>
  );
};
