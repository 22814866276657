import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router";
import moment from "moment";

const ScannerBox = ({ scanner }) => {

  const navigate = useNavigate();
  const isTabScreen = useMediaQuery({
    query: "(max-width: 800px)",
  });

  const [series, setSeries] = useState([
    {
      name: "Desktops",
      data: [30, 40, 45, 50, 10, 20, 70, 91, 30, 40, 45, 50],
    },
  ]);

  const handleSingelStrategy = () => {
    const url = `/scanner/result/${scanner?.scanner_id}`;
    navigate(url);
  };
  return (
    <>
      <Card
        className={`strategy-card ${
          !isTabScreen ? "" : "tab-screen"
        } pointer-cursor`}
        style={{
          // width: "14vw",
          backgroundColor: "rgba(128, 128, 128, 0.1)",
          boxShadow: "0 4px 6px rgba(0, 0, 255, 0.2)",
          border: "1px solid rgba(255, 255, 255, 0.3)",
          // background: "#f6f6f6",
          left: 20,
          height: "160px",
        }}
      >
        <Card.Body onClick={handleSingelStrategy}>
          <Card.Body
            className="discover-scanner-body fs-6 p-0"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
              alignItems: "center",
            }}
          >
            <img
              alt="not found"
              src="./candleChart.png"
              style={{
                height: "60%",
                width: "60%",
                marginBottom: "18px",
              }}
              loading="lazy"
            />
            <Card.Subtitle style={{ fontSize: "13px" }}>
              {scanner.scanner_name}
            </Card.Subtitle>

            <Card.Text className="text-muted mt-0.8">
              <div style={{ fontSize: "14px" }}>
                {moment(scanner?.scanner_details?.created_at).format(
                  "DD-MM-YYYY"
                )}
              </div>
            </Card.Text>
          </Card.Body>
        </Card.Body>
      </Card>
    </>
  );
};

export default ScannerBox;
