import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { submitfeedback,getUserDetails } from "../../api/profileapi";
import { getUser } from "../../Session";
import AlertShow from "../../mcomponents/AlertShow";

const Feedback = () => {
    const [feedback,setFeedback]=useState('')
    const [feedbacktitle,setFeedbacktitle]=useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [closeAlert, setCloseAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [severity, setSeverity] = useState("");
    const [detail,setDetail]=useState([]);
    const [alertConfirm,setalertConfirm]=useState(false);
    //    const handleClose = () => {
    //        setUploadModalShow(false)
    //    }
    useEffect(()=>{
            const user=getUser();
            gettingdetail(user?.id);
    },[])
    const gettingdetail=async(id)=>{
      const result=await getUserDetails({user_id:id})
      // console.log("&&&&!!",result);
      setDetail(result);
      // if(result.userDetails)
    }
       const handleAlertMsg = (error_type, msg) => {
         setShowAlert(true);
         setSeverity(error_type);
         setAlertMsg(msg);
         setTimeout(() => {
           setShowAlert(false);
         }, 2000);
       };
       const handleCloseAlert = () => {
        setShowAlert(false);
      };
    const submitdesc=async()=>{    
      const user=getUser();
      // feedbacktitle
      if(feedback && feedbacktitle)
      {
      const result=await submitfeedback({user_id:user.id,description:feedback,title:feedbacktitle});
      if(result===true)
        {
            var msg = "Feedback submitted successfully...";
            const error_color = "success";
            handleAlertMsg(error_color, msg);       
            setFeedback('');   
            setFeedbacktitle('');  
        }
      }
      else
      {
        var msg = "Please Enter Feedback details to submit";
        setFeedback('');   
        setFeedbacktitle(''); 
        const error_color = "error";
        handleAlertMsg(error_color, msg);    
      }
}
        const setdesc=(e)=>{
            setFeedback(e.target.value);
        }
        const settitle=(e)=>{
          setFeedbacktitle(e.target.value);
        }

    return(
        <>
        {/* submitting feedback page  */}
            <Row className="mt-3">
                <Col lg={12} md={12} sm={12} className="text-center">
                   <h3 className="fw-6 heading-text-color" > Submit Feedback</h3>
                </Col>

            </Row>
            <Row className="mt-3">
            <Col>
                    <center>
                        <p className="fw-bolder">Name: {detail?.user_name}</p>
                        <p className="fw-bolder">Email: {detail?.email}</p>
                    </center>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <center>
                    <Form.Control value={feedbacktitle} onChange={settitle}  className="w-75" 
                    placeholder="Enter your feedback title here..."/>
                    </center>
                </Col>
            </Row>
         
            <Row className="mt-3">
                <Col>
                    <center>
                    <Form.Control as="textarea" rows={5} value={feedback} onChange={setdesc} className="w-75" 
                    placeholder="Enter your feedback here..."/>
                    </center>
                </Col>
            </Row>
            <Row className="mt-3">
                <center>
                <Button className="btn-color w-25" onClick={()=>submitdesc()}>Submit</Button>
                </center>
                <AlertShow
                alertShowHide={showAlert}
                 alertClose={handleCloseAlert}
                 setalertMsg={alertMsg}
                 severity={severity}
                />
            </Row>
        </>
    )
}

export default Feedback;