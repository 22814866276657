import React from "react";
import { useNavigate } from "react-router-dom";
const Card = ({ itemData, redirect }) => {
  const navigate = useNavigate();
  return (
    <div
      className="col-lg-4 col-md-4 col-sm-4 p-2 pointer-cursor"
      // onClick={() => {navigate(`/discover?filter=${itemData.filter}`)}}
      onClick={() => {
        navigate(redirect + itemData.id);
      }}
    >
      <div className={`p-4 ${itemData.class}`}>
        <img src={itemData.icon} alt="img" loading="lazy" />
        <div className="title-card">{itemData.name}</div>
        <div className="subtext py-2">{itemData.subtext}</div>
      </div>
    </div>
  );
};

export default Card;
