import React, { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { paisaDeleteAccount } from "../api/profileapi";

const Paisa5AccountDetails = (props) => {
  const {
    paisa5FormData,
    setIsAccountAdd,
    handleGetPaisaDetails,
    setShow,
    show,
    isDeleteAccount,
    setIsDeleteAccount,
    setConfirmationMsg,
  } = props;
  const userData = paisa5FormData;
  const handelDeleteAccountConfirmation = async () => {
    setConfirmationMsg("Are you sure you want to delete this account ?");
    setShow(true);
  };

  const handelDeleteAccount = async () => {
    try {
      await paisaDeleteAccount({ id: userData.id });
      handleGetPaisaDetails();
      setShow(true);
      setIsAccountAdd("");
    } catch (error) {
      console.log("Error : ", error);
    }
    setIsDeleteAccount(false);
    setShow(false);
  };

  useEffect(() => {
    console.log();
    if (isDeleteAccount) {
      handelDeleteAccount();
    }
    // console.log("Check the data...", paisa5FormData);
  }, [userData, paisa5FormData, isDeleteAccount]);

  return (
    <>
      <div className="col-lg-12">
        <div className="info-div">
          <h2 className="mobile-title">5Paisa Registartion</h2>
          <div className="card mb-4">
            <div className="card-body">
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextAppName"
                >
                  <Form.Label column sm="3">
                    App Name
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      value={userData?.app_name}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="3">
                    App Source
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      value={userData?.app_source}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextUserID"
                >
                  <Form.Label column sm="3">
                    User ID
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      value={userData?.app_user_id}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextpassword"
                >
                  <Form.Label column sm="3">
                    Password
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      value={userData?.app_password}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextpassword"
                >
                  <Form.Label column sm="3">
                    User Key
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      value={userData?.app_user_key}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextpassword"
                >
                  <Form.Label column sm="3">
                    Encreption Key
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      value={userData?.app_enc_key}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextpassword"
                >
                  <Form.Label column sm="3">
                    Client Id
                  </Form.Label>
                  <Col sm="9">
                    <Form.Control
                      plaintext
                      readOnly
                      value={userData?.client_id}
                    />
                  </Col>
                </Form.Group>
                <Row className="justify-content-md-center">
                  <Col xs lg="2">
                    <Button
                      variant="primary"
                      onClick={() => setIsAccountAdd("signupform")}
                    >
                      Update
                    </Button>
                  </Col>
                  <Col xs lg="2">
                    <Button
                      variant="danger"
                      onClick={handelDeleteAccountConfirmation}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paisa5AccountDetails;
