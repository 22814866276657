import React, { useContext } from "react";
import { Card } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../Config";
import { DataContext } from "../components/context/manageContent";

const TopMovers = ({ item, type }) => {
  const navigate = useNavigate();
  const contentData = useContext(DataContext);

  const isTabScreen = useMediaQuery({
    query: "(max-width: 800px)",
  });
  const gototechnical = (item) => {
    navigate(
      `/symbol/?id=${item?.stock_instrument_token}&itemName=${item?.stock_name}`
    );
  };
  const lastPrice = item?.last_price;
  const lastPriceNumber =
    typeof lastPrice === "number" ? lastPrice : parseFloat(lastPrice);
  const lastPriceFormatted = isNaN(lastPriceNumber)
    ? "N/A"
    : lastPriceNumber.toFixed(3);

  const diff = item?.percentageChange;
  const diffNumber = typeof diff === "number" ? diff : parseFloat(diff);
  const diffFormatted = isNaN(diffNumber) ? "N/A" : diffNumber.toFixed(3);
  const textclass = type === "losers" ? "text-danger" : "text-success";
  return (
    <>
      {item ? (
        <Card
          style={{ width: "170px", cursor: "pointer" }}
          className="top-mover"
          id="top-mover"
          onClick={() => gototechnical(item)}
        >
          <Card.Body
            className={`top-mover-body ${
              !isTabScreen ? "top-mover-body-web" : ""
            } `}
          >
            <div className="d-flex align-items-center">
              <img
                src={`${IMAGE_URL}${contentData?.featured_image}`}
                style={{ height: "20px", width: "20px" }}
                alt="nt found"
              ></img>
              <Card.Subtitle className="ps-2" style={{ fontSize: "13px" }}>
                {item?.stock_name}
              </Card.Subtitle>
            </div>
            <Card.Text className={`top-mover-text ${textclass} pt-1`}>
              <h6 className="text-end" style={{ fontSize: "12px" }}>
                <i className="fa fa-rupee pe-1 "></i>
                {lastPriceFormatted}
              </h6>
              <h6 className="text-end" style={{ fontSize: "10px" }}>
                {type === "gainers" && <i className="fa fa-plus pe-1 "></i>}
                {diffFormatted}%
              </h6>
            </Card.Text>
          </Card.Body>
        </Card>
      ) : (
        <div>No topmovers found</div>
      )}
    </>
  );
};

export default TopMovers;
