import React, { useMemo } from "react";
import PortFolio from "./Portfolio";
import { Col, Container, Row } from "react-bootstrap";
import DeployedBox from "./DeployedBox";
import { useNavigate, useSearchParams } from "react-router-dom";
import OrderBook from "../components/dashbord/OrderBook";

const Trads = () => {
    const [searchParams, setSearchParams] = useSearchParams('');
    const navigate = useNavigate();
    const tab = searchParams.get("tab");
    // setSearchParams(tab)

    const selectedTabComponent = useMemo(() => {
        switch (tab) {
            case "positions":
                return <PortFolio />;
            case "executed":
                return <OrderBook />;
            case "pending":
                return <OrderBook />;
            default:
                return null;
        }
    }, [tab]);
    return (
        <>
            <section className="mobile-view">
                <Container className="container web-cotainer">
                    <Row>
                        <Col lg={9}>
                            <Container className="title-section">
                                <Row>
                                    <Col>
                                        <i className="fa fa-chevron-left" onClick={() => {
                                            navigate(-1)
                                        }}></i>
                                        <span>{tab === "positions" ?  "Portfolio" : "Order Book" }</span>
                                    </Col>
                                </Row>
                            </Container>
                            {selectedTabComponent}
                        </Col>
                        <Col lg={3}>
                            <DeployedBox />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default Trads;
