import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Form, Row, Table } from "react-bootstrap";
import { paisaGetAccount } from "../api/profileapi";
import { getHoldingsApi, getPositionsApi } from "../api/paisa5api";
import { get5paisaToken } from "../Session";
import { getUser, getActiveAccountdata } from "../Session";
import Paisa5login from "../components/dashboardComponent/Paisa5login";
import ZerodhaLogin from "../components/zerodha/ZerodhaLogin";
import { Link, useNavigate } from "react-router-dom";
import {
  getZerodhaHoldings,
  getZerodhaMargin,
  getZerodhaPositions,
  getMarginandFO,
} from "../api/zerodhaapi";

const Cards = (props) => {
  const navigate = useNavigate();
  const d = getActiveAccountdata();
  const [connectBtn, setConnectBtn] = useState(false);
  const [activeMenu, setActiveMenu] = useState("paisa");
  const [paisa5data, setPaisa5Data] = useState();
  const [activeTab, setActiveTab] = useState(false);
  const [position, setPosition] = useState(false);
  const [activetab, setActivetab] = useState("positions");
  const [positiondata, setPositiondata] = useState([]);
  const [holdingsdata, setHoldingsdata] = useState([]);
  const handleChangeConnect = () => {
    setConnectBtn(true);
  };
  const handleChangeMenu = (menu) => {
    setActiveMenu(menu);
    // setActiveTab(true);
    if (menu === "zerodha") {
      navigate("/dashboard?page=zerodha");
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    const user = getUser();
    const fetchData = async () => {
      try {
        const getPaisa5data = await paisaGetAccount({ user_id: user.id });
        setPaisa5Data(getPaisa5data);
      } catch (error) {
        console.error("Error fetching Paisa5 data:", error);
      }
    };
    fetchData();
  }, []);

  const getPosition = async () => {
    const positions_holdings = await getMarginandFO({
      api_key: d?.api_key,
      access_key: d?.access_token,
    });
  
    if(positions_holdings && positions_holdings.length > 0 && (positions_holdings !== 'undefined' || positions_holdings !== undefined))
      {
    const [, , result, holdingresult] = positions_holdings;

    if (result) {
      setPosition(true);
      setPositiondata(result);
    } else {
      setPosition(false);
    }

    if (holdingresult) {
      setPosition(true);
      setHoldingsdata(holdingresult);
    } else {
      setPosition(false);
    }
     }
     else
     {
      console.log("GETTING ERROR IN GETTING RESPONSE")
     }
  };
  const getDetails = async () => {
    const user = getUser();
    const mData = await getZerodhaMargin({ user_id: user.id });
    const hData = await getZerodhaHoldings({ user_id: user.id });
    const pData = await getZerodhaPositions({ user_id: user.id });
    // setMargin(mData);
    // setHolding(Holding);
    // setPosition(pData);
  };

  useEffect(() => {
    // const paisatoken=get5paisaToken();
    if (d) {
      getPosition();
    }
  }, []);

  // const position=async()=>{
  // const positions=await getPositionsApi();
  //   console.log("@positions",positions);

  // }
  const clickstrategy=()=>{
    if(d)
    navigate("/strategies");
    else
      alert("To explore strategies you need to activate your account first");
  }
  const activeMenuContent = useMemo(() => {
    if (activeMenu === "paisa") {
      return (
        <Paisa5login
          handleChangeConnect={handleChangeConnect}
          connectBtn={connectBtn}
          paisa5data={paisa5data}
        />
      );
    } else if (activeMenu === "zerodha") {
      return <ZerodhaLogin />;
    } else {
      return null;
    }
  }, [activeMenu, connectBtn, paisa5data]);
  const positionactive = (nm) => {
    if (nm === "positions") {
      setActivetab("positions");
    }
    if (nm === "holdings") {
      setActivetab("holdings");
    }
  };
  return (
    <Card className="portfolio-card">
      <Card.Body className="portfolio-card-body text-center d-flex flex-column">
        {/* <Button variant="light" size="sm" className={activeMenu === "paisa" ? 'active' : ''} onClick={() =>{handleChangeMenu("paisa")}} >5Paisa</Button> */}
        {/* <Button variant="light"  className="zerodha-tab" size="sm" onClick={() =>{handleChangeMenu("zerodha")}}>Zerodha</Button> */}
        <hr />
        {activeTab ? (
          activeMenuContent
        ) : (
          <>
            <div>
              <Button
                variant="default"
                size="sm"
                onClick={() => positionactive("positions")}
                className={`border-0 ${
                  activetab === "positions" ? "active" : ""
                }`}
              >
                Positions
              </Button>
              <Button
                variant="default"
                size="sm"
                onClick={() => positionactive("holdings")}
                className={`border-0 ${
                  activetab === "holdings" ? "active" : ""
                }`}
              >
                Holdings
              </Button>
              <div className="d-flex justify-content-end">
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => {
                    if(d)
                      navigate("/trades?tab=positions");
                    else
                      alert("You need to activate your account first");
                  }}
                  className="border-0"
                >
                  View All
                </Button>
              </div>
            </div>
            {position === false ? (
              <>
                <Table className="holding-table">
                  <thead className="holding-thead border-bottom">
                  </thead>
                </Table>

                <div className="nofolderbox">
                  <div style={{ textAlign: "center" }} className="text-muted">
                    <i className="fa fa-folder-open-o fs-2"></i>
                    <h6>No positions available</h6>
                    <div onClick={clickstrategy} className="pointer-cursor text-decoration-none">
                      Go to Strategies{" "}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* <thead className="holding-thead border-bottom">
                <tr>
                  <th>Instrument@</th>
                  <th>Qty.</th>
                  <th>LTP.</th>
                  <th>P&L</th>
                </tr>
              </thead> */}
                <div style={{ maxHeight: "200px", overflowY: "scroll" }}>
                  {/* {activetab == "positions" ? (
                    <Table className="holding-table">
                      <thead className="holding-thead border-bottom">
                        <tr>
                          <th>Instruments</th>
                          <th>Qty.</th>
                          <th>LTP.</th>
                          <th>P&L</th>
                        </tr>
                      </thead>
                      <tbody>
                        {positiondata.slice(0, 5).map((item, index) => (
                          <tr>
                            <td>{item.instrument}</td>
                            <td>{item.quantity}</td>
                            <td>{item.last_traded_price}</td>
                            <td>{item.profit_loss}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <Table className="holding-table">
                      <thead className="holding-thead border-bottom">
                        <tr>
                          <th>Instruments</th>
                          <th>Qty.</th>
                          <th>LTP.</th>
                          <th>P&L</th>
                        </tr>
                      </thead>
                      <tbody>
                        {holdingsdata.slice(0, 5).map((item, index) => (
                          <tr>
                            <td>{item.instrument}</td>
                            <td>{item.quantity}</td>
                            <td>{item.last_traded_price}</td>
                            <td>{item.profit_loss}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )} */}
                  <Table className="holding-table">
                    <thead className="holding-thead border-bottom">
                      <tr>
                        <th>Instruments</th>
                        <th>Qty.</th>
                        <th>LTP.</th>
                        <th>P&L</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(activetab == "positions" ? positiondata : holdingsdata)
                        .slice(0, 5)
                        .map((item, index) => (
                          <tr>
                            <td>{item.instrument}</td>
                            <td>{item.quantity}</td>
                            <td>{item.last_traded_price}</td>
                            <td>{item.profit_loss}</td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default Cards;
