import React, { useContext, useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import MovingAverageChart from "../components/technical/MovingAverageChart";
import {IMAGE_URL} from "../Config" ;
import { DataContext } from "../components/context/manageContent";
const TechnicalBox = ({ technicalData, fetchscriptcode, percentageData }) => {
  const navigate = useNavigate();
  const [stockVal, setStockVal] = useState(null);
  const contentData = useContext(DataContext);

  const [series, setSeries] = useState([
    {
      name: "Desktops",
      data: [1200, 1380, 400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 120,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    forecastDataPoints: {
      count: 0,
      fillOpacity: 0.5,
      strokeWidth: undefined,
      // dashArray: 4
    },
    plotOptions: {
      bar: {
        barHeight: "30%",
        barWidth: "30%",
        columnWidth: "5%",
        distributed: true,
        horizontal: false,
        rangeBarOverlap: true,
        rangeBarGroupRows: false,
        // dataLabels: {
        //   position: 'bottom'
        // },
      },
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {},
      },
    ],
    colors: [
      "#33b2df",
      "#546E7A",
      "#d4526e",
      "#13d8aa",
      "#A5978B",
      "#2b908f",
      "#f9a3a4",
      "#90ee7e",
      "#f48024",
      "#69d2e7",
    ],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      colors: ["transparent"],
      width: 5,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        show: false,
      },
      y: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
  });

  const fetchsinglestock = (item) => {
    const queryParams = new URLSearchParams();
    queryParams.set("id", item.scripCode);
    queryParams.set("itemName", item.name);

    navigate(`/symbol?${queryParams.toString()}`);
    fetchscriptcode(item.scripCode);
  };
  const findSingleStock = (item) => {
    const stockData = percentageData?.find(
      (data) => data.stock_name === item?.name
    );
    return stockData?.live_price;
  };
  const findSingleStockUpDown = (item) => {
    const stockData = percentageData?.find(
      (data) => data.stock_name === item?.name
    );
    return stockData?.percentage_change.toFixed(3);
  };
  // const icon = findSingleStockUpDown(item) > 0 ? faArrowUp : faArrowDown;
  return (
    <div>
      <Table id="technical-table">
        <tbody>
          {technicalData?.length > 0 ? (
            technicalData?.map((item) => (
              <tr
                key={item}
                className="pointer-cursor"
                // onClick={() =>{navigate("/symbol?id=1")}}
                // height="200px"
              >
                <td width="10px">
                  <img
                    src={`${IMAGE_URL}${contentData?.featured_image}`}
                    style={{ height: "30px", width: "30px" }}
                    alt="nt found"
                  ></img>
                </td>
                <td width="50%" onClick={() => fetchsinglestock(item)}>
                  <div className="d-flex flex-column">
                    <span className="fs-6">{item.name}</span>
                    {/* <span className="sub-text">{item.stock_name}</span> */}
                  </div>
                </td>

                <td width="50%" className=" text-end">
                  {/* <div className="d-flex  flex-column"> */}
                  {/* <div> */}
                  <span>
                    {findSingleStock(item) && `₹ ${findSingleStock(item)} `}{" "}
                  </span>{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {findSingleStockUpDown(item) && (
                    <span
                      style={{
                        color:
                          findSingleStockUpDown(item) > 0 ? "green" : "red",
                      }}
                      className="text-end"
                    >
                      {findSingleStockUpDown(item) > 0 ? (
                        <i className="fa fa-chevron-up"></i>
                      ) : (
                        <i className="fa fa-chevron-down"></i>
                      )}{" "}
                      &nbsp;
                      <span>{findSingleStockUpDown(item)} %</span>
                    </span>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <div>
              <h6>Not Added Stocks yet....</h6>
            </div>
          )}
          {}
        </tbody>
      </Table>
    </div>
  );
};

export default TechnicalBox;
