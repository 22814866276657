import React from "react";
import { Link } from "react-router-dom";

const Paisa5AddAccountMsg = ({setIsAccountAdd}) => {

    const handleChangeMsg = () => {
        // console.log("Paisa5Msg...");
        setIsAccountAdd("signupform");
    }

  return (
    <>
      <div className="col-lg-12">
        <div className="info-div">
          <h2 className="mobile-title">5Paisa Registartion</h2>
          <p className="p-3">
            You have no account please<span className="fw-5 text-primary cursor"  onClick={handleChangeMsg}> click here  </span> to{" "}
             create account
          </p>
        </div>
      </div>
    </>
  );
};
export default Paisa5AddAccountMsg;
