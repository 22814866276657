import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { paisaCreateAccount, paisaEditAccount } from "../api/profileapi";
import { getUser } from "../Session";
import TradingForm from "./componentCommon/TradingForm";

const Paisa5SignUp = (props) => {
  const { handleGetPaisaDetails, paisa5FormData, setIsAccountAdd } = props;
  // console.log("paisa5FormData", paisa5FormData);
  const [formData, setFormData] = useState({
    appName: paisa5FormData !== "" ? paisa5FormData?.app_name : "",
    appSource: paisa5FormData !== "" ? paisa5FormData?.app_source : "",
    appUserid: paisa5FormData !== "" ? paisa5FormData?.app_user_id : "",
    appPassword: paisa5FormData !== "" ? paisa5FormData?.app_password : "",
    appUserkey: paisa5FormData !== "" ? paisa5FormData?.app_user_key : "",
    appEnckey: paisa5FormData !== "" ? paisa5FormData?.app_enc_key : "",
    client_id: paisa5FormData !== "" ? paisa5FormData?.client_id : "",
  });

  const [formDataError, setFormDataError] = useState({
    errorappName: "",
    errorappSource: "",
    errorappUserid: "",
    errorappPassword: "",
    errorappUserkey: "",
    errorappEnckey: "",
    errorclient_id: "",
  });
  const {
    appName,
    appSource,
    appUserid,
    appPassword,
    appUserkey,
    appEnckey,
    client_id,
  } = formData;
  const {
    errorappName,
    errorappSource,
    errorappUserid,
    errorappPassword,
    errorappUserkey,
    errorappEnckey,
    errorclient_id,
  } = formDataError;

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const onSubmitDetails = async(data) => {
    console.table(data);
        const user = getUser();
        // console.log("user", user);
        const userId = user.id;

        // console.log("paisa5FormData?.id ", paisa5FormData?.id);
        if (paisa5FormData?.id !== undefined) {
          const updateData = {
            ...data,
            id: paisa5FormData?.id,
          };
          const response = await paisaEditAccount(updateData);
        } else {
          const resdata = {
            ...data,
            user_id: userId,
          };
          const response = await paisaCreateAccount(resdata);
        }
        setIsAccountAdd("detailsform");
        handleGetPaisaDetails();
  };
  // const handelSubmit = async (e) => {
  //   e.preventDefault();
  //   let flag = 0;
  //   if (appName === "") {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappName: "Please enter app name",
  //     }));
  //     flag = 1;
  //   } else {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappName: "",
  //     }));
  //   }

  //   if (appSource === "") {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappSource: "Please enter app name",
  //     }));
  //     flag = 1;
  //   } else {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappSource: "",
  //     }));
  //   }

  //   if (appUserid === "") {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappUserid: "Please enter app user id",
  //     }));
  //     flag = 1;
  //   } else {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappUserid: "",
  //     }));
  //   }

  //   if (appPassword === "") {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappPassword: "Please enter password",
  //     }));
  //     flag = 1;
  //   } else {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappPassword: "",
  //     }));
  //   }

  //   if (appUserkey === "") {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappUserkey: "Please enter user key",
  //     }));
  //     flag = 1;
  //   } else {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappUserkey: "",
  //     }));
  //   }

  //   if (appEnckey === "") {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappEnckey: "Please enter encrypt key",
  //     }));
  //     flag = 1;
  //   } else {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorappEnckey: "",
  //     }));
  //   }
  //   if (client_id === "") {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorclient_id: "Please enter client id",
  //     }));
  //     flag = 1;
  //   } else {
  //     setFormDataError((prevErrors) => ({
  //       ...prevErrors,
  //       errorclient_id: "",
  //     }));
  //   }
  //   if (flag === 0) {
  //     const user = getUser();
  //     console.log("user", user);
  //     const userId = user.id;

  //     console.log("paisa5FormData?.id ", paisa5FormData?.id);
  //     if (paisa5FormData?.id !== undefined) {
  //       const updateData = {
  //         ...formData,
  //         id: paisa5FormData?.id,
  //       };
  //       const response = await paisaEditAccount(updateData);
  //     } else {
  //       const data = {
  //         ...formData,
  //         user_id: userId,
  //       };
  //       const response = await paisaCreateAccount(data);
  //     }
  //     setIsAccountAdd("detailsform");
  //     handleGetPaisaDetails();
  //   }
  // };
 
  return (
    <>
      <TradingForm
        tradeName="5paisa"
        title="5Paisa Registartion"
        onSubmitDetails={onSubmitDetails}
      />

      {/* <div className="col-lg-12">
        <div className="info-div">
          <h2 className="mobile-title">5Paisa Registartion</h2>
          <div className="card mb-4">
            <div className="card-body">
              <Form onSubmit={handelSubmit}>
                <Row>
                  <Col sm="6">
                    <Form.Label>App Name</Form.Label>
                    <Form.Control
                      value={appName}
                      name="appName"
                      type="text"
                      placeholder="Enter app name"
                      onChange={handleChangeValue}
                    />
                    <span className="text-error">{errorappName}</span>
                  </Col>
                  <Col sm="6">
                    <Form.Label>App Source</Form.Label>
                    <Form.Control
                      value={appSource}
                      name="appSource"
                      type="text"
                      placeholder="Enter app source"
                      onChange={handleChangeValue}
                    />
                    <span className="text-error">{errorappSource}</span>
                  </Col>
                </Row>

                <Row>
                  <Col sm="6">
                    <Form.Label>User ID</Form.Label>
                    <Form.Control
                      value={appUserid}
                      type="text"
                      name="appUserid"
                      placeholder="Enter User ID"
                      onChange={handleChangeValue}
                    />
                    <span className="text-error">{errorappUserid}</span>
                  </Col>
                  <Col sm="6">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      value={appPassword}
                      name="appPassword"
                      type="text"
                      placeholder="Enter password"
                      onChange={handleChangeValue}
                    />
                    <span className="text-error">{errorappPassword}</span>
                  </Col>
                </Row>

                <Row>
                  <Col sm="6">
                    <Form.Label>User Key</Form.Label>
                    <Form.Control
                      value={appUserkey}
                      name="appUserkey"
                      type="text"
                      placeholder="Enter User key"
                      onChange={handleChangeValue}
                    />
                    <span className="text-error">{errorappUserkey}</span>
                  </Col>
                  <Col sm="6">
                    <Form.Label>Encreption Key</Form.Label>
                    <Form.Control
                      value={appEnckey}
                      name="appEnckey"
                      type="text"
                      placeholder="Enter encreption key"
                      onChange={handleChangeValue}
                    />
                    <span className="text-error">{errorappEnckey}</span>
                  </Col>
                </Row>

                <Row>
                  <Col sm="6">
                    <Form.Label>Client Id</Form.Label>
                    <Form.Control
                      value={client_id}
                      name="client_id"
                      type="text"
                      placeholder="Enter encreption key"
                      onChange={handleChangeValue}
                    />
                    <span className="text-error">{errorclient_id}</span>
                  </Col>
                </Row>
                <Row className=" justify-content-md-center">
                  <Col xs lg="2">
                    <Button variant="primary" type="submit">
                      {paisa5FormData?.id ? "Update" : "Submit"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Paisa5SignUp;
